import { message as messager, Modal } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { makeUploadHeaders, makeUploadName, makeUploadOptions } from "../../../../../crf_form/components/ocr-autofill/utils";

import alioss from 'ali-oss'

import modStyl from './SignModal.module.scss'
import { signAgreementQuery } from "../../../../../../services/data/data";
import { signAgreement } from "../../../../../../services/plat/plat";
import SignPad from "../../../../../signPad/SignPad";

//
const makeNowPlaceholder = ()=> {
  const n = new Date()
  return {
    keys: ['year', 'month', 'day'],
    values: [n.getFullYear(), n.getMonth() + 1, n.getDate()],
  }
}

enum SignCodeEnum {
  None, // 未签名
  Draw, // 签名已替换到页面预览
  Submit, // 签名已提交保存
}

type SignModalProps = {
  visible: boolean;
  subjectCode: string;
  onClose: ()=> void;
  onComplete: (url: string)=> void;
}

const SignModal: React.FC<SignModalProps> = (props)=> {


  const [signPadVisible, setSignPadVisible] = useState(false)

  const [visible, setVisible] = useState(false)

  const [signCode, setSignCode] = useState(SignCodeEnum.None)
  const [htmlContent, setHtmlContent] = useState('')

  const iframeRef = useRef<HTMLIFrameElement>(null)

  const signDateReplRef = useRef(false)

  const [subjectCode] = useState(()=> props.subjectCode)

  const startUpload = async (file: File)=> {
    const pieces = await makeUploadOptions(messager.error);

    if (!pieces) {
      return;
    }

    const [prefix, options] = pieces;

    const client = new alioss(options);

    const pathname = makeUploadName(prefix, file);

    if (!pathname) {
      messager.error("上传缺少必要参数");
      return;
    }

    // 上传图片到OSS
    const result = await client.put(pathname, file, {
      timeout: 600 * 1000,
      headers: makeUploadHeaders(0),
    });

    if (result.res.status === 200) {
      return result.url
    }
    messager.error("上传失败，请重试！");
  }

  const onUpdateSignUrl = async (file: File)=> {
    if(!iframeRef.current) {
      return
    }

    const url = await startUpload(file)

    if(!url) {
      return
    }

    setSignCode(SignCodeEnum.Draw)
    
    const contentDocument = iframeRef.current.contentDocument as Document
    const elements = contentDocument.querySelectorAll('span')

    const repl = makeNowPlaceholder()
    const elementList = Array.from(elements)
    for(const el of elementList) {
      // 签署日期
      if(!signDateReplRef.current) {
        let skip = false;
        for(const i in repl.keys) {
          if(el.innerHTML.indexOf(`<!--${repl.keys[i]}-->`) === 0) {
            el.innerHTML = String(repl.values[i])
            skip = true;
            break;
          }
        }
        if(skip) {
          continue;
        }
      }

      // 根据注释内容查找
      if(el.innerHTML.indexOf(`<!--研究者签名-->`) > -1) {
        // 先删除旧的
        const oldEl = el.querySelector('img')
        if(oldEl) {
          el.removeChild(oldEl)
        }

        const imgEl = contentDocument.createElement('img')
        imgEl.setAttribute('style', 'width: 100px')
        imgEl.src = url;
        el.appendChild(imgEl)
      }
    }

    signDateReplRef.current = true
  }

  const onConfirm = async()=> {
    if(!iframeRef.current) {
      return
    }
    const iframeDocument = iframeRef.current.contentDocument
    if(!iframeDocument) {
      return
    }
    const htmlEl = iframeDocument.querySelector('html') as HTMLHtmlElement
    const hidden = messager.loading("正在处理...")
    const { code, message, data } = await signAgreement({
      subjectCode,
      agreement: htmlEl.outerHTML
    }, true)

    setSignCode(SignCodeEnum.Submit)

    hidden()

    if(code === 0) {
      setVisible(false)
      props.onComplete(data)
      messager.success('签署成功')
    } else {
      messager.error(message)
    }
  }

  const fetchData = async ()=> {
    const { code, message, data } = await signAgreementQuery(subjectCode, true)

    if(code > 0) {
      messager.error(message)
      return
    }

    setHtmlContent(data.agreement)
  }

  useEffect(()=> {
    fetchData()
  }, [])

  useEffect(()=> {
    setVisible(props.visible)
  }, [props.visible])

  return (
    <Modal onCancel={()=> {
      setVisible(false)
      props.onClose()
    }} destroyOnClose bodyStyle={{padding: 0}} footer={null} visible={visible} title="医生协议签署">
      <div className={modStyl.container}>
        <iframe ref={iframeRef} className={modStyl.ifr} title="协议签署" srcDoc={htmlContent}></iframe>
        <div className={modStyl.footer}>
          {
            signCode === SignCodeEnum.Draw && (
              <>
                <button onClick={()=> setSignPadVisible(true)} type="button" className={modStyl.cancelButton}>重新签名</button>
                <button onClick={onConfirm} type="button">提交签名</button>
              </>
            )
          }
          {
            signCode === SignCodeEnum.None && (
              <button onClick={()=> setSignPadVisible(true)} type="button">立即签名</button>
            )
          }
        </div>
      </div>
      {signPadVisible && <SignPad onConfirm={onUpdateSignUrl} onClose={()=> setSignPadVisible(false)}/>}
    </Modal>
  )
}

export default SignModal