/**
 * 账号模型
 * lechen
 */

export class Account {
    // 用户名
    userCode:string;
    // 密码
    passWord:string;

    constructor(prop1:any, prop2:any) {
        if (typeof prop1 === 'object') {
            this.userCode = prop1.userCode;
            this.passWord = prop1.passWord;
        } else {
            this.userCode = prop1;
            this.passWord = prop2;
        }
    }

    check = () => {
        if (!this.userCode) {
            return '';
        }
        return false;
    }

    toParams = () => {
        let params = {
            accountId: this.userCode ? this.userCode : 'admin'
        }
    }
}


