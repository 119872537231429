/**
 * crc 录入
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './index.less';
import { Button, Input, Select, Tabs, message, Row, Col, Card, Checkbox, Switch, Modal, Spin, Image, Carousel, ConfigProvider } from 'antd';
import {
    DashboardOutlined, FundProjectionScreenOutlined,
    CoffeeOutlined, ExclamationCircleOutlined, WarningTwoTone, InfoOutlined, CloseOutlined
} from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import ImgCanvas from '../../../../img_canvas/drawCanvas.jsx';
import {
    GiveUpDctDiscard, GetDctIncomplete,
    GetDctAssignInputTask, SubmitInputTask, GetFormDataById, saveFormData, GetFormInfo
} from '../../../../../services/dct/DctService';
import Slider from "react-slick";
import Control from '../../../../crf_form/control/Control';
import InCompleteModal from './modal';
import sensitiveinfo from '../../../static/images/sensitiveinfo.svg';
import hintIcon from '../../../static/images/hint.png';
import CountdownCom from './countdown';
import axios from 'axios';
import PubSub from 'pubsub-js';

const CheckboxGroup = Checkbox.Group;
const { TabPane } = Tabs;
const { confirm } = Modal;

let _this;
const { Option } = Select;

const work = {
    background: '#1890ff',
    color: '#fff',
}
const noWork = {
    background: '#fff',
    color: '#1890ff',
}

class EnterComponent extends Component {

    constructor(props) {
        super(props);
        _this = this;
        this.state = {
            topNav: null,
            bottomNav: null,
            formData: null, // 表单信息
            ImgList: null,
            savedTime: 1000,
            formId: null, // 表单id
            taskId: null, // 任务id
            switchChecked: true, // 切换工作状态
            cardTitle: '', // 表单标题
            loading: true,
            isSpin: false,
            tenantCode: null,
            currItem: null,
            countDown: null, // 倒计时
            visible: false, // 图片不完整弹窗
            formCode: null,
            version: '',
            optionsData: [], // 图片不完整内容
            text: '', // 划词识别出来的文字信息
            slideIndex: 0,
            ocrFieldList: null, // 角标排序
            slidesToShow: 10, // 缩略图个数
            desensitization: null, // 1-需脱敏
            showInfo: false

        };
    }
    formControlRef = React.createRef();

    componentDidMount() {
        this.setState({
            topNav: this.sliderTop,
            bottomNav: this.sliderBottom
        });
        let enterData = sessionStorage.getItem('enterData')
        let data = this.props.location.state.data || JSON.parse(enterData)
        
        if (data && data.length > 0) {
            this.setState({
                ImgList: data,
                formId: data[0].formId,
                taskId: data[0].id,
                tenantCode: data[0].tenantCode,
                cardTitle: data[0].formName,
                countDown: data[0].countDown,
                formCode: data[0].formCode,
                version: data[0].version,
                desensitization: data[0].desensitization,
            })
            this.getFormDataById(data[0].formId, data[0].tenantCode) // 默认录入第一张表单
            this.getFormMenu(data[0].formCode, data[0].version)

            PubSub.publish('timer',{time: data[0].countDown});
        }
    }

    // 获取枚举 
    getFormMenu = (formCode, version) => {
        let params = {
            formCode: formCode || '',
            version: version || ''
        }
        GetFormInfo(params).then(res => {
            if (res && res.code == 0) {
                this.setState({
                    optionsData: res.data
                })
            }
        })
    }

    // 当前录入的表单
    onImgEnter = (item) => {
        this.setState({
            loading: false,
            formId: item.formId,
            taskId: item.id,
            cardTitle: item.formName,
            currItem: item,
            countDown: item.countDown,
            formCode: item.formCode,
            version: item.version,
            desensitization: item.desensitization,
        })
    }

    // 通过id获取表单信息
    getFormDataById = async (id, dataTenantCode) => {
        
        let params = { id, dataTenantCode }
        GetFormDataById(params).then(res => {
            if (res.code === 0 && res.data) {
                res.data.formActions = [
                    "FormActionSave",
                    "FormActionSubmit"
                ]
                res.data.dataActions = [
                    "DataActionInput",
                    "DataActionAnnotation",
                    "DataActionAnswer",
                    "DataActionOcr"
                ]
              
            }
            this.setState({
                formData: res.data,
                loading: false
            })
        })
    }

    // 渲染工作/休息状态
    renderExtra = () => {
        let { switchChecked } = this.state
        return <div className="switch">
            <div className="left" onClick={() => this.onSwitch(true)} style={switchChecked ? work : noWork}>
                <FundProjectionScreenOutlined />&nbsp;工作
            </div>
            <div className="right" onClick={() => this.onSwitch(false)} style={!switchChecked ? work : noWork}>
                <CoffeeOutlined />&nbsp;休息
            </div>
        </div>
    }

    // 下一任务
    nextTasks = async () => {
        const res = await GetDctAssignInputTask();
        if (res && res.code == 0) {
            if (!res.data || res.data.length == 0) {
                this.props.history.push({pathname: "/dct/crc/data", state: {enterStatus: false}});
                return
            }
            
            PubSub.publish('timer',{time: res.data[0].countDown, clear: true});
            // 清空图片数据，不然会有图片不更新问题
            this.setState({
                ImgList: []
            });
            this.setState({
                ImgList: res.data,
                formId: res.data[0].formId,
                tenantCode: res.data[0].tenantCode,
                taskId: res.data[0].id,
                cardTitle: res.data[0].formName,
                countDown: res.data[0].countDown,
                formCode: res.data[0].formCode,
                version: res.data[0].version,
                desensitization: res.data[0].desensitization,
                isSpin: false,
                loading: true,
            }, () => {
                this.getFormDataById(res.data[0].formId, res.data[0].tenantCode)
                this.getFormMenu(res.data[0].formCode, res.data[0].version)
                // 数据采集提交任务后当前图片不更新,需要点击手动更新
                let _dom = document.querySelector('.pictureList .slick-track');
                _dom = _dom && _dom.childNodes && _dom.childNodes[0];
                let clickInterVal = setInterval(() => {
                    _dom && _dom.click && _dom.click();
                }, 300);
                setTimeout(() => {
                    clearInterval(clickInterVal);
                    // 图片展示出来，更新绑定事件
                    this.setState({ slideIndex: 0});
                }, 3000);
            })
        }
    }

    // 模态框提醒
    modalConfirm = (data) => {
        this.setState({
            switchChecked: false
        })

        let params = {
            id: this.state.taskId,
        }

        Modal.confirm({
            title: data.title,
            icon: data.icon,
            content: data.content,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                this.setState({
                    switchChecked: false
                })

                if (data.title == '放弃任务') {
                    // 放弃，继续下一任务
                    GiveUpDctDiscard(params).then(res => {
                        if (res.code === 0) {
                            this.nextTasks()
                        }
                    })
                    this.toTuoMin();
                } else { // 退出工作模式
                    // 放弃
                    GiveUpDctDiscard(params).then(res => {
                        if (res.code === 0) {
                            this.props.history.push({pathname: "/dct/crc/data", state: {enterStatus: false, statusText: '休息'}});
                        }
                    })
                    this.toTuoMin();
                }
            },
            onCancel: () => {
                if (data.title == '退出工作模式') {
                    this.setState({
                        switchChecked: true
                    })
                }
            }
        });
    }

    // 工作/休息
    onSwitch = (checked) => {
        if (!checked) {
            let data = {
                title: '退出工作模式',
                icon: <ExclamationCircleOutlined />,
                content: '退出工作模式后，当前未完成的任务将会自动放弃，是否确认切换？',
            }
            this.modalConfirm(data)
        }
    }

    // 放弃任务
    giveUp = () => {
        let data = {
            title: '放弃任务',
            icon: <WarningTwoTone twoToneColor="#ff3300" />,
            content: '放弃任务后，当前填写的全部内容不会记入您完成的任务中，是否确认放弃？',
        }
        this.modalConfirm(data)
    }

    // 图片不完整
    inComplete = () => {
        this.setState({
            visible: true
        })
    }

    // 提交任务
    submitTask = () => {
        this.setState({
            isSpin: true,
        })
        let params = this.formControlRef.current.getSavedForm();
        params['dataTenantCode'] = this.state.tenantCode

        if (!params.values || params.values.length == 0) {
            message.error('请填写表单！')
            return
        }

        saveFormData(params).then(res => {
            let _this = this;
            if (res.code === 0) {
                SubmitInputTask({ id: this.state.taskId }).then(res => {
                    if (res.code === 0) {
                        message.success('操作成功！')
                        // 提交任务后，清空上次数据，有页面预览照片依旧是上个任务的照片的问题。
                        this.toTuoMin();
                        _this.nextTasks()
                    }
                })
            } else {
                _this.setState({
                    isSpin: false,
                })
            }
        })
    }

    // 渲染表单
    handleView = (data) => {
        let { formId, loading, text, slideIndex,ocrFieldList,formCode,version,ImgList,tenantCode } = this.state
        const triggerDrawAreaFunc = this.refs['toGetImageDrawArea' + slideIndex] && this.refs['toGetImageDrawArea' + slideIndex].getDrawArea;
        const cancelDrawAreaFunc = this.refs['toGetImageDrawArea' + slideIndex] && this.refs['toGetImageDrawArea' + slideIndex].cancelDrawArea;
        const deleteDrawAreaFunc = this.refs['toGetImageDrawArea' + slideIndex] && this.refs['toGetImageDrawArea' + slideIndex].deleteDrawArea;

        return (
            <Spin tip="Loading..." spinning={loading}>
                <Control
                    tenantCode={tenantCode}
                    triggerDrawAreaFunc={triggerDrawAreaFunc}
                    cancelDrawAreaFunc={cancelDrawAreaFunc}
                    deleteDrawAreaFunc={deleteDrawAreaFunc}
                    text={text}
                    formId={formId}
                    data={data}
                    ref={this.formControlRef}
                    startTimerFun={() => { }}
                    ocrFieldList={ocrFieldList}
                    isDct={true}
                ></Control>
            </Spin>
        )
    }

    // 渲染图片信息
    renderImgInfo = (item) => {
        let ImgInfoData = []
        if (!item || item.length == 0) {
            return
        }
        const targetName = sessionStorage.getItem('targetName') || '受试者';
        ImgInfoData = [
            { label: '', value: <>{item.label}</>, s: item.sensitiveInfo },
            { label: '所属项目：', value: item.projectName, width: '55%', s: item.sensitiveInfo },
            { label: '访视：', value: item.visitName, width: '45%', s: item.sensitiveInfo },
            { label: `${targetName}：`, value: `${item.subjectName || ''}（${item.subjectCode}）`, width: '55%', s: item.sensitiveInfo },
            { label: '采集人：', value: item.collector ? item.collector : item.collectorCode, width: '45%', s: item.sensitiveInfo }
        ]

        return ImgInfoData
    }

    // 关闭拒绝模态框
    closeModal = (closeType) => {
        this.setState({
            visible: false,
        });
        if (closeType === 'ok') {
            this.toTuoMin();
        }
    }

    // 获取模态框信息
    getModalValue = (values) => {
        // 保存接口，图片不完整接口，下一个任务接口
        if (_this.formControlRef.current) {
            let params = _this.formControlRef.current.getSavedForm();
            params.submit = true;
            params['dataTenantCode'] = _this.state.tenantCode

            saveFormData(params).then(res => {
                if (res.code === 0) {
                    // message.success('保存成功！')
                    let Incom = {
                        id: _this.state.taskId,
                        rejectReason: values
                    }
                    GetDctIncomplete(Incom).then(res => {
                        if (res.code === 0) {
                            message.success('操作成功！')
                            _this.nextTasks()
                        }
                    })
                }
            })
        }
    }

    getCountDownVal = (v) => {
        if (v == 'next') {
            this.setState({
                visible: false,
                countDown: null
            });
            _this.nextTasks()
            
        } else if (v == 'cancel') {
            _this.props.history.push({pathname: "/dct/crc/data", state: {enterStatus: v, statusText: '休息'}});
        } else if (v == 0) {
            this.setState({
                visible: false,
                countDown: null
            });
        }
    }

    getDrawAreaWord = (val) => {
        this.setState({
            text: val
        });
    }

    toTuoMin() {
       const { ImgList } = this.state;
       ImgList.forEach((val, index) => {
           const refComponent = this.refs['toGetImageDrawArea' + index];
           const param = refComponent && refComponent.getSubmitAreaListParam && refComponent.getSubmitAreaListParam();
           this.tuoMin(param);
       });
    }

    tuoMin(param) {
        param && param.areaList && (param.areaList.length) > 0 && axios.post("/file/image/desensitizate", param);
    }

    // 渲染缩略图背景
    renderBg = (data) => {
        let dom = [];
        for (var i = 0; i < data; i++) {
            dom.push(<div className={'bg-item'}></div>)
        }
        return dom
    }

    // 渲染任务title
    renderTitle = (sign,title) => {
        return <><span className="sign" hidden={!(sign == '1')}>需脱敏</span>{title}</>
    }

    showEnterInfo = (val) => {
        this.setState({
            showInfo: val
        })
    }

    render() {
        let { topNav, bottomNav, formData, ImgList, cardTitle, optionsData, ocrFieldList, desensitization, showInfo,
            formId, isSpin, loading, currItem, taskId, countDown, visible, slidesToShow } = this.state;
        countDown = countDown ? Math.abs(countDown) : null
        return (
            <div className={'enter_content'}>
                <div className={'card'}>
                    <Card title={this.renderTitle(desensitization,cardTitle)} extra={this.renderExtra()}>
                        <Row style={{ height: '100%' }}>
                            <Col span={13} style={{ height: '100%' }}>

                                <div className={'pictureList'} >
                                    <div className={'slider_bottom'}>
                                        <Slider
                                            asNavFor={topNav}
                                            ref={slider => (this.sliderBottom = slider)}
                                            slidesToShow={slidesToShow}
                                            swipeToSlide={true}
                                            focusOnSelect={true}
                                            dots={false}
                                            infinite={false}
                                            adaptiveHeight={false}
                                            slickGoTo = { (res) => { console.log('res');} }
                                        >
                                            {ImgList && ImgList.map((item, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => this.onImgEnter(item)}
                                                    className={item.id == (taskId || ImgList[0]) ? 'select' : 'ImgHeight'}
                                                >
                                                    <img src={item.url} alt='' key={index} />
                                                    <div className={'corner'} hidden={!(item.sensitiveInfo == '1')}>
                                                        <img src={hintIcon} alt=''/>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                        <div className={'slider-bg'}>
                                            {this.renderBg(slidesToShow)}
                                        </div>
                                    </div>
                                    <div className={'slider_top'}>
                                        <Slider
                                            asNavFor={bottomNav}
                                            ref={slider => (this.sliderTop = slider)}
                                            infinite={false}
                                            swipeToSlide={false}
                                            focusOnSelect={true}
                                            adaptiveHeight={false}
                                            arrows={false}
                                            draggable={false}
                                            onInit={(index) => { console.log('slider_initinit');}}
                                            beforeChange={(oldIndex, newIndex) => { this.setState({ slideIndex: newIndex}); }}
                                        >
                                            {ImgList && ImgList.map((item, index) => (
                                                <div key={index} className={'ImgHeight'}>
                                                    <ConfigProvider locale={zhCN}>
                                                        <ImgCanvas
                                                            ref={"toGetImageDrawArea" + index}
                                                            ifGetDrawArea = {true}
                                                            needUpdate = {false}
                                                            key = {index}
                                                            imgData = {item}
                                                            imgUrl = {item.url}
                                                            getDrawAreaWord = { (res) => { this.getDrawAreaWord(res) }}
                                                        />
                                                    </ConfigProvider>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>

                                    {!showInfo && <InfoOutlined className={'InfoOutlined'} onClick={() => this.showEnterInfo(true)} />}

                                    {showInfo && <div className={'enter_info'}>
                                        <CloseOutlined className={'CloseOutlined'} onClick={() => this.showEnterInfo(false)} />
                                        <div className="subtit_con">
                                            {
                                                ImgList && ImgList.length > 0 ?
                                                    this.renderImgInfo(currItem || ImgList[0]).map((item, index) => (
                                                        <>
                                                            <div className={item.label == '' ? 'tit' : 'subtit'}
                                                                key={index} style={{ width: item.width }}>{item.label}{item.value}</div>
                                                        </>
                                                    )) : null
                                            }
                                        </div>
                                    </div>}
                                </div>
                                
                            </Col>
                            <Col span={11} >
                                <div className={'enterInfo'}>
                                    {formData && this.handleView(formData)}
                                </div>
                                <div className="operate_bottom">
                                    <CountdownCom
                                        countDown={countDown}
                                        getValue={(v) => this.getCountDownVal(v)}
                                    />
                                    <div className="btns">
                                        <Button type={'link'} onClick={this.giveUp} danger>{'放弃任务'}</Button>
                                        <Button type={'primary'} onClick={this.inComplete} danger >图片不完整</Button>
                                        <Button type={'primary'} onClick={this.submitTask} loading={isSpin} disabled={loading}>提交任务</Button>
                                        <InCompleteModal
                                            visible={visible}
                                            closeModal={_this.closeModal}
                                            getValue={(v) => this.getModalValue(v)}
                                            optionsData={optionsData}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </div>
        )
    }
}

export default EnterComponent