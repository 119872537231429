import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react';
import { createFromIconfontCN } from '@ant-design/icons';
import { Button, Input, message, Popconfirm, Select, Table } from 'antd';
import styles from './maskingRule.module.scss';
import { GetRuleConfigList, GetRuleConfigSelects, ModifyRuleConfig } from '../../../services/config/ConfigService';
import localStorageUtil from '../../../comm/localStorage';
import PubSub from "pubsub-js";

const { Option } = Select;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2343612_6am0z107jta.js',
    ],
});

function MaskingRule(props: any, ref: any) {
    //规则列表
    const [ruleList, setRuleList] = useState<any>([]);
    //规则列表配置
    const [listColumns, setListColumns] = useState<any>([]);
    //scope
    const [scope, setScope] = useState<string>('');
    //类型list
    const [scopeTypeList, setScopeTypeList] = useState<any>([]);
    //类型
    const [scopeType, setScopeType] = useState<any>("All");
    //状态list
    const [ruleStateList, setRuleStateList] = useState<any>([]);
    //状态
    const [ruleState, setRuleState] = useState<any>("All");
    //所属项目list
    const [projectList, setProjectList] = useState<any>([]);
    //选中查询的项目
    const [selectedProject, setSelectedProject] = useState<string>();
    //page
    const [page, setPage] = useState<number>(1);
    //pageSize
    const [pageSize, setPageSize] = useState<number>(20);
    //totalNum
    const [totalNum, setTotalNum] = useState<number>(0);
    //tableLoading
    const [tableLoading, setTableLoading] = useState<boolean>(false);

    //初始化
    useEffect(() => {
        //初始化
        initData();
        //获取列表数据
        getRuleListData();
    }, [])

    //初始化数据
    const initData = () => {
        let menuModule: any = localStorageUtil.get('menuModule', true);
        menuModule.levelThreeTitle = '';
        menuModule.levelTwoUrl = '/config/maskingRule';
        localStorageUtil.set('menuModule', menuModule, true);
        PubSub.publish('onMenuModuleChange', menuModule);
        //设置表格配置
        let columns = [
            {
                align: 'center',
                title: '作用域',
                dataIndex: 'name',
                key: 'name',
                width: 150,
            },
            {
                align: 'center',
                title: '作用域类型',
                dataIndex: 'scopeName',
                key: 'scopeName',
                width: 150,
            },
            {
                align: 'center',
                title: '推荐版本',
                width: 150,
                dataIndex: 'versionRecom',
                key: 'versionRecom'
            },
            {
                align: 'center',
                title: '状态',
                width: 150,
                dataIndex: 'stateName',
                key: 'stateName'
            },
            {
                align: 'center',
                title: '创建人',
                width: 150,
                dataIndex: 'createUser',
                key: 'createUser'
            },
            {
                align: 'center',
                title: '创建时间',
                width: 150,
                dataIndex: 'createTime',
                key: 'createTime'
            },
            {
                align: 'center',
                title: '最新版本',
                width: 150,
                dataIndex: 'versionLatest',
                key: 'versionLatest'
            },
            {
                align: 'center',
                width: 150,
                title: '最新发布时间',
                dataIndex: 'versionTime',
                key: 'versionTime'
            },
            {
                align: 'center',
                fixed: 'right',
                title: '操作',
                width: 200,
                render: (text: any, item: any) => {
                    return switchActionByState(item)
                }
            }
        ]
        setListColumns(columns);

        //设置类型list && 状态list
        GetRuleConfigSelects().then(res => {
            if (res) {
                setScopeTypeList(res.scopeSelectEnumList);
                setRuleStateList(res.ruleStateSelectEnumList);
                setProjectList(res.projectSelect);
            }
        })

        // //设置所属项目
        // let projectList: never[] = [
        //     // {
        //     //     label: '糖尿病项目',
        //     //     value: 'project0001'
        //     // }
        // ];
        // setProjectList(projectList);
    }

    //获取规则列表数据
    const getRuleListData = () => {
        setTableLoading(true);
        let params = {
            // projectCode: selectedProject,
            name: scope ? scope : null,
            page: page,
            pageSize: pageSize,
            scope: scopeType !== "All" ? scopeType : null,
            state: ruleState !== "All" ? ruleState : null,
        }
        GetRuleConfigList(params).then(res => {
            setRuleList(res.rows);
            setTotalNum(res.total);
            setTableLoading(false);
        })
    }

    //查询事件
    const onSearch = () => {
        getRuleListData();
    }

    //当分页change
    const onPageChange = (page: any, pageSize: any) => {
        setPage(page);
        setPageSize(pageSize);
        setTableLoading(true);
        let params = {
            projectCode: selectedProject,
            name: scope ? scope : null,
            page: page,
            pageSize: pageSize,
            scope: scopeType !== "All" ? scopeType : null,
            state: ruleState !== "All" ? ruleState : null,
        }
        GetRuleConfigList(params).then(res => {
            setRuleList(res.rows);
            setTotalNum(res.total);
            setTableLoading(false);
        })
    }

    //根据当前状态选择不同操作
    const switchActionByState = (item: any) => {
        const state = item.state;
        switch (state) {
            case 'USE':
                return (
                    <div className={styles.actions}>
                        <div className={styles.actionBtn} onClick={() => { onRead(item) }}>
                            <IconFont type="iconchakan"></IconFont>
                            查看
                        </div>
                        <div className={styles.actionBtn} onClick={() => { onEdit(item) }}>
                            <IconFont type="iconbianji"></IconFont>
                            编辑
                        </div>
                        <Popconfirm
                            title="确认停用吗?"
                            okText='确认'
                            cancelText='取消'
                            onConfirm={() => { onStop(item) }}
                        >
                            <div style={{ color: '#f04864' }} className={styles.actionBtn}>
                                <IconFont type="iconstop_warning"></IconFont>
                                停用
                            </div>
                        </Popconfirm>
                    </div>
                )
                break;
            case 'UNUSE':
                return (
                    <div className={styles.actions}>
                        <div className={styles.actionBtn} onClick={() => { onRead(item) }}>
                            <IconFont type="iconchakan"></IconFont>
                            查看
                        </div>
                        <div className={styles.actionBtn} onClick={() => { onEdit(item) }}>
                            <IconFont type="iconbianji"></IconFont>
                            编辑
                        </div>
                        <div className={styles.actionBtn} onClick={() => { onStart(item) }}>
                            <IconFont type="iconstart"></IconFont>
                            启用
                        </div>
                    </div>
                )
                break;
            case 'UNRELEASE':
                return (
                    <div className={styles.actions}>
                        <div className={styles.actionBtn} onClick={() => { onRead(item) }}>
                            <IconFont type="iconchakan"></IconFont>
                            查看
                        </div>
                        <div className={styles.actionBtn} onClick={() => { onEdit(item) }}>
                            <IconFont type="iconbianji"></IconFont>
                            编辑
                        </div>
                        <div className={styles.actionBtn} onClick={() => { onSubmit(item) }}>
                            <IconFont type="iconsubmit"></IconFont>
                            发布
                        </div>
                    </div>
                )
                break;
            default:
                break;
        }
    }

    //编辑事件
    const onEdit = (item: any) => {
        let menuModule: any = localStorageUtil.get('menuModule', true);
        menuModule.levelThreeTitle = '脱敏规则编辑';
        menuModule.levelTwoUrl = '/config/maskingRule';
        localStorageUtil.set('menuModule', menuModule, true);
        PubSub.publish('onMenuModuleChange', menuModule);
        props.history.push({ pathname: `/config/maskingRule/ruleDetail/${item.id}`, state: { id: item.id } })
    }

    //点击查看
    const onRead = (item: any) => {
        let menuModule: any = localStorageUtil.get('menuModule', true);
        menuModule.levelThreeTitle = '脱敏规则查看';
        menuModule.levelTwoUrl = '/config/maskingRule';
        localStorageUtil.set('menuModule', menuModule, true);
        PubSub.publish('onMenuModuleChange', menuModule);
        if (item.versionRecom) {
            props.history.push(`/config/maskingRule/readRuleDetail/${item.id}/${item.versionRecom}`)
        }
        else {
            message.error('暂无版本可查看！', 1)
        }
    }

    //发布
    const onSubmit = (item: any) => {
        let menuModule: any = localStorageUtil.get('menuModule', true);
        menuModule.levelThreeTitle = '脱敏规则发布';
        menuModule.levelTwoUrl = '/config/maskingRule';
        localStorageUtil.set('menuModule', menuModule, true);
        PubSub.publish('onMenuModuleChange', menuModule);
        props.history.push(`/config/maskingRule/ruleDetail/${item.id}`)
    }

    //停用
    const onStop = (item: any) => {
        let params = {
            id: item.id,
            state: 'UNUSE'
        }
        ModifyRuleConfig(params).then(res => {
            if (res.code === 0) {
                getRuleListData();
            }
            else {
                message.error('操作失败！', 1)
            }
        })
    }

    //启用
    const onStart = (item: any) => {
        let params = {
            id: item.id,
            state: 'USE'
        }
        ModifyRuleConfig(params).then(res => {
            if (res.code === 0) {
                getRuleListData();
            }
            else {
                message.error('操作失败！', 1)
            }
        })
    }

    return (
        <div className={styles.maskingRule}>
            <div className={styles.search_content}>
                <div className={styles.searchItem}>
                    <div className={styles.label}>作用域：</div>
                    <div className={styles.itemForm}>
                        <Input placeholder="填写作用域" value={scope} allowClear onChange={(e) => { setScope(e.target.value) }} />
                    </div>
                </div>
                <div className={styles.searchItem}>
                    <div className={styles.label}>类型：</div>
                    <div className={styles.itemForm}>
                        <Select placeholder="选择类型" value={scopeType} style={{ width: 150 }} onChange={(e) => { setScopeType(e) }}>
                            <Option value={"All"}>全部类型</Option>
                            {
                                scopeTypeList && scopeTypeList.map((item: any, index: any) => {
                                    return <Option value={item.code} key={index}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className={styles.searchItem}>
                    <div className={styles.label}>状态：</div>
                    <div className={styles.itemForm}>
                        <Select placeholder="选择状态" value={ruleState} style={{ width: 150 }} onChange={(e) => { setRuleState(e) }}>
                            <Option value={"All"}>全部状态</Option>
                            {
                                ruleStateList && ruleStateList.map((item: any, index: any) => {
                                    return <Option value={item.code} key={index}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </div>
                </div>
                {/* {
                    scopeType === 'project' ? <div className={styles.searchItem}>
                        <div className={styles.label}>所属项目：</div>
                        <div className={styles.itemForm}>
                            <Select placeholder="选择所属项目" value={selectedProject} style={{ width: 150 }} onChange={(e) => { setSelectedProject(e) }}>
                                {
                                    projectList && projectList.map((item: any, index: any) => {
                                        return <Option value={item.key} key={index}>{item.label}</Option>
                                    })

                                }
                            </Select>
                        </div>
                    </div> : null
                } */}
                <div className={styles.searchBtn}>
                    <Button type="primary" onClick={onSearch}>查询</Button>
                </div>
            </div>
            <div className={styles.table}>
                <Table
                    locale={{ emptyText: "暂无数据" }}
                    className={'table-list'}
                    bordered
                    columns={listColumns}
                    dataSource={ruleList}
                    pagination={{ pageSize: pageSize, total: totalNum, onChange: onPageChange }}
                    scroll={{ x: 1200 }}
                    rowKey={'id'}
                    loading={tableLoading}
                />
            </div>
        </div>
    )
}


export default MaskingRule;