import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import Chat from "./Chat";

type ChatModalProps = {
  subjectCode: string;
  visible: boolean;
  onClose: () => void;
};

const ChatModal = (props: ChatModalProps) => {
  const [visible, setVisible] = useState(false);
  const [subjectCode, setSubjectCode] = useState('')

  useEffect(()=> {
    setVisible(props.visible)
    if(props.visible) {
      setSubjectCode(props.subjectCode)
    }
  }, [props.visible])

  const onCancel = ()=> {
    setVisible(false)
    setSubjectCode('')
    props.onClose()
  }

  return (
    <Modal
      bodyStyle={{ height: 500, padding: 0 }}
      footer={null}
      width="960px"
      title="与患者对话"
      visible={visible}
      onCancel={onCancel}
      keyboard={false}
      maskClosable={false}
      destroyOnClose
    >
      <Chat subjectCode={subjectCode}/>
    </Modal>
  );
};

export default ChatModal;
