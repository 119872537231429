import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import utils from '../../utils/utils';
import { Modal, Upload, Image, ConfigProvider, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './photo.less';
import formAction from '../../utils/formAction';
import zh_CN from 'antd/lib/locale-provider/zh_CN';



function CameraCom(props: any, ref: any) {
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [previewTitle, setPreviewTitle] = useState<string>('');
    const [fileList, setFileList] = useState<any>([]);
    const [value, setValue] = useState<any>([]);
    const [data, setData] = useState<any>();
    const [formId, setFormId] = useState<any>();
    const [dataActions, setDataActions] = useState<any>([]);
    const [formIsRead, setFormIsRead] = useState<boolean>(true);
    const [savedValues, setSavedValues] = useState<any>([]);
    const [previewImage, setPreviewImage] = useState<any>();
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [updatedValue, setUpdatedValue] = useState<any>([]);

    const [visible, setVisible] = useState(false);


    useEffect(() => {
        if (props.data) {
            //设置dataActions
            setDataActions(props.dataActions);
            //表单是否只读
            setFormIsRead(props.formIsRead);
            //获取当前表单的id
            setFormId(props.formId);
            //该条表单元素的数据
            setData(props.data);
            //已经保存的表单数据
            setSavedValues(props.savedValues);
            //如果是修改时
            if (props.isUpdate) {
                setIsUpdate(props.isUpdate)
            }
            else {
                setIsUpdate(false)
            }
            //设置当前表单已存的value
            let currentItem = props.savedValues.find((i: any) => {
                return i.dataCode == props.data.dataCode
            });
            if (currentItem) {
                let fieldList = [];
                if (currentItem.value) {
                    for (let item of currentItem.value) {
                        fieldList.push({
                            url: item,
                            uid: item
                        })
                    }
                }
                setValue(fieldList);
                setFileList(fieldList);
                setUpdatedValue(fieldList);
            }
            else {
                setValue([])
                setFileList([]);
                setUpdatedValue([]);
            }
        }
    }, [props.data, props.savedValues])



    //图片预览
    const handlePreview = (e: any) => {
        if (e.url) {
            setPreviewImage(e.url);
        } else if (e.response) {
            setPreviewImage(e.response.data.url);
        }
        setPreviewVisible(true);
        setPreviewTitle('图片预览');
    }

    const _itemData = {
        projectCode: '',
        subjectCode: ''
    };
    let itemData = sessionStorage.getItem('photoEditItem');
    itemData = itemData ? JSON.parse(itemData) : _itemData;
    const extraHeader = {
        "x-formId-code": formId,
        // @ts-ignore 首页进来时点击条目的projectCode
        "x-projectCode-code": itemData.projectCode,
        // @ts-ignore 首页进来时点击条目的subjectCode
        "x-subjectCode-code": encodeURIComponent(itemData.subjectCode)
    }



    const handleChange = () => {
        return message.error('请从小程序端拍照上传材料！')
    }

    //暂存数据
    const saveData = (value: any) => {
        let filterArr = [];
        for (let item of value) {
            if (item.url) {
                filterArr.push(item.url);
            }
        }
        let values = [
            {
                dataCode: data.dataCode,
                deCode: data.deCode,
                value: filterArr
            }
        ];
        let params = {
            formId: formId,
            submit: false,
            values: values
        }
        formAction.saveData(params);
        // props.startTimerFun();
        props.updateFun()
        // saveFormData(params).then((res) => {
        //     props.updateFun()
        // })
    }

    const handleCancel = () => {
        setPreviewVisible(false);
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 2, paddingLeft: 4, paddingRight: 4 }}>提示：请从小程序端拍照上传</div>
        </div>
    );

    useImperativeHandle(ref, () => ({
        // changeVal 就是暴露给父组件的方法
        getModifyValue: () => {
            let filterArr = [];
            for (let item of updatedValue) {
                if (item.url) {
                    filterArr.push(item.url);
                }
            }
            let params = {
                dataCode: data.dataCode,
                deCode: data.deCode,
                formId: formId,
                value: filterArr,
            }
            return params;
        },
    }));


    const removeChange = (file: any) => {
        let _fileList = fileList.filter((v: any) => v.uid !== file.uid)
        setValue(_fileList)
        setFileList(_fileList)
        if (isUpdate === true) {
            setUpdatedValue(_fileList);
        }
        else {
            saveData(_fileList);
        }
    }

    return (
        <div className="photoCom">
            {
                <div>
                    <Upload
                        // action={`${BASE_URL}data/data/image`} // 之前的上传接口
                        // action={`${BASE_URL}file/image/upload`} // 脱敏图片上传接口
                        method="POST"
                        accept="image/*"
                        // @ts-ignore
                        headers={utils.getHeaders(extraHeader)}
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        title="请从小程序端拍照上传材料！"
                        onRemove={removeChange}
                        disabled
                    >
                        {value.length >= 30 ? null : uploadButton}
                    </Upload>

                    <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        // zIndex={99999}
                        onCancel={handleCancel}
                    >
                        {/* <img alt="img" style={{ width: '100%' }} src={previewImage} /> */}

                        <ConfigProvider locale={zh_CN}>
                            {/* <Image
                                width={'100%'}
                                src={previewImage}
                            /> */}

                            <Image
                                width={'100%'}
                                src={previewImage}
                                preview={{ visible: false }}
                                onClick={() => setVisible(true)}
                            />

                            <div style={{ display: 'none' }}>
                                <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
                                    <Image src={previewImage} />
                                    {
                                        fileList && fileList.length > 0 && fileList.map((file: any) => (
                                            previewImage != file.url && <Image src={file.url} />
                                        ))
                                    }
                                </Image.PreviewGroup>
                            </div>
                        </ConfigProvider>
                    </Modal>
                </div>
            }
        </div>
    )
}

export default forwardRef(CameraCom);
