import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import './codeSetEdit.less'
import {Button, Form, Input, Select, message} from "antd";
import {createFromIconfontCN} from "@ant-design/icons";
import {GetDataCodesetModify, GetDataCodesetInfo} from '../../../../services/config/ConfigService'
import {clearThreeTitle} from '../../../../util/menuModuleUtil';

const layout = {
    labelCol: {
        span: 7,
    },
    wrapperCol: {
        span: 17,
    },
};
const {Option} = Select
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

class codeSetEdit extends Component {

    constructor(props) {
        super(props);
    }

    formRef = React.createRef();

    componentWillUnmount() {
        clearThreeTitle()
    }

    componentDidMount() {
        this.getDataCodesetInfo()
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName}/>) : undefined;
    }

    /**
     * 详情
     */
    getDataCodesetInfo = () => {
        GetDataCodesetInfo(this.props.location.state.id).then(res => {
            if (this.formRef && this.formRef.current) {
                this.formRef.current.setFieldsValue({
                    name: res.name,
                    code: res.code,
                    useScope: res.useScope,
                    type: res.type,
                    state: res.state,
                    /*version: res.version*/
                })
            }
        })
    }

    /**
     * 添加 提交表单
     */
    handleFinish = (values) => {
        values.name = values.name.trim();
        /*values.code = values.code.trim();*/
        values.useScope = values.useScope.trim();
        /*values.version = values.version.trim();*/
        values.id = this.props.location.state.id
        GetDataCodesetModify(values).then((res) => {
            if (res.code == '0') {
                message.success('修改成功！')
                this.props.history.push('/config/codeSet')
            }
        })
    }

    render() {
        return (
            <div className={'codeSetEdit'}>
                <div className={'codeSetAddCon'}>
                    <div className={'codeSetAdd_title'}>修改数据编码集</div>
                    <div className={'codeSetAdd_con'}>
                        <Form
                            {...layout}
                            name="nest-messages"
                            onFinish={this.handleFinish}
                            requiredMark={true}
                            ref={this.formRef}
                        >
                            <Form.Item
                                label="数据集名称"
                                name='name'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入数据集名称',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input
                                    style={{width: 468, marginLeft: 6}}
                                    placeholder="请输入数据集名称"
                                />
                            </Form.Item>

                            {/* <Form.Item
                                label="数据集编码"
                                name='code'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入数据集编码',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input
                                    style={{width: 468, marginLeft: 6}}
                                    placeholder="请输入数据集编码"
                                />
                            </Form.Item>*/}

                            <Form.Item
                                label="使用范围"
                                name='useScope'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择使用范围',
                                    }
                                ]}
                            >
                                <Select
                                    onChange={this.handleChange}
                                    placeholder='请选择使用范围'
                                    style={{width: 468, marginLeft: 6}}
                                >
                                    {
                                        this.props.useScopeSelectList && this.props.useScopeSelectList.length != 0 ?
                                            this.props.useScopeSelectList.map(v => (
                                                <Option value={v.get('code')}
                                                        key={v.get('code')}>{v.get('name')}</Option>
                                            )) : ''
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="类型"
                                name='type'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择类型',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='请选择类型'
                                    style={{width: 468, marginLeft: 6}}
                                >
                                    {
                                        this.props.typeSelectList && this.props.typeSelectList.length != 0 ?
                                            this.props.typeSelectList.map(v => (
                                                <Option value={v.get('code')}
                                                        key={v.get('code')}>{v.get('name')}</Option>
                                            )) : ''
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="状态"
                                name='state'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择状态!',
                                    },
                                ]}
                            >
                                <Select
                                    onChange={this.handleChange}
                                    placeholder='请选择状态'
                                    style={{width: 468, marginLeft: 6}}
                                >
                                    {
                                        this.props.typeSelectList && this.props.typeSelectList.length != 0 ?
                                            this.props.stateSelectList.map(v => (
                                                <Option value={v.get('code')}
                                                        key={v.get('code')}>{v.get('name')}</Option>
                                            )) : ''
                                    }
                                </Select>
                            </Form.Item>

                            {/*<Form.Item
                                label="版本号"
                                name='version'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入版本号',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input
                                    style={{width: 468, marginLeft: 6}}
                                    placeholder="请输入版本号"
                                />
                            </Form.Item>*/}

                            <Form.Item
                                wrapperCol={{span: 17, offset: 7}}
                                style={{marginBottom: 0}}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{marginLeft: 410}}
                                >
                                    保存
                                </Button>
                            </Form.Item>

                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    stateSelectList: state.getIn(['config', 'codesetStateSelectList']),
    typeSelectList: state.getIn(['config', 'codesetTypeSelectList']),
    useScopeSelectList: state.getIn(['config', 'codesetUseScopeSelectList']),
})

const mapDispatchToProps = (dispatch) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(codeSetEdit);