import React from "react"
import locale from "antd/es/date-picker/locale/zh_CN"
import { Button, Col, DatePicker, Form, Input, Row, Select, Dropdown, Menu } from "antd"
import { useEffect, useState } from "react"

import modStyl from './Condition.module.scss'
import { Moment } from "moment"
import { GetCenterByProjectId, GetProject } from "../../../../services/data/DataService"
import utils from "../../../../util/utils"
import { visitStatusDict } from "./DictTransform"

export type Params = {
  projectCode: string;
  centerCode: string;
  subjectCode: string;
  adminUser: string;
  visitStatus: number;
  startTime: string;
  endTime: string;
}

type ConditionProps = {
  onSearch: (params: Params)=> void
}

type SelectOption = {
  label: string;
  value: string;
}

const Condition: React.FC<ConditionProps> = (props)=> {
  const [projectCode, setProjectCode] = useState<string>()
  const [centerCode, setCenterCode] = useState<string>()
  const [subjectCode, setSubjectCode] = useState('')
  const [visitStatus, setVisitStatus] = useState(0)
  const [adminUser, setAdminUser] = useState('')
  const [betweenAt, setBetweenAt] = useState<string[]>([])
  const [betweenAtMonent, setBetweenAtMonent] = useState<[Moment, Moment]>()

  // 所属项目
  const [projs, setProjs] = useState<SelectOption[]>([])
  // 所属中心
  const [centers, setCenters] = useState<SelectOption[]>([])

  // 项目列表
  type ProjectItem = {
    id: number;
    code: string;
    name: string;
  }
  type ProjectResult = {
    code: number;
    message?: string;
    data: ProjectItem[];
  }
  const fetchProjs = async ()=> {
    const { code, data }: ProjectResult = await GetProject()
    if(code === 0) {
      if(data.length === 1) {
        const {id, code} = data[0]
        setProjectCode(code)

        fetchCenters(id, (cCode: string)=> {
          props.onSearch(
            makeParams({
              projectCode: code,
              centerCode: cCode
            })
          )
        })

      }
      setProjs(
        data.map(({name, code})=> ({
          label: name,
          value: code
        }))
      )
    }
  }

  // 中心列表
  type  CenterResult = {
    code: number;
    message?: string;
    data: {
      code: string;
      name: string;
    }[];
  }
  const fetchCenters = async (id: number, callback: (code: string)=> void)=> {
    const { code, data }: CenterResult = await GetCenterByProjectId(id)
    if(code === 0) {
      if(data.length === 1) {
        const { code } = data[0]
        setCenterCode(code)
        callback(code)
      }
      setCenters(
        data.map(({code, name})=> ({
          label: name,
          value: code
        }))
      )
    }
  }

  const rangePickerControl = (raw: any, between: [string, string])=> {
    setBetweenAt(between)
    setBetweenAtMonent(raw)
  }

  const makeParams = (init={} as Partial<Params>)=> {
    const [startTime, endTime] = betweenAt
    return {
      visitStatus,
      projectCode: projectCode ?? '',
      centerCode: centerCode ?? '',
      subjectCode,
      adminUser,
      startTime,
      endTime,
      ...init
    }
  }

  const submitControl = ()=> {
    props.onSearch(makeParams())
  }

  const resetControl = ()=> {
    const projCode = projs.length === 1 ? projs[0]['value'] : undefined
    const centCode = centers.length === 1 ? centers[0]['value'] : undefined

    setCenterCode(centCode)
    setProjectCode(projCode)
    setVisitStatus(0)
    setBetweenAt([])
    setBetweenAtMonent(undefined)
    setAdminUser('')
    setSubjectCode('')

    props.onSearch({
      visitStatus: 0,
      projectCode: projCode ?? '',
      centerCode: centCode ?? '',
      subjectCode: '',
      adminUser: '',
      startTime: '',
      endTime: '',
    })
  }

  useEffect(()=> {
    fetchProjs()
  }, [])

  const colCols = {
    span: 6
  }
  const fieldCols = {
    labelCol: {
      span: 5
    },
    wrapperCol: {
      span: 19
    }
  }
  const exportSMSControl = ()=> {
    utils.exportPostFun('/data/visitTask/exportMsg', makeParams(), '导出自动短信.xls')
  }
  const exportControl = ()=> {
    utils.exportPostFun('data/visitTask/exportTask', makeParams())
  }

  return (
    <div className={modStyl.wrapper}>
      <Form>
        <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="所属项目">
              <Select onChange={(value)=> setProjectCode(value)} value={projectCode} options={projs} placeholder="请选择" showSearch allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="所属中心">
              <Select onChange={(value)=> setCenterCode(value)} value={centerCode} options={centers} placeholder="请选择" showSearch allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="　采集人">
              <Input onChange={({target})=> setAdminUser(target.value)} value={adminUser} placeholder="请输入采集人" allowClear/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="　患者">
              <Input onChange={({target})=> setSubjectCode(target.value)} value={subjectCode} placeholder="请输入患者姓名缩写/编号查找" allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="随访状态">
              <Select onChange={(value)=> setVisitStatus(value)} value={visitStatus} options={[{value: '', label: '全部'}, ...visitStatusDict]} placeholder="请选择" allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="随访时间">
              <DatePicker.RangePicker style={{width: '100%'}} onChange={rangePickerControl} value={betweenAtMonent} locale={locale} allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Button onClick={submitControl} type="primary">搜索</Button>
            <Button onClick={resetControl} style={{marginLeft: 10}}>重置</Button>
            <Button onClick={exportControl} type="link" style={{marginLeft: 10, padding: '4px 0'}}>导出数据</Button>
            <Button onClick={exportSMSControl} type="link" style={{marginLeft: 10, padding: '4px 0'}}>导出自动短信</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Condition
