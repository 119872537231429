import { Button, Col, Empty, Row, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { ColumnsType } from "antd/lib/table";

import modStyl from './index.module.scss'
import CreateModal from "./components/create-modal";
import { getMenuEnum, getMenuNodes } from "../../../services/system/menu";
import { createFromIconfontCN } from "@ant-design/icons";
import ViewModal from "./components/view-modal";
import EditModal from "./components/edit-modal";

const IconFont = createFromIconfontCN({
  scriptUrl: [
    '//at.alicdn.com/t/c/font_2343612_2ojyi94p9ix.js',
  ],
});

export type DataType = {
  code: string
  config: string
  icon: string
  method: string
  name: string
  appCode: string
  parentCode: string
  type: string
  url: string
  children: DataType[]
}

type Props = {

}
const MenuResource: React.FC<Props> = ()=> {

  const [loading, setLoading] = useState(false)

  const [dataSource, setDataSource] = useState<any[]>([])

  const [visible, setVisible] = useState(-1)

  const [current, setCurrent] = useState({} as DataType)

  const menuTypes = useRef<Record<string, string>>({})

  //#region 表格列
  const columns: ColumnsType<DataType> = [
    {
      width: 200,
      title: '菜单名称',
      dataIndex: 'name',
      render: (_, record)=> <span>{record.icon && <IconFont type={record.icon} style={{width: 20}}/>}{record.name}</span>
    },
    {
      width: 200,
      title: '菜单类型',
      dataIndex: 'type',
      render: (value)=> menuTypes.current[value] || '-'
    },
    {
      title: '地址',
      dataIndex: 'url',
    },
    {
      width: 220,
      title: '操作',
      align: 'center',
      dataIndex: 'code',
      render(value: any, record: DataType) {
        return (
          <>
          <Button onClick={()=> openViewModal(record)} size="small">查看</Button>
          <Button onClick={()=> openEditModal(record)} size="small" style={{margin: '0 8px'}}>编辑</Button>
          <Button onClick={()=> openCreateModal(record)} type="primary" size="small">创建子菜单</Button>
          </>
        )
      }
    },
  ];
  //#endregion

  const fetchPaginate = async ()=> {
    setLoading(true)
    const { allResource } = await getMenuNodes()
    setLoading(false)
    setDataSource(allResource)
  }

  const fetchMenuType = async(callback: ()=> void)=> {
    const data = await getMenuEnum()
    data.forEach(({key, text}: any)=> {
      menuTypes.current[key] = text
    })
    callback()
  }

  const openViewModal = (value: DataType)=> {
    setCurrent(value)
    setVisible(0)
  }
  
  const openEditModal = (value: DataType)=> {
    setCurrent(value)
    setVisible(1)
  }

  const openCreateModal = (value: DataType)=> {
    setCurrent(value)
    setVisible(2)
  }

  useEffect(()=> {
    fetchMenuType(()=> {
      fetchPaginate()
    })
  }, [])

  return (
    <div className={modStyl.wrapper}>
      <div className={modStyl.condition}>
        <Row gutter={16}>
          <Col span={21}></Col>
          <Col span={3} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button onClick={()=> openCreateModal({code: '000'} as DataType)} type="primary">创建菜单</Button>
          </Col>
        </Row>
      </div>
      <Table
        expandable={{defaultExpandAllRows: true}}
        loading={loading}
        size="middle"
        rowKey='code'
        scroll={{y: window.innerHeight - 250}}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: 100,
          hideOnSinglePage: true
        }}
        locale={{emptyText: <Empty description="暂无数据"/>}}/>

        {/* 查看 */}
        {
          visible === 0 && (
            <ViewModal dataSource={dataSource} types={menuTypes.current} data={current} onClose={()=> setVisible(-1)}/>
          )
        }

        {/* 编辑 */}
        {
          visible === 1 && (
            <EditModal data={current} onClose={(refresh)=> {
              if (refresh) {
                fetchPaginate()
              }
              setVisible(-1)
            }}/>
          )
        }

        {/* 新增菜单 */}
        {
          visible === 2 && (
            <CreateModal parentCode={current.code} onClose={(refresh)=> {
              if (refresh) {
                fetchPaginate()
              }
              setVisible(-1)
            }}/>
          )
        }
        
    </div>
  )
}

export default MenuResource