import { Button, Col, DatePicker, Empty, Form, Input, InputRef, message, Modal, Row, Select, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import locale from "antd/es/date-picker/locale/zh_CN";

import modStyl from './index.module.scss'
import { GetDataSourceList, GetDownLoadUrl } from "../../services/download/DownloadService";
import CreateModal from "./components/create-modal";
import CreateBatchModal from "./components/create-batch-modal";
import { getTenantProjectList } from "../../services/cdms/data-source";

type DataType = {
  dataAmount: number
  downloadState: number
  finishCount: number
  finishTime: string
  id: number
  leftTime: string
  percent: string
  planCode: null | string
  planVersion: null | string
  projectCode: string
  projectName: string
  redisKey: string
  startTime: string
  subjectEndTime: null | string
  subjectStartTime: null | string
  taskName: string
}

type Props = {

}
const Download: React.FC<Props> = ()=> {

  const [pageSize] = useState(20)
  const [pageNum, setPageNum] = useState(1)
  
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const [projects, setProjects] = useState<any[]>([])

  const [dataSource, setDataSource] = useState<any[]>([])

  const [visible, setVisible] = useState(false)
  const [batchVisible, setBatchVisible] = useState(false)

  const passwordRef = useRef<InputRef>(null)

  const conditionRef = useRef({
    projectCode: '',
    startDate: '',
    endDate: '',
  })

  const dlDoc = (id: number)=> {
    Modal.confirm({
      title: '验证密码',
      content: (
        <Input.Password ref={passwordRef} placeholder="请输入超级密码" />
      ),
      cancelText: '取消',
      okText: '开始下载',
      onOk: async (e)=> {
        return new Promise(async (resolve, reject)=> {
          if (!passwordRef.current) {
            reject()
            return
          }
          const passWord = passwordRef.current.input?.value

          if (!passWord) {
            message.error('请输入超级密码')
            reject()
            return
          }

          const { code,data } = await GetDownLoadUrl({
            id,
            passWord,
          })

          if (code != 0) {
            reject()
            return
          }

          resolve(0)

          const el = document.createElement('a');
          if ('download' in el) {
            el.style.display = 'none';
            el.href = data;
            document.body.appendChild(el);
            // 触发链接
            el.click();
            URL.revokeObjectURL(el.href);
            document.body.removeChild(el)
          }
        })
      },
      onCancel: ()=> {
      }
    })
  }

  //#region 表格列
  const columns: ColumnsType<DataType> = [
    {
      title: '任务名称',
      dataIndex: 'taskName'
    },
    {
      title: '项目名称',
      dataIndex: 'projectName'
    },
    {
      width: 150,
      title: '开始时间',
      align: 'center',
      dataIndex: 'startTime',
      render: (value) => value ? value.replace('T', ' ') : '--'
    },
    {
      width: 150,
      title: '完成时间',
      align: 'center',
      dataIndex: 'finishTime',
      render: (value) => {
        if (value === 'null') {
          return '--'
        }
        return value ? value.replace('T', ' ') : '--'
      }
    },
    {
      width: 100,
      title: '状态',
      align: 'center',
      dataIndex: 'downloadState',
      render: (value) => <span style={{color: value === 0 ? '#39f' : '#333'}}>{value === 0 ? '下载中' : '已完成'}</span>
    },
    {
      width: 100,
      title: '数据量',
      align: 'right',
      dataIndex: 'dataAmount',
    },
    {
      width: 100,
      title: '进度',
      align: 'center',
      dataIndex: 'percent',
    },
    {
      width: 100,
      title: '剩余时间',
      align: 'center',
      dataIndex: 'leftTime',
    },
    {
      width: 100,
      title: '操作',
      align: 'center',
      dataIndex: 'id',
      render(value: number, record: DataType) {
        if (record.downloadState === 0) {
          return null
        }
        return (
          <Button onClick={()=> dlDoc(record.id)} type="primary" size="small">下载数据</Button>
        )
      }
    },
  ];
  //#endregion

  const fetchPaginate = async (page: boolean | number)=> {
    setLoading(true)
    const { data } = await GetDataSourceList({
      ...conditionRef.current,
      page: typeof page === 'boolean' ? 1 : page,
      pageSize, 
    })
    setLoading(false)
    setTotal(data.total)
    setDataSource(data.rows)

    if (typeof page === 'boolean') {
      setPageNum(1)
    }
  }

  const fetchProject = async ()=> {
    const { data } = await getTenantProjectList()
    setProjects(data.map(({name, code}: any)=> ({
      label: name,
      value: code,
    })))
  }

  const dateChange = (e: any, date: string)=> {
    conditionRef.current.startDate = date
    conditionRef.current.endDate = date
    fetchPaginate(true)
  }

  const projectChange = (projectCode: string)=> {
    conditionRef.current.projectCode = projectCode
    fetchPaginate(true)
  }

  useEffect(()=> {
    sessionStorage.setItem('dataSourceDownload', 'true');
    
    fetchProject()
    fetchPaginate(true)

    return ()=> {
      sessionStorage.removeItem('dataSourceDownload');
    }
  }, [])

  return (
    <div className={modStyl.wrapper}>
      <div className={modStyl.condition}>
        <Row gutter={16}>
          <Col span={12}></Col>
          <Col span={3}>
            <DatePicker locale={locale} onChange={dateChange} style={{width: '100%'}}/>
          </Col>
          <Col span={3}>
            <Select filterOption={(value, option)=> option.label.includes(value)} onChange={projectChange} options={projects} placeholder="请选择" style={{width: '100%'}} showSearch allowClear/>
          </Col>
          <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button onClick={()=> setVisible(true)} type="primary">创建下载任务</Button>
            <Button onClick={()=> setBatchVisible(true)} type="primary" style={{marginLeft: 16}}>创建批量下载任务</Button>
          </Col>
        </Row>
      </div>
      <Table
        loading={loading}
        size="middle"
        rowKey='id'
        scroll={{y: window.innerHeight - 250}}
        columns={columns}
        dataSource={dataSource}
        locale={{emptyText: <Empty description="暂无数据"/>}}
        pagination={{
          onChange: (page: number)=> {
            setPageNum(page)
            fetchPaginate(page)
          },
          showTotal: total=> `共${total}条，每页${pageSize}条`,
          style: {marginBottom: 0},
          total: total,
          current: pageNum,
          pageSize: pageSize,
          hideOnSinglePage: true,
          showSizeChanger: false,
          showQuickJumper: false,
          position: ["bottomRight"]}
        }/>
      
      {
        visible && (<CreateModal onClose={(refresh)=> {
          if (refresh) {
            fetchPaginate(true)
          }
          setVisible(false)
        }}/>)
      }
      {
        batchVisible && (<CreateBatchModal onClose={(refresh)=> {
          if (refresh) {
            fetchPaginate(true)
          }
          setBatchVisible(false)
        }}/>)
      }
    </div>
  )
}

export default Download