import React from "react";
import { Button, Upload, message as messager } from "antd";
import { PictureFilled } from "@ant-design/icons";
import alioss from "ali-oss";
import { makeUploadHeaders, makeUploadName, makeUploadOptions } from "../crf_form/components/ocr-autofill/utils";

type AliUploadProps = {
  disabled?: boolean;
  onUpload: (url: string)=> void;
}

const AliUpload = (props: AliUploadProps) => {
  // 第1步：上传图片
  const startUpload = async (file: File) => {
    const pieces = await makeUploadOptions(messager.error);

    if (!pieces) {
      messager.error("初始化上传参数失败");
      return;
    }

    const [prefix, options] = pieces;

    const client = new alioss(options);

    const pathname = makeUploadName(prefix, file);

    if (!pathname) {
      messager.error("上传缺少必要参数");
      return;
    }

    // 上传图片到OSS
    const result = await client.put(pathname, file, {
      timeout: 600 * 1000,
      headers: makeUploadHeaders(0),
    });

    if (result.res.status !== 200) {
      messager.error("上传失败，请重试！");
      return
    }

    props.onUpload(result.url)
  }

  // changeVal 就是暴露给父组件的方法
  const customRequest = async (options: any) => {
    const key = 'aliyun-loading';
    try {
      messager.loading({
        content: '正在上传...',
        duration: 0,
        key
      })
      await startUpload(options.file);
    } catch (error) {
      console.log('?aliupload', error)
    } finally {
      setTimeout(() => messager.destroy(key), 100);
    }
  };

  const beforeUploadControl = (file: File) => {
    const [_, ext] = file.type.split('/')
    if(!['.png', '.jpg', '.jpeg'].includes(`.${ext}`)) {
      messager.error('上传格式不允许')
      return false
    }
  };

  return (
    <Upload
      accept=".png, .jpg, .jpeg"
      maxCount={20}
      showUploadList={false}
      customRequest={customRequest}
      beforeUpload={beforeUploadControl}
      disabled={props.disabled}
    >
      <Button disabled={props.disabled} icon={<PictureFilled />}>选择图片</Button>
    </Upload>
  );
};

export default AliUpload;
