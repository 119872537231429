import React, { Component, Fragment } from 'react';
import 'antd/dist/antd.css';
import './style/App.less';

import {
    Layout
} from 'antd';

import AppHeader from './components/plat/header/Header';
import MenuNav from './components/plat/nav/MenuNav';
import AppLogin from './components/plat/login/Login';
import SettlementEvidence from './components/plat/settlementEvidence';
import SafeLogin from './components/plat/login/safeLogin';
import AppLoading from './components/plat/loading/loading';
import AppRegister from './components/plat/register/register';
import { RootRouter } from "./routes/Router";
import CrumbsNav from './components/plat/nav/CrumbsNav';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PubSub from 'pubsub-js';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
const { Content } = Layout;
let _this;
let sesioonMenuWidth = sessionStorage.getItem('menuWidth');
class App extends Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            view: 'login',       // 当前页面状态
            // menuWidth: '200px'
            menuWidth: !sesioonMenuWidth ? '200px' : sesioonMenuWidth+'px',
        };
    }

    componentDidMount() {
        _this.onLoginStatusChange();
        _this.onRegisStatusChange();
        _this.checkLogin();
        _this.initLoginPage();
    }

    /**
     * 设置初始登录页(刷新，token过期等返回至于初始登录页)
     */
    initLoginPage = () => {
        sessionStorage.setItem('LoginPathName', window.location.pathname);  // /login , /safeLogin
    }

    /**
     * 检查是否登录
     */
    checkLogin = () => {
        if (sessionStorage.getItem('token') != null) {
            PubSub.publish('onLoginSuccess');
        }
    }

    /**
     * 登录状态改变
     */
    handleLoginStateChange = (state) => {
        _this.setState({
            view: state
        });
    }
    onLoginStatusChange = () => {
        PubSub.subscribe("onLoginSuccess", () => {
            _this.handleLoginStateChange('intoApp')
        })
        PubSub.subscribe("onLogout", () => {
            _this.handleLoginStateChange('login')
        })
    }

    /**
     * 注册状态改变
     */
    handleRegisterStateChange = (state) => {
        this.setState({
            view: state
        });
    }
    onRegisStatusChange = () => {
        PubSub.subscribe("goRegister", () => {
            _this.handleLoginStateChange('register')
        })

        PubSub.subscribe("onRegisterSuccess", () => {
            _this.handleLoginStateChange('login')
        })
    }

    // 获取左侧菜单栏的宽度
    getWidthVal = (v) => {
        if (v) {
            this.setState({
                menuWidth: v.menuWidth
            })
        }
    }
    getMouseUpVal = (v) => {
        if (v) {
            this.setState({
                menuWidth: `${v.menuWidth}px`
            })
        }
    }
    render() {
        const isLogin = sessionStorage.getItem('token') || '';
        const { view, menuWidth } = _this.state;
        const pathName = sessionStorage.getItem('LoginPathName') || window.location.pathname; // /login , /safeLogin

        return (
            <Router>
                <Layout className='app'>
                    <AppLoading />
                    <Layout className='omz-fit'>
                        {view == 'intoApp' || isLogin ? (
                            <Fragment>
                                <AppHeader />
                                <MenuNav getValue={(v) => this.getWidthVal(v)} getMouseUpVal={(v) => this.getMouseUpVal(v)}/>
                                <Layout className='content' style={{ width: `calc(100% - ${menuWidth})`, left: `${menuWidth}` }}>
                                    <Content>
                                        {/* {menuWidth} */}
                                        <CrumbsNav></CrumbsNav>
                                        <RootRouter></RootRouter>
                                    </Content>
                                </Layout>
                            </Fragment>
                        ) : (null)}
                        {!isLogin ? (
                            <Switch>
                                <Route exact path="/login" component={AppLogin} />
                                <Route exact path="/settlementLg" component={SettlementEvidence} />
                                {/* <Route exact path="/register" component={AppRegister} /> */}
                                <Route exact path="/safeLogin" component={SafeLogin} />
                                {view == 'intoApp' ? (
                                    <Redirect exact from={'/'} to={{ pathname: `${pathName == '/safeLogin' ? '/safeLogin' : '/login'}` }} />
                                ) : (<Redirect from={'/'} to={{ pathname: `${pathName == '/safeLogin' ? '/safeLogin' : '/login'}` }} />)}
                            </Switch>
                        ) : (null)}
                    </Layout>
                </Layout>
            </Router>
        );
    }
}

export default App;
