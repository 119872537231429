/**
 * 图片列表
 */
import React, { Component } from 'react';
import './index.less';
import { Button, message, Row, Col, Switch, List, Modal, Checkbox, Spin } from 'antd';
import {
    ExclamationCircleOutlined, QuestionCircleTwoTone, CheckCircleTwoTone,
    CoffeeOutlined, FundProjectionScreenOutlined
} from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { GetDctApprovalOperation, CancelDctApprovalOperation } from "../../../../../services/dct/DctService";
import axios from 'axios';

const CheckboxGroup = Checkbox.Group;

let _this;
const itemChecked = {
    border: '1px solid rgb(10, 88, 255)',
    borderRadius: '3px',
    background: 'rgba(77, 136, 245, .2)'
}
const work = {
    background: '#1890ff',
    color: '#fff',
}
const noWork = {
    background: '#fff',
    color: '#1890ff',
}

class CheckListComponent extends Component {

    constructor(props) {
        super(props);
        _this = this;
        this.state = {
            isBatch: false, // 批量选择
            isSpin: false,
            isPass: false, // 通过or取消通过
            passStatus: null,
        };
    }

    componentDidMount() {

    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {

        if (nextProps) {
            let { isSpin, passStatus, isBatch } = nextProps
            this.setState({
                passStatus,
                isSpin,
                isBatch
            })
        }
    }

    // 通过/质疑
    passOperation = async (params) => {
        const res = await GetDctApprovalOperation(params);
        if (res && res.code == 0) {
            message.success(res.message)
            this.setState({
                isSpin: false,
                isBatch: false
            })
            _this.props.getValue(true)
        }
    }

    // 全选/批量选择
    onCheckAll = (value) => {
        this.props.onCheckAll(value)
        this.setState({
            isSpin: false,
            isBatch: !this.state.isBatch
        })
    };

    // 一键通过
    onKeyPass = () => {
        this.setState({
            isSpin: true
        })
        this.props.aKeyBy()
    }
    // 通过or取消通过
    onPassOrNo = (value) => {

        if (value == 'AGREE') {
            // 取消通过
            this.setState({
                isPass: true,
                // isSpin: true,
            })
            this.props.onChecked()
        } else {
            this.setState({
                isPass: false,
                // isSpin: true,
            })
            // 通过
            this.props.onChecked('AGREE')
        }
    }

    // 质疑
    onQuery = () => {
        this.props.onChecked('REJECT')
    }
    submitTuoMinPicture(tuoMinImgMap, imgUrl, imgList) {
        if (!tuoMinImgMap) {
            return;
        }
        tuoMinImgMap = JSON.parse(tuoMinImgMap);
        if (imgList && imgList.length > 0) {
            // 一键通过
            for (const key in tuoMinImgMap) {
                if (Object.hasOwnProperty.call(tuoMinImgMap, key)) {
                    const element = tuoMinImgMap[key];
                    const areaList = element.areaList.filter(val => !val.drawOnlyId );
                    this.submitTuoMin({
                        imgUrl: key,
                        imgData: element.imgData,
                        areaList: areaList,
                    });
                }
            }
            sessionStorage.removeItem('CheckTuoMinImgMap');
        } else {
            // 其他，单张脱敏
            const curImgInfo = tuoMinImgMap[imgUrl];
            // 切换图片对应问题
            if (!curImgInfo) {
                return;
            }
            const areaList = curImgInfo.areaList.filter(val => !val.drawOnlyId );
            curImgInfo && this.submitTuoMin({
                imgUrl: imgUrl,
                imgData: curImgInfo.imgData,
                areaList: areaList,
            });
        }
    }
    submitTuoMin(param) {
        const _imageData = param.imgData;
        if (!param.areaList) {
            return;
        }
        if (param.areaList && param.areaList.length === 0) {
            return;
        }
        const postData = {
          "areaList": param.areaList,
          "fileUrl": param.imgUrl,
          "formId": _imageData.formId,
          "platform": 0, // 1为h5, 0为pc
          "subjectCode": _imageData.subjectCode
        }
        axios.post("/file/image/desensitizate", postData);
    }
    tuoMin = (imgUrl, imgList) => {
        let tuoMinImgMap = sessionStorage.getItem('CheckTuoMinImgMap');
        if (!tuoMinImgMap) {
            return;
        }
        this.submitTuoMinPicture(tuoMinImgMap, imgUrl, imgList);
    }

    render() {
        let { isPass, passStatus, isBatch, isSpin } = this.state;
        return (
            <div className="btns-wrap">
                <Button type={'link'} onClick={() => this.onCheckAll(isBatch)}>{isBatch ? '全部取消' : '批量选择'}</Button>
                <div className="primary-wrap">
                    {/* 批量选择不需要质疑 */}
                    <Button type={'primary'} danger onClick={() => this.onQuery()} hidden={passStatus == 'AGREE' || isBatch}>质疑</Button>
                    <Button type={'primary'} onClick={() => this.onKeyPass(isBatch)} hidden={!isBatch} disabled={isSpin} >一键通过</Button>
                    <Button type={'primary'} onClick={() => this.onPassOrNo(passStatus)} hidden={isBatch} disabled={isSpin} >{passStatus == 'AGREE' ? '取消通过' : '通过'}</Button>
                </div>
            </div>
        )
    }
}
export default CheckListComponent