/**
 * 源数据下载
 */
 import { requestPostFile, requestPost, requestGet, requestPostRes, requestGetRes, requestPatchRes } from '../EdcService';

 const urlPrefix = "/cdms/customerData";

// 客户数据数任务列表 
export const GetDataTaskList = (params) => {
    const url = `${urlPrefix}/dataTaskList`;
    return requestPostRes(url, params)
};

// 获取下载链接
export const GetDownLoadUrl = (params) => {
    const url = `${urlPrefix}/getUrl`;
    return requestGetRes(url, params)
};

// 创建下载任务 
export const createDataTask = (params) => {
    const url = `${urlPrefix}/createTask`;
    return requestPostRes(url, params)
};

// 项目列表枚举
export const GetListEnume = (params) => {
    const url = `${urlPrefix}/tenant/process/list`;
    return requestGet(url, params)
};




// 数据清理任务列表 
export const GetDataClearTaskList = (params) => {
    const url = `/data/clearTaskList`;
    return requestPostRes(url, params)
};


// 创建清理数据任务 
export const createClearTask = (params) => {
    const url = `/data/createClearTask`;
    return requestPostRes(url, params)
};

//下载结算申请单
export const downloadSettlement = (params) => {
    const url = `/settlement/billmain/down/excel/${params.billId}`;
    return requestGetRes(url);
};