import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react';
import { createFromIconfontCN, ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './ruleDetail.module.scss';
import { Button, Col, Form, Input, InputNumber, Modal, Radio, Row, Select, Tooltip } from 'antd';
import { Tree } from 'antd';
import { AddDirectory, DeleteDirectory, GetDeCodeList, GetEditRuleInfo, GetRuleById, GetRuleConfigSelects, ModifyDirectory, ModifyRecomVersion, ModifyRule, SaveRule, SearchRule, SubmitRule } from '../../../../services/config/ConfigService';
import { message } from 'antd';

const { DirectoryTree } = Tree;
const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2343612_p3rtqwp0d98.js',
    ],
});

function RuleDetail(props: any, ref: any) {
    //保存目录名称loading状态
    const [saveFolderLoading, setSaveFolderLoading] = useState<boolean>(false);
    //修改目录名称loading状态
    const [modifyFolderLoading, setModifyFolderLoading] = useState<boolean>(false);
    //保存脱敏数据名称loading状态
    const [saveFileLoading, setSaveFileLoading] = useState<boolean>(false);
    //修改脱敏数据名称loading状态
    const [modifyFileLoading, setModifyFileLoading] = useState<boolean>(false);
    //保存规则loading状态
    const [saveRuleLoading, setSaveRuleLoading] = useState<boolean>(false);
    //修改规则loading状态
    const [modifyRuleLoading, setModifyRuleLoading] = useState<boolean>(false);
    //发布规则loading状态
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    //设置是否为第一次状态
    const [isFirst, setIsFirst] = useState<boolean>(true);
    //脱敏规则详情
    const [ruleInfo, setRuleInfo] = useState<any>();
    //ruleElementList
    const [ruleElementList, setRuleElementList] = useState<any>([]);
    //版本list
    const [versionList, setVersionList] = useState<any>([]);
    //当前规则configurationId
    const [configurationId, setConfigurationId] = useState<string>();
    //推荐版本
    const [versionRecom, setVersionRecom] = useState<any>();
    //treeData
    const [treeData, setTreeData] = useState<any>([]);
    //当前选中的节点
    const [selectedNode, setSelectedNode] = useState<any>();
    //指定展开的树节点
    const [expandedKeys, setExpandedKeys] = useState<any>();
    //选中的树节点
    const [selectedKeys, setSelectedKeys] = useState<any>();
    //当前操作名称
    const [currentAction, setCurrentAction] = useState<any>();
    //当前操作tab选中的key
    const [currentTab, setCurrentTab] = useState<any>('addFolder');
    //新增目录名称
    const [directoryName, setDirectoryName] = useState<any>();
    //新增节点名称
    const [ruleName, setRuleName] = useState<any>();
    //数据元编码
    const [deCode, setDeCode] = useState<any>();
    //数据元名称
    const [deCodeName, setDeCodeName] = useState<any>();
    //数据安全性
    const [security, setSecurity] = useState<any>('high');
    //数据安全性枚举list
    const [securityList, setSecurityList] = useState<any>([]);
    //数据可用性
    const [usability, setUsability] = useState<any>('low');
    //数据可用性枚举列表
    const [usabilityList, setUsabilityList] = useState<any>([]);
    //脱敏规则
    const [rule, setRule] = useState<any>();
    //脱敏规则枚举列表
    const [ruleList, setRuleList] = useState<any>([]);
    //脱敏优先级
    const [rulePriority, setRulePriority] = useState<any>();
    //deCode模糊搜索下拉列表
    const [deCodeOptionList, setDeCodeOptionList] = useState<any>([]);
    //当前选中的节点是否为修改状态
    const [currentRuleIsModify, setCurrentRuleIsModify] = useState<boolean>(false);
    //保存后的rule
    const [savedRuleItem, setSavedRuleItem] = useState<any>();
    //发版弹框
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    //发布版本号
    const [versionNum, setVersionNum] = useState<string>();
    //版本描述
    const [describe, setDescribe] = useState<string>();
    //是否为默认版本
    const [isDefault, setIsDefault] = useState<boolean>(false);
    //ruleParams
    const [ruleParams, setRuleParams] = useState<any>([]);

    //lengthmasking 遮盖类型
    const [lengthmaskingType, setLengthmaskingType] = useState<any>();
    //lengthmasking 前n个字符
    const [lengthmaskingBefore, setLengthmaskingBefore] = useState<any>();
    //lengthmasking 后n个字符
    const [lengthmaskingAfter, setLengthmaskingAfter] = useState<any>();

    //rangemasking 遮盖类型
    const [rangemaskingType, setRangemaskingType] = useState<any>();
    //rangemasking 开始位置
    const [rangemaskingStart, setRangemaskingStart] = useState<any>();
    //rangemasking 开始位置
    const [rangemaskingEnd, setRangemaskingEnd] = useState<any>();

    //aes128 密钥
    const [aes128Key, setAes128Key] = useState<any>();
    //aes128 加密初始位置
    const [aes128Start, setAes128Start] = useState<any>();
    //aes128 是否base64
    const [aes128IsBase64, setAes128IsBase64] = useState<any>();

    //aes256 密钥
    const [aes256Key, setAes256Key] = useState<any>();
    //aes256 加密初始位置
    const [aes256Start, setAes256Start] = useState<any>();
    //aes256 是否base64
    const [aes256IsBase64, setAes256IsBase64] = useState<any>();

    //初始化
    useEffect(() => {
        initData();
        setIsFirst(false);
    }, [])

    //初始化数据
    const initData = () => {
        setConfigurationId(props.match.params.id);
        //编辑状态规则详情
        GetEditRuleInfo({ id: props.match.params.id }).then(res => {
            if (res) {
                setRuleInfo(res.header);
                filterRuleData(res.directoryDtos);
                let filterVersionList = res.versionList.sort(compare);
                setVersionList(filterVersionList);
                setVersionRecom(res.header.versionRecom);
                setRuleElementList(res.ruleElementList);
                setDirectoryName('');
                setRuleName('');
            }
        })

        //获取数据安全性 && 数据可用性下拉列表
        GetRuleConfigSelects().then(res => {
            if (res) {
                setSecurityList(res.securitySelectEnumList);
                setSecurity('high');
                setUsabilityList(res.usabilitySelectEnumList);
                setUsability('low');
            }
        })
    }

    //排序函数
    const compare = (x: any, y: any) => {//比较函数
        if (x > y) {
            return -1;
        } else if (x < y) {
            return 1;
        } else {
            return 0;
        }
    }

    //findIndex方法
    const findItem = (data: any, key: any) => {
        let findedItem;
        for (let item of data) {
            if (item.id === key) {
                findedItem = item
            }
            else {
                findItem(item.children, key)
            }
        }
        return findedItem;
    }

    //处理数据
    const filterRuleData = (data: any) => {
        let filterFun = (data: any) => {
            for (let item of data) {
                if (item.children && item.children.length > 0) {
                    let findResult = findItem(data, item.id);
                    if (findResult) {
                        findResult.title = item.name;
                        delete item.name;
                        findResult.key = item.id;
                        delete item.id;
                        findResult.isLeaf = item.isDeCode;
                        delete item.isDeCode;
                        if (item.parentId === 0) {
                            findResult.icon = item.parentId === 0 ? <IconFont type="icondatabase"></IconFont> : <React.Fragment></React.Fragment>;
                        }
                    }
                    filterFun(item.children);
                }
                else {
                    //叶子节点
                    if (item.isDeCode) {
                        let findResult = findItem(data, item.id);
                        if (findResult) {
                            findResult.title = item.name;
                            delete item.name;
                            findResult.key = item.id;
                            delete item.id;
                            findResult.isLeaf = item.isDeCode;
                            delete item.isDeCode;
                            findResult.icon = <IconFont type="iconfile2"></IconFont>;
                        }
                    }
                    //文件夹
                    else {
                        let findResult = findItem(data, item.id);
                        if (findResult) {
                            findResult.title = item.name;
                            delete item.name;
                            findResult.key = item.id;
                            delete item.id;
                            findResult.isLeaf = item.isDeCode;
                            delete item.isDeCode;
                        }
                    }
                }
            }
        }
        filterFun(data);
        setTreeData(data);
        if (isFirst) {
            setDefaultKeys(data);
        }
    }

    //设置默认展开&&默认选中的节点
    const setDefaultKeys = (data: any) => {
        let expandedKeys: any[] = [];
        let selectedKeys: any[] = [];
        let selectedNode;
        if (data && data.length > 0) {
            expandedKeys.push(data[0].key);
            if (data[0].children && data[0].children.length > 0) {
                let judgeIsOut = false;
                let filterData = (dataItem: any) => {
                    for (let item of dataItem) {
                        if (item.children && item.children.length > 0) {
                            if (item.isLeaf) {
                                selectedKeys = [item.key];
                                selectedNode = item;
                                judgeIsOut = true;
                            }
                            else {
                                expandedKeys.push(item.key);
                                filterData(item.children);
                            }
                        }
                        else {
                            if (item.isLeaf) {
                                selectedKeys = [item.key];
                                selectedNode = item;
                                judgeIsOut = true;
                            }
                        }
                        if (judgeIsOut) {
                            return;
                        }
                    }
                }
                filterData(data[0].children);
                setExpandedKeys(expandedKeys);
                setSelectedKeys(selectedKeys);
                setSelectedNode(selectedNode);
                setCurrentAction('setRule');
            }
            else {
                setExpandedKeys([data[0].key]);
                setSelectedKeys([data[0].key]);
                setSelectedNode(data[0]);
                setCurrentAction('folderActionAll');
            }
        }
    }

    useEffect(() => {
        if (selectedKeys) {
            setValueById(selectedKeys);
        }
    }, [ruleElementList])

    //根据选中的节点id设置保存的值
    const setValueById = (key: any) => {
        if (ruleElementList && ruleElementList.length > 0) {
            let findItem = ruleElementList.find((item: any) => {
                return item.directoryId === key[0]
            })
            if (findItem) {
                setDeCode(findItem.deCode);
                setDeCodeName(findItem.name);
                setSecurity(findItem.security);
                setUsability(findItem.usability);
                setRule(findItem.ruleCode);
                setRulePriority(findItem.priorityNum);
                setCurrentRuleIsModify(true);
                setSavedRuleItem(findItem);
                if (findItem.ruleParams) {
                    switch (findItem.ruleCode) {
                        case 'lengthmasking':
                            setLengthmaskingType(findItem.ruleParams.type);
                            setLengthmaskingBefore(findItem.ruleParams.n);
                            setLengthmaskingAfter(findItem.ruleParams.m);
                            break;
                        case 'rangemasking':
                            setRangemaskingType(findItem.ruleParams.type);
                            setRangemaskingStart(findItem.ruleParams.x);
                            setRangemaskingEnd(findItem.ruleParams.y);
                            break;
                        case 'aes128':
                            setAes128Key(findItem.ruleParams.key);
                            setAes128Start(findItem.ruleParams.iv);
                            setAes128IsBase64(findItem.ruleParams.base64 ? 1 : 0);
                            break;
                        case 'aes256':
                            setAes256Key(findItem.ruleParams.key);
                            setAes256Start(findItem.ruleParams.iv);
                            setAes256IsBase64(findItem.ruleParams.base64 ? 1 : 0);
                            break;
                        default:
                            break;
                    }
                }
            }
            else {
                emptyData()
            }
        }
        else {
            emptyData()
        }
    }

    //清空数据
    const emptyData = () => {
        setDeCode(null);
        setDeCodeName(null);
        setSecurity('high');
        setUsability('low');
        setRule(null);
        setRulePriority(null);
        setCurrentRuleIsModify(false);
        setSavedRuleItem(null);
        setLengthmaskingType(null);
        setLengthmaskingBefore(null);
        setLengthmaskingAfter(null);
        setRangemaskingType(null);
        setRangemaskingStart(null);
        setRangemaskingEnd(null);
        setAes128Key(null);
        setAes128Key(null);
        setAes128IsBase64(null);
        setAes256Key(null);
        setAes256Key(null);
        setAes256IsBase64(null);
        setDeCodeOptionList([]);
    }

    //当选择节点
    const onSelect = (keys: React.Key[], info: any) => {
        setSelectedKeys([...keys]);
        setSelectedNode(info.node);
        setValueById([...keys]);
        if (info.node.isLeaf) {
            setCurrentAction('setRule');
        }
        else {
            setCurrentAction('folderActionAll');
        }
    }

    //当展开节点
    const onExpand = (keys: React.Key[]) => {
        setExpandedKeys([...keys])
    };

    //当右击
    const onRightClick = (params: { event: any; node: any; }) => {
        let event = params.event;
        let node = params.node;
        setSelectedNode(node);
        //modal元素
        let modalEle = document.getElementById('modal');
        let modal_content = document.getElementById('modal_content');
        if (node && node.isLeaf !== undefined) {
            //右击设置选中的节点
            setSelectedKeys([node.key]);
            if (modalEle) {
                modalEle.style.display = 'block';
            }
            if (modal_content) {
                modal_content.style.top = event.clientY + 'px';
                modal_content.style.left = event.clientX + 'px';
            }
        }
        else {
            if (modalEle) {
                modalEle.style.display = 'none';
            }
        }
    }

    //modal中操作
    const switchModalAction = () => {
        if (selectedNode) {
            let isLeaf = selectedNode.isLeaf;
            let parentId = selectedNode.parentId;
            switch (isLeaf) {
                case true:
                    return (
                        <div className={styles.actions}>
                            <div className={styles.actionItem} onClick={modifyFile}>
                                <IconFont type="iconedit"></IconFont>
                                修改脱敏数据
                            </div>
                            <div className={styles.actionItem} onClick={deleteFile}>
                                <IconFont type="icondelete"></IconFont>
                                删除脱敏数据
                            </div>
                        </div>
                    )
                    break;
                case false:
                    return (
                        <div className={styles.actions}>
                            <div className={styles.actionItem} onClick={addFolder}>
                                <IconFont type="iconaddfolder"></IconFont>
                                添加规则目录
                            </div>
                            <div className={styles.actionItem} onClick={addFile}>
                                <IconFont type="iconaddFile"></IconFont>
                                添加脱敏数据
                            </div>
                            {
                                parentId !== 0 ? <React.Fragment>
                                    <div className={styles.actionItem} onClick={modifyFolder}>
                                        <IconFont type="iconedit"></IconFont>
                                        修改规则目录
                                    </div>
                                    <div className={styles.actionItem} onClick={deleteFolder}>
                                        <IconFont type="icondelete"></IconFont>
                                        删除规则目录
                                    </div>
                                </React.Fragment> : null
                            }
                        </div>
                    )
                    break;
                default:
                    return null
                    break;
            }
        }
    }

    //点击其他位置关闭modal
    const closeModal = () => {
        let modalEle = document.getElementById('modal');
        if (modalEle) {
            modalEle.style.display = 'none';
        }
    }

    //添加新目录
    const addFolder = (event: any) => {
        //阻止冒泡
        event.stopPropagation();
        setCurrentAction('addDirectory');
        closeModal();
    }

    //添加新文件
    const addFile = (event: any) => {
        //阻止冒泡
        event.stopPropagation();
        setCurrentAction('addRule');
        closeModal();
    }

    //修改目录
    const modifyFolder = (event: any) => {
        //阻止冒泡
        event.stopPropagation();
        setDirectoryName(selectedNode.title);
        setCurrentAction('modifyFolder');
        closeModal();
    }

    //修改脱敏数据
    const modifyFile = (event: any) => {
        //阻止冒泡
        event.stopPropagation();
        setRuleName(selectedNode.title);
        setCurrentAction('modifyFile');
        closeModal();
    }

    //删除脱敏数据
    const deleteFile = (event: any) => {
        //阻止冒泡
        event.stopPropagation();
        confirm({
            title: '确定删除此条脱敏数据吗？',
            icon: <ExclamationCircleOutlined />,
            okText: '确认',
            cancelText: '取消',
            content: '',
            centered: true,
            onOk() {
                DeleteDirectory({ id: selectedNode.key }).then(res => {
                    initData();
                    closeModal();
                })
            },
            onCancel() {
                closeModal();
            },
        });
    }

    //删除目录
    const deleteFolder = (event: any) => {
        //阻止冒泡
        event.stopPropagation();
        confirm({
            title: '确定删除此规则目录吗？',
            icon: <ExclamationCircleOutlined />,
            okText: '确认',
            cancelText: '取消',
            centered: true,
            content: '',
            onOk() {
                DeleteDirectory({ id: selectedNode.key }).then(res => {
                    initData();
                    closeModal();
                })
            },
            onCancel() {
                closeModal();
            },
        });
    }

    //保存新规则目录
    const onAddDirectory = () => {
        if (directoryName && directoryName.trim()) {
            let params = {
                configurationId: configurationId,
                isDeCode: false,
                name: directoryName,
                parentId: selectedNode.key
            }
            setSaveFolderLoading(true);
            AddDirectory(params).then(res => {
                if (res.code === 0) {
                    initData();
                }
                else {
                    message.error('保存失败！', 1);
                }
                setSaveFolderLoading(false);
            })
        }
        else {
            message.error('请输入规则目录名称！', 1)
        }
    }

    //保存新脱敏数据
    const onAddRule = () => {
        if (ruleName && ruleName.trim()) {
            let params = {
                configurationId: configurationId,
                isDeCode: true,
                name: ruleName,
                parentId: selectedNode.key
            }
            setSaveFileLoading(true);
            AddDirectory(params).then(res => {
                if (res.code === 0) {
                    initData();
                }
                else {
                    message.error('保存失败！', 1);
                }
                setSaveFileLoading(false);
            })
        }
        else {
            message.error('请输入脱敏数据名称！', 1)
        }
    }

    //修改目录
    const onModifyFolder = () => {
        if (directoryName && directoryName.trim()) {
            let params = {
                configurationId: configurationId,
                isDeCode: false,
                name: directoryName,
                parentId: selectedNode.parentId,
                id: selectedNode.key
            }
            setModifyFolderLoading(true);
            ModifyDirectory(params).then(res => {
                if (res.code === 0) {
                    initData();
                }
                else {
                    message.error('修改失败！', 1);
                }
                setModifyFolderLoading(false);
            })
        }
        else {
            message.error('请输入规则目录名称！', 1)
        }
    }

    //修改脱敏数据
    const onModifyFile = () => {
        if (ruleName && ruleName.trim()) {
            let params = {
                configurationId: configurationId,
                isDeCode: true,
                name: ruleName,
                parentId: selectedNode.parentId,
                id: selectedNode.key
            }
            setModifyFileLoading(true);
            ModifyDirectory(params).then(res => {
                if (res.code === 0) {
                    initData();
                }
                else {
                    message.error('修改失败！', 1);
                }
                setModifyFileLoading(false);
            })
        }
        else {
            message.error('请输入脱敏数据名称！', 1)
        }
    }

    //数据元模糊搜索
    const onDeCodeChange = (value: any) => {
        setDeCode(value);
        let deCode = deCodeOptionList.find((item: any) => {
            return item.code === value;
        })
        setDeCodeName(deCode.name);
    }

    //数据元onDeCodeSearch
    const onDeCodeSearch = (value: any) => {
        if (value && value.length >= 2) {
            GetDeCodeList({ code: value }).then(res => {
                if (res.code === 0) {
                    let filterArr = res.data.slice(0, 10);
                    setDeCodeOptionList(filterArr);
                }
            })
        }
    }

    //保存规则
    const onSaveRule = () => {
        let ruleItem = ruleList.find((item: { code: any; }) => { return item.code === rule });
        if (deCode && selectedNode.key && deCodeName && rulePriority && rule && ruleItem && security && usability) {
            //设置ruleParams
            let ruleParams;
            switch (rule) {
                case 'lengthmasking':
                    if (lengthmaskingType && lengthmaskingBefore && lengthmaskingBefore.trim() && lengthmaskingAfter && lengthmaskingAfter.trim()) {
                        ruleParams = {
                            type: lengthmaskingType,
                            n: lengthmaskingBefore.trim(),
                            m: lengthmaskingAfter.trim()
                        }
                        let params = {
                            deCode: deCode,
                            directoryId: selectedNode.key,
                            name: deCodeName,
                            priorityNum: rulePriority,
                            ruleCode: rule,
                            ruleId: ruleItem.id,
                            ruleName: ruleItem.name,
                            ruleParams: ruleParams,
                            security: security,
                            usability: usability
                        }
                        setSaveRuleLoading(true);
                        SaveRule(params).then(res => {
                            if (res.code === 0) {
                                message.success('保存成功！', 1);
                                initData();
                            }
                            else {
                                message.error('保存失败', 1);
                            }
                            setSaveRuleLoading(false);
                        })
                    }
                    else {
                        message.error('请填写完整后保存!', 1);
                    }
                    break;
                case 'rangemasking':
                    if (rangemaskingType && rangemaskingStart && rangemaskingStart.trim() && rangemaskingEnd && rangemaskingEnd.trim()) {
                        ruleParams = {
                            type: rangemaskingType,
                            x: rangemaskingStart.trim(),
                            y: rangemaskingEnd.trim()
                        }
                        let params = {
                            deCode: deCode,
                            directoryId: selectedNode.key,
                            name: deCodeName,
                            priorityNum: rulePriority,
                            ruleCode: rule,
                            ruleId: ruleItem.id,
                            ruleName: ruleItem.name,
                            ruleParams: ruleParams,
                            security: security,
                            usability: usability
                        }
                        setSaveRuleLoading(true);
                        SaveRule(params).then(res => {
                            if (res.code === 0) {
                                message.success('保存成功！', 1);
                                initData();
                            }
                            else {
                                message.error('保存失败', 1);
                            }
                            setSaveRuleLoading(false);
                        })
                    }
                    else {
                        message.error('请填写完整后保存!', 1);
                    }
                    break;
                case 'aes128':
                    // if (aes128Start && aes128Start.trim() && aes128IsBase64 !== undefined) {
                    // if ((aes128Key && aes128Key.trim() && aes128Key.length === 32) || !aes128Key) {
                    ruleParams = {
                        key: ruleItem.ruleParams.key,
                        iv: ruleItem.ruleParams.iv,
                        base64: ruleItem.ruleParams.base64
                    }
                    let aes128Params = {
                        deCode: deCode,
                        directoryId: selectedNode.key,
                        name: deCodeName,
                        priorityNum: rulePriority,
                        ruleCode: rule,
                        ruleId: ruleItem.id,
                        ruleName: ruleItem.name,
                        ruleParams: ruleParams,
                        security: security,
                        usability: usability
                    }
                    setSaveRuleLoading(true);
                    SaveRule(aes128Params).then(res => {
                        if (res.code === 0) {
                            message.success('保存成功！', 1);
                            initData();
                        }
                        else {
                            message.error('保存失败', 1);
                        }
                        setSaveRuleLoading(false);
                    })
                    // }
                    // else {
                    //     message.error('key密钥长度为32', 1);
                    // }
                    // }
                    // else {
                    //     message.error('请填写完整后保存!', 1);
                    // }
                    break;
                case 'aes256':
                    // if (aes256Start && aes256Start.trim() && aes256IsBase64 !== undefined) {
                    // if ((aes256Key && aes256Key.trim() && aes256Key.length === 64) || !aes256Key) {
                    ruleParams = {
                        // key: aes256Key ? aes256Key.trim() : undefined,
                        key: ruleItem.ruleParams.key,
                        iv: ruleItem.ruleParams.iv,
                        base64: ruleItem.ruleParams.base64
                    }
                    let aes256Params = {
                        deCode: deCode,
                        directoryId: selectedNode.key,
                        name: deCodeName,
                        priorityNum: rulePriority,
                        ruleCode: rule,
                        ruleId: ruleItem.id,
                        ruleName: ruleItem.name,
                        ruleParams: ruleParams,
                        security: security,
                        usability: usability
                    }
                    setSaveRuleLoading(true);
                    SaveRule(aes256Params).then(res => {
                        if (res.code === 0) {
                            message.success('保存成功！', 1);
                            initData();
                        }
                        else {
                            message.error('保存失败', 1);
                        }
                        setSaveRuleLoading(false);
                    })
                    // }
                    // else {
                    //     message.error('key密钥长度为64', 1);
                    // }
                    // }
                    // else {
                    //     message.error('请填写完整后保存!', 1);
                    // }
                    break;
                default:
                    break;
            }
        }
        else {
            message.error('请填写完整后保存!', 1);
        }
    }

    //修改规则
    const onModifyRule = () => {
        let ruleItem = ruleList.find((item: { code: any; }) => { return item.code === rule });
        if (savedRuleItem.id && deCode && selectedNode.key && deCodeName && rulePriority && rule && ruleItem && security && usability) {
            //设置ruleParams
            let ruleParams;
            switch (rule) {
                case 'lengthmasking':
                    if (lengthmaskingType && lengthmaskingBefore && lengthmaskingBefore.trim() && lengthmaskingAfter && lengthmaskingAfter.trim()) {
                        ruleParams = {
                            type: lengthmaskingType,
                            n: lengthmaskingBefore.trim(),
                            m: lengthmaskingAfter.trim()
                        }
                        let params = {
                            id: savedRuleItem.id,
                            deCode: deCode,
                            directoryId: selectedNode.key,
                            name: deCodeName,
                            priorityNum: rulePriority,
                            ruleCode: rule,
                            ruleId: ruleItem.id,
                            ruleName: ruleItem.name,
                            ruleParams: ruleParams,
                            security: security,
                            usability: usability
                        }
                        setModifyRuleLoading(true);
                        ModifyRule(params).then(res => {
                            if (res.code === 0) {
                                message.success('修改成功！', 1);
                            }
                            else {
                                message.error('修改失败', 1);
                            }
                            setModifyRuleLoading(false);
                        })
                    }
                    else {
                        console.log(1111)
                        message.error('请填写完整后再提交！', 1);
                    }
                    break;
                case 'rangemasking':
                    if (rangemaskingType && rangemaskingStart && rangemaskingStart.trim() && rangemaskingEnd && rangemaskingEnd.trim()) {
                        ruleParams = {
                            type: rangemaskingType,
                            x: rangemaskingStart.trim(),
                            y: rangemaskingEnd.trim()
                        }
                        let params = {
                            id: savedRuleItem.id,
                            deCode: deCode,
                            directoryId: selectedNode.key,
                            name: deCodeName,
                            priorityNum: rulePriority,
                            ruleCode: rule,
                            ruleId: ruleItem.id,
                            ruleName: ruleItem.name,
                            ruleParams: ruleParams,
                            security: security,
                            usability: usability
                        }
                        setModifyRuleLoading(true);
                        ModifyRule(params).then(res => {
                            if (res.code === 0) {
                                message.success('修改成功！', 1);
                            }
                            else {
                                message.error('修改失败！', 1);
                            }
                            setModifyRuleLoading(false);
                        })
                    }
                    else {
                        message.error('请填写完整后再提交！', 1);
                    }
                    break;
                case 'aes128':
                    // if (aes128Start && aes128Start.trim() && aes128IsBase64 !== undefined) {
                    // if ((aes128Key && aes128Key.trim() && aes128Key.length === 32) || !aes128Key) {
                    ruleParams = {
                        // key: aes128Key ? aes128Key.trim() : undefined,
                        key: ruleItem.ruleParams.key,
                        // iv: aes128Start.trim(),
                        iv: ruleItem.ruleParams.iv,
                        // base64: aes128IsBase64 === 1 ? true : false
                        base64: ruleItem.ruleParams.base64
                    }
                    let aes128Params = {
                        id: savedRuleItem.id,
                        deCode: deCode,
                        directoryId: selectedNode.key,
                        name: deCodeName,
                        priorityNum: rulePriority,
                        ruleCode: rule,
                        ruleId: ruleItem.id,
                        ruleName: ruleItem.name,
                        ruleParams: ruleParams,
                        security: security,
                        usability: usability
                    }
                    setModifyRuleLoading(true);
                    ModifyRule(aes128Params).then(res => {
                        if (res.code === 0) {
                            message.success('修改成功！', 1);
                        }
                        else {
                            message.error('修改失败', 1);
                        }
                        setModifyRuleLoading(false);
                    })
                    // }
                    // else {
                    //     message.error('key密钥长度为32', 1);
                    // }
                    // }
                    // else {
                    //     message.error('请填写完整后再提交！', 1);
                    // }
                    break;
                case 'aes256':
                    // if (aes256Start && aes256Start.trim() && aes256IsBase64 !== undefined) {
                    // if ((aes256Key && aes256Key.trim() && aes256Key.length === 64) || !aes256Key) {
                    ruleParams = {
                        // key: aes256Key ? aes256Key.trim() : undefined,
                        key: ruleItem.ruleParams.key,
                        iv: ruleItem.ruleParams.iv,
                        base64: ruleItem.ruleParams.base64
                    }
                    let aes256Params = {
                        id: savedRuleItem.id,
                        deCode: deCode,
                        directoryId: selectedNode.key,
                        name: deCodeName,
                        priorityNum: rulePriority,
                        ruleCode: rule,
                        ruleId: ruleItem.id,
                        ruleName: ruleItem.name,
                        ruleParams: ruleParams,
                        security: security,
                        usability: usability
                    }
                    setModifyRuleLoading(true);
                    ModifyRule(aes256Params).then(res => {
                        if (res.code === 0) {
                            message.success('修改成功！', 1);
                        }
                        else {
                            message.error('修改失败', 1);
                        }
                        setModifyRuleLoading(false);
                    })
                    // }
                    // else {
                    //     message.error('key密钥长度为64', 1);
                    // }
                    // }
                    // else {
                    //     message.error('请填写完整后再提交！', 1);
                    // }
                    break;
                default:
                    break;
            }
        }
        else {
            message.error('请填写完整后提交!', 1);
        }
    }

    //点击版本发布按钮
    const submitBtn = () => {
        setModalVisible(true);
    }

    //处理treeData
    const handleTreeData = (data: any) => {
        let handleFun = (data: any) => {
            for (let item of data) {
                if (item.children && item.children.length > 0) {
                    if (item.icon) {
                        delete item.icon
                    }
                    handleFun(item.children);
                }
                else {
                    if (item.icon) {
                        delete item.icon
                    }
                }
            }
        }
        handleFun(data)
        return data;
    }

    //发布版本
    const onSubmitVersion = () => {
        let filterTreeData = handleTreeData(treeData);
        if (versionNum && versionNum.trim() && describe && describe.trim() && isDefault !== undefined && configurationId && filterTreeData && ruleElementList) {
            let params = {
                version: versionNum,
                description: describe,
                isDefault: isDefault,
                configurationId: configurationId,
                directoryDtos: filterTreeData,
                ruleElementList: ruleElementList
            }
            setSubmitLoading(true);
            SubmitRule(params).then(res => {
                if (res.code === 0) {
                    message.success('版本发布成功！', 1);
                    setModalVisible(false);
                    setVersionNum('');
                    setDescribe('');
                    setIsDefault(false);
                    initData();
                }
                else {
                    message.error('版本发布失败！', 1);
                }
                setSubmitLoading(false);
            })
        }
        else {
            message.error('请填写完整后再提交！', 1)
        }
    }

    //切换推荐版本
    const onVersionRecomChange = (value: any) => {
        confirm({
            title: '确定切换推荐版本吗？',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk() {
                let params = {
                    id: ruleInfo.id,
                    versionRecom: value
                }
                ModifyRecomVersion(params).then(res => {
                    if (res) {
                        setRuleInfo(res);
                        setVersionRecom(res.versionRecom);
                        message.success('修改推荐版本成功！', 1);
                    }
                    else {
                        message.error('修改推荐版本失败！', 1);
                    }
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    useEffect(() => {
        let params = {
            usability: usability,
            security: security
        }
        SearchRule(params).then(res => {
            if (res.code === 0) {
                if (res.data && res.data.length > 0) {
                    let ruleList = [];
                    for (let item of res.data) {
                        ruleList.push({
                            code: item.code,
                            name: item.name,
                            id: item.id,
                            description: item.description,
                            ruleParams: item.ruleParams
                        })
                    }
                    setRuleList(ruleList);
                }
                else {
                    setRuleList([]);
                    setRule(null);
                }
            }
        })
    }, [usability, security])

    //根据rulecode得到tooltip
    const getTooltipTitle = () => {
        let ruleItem = ruleList.find((item: any) => {
            return item.code === rule
        })
        if (ruleItem) {
            return ruleItem.description
        }
        else {
            return '暂无提示内容！'
        }
    }


    //根据规则选择不同表单
    const switchFormByRuleCode = () => {
        switch (rule) {
            case 'lengthmasking':
                return (
                    <React.Fragment>
                        <div className={styles.actionItem}>
                            <div className={styles.label}>
                                <div className={styles.notEmpty}>*</div>
                                        遮盖类型：
                                    </div>
                            <div className={styles.actionForm}>
                                <Select placeholder="遮盖类型" value={lengthmaskingType} style={{ width: '100%' }} onChange={(value) => { setLengthmaskingType(value) }}>
                                    <Option value={1}>遮盖</Option>
                                    <Option value={2}>保留</Option>
                                </Select>
                            </div>
                        </div>
                        <div className={styles.actionItem}>
                            <div className={styles.label}>
                                <div className={styles.notEmpty}>*</div>
                                {`前n个字符${lengthmaskingType === 1 ? "遮盖" : "保留"}：`}
                            </div>
                            <div className={styles.actionForm}>
                                <Input value={lengthmaskingBefore} onChange={(e) => { setLengthmaskingBefore(e.target.value) }} placeholder="前n个字符遮盖" />
                            </div>
                        </div>
                        <div className={styles.actionItem}>
                            <div className={styles.label}>
                                <div className={styles.notEmpty}>*</div>
                                {`后m个字符${lengthmaskingType === 1 ? "遮盖" : "保留"}：`}
                            </div>
                            <div className={styles.actionForm}>
                                <Input value={lengthmaskingAfter} onChange={(e) => { setLengthmaskingAfter(e.target.value) }} placeholder="后n个字符遮盖" />
                            </div>
                        </div>
                    </React.Fragment>
                )
                break;
            case 'rangemasking':
                return (
                    <React.Fragment>
                        <div className={styles.actionItem}>
                            <div className={styles.label}>
                                <div className={styles.notEmpty}>*</div>
                                            遮盖类型：
                                        </div>
                            <div className={styles.actionForm}>
                                <Select placeholder="遮盖类型" value={rangemaskingType} style={{ width: '100%' }} onChange={(value) => { setRangemaskingType(value) }}>
                                    <Option value={1}>遮盖</Option>
                                    <Option value={2}>保留</Option>
                                </Select>
                            </div>
                        </div>
                        <div className={styles.actionItem}>
                            <div className={styles.label}>
                                <div className={styles.notEmpty}>*</div>
                                            开始位置：
                                        </div>
                            <div className={styles.actionForm}>
                                <Input value={rangemaskingStart} onChange={(e) => { setRangemaskingStart(e.target.value) }} placeholder="开始位置" />
                            </div>
                        </div>
                        <div className={styles.actionItem}>
                            <div className={styles.label}>
                                <div className={styles.notEmpty}>*</div>
                                            结束位置：
                                        </div>
                            <div className={styles.actionForm}>
                                <Input value={rangemaskingEnd} onChange={(e) => { setRangemaskingEnd(e.target.value) }} placeholder="结束位置" />
                            </div>
                        </div>
                    </React.Fragment>
                )
                break;
            case 'aes128':
                return (
                    <React.Fragment>
                        {/* <div className={styles.actionItem}>
                            <div className={styles.label}>
                                key密钥：
                                            </div>
                            <div className={styles.actionForm}>
                                <Input maxLength={32} value={aes128Key} onChange={(e) => { setAes128Key(e.target.value) }} placeholder="key密钥" />
                            </div>
                        </div>
                        <div className={styles.actionItem}>
                            <div className={styles.label}>
                                <div className={styles.notEmpty}>*</div>
                                                加密初始位置：
                                            </div>
                            <div className={styles.actionForm}>
                                <Input disabled={!aes128Key || !aes128Key.trim()} value={aes128Start} onChange={(e) => { setAes128Start(e.target.value) }} placeholder="加密初始位置" />
                            </div>
                        </div>
                        <div className={styles.actionItem}>
                            <div className={styles.label}>
                                <div className={styles.notEmpty}>*</div>
                                                是否为base64：
                                            </div>
                            <div className={styles.actionForm}>
                                <Select disabled={!aes128Key || !aes128Key.trim()} placeholder="是否为base64" value={aes128IsBase64} style={{ width: 120 }} onChange={(value) => { setAes128IsBase64(value) }}>
                                    <Option value={1}>是</Option>
                                    <Option value={0}>否</Option>
                                </Select>
                            </div>
                        </div> */}
                    </React.Fragment>
                )
                break;
            case 'aes256':
                return (
                    <React.Fragment>
                        {/* <div className={styles.actionItem}>
                            <div className={styles.label}>
                                key密钥：
                                                </div>
                            <div className={styles.actionForm}>
                                <Input maxLength={64} value={aes256Key} onChange={(e) => { setAes256Key(e.target.value) }} placeholder="key密钥" />
                            </div>
                        </div>
                        <div className={styles.actionItem}>
                            <div className={styles.label}>
                                <div className={styles.notEmpty}>*</div>
                                                    加密初始位置：
                                                </div>
                            <div className={styles.actionForm}>
                                <Input disabled={!aes256Key || !aes256Key.trim()} value={aes256Start} onChange={(e) => { setAes256Start(e.target.value) }} placeholder="加密初始位置" />
                            </div>
                        </div>
                        <div className={styles.actionItem}>
                            <div className={styles.label}>
                                <div className={styles.notEmpty}>*</div>
                                                    是否为base64：
                                                </div>
                            <div className={styles.actionForm}>
                                <Select disabled={!aes256Key || !aes256Key.trim()} placeholder="是否为base64" value={aes256IsBase64} style={{ width: 120 }} onChange={(value) => { setAes256IsBase64(value) }}>
                                    <Option value={1}>是</Option>
                                    <Option value={0}>否</Option>
                                </Select>
                            </div>
                        </div> */}
                    </React.Fragment>
                )
                break;
            default:
                break;
        }
    }

    //根据不同操作选择不同操作view
    const switchAction = () => {
        switch (currentAction) {
            case 'addDirectory':
                return (
                    <React.Fragment>
                        <div className={styles.head}>
                            <div className={styles.title}>
                                添加规则目录
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    规则目录名称：
                                </div>
                                <div className={styles.actionForm}>
                                    <Input autoFocus value={directoryName} onChange={(e) => { setDirectoryName(e.target.value) }} placeholder="规则目录名称" />
                                </div>
                            </div>
                            <div className={styles.actionBtn}>
                                <Button onClick={onAddDirectory} type="primary" loading={saveFolderLoading}>
                                    <IconFont type="iconsave"></IconFont>
                                    保存
                                </Button>
                            </div>
                        </div>
                    </React.Fragment>

                )
                break;
            case 'addRule':
                return (
                    <React.Fragment>
                        <div className={styles.head}>
                            <div className={styles.title}>
                                添加脱敏数据
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    脱敏数据名称：
                                </div>
                                <div className={styles.actionForm}>
                                    <Input autoFocus value={ruleName} onChange={(e) => { setRuleName(e.target.value) }} placeholder="脱敏数据名称" />
                                </div>
                            </div>
                            <div className={styles.actionBtn}>
                                <Button onClick={onAddRule} type="primary" loading={saveFileLoading}>
                                    <IconFont type="iconsave"></IconFont>
                                    保存
                                </Button>
                            </div>
                        </div>
                    </React.Fragment>

                )
                break;
            case 'modifyFolder':
                return (
                    <React.Fragment>
                        <div className={styles.head}>
                            <div className={styles.title}>
                                修改规则目录
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    修改规则目录名称：
                                 </div>
                                <div className={styles.actionForm}>
                                    <Input autoFocus value={directoryName} onChange={(e) => { setDirectoryName(e.target.value) }} placeholder="修改规则目录名称" />
                                </div>
                            </div>
                            <div className={styles.actionBtn}>
                                <Button onClick={onModifyFolder} type="primary" loading={modifyFolderLoading}>
                                    <IconFont type="iconmodify_icon"></IconFont>
                                    修改
                                </Button>
                            </div>
                        </div>
                    </React.Fragment>

                )
                break;
            case 'modifyFile':
                return (
                    <React.Fragment>
                        <div className={styles.head}>
                            <div className={styles.title}>
                                修改脱敏数据
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    修改脱敏数据名称：
                                     </div>
                                <div className={styles.actionForm}>
                                    <Input autoFocus value={ruleName} onChange={(e) => { setRuleName(e.target.value) }} placeholder="修改脱敏数据名称" />
                                </div>
                            </div>
                            <div className={styles.actionBtn}>
                                <Button onClick={onModifyFile} type="primary" loading={modifyFileLoading}>
                                    <IconFont type="iconmodify_icon"></IconFont>
                                    修改
                                </Button>
                            </div>
                        </div>
                    </React.Fragment>

                )
                break;
            case 'folderActionAll':
                return (
                    <React.Fragment>
                        <div className={styles.head}>
                            <div className={styles.title}>
                                <div style={{ color: currentTab === 'addFolder' ? '#6287ec' : '#000000', borderBottom: currentTab === 'addFolder' ? '2px solid #6287ec' : '' }} onClick={() => { setCurrentTab('addFolder') }} className={styles.tab}>添加规则目录</div>
                                <div style={{ color: currentTab === 'addFile' ? '#6287ec' : '#000000', borderBottom: currentTab === 'addFile' ? '2px solid #6287ec' : '' }} onClick={() => { setCurrentTab('addFile') }} className={styles.tab}>添加脱敏数据</div>
                            </div>
                        </div>
                        <div className={styles.content}>
                            {
                                currentTab === 'addFolder' ? <React.Fragment>
                                    <div className={styles.actionItem}>
                                        <div className={styles.label}>
                                            规则目录名称：
                                        </div>
                                        <div className={styles.actionForm}>
                                            <Input autoFocus value={directoryName} onChange={(e) => { setDirectoryName(e.target.value) }} placeholder="规则目录名称" />
                                        </div>
                                    </div>
                                    <div className={styles.actionBtn}>
                                        <Button onClick={onAddDirectory} type="primary" loading={saveFolderLoading}>
                                            <IconFont type="iconsave"></IconFont>
                                            保存
                                        </Button>
                                    </div>
                                </React.Fragment> : <React.Fragment>
                                    <div className={styles.actionItem}>
                                        <div className={styles.label}>
                                            脱敏数据名称：
                                            </div>
                                        <div className={styles.actionForm}>
                                            <Input autoFocus value={ruleName} onChange={(e) => { setRuleName(e.target.value) }} placeholder="脱敏数据名称" />
                                        </div>
                                    </div>
                                    <div className={styles.actionBtn}>
                                        <Button onClick={onAddRule} type="primary" loading={saveFileLoading}>
                                            <IconFont type="iconsave"></IconFont>
                                                保存
                                            </Button>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </React.Fragment>

                )
                break;
            case 'setRule':
                return (
                    <React.Fragment>
                        <div className={styles.head}>
                            <div className={styles.title}>
                                {selectedNode ? `脱敏数据设置(${selectedNode.title})：` : '脱敏数据设置：'}
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>
                                        数据元编码：
                                    </div>
                                <div className={styles.actionForm}>
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="数据元编码"
                                        optionLabelProp="label"
                                        onChange={onDeCodeChange}
                                        onSearch={onDeCodeSearch}
                                        value={deCode}
                                        filterOption={false}
                                        disabled={currentRuleIsModify}
                                    >
                                        {
                                            deCodeOptionList && deCodeOptionList.map((item: any, index: any) => {
                                                return <Option value={item.code} key={index}>{`${item.code}(${item.name})`}</Option>
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>
                                        数据元名称：
                                    </div>
                                <div className={styles.actionForm}>
                                    {
                                        deCodeName ? <div>{deCodeName}</div> : <div>请选择一个数据元编码</div>
                                    }
                                </div>
                            </div>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>
                                        数据安全性：
                                    </div>
                                <div className={styles.actionForm}>
                                    <Select placeholder="数据安全性" value={security} style={{ width: '100%' }} onChange={(value) => { setSecurity(value) }}>
                                        {
                                            securityList && securityList.map((item: any, index: any) => {
                                                return <Option value={item.code} key={index}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>
                                        数据可用性：
                                    </div>
                                <div className={styles.actionForm}>
                                    <Select placeholder="数据可用性" value={usability} style={{ width: '100%' }} onChange={(value) => { setUsability(value) }}>
                                        {
                                            usabilityList && usabilityList.map((item: any, index: any) => {
                                                return <Option value={item.code} key={index}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>
                                        脱敏规则：
                                    </div>
                                <div className={styles.actionForm}>
                                    <Select placeholder="脱敏规则" value={rule} style={{ width: 'calc(100% - 20px)' }} onChange={(value) => { setRule(value) }}>
                                        {
                                            ruleList && ruleList.map((item: any, index: any) => {
                                                return <Option value={item.code} key={index}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </div>
                                {
                                    rule ? <div className={styles.tooltip_content}>
                                        <div className={styles.tooltip}>
                                            <Tooltip placement="top" title={getTooltipTitle}>
                                                <IconFont style={{ fontSize: '16px' }} type="iconDataActionQuestion"></IconFont>
                                            </Tooltip>
                                        </div>
                                    </div> : null
                                }
                            </div>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>
                                        规则优先级：
                                    </div>
                                <div className={styles.actionForm}>
                                    <InputNumber value={rulePriority} placeholder="规则优先级" min={1} max={100} onChange={(value) => { setRulePriority(value) }} />
                                    <div className={styles.prompt}>
                                        注：1~100，数字最大优先级越高
                                    </div>
                                </div>
                            </div>
                            {
                                switchFormByRuleCode()
                            }
                            <div className={styles.actionBtn}>
                                {
                                    currentRuleIsModify ? <Button onClick={onModifyRule} type="primary" loading={modifyRuleLoading}>
                                        <IconFont type="iconmodify_icon"></IconFont>
                                            修改
                                        </Button> : <Button onClick={onSaveRule} type="primary" loading={saveRuleLoading}>
                                        <IconFont type="iconsave"></IconFont>
                                            保存
                                        </Button>
                                }
                            </div>
                        </div>
                    </React.Fragment>

                )
                break;
            default:
                break;
        }
    }

    return (
        <div className={styles.ruleDetail}>
            <div className={styles.header}>
                <div className={styles.title_content}>
                    <div className={styles.title}>脱敏规则设置</div>
                    <div className={styles.submitBtn}>
                        <Button onClick={submitBtn} type="primary">
                            <IconFont type="iconsubmit_white"></IconFont>
                            版本发布
                        </Button>
                    </div>
                </div>
                <div className={styles.headerContent}>
                    <Row>
                        <Col span={6}>
                            <div className={styles.item}>
                                <div className={styles.label}>
                                    作用域类型：
                                </div>
                                <div className={styles.content}>
                                    {ruleInfo ? ruleInfo.name : ''}
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={styles.item}>
                                <div className={styles.label}>
                                    作用域：
                                </div>
                                <div className={styles.content}>
                                    {ruleInfo ? ruleInfo.scopeName : ''}
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={styles.item}>
                                <div className={styles.label}>
                                    创建人：
                                </div>
                                <div className={styles.content}>
                                    {ruleInfo ? ruleInfo.createUser : ''}
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={styles.item}>
                                <div className={styles.label}>
                                    创建时间：
                                </div>
                                <div className={styles.content}>
                                    {ruleInfo ? ruleInfo.createTime : ''}
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={styles.item}>
                                <div className={styles.label}>
                                    推荐版本：
                                </div>
                                <div className={styles.content}>
                                    <div style={{ width: '100px' }}>
                                        <Select placeholder="选择版本" value={versionRecom} onChange={onVersionRecomChange}>
                                            {
                                                versionList && versionList.map((item: any, index: any) => {
                                                    return <Option value={item} key={index}>{item}</Option>
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={styles.item}>
                                <div className={styles.label}>
                                    最新版本：
                                </div>
                                <div className={styles.content}>
                                    {ruleInfo ? ruleInfo.versionLatest : ''}
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={styles.item}>
                                <div className={styles.label}>
                                    最新发布时间：
                                </div>
                                <div className={styles.content}>
                                    {ruleInfo ? ruleInfo.versionTime : ''}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className={styles.detail_content}>
                <Row>
                    <Col span={8}>
                        <div className={styles.content_left}>
                            <div className={styles.directory}>
                                <div className={styles.head}>
                                    <div className={styles.title}>
                                        目录
                                    </div>
                                </div>
                                <div className={styles.content}>
                                    <DirectoryTree
                                        onSelect={onSelect}
                                        onExpand={onExpand}
                                        expandedKeys={expandedKeys}
                                        showLine={{ showLeafIcon: false }}
                                        selectedKeys={selectedKeys}
                                        treeData={treeData}
                                        onRightClick={onRightClick}
                                    />
                                    {/* 菜单部分 */}
                                    <div className={styles.modal} id="modal" onClick={closeModal}>
                                        <div id="modal_content" className={styles.modal_content}>
                                            {
                                                switchModalAction()
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={16}>
                        <div className={styles.content_right}>
                            <div className={styles.setContent}>
                                {switchAction()}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal visible={modalVisible}
                title={'发布版本'}
                onCancel={() => { setModalVisible(false) }}
                width={'800px'}
                centered
                destroyOnClose
                maskClosable={true}
                footer={null}>
                <div className={styles.modalContent}>
                    <div className={styles.formItem}>
                        <div className={styles.label}>版本号：</div>
                        <div className={styles.content}>
                            <Input style={{ width: '500px' }} value={versionNum} onChange={(e) => { setVersionNum(e.target.value) }} placeholder="版本号" />
                        </div>
                    </div>
                    <div className={styles.formItem}>
                        <div className={styles.label}>版本说明：</div>
                        <div className={styles.content}>
                            <TextArea style={{ width: '500px' }} value={describe} onChange={(e) => { setDescribe(e.target.value) }} placeholder="版本说明" rows={4} />
                        </div>
                    </div>
                    <div className={styles.formItem}>
                        <div className={styles.label}>是否设为默认：</div>
                        <div className={styles.content}>
                            <Radio.Group onChange={(e) => { setIsDefault(e.target.value) }} value={isDefault}>
                                <Radio value={true}>默认版本</Radio>
                                <Radio value={false}>非默认版本</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className={styles.actionContent}>
                        <div className={styles.btn}>
                            <Button style={{ backgroundColor: '#ffffff', color: '#6287EC', borderColor: '#6287EC' }} onClick={() => { setModalVisible(false) }} type="primary">
                                <IconFont type="iconcancle"></IconFont>
                                取消
                            </Button></div>
                        <div className={styles.btn}>
                            <Button style={{ backgroundColor: '#6287EC', borderColor: '#6287EC' }} loading={submitLoading} onClick={onSubmitVersion} type="primary">
                                <IconFont type="iconsubmit_white"></IconFont>
                                发布
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}


export default RuleDetail;