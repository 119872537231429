import React from "react";
import {Breadcrumb} from 'antd';
import {createFromIconfontCN} from '@ant-design/icons';

let _this = this;

class NoConfigPage extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
    }

    render() {
        return (
            <div style={{fontSize:'14px',marginLeft:'20px'}}>
                未配置菜单，请联系管理员！
            </div>
        );
    }
}

export default NoConfigPage;