import React from 'react';
import { connect } from 'react-redux';
import { actionCreator } from '../../store';
import { Select, Button, DatePicker, Form, TreeSelect, Input, Row, Col } from 'antd';
import './monitoring.less';
import EchartsOne from './echartsOne/echartsOne';
import EchartsTwo from './echartsTwo/echartsTwo';
import EchartsOverallSituation from './echartsOverallSituation/echartsOverallSituation';
import EchartsAreaCondition from './echartsAreaCondition/echartsAreaCondition';
import ProjectStatistics from './projectStatistics/projectStatistics';
import { CaretDownOutlined, createFromIconfontCN, SearchOutlined, PieChartOutlined, TableOutlined } from '@ant-design/icons';

import { GetProjectMonitor, GetAreaCenterSelect, GetCenterUserSelect } from "../../../../services/project/ProjectService";
import locale from "antd/es/date-picker/locale/zh_CN";

let _this;
const { Option } = Select;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});
const noColor = {
    color: '#999'
}
const haveColor = {
    color: '#1890ff'
}

class Monitoring extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            selectAreaCode: null, // 选中下拉区域编码
            selectCenterCode: null,// 选中下拉中心编码
            selectUserCode: null,// 选中下拉参与者编码
            projectId: props.projectId,
            projectMonitorCallbackData: {}, // 项目监控数据集合
            areaSelectList: [], // 全部区域
            centerSelectList: [], // 全部中心
            userSelectList: [], // 全部参与者
            colorState: 1, // 切换颜色
        }
    }

    componentDidMount() {
        _this.getProjectMonitor() // 获取项目监控详情
    }

    /**
     * 获取项目监控详情
     */
    getProjectMonitor = () => {
        GetProjectMonitor(_this.state.projectId).then(res => {
            _this.props.handleProjectMonitor(res);
            _this.setState({
                projectMonitorCallbackData: res,
                areaSelectList: _this.areaSelectMapList(res.areaSelectList),
                centerSelectList: res.centerSelectList ? res.centerSelectList : [],
                userSelectList: res.userSelectList ? res.userSelectList : [],
            })
        })
    }

    /**
     * 区域映射
     */
    areaSelectMapList = (data) => {
        let b = [];
        data.forEach(v => {
            let one = {};
            one.title = v.areaName
            one.value = v.areaCode + '_' + v.areaName
            one.selected = v.selected
            if (v.children && v.children.length != 0) {
                one.children = _this.areaSelectMapList(v.children)
            } else {
                one.children = []
            }
            b.push(one);
        })
        return b
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    /**
     * 区域下拉方法
     * @param a
     */
    handleTreeChange = (value, label, extra) => {
        if (value) {
            let areaCode = value.split('_')[0]
            GetAreaCenterSelect(_this.state.projectId, areaCode).then(result => {
                const params = {
                    areaCode,
                }
                GetProjectMonitor(_this.state.projectId, params).then(res => {
                    _this.props.handleProjectMonitor(res);
                    _this.setState({
                        selectAreaCode: value,
                        projectMonitorCallbackData: res,
                        centerSelectList: result,
                        // userSelectList: [],
                        selectUserCode: null,
                        selectCenterCode: null,
                    })
                })
            })
        } else {
            _this.setState({
                selectAreaCode: null,
                selectCenterCode: null,
                selectUserCode: null,
                // centerSelectList: [],
                // userSelectList: [],
            }, () => {
                _this.getProjectMonitor()
            })
        }
    }

    /**
     * 中心下拉触发方法
     * @param centerCode
     */
    handleCenterSelect = (centerCode) => {
        if (centerCode) {
            GetCenterUserSelect(_this.state.projectId, centerCode).then(result => {
                const params = {
                    centerCode,
                }
                GetProjectMonitor(_this.state.projectId, params).then(res => {
                    _this.props.handleProjectMonitor(res);
                    _this.setState({
                        selectCenterCode: centerCode,
                        projectMonitorCallbackData: res,
                        userSelectList: result,
                        selectUserCode: null,
                    })
                })
            })
        } else {
            _this.setState({
                selectCenterCode: null,
                selectUserCode: null,
                // userSelectList: [],
            }, () => {
                const params = {
                    areaCode: _this.state.selectAreaCode ? _this.state.selectAreaCode.split('_')[0] : '',
                }
                GetProjectMonitor(_this.state.projectId, params).then(res => {
                    _this.props.handleProjectMonitor(res);
                    _this.setState({
                        projectMonitorCallbackData: res,
                    })
                })
            })
        }
    }

    /**
     * 参与者下拉触发方法
     * @param val
     */
    handleUserSelect = (userCode) => {
        if (userCode) {
            const params = {
                centerCode: _this.state.selectCenterCode,
                userCode,
            }
            GetProjectMonitor(_this.state.projectId, params).then(res => {
                _this.props.handleProjectMonitor(res);
                _this.setState({
                    selectUserCode: userCode,
                    projectMonitorCallbackData: res,
                })
            })
        } else {
            _this.setState({
                selectUserCode: null,
            }, () => {
                const params = {
                    centerCode: _this.state.selectCenterCode,
                }
                GetProjectMonitor(_this.state.projectId, params).then(res => {
                    _this.props.handleProjectMonitor(res);
                    _this.setState({
                        projectMonitorCallbackData: res,
                    })
                })
            })
        }
    }

    /**
     * 切换颜色
     */
    changeColor = (val) => {
        this.setState({
            colorState: val
        })
    }

    render() {
        let { colorState } = this.state;
        return (
            <div className={'monitoring'}>
                <div className={'monitoring_top'}>
                    <div className={'selectQueryGroup'}>
                        <Row>
                            <Col span={22}>
                                <TreeSelect
                                    showSearch
                                    className='searchCriteria'
                                    value={_this.state.selectAreaCode}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    treeData={_this.state.areaSelectList}
                                    placeholder="全部区域"
                                    treeDefaultExpandAll
                                    onChange={(value, label, extra) => _this.handleTreeChange(value, label, extra)}
                                    allowClear
                                />
                                <Select
                                    showSearch
                                    value={_this.state.selectCenterCode}
                                    placeholder="全部中心"
                                    className='searchCriteria'
                                    onChange={_this.handleCenterSelect}
                                    allowClear
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        _this.state.centerSelectList.map(v => (
                                            <Option value={v.centerCode}
                                                key={v.centerCode}>{v.centerName}</Option>
                                        ))
                                    }
                                </Select>
                                <Select
                                    value={_this.state.selectUserCode}
                                    placeholder="全部参与者"
                                    style={{ width: 252, marginRight: 16, height: 36 }}
                                    onChange={_this.handleUserSelect}
                                    allowClear
                                >
                                    {
                                        _this.state.userSelectList.map(v => (
                                            <Option value={v.userCode}
                                                key={v.userCode}>{v.userName}</Option>
                                        ))
                                    }
                                </Select>

                                {/* （v2.4项目监控页面改版-暂不展示） */}
                                {/* <Input placeholder="请输入参与者" className='searchCriteria'/>
                                <Button type="primary" onClick={this.createBill} >
                                    <SearchOutlined />查询
                                </Button> */}

                            </Col>

                            {/* （v2.4项目监控页面改版-暂不展示） */}
                            {/* <Col span={2} className={'col4'}>
                                <PieChartOutlined onClick={()=>this.changeColor(1)} style={{color: colorState == 1 ? '#1890ff' : '#999'}}/>
                                <TableOutlined onClick={()=>this.changeColor(2)} style={{color: colorState == 2 ? '#1890ff' : '#999'}}/>
                            </Col> */}

                        </Row>
                    </div>
                </div>

                {/* 原数据展示形式（保留） */}
                <div className={'echartsGroup'}>
                    <EchartsOne projectId={_this.props.projectId} />
                    <EchartsTwo projectId={_this.props.projectId} />
                </div>

                {/* echarts图展示（v2.4项目监控页面改版-暂不展示） */}
                {/* {colorState == 1 &&
                    <div className={'charts'}>
                        <div className={'overallSituation container'}>
                            <span className={'title'}>数据采集总体情况</span>
                            <EchartsOverallSituation/>
                        </div>
                        <div className={'areaCondition container'}>
                            <span className={'title'}>各区域完成情况概述</span>
                            <EchartsAreaCondition/>
                        </div>
                    </div>
                } */}

                {/* 表格展示（v2.4项目监控页面改版-暂不展示）  */}
                {/* {
                    colorState == 2 &&
                    <ProjectStatistics/>
                } */}

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    projectMonitor: state.getIn(['project', 'projectMonitor'])
})

const mapDispatchToProps = (dispatch) => ({
    handleProjectMonitor(data) {
        const action = actionCreator.ProjectMonitorAction(data);
        dispatch(action)
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(Monitoring);