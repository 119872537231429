import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { DatePicker, Input, Radio, Space, Tooltip } from 'antd';
import { saveFormData } from "../../../../services/data/DataService";
import moment from 'moment';
import './select.less';
import ActionView from '../../com/actionView/actionView';
import formAction from '../../utils/formAction';
import mExper from "../../utils/operation.ts";
import { InfoCircleOutlined } from '@ant-design/icons';
import verify, { replaceFieldRemind } from '../../utils/verify';

function SelectCom(props: any, ref: any) {
    const [value, setValue] = useState<any>();
    const [data, setData] = useState<any>();
    const [formId, setFormId] = useState<any>();
    const [optional, setOptional] = useState<any>([]);
    const [dataActions, setDataActions] = useState<any>([]);
    const [formIsRead, setFormIsRead] = useState<boolean>(true);
    const [timeObject, setTimeObject] = useState<any>();
    const [savedValues, setSavedValues] = useState<any>([]);
    const [otherValue, setOtherValue] = useState<any>();
    const [updatedValue, setUpdatedValue] = useState<any>();
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [direction, setDirection] = useState<any>('horizontal');

    // 超范围异常提醒
    const [annotation, setAnnotation] = useState<any>(null);

    useEffect(() => {
        if (props.data) {
                        //设置dataActions
            setDataActions(props.dataActions);
            //表单是否只读
            setFormIsRead(props.formIsRead);
            //获取当前表单的id
            setFormId(props.formId);
            //该条表单元素的数据
            setData(props.data);
            //已经保存的表单数据
            setSavedValues(props.savedValues);
            //如果是修改时
            if (props.isUpdate) {
                setIsUpdate(props.isUpdate)
            }
            else {
                setIsUpdate(false)
            }

            //设置排列方向
            setDirection(props.data.direction ? props.data.direction : 'horizontal')

            //设置options
            let selectFilterArr = [];
            for (let optionItem of Object.keys(props.data.optional)) {
                selectFilterArr.push(
                    {
                        label: props.data.optional[optionItem],
                        value: optionItem.toString(),
                        defaultValue: props.data.defaultValue
                    }
                )
            }
            setOptional(selectFilterArr);

            if (props.data.isAdd) {
                let Index = props.savedValues.findIndex((v: any) => v.dataCode == props.data.dataCode)
                if (Index == -1) {
                    let params = {
                        formId: props.formId,
                        submit: false,
                        values: [
                            {
                                dataCode: props.data.dataCode,
                                deCode: props.data.deCode,
                                value: '',
                                extendAttribute: 'isAdd=true'
                            }
                        ]
                    }
                    formAction.saveData(params);
                }
            }

            if (props.data.logicOperations && props.data.logicOperations.length > 0) {
                for (let logicOperation of props.data.logicOperations) {
                    let result = mExper.handleLogicOperations(logicOperation, {
                        savedValues: props.savedValues,
                        data: data
                    });
                    if (result == "null") {
                        continue;
                    }

                    let Index = selectFilterArr.findIndex((v: any) => v.value == result)
                    if (logicOperation.type == 'dynamicValue' && Index == -1) {
                        continue;
                    }
                    setValue(result);
                    let values = [
                        {
                            dataCode: data.dataCode,
                            deCode: data.deCode,
                            value: result
                        }
                    ]
                    saveData(formId, values);
                }
            }

            if (props?.data?.conditionalValues?.length) {
                let result = mExper.setConditionalValue(props.data.conditionalValues, {
                    savedValues: props.savedValues,
                    data: data
                });

                if (result && selectFilterArr?.length && selectFilterArr.some((v) => v.value == result)) {
                    setValue(result)
                    let values = [
                        {
                            dataCode: data.dataCode,
                            deCode: data.deCode,
                            value: result
                        }
                    ]
                    saveData(formId, values);
                }
            }

            //设置当前表单已存的value
            let currentItem = props.savedValues.find((i: any) => {
                return i.dataCode == props.data.dataCode
            });
            if (currentItem) {
                setUpdatedValue(currentItem.value ? currentItem.value.toString() : currentItem.value);
                if (data && data.other) {
                    if (data.optional[currentItem.value]) {
                        //设置当前值
                        setValue(currentItem.value);
                        setOtherValue(null);
                    }
                    else {
                        //设置当前值
                        setValue(data.other.key);
                        if (data.other.type === 'input') {
                            setOtherValue(currentItem.value)
                        }
                        else if (data.other.type === 'date') {
                            setOtherValue(moment(currentItem.value))
                        }
                    }
                }
                else {
                    //设置当前值
                    setValue(currentItem.value ? currentItem.value.toString() : currentItem.value)
                    //设置其他值
                    setOtherValue(null);
                }
            }
            else {
                setUpdatedValue(null);
                setValue(null);
                setOtherValue(null);
            }

            // 超范围异常提醒 
            if (props.data.remind || props.data.reminds) {
                // feature: 使用列表中的其他字段进行比较
                if(props.data.remind) {
                    replaceFieldRemind(props.data.remind, props.savedValues)
                }
                //
                setAnnotation(
                    verify.checkValueAnnotation(
                        currentItem ? currentItem.value : value,
                        props.data,
                        props.formCenterCode,
                        props.savedValues
                    )
                );
            }
        }
    }, [props])

    useEffect(() => {
        if (!value && data) {
            if (data.defaultValue) {
                onChangeValue(data.defaultValue)
            }
        }
    }, [data, value])

    //当提交后显示的value
    const showSavedValue = () => {
        if (value) {
            if (data.optional[value]) {
                return data.optional[value]
            }
            else {
                if (data.other) {
                    if (data.other.type === 'input') {
                        return value
                    }
                    else if (data.other.type === 'date') {
                        return moment(value).format('YYYY/MM/DD');
                    }
                }
            }
        }
        else {
            return '未录入'
        }
    }

    //当输入改变
    const onChange = (e: any) => {
        if (props.data.isAdd && props.data.logicOperations) {
            props.data.logicOperations.forEach((item: any) => {
                if (item.type == 'clearValue') {
                    let target = item.target;
                    let index = props.data.dataCode.includes('#') ? props.data.dataCode.split('#')[1] : ''
                    let targets: any = []
                    if (index) {
                        target.forEach((v: any) => {
                            if (v.includes('#{index}')) {
                                let newTarget = v.replace('{index}', index)
                                targets.push(newTarget)
                            }
                        });
                    } else {
                        targets = target
                    }

                    let params = {
                        formId: formId,
                        submit: false,
                        targets: targets
                    }
                    formAction.deleteData(params);
                }
            })
        }

       
        setValue(e.target.value);
        onActionChange(e.target.value);
        onChangeFilterValue(e.target.value);
    }

    const onActionChange = (val: any) => {
        let _action = (props?.data?.actions || []).find((val: any) => val.type == 'clearList') || {};
        if (val == _action.rule) {
            if (_action.type == 'clearList') {
                let _target = _action.target || [];
                let _savedValues = props.savedValues;
                _target.forEach((tDataCode: string) => {
                    _savedValues.forEach((v: any) => {
                        let isClear = false;
                        if (v.dataCode.indexOf('#') >= 0) {
                            let _v = v.dataCode.split('#')[0];
                            isClear = (_v == tDataCode);
                        } else {
                            isClear = (v.dataCode == tDataCode);
                        }
                        if (isClear) {
                            // 清空对应的数据
                            let values = [
                                {
                                    dataCode: v.dataCode,
                                    deCode: v.deCode,
                                    value: null
                                }
                            ]
                            let params = {
                                formId: props.formId,
                                submit: false,
                                values: values
                            }
                            formAction.saveData(params);
                        }
                    });
                });

            }
        }
    }

    //当输入改变
    const onChangeValue = (v: any) => {
        setValue(v);
        setAnnotation(verify.checkValueAnnotation(v, data, props.formCenterCode, props.savedValues));
        onChangeFilterValue(v);
    }

    //当other input发生改变
    const onOtherInputChange = (e: any) => {
        setOtherValue(e.target.value);
        if (e.target.value) {
            onChangeFilterValue(e.target.value);
        }
        else {
            onChangeFilterValue('');
        }
    }

    const onOtherDateChange = (value: any) => {
        setOtherValue(value);
        onChangeFilterValue(value);
    }

    //防抖函数
    const onChangeFilterValue = (value: any) => {
        let extendAttribute = ''
        if (props.data.isAdd) {
            extendAttribute = 'isAdd=true'
        }
        let values = [
            {
                dataCode: data.dataCode,
                deCode: data.deCode,
                value: value,
                extendAttribute: extendAttribute ? extendAttribute : undefined
            }
        ]
        if (isUpdate) {
            setUpdatedValue(value);
        }
        if (timeObject) {
            clearTimeout(timeObject);
        }
        let time = setTimeout(() => {
            if (!isUpdate) {
                saveData(formId, values)
            }
        }, 200);
        setTimeObject(time)
    }

    //暂存数据
    const saveData = (formId: any, values: any) => {
        let params = {
            formId: formId,
            submit: false,
            values: values
        }
        formAction.saveData(params);
        // props.startTimerFun();
        props.updateFun && props.updateFun(getPure(values[0].value))
        // saveFormData(params).then((res) => {
        //     props.updateFun()
        // })
    }

    const handleRadioCheck = (e:any)=>{
        console.log(e.target.value)
        // 如果是选中状态则取消选中
        if (value === e.target.value) {
            e.target.checked = false;
            setValue("");
            setOtherValue("");
            onActionChange("");
            onChangeFilterValue("");
        } 
    }

    //选择当有other字段时对应的表单
    const switchOtherField = () => {
        if (data && data.other) {
            if (value && value.indexOf(data.other.key) !== -1) {
                let type = data.other.type;
                switch (type) {
                    //input
                    case 'input':
                        return (
                            <Input allowClear={true} style={{ width: '150px' }} disabled={!formIsRead} placeholder={data ? `请输入${data.label}` : ''} suffix={data && data.unit ? data.unit : ''} value={otherValue} onChange={onOtherInputChange} />
                        )
                        break;
                    //date
                    case 'date':
                        return (
                            <DatePicker style={{ width: '150px' }} allowClear={false} value={otherValue} disabled={!formIsRead} placeholder={data ? `请输入${data.label}` : ''} format={'YYYY/MM/DD'} onChange={onOtherDateChange} />
                        )
                        break;
                    default:
                        break;
                }
            }
        }
        else {
            return null;
        }
    }

    const getPure = (currentValue?: any)=> ({
        dataCode: data.dataCode,
        deCode: data.deCode,
        formId: formId,
        value: currentValue ?? updatedValue,
    })

    useImperativeHandle(ref, () => ({
        // changeVal 就是暴露给父组件的方法
        getModifyValue: getPure,
    }));

    return (
        <div className="selectCom">
            {/* {
                formIsRead ? <React.Fragment>
                    <Radio.Group size="large" onChange={onChange} value={value}>
                        {
                            optional && optional.map((item: any, index: any) => {
                                return <Radio disabled={!formIsRead} key={index} value={item.value}>{item.label}</Radio>
                            })
                        }
                    </Radio.Group>
                    {
                        switchOtherField()
                    }
                </React.Fragment> : <div>{showSavedValue()}</div>
            } */}
            <React.Fragment>
                <Radio.Group size="large" onChange={onChange}  value={value}  defaultValue={ optional[0]?.defaultValue}>

                    {direction === 'vertical' ? <Space direction="vertical">
                        {
                            optional && optional.map((item: any, index: any) => {
                                return <Radio disabled={!formIsRead} key={index} value={item.value}    onClick={handleRadioCheck}>
                                    <div style={{ display: 'block' }} dangerouslySetInnerHTML={{ __html: item.label }}  ></div>
                                </Radio>
                            })
                        }
                    </Space> :
                        <React.Fragment>
                            {
                                optional && optional.map((item: any, index: any) => {
                                    return <Radio disabled={!formIsRead} key={index} value={item.value}   onClick={handleRadioCheck}>
                                        <div style={{ display: 'block' }} dangerouslySetInnerHTML={{ __html: item.label }}></div>
                                    </Radio>
                                })
                            }
                        </React.Fragment>
                    }
                </Radio.Group>
                {
                    switchOtherField()
                }
                {
                    annotation && annotation.status &&
                    <Tooltip title={annotation.message}>
                        <InfoCircleOutlined style={{ color: 'red', fontSize: 16 }} />
                    </Tooltip>
                }
            </React.Fragment>
        </div>
    )
}

export default forwardRef(SelectCom);
