import { Button, Form, Input, Modal, Select, message as messager } from 'antd'
import modStyl from './edit-modal.module.scss'

import React, { useEffect, useState } from 'react'
import { createAssistant, CreateAssistantPayload, DataType, updateAssistant } from '../../../../../services/apply/patient'
import { getProjectList } from '../../../../../services/project/project'

type SelectOption = {
  label: string;
  value: string;
}

type Props = {
  record?: DataType;
  onClose: ()=> void;
}

const EditModal: React.FC<Props> = ({record, onClose})=> {

  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const [projectCode, setProjectCode] = useState<string>()

  // 所属项目
  const [projs, setProjs] = useState<SelectOption[]>([])

  // 项目列表
  type ProjectItem = {
    id: number;
    code: string;
    name: string;
  }
  type ProjectResult = {
    code: number;
    message?: string;
    data: ProjectItem[];
  }
  const fetchProjs = async ()=> {
    const { code, data }: ProjectResult = await getProjectList()
    if(code === 0) {
      if(data.length === 1) {
        setProjectCode(data[0].code)
      }
      setProjs(
        data.map(({name, code})=> ({
          label: name,
          value: code
        }))
      )
    }
  }

  const handleFinish = async (data: CreateAssistantPayload)=> {
    if (record) {
      data.id = record.id
    }
    data.projectName = projs.find(({value})=> value === projectCode)?.label as string
    setLoading(true)
    const { code } = await (record ? updateAssistant : createAssistant)(data)
    setLoading(false)
    if(code === 0) {
      onClose()
      messager.success(record ? '编辑成功' : '新增成功')
    }
  }

  useEffect(()=> {
    fetchProjs()
  }, [])

  return (
    <Modal onCancel={onClose} visible={true} title={record ? '编辑医助' : '新增医助'} footer={null} closable maskClosable={false} destroyOnClose>
      <Form onFinish={handleFinish} form={form} initialValues={ record || {}} colon>
        <Form.Item label="所属项目" name="projectCode" labelCol={{span: 8}} rules={[{required: true, message: '请选择所属项目'}]}>
          {
            projs.length && (
              <Select onChange={(value)=> setProjectCode(value)} value={projectCode} options={projs} placeholder="请选择" showSearch allowClear/>
            )
          }
        </Form.Item>
        <Form.Item label="医助姓名" name="assistantName" labelCol={{span: 8}} rules={[{required: true, message: '请输入备注信息'}]}>
          <Input placeholder='请输入医助姓名'></Input>
        </Form.Item>
        <Form.Item label="手机号" name="phone" labelCol={{span: 8}} rules={[{required: true, message: '请输入备注信息'}]}>
          <Input placeholder='请输入医助手机号'></Input>
        </Form.Item>
        <Form.Item label="绑定关爱专员手机号" name="careOfficerPhone" labelCol={{span: 8}} rules={[{required: true, message: '请输入绑定关爱专员手机号'}]}>
          <Input placeholder='请输入绑定关爱专员手机号'/>
        </Form.Item>
        <div className={modStyl.footer}>
          <Button onClick={onClose}>取消</Button>
          <Button loading={loading} htmlType="submit" type="primary">确认</Button>
        </div>
      </Form>
    </Modal>
  )
}

export default EditModal