/**
 * 系统用户服务接口
 */
import { requestPost, requestGet } from '../EdcService';

const urlPrefix = "/plat";


/**
 * 获取租户列表
 * @param {} params
 */
export const getTenantList = (params) => {
    const url = urlPrefix + "/tenant/list";
    return requestPost(url, params)
};

/**
 * 获取用户列表
 * @param {} params
 */
export const getUserList = (params) => {
    const url = urlPrefix + "/user/list";
    return requestPost(url, params)
};

/**
 * 用户添加接口
 * @param {} params
 */
export const addUser = (params) => {
    const url = urlPrefix + "/user/add";
    return requestPost(url, params)
};

/**
 * 用户修改接口
 * @param {} params
 */
export const editUser = (params) => {
    const url = urlPrefix + "/user/edit";
    return requestPost(url, params)
};

/**
 * 账号删除
 * @param {} params
 */
export const deleteUser = (params) => {
    const url = urlPrefix + "/user/delete?code=" + params.code;
    return requestPost(url, params)
};

/**
 * 账号启用禁用
 * @param {} params
 */
export const editUserStatus = (params) => {
    const url = urlPrefix + "/user/edit/status?code=" + params.code;
    return requestPost(url, params)
};