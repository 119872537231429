import React from 'react';
import './index.less';
import { Button, Select, Switch, Table, Input, Spin, message, Modal, Tooltip, Image, ConfigProvider } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import {
    GetApplyList, GetApplyDetail, GetApplyProjectList, GetApplyIdentification, revokeRollback
} from "../../../services/apply/ApplyService";
import zh_CN from 'antd/lib/locale-provider/zh_CN';

import ReasonModal from "../list/modal/modal";

const { Option } = Select;
const { Search } = Input;

let timer = null;

class DoctorAudit extends React.Component {
    state = {
        activityType: 1,
        loading: false,
        projectData: [],
        projectCode: "",
        activeKey: 0,
        name: '',
        page: 1,
        pageSize: 10,
        dataLists: [],
        totalRow: 0,
        visible: false,
        detailsData: {}, // 详情数据

        reasonVisible: false, // 拒绝原因模态框
        reasonType: '', // 拒绝原因类型
    }


    componentDidMount() {
        this.getProjectList();
        this.getPageData();
    }


    getProjectList = () => {
        GetApplyProjectList().then(res => {
            if (res.code == 0) {
                this.setState({
                    projectData: res.data
                })
            }
        })
    }


    getPageData = async (curPage) => {
        let { page, pageSize, searchName, projectCode, activityType } = this.state;
        const params = {
            projectCode: projectCode,
            page: curPage ? curPage : page,
            pageSize: pageSize,
            name: searchName,
            activityType
        }

        if (this.state.activeKey == 1) {
            params['approveStatus'] = 'RUNNING'
        } else if (this.state.activeKey == 2) {
            params['identificationStatus'] = 'RUNNING'
        }

        const res = await GetApplyList(params);
        if (res && res.code == 0) {
            this.setState({
                dataLists: [...res.data.rows],
                page: res.data.page,
                pageSize: res.data.pageSize,
                totalRow: res.data.total
            })
        }
    }

    onSelect = (value) => {
        this.setState({
            projectCode: value,
            page: 1
        }, () => {
            this.getPageData()
        })
    }


    changePage = (pageNo) => {
        this.getPageData(pageNo)
    }


    onSearch = () => {
        this.setState({
            page: 1
        }, () => {
            this.getPageData()
        })
    }

    changeTab = (activeKey) => {
        this.setState({
            activeKey,
            page: 1
        }, () => {
            this.getPageData()
        })
    }


    // 自动刷新
    autoRefresh = (checked) => {
        if (!checked) {
            clearInterval(timer)
            return
        }
        timer = setInterval(() => {
            this.getPageData()
        }, 60000)
    }


    //关闭详情modal
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    /**
     * 查看详情
     */
    onDetails = async (data) => {
        const params = {
            id: data.id
        }
        const res = await GetApplyDetail(params);
        if (res && res.code == 0) {
            this.setState({
                detailsData: res.data ? res.data : {},
                visible: true
            })
        }
    }

    // 确认医生信息
    handleSure = async () => {
        let { detailsData } = this.state;

        let params = {
            id: detailsData.id,
            status: 'AGREE'
        }

        const res = await GetApplyIdentification(params);
        if (res && res.code == 0) {
            message.success('确认医生信息成功');
            this.getPageData()
            this.setState({
                visible: false
            })
        } else {
            message.error(res.message);
        }
    }



    // 显示非医生modal
    showReasonModal = async () => {
        // 非医生
        this.setState({
            reasonVisible: true,
            reasonType: 'isdoctor'
        })
    }


    // 非医生原因
    getRejectReason = async (value, type) => {
        let { detailsData } = this.state;
        let params = {
            id: detailsData.id,
            status: 'REJECT',
            rejectReasons: value
        }

        const res = await GetApplyIdentification(params);
        if (res && res.code == 0) {
            message.success(res.message);
            this.getPageData()
        } else {
            message.error(res.message);
        }

        this.setState({
            visible: false,
            reasonVisible: false
        })
    }


    // 撤销审批
    handleRevoke = async () => {
        let { detailsData } = this.state;
        let params = {
            id: detailsData.id
        }
        const res = await revokeRollback(params);
        if (res && res.code == 0) {
            this.setState({
                visible: false,
            })
            this.getPageData()
            message.success('撤销成功');
        } else {
            message.error(res.message);
        }
    }




    componentWillUnmount() {
        if (timer) {
            clearInterval(timer)
        }
    }



    // 前往卫健委网站验证
    onLink = () => {
        let url = 'http://zgcx.nhc.gov.cn:9090/doctor'
        window.open(url, '_blank')
    }

    render() {
        let { projectData, loading, dataLists, visible, activeKey, detailsData, reasonVisible, reasonType } = this.state;


        const columns = [
            {
                title: '项目名称',
                dataIndex: 'projectName',
                width: 170,
                render: (text) => {
                    return (
                        <div className={'con_div'}>
                            <div className='projectName'>
                                <Tooltip title={text}>
                                    {text}
                                </Tooltip>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '身份信息',
                render: (text, record) => {
                    return (
                        <div className={'con_div'}>
                            <div className={'title'}>
                                {record.name}
                            </div>
                            <div className={'sub-title'}>
                                {record.phone}
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '所属医院/科室',
                render: (text, record, index) => {
                    return (
                        <div className={'con_div'}>
                            <div className={'title'}>{record.hospitalName}</div>
                            <div className={'sub-title'}>{record.dept}</div>
                        </div>
                    )
                }
            },
            {
                title: '身份认证状态',
                dataIndex: 'planStatus',
                width: 130,
                render: (text, record) => {
                    return (
                        <div className={'con_div'}>
                            <div className={record.identificationStatus === 'RUNNING' ? 'running' :
                                record.identificationStatus === 'AGREE' ? 'ageree' :
                                    record.identificationStatus === 'SIGNFAIL' ? 'notPass' : 'notPass'}>
                                {
                                    record.identificationStatus === 'RUNNING' ? '待人工审核' :
                                        record.identificationStatus === 'AGREE' ? '审核通过' :
                                            record.identificationStatus === 'SIGNFAIL' ? '签名不通过' : '身份不通过'
                                }
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '拒绝原因',
                width: 150,
                dataIndex: 'rejectReasons',
                render: (text) => {
                    return (
                        <div className={'con_div'} >
                            <div className='rejectReasons'>
                                <Tooltip title={text}>
                                    {text}
                                </Tooltip>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '审核人',
                width: 120,
                dataIndex: 'approveUser'
            },
            {
                title: '申请时间',
                width: 120,
                render: (text, record) => {
                    if (record.createTime) {
                        return (
                            <div className={'con_div'}>
                                {record.createTime ? record.createTime.replace('T', ' ') : ''}
                            </div>
                        )
                    }
                }
            },
            {
                title: '审核时间',
                width: 120,
                render: (text, record) => {
                    if (record.approveTime) {
                        return (
                            <div className={'con_div'}>
                                {record.approveTime ? record.approveTime.replace('T', ' ') : ''}
                            </div>
                        )
                    }
                }
            },
            {
                title: '用户身份信息更新时间',
                dataIndex: 'modifyTime',
                width: 200,
                render: (text, record) => {
                    if (record.modifyTime) {
                        return (
                            <div className={'con_div'}>
                                {record.modifyTime ? record.modifyTime.replace('T', ' ') : ''}
                            </div>
                        )
                    }
                }
            },
            {
                title: '操作',
                render: (text, record) =>
                    <div >
                        <Button type="link" onClick={() => this.onDetails(record)} >
                            身份审核
                        </Button>
                    </div>
            },
        ];


        const pagination = {
            current: this.state.page,
            pageSize: this.state.pageSize,
            total: this.state.totalRow,
            showSizeChanger: false,
            onChange: this.changePage
        }


        const detailsInfo = [{
            title: '个人信息',
            icon: 'icon1',
            content: [
                { type: 'text', label: '姓名', defaultValue: detailsData.doctorName ? detailsData.doctorName : '', require: true },
                { type: 'text', label: '手机号', defaultValue: detailsData.phone ? detailsData.phone : '', require: false },
                { type: 'text', label: '身份证', defaultValue: detailsData.idCard ? detailsData.idCard : '', require: true },
                { type: 'DatePicker', label: '申请时间', defaultValue: detailsData.createTime ? detailsData.createTime.substring(0, 10) : '', require: false },
            ]
        }, {
            title: '医院信息',
            icon: 'icon2',
            content: [
                { type: 'select', label: '所在省份', defaultValue: detailsData.province ? detailsData.province : '', require: true },
                { type: 'text', label: '所在城市', defaultValue: detailsData.city ? detailsData.city : '', require: true },
                { type: 'text', label: '所在医院', defaultValue: detailsData.hospitalName ? detailsData.hospitalName : '', require: true },
                { type: 'text', label: '所在科室', defaultValue: detailsData.dept ? detailsData.dept : '', require: true },
                { type: 'text', label: '职称', defaultValue: detailsData.jobTitle ? detailsData.jobTitle : '', require: true }
            ]
        }, {
            title: '证件信息',
            icon: 'icon4',
            content: [
                { type: 'Image', label: '', defaultValue: detailsData.certificate ? detailsData.certificate.split(',') : [], require: false }
            ]
        }, {
            title: '审核详情',
            icon: 'icon6',
            content: [
                { type: 'text', label: '审核人', defaultValue: detailsData.approveUser ? detailsData.approveUser : '', require: false },
                { type: 'text', label: '审核时间', defaultValue: detailsData.approveTime ? detailsData.approveTime.substring(0, 10) : '', require: false },
            ]
        }]




        return (
            <>
                <div className='audit_container'>
                    <div className='audit_content'>
                        <div className='tab_header'>
                            <div className='tabs'>
                                <div className={activeKey == 0 ? 'tab active' : 'tab'} onClick={() => this.changeTab(0)}>全部申请</div>
                                <div className={activeKey == 1 ? 'tab active' : 'tab'} onClick={() => this.changeTab(1)}>待通过申请</div>
                                <div className={activeKey == 2 ? 'tab active' : 'tab'} onClick={() => this.changeTab(2)}>待审核申请</div>
                            </div>
                            <div>自动刷新 <Switch defaultChecked={false} onChange={this.autoRefresh}></Switch></div>
                        </div>
                        <div className='search_warp'>
                            <Select
                                showSearch
                                placeholder="请选择项目"
                                style={{ width: 260 }}
                                allowClear
                                onChange={this.onSelect}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {projectData.map(item => (
                                    <Option key={item.projectCode} value={item.projectCode}>{item.projectName}</Option>
                                ))}
                            </Select>
                            <Search
                                allowClear
                                placeholder="请输入姓名或医院名称"
                                style={{ width: 260, marginLeft: 16 }}
                                onSearch={this.onSearch}
                                onChange={(e) => this.setState({ searchName: e.target.value })}
                            />
                        </div>

                        <div className='cpds_content'>
                            <Table
                                loading={loading}
                                rowKey={(record) => record.id}
                                dataSource={dataLists}
                                columns={columns}
                                pagination={pagination}
                                scroll={{ x: 'calc(700px + 50%)' }}
                            />;
                        </div>
                    </div>
                </div>
                <Modal
                    title="身份审核"
                    visible={visible}
                    footer={null}
                    onCancel={this.handleCancel}
                    width={760}
                    className='audit_modal'
                    destroyOnClose
                >

                    <div className='modal_content'>
                        {
                            detailsInfo.map((item, index) => (
                                <div
                                    className={'model_detail_Info'}
                                    key={index}
                                    style={{ display: (item.title == '审核详情' && detailsData.identificationStatus == 'RUNNING') ? 'none' : '' }}
                                >
                                    <div className={'info_title'}>
                                        <div className={item.icon}></div>
                                        <div> {detailsData.certificate ? item.title : ''}</div>

                                    </div>
                                    <div className='wraps_content'>
                                        {
                                            item.content.map((x, y) => (
                                                <div className={x.type == 'Image' ? 'wraps_Image' : 'wraps'} key={y}>
                                                    <span className={'label'} hidden={!x.label}>
                                                        <span style={{ color: '#F03333' }} hidden={!x.require}>*</span>
                                                        {x.label}
                                                    </span>
                                                    <div className={'Image_item'}>
                                                        {
                                                            x.type == 'Image' ?
                                                                <>
                                                                    {x.defaultValue.length > 0 && x.defaultValue.map((i, dIndex) => (
                                                                        <ConfigProvider locale={zh_CN}>
                                                                            <Image
                                                                                height={'100%'}
                                                                                src={i}
                                                                                alt=''
                                                                                key={dIndex}
                                                                            />
                                                                        </ConfigProvider>
                                                                    ))}
                                                                </>
                                                                :
                                                                <div class="information"> <Input defaultValue={x.defaultValue} disabled style={{ width: 230 }} /></div>
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }

                        <div className='approveInfo' style={{ display: detailsData.identificationStatus == 'RUNNING' ? 'none' : '' }}>
                            <span>审核结果</span>
                            {
                                detailsData.identificationStatus == 'AGREE' ? <span className='agree'>审核通过</span> : <span className='notPass'>审核不通过</span>
                            }
                        </div>

                        {
                            detailsData.identificationStatus == 'RUNNING' ?
                                <div className='linkBtns'>
                                    <Button type="link" onClick={this.onLink} className={'linkBtn'}><PaperClipOutlined /> 前往卫健委网站验证</Button>
                                    <div>
                                        <Button danger type="primary" onClick={this.showReasonModal}>非医生</Button>
                                        <Button type="primary" onClick={this.handleSure}>确认医生信息</Button>
                                    </div>
                                </div> : <div className='linkBtns_simple'>
                                    <Button type="primary" onClick={this.handleRevoke}>撤销审核</Button>
                                </div>
                        }

                    </div>

                </Modal>

                {
                    reasonVisible &&
                    <ReasonModal
                        visible={reasonVisible}
                        closeModal={() => this.setState({ reasonVisible: false })}
                        type={reasonType}
                        getRejectReason={this.getRejectReason}
                    />
                }

            </>
        )
    }
}

export default DoctorAudit;