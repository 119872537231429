/**
 * 接单
 */
import React, { Component } from 'react';
import { Button, message } from 'antd';
import beginImg from "../../../static/images/begin.png";
import waiting from "../../../static/images/waiting.png";
import zhCN from 'antd/lib/locale/zh_CN';
import { GetDctAssignInputTask } from "../../../../../services/dct/DctService";
import '../../../components/comm.less';
import './index.less';

let _this;

class EnterTaskComponent extends Component {

    constructor(props) {
        super(props);
        _this = this;
        this.state = {
            checkTextData: {
                title: '暂未开始接单',
                subTitle: '点击开始审核按钮，系统将为您分派图片审核任务',
                button: '开始接单',
                img: beginImg,
            },
        };
        this.timerEnter = null
    }

    componentDidMount() {
        
    }

    componentWillUnmount = () => {
        clearTimeout(this.timerEnter)
    }

    // 开始审核
    handleCheck = async (value) => {

        if (value == '开始接单') {
            _this.setState({
                checkTextData: {
                    title: '等待系统派单中......',
                    subTitle: ' ',
                    button: '取消等待',
                    img: waiting
                }
            })
            this.startEnter()
        } else if (value == '取消等待') {
            clearTimeout(this.timerEnter)
            _this.setState({
                checkTextData: {
                    title: '暂未开始接单',
                    subTitle: '点击开始审核按钮，系统将为您分派图片审核任务',
                    button: '开始接单',
                    img: beginImg,
                }
            })
        }
        
    }

    // 开始接单
    startEnter = async () => {
        const res = await GetDctAssignInputTask();
            if (res && res.code == 0) {
                if (!res.data || res.data.length == 0) {
                    this.timerEnter = setTimeout(() => {
                        this.startEnter()
                    }, 10000)
                    return
                }
                clearTimeout(this.timerEnter)
                this.props.history.push({pathname: "/dct/crc/data/enter", state: {data: res.data}});
                sessionStorage.setItem('enterData', JSON.stringify(res.data))

            } else {
                clearTimeout(this.timerEnter)
                _this.setState({
                    checkTextData: {
                        title: '暂未开始接单',
                        subTitle: '点击开始审核按钮，系统将为您分派图片审核任务',
                        button: '开始接单',
                        img: beginImg,
                    }
                })
            }
    }

    render() {
        let { checkTextData } = this.state;
        return (
            <div className={'enterTask_wrap'}>
                <img src={beginImg} width='25%' alt='' />
                <div className={'txt'}>{checkTextData.title}</div>
                <span className={'hint'}>{checkTextData.subTitle}</span>
                <Button type='primary' onClick={() => this.handleCheck(checkTextData.button)} className={'checkBtn'}>
                    {checkTextData.button}
                </Button>
            </div>
        )
    }
}
export default EnterTaskComponent