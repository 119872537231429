import React, { Component } from 'react';
import { Button, Input, Select, Radio, message as Message, message } from 'antd';
import { createFromIconfontCN, PlusCircleOutlined, MinusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { CsoDoctorSearch, CsoSupplierAddProject } from "../../../../services/cso/CsoService";
import './add.less';
import utils from '../../../../util/utils';

const { Option } = Select;

class Add extends Component {

    constructor(props) {
        super(props);
        this.state = {
            radioValue: null,
            page: 1, // 当前页码数
            pageSize: 10, // 页码条数
            list: [],
            doctorList: [],
            doctor: {},
            doctorContent: '',
            showDoctorList: false
        }
    }

    componentDidMount() {
        this.getDoctorData()
    }

    /**
     * 新增成功后页面初始化 
     */
    resetPage() {
        this.setState({
            radioValue: null,
            page: 1, // 当前页码数
            pageSize: 10, // 页码条数
            list: [],
            doctorList: [],
            doctor: {},
            doctorContent: '',
            showDoctorList: false
        }, () => {
            this.getDoctorData();
        });
    }

    /**
     * 获取医生列表数据 
     */
    getDoctorData = async (curPage, content) => {
        if (!content) {
            this.setState({
                showDoctorList: false,
                doctorList: [],
                doctorContent: ''
            })
            return;
        }
        let { page } = this.state;
        const params = {
            content: content ? content : '',
            page: curPage ? curPage : page,
            pageSize: this.state.pageSize,
        }
        const res = await CsoDoctorSearch(params);
        if (res && res.code == 0) {
            let nowPage = res.data.page;
            nowPage++;
            this.setState({
                showDoctorList: true,
                doctorList: [...res.data.rows],
                page: nowPage,
                pageSize: res.data.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })
        }
    }

    onRadioChange = (e) => {
        this.setState({
            radioValue: e.target.value
        });
    };

    addPrescription = () => {
        const list = this.state.list;
        this.setState({
            list: list.concat({
                selectValue: '',
                inputValue: '',
                guid: utils.guid()
            })
        });
    }

    deletePrescription = (index) => {
        let list = [...this.state.list];
        list.splice(index, 1)
        this.setState({
            list: list
        });
    }

    onInputChange = (type, value) =>{
        if (type === 'doctor') {
            this.setState({
                doctor: {},
                doctorContent: value
            });
            this.getDoctorData(1, value)
        }
    }

    doctorSelected = (val) => {
        this.setState({
            doctor: val,
            showDoctorList: false
        });
    }

    add = async () => {
        const state = this.state;
        if (!state.doctor.code) {
            message.info('请输入医生姓名或者手机号。');
            return;
        }
        if (state.radioValue == null) {
            message.info('请选择是否立即生效');
            return;
        }
        const params = {
            projectCode: this.props.projectCode,
            supplierUserCode: this.state.doctor.code
        };
        const res = await CsoSupplierAddProject(params);
        if (res && res.code == 0) {
            message.success('添加成功');
            return true;
        }
    }

    toAdd = async () => {
        const addResult = await this.add();
        if (addResult) {
            this.exit();
        }
    }

    addAndNext = async () => {
        const addResult = await this.add();
        if (addResult) {
            this.resetPage();
        }
    }

    exit = ()=> {
        this.props.closeModal();
    }

    render() {
        const { radioValue, list, doctorList, showDoctorList, doctor, doctorContent } = this.state;
        return (
            <div className='cso_project_manage_add'>
                {/* <div className='add_item'>
                    <div className='add_item_label'>添加分组</div>
                    <Input className='add_item_input'></Input>
                </div> */}
                <div className='add_item'>
                    <div className='add_item_label'>绑定医生</div>
                    <Input className='add_item_input' placeholder='请输入医生姓名或手机号，然后从下拉框点击选择' value={doctor.name || doctorContent} onChange={(e) => { this.onInputChange('doctor', e.target.value) }} ></Input>
                    {
                        showDoctorList && doctorList.length > 0 &&
                        <div className='add_item_input_doctor'>
                            {
                                doctorList.map((val, key) => (
                                    <div className='add_item_input_doctor_item' key={key} onClick={() => { this.doctorSelected(val) }}>
                                        姓名：{val.name} 手机号：{val.code}
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
                <div className='add_item'>
                    <div className='add_item_label'>所在医院</div>
                    <Select className='add_item_input' disabled value={doctor.institution}></Select>
                </div>
                <div className='add_item'>
                    <div className='add_item_label'>职称</div>
                    <Select className='add_item_input' disabled value={doctor.title}></Select>
                </div>
                <div className='add_item'>
                    <div className='add_item_label'>科室</div>
                    <Select className='add_item_input' disabled value={doctor.dept}></Select>
                </div>
                <div className='add_item'>
                    <div className='add_item_label'>立即生效</div>
                    <Radio.Group onChange={this.onRadioChange} value={radioValue}>
                        <Radio value={1}>是</Radio>
                        <Radio value={2}>否</Radio>
                    </Radio.Group>
                </div>
                {
                    list && list.map((val, key) => (
                        <div className='add_item' key={val.guid}>
                            <div className='add_item_label'>
                                {
                                    key == 0 ? '分配处方' : ''
                                }
                            </div>
                            <div className='add_item_equal_input'>
                                <MinusCircleOutlined className='icon_minus cursor_pointer' onClick={() => {
                                    this.deletePrescription(key)
                                }}></MinusCircleOutlined> 
                                <Select className='add_item_equal_input_select'></Select>
                                <Input className='add_item_equal_input_input' placeholder='请设置分销链接'></Input>
                            </div>
                            { key == 0 ?  
                                <div className='add_item_desc cursor_pointer'>
                                    <QuestionCircleOutlined className='cursor_pointer'></QuestionCircleOutlined>
                                    <span>如何获取链接？</span>
                                </div> : null 
                            }
                        </div>
                    ))
                }
                {/* <div className='add_item cursor_pointer' onClick={this.addPrescription}>
                    <div className='add_item_label'>
                        { list.length === 0 ? '分配处方' : '' }
                    </div>
                    <PlusCircleOutlined className='icon_plus'></PlusCircleOutlined> 
                    <div className='add_item_add_prescription'>新增处方</div>
                </div> */}
                <div className='add_item'>
                    <div className='add_item_label'></div>
                    <div className='add_item_equal_input_btns'>
                        <Button onClick={this.exit}>退出</Button>
                        <Button type='primary' onClick={this.toAdd}>保存并退出</Button>
                        <Button type='primary' onClick={this.addAndNext}>保存并创建下一个</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Add;