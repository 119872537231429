import React from 'react';
import './regionalManagement.less';
import { Form, Input, Select, Button, Table, Popconfirm, TreeSelect, InputNumber } from "antd";
import {
    GetProjectAreaList,
    AddProjectArea,
    UpdateProjectAreaStatus,
    DeleteProjectArea,
    ModifyProjectArea,
} from '../../../../services/project/ProjectService'
import { CaretDownOutlined, createFromIconfontCN } from '@ant-design/icons';

let _this;
const { Option } = Select;
const { Search } = Input;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_sb9kcktrb1j.js',//icon-shanchu icon-tongji icon-quxiao icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});
const layout = {
    wrapperCol: {
        span: 24,
    },
};

class RegionalManagement extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            userSelectList: [],// 项目区域经理下拉集合数据
            areaList: [],// 区域集合数据
            areaSelectList: [],// 上级区域 树 下拉集合数据
            selectTreeValue: undefined, // 树下拉 选中value值
            statusList: [],// 区域状态枚举集合
            columns: [
                // {
                //     width: '0%',
                //     key: 'id',
                // },
                {
                    width: '48%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left1'}>
                                <p style={{marginTop: '-2.5px'}}>{record.name}</p>
                            </div>
                        )
                    }
                },
                {
                    width: '11%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left2'}>
                                <p>
                                    <span>{record.finishedNum != undefined ? record.finishedNum : '--'}</span>
                                </p>
                                <p>
                                    <span>已完成</span>
                                </p>
                            </div>
                        )
                    }
                },
                {
                    width: '11%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left2'}>
                                <p>
                                    <span>{record.goalNum != undefined ? record.goalNum : '--'}</span>
                                </p>
                                <p>
                                    <span>目标</span>
                                </p>
                            </div>
                        )
                    }
                },
                {
                    width: '12%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left3'}>
                                <p>
                                    <span>{record.adminName ? record.adminName : '未绑定'}</span>
                                    <span></span>
                                </p>
                                <p>区域经理</p>
                            </div>
                        )
                    }
                },
                {
                    width: '14%',
                    key: 'address',
                    render: (text, record, index) => {
                        return (
                            <div
                                onClick={(eve) => {
                                    eve.stopPropagation();
                                }}
                                className={'c_right'}
                            >
                                <Select
                                    defaultValue={record.status}
                                    /*suffixIcon={<CaretDownOutlined/>}*/
                                    onSelect={_this.UpdateProjectResearchStatus}
                                    style={{ width: 193, float: 'right' }}
                                    allowClear
                                >
                                    {_this.state.statusList.map(status => {
                                        return <Option
                                            value={status.code} id={record.id}
                                            key={status.code}>{status.message}</Option>
                                    })}
                                </Select>
                            </div>
                        )
                    }
                },
                {
                    width: '6%',
                    render: (text, record) =>
                        _this.state.areaList.length >= 1 ? (
                            <div onClick={eve => {
                                eve.stopPropagation();
                            }}>
                                {/*<Button
                                    type="primary"
                                    danger
                                    onClick={() => {
                                        this.handleDelete(record.id)
                                    }}
                                >
                                    {_this.formatIcon('icon-shanchu')}
                                </Button>*/}
                                <Popconfirm
                                    title="确认删除该条数据?"
                                    okText='确认'
                                    cancelText='取消'
                                    /*icon={null}*/
                                    onConfirm={() => {
                                        this.handleDelete(record.id)
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        danger
                                        style={{ height: 36 }}
                                    >
                                        {_this.formatIcon('icon-shanchu')}
                                    </Button>
                                </Popconfirm>
                            </div>
                        ) : null,
                },
            ],// 表格 行 配置
            rowId: '', // 表格选中行 id
            editFormData: {
                adminCode: null,
                goalNum: '',
                name: '',
                parentId: '',
                id: ''
            }, // 选中表格某行修改
            editBtnFlag: false,// 修改btn显 隐
            parentAreaFlag: false,// 上级区域显 隐
            defaultExpandedRowKeys: [456, 457], // table树形结构 展开节点的key值
            searchStateFlag: false, // 搜索状态下 判断表格展示
        }
    }

    formRef = React.createRef();
    inputRef = React.createRef();

    componentDidMount() {
        _this.getAreaList(); // 获取区域列表接口
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    /**
     * 获取区域选择的id
     */
    getSelectIds = (arr, name) => {
        let ids = [];
        if (arr && arr.length != 0) {
            arr.map(one => {
                if (one.children && one.children.length != 0) {
                    if (this.childrenHasName('list', one.children, name)) {
                        ids.push(one.id);
                        ids = [...ids, ..._this.getSelectIds(one.children, name)]
                    }
                }
                // if(one.name.indexOf(name) > -1){
                //     if (one.children && one.children.length != 0) {
                //         if (this.childrenHasName('list',one.children,name)) {
                //             ids.push(one.id);
                //             ids = [...ids,..._this.getSelectIds(one.children,name)]
                //         }
                //     }
                // }else {
                //     if (one.children && one.children.length != 0) {
                //         if (this.childrenHasName('list',one.children,name)) {
                //             ids.push(one.id);
                //             ids = [...ids,..._this.getSelectIds(one.children,name)]
                //         }
                //     }
                // }
            })
        } else {
            return ids;
        }
        return ids;
    }


    /**
     * 获取区域列表接口
     */
    getAreaList = (name, searchTrue = false) => {
        const projectId = _this.props.projectId;
        const params = {
            name: name ? name : '',
            projectId
        }
        GetProjectAreaList(params).then(res => {
            let areaList = [];
            areaList = name ? _this.areaMapListByName(res.areaList, name) : res.areaList
            //搜索状态下的回调
            if (searchTrue) {
                let defaultExpandedRowKeys = [];
                if (name && name != "") {
                    const selectIds = _this.getSelectIds(areaList, name);
                    defaultExpandedRowKeys = selectIds;
                }
                this.setState({
                    defaultExpandedRowKeys: defaultExpandedRowKeys,
                    searchStateFlag: true
                })
            } else {
                this.setState({
                    defaultExpandedRowKeys: [],
                    searchStateFlag: false
                })
            }

            //正常回掉
            let statusList = [];
            res.statusList.forEach(x => {
                if (x == "PROCESS") {
                    statusList.push({ code: x, message: "进行中" })
                } else if (x == "STOP") {
                    statusList.push({ code: x, message: "已暂停" })
                } else if (x == "END") {
                    statusList.push({ code: x, message: "已完成" })
                }
            })
            _this.setState({
                userSelectList: res.userSelectList,
                areaList: areaList,
                areaSelectList: name ? _this.areaSelectMapListByName(res.areaSelectList, name) : _this.areaSelectMapList(res.areaSelectList),
                statusList,
            })
        })
    }

    /**
     * 区域下拉映射
     * @param values
     */
    areaSelectMapList = (data) => {
        let b = [];
        data.forEach(v => {
            let one = {};
            one.title = v.areaName
            one.value = v.areaId + '_' + v.areaName
            one.selected = v.selected
            if (v.children && v.children.length != 0) {
                one.children = _this.areaSelectMapList(v.children)
            } else {
                one.children = []
            }
            b.push(one);
        })
        return b
    }

    /**
    * 判断子区域是否包含此名称的节点
    * @param data 区域集合
    * @param name
    */
    childrenHasName = (type, data, name) => {
        let flag = false;
        let existArr = [];
        if (type == 'list') {
            existArr = data.filter(x => x.name.indexOf(name) > -1);
        } else if (type == 'selectlist') {
            existArr = data.filter(x => x.areaName.indexOf(name) > -1);
        } else {
            existArr = data.filter(x => x.name.indexOf(name) > -1);
        }
        if (existArr && existArr.length != 0) {
            return true;
        } else {
            data.forEach(x => {
                if (x.children && x.children.length != 0) {
                    const check = this.childrenHasName(type, x.children, name);
                    if (check) {
                        flag = true;
                    }
                }
            })
        }
        return flag;
    }

    /**
     * 区域查询映射
     * @param values
     */
    areaMapList = (data) => {
        let b = [];
        data.forEach(v => {
            if (v.children && v.children.length != 0) {
                v.children = _this.areaMapList(v.children)
            } else {
                v.children = null
            }
            b.push(v);
        })
        return b
    }

    /**
    * 区域查询过滤名称方法
    * @param values
    */
    areaMapListByName = (data, name) => {
        let b = [];
        data.forEach(v => {
            if (v.name.indexOf(name) > -1) {
                if (v.children && v.children.length != 0) {
                    if (this.childrenHasName('list', v.children, name)) {
                        v.children = _this.areaMapListByName(v.children, name)
                    }
                } else {
                    v.children = null
                }
                b.push(v);
            } else {
                if (v.children && v.children.length != 0) {
                    if (this.childrenHasName('list', v.children, name)) {
                        if (v.children && v.children.length != 0) {
                            v.children = _this.areaMapListByName(v.children, name)
                        } else {
                            v.children = null
                        }
                        b.push(v);
                    }
                }
            }

        })
        return b
    }
    /**
     * 下拉区域查询过滤名称方法
     * @param values
     */
    areaSelectMapListByName = (data, name) => {
        let b = [];
        data.forEach(v => {
            if (v.areaName.indexOf(name) > -1) {
                let one = {};
                one.title = v.areaName
                one.value = v.areaId
                one.selected = v.selected
                if (v.children && v.children.length != 0) {
                    one.children = _this.areaSelectMapListByName(v.children, name)
                } else {
                    one.children = []
                }
                b.push(one);
            } else {
                if (v.children && v.children.length != 0) {
                    if (this.childrenHasName('selectlist', v.children, name)) {
                        let one = {};
                        one.title = v.areaName
                        one.value = v.areaId
                        one.selected = v.selected
                        if (v.children && v.children.length != 0) {
                            one.children = _this.areaSelectMapListByName(v.children, name)
                        } else {
                            one.children = []
                        }
                        b.push(one);
                    }
                }
            }

        })
        return b
    }

    /**
     * 表单提交
     * @param values
     */
    handleFinish = (values) => {
        if (this.state.editBtnFlag) {
            //修改
            const form = _this.formRef.current
            const values = form.getFieldsValue(['name', 'parentAreaId', 'adminCode', 'goalNum'])
            values.projectId = _this.props.projectId
            values.parentAreaId = values.parentAreaId ? values.parentAreaId.split('_')[0] : null
            values.id = _this.state.editFormData.id // 修改 当条数据的id
            ModifyProjectArea(values).then(res => {
                _this.getAreaList();
                _this.setState({
                    rowId: '',
                    editFormData: {
                        adminCode: '',
                        goalNum: '',
                        name: '',
                        parentId: '',
                        id: ''
                    },
                    editBtnFlag: false,
                    parentAreaFlag: false
                })
                _this.formRef.current.resetFields();
            })

        } else {
            //添加
            values.projectId = _this.props.projectId
            values.parentAreaId = values.parentAreaId ? values.parentAreaId.split('_')[0] : null
            AddProjectArea(values).then(res => {
                _this.getAreaList();
                _this.setState({
                    rowId: '',
                    editFormData: {
                        adminCode: '',
                        goalNum: '',
                        name: '',
                        parentId: '',
                        id: ''
                    },
                    editBtnFlag: false
                })
                _this.formRef.current.resetFields();
            })
        }
    }

    /**
     * 搜索 events
     * @param value
     */
    handleSearch = () => {
        _this.getAreaList(this.inputRef.current?.input?.value, true);
    }

    /**
     * 删除
     * @returns {JSX.Element}
     */
    handleDelete = (key) => {
        _this.formRef.current.resetFields();
        _this.setState({
            editBtnFlag: false,
        })
        let params = {
            areaId: key
        }
        DeleteProjectArea(key, params).then(res => {
            _this.getAreaList();
        })
    }

    /**
     * 更新状态 下拉框
     * @returns {JSX.Element}
     */
    UpdateProjectResearchStatus = (status, options) => {
        UpdateProjectAreaStatus(options.id, { status }).then(res => {

        })
    }

    /**
     * 展开事件
     * @returns {JSX.Element}
     */
    handleOnExpand = (expanded, record) => {
        const index = this.state.defaultExpandedRowKeys.findIndex((item, index) => item == record.id)
        if (expanded) {
            this.state.defaultExpandedRowKeys.push(record.id)
            this.setState({
                defaultExpandedRowKeys: [...this.state.defaultExpandedRowKeys]
            })
        } else {
            this.state.defaultExpandedRowKeys.splice(index, 1);
            this.setState({
                defaultExpandedRowKeys: [...this.state.defaultExpandedRowKeys]
            })
        }
    }

    /**
     * 行点击 event
     * @returns {JSX.Element}
     */
    handleRow = (record) => {
        return {
            onClick: () => {
                // 上级区域 处理
                let parentAreaId = '';
                if (record.parentId === 0) {
                    this.setState({
                        parentAreaFlag: true
                    })
                } else {
                    this.setState({
                        parentAreaFlag: false
                    })
                    parentAreaId = record.parentId + '_' + record.parentName
                }
                _this.setState({
                    rowId: record.id,
                    editFormData: {
                        adminCode: record.adminCode,
                        goalNum: record.goalNum,
                        name: record.name,
                        parentId: record.parentId + '_' + record.parentName,
                        id: record.id
                    },
                    editBtnFlag: true
                });

                //表单赋值
                _this.formRef.current.setFieldsValue({
                    adminCode: record.adminCode,
                    goalNum: record.goalNum,
                    name: record.name,
                    parentAreaId: parentAreaId,
                    id: ''
                });
            },
            onMouseEnter: () => {
                /*this.setState({
                    rowId: record.id,
                });*/
            },
        }
    };

    /**
     * 行点击高亮
     * @returns {JSX.Element}
     */
    handleSetRowClassName = (record) => {
        return record.id === _this.state.rowId ? 'clickRowStyl' : '';
    }

    /**
     * 去掉折叠图标
     * @returns {JSX.Element}
     */
    handleExpandIcon = (props) => {
        return <span style={{ marginRight: 0 }}></span>
    }

    /**
     * 上级区域下拉树 change
     * @returns {JSX.Element}
     */
    handleTreeChange = (value) => {
        _this.setState({
            selectTreeValue: value
        });
    }

    /**
     * 取消 修改
     * @returns {JSX.Element}
     */
    handleCancel = () => {
        _this.setState({
            rowId: '',
            editFormData: {
                adminCode: '',
                goalNum: '',
                name: '',
                parentId: '',
                id: ''
            },
            editBtnFlag: false,
            parentAreaFlag: false
        });
        _this.formRef.current.resetFields();
    }

    render() {
        return (
            <div className={'regionalManagement'}>
                <div className={'rm_top'}>
                    <div className={'selectQueryGroup'}>
                        <Form
                            layout={'inline'}
                            initialValues={{ layout: 'inline' }}
                            onFinish={_this.handleFinish}
                            ref={_this.formRef}
                            {...layout}
                        >
                            <Form.Item
                                name='name'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入区域名称!',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                                className={this.state.editBtnFlag ? 'editClass' : ''}
                                style={{ width: '25%', marginRight: 0, paddingRight: 16 }}
                            >
                                <Input
                                    style={{ width: '100%', marginBottom: 16, height: 36, borderColor: '#EEEFF7' }}
                                    placeholder="请输入区域名称"
                                />
                            </Form.Item>
                            <Form.Item
                                name='parentAreaId'
                                className={this.state.editBtnFlag ? 'editClass' : ''}
                                style={{ width: '25%', marginRight: 0, paddingRight: 16 }}
                            >
                                <TreeSelect
                                    showSearch
                                    style={{ width: '100%', marginBottom: 16, height: 36 }}
                                    value={_this.state.selectTreeValue}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    treeData={_this.state.areaSelectList}
                                    placeholder="请选择上级区域"
                                    treeDefaultExpandAll
                                    onChange={_this.handleTreeChange}
                                    disabled={this.state.parentAreaFlag ? true : false}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                name='adminCode'
                                className={this.state.editBtnFlag ? 'editClass' : ''}
                                style={{ width: '25%', marginRight: 0, paddingRight: 16 }}
                            >
                                <Select
                                    placeholder="请选择区域管理员"
                                    style={{ width: '100%', marginBottom: 16, height: 36 }}
                                    allowClear
                                >
                                    {_this.state.userSelectList.map(user => {
                                        return <Option
                                            value={user.userCode}
                                            key={user.userId}>{user.userName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='goalNum'
                                rules={[
                                    {
                                        type: 'number',
                                        max: 999999999,
                                        message: '请输入小于10位的正整数!',
                                    }
                                ]}
                                className={this.state.editBtnFlag ? 'editClass' : ''}
                                style={{ width: '25%', marginRight: 0 }}
                            >
                                <InputNumber
                                    min={0}
                                    max={999999999}
                                    style={{ width: '100%', marginBottom: 16, height: 36, borderColor: '#EEEFF7' }}
                                    parser={value => value.replace(/^(-1+)|[^\d]+/g, '')}
                                    placeholder="请输入区域目标样本数"
                                />
                            </Form.Item>
                            <Form.Item
                                style={{
                                    display: (_this.state.editBtnFlag ? 'none' : 'block'),
                                    textAlign: 'right',
                                    width: '100%',
                                }}
                            >
                                <Button style={{ marginLeft: 40, height: 36 }} type="primary" htmlType="submit">
                                    {/*{_this.formatIcon('icon-tianjia1')}*/}
                                    添加
                                </Button>
                            </Form.Item>
                            <Form.Item
                                style={{
                                    display: (_this.state.editBtnFlag ? 'block' : 'none'),
                                    textAlign: 'right',
                                    width: '100%',
                                }}
                            >
                                <Button
                                    className={'cancelBtn'}
                                    onClick={_this.handleCancel}
                                    style={{ height: 36 }}
                                >
                                    {/*{_this.formatIcon('icon-quxiao')}*/}
                                    取消
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ height: 36 }}
                                >
                                    {/*{_this.formatIcon('icon-xiugai')}*/}
                                    修改
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div className={'rm_content'}>
                    <div className={'search'}>
                        <span className={'rc_title'}>项目区域管理</span>
                        <div className={'search_group'}>
                            <Input
                                placeholder="查找"
                                style={{
                                    width: 300,
                                    height: 36,
                                    lineHeight: 1.8,
                                    borderColor: '#EEEFF7',
                                    borderTopLeftRadius: 2,
                                    borderBottomLeftRadius: 2,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                }}
                                allowClear
                                ref={this.inputRef}
                            />
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={_this.handleSearch}
                                style={{
                                    height: 36,
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderTopRightRadius: 2,
                                    borderBottomRightRadius: 2,
                                }}
                            >
                                {/* {_this.formatIcon('icon-chazhao')}*/}
                                查找
                            </Button>
                        </div>
                    </div>
                    <div className={'itemList'}>
                        {
                            _this.state.areaList && _this.state.areaList.length != 0 ?
                                _this.state.searchStateFlag && _this.state.defaultExpandedRowKeys.length != 0 ?
                                    <Table
                                        locale={{ emptyText: "暂无数据" }}
                                        columns={_this.state.columns}
                                        dataSource={_this.state.areaList}
                                        // pagination={false}
                                        showHeader={false}
                                        scroll={{y: window.innerHeight - 520}}
                                        expandIconAsCell={false}
                                        onRow={_this.handleRow}
                                        onExpand={_this.handleOnExpand}
                                        expandedRowKeys={[..._this.state.defaultExpandedRowKeys]}
                                        //expandIcon={(props) => _this.handleExpandIcon(props)}
                                        rowClassName={_this.handleSetRowClassName}
                                        rowKey="id"
                                    />
                                    : <Table
                                        locale={{ emptyText: "暂无数据" }}
                                        columns={_this.state.columns}
                                        dataSource={_this.state.areaList}
                                        scroll={{y: window.innerHeight - 520}}
                                        // pagination={false}
                                        showHeader={false}
                                        expandIconAsCell={false}
                                        onRow={_this.handleRow}
                                        //expandIcon={(props) => _this.handleExpandIcon(props)}
                                        rowClassName={_this.handleSetRowClassName}
                                        rowKey="id"
                                    />
                                :
                                <Table
                                    locale={{ emptyText: "暂无数据" }}
                                    showHeader={false}
                                />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default RegionalManagement;
