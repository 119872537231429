import { PLAT } from './actionTypes';
import { fromJS } from 'immutable';

const defaultState = fromJS({
  pageNo: 1,
})

export default (preState = defaultState, action) => {
  switch (action.type) {
    case PLAT:
      return preState.set('pageNo', action.pageNo);
    default:
      return preState
  }

}