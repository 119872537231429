import React, { Component } from 'react';
import { Button, Table } from 'antd';
import { CsoProjectSupplierPage } from "../../../../services/cso/CsoService";
import './list.less'

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataLists: [],
            columns: [
                {
                    title: '医生姓名',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.doctorName}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '绑定状态',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={`title ${record.state == 1 ? 'start' : record.state == 2 ? 'pause' : record.state == 3 ? 'closed' : ''}`}>
                                    { record.state == 1 ? '启用' : record.state == 2 ? '禁用' : '' }
                                </div>
                            </div>
                        )
                    }
                },
                {
                   title: '患者数量',
                   width: '',
                   render: (text, record, index) => {
                       return (
                           <div className={'con_div'}>
                               <div className={'title'}>
                                    { record.patientCount }
                               </div>
                           </div>
                       )
                   }
               },
               {
                   title: '所在分组',
                   width: '',
                   render: (text, record, index) => {
                       return (
                           <div className={'con_div'}>
                               <div className={'title'}>
                                {record.provinceCode}
                               </div>
                           </div>
                       )
                   }
               },
               {
                   title: '所在医院',
                   width: '',
                   render: (text, record, index) => {
                       return (
                           <div className={'con_div'}>
                               <div className={'title'}>
                                    {record.institution}
                               </div>
                           </div>
                       )
                   }
               },
               {
                   title: '医生级别',
                   width: '',
                   render: (text, record, index) => {
                       return (
                           <div className={'con_div'}>
                               <div className={'title'}>
                                    {record.title}
                               </div>
                           </div>
                       )
                   }
               },
               {
                   title: '所属科室',
                   width: '',
                   render: (text, record, index) => {
                       return (
                           <div className={'con_div'}>
                               <div className={'title'}>
                                    {record.dept}
                               </div>
                           </div>
                       )
                   }
               },
               {
                   title: '绑定时间',
                   width: '',
                   render: (text, record, index) => {
                       return (
                           <div className={'con_div'}>
                               <div className={'title'}>
                                {record.createTime? record.createTime.replace('T', ' '): ''}
                               </div>
                           </div>
                       )
                   }
               },
               {
                   title: '绑定人',
                   width: '',
                   render: (text, record, index) => {
                       return (
                           <div className={'con_div'}>
                               <div className={'title'}>
                               {record.createUser}
                               </div>
                           </div>
                       )
                   }
               },
                {
                    title: <div style={{ textAlign: 'center' }}>操作</div>,
                    width: 180,
                    fixed: 'right',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'} style={{display: 'flex'}} >
                               <Button className='not_allowed' danger={record.state == 1} type='link' style={{ flex: 1, paddingLeft: 0, paddingRight: 0, textAlign: 'center' }} onClick={() => { this.operation(record) }} >
                                { record.state == 1 ? '禁用' : record.state == 2 ? '启用' : '' }
                               </Button>
                            </div>
                        )
                    }
                }
            ],// 表格 行 配置
            page: 1,//当前页码数
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo);
                },
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: false,
            },
        }
    }

    componentDidMount() {
        this.getPageData();
    }

    /**
     * 获取列表数据 
     */
    getPageData = async (curPage) => {
        let { page } = this.state;
        const params = {
            projectCode: this.props.projectCode,
            page: curPage ? curPage : page,
            pageSize: this.state.pagination.pageSize,
        }

        const res = await CsoProjectSupplierPage(params);
        if (res && res.code == 0) {
            this.setState({
                dataLists: [...res.data.rows],
                page: res.data.page,
                pageSize: res.data.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })
        }
    }

    /**
     * 禁用、启用 
     */
    operation = async () => {
    }

    render() {
        let { columns, dataLists, pagination } = this.state;
        return (
                <Table
                    className='cso_project_manage_modal_doctor_list'
                    locale={{ emptyText: "暂无数据" }}
                    columns={columns}
                    dataSource={dataLists}
                    defaultExpandAllRows={true}
                    expandIconAsCell={false}
                    pagination={dataLists.length !== 0 && pagination}
                    rowKey={'id'}
                    size='small'
                    scroll={{x: 1300 }}
                />
        );
    }
}

export default List;
