/**
 * 头部导航
 * lechen
 */

import React from 'react';
import {Tabs} from 'antd';
import './nav.less'
import {GetMenuService} from "../../../services/plat/MenuService";
import localStorageUtil from "../../../comm/localStorage";
import PubSub from "pubsub-js";
import {createFromIconfontCN} from '@ant-design/icons';
import storageX, { Engine } from '../../../util/storageX';

const {TabPane} = Tabs;
let _this = this;

const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/c/font_2343612_2ojyi94p9ix.js',
        '//at.alicdn.com/t/c/font_2343612_tzn8oc11nt.js'
    ],

});

class HeaderNav extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            menu: [],
            activeKey: '0',
        }
    }

    componentDidMount() {
        this.loadMenus();
        PubSub.subscribe('onLoadMenus', this.loadMenus);
    }

    /**
     * 缓存一级菜单标题和key
     */
    handleOneLevelCache = (menuList,menuModule) => {
        if(menuModule){
            menuModule.levelOneTitle = menuList[0].title;
            menuModule.levelOneKey = menuList[0].key;
            menuModule.levelOneIndex = 0;
        }else {
            menuModule = {
                levelOneTitle: menuList[0].title,
                levelOneKey: menuList[0].key,
                levelOneIndex: 0,
            }
        }
        localStorageUtil.set('menuModule', menuModule, true);
    }

    // 非君实租户清除随访任务列表
    removeVisitEntry(menus) {
        const {tenantCode} = storageX(Engine.Session).user()
        if(['TENANT-0668', 'TENANT-056'].includes(tenantCode)) {
            return
        }

        let skip = false;
        const removeEntry = (target)=> {
            if(!Array.isArray(target)) {
                return
            }
            for(const i in target) {
                if(skip) {
                    return
                }
                const item = target[i]
                if(item.key === 'DCT_PAGE_VISIT_LIST') {
                    target.splice(i, 1)
                    skip = true;
                    return
                }
                const next = item.children
                if(Array.isArray(next) && next.length > 0) {
                    removeEntry(next)
                }
            }
        }

        removeEntry(menus)
    }

    /**
     * 加载菜单数据
     */
    loadMenus = () => {
        let menuModule = localStorageUtil.get('menuModule', true);
        let chooseIndex  = 0;
        let isRefresh  = true;
        if(menuModule && menuModule.levelOneIndex){
            chooseIndex = menuModule.levelOneIndex
        }else {
            isRefresh  = false;
        }
        const menusList = localStorageUtil.get("menus", true);
        if (menusList) {
            _this.handleOneLevelCache(menusList,menuModule);
            _this.setState({
                menu: menusList,
            }, () => {
                _this.handleChange(chooseIndex,isRefresh);
            });
        } else {
            GetMenuService().then(menus => {
                this.removeVisitEntry(menus)

                _this.handleOneLevelCache(menus,menuModule);
                _this.setState({
                    menu: menus,
                }, () => {
                    _this.handleChange(chooseIndex,isRefresh);
                });
                localStorageUtil.set("menus", menus, true);
            })
        }
    }

    /**
     * 头部点击事件
     */
    handleChange = (key,isInit) => {
        const menuModule = localStorageUtil.get('menuModule', true);
        _this.state.menu.forEach((item, index) => {
            if (index == key) {
                _this.setState({
                    activeKey: key,
                },()=>{
                    // 缓存选中的第一级菜单模块
                    menuModule.levelOneTitle = item.title;
                    menuModule.levelOneKey = item.key;
                    menuModule.levelOneIndex = index;
                    localStorageUtil.set('menuModule', menuModule, true);
                    // 派发事件
                    const param ={
                        isInit,
                        menus:item.children
                    }
                    PubSub.publish("onLeftNavChange", param);
                })
            }
        })
    }

    /**
     * icon 图标
     */
     formatIcon = (iconName, title) => {
        return iconName && iconName != '' ? (<><IconFont type={iconName}/>{title}</>) : title;
    }

    render() {
        return (
            <div className={'headerNav'}>
                <ul className={'headerItems'}>
                    <Tabs id={'tabTree'} activeKey={_this.state.activeKey+''} onChange={this.handleChange}>
                        {
                            _this.state.menu.map((item, ind) => (
                                <TabPane tab={_this.formatIcon(item.iconName, item.title)} key={ind+''}>
                                </TabPane>
                            ))
                        }
                    </Tabs>
                </ul>
            </div>
        )
    }

}

export default HeaderNav;


