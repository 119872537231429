/**
 * 用户数据模型
 * lechen
 */

class User {

    token: string;

    userName: string;

    tenantName: string;

    tenantCode: string;

    roleCode: string;
    
    roleCodes: string;

    constructor(data:any) {
        this.roleCode = data.roleCode;
        this.roleCodes = data.roleCodes;
        this.token = data.token;
        this.userName = data.userName;
        this.tenantName = data.tenantName;
        this.tenantCode = data.tenantCode;
    }
}

export default User;

