

/**
 * 活动管理
 */
import ActivityList from '../components/activity/list/list';
const routes = [
    { 'path': '/activity/list', 'component': ActivityList },
];

export default routes