/**
 * 设计中心服务接口
 * meixin
 */

import { requestPost, requestGet, requestPostRes, requestGetRes } from '../EdcService';

const urlPrefix = "/design";

/**
 * 获取 列表
 * @param {} params
 */
export const GetDesignFormList = (params) => {
    const url = urlPrefix + "/formLibrary/selectlist";
    return requestPost(url, params)
};

/**
 * 获取 下拉框list
 * @param {} params
 */
export const GetStatusList = (params) => {
    const url = urlPrefix + "/formLibrary/selects";
    return requestGet(url, params)
};

/**
 * 获取 树 info
 * @param {} params
 */
export const GetFormTreeList = (id, params) => {
    const url = urlPrefix + `/library/${id}/directorys`;
    return requestGet(url, params)
};

/**
 * 获取 方案 下拉集合
 * @param {} params
 */
export const GetStatusPlanList = () => {
    const url = urlPrefix + `/plan/selects`;
    return requestGet(url)
};

/**
 * 获取 方案 列表集合
 * @param {} params
 */
export const GetPlanList = (params) => {
    const url = urlPrefix + `/plan/list`;
    return requestPost(url, params)
};

/**
 * 获取 方案 添加
 * @param {} params
 */
export const GetPlanSave = (params) => {
    const url = urlPrefix + `/plan/save`;
    return requestPostRes(url, params)
};

/**
 * 获取 查看方案 详情
 * @param {} params
 */
export const GetPlanDetail = (id) => {
    const url = urlPrefix + `/plan/${id}`;
    return requestGet(url)
};

/**
 * 获取 编辑方案 详情
 * @param {} params
 */
export const GetPlanModifyDetail = (id) => {
    const url = urlPrefix + `/plan/modify/${id}`;
    return requestGet(url)
};

/**
 * 方案 修改
 * @param {} params
 */
export const GetPlanModify = (params) => {
    const url = urlPrefix + `/plan/modify`;
    return requestPost(url, params)
};

/**
 * 方案 修改
 * @param {} params
 */
export const GetPlanVersionSave = (params) => {
    const url = urlPrefix + `/planVersion/save`;
    return requestPostRes(url, params)
};


/**
 * 方案 编辑 节点添加
 * @param {} params
 */
export const GetPlanNodeSave = (params) => {
    const url = urlPrefix + `/planNode/save`;
    return requestPostRes(url, params)
};

/**
 * 方案 编辑 节点修改
 * @param {} params
 */
export const GetPlanNodeModify = (params) => {
    const url = urlPrefix + `/planNode/modify`;
    return requestPostRes(url, params)
};

/**
 * 方案 编辑 节点删除
 * @param {} params
 */
export const GetPlanNodeDel = (id) => {
    const url = urlPrefix + `/planNode/delete/${id}`;
    return requestPostRes(url)
};

/**
 * 方案 编辑 节点关联表单 添加
 * @param {} params
 */
export const GetPlanNodeFormSave = (params) => {
    const url = urlPrefix + `/nodeForm/save`;
    return requestPostRes(url, params)
};

/**
 * 方案 编辑 节点关联表单 修改
 * @param {} params
 */
export const GetPlanNodeFormModify = (params) => {
    const url = urlPrefix + `/nodeForm/modify`;
    return requestPostRes(url, params)
};

/**
 * 方案 编辑 节点关联表单 删除
 * @param {} params
 */
export const GetPlanNodeFormDel = (formCode, nodeId) => {
    const url = urlPrefix + `/nodeForm/delete/${formCode}/${nodeId}`;
    return requestPostRes(url)
};



/**
 * 方案列表
 * @param {} params
 */
export const GetDesignPlanList = (params) => {
    const url = urlPrefix + `/designCenter/planList`;
    return requestPostRes(url, params)
};



/**
 * 获取 病种列表下拉框list
 * @param {} params
 */
export const GetDiseaseList = (params) => {
    const url = urlPrefix + "/designCenter/diseaseList";
    return requestGetRes(url, params)
};

/**
 * 获取类型列表
 * @param {} params
 */
export const GetTypeList = (params) => {
    const url = urlPrefix + "/designCenter/typeList";
    return requestGetRes(url, params)
};

/**
 * 获取租户列表
 * @param {} params
 */
export const GetTenantList = (params) => {
    const url = urlPrefix + "/designCenter/tenantList";
    return requestGetRes(url, params)
};

/**
 * 获取方案详情
 * @param {} params
 */
export const GetDesignPlanDetail = (params) => {
    const url = urlPrefix + `/designCenter/planDetail/${params.id}`;
    return requestGetRes(url)
};

/**
 * 获取方案版本列表
 * @param {} params
 */
export const GetDesignPlanVersions = (params) => {
    const url = urlPrefix + `/designCenter/planVersions`;
    return requestPostRes(url, params)
};

/**
 * 获取方案变更记录列表
 * @param {} params
 */
export const GetDesignUpdateRecords = (params) => {
    const url = urlPrefix + `/designCenter/updateRecords`;
    return requestPostRes(url, params)
};

/**
 * 获取方案发布记录列表
 * @param {} params
 */
export const GetDesignPublishRecords = (params) => {
    const url = urlPrefix + `/designCenter/publishRecords`;
    return requestPostRes(url, params)
};

/**
 * 添加方案
 * @param {} params
 */
export const CreateDesignPlan = (params) => {
    const url = urlPrefix + `/designCenter/createPlan`;
    return requestPostRes(url, params)
};

/**
 * 保存方案版本
 * @param {} params
 */
export const SavePlanVersion = (params) => {
    const url = urlPrefix + `/designCenter/savePlanVersion`;
    return requestPostRes(url, params)
};

/**
 * 格式校验
 * @param {} params
 */
export const CheckContent = (params) => {
    const url = urlPrefix + `/designCenter/checkContent`;
    return requestPostRes(url, params)
};

/**
 * 发布内容   1-方案 2-问卷
 * @param {} params
 */
export const PublishContent = (params) => {
    const url = urlPrefix + `/designCenter/publishContent`;
    return requestPostRes(url, params)
};


/**
 * 保存问卷
 * @param {} params
 */
export const SavePlanForm = (params) => {
    const url = urlPrefix + `/designCenter/saveForm`;
    return requestPostRes(url, params)
};


/**
 * 获取问卷内容详情
 * @param {} params
 */
export const GetFormContentDetail = (params) => {
    const url = urlPrefix + `/designCenter/formContentDetail/${params.formCode}/${params.version}`;
    return requestGetRes(url)
};

/**
 * 获取方案内容详情
 * @param {} params
 */
export const GetPlanContentDetail = (params) => {
    const url = urlPrefix + `/designCenter/planContentDetail/${params.id}`;
    return requestGetRes(url)
};

/**
 * 发布-撤销
 * @param {} params
 */
export const PublishOrRollback = (params) => {
    const url = urlPrefix + `/designCenter/publishOrRollback`;
    return requestPostRes(url, params)
};

/**
 * 设为当前版本
 * @param {} params
 */
export const  setCurrentVersion= (params) => {
    const url = urlPrefix + `/designCenter/setCurrent/${params.id}`;
    return requestPostRes(url, params)
};








