/**
 * 审核权限管理服务接口
 */
import { requestPost, requestGet, requestPostFile } from '../EdcService';

const urlPrefix = "/plat";

/**
* 获取角色列表
*/
export const getAllRoleList = (params) => {
    const url = urlPrefix + "/audit/permission/roleList";
    return requestGet(url, params)
};

/**
 *可选项目列表
 */
export const getProjectList = (params) => {
    const url = urlPrefix + "/audit/permission/projectList";
    return requestGet(url, params)
};

/**
 * 审核权限管理列表
 * @param {} params
 */
export const getAuditPermissionList = (params) => {
    const url = urlPrefix + "/audit/permission/list";
    return requestPost(url, params)
};

/**
 * 审核权限项目设置
 */
export const getAuditPermissionSet = (params) => {
    const url = urlPrefix + "/audit/permission/set";
    return requestPost(url, params)
};

/**
 * 审核权限导出
 * @param {} params 
 * @returns 
 */
export const auditExport = (params) => {
    const url = urlPrefix + "/audit/export";
    return requestPost(url, params)
}
/**
 * 审核权限导入
 * @param {} params 
 * @returns 
 */
export const auditImport = (params) => {
    const formData = new FormData();
    console.log(params.file)
    formData.append("file", params.file)
    const url = urlPrefix + `/audit/case/import/${params.userCode}`;
    return requestPostFile(url, formData)
}



