/**
 * 登录
 * lechen
 */

import React from 'react';
import './register.less';
import {withRouter} from 'react-router-dom';
import PubSub from "pubsub-js";
import {Button, Form, Input} from "antd";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

class AppRegister extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            registerSuccessFlag: false,
            registerFlag: true
        }
    }

    /**
     * 已有账号，返回登录
     */
    returnLogin = () => {
        PubSub.publish("onRegisterSuccess")
    }

    /**
     * 注册事件
     */
    registerClickEvent = () => {
        this.setState({
            RegisterSuccessFlag: true,
            registerFlag: false
        })
    }

    /**
     * 注册成功事件
     */
    onRegisterSuccess = () => {
        PubSub.publish('onRegisterSuccess');
    }

    render() {
        return (
            <div className={"register"}>
                <div className={"register_con"}>
                    <h1 className={"title"}>电子数据采集平台</h1>
                    <div className={"title_tips"}>Huimei Health Electronic Data Capture Platform</div>
                    <div className={"register_main"} style={{display: (this.state.registerFlag) ? "block" : "none"}}>
                        <div className={'register_title'}>账号注册</div>
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            requiredMark={false}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Form.Item
                                label="账号"
                                name="usercode"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入账号！',
                                    },
                                ]}
                            >
                                <Input placeholder="请输入您的手机号"/>
                            </Form.Item>
                            <Form.Item
                                label="验证码"
                                name="verificationCode"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入验证码！',
                                    },
                                ]}
                            >
                                <Input placeholder="请输入验证码"/>
                            </Form.Item>

                            <Form.Item
                                label="密码"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入密码!',
                                    },
                                ]}
                            >
                                <Input.Password placeholder="请输入密码"/>
                            </Form.Item>

                            <Form.Item
                                label="项目"
                                name="verificationCode"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入项目名称！',
                                    },
                                ]}
                            >
                                <Input placeholder="请输入您要加入的项目名称"/>
                            </Form.Item>

                            <Form.Item
                                label="中心"
                                name="core"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入中心名称！',
                                    },
                                ]}
                            >
                                <Input placeholder="请输入您要加入的中心名称"/>
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <div className={'login_register'} onClick={this.returnLogin}>
                                    已有账号，返回登录
                                </div>
                                <Button className={"submit"} type="primary" htmlType="submit"
                                        onClick={this.registerClickEvent}>
                                    注册
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className={"register_main"}
                         style={{display: (this.state.registerSuccessFlag) ? "block" : "none"}}>
                        注册成功！
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(AppRegister);


