import React, { Component } from 'react';
import { Modal, Button, Form, Input, Select, Radio, Upload, message, Progress } from 'antd';
import { createFromIconfontCN, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
// import { GetProjectUploadUser } from '../../../../services/project/ProjectService';
import { CsoDrugImport } from "../../../../services/cso/CsoService";
import './modal.less'
import im from './import.png'
import ok from './ok.png'

const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 19,
    },
};
const { Option } = Select;
let timeInterval;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

class modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            GetProjectUploadUser: `http://192.168.1.38:9090/project/user/uploadUser/${props.projectId}`,
            GetProjectUploadProgress: `http://192.168.1.38:9090/project/user/uploadUser/`,
            fileList: [],
            uploadFlag: true,
            uploadBtnFlag: false,
            uploadProgressFlag: false,
            uploadResultFlag: false,
            totalNum: 1,
            successNum: 0,
            coverSwitch: false, //导入是否强制覆盖
            failNum: 0,
            existList: [],
            emptyList: [],
            goalNumCoverList: [],
            cancelBtnFlag: false,
            noFileTips: false,//未选择文件提示
        }
    }

    /**
     * 导入
     */
    handleOk = async () => {
        // this.setState({loading: true})
        /*this.setState({loading: true});
        setTimeout(() => {
            this.setState({loading: false}, () => {
                this.props.closeModal();
            });
        }, 3000);*/
        const { fileList } = this.state;
        console.log('fileList', fileList);
        if (fileList.length === 0) {
            this.setState({
                noFileTips: true
            })
            return;
        }
        this.setState({ loading: true })
        const uploadFormData = new FormData();
        fileList.forEach(file => {
            uploadFormData.append('file', file);
        });

        let res = await CsoDrugImport(uploadFormData);
        if (res) {
            message.success(`uploaded successfully`);
            this.setState({
                fileList: [],
                loading: false,
                uploadBtnFlag: false
            });
        } else {
            this.setState({
                loading: false,
            });
            message.success(`上传成功`);
            setTimeout(() => {
                this.props.closeModal();
            }, 200);
        }
    };

    /**
     * 关闭
     */
    handleCancel = () => {
        clearInterval(timeInterval)
        this.setState({
            uploadFlag: true,
            uploadProgressFlag: false,
            uploadResultFlag: false,
            fileList: [],
            totalNum: 1,
            successNum: 0,
            failNum: 0,
            existList: [],
            emptyList: [],
            goalNumCoverList: [],
            cancelBtnFlag: false,
            loading: false,
            uploadBtnFlag: false,
            noFileTips: false
        }, () => {
            this.props.closeModal();
        })
    };

    /**
     * 下载倒入模板
     */
    handleDownloadTmplate = () => {
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    render() {
        const { loading, fileList, coverSwitch } = this.state;
        const { visible } = this.props;
        const props = {
            name: 'file',
            accept: ".xlsx,.xls",
            action: this.state.GetProjectUploadUser,
            headers: {
                authorization: 'authorization-text',
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }), () => {
                    if (this.state.fileList.length === 1) {
                        this.setState({
                            uploadBtnFlag: true,
                            noFileTips: false
                        })
                    }
                });
                return false
            },
            /*onChange: (info) => {
                if (info.file.status !== 'uploading') {
                    /!*console.log(info.file, info.fileList);*!/
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`);

                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },*/
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
                this.setState({
                    uploadBtnFlag: false
                })
            },
        };
        return (
            <>
                <Modal
                    className={'parModals'}
                    visible={visible}
                    title="批量导入"
                    onCancel={this.handleCancel}
                    width={614}
                    centered
                    destroyOnClose
                    footer={[
                        <Button
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={this.handleOk}
                            /*disabled={fileList.length === 0}*/
                            style={{
                                display: (this.state.cancelBtnFlag || !this.state.uploadFlag ? 'none' : 'block'),
                                marginLeft: '88%',
                                height: 36
                            }}
                        >
                            导入
                        </Button>,
                        <Button
                            key="back"
                            onClick={this.handleCancel}
                            style={{
                                display: (this.state.cancelBtnFlag ? 'block' : 'none'),
                                marginLeft: '88%',
                                height: 36
                            }}
                            className={'cancelBtn'}
                        >
                            关闭
                        </Button>
                    ]}
                >
                    <div style={{ height: 198 }}>
                        {/*上传文件*/}
                        <div
                            className={'upFile'}
                            style={{
                                display: (this.state.uploadFlag ? 'block' : 'none')
                            }}
                        >
                            <span className={'select'}>批量导入表：</span>
                            <Upload
                                {...props}
                            >
                                <Button
                                    icon={<UploadOutlined />}
                                    disabled={this.state.uploadBtnFlag}
                                >
                                    上传文件
                                </Button>
                            </Upload>
                            <div
                                className={'tips'}
                                style={{ display: `${this.state.noFileTips ? 'block' : 'none'}` }}
                            >
                                {this.formatIcon('icon-guanbi')}
                                <span style={{ marginLeft: 4 }}>请先选择文件</span>
                            </div>
                            <div className={'tip'}>支持扩展名：.xlsx</div>
                            {/*<Button
                                type="primary"
                                onClick={this.handleDownloadTmplate}
                                style={{marginTop: 20}}
                            >
                                下载导入模板
                            </Button>*/}
                        </div>
                        {/*正在倒入中*/}
                        <div
                            className={'upIngFile'}
                            style={{
                                display: (this.state.uploadProgressFlag ? 'block' : 'none')
                            }}
                        >
                            <div className={'title'}>
                                <img src={im} alt="" />
                                <span>正在导入中....</span>
                            </div>
                            <div className={'progress'}>
                                <Progress
                                    percent={((this.state.successNum + this.state.failNum) / this.state.totalNum) * 100}
                                    showInfo={false}
                                />
                            </div>
                            <p className={'result'}>
                                导入
                                <span>完成{this.state.successNum}条，</span>
                                <span>失败{this.state.failNum}条，</span>
                                <span>剩余{this.state.totalNum - (this.state.successNum + this.state.failNum)}条</span>
                            </p>
                        </div>
                        {/*结果*/}
                        <div
                            className={'upedFile'}
                            style={{
                                display: (this.state.uploadResultFlag ? 'block' : 'none')
                            }}
                        >
                            <p className={'title'}>
                                <img src={ok} alt="" />
                                <span>导入完成</span>
                            </p>
                            <p className={'title1'}>
                                <span>成功{this.state.successNum}条、失败{this.state.failNum}条</span>
                            </p>
                            <div
                                className={'message'}
                            >
                                {
                                    this.state.existList.length == 0 ? '' : this.state.existList.map((res, i) => (
                                        <div>【{i + 1}】{res.userName}{res.edcCode}导入失败，原因账号已存在</div>
                                    ))
                                }
                                {
                                    this.state.emptyList.length == 0 ? '' : this.state.emptyList.map((res, i) => (
                                        <div>【{i + 1}】{res.userName}{res.edcCode}导入失败，原因表格必填项为空</div>
                                    ))
                                }
                                {
                                    this.state.goalNumCoverList.length == 0 ? '' : this.state.goalNumCoverList.map((res, i) => {
                                        return (
                                            <div>【{i + 1}】{res.userName}{res.edcCode} {res.errorMsg}</div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default modal
