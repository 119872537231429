import localStorageUtil from "../comm/localStorage";
import PubSub from "pubsub-js";

export const clearThreeTitle = () => {
    let menuModule = localStorageUtil.get('menuModule', true);
    if (menuModule) {
        menuModule.levelThreeTitle = '';
        localStorageUtil.set('menuModule', menuModule, true);
        PubSub.publish('onMenuModuleChange', menuModule);
    }
}