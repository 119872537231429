/**
 * 审核操作记录列表
 */
import React from 'react';
import './index.less';
import {
    Button, message as Message, Table,
    message, Modal, Checkbox, Input
} from "antd";
import 'moment/locale/zh-cn';
import { GetApproveRecordList } from "../../../services/approveRecord/ApproveRecordService";
import {
    GetSubjectDetail, GetFormsById
} from "../../../services/data/DataService";
import Form from '../../crf_form/Index';
class ApproveRecordList extends React.Component {

    constructor(props) {
        super(props);
        let tenantCode = '';
        this.state = {
            dataList: [], // 列表集合
            columns: [
                {
                    title: '病例编码',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.subjectCode}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '项目名称',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.projectName}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '医生账号',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.doctorCode}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '审核人',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.approveUser || '-'}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '审核时间',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.approveTime ? record.approveTime.replace('T', ' ') : ''}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '拒绝原因',
                    width: '10%',
                    render: (text, record) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.approveReason}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '审核状态',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'} >
                                <div className={'title'}>
                                    {record.operate == 'Pass' ? '通过' :
                                        record.operate == 'Reject' ? '拒绝' :
                                            record.operate == 'Rollback' ? '撤销' : ''}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '操作',
                    width: '',
                    render: (text, record) =>
                        <div
                            onClick={eve => {
                                eve.stopPropagation();
                            }}
                            className={'partPop'}
                        >
                            <Button type="link" onClick={() => this.toDetail(record)} >
                                查看详情
                            </Button>
                        </div>
                },
            ],// 表格 行 配置
            page: 1,//当前页码数
            pageSize: 10,//页码大小
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo, this.state.searchVal);
                },
                page: 1,
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: false,
            },
            searchVal: {
                approveDate: '',
                approveUser: '',
                doctorCode: '',
                projectCode: ''
            }, // 筛选条件
            formModalVisible: false,
            allCount: 0,
            clickedTenantCode: '', // 当前点击的租户
            crfParams: null, // crf表单初始化
            repeatSubjectCodeList: [], // 重复受试者code列表
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        this.getPageData();
    }

    /**
     * 获取表单前置信息
     */
    toGetSubjectDetail = (subjectCode = '') => {
        return new Promise(resolve => {
            GetSubjectDetail(subjectCode).then(res => {
                if (res.code == 0) {
                    return resolve(res.data || {});
                }
            })
        });
    }

    /**
     *  获取表单前置条目信息
     */
    toGetFormsById = (id) => {
        return new Promise(resolve => {
            GetFormsById(id).then(res => {
                if (res.code == 0) {
                    return resolve(res.data || {});
                }
            })
        });
    }

    /*
     * 获取列表数据
     */
    getPageData = async (currPage, searchVal = {
        approveDate: '',
        approveUser: '',
        doctorCode: '',
        projectCode: '',
    }) => {
        this.setState({ searchVal });

        let { page, pageSize } = this.state.pagination;
        let params = {
            page: currPage || page,
            pageSize: pageSize,
        }

        if (searchVal.approveDate != '') {
            params['approveDate'] = searchVal.approveDate
        }
        if (searchVal.approveUser != '') {
            params['approveUser'] = searchVal.approveUser
        }
        if (searchVal.doctorCode != '') {
            params['doctorCode'] = searchVal.doctorCode
        }
        if (searchVal.projectCode != '') {
            params['projectCode'] = searchVal.projectCode
        }

        const res = await GetApproveRecordList(params);
        if (res && res.code == 0) {
            this.setState({
                allCount: res.data.total,
                dataList: [],
                pagination: {
                    ...this.state.pagination,
                    page: res.data.page,
                    current: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })

            if (res.data.rows) {
                this.setState({
                    dataList: [...res.data.rows],
                })
            }
        }
    }

    /**
      * 详情
      */
    toDetail = (item) => {
        this.toGetSubjectDetail(item.subjectCode).then((res) => {
            this.setState({
                repeatSubjectCodeList: res.repeatSubjectCodeList
            })

            if (res.visits.length != 0) {
                let id = res.visits[0] && res.visits[0].id;

                let spreadList = []
                res.visits.forEach(item => {
                    spreadList.push(
                        this.toGetFormsById(item.id)
                    )
                })
                Promise.all(spreadList).then(res => {
                    let dataForms = []
                    res.forEach(v => {
                        dataForms = dataForms.concat(v.forms)
                    })

                    let filterArr = (dataForms || []).map(item => {
                        return {
                            title: item.name,
                            id: item.id,
                            formCode: item.code
                        };
                    });

                    let forms = {
                        activeId: filterArr[0] && filterArr[0].id,
                        forms: filterArr,
                        state: dataForms[0] && dataForms[0].inputState
                    };
                    localStorage.setItem('localStorage_forms', JSON.stringify(forms));
                    this.setState({
                        clickedTenantCode: item.tenantCode
                    }, () => {
                        this.setState({
                            formModalVisible: true
                        })
                    })

                    // crf
                    if (dataForms[0] && dataForms[0].type == 'CRF') {
                        let d = dataForms[0];
                        let photoEditItem = sessionStorage.getItem('photoEditItem')
                        let crfParams = {
                            caseCode: item.subjectCode,
                            crfCode: d.code,
                            crfVersion: d.version,
                            eventCode: id,
                            headers: {},
                            projectCode: item.projectCode,
                            recordCode: d.id,
                            tenantCode: item.tenantCode,
                            userCode: item.userCode
                        }

                        this.setState({
                            crfParams
                        })
                    } else {
                        this.setState({
                            crfParams: null
                        })
                    }
                })
            }
        });;
    }

    /**
     * 当关闭表单触发
     */
    onCloseModal = () => {
        if (this.formRef.current) {
            this.formRef.current.closeModal();
        }
    }

    /**
     * 关闭弹框
     */
    closeFormModal = () => {
        this.setState({
            formModalVisible: false
        });
    }

    render() {
        let { columns, dataList, pagination, clickedTenantCode, allCount, crfParams, repeatSubjectCodeList } = this.state;

        return (
            <div className='dctRecord_list'>
                <div className={'table-list'}>
                    <Table
                        locale={{ emptyText: "暂无数据" }}
                        columns={columns}
                        dataSource={dataList}
                        showHeader={true}
                        pagination={dataList.length !== 0 && pagination}
                        rowKey={'subjectCode'}
                        footer={() => <div className='case-audit-table-footer' >{allCount > 0 && <>共{allCount}条记录</>}</div>}
                    />

                    {/* 表单填写弹框 */}
                    <Modal
                        visible={this.state.formModalVisible}
                        title={null}
                        onCancel={this.onCloseModal}
                        width={'90%'}
                        centered
                        closable={false}
                        destroyOnClose
                        maskClosable={false}
                        footer={null}>
                        <Form closeModal={this.closeFormModal} ref={this.formRef} textAreaMaxLen={1000} fromApproveRecord={true} {...this.props} tenantCode={clickedTenantCode} repeatCodes={repeatSubjectCodeList}></Form>
                    </Modal>

                    {/* 表单填写弹框 */}
                    <Modal
                        visible={this.state.formModalVisible}
                        title={null}
                        onCancel={this.onCloseModal}
                        width={'90%'}
                        centered
                        closable={false}
                        destroyOnClose
                        maskClosable={false}
                        footer={null}>
                        <Form closeModal={this.closeFormModal} ref={this.formRef} textAreaMaxLen={1000} fromApproveRecord={true} {...this.props} tenantCode={clickedTenantCode} crfParams={crfParams} repeatCodes={repeatSubjectCodeList}></Form>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default ApproveRecordList;