/**
 * 报名统计
 */
 import React from 'react';
 import './index.less';
 import moment from 'moment';
 import { withRouter } from 'react-router-dom';
 import {
     Input, Select, Button, message as Message, Table, message, Tag 
 } from "antd";
 import { createFromIconfontCN } from '@ant-design/icons';
 
 import 'moment/locale/zh-cn';
 import locale from 'antd/es/date-picker/locale/zh_CN';
 import PubSub from "pubsub-js";
 import RemarksModal from './modal/modal';
 import { GetCsoAuditList } from "../../../services/cso/CsoService";
 
 let env = process.env.REACT_APP_ENV;
 let _this;
 const { Option } = Select;
 const { Search } = Input;
 const IconFont = createFromIconfontCN({
     scriptUrl: [
         '//at.alicdn.com/t/font_2283082_sb9kcktrb1j.js',//icon-shanchu icon-tongji icon-quxiao icon-quyufangwenqushi icon-tianjia1 icon-xiugai
     ],
 });
 
 class QuerylistsComponent extends React.Component {
 
     constructor(props) {
         super(props);
         _this = this;
         _this.state = {
             visible: false,
             dataLists: [], // 列表集合
             columns: [
                 {
                     title: '创建人',
                     width: '',
                     render: (text, record, index) => {
                         return (
                             <div className={'con_div'}>
                                 <div className={'title'}>
                                     {record.createUser}
                                 </div>
                             </div>
                         )
                     }
                 },
                //  {
                //      title: '项目名称',
                //      width: '',
                //      render: (text, record, index) => {
                //          return (
                //              <div className={'con_div'}>
                //                  <div className={'title'}>
                //                      {record.projectNames}
                //                  </div>
                //              </div>
                //          )
                //      }
                //  },
                 {
                     title: '任务名称',
                     width: '',
                     render: (text, record, index) => {
                         return (
                             <div className={'con_div'}>
                                 <div className={'title'}>{record.taskName}</div>
                                 
                             </div>
                         )
                     }
                 },
                 {
                     title: '创建时间',
                     width: '',
                     render: (text, record, index) => {
                         return (
                             <div className={'con_div'}>
                                 <div className={'title'}>
                                     { record.createTime ? record.createTime.replace('T', ' ') : '' }
                                     {/* {record.createDate ? record.createDate.substring(0, 10) : ''} */}
                                 </div>
                             </div>
                         )
                     }
                 },
                 {
                     title: '操作',
                     width: '',
                     render: (text, record, index) => {
                         return (
                             <div className={'con_div'}>
                                <Button type='link' style={{ paddingLeft: 0}} onClick={() => { this.downloadExcel(record) }} >下载数据</Button>
                             </div>
                         )
                     }
                 }
             ],// 表格 行 配置
             page: 1,//当前页码数
             pagination: { // 分页组件元素对象信息
                 onChange: (pageNo) => {
                     this.getPageData(pageNo);
                 },
                 current: 1,
                 pageSize: 10,
                 total: 0,
                 showSizeChanger: false,
             },
         }
         this.timer = null
     }
 
     formRef = React.createRef();
     inputRef = React.createRef();
 
     componentDidMount() {
         _this.getPageData();
     }
 
     /**
      * 获取列表数据 
      */
     getPageData = async (curPage, name) => {
         let { page } = this.state;
         const params = {
             page: curPage ? curPage : page,
             pageSize: this.state.pagination.pageSize,
         }
 
         const res = await GetCsoAuditList(params);
         if (res && res.code == 0) {
             _this.setState({
                 dataLists: [...res.data.rows],
                 page: res.data.page,
                 pageSize: res.data.pageSize,
                 pagination: {
                     ...this.state.pagination,
                     current: res.data.page,
                     pageSize: res.data.pageSize,
                     total: res.data.total,
                 }
             })
         }
     }

    /**
      * 下载 
      */
     downloadExcel (rParams) {
        if (rParams.fileCode) {
          window.location.href = rParams.fileCode;
        //   window.open(rParams.fileCode);
        }
     }

    /**
     * 设置关闭隐藏状态和要传递的参数
     */
    closeModal = () => {
        this.setState({
            visible: false,
        });
    }
 
     render() {
         let { columns, dataLists, pagination } = this.state;
 
         dataLists.map((item, index) => {
             item['key'] = index + 1
         })
 
         return (
             <div className='cso_queryList_wrap'>
                 <div className={'queryList'}>
                     <div className={'header_content'}>
                         <div className={'left_wrap'}>
                             <span>数据统计</span><br/>
                             <span className={'subTitle'}>共 {pagination.total} 条记录</span>
                         </div>
                         <div className={'right_wrap'}>
                                <Button type='primary'onClick={() => { this.setState({ visible: true })} } >上传审核结果</Button>
                         </div>
                     </div>
 
                     <Table
                         locale={{ emptyText: "暂无数据" }}
                         columns={columns}
                         dataSource={dataLists}
                         defaultExpandAllRows={true}
                         expandIconAsCell={false}
                         pagination={dataLists.length !== 0 && pagination}
                         rowKey={'id'}
                         size='small'
                     />
                 </div>
                {/* 批量导入参与者 */}
                <div className={'modal'}>
                    {
                        this.state.visible 
                        &&
                        <RemarksModal
                            visible={this.state.visible}
                            closeModal={this.closeModal}
                        />
                    }

                </div>
             </div>
         );
     }
 }
 export default withRouter(QuerylistsComponent);