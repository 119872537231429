export default {
    // 毫秒转化成天时分秒的时间格式
    formatDuring (time) {
        let currTime = new Date(time).getTime();
        let nowTime = new Date().getTime();
        let mss = nowTime - currTime;
        let days = parseInt(mss / (1000 * 60 * 60 * 24));
        let hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
        
        // var seconds = (mss % (1000 * 60)) / 1000;
        // return days + " 天 " + hours + " 小时 " + minutes + " 分钟 " + seconds + " 秒 ";

        days = days > 0 ? `${days}天` : '';
        hours = hours > 0 ? `${hours}小时` : '';
        minutes = minutes > 0 ? `${minutes}分钟` : '';

        return days + hours + minutes;
    },

    // 开发或测试环境项目
    matchCodesTEST (codes) {
        if (!codes) {
            return codes;
        }
        return codes.replace('PROJECT10000145','肺癌')
        .replace('PROJECT10000146','8888')
        .replace('PROJECT10000147','测试092801')
        .replace('PROJECT10000148','测试092802')
        .replace('PROJECT10000149','测试092803')
        .replace('PROJECT10000150','测试092804')
        .replace('PROJECT10000151','测试092806')
        .replace('PROJECT10000152','测试09280111')
        .replace('PROJECT10000153','测试前辍')
        .replace('PROJECT10000154','测试0928011111')
        .replace('PROJECT10000155','23')
        .replace('PROJECT10000156','测试092801122')
        .replace('PROJECT10000157','肺癌')
        .replace('PROJECT10000158','肝癌')
        .replace('PROJECT10000159','胃癌')
        .replace('PROJECT10000160','鼻咽癌')
        .replace('PROJECT10000161','黑色素瘤')
        .replace('PROJECT10000162','肠外营养')
        .replace('PROJECT10000163','食管癌')
        .replace('PROJECT10000175','胆管癌')
    },

    // 生产环境项目prod
    matchCodesPROD (codes) {
        if (!codes) {
            return codes;
        }
        return codes.replace('PROJECT10000060','白血病')
        .replace('PROJECT10000061','肝癌')
        .replace('PROJECT10000062','肺癌')
        .replace('PROJECT10000063','黑色素瘤')
        .replace('PROJECT10000064','胃癌')
        .replace('PROJECT10000065','头颈部癌')
        .replace('PROJECT10000067','食管癌')
        .replace('PROJECT10000077','胆管癌')
    }
}