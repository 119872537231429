import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef, Children } from 'react';
import { createFromIconfontCN } from '@ant-design/icons';
import styles from './readRuleDetail.module.scss';
import { Button, Col, Input, Row, Select } from 'antd';
import { Tree } from 'antd';
import { AddDirectory, DeleteDirectory, GetDeCodeList, GetEditRuleInfo, GetReadRuleInfo, GetRuleById, GetRuleConfigSelects, ModifyDirectory, SearchRule } from '../../../../services/config/ConfigService';

const { DirectoryTree } = Tree;
const { Option } = Select;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2343612_4qmxqp0jbfl.js',
    ],
});

function ReadRuleDetail(props: any, ref: any) {
    //当前选中的版本
    const [version, setVersion] = useState<any>();
    //脱敏规则详情
    const [ruleInfo, setRuleInfo] = useState<any>();
    //版本list
    const [versionList, setVersionList] = useState<any>([]);
    //当前规则configurationId
    const [configurationId, setConfigurationId] = useState<string>();
    //ruleElementList
    const [ruleElementList, setRuleElementList] = useState<any>([]);
    //推荐版本
    const [versionRecom, setVersionRecom] = useState<any>();
    //treeData
    const [treeData, setTreeData] = useState<any>();
    //当前选中的节点
    const [selectedNode, setSelectedNode] = useState<any>();
    //指定展开的树节点
    const [expandedKeys, setExpandedKeys] = useState<any>();
    //选中的树节点
    const [selectedKeys, setSelectedKeys] = useState<any>();
    //当前操作名称
    const [currentAction, setCurrentAction] = useState<any>();
    //数据元编码
    const [deCode, setDeCode] = useState<any>();
    //数据元名称
    const [deCodeName, setDeCodeName] = useState<any>();
    //数据安全性
    const [security, setSecurity] = useState<any>();
    //数据安全性枚举list
    const [securityList, setSecurityList] = useState<any>([]);
    //数据可用性
    const [usability, setUsability] = useState<any>('low');
    //数据可用性枚举列表
    const [usabilityList, setUsabilityList] = useState<any>([]);
    //脱敏规则
    const [rule, setRule] = useState<any>();
    //脱敏规则code
    const [ruleCode, setRuleCode] = useState<any>();
    //脱敏优先级
    const [rulePriority, setRulePriority] = useState<any>();

    //lengthmasking 遮盖类型
    const [lengthmaskingType, setLengthmaskingType] = useState<any>();
    //lengthmasking 前n个字符
    const [lengthmaskingBefore, setLengthmaskingBefore] = useState<any>();
    //lengthmasking 后n个字符
    const [lengthmaskingAfter, setLengthmaskingAfter] = useState<any>();

    //rangemasking 遮盖类型
    const [rangemaskingType, setRangemaskingType] = useState<any>();
    //rangemasking 开始位置
    const [rangemaskingStart, setRangemaskingStart] = useState<any>();
    //rangemasking 开始位置
    const [rangemaskingEnd, setRangemaskingEnd] = useState<any>();

    //aes128 密钥
    const [aes128Key, setAes128Key] = useState<any>();
    //aes128 加密初始位置
    const [aes128Start, setAes128Start] = useState<any>();
    //aes128 是否base64
    const [aes128IsBase64, setAes128IsBase64] = useState<any>();

    //aes256 密钥
    const [aes256Key, setAes256Key] = useState<any>();
    //aes256 加密初始位置
    const [aes256Start, setAes256Start] = useState<any>();
    //aes256 是否base64
    const [aes256IsBase64, setAes256IsBase64] = useState<any>();

    //初始化
    useEffect(() => {
        initData();
    }, [])

    //初始化数据
    const initData = () => {
        //获取数据安全性 && 数据可用性下拉列表
        GetRuleConfigSelects().then(res => {
            if (res) {
                setSecurityList(res.securitySelectEnumList);
                setUsabilityList(res.usabilitySelectEnumList);
            }
            setConfigurationId(props.match.params.id);
            setVersion(props.match.params.version);
            //设置推荐版本
            setVersionRecom(props.match.params.version);
        })
    }

    //根据选中的节点id设置保存的值
    const setValueById = (key: any) => {
        if (ruleElementList && ruleElementList.length > 0) {
            let findItem = ruleElementList.find((item: any) => {
                return item.directoryId === key[0]
            })
            if (findItem) {
                setDeCode(findItem.deCode);
                setDeCodeName(findItem.name);
                let security = securityList.find((item: any) => {
                    return item.code === findItem.security;
                })
                if (security) {
                    setSecurity(security.name);
                }
                let usability = usabilityList.find((item: any) => {
                    return item.code === findItem.usability;
                })
                if (usability) {
                    setUsability(usability.name);
                }
                setRule(findItem.ruleName);
                setRuleCode(findItem.ruleCode);
                setRulePriority(findItem.priorityNum);
                if (findItem.ruleParams) {
                    switch (findItem.ruleCode) {
                        case 'lengthmasking':
                            if (findItem.ruleParams.type === 1) {
                                setLengthmaskingType('遮盖');
                            }
                            else if (findItem.ruleParams.type === 2) {
                                setLengthmaskingType('保留');
                            }
                            setLengthmaskingBefore(findItem.ruleParams.n);
                            setLengthmaskingAfter(findItem.ruleParams.m);
                            break;
                        case 'rangemasking':
                            if (findItem.ruleParams.type === 1) {
                                setRangemaskingType('遮盖');
                            }
                            else if (findItem.ruleParams.type === 2) {
                                setRangemaskingType('保留');
                            }
                            setRangemaskingStart(findItem.ruleParams.x);
                            setRangemaskingEnd(findItem.ruleParams.y);
                            break;
                        case 'aes128':
                            setAes128Key(findItem.ruleParams.key);
                            setAes128Start(findItem.ruleParams.iv);
                            setAes128IsBase64(findItem.ruleParams.base64 ? '是' : '否');
                            break;
                        case 'aes256':
                            setAes256Key(findItem.ruleParams.key);
                            setAes256Start(findItem.ruleParams.iv);
                            setAes256IsBase64(findItem.ruleParams.base64 ? '是' : '否');
                            break;
                        default:
                            break;
                    }
                }
            }
            else {
                setDeCode(null);
                setDeCodeName(null);
                setSecurity(null);
                setUsability(null);
                setRule(null);
                setRuleCode(null);
                setRulePriority(null);
                setLengthmaskingType(null);
                setLengthmaskingBefore(null);
                setLengthmaskingAfter(null);
                setRangemaskingType(null);
                setRangemaskingStart(null);
                setRangemaskingEnd(null);
                setAes128Key(null);
                setAes128Key(null);
                setAes128IsBase64(null);
                setAes256Key(null);
                setAes256Key(null);
                setAes256IsBase64(null);
            }
        }
    }

    useEffect(() => {
        if (selectedKeys) {
            setValueById(selectedKeys);
        }
    }, [ruleElementList])

    //findIndex方法
    const findItem = (data: any, key: any) => {
        let findedItem;
        for (let item of data) {
            if (item.key === key) {
                findedItem = item
            }
            else {
                findItem(item.children, key)
            }
        }
        return findedItem;
    }

    //处理数据
    const filterRuleData = (data: any) => {
        if (data) {
            let filterFun = (data: any) => {
                for (let item of data) {
                    if (item.children && item.children.length > 0) {
                        let findResult = findItem(data, item.key);
                        if (findResult) {
                            if (item.parentId === 0) {
                                findResult.icon = item.parentId === 0 ? <IconFont type="icondatabase"></IconFont> : <React.Fragment></React.Fragment>;
                            }
                        }
                        filterFun(item.children);
                    }
                    else {
                        //叶子节点
                        if (item.isLeaf) {
                            let findResult = findItem(data, item.key);
                            if (findResult) {
                                findResult.icon = <IconFont type="iconfile2"></IconFont>;
                            }
                        }
                    }
                }
            }
            filterFun(data);
            setTreeData(data);
            setDefaultKeys(data);
        }
    }

    //设置默认展开&&默认选中的节点
    const setDefaultKeys = (data: any) => {
        let expandedKeys: any[] = [];
        let selectedKeys: any[] = [];
        let selectedNode;
        if (data && data.length > 0) {
            expandedKeys.push(data[0].key);
            if (data[0].children && data[0].children.length > 0) {
                let judgeIsOut = false;
                let filterData = (dataItem: any) => {
                    for (let item of dataItem) {
                        if (item.children && item.children.length > 0) {
                            if (item.isLeaf) {
                                selectedKeys = [item.key];
                                selectedNode = item;
                                judgeIsOut = true;
                            }
                            else {
                                expandedKeys.push(item.key);
                                filterData(item.children);
                            }
                        }
                        else {
                            if (item.isLeaf) {
                                selectedKeys = [item.key];
                                selectedNode = item;
                                judgeIsOut = true;
                            }
                        }
                        if (judgeIsOut) {
                            return;
                        }
                    }
                }
                filterData(data[0].children);
                setExpandedKeys(expandedKeys);
                setSelectedKeys(selectedKeys);
                setSelectedNode(selectedNode);
                setCurrentAction('setRule');
            }
            else {
                setExpandedKeys([data[0].key]);
                setSelectedKeys([data[0].key]);
                setSelectedNode(data[0]);
                setCurrentAction('noRule');
            }
        }
    }

    //当选择节点
    const onSelect = (keys: React.Key[], info: any) => {
        setSelectedKeys([...keys]);
        setSelectedNode(info.node);
        setValueById([...keys]);
        if (info.node.isLeaf) {
            setCurrentAction('setRule');
        }
        else {
            setCurrentAction('noRule');
        }
    }

    //当展开节点
    const onExpand = (keys: React.Key[]) => {
        setExpandedKeys([...keys])
    };

    //当右击
    const onRightClick = (params: any) => {

    }

    //当切换版本
    const onVersionRecomChange = (value: any) => {
        setVersionRecom(value);
    }

    useEffect(() => {
        //只读状态规则详情
        if (versionRecom) {
            GetReadRuleInfo({ id: props.match.params.id, version: versionRecom }).then(res => {
                if (res) {
                    setRuleInfo(res.header);
                    let filterVersionList = res.versionList.sort(compare);
                    setVersionList(filterVersionList);
                    filterRuleData(res.directoryDtos);
                    setRuleElementList(res.ruleElementList);
                }
            })
        }
    }, [versionRecom])

    //排序函数
    const compare = (x: any, y: any) => {//比较函数
        if (x > y) {
            return -1;
        } else if (x < y) {
            return 1;
        } else {
            return 0;
        }
    }

    //根据ruleCode选择不同表单
    const switchFormByRuleCode = () => {
        if (ruleCode) {
            switch (ruleCode) {
                case 'lengthmasking':
                    return (
                        <React.Fragment>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>
                                            遮盖类型：
                                        </div>
                                <div className={styles.actionForm}>
                                    {lengthmaskingType ? lengthmaskingType : '未配置'}
                                </div>
                            </div>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>
                                    {`前n个字符${lengthmaskingType}：`}
                                </div>
                                <div className={styles.actionForm}>
                                    {lengthmaskingBefore ? lengthmaskingBefore : '未配置'}
                                </div>
                            </div>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>
                                    {`前m个字符${lengthmaskingType}：`}
                                </div>
                                <div className={styles.actionForm}>
                                    {lengthmaskingAfter ? lengthmaskingAfter : '未配置'}
                                </div>
                            </div>
                        </React.Fragment>
                    )
                    break;
                case 'rangemasking':
                    return (
                        <React.Fragment>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>
                                                遮盖类型：
                                            </div>
                                <div className={styles.actionForm}>
                                    {rangemaskingType ? rangemaskingType : '未配置'}
                                </div>
                            </div>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>
                                                开始位置：
                                            </div>
                                <div className={styles.actionForm}>
                                    {rangemaskingStart ? rangemaskingStart : '未配置'}
                                </div>
                            </div>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>
                                                结束位置：
                                            </div>
                                <div className={styles.actionForm}>
                                    {rangemaskingEnd ? rangemaskingEnd : '未配置'}
                                </div>
                            </div>
                        </React.Fragment>
                    )
                    break;
                case 'aes128':
                    return (
                        <React.Fragment>
                            {/* <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    key密钥：
                                                </div>
                                <div className={styles.actionForm}>
                                    {aes128Key ? aes128Key : '未配置'}
                                </div>
                            </div> */}
                            {/* <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>
                                                    加密初始位置：
                                                </div>
                                <div className={styles.actionForm}>
                                    {aes128Start ? aes128Start : '未配置'}
                                </div>
                            </div>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>
                                                    是否为base64：
                                                </div>
                                <div className={styles.actionForm}>
                                    {aes128IsBase64 ? aes128IsBase64 : '未配置'}
                                </div>
                            </div> */}
                        </React.Fragment>
                    )
                    break;
                case 'aes256':
                    return (
                        <React.Fragment>
                            {/* <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    key密钥：
                                                    </div>
                                <div className={styles.actionForm}>
                                    {aes256Key ? aes256Key : '未配置'}
                                </div>
                            </div> */}
                            {/* <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>
                                                        加密初始位置：
                                                    </div>
                                <div className={styles.actionForm}>
                                    {aes256Start ? aes256Start : '未配置'}
                                </div>
                            </div>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>
                                                        是否为base64：
                                                    </div>
                                <div className={styles.actionForm}>
                                    {aes256IsBase64 ? aes256IsBase64 : '未配置'}
                                </div>
                            </div> */}
                        </React.Fragment>
                    )
                    break;
                default:
                    break;
            }
        }
    }

    //根据不同操作选择不同操作view
    const switchAction = () => {
        switch (currentAction) {
            case 'setRule':
                return (
                    <React.Fragment>
                        <div className={styles.head}>
                            <div className={styles.title}>
                                {selectedNode ? `脱敏数据设置(${selectedNode.title})：` : '脱敏数据设置：'}
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>

                                    数据元编码：
                                </div>
                                <div className={styles.actionForm}>
                                    {deCode ? deCode : '未配置'}
                                </div>
                            </div>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>

                                    数据元名称：
                                </div>
                                <div className={styles.actionForm}>
                                    {deCodeName ? deCodeName : '未配置'}
                                </div>
                            </div>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>

                                    数据安全性：
                                </div>
                                <div className={styles.actionForm}>
                                    {security ? security : '未配置'}
                                </div>
                            </div>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>

                                    数据可用性：
                                </div>
                                <div className={styles.actionForm}>
                                    {usability ? usability : '未配置'}
                                </div>
                            </div>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>

                                    脱敏规则：
                                </div>
                                <div className={styles.actionForm}>
                                    {rule ? rule : '未配置'}
                                </div>
                            </div>
                            <div className={styles.actionItem}>
                                <div className={styles.label}>
                                    <div className={styles.notEmpty}>*</div>

                                    规则优先级：
                                </div>
                                <div className={styles.actionForm}>
                                    {rulePriority ? rulePriority : '未配置'}
                                </div>
                            </div>
                            {
                                switchFormByRuleCode()
                            }
                        </div>
                    </React.Fragment>

                )
                break;
            case 'noRule':
                return (
                    <React.Fragment>
                        <div className={styles.head}>
                            <div className={styles.title}>
                                未选择脱敏数据
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.noRule}>
                                <IconFont type="icontoLeft"></IconFont>
                                请选择左边已保存的脱敏数据后查看!
                            </div>
                        </div>
                    </React.Fragment>
                )
                break;
            default:
                break;
        }
    }

    return (
        <div className={styles.ruleDetail}>
            <div className={styles.header}>
                <div className={styles.title}>
                    脱敏规则设置
                 </div>
                <div className={styles.headerContent}>
                    <Row>
                        <Col span={6}>
                            <div className={styles.item}>
                                <div className={styles.label}>
                                    作用域类型：
                                </div>
                                <div className={styles.content}>
                                    {ruleInfo ? ruleInfo.name : ''}
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={styles.item}>
                                <div className={styles.label}>
                                    作用域：
                                </div>
                                <div className={styles.content}>
                                    {ruleInfo ? ruleInfo.scopeName : ''}
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={styles.item}>
                                <div className={styles.label}>
                                    创建人：
                                </div>
                                <div className={styles.content}>
                                    {ruleInfo ? ruleInfo.createUser : ''}
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={styles.item}>
                                <div className={styles.label}>
                                    创建时间：
                                </div>
                                <div className={styles.content}>
                                    {ruleInfo ? ruleInfo.createTime : ''}
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={styles.item}>
                                <div className={styles.label}>
                                    推荐版本：
                                </div>
                                <div className={styles.content}>
                                    {versionRecom ? versionRecom : ''}
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={styles.item}>
                                <div className={styles.label}>
                                    最新版本：
                                </div>
                                <div className={styles.content}>
                                    {ruleInfo ? ruleInfo.versionLatest : ''}
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={styles.item}>
                                <div className={styles.label}>
                                    最新发布时间：
                                </div>
                                <div className={styles.content}>
                                    {ruleInfo ? ruleInfo.versionTime : ''}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className={styles.detail_content}>
                <Row>
                    <Col span={8}>
                        <div className={styles.content_left}>
                            <div className={styles.directory}>
                                <div className={styles.head}>
                                    <div className={styles.title}>
                                        目录
                                    </div>
                                    <div className={styles.select}>
                                        <Select placeholder="选择版本" value={versionRecom} style={{ width: 150 }} onChange={onVersionRecomChange}>
                                            {
                                                versionList && versionList.map((item: any, index: any) => {
                                                    return <Option value={item} key={index}>{item}</Option>
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div className={styles.content}>
                                    <DirectoryTree
                                        onSelect={onSelect}
                                        onExpand={onExpand}
                                        expandedKeys={expandedKeys}
                                        showLine={{ showLeafIcon: false }}
                                        selectedKeys={selectedKeys}
                                        treeData={treeData}
                                        onRightClick={onRightClick}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={16}>
                        <div className={styles.content_right}>
                            <div className={styles.setContent}>
                                {switchAction()}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}


export default ReadRuleDetail;