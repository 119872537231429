import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Checkbox, Input, Tooltip } from 'antd';
import verify, { replaceFieldRemind } from '../../utils/verify';
import { saveFormData, updateFormData } from "../../../../services/data/DataService";
import ActionView from '../../com/actionView/actionView';
import './checkbox.less';
import formAction from '../../utils/formAction';
import { InfoCircleOutlined } from '@ant-design/icons';

function CheckboxCom(props: any, ref: any) {
    const [value, setValue] = useState<any>();
    const [data, setData] = useState<any>();
    const [optional, setOptional] = useState<any>([]);
    const [formId, setFormId] = useState<any>();
    const [dataActions, setDataActions] = useState<any>([]);
    const [formIsRead, setFormIsRead] = useState<boolean>(true);
    const [timeObject, setTimeObject] = useState<any>();
    const [savedValues, setSavedValues] = useState<any>([]);
    const [otherValue, setOtherValue] = useState<any>('');
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [updatedValue, setUpdatedValue] = useState<any>();

    // 超范围异常提醒
    const [annotation, setAnnotation] = useState<any>(null);

    useEffect(() => {
        if (props.data) {
            //设置dataActions
            setDataActions(props.dataActions);
            //表单是否只读
            setFormIsRead(props.formIsRead);
            //获取当前表单的id
            setFormId(props.formId);
            //该条表单元素的数据
            setData(props.data);
            //已经保存的表单数据
            setSavedValues(props.savedValues);
            //如果是修改时
            if (props.isUpdate) {
                setIsUpdate(props.isUpdate)
            }
            else {
                setIsUpdate(false)
            }

            //设置当前表单已存的value
            let currentItem = props.savedValues.find((i: any) => {
                return i.dataCode == props.data.dataCode
            });
            if (currentItem && currentItem.value) {
                if (props.data && props.data.other) {
                    if (currentItem.value.indexOf(props.data.other.key) !== -1) {
                        setOtherValue(currentItem.value[currentItem.value.length - 1]);
                        let filterCurrentValue = [];
                        for (let i = 0; i < currentItem.value.length - 1; i++) {
                            filterCurrentValue.push(currentItem.value[i])
                        }
                        setValue([...filterCurrentValue]);
                        setUpdatedValue([...filterCurrentValue]);
                    }
                    else {
                        setOtherValue('');
                        setValue([...currentItem.value]);
                        setUpdatedValue([...currentItem.value]);
                    }
                }
                else {
                    // 设置当前值
                    setValue([...currentItem.value]);
                    setUpdatedValue([...currentItem.value]);
                }
            }
            else {
                // 默认选中和暂存默认值
                if (props.data.defaultValue) {
                    let arr = Array(Object.keys(props.data.optional).length).fill('');
                    arr[props.data.defaultValue] = props.data.defaultValue;
                    setValue(arr);
                    filterValue(arr);
                } else {
                    setValue(Array(Object.keys(props.data.optional).length).fill(''));
                }
                setOtherValue('');
                setUpdatedValue(null);
            }


            // 超范围异常提醒 
            if (props.data.remind || props.data.reminds) {
                // feature: 使用列表中的其他字段进行比较
                if(props.data.remind) {
                    replaceFieldRemind(props.data.remind, props.savedValues)
                }
                //
                setAnnotation(
                    verify.checkValueAnnotation(
                        currentItem ? currentItem.value : value,
                        props.data,
                        props.formCenterCode,
                        props.savedValues
                    )
                );
            }
        }

    }, [props])


    //当提交后显示的value
    const showSavedValue = () => {
        //设置当前表单已存的value
        let currentItem = props.savedValues.find((i: any) => {
            return i.dataCode == props.data.dataCode
        });
        if (currentItem && currentItem.value) {
            let filterArr = [];
            for (let i of currentItem.value) {
                if (data.other) {
                    if (i !== data.other.key) {
                        if (data.optional[i]) {
                            filterArr.push(data.optional[i]);
                        }
                    }
                }
                else {
                    if (data.optional[i]) {
                        filterArr.push(data.optional[i]);
                    }
                }
            }
            if (data.other) {
                if (currentItem.value.indexOf(data.other.key) !== -1) {
                    filterArr.push(currentItem.value[currentItem.value.length - 1])
                }
            }
            return filterArr.join(',')
        }
        else {
            return '未录入'
        }
    }

    //checkbox的点击事件
    const onClickCheckbox = (val: any) => {
        return () => {
            let filterArr = value;
            let i = filterArr.indexOf(val);
            if (i !== -1) {
                filterArr.splice(i, 1, '');
            }
            else {
                for (let i = 0; i < Object.keys(data.optional).length; i++) {
                    if (Object.keys(data.optional)[i] === val) {
                        filterArr.splice(i, 1, val)
                    }
                }
            }
            if (data.validationRules) {
                data.validationRules.forEach((item: { type: string; condition: string; rule: any; }, index: any) => {
                    if (item.type === 'logic') {
                        if (item.condition === 'mutex') {
                            //当前的value在rule数组中的index
                            let arrIndex: any;
                            item.rule.forEach((ruleItem: string | any[], ruleItemIndex: any) => {
                                if (ruleItem.indexOf(val) !== -1) {
                                    arrIndex = ruleItemIndex;
                                }
                            })
                            if (arrIndex !== undefined) {
                                item.rule.forEach((ruleItem: any[], ruleItemIndex: any) => {
                                    if (ruleItemIndex !== arrIndex) {
                                        ruleItem.forEach((key: any) => {
                                            let i = filterArr.indexOf(key);
                                            if (i !== -1) {
                                                filterArr[i] = '';
                                            }
                                        })
                                    }
                                })
                            }
                        }
                    }
                })
            }
            setAnnotation(verify.checkValueAnnotation(filterArr, data, props.formCenterCode, props.savedValues));
            setValue(filterArr);
            filterValue(filterArr);
        }
    }


    //处理数据
    const filterValue = (value: any, otherVal?: any) => {
        let filterArr = JSON.parse(JSON.stringify(value));
        let filterValue: any;
        let mData = data || props.data;
        if (mData.other) {
            if (filterArr.indexOf(mData.other.key) !== -1) {
                if (otherVal) {
                    filterValue = value.concat(otherVal);
                }
                else if (otherVal === '') {
                    filterValue = value.concat('');
                }
                else if (otherVal === undefined) {
                    filterValue = value.concat(otherValue);
                }
            }
            else {
                filterValue = value;
            }
        }
        else {
            filterValue = value;
        }

        if (isUpdate) {
            setUpdatedValue(filterValue);
        }

        let extendAttribute = ''
        if (props.data.isAdd) {
            extendAttribute = 'isAdd=true'
        }
        let values = [
            {
                dataCode: mData.dataCode,
                deCode: mData.deCode,
                value: filterValue,
                extendAttribute: extendAttribute ? extendAttribute : undefined
            }
        ]
        if (timeObject) {
            clearTimeout(timeObject);
        }
        let time = setTimeout(() => {
            if (!isUpdate) {
                saveData(formId, values);
            }
        }, 200);
        setTimeObject(time)
    }

    //暂存数据
    const saveData = (formId: any, values: any) => {
        let params = {
            formId: formId,
            submit: false,
            values: values
        }
        formAction.saveData(params);
        // props.startTimerFun();
        props.updateFun()
        // saveFormData(params).then((res) => {
        //     props.updateFun()
        // })
    }

    //当other输入change
    const onOtherInputChange = (e: any) => {
        setOtherValue(e.target.value);
        filterValue(value, e.target.value);
    }

    useImperativeHandle(ref, () => ({
        // changeVal 就是暴露给父组件的方法
        getModifyValue: () => {
            let params = {
                dataCode: data.dataCode,
                deCode: data.deCode,
                formId: formId,
                value: updatedValue,
            }
            return params;
        },
    }));

    //选择other对应的表单
    const switchOtherField = () => {
        if (data && data.other) {
            if (value && value.indexOf(data.other.key) !== -1) {

                let type = data.other.type;
                switch (type) {
                    //input
                    case 'input':
                        return (
                            // <div style={{ marginTop: '10px' }}>
                            <Input allowClear={true} disabled={!formIsRead} style={{ width: '150px' }} value={otherValue} placeholder={data ? `请输入${data.label}` : ''} suffix={data && data.unit ? data.unit : ''} onChange={onOtherInputChange} />
                            // </div>
                        )
                        break;
                    default:
                        break;
                }
            }
        }
        else {
            return null;
        }
    }

    //checkboxView
    const checkboxView = () => {
        let checkboxFilterArr = [];
        if (data && data.optional) {
            for (let optionItem of Object.keys(data.optional)) {
                checkboxFilterArr.push(
                    {
                        label: data.optional[optionItem],
                        value: optionItem,
                        checked: value && value.indexOf(optionItem) !== -1 ? true : false
                    }
                )
            }
        }
        return (
            <div className="checkboxView">
                {
                    checkboxFilterArr && checkboxFilterArr.length > 0 && checkboxFilterArr.map((item: any, index: any) => {
                        return (
                            <Checkbox checked={item.checked} disabled={!formIsRead} onClick={onClickCheckbox(item.value)} value={item.value} key={index}>{item.label}</Checkbox>
                        )
                    })
                }
                {
                    switchOtherField()
                }
                {
                    annotation && annotation.status &&
                    <Tooltip title={annotation.message}>
                        <InfoCircleOutlined style={{ color: 'red', fontSize: 16 }} />
                    </Tooltip>
                }
            </div>
        )
    }

    return (
        <div className="checkboxCom">
            {/* {
                formIsRead ? <React.Fragment>
                    {
                        checkboxView()
                    }
                    {
                        switchOtherField()
                    }
                </React.Fragment> : <div>{showSavedValue()}</div>
            } */}
            {
                <React.Fragment>
                    {
                        checkboxView()
                    }
                </React.Fragment>
            }
        </div>
    )
}

export default forwardRef(CheckboxCom);
