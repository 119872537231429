/**
 * 项目报表详情
 */
import React, { useState, useEffect } from 'react';
import './index.less';
import { message, Table } from 'antd';

function Summarize(props) {

  // 汇总列表
  const [data, setData] = useState([]);
  // tableLoading
  const [tableLoading, setTableLoading] = useState(true);
  
  const columns = [
    {
      title: '范围',
      dataIndex: 'range',
      key: 'range',
      fixed: 'left',
      render: (text, record, index) => {
        return (
          <div className={'con_div'}>
            {record.range == 'PROJECT' ? '项目开始-统计时间截止' : record.range == 'CURRENT' ? '本期汇总' : ''}
          </div>
        )
      }
    },
    {
      title: '时间',
      dataIndex: 'time',
      key: 'time',
      render: (text, record, index) => {
          return (
              <div className={'con_div'}>
                  {`${record.beginTime ? record.beginTime.substring(0,10) : ''} ~ ${record.endTime ? record.endTime.substring(0,10) : ''}`}
              </div>
          )
      }
    },
    {
      title: '图片审核',
      children: [
        {
          title: '任务总数',
          dataIndex: 'imageTotal',
          key: 'imageTotal',
        },
        {
          title: '已处理数',
          dataIndex: 'imageProcessedNum',
          key: 'imageProcessedNum',
        },
        {
          title: '剩余数',
          dataIndex: 'imageSurplusNum',
          key: 'imageSurplusNum',
        }
      ],
    },
    {
      title: '录入任务',
      children: [
        {
          title: '任务总数',
          dataIndex: 'inputTotal',
          key: 'inputTotal',
        },
        {
          title: '已处理数',
          dataIndex: 'inputProcessedNum',
          key: 'inputProcessedNum',
        },
        {
          title: '剩余数',
          dataIndex: 'inputSurplusNum',
          key: 'inputSurplusNum',
        },
      ],
    }
  ];

  useEffect(() => {
    let summarizeData = JSON.parse(sessionStorage.getItem('summarizeData'))
    if (props.data) {
      setTableLoading(false)
      setData(props.data || summarizeData)
    }
  })
  
  return (
    <div className='details_Summarize'>
      <div className={'titleStyle'}>项目统计汇总</div>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        style={{ marginBottom: 15 }}
        size="small"
        loading={tableLoading}
      />
    </div>
  )
}
export default Summarize;
