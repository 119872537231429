/**
 * 模态框布局
 */

const targetName = sessionStorage.getItem('targetName') || '受试者'
export const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

/** 
 * 停止受试原因
*/
export const stopReasonList = [
    { vlaue: 0, name: 'AE' },
    { vlaue: 1, name: 'ADR' },
    { vlaue: 2, name: '失访' },
    { vlaue: 3, name: '康复' },
    { vlaue: 4, name: '死完' },
    { vlaue: 5, name: '未知' },
]

/**
 * 暂停受试原因
 */
export const pauseReasonList = [
    { vlaue: 0, name: '数据质量问题' },
    { vlaue: 1, name: '重复记录' },
    { vlaue: 2, name: 'AE' },
    { vlaue: 3, name: 'ADR' },
    { vlaue: 4, name: `${targetName}申请暂停` },
    { vlaue: 5, name: '暂时无法联系' },
    { vlaue: 6, name: '其它' },
]

/**
 * 废弃数据原因
 */
export const discardReasonList = [
    { vlaue: '0', name: `${targetName}失访` },
    { vlaue: '1', name: '数据重复' },
    { vlaue: '2', name: '知情同意书有误' },
    { vlaue: '3', name: `${targetName}申请废弃` },
    { vlaue: '4', name: '其它' },

]

/**
 * 暂停类型列表
 */
export const stopTypeList = [
    { value: 0, name: '停止受试' },
    { value: 1, name: '临时暂停' },
    { value: 2, name: '永久废弃' },
]

export const stopTypeList2 = [
    { value: 0, name: '停止受试' },
    { value: 1, name: '临时暂停' },

]

/**
 * 病史备注列表
 */
export const diseaseTypeList = [
    { value: 0, name: '个人病史' },
    { value: 1, name: '家族病史' },
    { value: 2, name: '过敏史' },
]

/**
 * 事件登记列表
 */
export const eventTypeList = [
    { value: 0, name: '不良事件' },
    { value: 1, name: '药物不良反应' },
]

/**
 * 与患者关系
 */
export const relativeList = [
    { value: 0, name: '父母' },
    { value: 1, name: '子女' },
    { value: 2, name: '兄妹' },
    { value: 3, name: '祖父母' },
    { value: 4, name: '其它' },
]

/**
 * AE事件分类
 */
export const AEeventClassList = [
    { value: 0, name: '严重不良事件' },
    { value: 1, name: '重要不良事件' },
    { value: 2, name: '非预期不良事件' },
]

/**
 * 事件程度
 */
export const eventDegreeList = [
    { value: 0, name: 'Mild' },
    { value: 1, name: 'Moderate' },
    { value: 2, name: 'Severe' },
]

/**
 * AE事件分级
 */
export const AEeventLevelList = [
    { value: 0, name: '分级1' },
    { value: 1, name: '分级2' },
    { value: 2, name: '分级3' },
    { value: 3, name: '分级4' },
    { value: 4, name: '分级5' },
]

/**
 * 不良反应分类
 */
export const untowardEffectList = [
    { value: 0, name: '药物不良反应ADR' },
    { value: 1, name: '可疑不良反应SAR' },
    { value: 2, name: '非预期药物不良反应UADR' },
    { value: 3, name: '可疑非预期严重不良反应SUSAR' },
]

/**
 * 相关性评定
 */
export const evaluateList = [
    { value: 0, name: '情况不明' },
    { value: 1, name: '肯定' },
    { value: 2, name: '很可能' },
    { value: 3, name: '可能' },
    { value: 3, name: '可疑' },
    { value: 3, name: '不可能' },
]

/**
 * 是否警示
 */
export const isWarnList = [
    { value: 0, name: '警示病史' },
    { value: 1, name: '不做警示' },
]

/**
 * 是否治愈
 */
export const isHealthList = [
    { value: '0', name: '已痊愈' },
    { value: '1', name: '未痊愈' },
]

/**
 * 严重程度
 */
export const severeLeavelList = [
    { value: '0', name: '严重' },
    { value: '1', name: '一般' },
    { value: '2', name: '轻度' },
]

