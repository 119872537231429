
import modStyl from './PhoneModal.module.scss'
import { Modal } from "antd"
import React, { useContext, useRef } from "react"
import UserInfo from './UserInfo'
import { DataType, visitTaskContext } from '..'
import CallPhoneNumber from './CallPhoneNumber'
import { maskPhoneNumber } from './utils'

type Props = {
  current: DataType;
  onClose: ()=> void;
}

const PhoneModal: React.FC<Props> = ({current, onClose})=> {

  const needRefresh = useRef(false)
  const ctx = useContext(visitTaskContext)

  const closeHandle = ()=> {
    onClose()
    if(needRefresh.current) {
      ctx.reload()
    }
  }

  return (
    <Modal visible={true} title="电话随访" onCancel={closeHandle} footer={null} closable destroyOnClose maskClosable={false}>
      <div className={modStyl.wrapper}>
        <div className={modStyl.row}>
          <div className={modStyl.label}>患者</div>
          <div className={modStyl.content}>
            <UserInfo data={current}/>
          </div>
        </div>
        <div className={modStyl.row}>
          <div className={modStyl.label}>联系号码</div>
          <div className={modStyl.content}>
            <p className={modStyl.readonly}>{maskPhoneNumber(current.subjectMobile)}</p>
          </div>
        </div>
        <div className={modStyl.row}>
          <CallPhoneNumber refresh={()=> needRefresh.current = true} phoneNumber={current.subjectMobile} visitTaskId={current.id}/>
        </div>
      </div>
    </Modal>
  )
}

export default PhoneModal