import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { GetDctImageStatistics } from "../../../../services/dct/DctService"

import passPNG from "../../static/images/pass.png";
import checkPNG from "../../static/images/check.png";
import rejectPNG from "../../static/images/reject.png";
import settlePNG from "../../static/images/settle.png";
import pendingPNG from "../../static/images/pending.png";

import modStyl from './Statistics.module.scss'

export type StatisticsActions = {
  refresh: ()=> Promise<void>
}
export type StatisticsProps = {

}
const Statistics = forwardRef<StatisticsActions, StatisticsProps>((props, ref)=> {
  const [items, setItems] = useState([
    {
      id: "pendingReviewTotal",
      icon: pendingPNG,
      name: "待审核图片",
      value: 0,
      color: "#276ec9",
    },
    {
      id: "reviewTotal",
      icon: checkPNG,
      name: "今日审核图片",
      value: 0,
      color: "#f39c00",
    },
    {
      id: "adoptTotal",
      icon: passPNG,
      name: "今日通过图片",
      value: 0,
      color: "#23aea8",
    },
    {
      id: "rejectTotal",
      icon: rejectPNG,
      name: "今日拒绝图片",
      value: 0,
      color: "#f83b24",
    },
    // {
    //   id: "settleAccountsTotal",
    //   icon: settlePNG,
    //   name: "可结算图片",
    //   value: 0,
    //   color: "#58278f",
    // },
  ]);

  const refresh = async ()=> {
    const {code, data} = await GetDctImageStatistics()
    if(code === 0) {
      setItems(
        items.map((item)=> {
          return {
            ...item,
            value: data[item.id]  ?? 0,
          }
        })
      )
    }
  }

  useImperativeHandle(ref, ()=> {
    return {
      refresh
    }
  }, [])

  useEffect(()=> {
    refresh()
  }, [])

  return (
    <div className={modStyl.wrapper}>
      <ul>
        {items.map((item)=> (
          <li key={item.id}>
            <dl style={{backgroundImage: 'url('+ item.icon +')'}}>
              <dt style={{color: item.color}}>{item.value}</dt>
              <dd>{item.name}</dd>
            </dl>
          </li>
        ))}
      </ul>
    </div>
  )
})

export default Statistics