/**
 * 项目报表
 */
import React from 'react';
import './projectReport.less';
import { Tabs } from "antd";
import MonthReport from "./monthReport/monthReport";
import {
    getReportTypes
} from "../../../../services/project/ProjectService";

const { TabPane } = Tabs;
let _this;

class ProjectReport extends React.Component {

    constructor(props) {
        super(props);
        //  创建ref容器
        _this = this;
        _this.state = {
            selectTabPane: 0, // 默认项目月报
            reportTypeList: [], // 报表类型
        }
    }

    componentDidMount() {
        this.getReportTypes();
    }

    /**
     * 
     * @param {*} 报表类型
     */
    getReportTypes = () => {
        getReportTypes().then(res => {
            this.setState({
                reportTypeList: [...res]
            })
        })
    }


    /**
     * 选择月报、季报、结项报告
     */
    onTabClick = (index) => {
        this.setState({
            selectTabPane: Number(index)
        })
    }

    render() {
        let { selectTabPane, reportTypeList } = this.state;
        let { projectId } = this.props;

        //  不展示季报，前端处理
        let showReportTypeList = [];
        reportTypeList.map((item, index) => {
            if (item.key != 3) {
                showReportTypeList.push(item)
            }
        })

        return (
            <div className={'projectReport'}>
                <div className='tabsTitle'>
                    <Tabs defaultActiveKey="0" tabPosition='top' onTabClick={this.onTabClick}>
                        {showReportTypeList.map((i, index) => {
                            return (
                                // TabPane 会多次共用同一个组件，会导致切换后A、B组价数据重复的问题
                                <TabPane tab={i.text} key={index} >
                                    {selectTabPane === (index) &&
                                        <MonthReport
                                            projectId={projectId}
                                            {...this.props}
                                            type={i.key}
                                        />
                                    }
                                </TabPane>
                            )
                        })}
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default ProjectReport;