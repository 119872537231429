import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {actionCreator} from '../store';
import './dictionary.less';
import {Form, Row, Col, Input, Button, Select, Table, Popconfirm} from 'antd';
import {DictionaryPageList, DictionariesDelete} from '../../../services/config/ConfigService'
import localStorageUtil from "../../../comm/localStorage";
import PubSub from "pubsub-js";
import {createFromIconfontCN} from "@ant-design/icons";

const FormItem = Form.Item;
const {Option} = Select;
let _this;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2343612_7unhssk7d2o.js',
    ],
});

class dictionary extends Component {

    constructor(props) {
        super(props);
        _this = this;
        this.state = {
            /** 页面查询条件元素*/
            identification: '',//字典标识
            name: '',//字典名称
            state: 'USE',//字典状态
            type: null,//字典类别
            createUser: '', // 发布者
            page: 1,//当前页码数
            pageSize: 10,//页码大小
            /** 接口列表元素*/
            distionaryList: [], // 字典数据集合
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo);
                },
                current: 1,
                pageSize: 10,
                total: 100,
                showSizeChanger: false,
            },
            columns: [
                {
                    align: 'center',
                    title: '字典名称',
                    key: 'name',
                    width: 120,
                    dataIndex: 'name'
                },
                {
                    align: 'center',
                    title: '字典标识',
                    key: 'identification',
                    width: 120,
                    dataIndex: 'identification'
                },
                {
                    align: 'center',
                    title: '分类',
                    key: 'typeName',
                    width: 120,
                    dataIndex: 'typeName',
                },
                {
                    align: 'center',
                    title: '发布机构',
                    key: 'releasePro',
                    width: 120,
                    dataIndex: 'releasePro'
                }/*,
                {
                    align: 'center',
                    title: '版本',
                    key: 'version',
                    width: 120,
                    dataIndex: 'version'
                }*/,
                {
                    align: 'center',
                    title: '状态',
                    key: 'stateName',
                    width: 120,
                    dataIndex: 'stateName'
                },
                {
                    align: 'center',
                    title: '创建时间',
                    key: 'createTime',
                    width: 180,
                    dataIndex: 'createTime'
                },
                {
                    align: 'center',
                    title: '创建人',
                    key: 'createUser',
                    width: 180,
                    dataIndex: 'createUserName',
                    render: (text, record, index) => (
                        <>
                            {record.createUserName}({record.createUser})
                        </>
                    )
                },
                {
                    align: 'center',
                    title: '操作',
                    width: 148,
                    fixed: 'right',
                    render: (text, item) => {
                        return (
                            <div className={'tableBtns'}>
                                <div onClick={() => {
                                    this.handleItemDetail(item)
                                }}>
                                    {this.formatIcon('iconchakan')}
                                    查看
                                </div>
                                <div onClick={() => {
                                    this.handleItemEdit(item)
                                }}>
                                    {this.formatIcon('iconbianji')}
                                    编辑
                                </div>
                                <Popconfirm
                                    title="确认删除该条数据?"
                                    okText='确认'
                                    cancelText='取消'
                                    onConfirm={() => {
                                        this.handleItemDelete(item)
                                    }}
                                >
                                    <div className={'del'}>
                                        {this.formatIcon('iconshanchu')}
                                        删除
                                    </div>
                                </Popconfirm>
                            </div>
                        )
                    }
                }
            ], // 表格 列 配置
            tableLoading: false, // 表格 loading
        };
    }

    formRef = React.createRef();

    componentDidMount() {
        this.props.handleStateSelectList();

        let fromUrl = localStorageUtil.get('fromUrl');
        if (fromUrl == 'config/dictionary/edit') {
            let dictionaryQuery = localStorageUtil.get('dictionaryQuery', true);
            if (dictionaryQuery) {
                let data = JSON.parse(dictionaryQuery)
                _this.formRef.current.setFieldsValue({
                    identification: data.identification ? data.identification : '',//字典标识
                    name: data.name ? data.name : '',//字典名称
                    state: data.state,//字典状态
                    type: data.type,//字典类别
                    createUser: data.createUser ? data.createUser : '', // 发布者
                })
                this.setState({
                    identification: data.identification ? data.identification : '',//字典标识
                    name: data.name ? data.name : '',//字典名称
                    state: data.state,//字典状态
                    type: data.type,//字典类别
                    createUser: data.createUser ? data.createUser : '', // 发布者
                }, () => {
                    this.getList()
                })
            }
        } else {
            localStorageUtil.remove('dictionaryQuery');
            this.getList()
        }

    }

    componentWillUnmount() {
        localStorageUtil.remove('fromUrl');
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName}/>) : undefined;
    }

    /**
     * 获取平台字典查询集合
     */
    getList = (currentPage) => {
        this.setState({
            tableLoading: true
        })
        const params = {
            identification: this.state.identification,
            name: this.state.name,
            state: this.state.state,
            type: this.state.type,
            createUser: this.state.createUser,
            page: currentPage ? currentPage : this.state.page,
            pageSize: this.state.pageSize,
        }
        DictionaryPageList(params).then(res => {
            if (res) {
                this.setState({
                    tableLoading: false,
                    distionaryList: res.rows,
                    page: res.page,
                    pageSize: res.pageSize,
                    pagination: {
                        ...this.state.pagination,
                        current: res.page,
                        pageSize: res.pageSize,
                        total: res.total,
                    }
                })
            } else {
                this.setState({
                    tableLoading: false
                })
            }

        })
    }

    /**
     * 跳转页面元素
     * @param pageNo
     */
    getPageData = (pageNo) => {
        this.getList(pageNo);
    }

    /**
     * 查询提交方法
     * @param fieldsValue
     */
    
    onSearch = (searchParams) => {
        const params = {
            ...searchParams,
            page: this.state.page,
            pageSize: this.state.pageSize,
        }
        DictionaryPageList(params).then(res => {
            this.setState({
                distionaryList: res.rows,
                page: res.page,
                pageSize: res.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.page,
                    pageSize: res.pageSize,
                    total: res.total,
                }
            })
        })
    }

    /**
     * 查看详情方法
     */
    handleItemDetail = (item) => {
        let menuModule = localStorageUtil.get('menuModule', true);
        menuModule.levelThreeTitle = '平台字典详情';
        menuModule.selectMenuUrl = '/config/dictionary';
        localStorageUtil.set('menuModule', menuModule, true);
        PubSub.publish('onMenuModuleChange', menuModule);
        this.props.history.push({pathname: "/config/dictionary/detail", state: {id: item.id}});
    }

    /**
     * 编辑方法
     */
    handleItemEdit = (item) => {
        //储存 面包屑
        let menuModule = localStorageUtil.get('menuModule', true);
        menuModule.levelThreeTitle = '修改平台字典';
        menuModule.selectMenuUrl = '/config/dictionary';
        localStorageUtil.set('menuModule', menuModule, true);
        PubSub.publish('onMenuModuleChange', menuModule);

        //储存 平台字典库查询条件
        let dictionaryQuery = this.formRef.current.getFieldsValue(['name', 'identification', 'createUser', 'type', 'state'])
        localStorageUtil.set('dictionaryQuery', JSON.stringify(dictionaryQuery), true);

        this.props.history.push({pathname: "/config/dictionary/edit", state: {id: item.id}});
    }

    /**
     * 删除方法
     */
    handleItemDelete = (item) => {
        DictionariesDelete(item.id).then(() => {
            this.getList('1');
        })
    }

    /**
     * 添加 方法
     */
    handleAddForm = () => {
        let menuModule = localStorageUtil.get('menuModule', true);
        menuModule.levelThreeTitle = '添加平台字典';
        menuModule.selectMenuUrl = '/config/dictionary';
        localStorageUtil.set('menuModule', menuModule, true);
        PubSub.publish('onMenuModuleChange', menuModule);
        this.props.history.push('/config/dictionary/add');
    }

    render() {
        return (
            <div className={'dictionary'}>
                <Form
                    layout="inline"
                    className={'dictionaryForm'}
                    onFinish={this.onSearch}
                    autoComplete="off"
                    ref={this.formRef}
                >
                    <FormItem
                        name="name"
                        label="名称"
                        rules={[
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ]}
                    >
                        <Input
                            style={{borderColor: '#EEEFF7'}}
                            placeholder="请输入名称"
                        />
                    </FormItem>
                    <FormItem
                        name="identification"
                        initialValue={''}
                        label="标识"
                        rules={[
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ]}
                    >
                        <Input
                            style={{borderColor: '#EEEFF7'}}
                            placeholder="请输入标识"
                        />
                    </FormItem>
                    <FormItem
                        name="createUser"
                        initialValue={''}
                        label="发布者"
                        rules={[
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ]}
                    >
                        <Input
                            style={{borderColor: '#EEEFF7'}}
                            placeholder="请输入发布者"
                        />
                    </FormItem>
                    <FormItem
                        name="type"
                        label="分类"
                        style={{paddingRight: 0}}
                    >
                        <Select
                            allowClear
                            placeholder="请选择发布者"
                        >
                            {
                                this.props.typeSelectList && this.props.typeSelectList.length != 0 ?
                                    this.props.typeSelectList.map(v => (
                                        <Option value={v.get('code')}
                                                key={v.get('code')}>{v.get('message')}</Option>
                                    )) : ''
                            }
                        </Select>
                    </FormItem>
                    <FormItem
                        name="state"
                        label="状态"
                        initialValue={this.state.state}
                        style={{marginBottom: 0}}
                    >
                        <Select
                            allowClear
                            placeholder="请选择状态"
                        >
                            {
                                this.props.stateSelectList && this.props.stateSelectList.length != 0 ?
                                    this.props.stateSelectList.map(v => (
                                        <Option value={v.get('code')}
                                                key={v.get('code')}>{v.get('message')}</Option>
                                    )) : ''
                            }
                        </Select>
                    </FormItem>
                    <FormItem
                        style={{width: '75%', textAlign: 'right', marginBottom: 0, paddingRight: 0}}
                        wrapperCol={{span: 24}}
                    >
                        <Button
                            className={'searchBtn'}
                            htmlType="submit"
                            style={{borderRadius: '2px', height: 36, marginRight: 16}}
                        >
                            查询
                        </Button>
                        <Button
                            type="primary"
                            onClick={this.handleAddForm}
                            style={{borderRadius: '2px', height: 36}}
                        >
                            添加
                        </Button>
                    </FormItem>
                </Form>
                <Table
                    locale={{emptyText: "暂无数据"}}
                    className={'tableList'}
                    bordered
                    columns={this.state.columns}
                    dataSource={this.state.distionaryList}
                    pagination={this.state.pagination}
                    rowKey={'id'}
                    scroll={{x: 1380}}
                    loading={this.state.tableLoading}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    stateSelectList: state.getIn(['config', 'stateSelectList']),
    typeSelectList: state.getIn(['config', 'typeSelectList']),
})

const mapDispatchToProps = (dispatch) => ({
    handleStateSelectList() {
        const action = actionCreator.getStateSelectListAction();
        dispatch(action)
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(dictionary);