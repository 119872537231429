import React, { Component } from 'react';
import { Modal, Button, Form, Input, Select, Radio, Upload, message, Progress, AutoComplete } from 'antd';
import { createFromIconfontCN, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { GetProjectUploadUser, GetProjectUploadProgress, subjectTransfer, getUsersTop, subjectTransferQuery } from '../../../../../services/project/ProjectService';
import './modalTransfer.less';

const { Option } = Select;
let timeInterval;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

class ModalTransfer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            GetProjectUploadUser: `http://192.168.1.38:9090/project/user/uploadUser/${props.projectId}`,
            GetProjectUploadProgress: `http://192.168.1.38:9090/project/user/uploadUser/`,
            transferVal: '',
            userSelectList: [],
            totalSubjects: 0, // 受试者数量
            userCode: '',
            userName: '',
            centerId: null,
            centerName: '',
        }
    }

    componentDidMount() {
        this.getSubjectTransferQueryData();
        this.getUsersTopData();
    }

    /**
     * 关闭
     */
    handleCancel = () => {
        this.props.closeModal();
        this.props.getDatas();
    };

    /**
     * 受试者转移数据
     */
    getSubjectTransferQueryData = () => {
        let { projectId, transferInfo } = this.props;
        let { centerIds, code } = transferInfo;
        subjectTransferQuery(code, centerIds[0], projectId).then(res => {
            if (res && res.code == 0) {
                this.setState({
                    userCode: res.data.userCode,
                    userName: res.data.userName,
                    centerId: res.data.centerId,
                    centerName: res.data.centerName,
                    totalSubjects: res.data.totalSubjects
                })

            }
        })
    }

    /**
     * 
     * @returns 转移
     */
    handleOk = () => {
        let { transferVal, centerId, totalSubjects, userCode } = this.state;
        let { projectId } = this.props;
        let params = {
            centerId: centerId,
            projectId: projectId,
            recipientCode: transferVal,
            totalSubjects: totalSubjects,
            transferCode: userCode,
        }

        subjectTransfer(params).then(res => {
            if(res && res.code == 0){
                this.props.closeModal();
                message.success(res.message);
                this.props.getDatas();
            }
        })
    }

    /**
     * 
     * @param {*} 查找账号/名称
     */
     getUsersTopData = () => {
        let { centerIds } = this.props.transferInfo;
        const params = {
            centerId: centerIds[0],
        }
        getUsersTop(this.props.projectId,params).then(res => {
            if (res && res.code == 0) {
               this.setState({
                   userSelectList: res.data
               })
            }
        })
    }

    onChange = (v) => {
        this.setState({
            transferVal: v
        })
    }

    /**搜索账号 */
    handleSearch = (val) => {
        if (val) {
            this.setState({
                // transferVal: val,
            }, () => {
                this.getUsersTopData()
            })
        }
    }

    // handleChange = (val) => {
    //     this.getUsersTopData()
    // }

    render() {
        const { loading, transferVal, userSelectList, totalSubjects, userCode, userName, centerName } = this.state;
        const { visible, } = this.props;
        const targetName = sessionStorage.getItem('targetName') || '受试者';
        // select中去除将要转移的账号
        let anewUserSelectList = [];
        userSelectList.map(item => {
            if (item.key != userCode) {
                anewUserSelectList.push(item)
            }
        })

        return (
            <>
                <Modal
                    className={'TransferModals'}
                    visible={visible}
                    title={targetName+'转移'}
                    onCancel={this.handleCancel}
                    width={'50%'}
                    centered
                    destroyOnClose
                    footer={[
                        <Button
                            key="back"
                            onClick={this.handleCancel}
                            style={{
                                height: 36
                            }}
                            className={'cancelBtn'}
                        >
                            取消
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={this.handleOk}
                            style={{
                                height: 36
                            }}
                        >
                            转移
                        </Button>
                    ]}
                >
                    <div className={'content'}>
                        <div className={'left'}>
                            <p className={'name'}>{userName}{userCode ? `（${userCode}）` : ''}</p>
                            <p className={'msg'}>将在{centerName ? `“ ${centerName} ”` : '“ 未绑定医院 ”'}名下管理的
                                <span className={'color'}>{totalSubjects}个</span>{targetName}
                            </p>
                        </div>
                        <div className={'arrows'}>
                            <div className={'square'}></div>
                            <div className={'triangle'}></div>
                        </div>

                        <div>
                            <Select
                                value={transferVal}
                                showSearch
                                style={{ width: 180 }}
                                placeholder=""
                                optionFilterProp="children"
                                onChange={this.onChange}
                                onSearch={val => this.handleSearch(val)}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    anewUserSelectList && anewUserSelectList.length != 0 ?
                                    anewUserSelectList.map((r, index) => (
                                            <Option value={`${r.key}`}
                                                key={index}>{`${r.text}`}</Option>
                                        )) : ''
                                }
                            </Select>

                            {/* <AutoComplete
                                style={{ width: '100%', marginBottom: 5, height: 36 }}
                                allowClear
                                placeholder="查找账号/名称"
                                onChange={val => this.handleChange(val)}
                                // notFoundContent={`暂无数据`}
                                // onFocus={val => this.handleFocus(val)}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    anewUserSelectList && anewUserSelectList.length != 0 ?
                                        anewUserSelectList.map((r, index) => (
                                            <Option value={`${r.key}`}
                                                key={index}>{`${r.text}`}</Option>
                                        )) : ''
                                }
                            </AutoComplete> */}
                            <p className={'hintMsg'}>{anewUserSelectList.length != 0 ? '仅能转移给同中心的CRC账号' : '该中心下没有其他医生，请先添加账号!'}</p>
                        </div>

                    </div>
                </Modal>
            </>
        );
    }
}

export default ModalTransfer;