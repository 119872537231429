/**
 * 报名统计
 */
import React from 'react';
import './index.less';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
    Input, Select, Button, message as Message, Table, message, Tag 
} from "antd";
import { createFromIconfontCN } from '@ant-design/icons';

import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import PubSub from "pubsub-js";
import { GetRegistrationStatistic } from "../../../../services/dct/DctService";

let env = process.env.REACT_APP_ENV;
let _this;
const { Option } = Select;
const { Search } = Input;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_sb9kcktrb1j.js',//icon-shanchu icon-tongji icon-quxiao icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

class QuerylistsComponent extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            dataLists: [], // 列表集合
            columns: [
                {
                    title: '租户名称',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.tenantName}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '项目名称',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.projectNames}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '审核日期',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.createDate ? record.createDate.substring(0, 10) : ''}</div>
                                
                            </div>
                        )
                    }
                },
                {
                    title: '审核账号',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.auditUser}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '昨日结余',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.yesterdayBalance}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '今日新增',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.todayNew}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '今日审核通过数',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.todayPass}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '今日审核未通过数',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.todayReject}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '今日结余',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.todayBalance}</div>
                            </div>
                        )
                    }
                },
            ],// 表格 行 配置
            page: 1,//当前页码数
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo);
                },
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: false,
            },
        }
        this.timer = null
    }

    formRef = React.createRef();
    inputRef = React.createRef();

    componentDidMount() {
        _this.getPageData();
    }

    /**
     * 获取列表数据 
     */
    getPageData = async (curPage, name) => {
        let { projectId } = this.props;
        let { page } = this.state;
        const params = {
            page: curPage ? curPage : page,
            pageSize: this.state.pagination.pageSize,
        }

        const res = await GetRegistrationStatistic(params);
        if (res && res.code == 0) {
            _this.setState({
                dataLists: [...res.data.rows],
                page: res.data.page,
                pageSize: res.data.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })
        }
    }

    render() {
        let { columns, dataLists, pagination } = this.state;

        dataLists.map((item, index) => {
            item['key'] = index + 1
        })

        return (
            <div className='queryList_wrap'>
                <div className={'queryList'}>
                    <div className={'header_content'}>
                        <div className={'left_wrap'}>
                            <span>报名统计</span><br/>
                            <span className={'subTitle'}>共 {pagination.total} 条记录</span>
                        </div>
                    </div>

                    <Table
                        locale={{ emptyText: "暂无数据" }}
                        columns={columns}
                        dataSource={dataLists}
                        defaultExpandAllRows={true}
                        expandIconAsCell={false}
                        pagination={dataLists.length !== 0 && pagination}
                        rowKey={'id'}
                        size='small'
                    />
                </div>
            </div>
        );
    }
}
export default withRouter(QuerylistsComponent);