import './DetailModal.less'
import detailStyl from './DetailModal.module.scss'

import React, { useEffect, useRef, useState } from 'react'

import { message as messager, Button, Modal, Spin } from 'antd';
import { DataResult, DataType } from '../';
import { GetDctIncomplete, GetFormDataById, getTaskDistributeInputDetail, getTaskDistributeInputList, saveFormData } from '../../../../../services/dct/DctService';
import { TaskStatus, Params, StatusEnum } from './Condition';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import Slider from "react-slick";

import DrawCanvas from '../../../imageCheck/components/imgCanvas/drawCanvas'
import Current from './Current';
import Control from '../../../../crf_form/control/Control';

export type Options = {
  index: number;
  // 0:是第1条, 1:最后一条, -1:既不是最后也不是第1条 
  last: 0 | 1 | -1;
  condition: Params;
  pageNum: number;
  pageSize: number;
  // 总条数
  total: number;
  // 当前翻页数据
  records: DataType[];
}

export const makeLast = (index: number, total: number, pageNum: number, pageSize: number): (0 | 1 | -1)=> {
  let linenumber = index + 1;
  let num = Math.abs(pageNum * pageSize - pageSize + linenumber);
  // 是否是最后一条
  if (num >= total) {
    return 1
  } else if(num <= 1) {
    return 0
  }
  return -1;
}

export const fetchTaskDistributeInputList = async (pageNum: number, pageSize: number, params: Params): Promise<[number, DataType[]]>=> {
  const {code, data}: DataResult = await getTaskDistributeInputList({
    ...params,
    page: pageNum,
    pageSize: pageSize
  })
  if(code === 0) {
    return [data.total, data.rows]
  }
  return [0, []]
}

type DetailModalProps = {
  options: Options;
  onClose: (refresh: boolean)=> void;
}
const DetailModal: React.FC<DetailModalProps> = (props)=> {
  // 加载状态
  const [loading, setLoading] = useState(false)
  // 翻页查询中 0未在请求 1在请求下一页 -1在请求上一页
  const [queryLoading, setQueryLoading] = useState(0)
  // 是否需要在关闭详情时刷新列表
  const [refresh, setRefresh] = useState(false)
  // 当前状态
  const [status, setStatus] = useState<TaskStatus>()
  // 当前某条记录
  const [current, setCurrent] = useState<DataType>()
  // 当前数据在records中的索引
  const [index, setIndex] = useState(-1)
  // 是否是最后一条 1:是最后一条 0:是第1条 -1:除最后和第1条外的
  const [last, setLast] = useState(0)
  // 页码
  const [pageNum, setPageNum] = useState(1)
  // 总记录条数
  const [total, setTotal] = useState(0)
  // 翻页数据
  const [records, setRecords] = useState<DataType[]>([])
  // 参数
  const [options, setOptions] = useState({} as Options)  

  // 详情信息
  const [formSet, setFormSet] = useState<any>()

  const [thumb, setThumb] = useState<DataType>()
  const [thumbs, setThumbs] = useState<DataType[]>([])

  const formRef = useRef()

  useEffect(()=> {
    if(!props.options) {
      return;
    }

    const { options } = props;

    const item = options.records[options.index]

    setLast(options.last)
    setIndex(options.index)
    setPageNum(options.pageNum)
    setTotal(options.total)
    setRecords(options.records)
    setOptions(options)
    setCurrent(item)
    setStatus(item.approveStatus)
  }, [props.options])


  // 获取详情信息
  const fetchDetail = async(id: number)=> {
    const {code, data} = await getTaskDistributeInputDetail(id)
    if(code === 0 && data.length > 0) {
      setThumbs(data)
      setThumb(data[0])
    }
  }

  // 获取表单数据
  const fetchForm = async (id: number, dataTenantCode: string) => {
    const {code, data} = await GetFormDataById({
      id,
      dataTenantCode
    })

    if(code === 0) {
      data.formActions = [
        "FormActionSave",
        "FormActionSubmit"
      ]
      data.dataActions = [
        "DataActionInput",
        "DataActionAnnotation",
        "DataActionAnswer",
        // "DataActionQuestion",
        // "DataActionOcr"
      ]
      setFormSet(data)
    }
  }

  // 1. 获取表单相关数据
  // 2. 获取详情当前表单所有图片列表
  // 3. 清空拒绝数据
  useEffect(()=> {
    if(records[index]) {
      const { formId, tenantCode } = records[index]
      
      fetchDetail(formId)
      fetchForm(formId, tenantCode)
    }
  }, [index])

  // 处理上一条下一条数据
  const recordControl = async (step: number)=> {
    // 第1条
    if(step === -1 && last === 0) {
      return
    }
    // 最后一条
    if(step === 1 && last === 1) {
      return
    }

    let currentIndex = index + step;

    // 未涉及到翻页
    if(records[currentIndex]) {
      setIndex(currentIndex)
      setCurrent(records[currentIndex])
      setLast(makeLast(currentIndex, total, pageNum, options.pageSize))      
      return
    }

    setQueryLoading(step)

    const pageNo = pageNum + step

    // 当前索引
    currentIndex = step === 1 ? 0 : options.pageSize - 1
    const [a, b] = await fetchTaskDistributeInputList(pageNo, options.pageSize, options.condition)
    
    setQueryLoading(0)
    setTotal(a)
    setRecords(b)
    setCurrent(b[currentIndex])
    
    setPageNum(pageNo)
    setIndex(currentIndex)
    setLast(makeLast(step === 1 ? currentIndex : 0-currentIndex, a, pageNo, options.pageSize))    
  }

  const afterChange =(i: number)=> {
    setThumb(thumbs[i])
  }

  const submitForm = async()=> {
    if(!formRef.current || !current) {
      return
    }
    // @ts-ignore
    const {body, ...params} = formRef.current.getSavedForm();
    params.submit = true;
    params.dataTenantCode = current.tenantCode

    const r1: any = await saveFormData(params)

    const r2: any = await GetDctIncomplete({
      id: current.id,
      rejectReason: []
    })

    if(r1.code === 0 && r2.code === 0) {
      recordControl(1)
    }

    if(r1.code === 0 || r2.code === 0) {
      if(r2.code === 0) {
        const tmp = [...records]
        tmp[index].approveStatus = 'AGREE'
        setRecords(tmp)
      }
      setRefresh(true)
    } else {
      const pieces = []
      if(r1.code > 0) {
        pieces.push(r1.message)
      }
      if(r2.code > 0) {
        pieces.push(r2.message)
      }
      messager.error(pieces.join(','))
    }
  }

  return (
    <Modal
      onCancel={()=> props.onClose(refresh)}
      destroyOnClose
      title="数据补充录入"
      width={"80%"}
      bodyStyle={{padding: 0}}
      visible={true}
      footer={null}
      maskClosable={false}>
      <div className={detailStyl.wrapper}>
        <div className={detailStyl.main}>
          <div className={detailStyl.thumbs}>
            <Slider
              arrows={true}
              className="datacheck-detail-modal-slider"
              slidesToShow={8}
              swipeToSlide={true}
              focusOnSelect={true}
              dots={false}
              infinite={false}
              adaptiveHeight={false}>
                {
                  thumbs.map((item, i)=> (
                    <img onClick={()=> afterChange(i)} key={item.id} src={item.url+'?x-oss-process=image/resize,w_82,h_50,limit_0,m_fill'} alt={item.subjectName}/>
                  ))
                }
            </Slider>
          </div>
          <div className={detailStyl.canvas}>
            {
              thumb && (
                <DrawCanvas ifGetDrawArea={false} needUpdate={true} imgData={thumb} imgUrl={thumb.url}/>
              )
            }
          </div>
          <div className={detailStyl.steps}>
            <Button loading={queryLoading === -1} disabled={last === 0} onClick={()=> recordControl(-1)} shape="round"><ArrowLeftOutlined/>上一条</Button>
            <Button loading={queryLoading === 1} disabled={last === 1} onClick={()=> recordControl(1)} shape="round" style={{marginLeft: 20}}>下一条<ArrowRightOutlined/></Button>
          </div>
          {thumb && <Current<DataType> data={thumb}/>}
        </div>
        <div className={detailStyl.east}>
          <div className={detailStyl.info}>
            <Spin tip="Loading..." spinning={loading}>
              {
                formSet && current && (
                  <>
                    <Control tenantCode={current.tenantCode} formId={current.formId} data={formSet} ref={formRef} isDct={true}></Control>
                    <span data-status={current.approveStatus} className={detailStyl.status}>{StatusEnum[current.approveStatus]}</span>
                  </>
                )
              }
            </Spin>
          </div>
          <div className={detailStyl.foot}>
            <Button onClick={submitForm} loading={loading} type="primary" shape="round" style={{marginRight: 10}}>提交任务</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DetailModal