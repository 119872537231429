/**
 * 数据中心模块路由配置
 */

import ProjectReportDownComponent from "../components/cdms/down/projectReport";
import ProjectDataDownComponent from "../components/cdms/down/projectData";

const routes = [
    {'path': '/cdms/down/project/report', 'component': ProjectReportDownComponent},
    {'path': '/cdms/down/project/data', 'component': ProjectDataDownComponent},
];

export default routes
