import {STATE_SELECT_LIST, META_STATE_AND_TYPE_SELECT_LIST, CODESET_SELECT_LIST} from './actionTypes';
import {fromJS} from 'immutable';

const defaultState = fromJS({
    stateSelectList: [],
    typeSelectList: [],
    dataThemeStateSelectList: [],
    dataThemeTypeSelectList: [],
    codesetStateSelectList:[],
    codesetTypeSelectList:[],
    codesetUseScopeSelectList:[],
})

export default (preState = defaultState, action) => {
    switch (action.type) {
        case STATE_SELECT_LIST:
            return preState.set('stateSelectList', action.stateSelectList)
                .set('typeSelectList', action.typeSelectList);
        case META_STATE_AND_TYPE_SELECT_LIST:
            return preState.set('dataThemeStateSelectList', action.dataThemeStateSelectList)
                .set('dataThemeTypeSelectList', action.dataThemeTypeSelectList);
        case CODESET_SELECT_LIST:
            return preState.set('codesetStateSelectList', action.codesetStateSelectList)
                .set('codesetTypeSelectList', action.codesetTypeSelectList)
                .set('codesetUseScopeSelectList', action.codesetUseScopeSelectList);
        default:
            return preState
    }

}