import React from 'react';
import { Spin } from 'antd';
import './loading.less'

const Loading = () => {
    return (
        <div id={'edc-loading'} style={{display:'none'}}>
            <div className="overlay"></div>
            <div className="loading">
                <Spin tip="正在加载中 ..." size="large" spinning={true} />
            </div>
        </div>
    )
}

export default Loading;