
import modStyl from './DetailModal.module.scss'
import { Button, Card, Col, Divider, Empty, Modal, Row } from "antd"
import React, { useEffect, useState } from "react"
import UserInfo from './UserInfo';
import AudioPlayer from './AudioPlayer';
import { DataType } from '..';
import DictTransform from './DictTransform';
import { formatDateTime, formatSeconds } from './utils';
import { getVisitRecord, VisitRecord } from '../../../../services/dct/visit';

//#region 标题
type TitleNodeProps = {
  status: number;
}
const TitleNode: React.FC<TitleNodeProps> = ({status})=> {
  return (
    <div className={modStyl.cardTitle}>
      <h2>随访任务</h2>
      <DictTransform name="visitStatus" value={status}/>
    </div>
  )
}
//#endregion

//#region 随访字段
type FieldContentProps = {
  label: string;
}
const FieldContent: React.FC<FieldContentProps> = ({label, children})=> {
  return (
    <div className={modStyl.fieldContent}>
      <div className={modStyl.label}>{label}：</div>
      <div className={modStyl.content}>{children}</div>
    </div>
  )
}
//#endregion

//#region 短信随访结果
type SendContentProps = {
  item: VisitRecord;
}
const SendContent: React.FC<SendContentProps> = ({item})=> {
  if(item.visitStatus === 0) {
    return <FieldContent label="短信内容">发送失败</FieldContent>
  }
  return <FieldContent label="短信内容">{item.msgContent}</FieldContent>
}
//#endregion

//#region 电话随访结果
type PhoneContentProps = {
  item: VisitRecord;
}
const PhoneContent: React.FC<PhoneContentProps> = ({item})=> {
  if(item.visitStatus === 0) {
    return <FieldContent label="电话录音">未接通</FieldContent>
  }
  return (
    <>
      <FieldContent label="时长">{item.totalDuration ? formatSeconds(item.totalDuration) : '--'}</FieldContent>
      {
        typeof item.recordPath === 'string' && (
          <FieldContent label="电话录音">
            <AudioPlayer duration={Number(item.totalDuration)} label={`${item.sinToken}.mp3`} url={item.recordPath}/>
          </FieldContent>
        )
      }
    </>
  )
}
//#endregion

//#region 随访记录
type CardRecordProps = {
  item: VisitRecord;
}
const CardRecord: React.FC<CardRecordProps> = ({item})=> {
  return (
    <>
      <FieldContent label="随访方式">{item.type === 1 ? '短信' : '电话'}</FieldContent>
      <FieldContent label="随访人员">{item.visitUserName}</FieldContent>
      <FieldContent label="实际随访时间">{formatDateTime(item.visitTime)}</FieldContent>
      {
        item.type === 1 ? <SendContent item={item}/> : <PhoneContent item={item}/>
      }
    </>
  )
}
//#endregion

export enum CommandEnum {
  Send = 'send',
  Phone = 'phone',
  Complete = 'complete',
}

type Props = {
  current: DataType;
  onClose: ()=> void;
  onCommand: (type: CommandEnum)=> void;
}

const DetailModal: React.FC<Props> = ({current, onClose, onCommand})=> {
  const [record, setRecord] = useState<VisitRecord[]>([])

  const fetchDetail = async ()=> {
    const {code, data} = await getVisitRecord(current.id)
    if(code === 0) {
      setRecord(data)
    }
    console.log(data)
  }

  const onCommandBefore = ()=> {
    let times = 0;
    let flag = 0;
    record.forEach(t=> {
      if(t.type === 0) {
        if(1 === t.visitStatus) {
          times++
        } else {
          flag++
        }
      }
    })

    // 当该随访任务有打通的电话或者3个及以上未接通的电话时才可完成随访任务
    // 不满足条件点击弹窗提示
    if(times === 0 && flag < 3) {
      Modal.warning({
        title: '提示',
        content: '拨通电话或3次以上未接通后才可标记完成随访',
        okText: '知道了',
      })
    } else {
      onCommand(CommandEnum.Complete)
    }
  }

  useEffect(()=> {
    fetchDetail()
  }, [])

  return (
    <Modal onCancel={onClose} visible={true} width={980} bodyStyle={{padding: 18, background: '#F6F7FC'}} title="随访任务" footer={null} closable maskClosable={false} destroyOnClose>
      <div className={modStyl.wrapper}>
        <div className={modStyl.main}>
          <Card size="small" title={<TitleNode status={current.visitStatus}/>}>
            <Row gutter={12}>
              <Col span={13}>
                <FieldContent label="所属项目">{current.projectName}</FieldContent>
                <FieldContent label="患者">
                  <UserInfo data={current} nowrap/>
                </FieldContent>
                <FieldContent label="随访内容">{current.visitContent}</FieldContent>
              </Col>
              <Col span={5}>
                <FieldContent label="所属中心">{current.centerName}</FieldContent>
                <FieldContent label="随访类型">
                  <DictTransform name="visitType" value={current.visitType}/>
                </FieldContent>
              </Col>
              <Col span={6}>
                <FieldContent label="采集人">{current.adminName}</FieldContent>
                <FieldContent label="随访日期">{current.startDate}</FieldContent>
              </Col>
            </Row>
          </Card>
          {
            <Card size="small" title="随访记录" style={{marginTop: 20}}>
              {
                record.map((item, index)=> (
                  <>
                    {index > 0 && <Divider/>}
                    <CardRecord item={item}/>
                  </>
                ))
              }
              {
                record.length === 0 && <Empty description="暂无随访记录"/>
              }
            </Card>
          }
          {
            current.visitStatus === 2 && (
              <Card size="small" title="完成随访" style={{marginTop: 20}}>
                <Row gutter={12}>
                  <Col span={13}>
                    <FieldContent label="受试者状态">
                      <DictTransform name="examineeStatus" value={current.subjectState}/>
                    </FieldContent>
                    <FieldContent label="其他需要备注的重要信息">{current.remark}</FieldContent>
                  </Col>
                </Row>
              </Card>
            )
          }
          {
            current.visitStatus === 3 && (
              <Card size="small" title="任务失效" style={{marginTop: 20}}>
                <Row gutter={12}>
                  <Col span={13}>
                    <FieldContent label="失效原因">{current.failCause}</FieldContent>
                  </Col>
                </Row>
              </Card>
            )
          }
        </div>
        {
          current.visitStatus < 2 && (
            <div className={modStyl.footer}>
              <div className={modStyl.west}>
                {
                  current.visitType === 2 && (
                    <Button onClick={()=> onCommand(CommandEnum.Send)} type='primary' style={{borderColor: 'orange', background: 'orange'}} className={modStyl.button}>短信提醒</Button>
                  )
                }
                <Button onClick={()=> onCommand(CommandEnum.Phone)} type='primary' className={modStyl.button}>电话随访</Button>
              </div>
              <div className={modStyl.east}>
                <Button onClick={onCommandBefore} type='primary' className={modStyl.button}>完成随访</Button>
              </div>
            </div>
          )
        }
      </div>
    </Modal>
  )
}

export default DetailModal