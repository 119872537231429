import { Button, Form, Input, Modal, Select, message as messager } from 'antd'
import modStyl from './edit-modal.module.scss'

import React, { useEffect, useState } from 'react'
import { CareOfficerDataType, updateCareOfficer, updateCareOfficerPayload } from '../../../../../services/apply/patient'
import { getProjectList } from '../../../../../services/project/project'

type SelectOption = {
  label: string;
  value: string;
}

type Props = {
  record: CareOfficerDataType;
  onClose: ()=> void;
}

const EditModal: React.FC<Props> = ({record, onClose})=> {

  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const [projectCode, setProjectCode] = useState<string>()

  // 所属项目
  const [projs, setProjs] = useState<SelectOption[]>([])

  // 项目列表
  type ProjectItem = {
    id: number;
    code: string;
    name: string;
  }
  type ProjectResult = {
    code: number;
    message?: string;
    data: ProjectItem[];
  }
  const fetchProjs = async ()=> {
    const { code, data }: ProjectResult = await getProjectList()
    if(code === 0) {
      if(data.length === 1) {
        const {id, code} = data[0]
        setProjectCode(code)
      }
      setProjs(
        data.map(({name, code})=> ({
          label: name,
          value: code
        }))
      )
    }
  }

  const handleFinish = async (data: updateCareOfficerPayload)=> {
    setLoading(true)
    const {code} = await updateCareOfficer({
      id: record.id,
      projectCode: data.projectCode,
      wechatCode: data.wechatCode,
      projectName: projs.find(({value})=> value === projectCode)?.label as string
    })
    setLoading(false)
    if(code === 0) {
      onClose()
      messager.success('编辑成功')
    }
  }

  useEffect(()=> {
    fetchProjs()
  }, [])

  return (
    <Modal onCancel={onClose} visible={true} title="编辑" footer={null} closable maskClosable={false} destroyOnClose>
      <Form onFinish={handleFinish} form={form} initialValues={record} colon>
        <Form.Item label="所属项目" name="projectCode" labelCol={{span: 8}} rules={[{required: true, message: '请选择患者状态'}]}>
          <Select onChange={(value)=> setProjectCode(value)} value={projectCode} options={projs} placeholder="请选择" showSearch allowClear/>
        </Form.Item>
        <Form.Item label="关爱助手姓名" name="careOfficerName" labelCol={{span: 8}} rules={[{required: true, message: '请输入备注信息'}]}>
          <Input disabled></Input>
        </Form.Item>
        <Form.Item label="手机号" name="phone" labelCol={{span: 8}} rules={[{required: true, message: '请输入备注信息'}]}>
          <Input disabled></Input>
        </Form.Item>
        <Form.Item label="企业微信号" name="wechatCode" labelCol={{span: 8}} rules={[{required: true, message: '请输入备注信息'}]}>
          <Input placeholder=''></Input>
        </Form.Item>
        <div className={modStyl.footer}>
          <Button onClick={onClose}>取消</Button>
          <Button loading={loading} htmlType="submit" type="primary">确认</Button>
        </div>
      </Form>
    </Modal>
  )
}

export default EditModal