import React from 'react';
import './researchCenter.less';
import myicon from './photo_icon.png';
import { Form, Input, Select, Button, TreeSelect, InputNumber, Table, Popconfirm } from "antd";
import { CaretDownOutlined, createFromIconfontCN } from '@ant-design/icons';
import {
    GetProjectCenterList,
    AddProjectCenter,
    DeleteProjectCenter,
    GetProjectCenterPageList,
    UpdateProjectCenterStatus, ModifyProjectCenter, GetProjectUserPageList, GetProjectAllocationList
} from "../../../../services/project/ProjectService";

let _this;
const { Option } = Select;
const { Search } = Input;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2424194_51q7uk00f4c.js',//icon-shanchu icon-tongji icon-quxiao icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

const layout = {
    wrapperCol: {
        span: 24,
    },
};

class Center extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            areaSelectList: [], // 区域下拉框 树 集合
            selectTreeValue: undefined, // 树下拉 选中value值
            centerList: [], // 研究中心列表
            statusList: [], // 状态下拉集合
            userSelectList: [], // 中心管理员下拉集合
            listEmpty: false, // 列表 是否为空flag
            rowId: '', // 表格选中行 id
            editFormData: {
                adminCode: null,
                goalNum: '',
                name: '',
                parentId: '',
                id: '',
                areaId: ''
            }, // 选中表格某行修改
            editBtnFlag: false,// 修改btn显 隐
            columns: [
                {
                    width: '36%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left'}>
                                <div className={'photo'}>
                                    <img src={myicon} alt="" />
                                </div>
                                <div className={'c_left_title'}>
                                    <p>{record.name}</p>
                                    <p>{record.beforeMonths}</p>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    width: '11%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left2'}>
                                <p>
                                    <span>{record.finishedNum != undefined ? record.finishedNum : '--'}</span>
                                </p>
                                <p>
                                    <span>已完成</span>
                                </p>
                            </div>
                        )
                    }
                },
                {
                    width: '11%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left2'}>
                                <p>
                                    <span>{record.goalNum != undefined ? record.goalNum : '--'}</span>
                                </p>
                                <p>
                                    <span>目标</span>
                                </p>
                            </div>
                        )
                    }
                },
                {
                    width: '12%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left3'}>
                                <p>
                                    <span>{record.adminName ? record.adminName : '未绑定'}</span>
                                </p>
                                <p>管理员</p>
                            </div>
                        )
                    }
                },
                {
                    width: '14%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left4'}>
                                <p>{record.areaName ? record.areaName : '未绑定区域'}</p>
                            </div>
                        )
                    }
                },
                {
                    width: '10%',
                    key: 'address',
                    render: (text, record, index) => {
                        return (
                            <div
                                onClick={(eve) => {
                                    eve.stopPropagation();
                                }}
                                className={'c_right'}
                            >
                                <Select
                                    defaultValue={record.status}
                                    /*suffixIcon={<CaretDownOutlined/>}*/
                                    onSelect={_this.UpdateProjectCenterStatus}
                                    /*disabled={item.status === 'PROCESS' ? true : false}*/
                                    style={{ width: 193, float: 'right' }}
                                    allowClear
                                >
                                    {
                                        _this.state.statusList.map(x => (
                                            <Option value={x.code}
                                                id={record.id}
                                                key={x.code}>{x.message}</Option>
                                        ))
                                    }
                                </Select>
                            </div>
                        )
                    }
                },
                {
                    width: '6%',
                    render: (text, record) =>
                        _this.state.centerList.length >= 1 ? (
                            <div onClick={eve => {
                                eve.stopPropagation();
                            }}>
                                {/*<Button
                                    type="primary"
                                    danger
                                    onClick={() => {
                                        this.handleDelete(record.id)
                                    }}
                                >
                                    {_this.formatIcon('icon-shanchu')}
                                </Button>*/}
                                <Popconfirm
                                    title="确认删除该条数据?"
                                    okText='确认'
                                    cancelText='取消'
                                    /*icon={null}*/
                                    onConfirm={() => {
                                        this.handleDelete(record.id)
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        danger
                                        style={{ height: 36 }}
                                    >
                                        {_this.formatIcon('icon-shanchu')}
                                    </Button>
                                </Popconfirm>
                            </div>
                        ) : null,
                },
            ],// 表格 行 配置
            //分页配置
            page: 1,//当前页码数
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo);
                },
                current: 1,
                pageSize: 10,
                total: 1,
                showSizeChanger: false,
            }
        }
    }

    formRef = React.createRef();
    inputRef = React.createRef();

    componentDidMount() {
        _this.getDatas(); // 查询列表
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    /**
     * 查询列表
     */
    getDatas = async (operation) => {
        const selectData = await GetProjectCenterList({ "projectId": _this.props.projectId });
        let statusList = [];
        selectData.statusList.map(x => {
            if (x == "PROCESS") {
                statusList.push({ code: x, message: "进行中" })
            } else if (x == "STOP") {
                statusList.push({ code: x, message: "已暂停" })
            } else if (x == "END") {
                statusList.push({ code: x, message: "已完成" })
            }
        })
        _this.setState({
            areaSelectList: _this.areaSelectMapList(selectData.areaSelectList),
            userSelectList: selectData.userSelectList,
            statusList
        }, () => {
            if (operation == 'add') {
                _this.getPageData('1');
            } else if (operation == 'del') {
                if (this.state.centerList.length == 1 && _this.state.page != 1) {
                    _this.getPageData(_this.state.page - 1);
                } else {
                    _this.getPageData(_this.state.page);
                }
            } else {
                _this.getPageData(_this.state.page);
            }

        })
    }

    /**
     * 获取分页数据
     */
    getPageData = async (currentPage) => {
        const params = {
            name: _this.inputRef.current?.input?.value,
            projectId: _this.props.projectId,
            page: currentPage ? currentPage : this.state.page,
            pageSize: this.state.pagination.pageSize,
        }
        const res = await GetProjectCenterPageList(params);
        _this.setState({
            centerList: res.rows,
            page: res.page,
            pagination: {
                ...this.state.pagination,
                current: res.page,
                total: res.total,
            }
        })
    }

    /**
     * 区域映射
     * @param values
     */
    areaSelectMapList = (data) => {
        let b = [];
        data.forEach(v => {
            let one = {};
            one.title = v.areaName
            one.value = v.areaId + '_' + v.areaName
            one.selected = v.selected;
            if (v.children && v.children.length != 0) {
                one.children = _this.areaSelectMapList(v.children)
            } else {
                one.children = []
            }
            b.push(one);
        })
        return b
    }

    /**
     * 表单提交
     * @param values
     */
    handleFinish = (values) => {
        if (this.state.editBtnFlag) {
            //修改
            const form = _this.formRef.current
            const values = form.getFieldsValue(['name', 'parentId', 'adminCode', 'goalNum', 'areaId'])
            values.projectId = _this.props.projectId
            values.id = _this.state.editFormData.id // 修改 当条数据的id
            values.name = values.name.trim();
            values.areaId = values.areaId ? values.areaId.split('_')[0] : null
            ModifyProjectCenter(values).then(res => {
                _this.getDatas('add');
                _this.setState({
                    editFormData: {
                        adminCode: '',
                        goalNum: '',
                        name: '',
                        parentId: '',
                        id: '',
                        areaId: ''
                    },
                    rowId: '',
                    editBtnFlag: false
                })
                _this.formRef.current.resetFields();
            })

        } else {
            //添加
            values.projectId = _this.props.projectId
            values.name = values.name.trim();
            values.areaId = values.areaId ? values.areaId.split('_')[0] : null
            AddProjectCenter(values).then(res => {
                _this.getDatas();
                _this.setState({
                    editBtnFlag: false,
                    rowId: '',
                    editFormData: {
                        adminCode: null,
                        goalNum: '',
                        name: '',
                        parentId: '',
                        id: '',
                        areaId: ''
                    }
                })
                _this.formRef.current.resetFields();
            })
        }
    }

    /**
     * 搜索 events
     * @param value
     */
    handleSearch = () => {
        _this.getDatas(this.inputRef.current?.input?.value);
    }

    /**
     * 删除
     * @returns {JSX.Element}
     */
    handleDelete = (id) => {
        _this.formRef.current.resetFields();
        _this.setState({
            editBtnFlag: false,
        })
        let params = {
            researchId: id
        }
        DeleteProjectCenter(id, params).then(res => {
            _this.getDatas('del');
        })
    }

    /**
     * 更新状态
     * @returns {JSX.Element}
     */
    UpdateProjectCenterStatus = (status, options) => {
        UpdateProjectCenterStatus(options.id, { status }).then(res => {
            _this.getDatas();
        })
    }

    /**
     * 上级区域下拉树 change
     * @returns {JSX.Element}
     */
    handleTreeChange = (value) => {
        console.log(value);
        _this.setState({
            selectTreeValue: value
        });
    }

    /**
     * 行点击 event
     * @returns {JSX.Element}
     */
    handleRow = (record) => {
        console.log(record);
        return {
            onClick: () => {
                let areaId = null;
                if (record.areaId && record.areaName)  {
                    areaId = record.areaId + '_' + record.areaName
                }

                _this.setState({
                    editFormData: {
                        adminCode: record.adminCode,
                        goalNum: record.goalNum,
                        name: record.name,
                        parentId: record.parentId,
                        id: record.id,
                        areaId
                    },
                    rowId: record.id,
                    editBtnFlag: true
                })
                _this.formRef.current.setFieldsValue({
                    adminCode: record.adminCode,
                    goalNum: record.goalNum,
                    name: record.name,
                    parentId: record.parentId,
                    id: record.id,
                    areaId
                });
            },
            onMouseEnter: () => {
                /*this.setState({
                    rowId: record.id,
                });*/
            },
        }
    };

    /**
     * 行点击高亮
     * @returns {JSX.Element}
     */
    handleSetRowClassName = (record) => {
        return record.id === _this.state.rowId ? 'clickRowStyl' : '';
    }

    /**
     * 取消 修改
     * @returns {JSX.Element}
     */
    handleCancel = () => {
        _this.setState({
            rowId: '',
            editBtnFlag: false,
            editFormData: {
                adminCode: null,
                goalNum: '',
                name: '',
                parentId: '',
                id: '',
                areaId: ''
            }
        });
        _this.formRef.current.resetFields();
    }

    render() {
        return (
            <div className={'researchCenter'}>
                <div className={'rc_top'}>
                    <div className={'selectQueryGroup'}>
                        <Form
                            layout={'inline'}
                            initialValues={{ layout: 'inline' }}
                            onValuesChange={_this.onFormLayoutChange}
                            onFinish={_this.handleFinish}
                            ref={_this.formRef}
                            {...layout}
                        >
                            <Form.Item
                                name='name'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入研究中心名称!',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                                style={{ width: '25%', marginRight: 0, paddingRight: 16 }}
                                className={this.state.editBtnFlag ? 'editClass' : ''}
                            >
                                <Input
                                    style={{
                                        width: '100%',
                                        height: 36,
                                        lineHeight: 36,
                                        borderColor: '#EEEFF7'
                                    }}
                                    placeholder="请输入研究中心名称"
                                />
                            </Form.Item>
                            <Form.Item
                                name='areaId'
                                style={{ width: '25%', marginRight: 0, paddingRight: 16 }}
                                className={this.state.editBtnFlag ? 'editClass' : ''}
                            >
                                <TreeSelect
                                    showSearch
                                    style={{ width: '100%', marginBottom: 16, height: 36 }}
                                    value={_this.state.selectTreeValue}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    treeData={_this.state.areaSelectList}
                                    placeholder="请选择所属区域"
                                    treeDefaultExpandAll
                                    onChange={_this.handleTreeChange}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                name='adminCode'
                                style={{ width: '25%', marginRight: 0, paddingRight: 16 }}
                                className={this.state.editBtnFlag ? 'editClass' : ''}
                            >
                                <Select
                                    placeholder="请选择中心管理员"
                                    style={{ width: '100%', marginBottom: 16, height: 36 }}
                                    allowClear
                                >
                                    {
                                        _this.state.userSelectList.map((item) => {
                                            return (
                                                <Option value={item.userCode}
                                                    key={item.userId}>{item.userName}</Option>)
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='goalNum'
                                rules={[
                                    {
                                        type: 'number',
                                        max: 999999999,
                                        message: '请输入小于10位的正整数!',
                                    }
                                ]}
                                style={{ width: '25%', marginRight: 0 }}
                                className={this.state.editBtnFlag ? 'editClass' : ''}
                            >
                                <InputNumber
                                    min={0}
                                    max={999999999}
                                    style={{ width: '100%', marginBottom: 16, height: 36, borderColor: '#EEEFF7' }}
                                    parser={value => value.replace(/^(-1+)|[^\d]+/g, '')}
                                    placeholder="请输入中心目标样本数"
                                />
                            </Form.Item>
                            <Form.Item
                                style={{
                                    display: (_this.state.editBtnFlag ? 'none' : 'block'),
                                    textAlign: 'right',
                                    width: '100%'
                                }}
                                wrapperCol={{ span: 20, offset: 3 }}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ borderRadius: '2px', height: 36 }}
                                >
                                    {/*{_this.formatIcon('icon-tianjia1')}*/}
                                    添加
                                </Button>
                            </Form.Item>
                            <Form.Item
                                style={{
                                    display: (_this.state.editBtnFlag ? 'block' : 'none'),
                                    textAlign: 'right',
                                    width: '100%',
                                }}
                            >
                                <Button
                                    className={'cancelBtn'}
                                    onClick={_this.handleCancel}
                                    style={{ borderRadius: '2px', height: 36 }}
                                >
                                    {/*{_this.formatIcon('icon-quxiao')}*/}
                                    取消
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ borderRadius: '2px', height: 36 }}
                                >
                                    {/*{_this.formatIcon('icon-xiugai')}*/}
                                    修改
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div className={'rc_content'}>
                    <div className={'search'}>
                        <span className={'rc_title'}>项目研究中心</span>
                        <div className={'search_group'}>
                            <Input
                                placeholder="查找"
                                style={{
                                    width: 300,
                                    height: 36,
                                    lineHeight: 1.8,
                                    borderColor: '#EEEFF7',
                                    borderTopLeftRadius: 2,
                                    borderBottomLeftRadius: 2,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                }}
                                allowClear
                                ref={this.inputRef}
                            />
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={_this.handleSearch}
                                style={{
                                    height: 36,
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderTopRightRadius: 2,
                                    borderBottomRightRadius: 2,
                                }}
                            >
                                {/*{_this.formatIcon('icon-chazhao')}*/}
                                查找
                            </Button>
                        </div>
                    </div>
                    <div className={'itemList'}>
                        {
                            <Table
                                locale={{ emptyText: "暂无数据" }}
                                columns={_this.state.columns}
                                dataSource={_this.state.centerList}
                                showHeader={false}
                                defaultExpandAllRows={true}
                                scroll={{y: window.innerHeight - 521}}
                                expandIconAsCell={false}
                                onRow={_this.handleRow}
                                rowClassName={_this.handleSetRowClassName}
                                pagination={this.state.pagination}
                                rowKey={'id'}
                            />
                        }
                        {
                            /* _this.state.centerList.length === 0 ? (
                                     <div className={'nothing'}>暂无数据...</div>)
                                 : _this.state.centerList.map((item) => {
                                     return (
                                         <div className={`item ${item.checked ? 'itemSelected' : ''}`} key={item.id}
                                              onClick={() => (_this.handleOnclick(item.id))}

                                         >
                                             <div className={'item_con'}>
                                                 <div className={'con_div c_left'}>
                                                     <div className={'photo'}>
                                                         <img src={myicon} alt=""/>
                                                     </div>
                                                     <div className={'c_left_title'}>
                                                         <p>{item.name}</p>
                                                         <p>{item.beforeMonths}</p>
                                                     </div>
                                                 </div>
                                                 <div className={'con_div c_left2'}>
                                                     <p>
                                                         <span>{item.finishedNum}</span>/
                                                         <span>{item.goalNum}</span>
                                                     </p>
                                                     <p>
                                                         <span>已完成</span>/
                                                         <span>目标</span>
                                                     </p>
                                                 </div>
                                                 <div className={'con_div c_left3'}>
                                                     <p>
                                                         <span>{item.adminName}</span>
                                                     </p>
                                                     <p>管理员</p>
                                                 </div>
                                                 <div className={'con_div c_left4'}>
                                                     <p>{item.areaName}</p>
                                                 </div>
                                                 <div className={'con_div c_right'}
                                                      onClick={(eve) => {
                                                          eve.stopPropagation();
                                                      }}>
                                                     <Select
                                                         defaultValue={item.status}
                                                         suffixIcon={<CaretDownOutlined/>}
                                                         onSelect={_this.UpdateProjectCenterStatus}
                                                         /!*disabled={item.status === 'PROCESS' ? true : false}*!/
                                                         allowClear
                                                     >
                                                         {
                                                             _this.state.statusList.map(x => (
                                                                 <Option value={x.code}
                                                                         id={item.id}
                                                                         key={x.code}>{x.message}</Option>
                                                             ))
                                                         }
                                                     </Select>
                                                     <Button type="primary" danger
                                                             onClick={(eve) => {
                                                                 eve.stopPropagation();
                                                                 _this.handleDelete(item.id)
                                                             }}>
                                                         {_this.formatIcon('icon-shanchu')}
                                                         删除
                                                     </Button>
                                                 </div>
                                             </div>
                                         </div>
                                     )
                                 })*/
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Center;