import React, { Fragment, useEffect, useRef, useState } from "react"

import { Button, Empty, Table } from "antd"
import indexStyl from './index.module.scss'
import Condition from "./components/condition"
import { ColumnsType } from "antd/lib/table"
import EditModal from "./components/edit-modal"
import QRModal from "./components/qr-modal"
import { formatDateTime } from "./components/utils"
import { AssistantListParams, DataType, getAssistantList } from "../../../../services/apply/patient"

type VisitTaskContext = {
  openSubjectModal: (subjectCode: string)=> void;
  reload: ()=> void;
}
export const visitTaskContext = React.createContext<VisitTaskContext>({
  openSubjectModal: ()=> {},
  reload: ()=> {},
})

const AssistantDoctor = ()=> {
  const [record, setRecord] = useState<DataType>()
  // 详情
  const [visible, setVisible] = useState(false)
  // 邀请二维码
  const [visibleQR, setVisibleQR] = useState(false)
  
  const [pageSize] = useState(20)
  const [pageNum, setPageNum] = useState(1)
  
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const [dataSource, setDataSource] = useState<DataType[]>([])

  const conditionRef = useRef<AssistantListParams>({
    assistantName: '',
    phone: '',
  })
  
  //#region 表格列
  const columns: ColumnsType<DataType> = [
    {
      width: 120,
      title: '所属项目',
      ellipsis: true,
      dataIndex: 'projectName',
      render: (text: string) => <a>{text}</a>,
    },
    {
      width: 120,
      title: '医助姓名',
      dataIndex: 'assistantName',
    },
    {
      width: 120,
      title: '手机号',
      dataIndex: 'phone',
    },
    {
      width: 160,
      title: '绑定关爱专员',
      dataIndex: 'careOfficerName',
    },
    {
      width: 100,
      title: '邀请医生',
      dataIndex: 'inviteDoctorNumber',
      render: (value)=> `${value||0}个`
    },
    {
      width: 100,
      ellipsis: true,
      title: '邀请患者',
      dataIndex: 'invitePatientNumber',
      render: (value)=> `${value||0}个`
    },
    {
      title: '其他',
      render(value, record: DataType) {
        return `创建人：${record.createUser}，最后修改时间：${formatDateTime(record.createTime)}`
      }
    },
    {
      width: 180,
      title: '操作',
      key: 'action',
      render: (_: any, record: DataType) => (
        <Fragment>
          <Button onClick={()=> {
            setRecord(record)
            setVisible(true)
          }} type="primary" size="small">编辑</Button>
          <Button onClick={()=> {
            setRecord(record)
            setVisibleQR(true)
          }} size="small" style={{marginLeft: 5}}>邀请二维码</Button>
        </Fragment>
      ),
    },
  ];
  //#endregion

  const fetchData = async (params: AssistantListParams)=> {
    setLoading(true)
    const {code, data} = await getAssistantList(params, pageNum, pageSize)
    setLoading(false)
    if(code === 0) {
      setTotal(data.total)
      setDataSource(data.rows)
    }
  }

  const onSearch = (params: AssistantListParams)=> {
    setPageNum(1)
    conditionRef.current = params
    // 修复当前页面为第1页面不自动刷新问题
    if(pageNum == 1) {
      fetchData(conditionRef.current)
    }
  }


  const reload = ()=> {
    setVisible(false)
    if(pageNum === 1) {
      fetchData(conditionRef.current)
    } else {
      setPageNum(1)
    }
  }

  useEffect(()=> {
    fetchData(conditionRef.current)
  }, [pageNum])

  return (
    <Fragment>
    <div className={indexStyl.wrapper}>
      <Condition onSearch={onSearch}/>
      <div className={indexStyl.toolbar}>
        <Button type="primary" onClick={()=> {
          setRecord(undefined)
          setVisible(true)
        }}>新增</Button>
      </div>
      <div className={indexStyl.tableWrapper}>
        <Table
          loading={loading}
          size="middle"
          rowKey="id"
          scroll={{y: window.innerHeight - 350}}
          columns={columns}
          dataSource={dataSource}
          locale={{emptyText: <Empty description="暂无数据"/>}}
          pagination={{
            total: total,
            current: pageNum,
            pageSize: pageSize,
            onChange: (page: number)=> setPageNum(page),
            showSizeChanger: false,
            showQuickJumper: false,
            showTotal: total=> `共${total}条，每页${pageSize}条`,
            position: ["bottomRight"]}}/>
      </div>
    </div>
    {
      visible && (
        <EditModal record={record} onClose={reload}/>
      )
    }
    {
      visibleQR && record && (
        <QRModal record={record} onClose={()=> setVisibleQR(false)}/>
      )
    }
    </Fragment>
  )
}

export default AssistantDoctor
