/**
 * cra 首页
 */
import React, { Component } from 'react';
import pendingImg from "../static/images/pending.png";
import checkImg from "../static/images/check.png";
import passImg from "../static/images/pass.png";
import rejectImg from "../static/images/reject.png";
import settleImg from "../static/images/settle.png";
import { GetDctImageStatistics } from '../../../services/dct/DctService';
import DataComponent from "../components/data";
import CheckComponent from "./check";
import ImgTaskComponent from './task';
import './index.less';

let _this;

class CraImagesComponent extends Component {

    constructor(props) {
        super(props);
        this.childRef = React.createRef()
        _this = this;
        this.state = {
            isVisible: false,
            checkedData: [],
            statisticsData: null, // 统计数据
        };
    }

    componentDidMount() {
        this.getStatistics()
    }

    // 统计
    getStatistics = () => {
        GetDctImageStatistics().then(res => {
            if (res && res.code == 0) {
                if (!res.data || res.data.length == 0) {
                    return
                }
                this.setState({
                    statisticsData: res.data
                })
            }
        })
    }

    // 获取接单状态
    getStatus = async (data) => {
        this.setState({
            isVisible: data.length > 0 ? true : false,
            checkedData: data
        })
    }

    // 获取审核状态
    getValue = (v, t) => {
        this.setState({
            isVisible: v,
        })

        if (!v) {
            if (t == '休息') {
                return
            }
            this.childRef.current.handleCheck('开始审核')
            this.getStatistics()
        }
    }

    render() {
        let { checkedData, statisticsData } = _this.state;
        let statisticsList = [
            { key: 0, icon: pendingImg, name: '待审核图片', number: statisticsData?statisticsData.pendingReviewTotal:0, color: '#276ec9' },
            { key: 1, icon: checkImg, name: '今日审核图片', number: statisticsData?statisticsData.reviewTotal:0, color: '#f39c00' },
            { key: 2, icon: passImg, name: '今日通过图片', number: statisticsData?statisticsData.adoptTotal:0, color: '#23aea8' },
            { key: 3, icon: rejectImg, name: '今日拒绝图片', number: statisticsData?statisticsData.rejectTotal:0, color: '#f83b24' },
            { key: 4, icon: settleImg, name: '可结算图片', number: statisticsData?statisticsData.settleAccountsTotal:0, color: '#58278f' }
        ]
        return (
            <div className={'dctCra_content'}>
                {!this.state.isVisible && <DataComponent data={statisticsList} />}

                {this.state.isVisible &&
                    <CheckComponent
                        data={checkedData}
                        getValue={(v,t) => this.getValue(v,t)}
                    />}

                {!this.state.isVisible &&
                    <ImgTaskComponent
                        getStatus={(v) => this.getStatus(v)}
                        ref={this.childRef}
                    />}
            </div>
        )
    }
}
export default CraImagesComponent