import React from 'react';
import { connect } from 'react-redux';
import './echartsOne.less';

import ReactEcharts from '../../../../base/echarts/ReactEcharts';
import {GetProjectMonitor} from "../../../../../services/project/ProjectService";
import {actionCreator} from "../../../store";


let _this;

class EchartsOne extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            monitorData: [],
        }
    }

    componentDidMount() {
    }

    getOption1Data = () => {
        let {groupNum,groupTotalNum} = _this.props.projectMonitor;
        const res = [
            {value: groupNum, name: '入组数',num: groupNum, total: groupTotalNum},
            {value: groupTotalNum - groupNum, name: '未入组数',num: groupNum, total: groupTotalNum},
        ]
        return res;
    }

    getOption1 = () => {
        let option = {
            series: [
                {
                    name: '入组',
                    type: 'pie',
                    radius: ['97%', '100%'],
                    hoverAnimation: false,
                    label: {
                        color: 'rgba(0, 0, 0, 0.65)',
                        formatter: (item)=>{
                            const data = item.data;
                            if(data.total != undefined){
                                if(data.total == 0){
                                    return "*%";
                                }else {
                                    return ((data.num/data.total).toFixed(4)*100).toFixed(1) + "%";
                                }
                            }else {
                                return "--";
                            }
                        },
                        position: 'center',
                        fontSize: '14',
                        fontWeight: '500',
                        fontFamily: 'PingFangSC-Medium, PingFang SC'
                    },
                    labelLine: {
                        show: false
                    },
                    data: _this.getOption1Data(),
                    color: ['#6C96F7', '#E9F2FF']
                }
            ]
        }
        return option;
    }

    getOption2Data = () => {
        let {inputNum,inputTotalNum} = _this.props.projectMonitor;
        const res = [
            {value: inputNum, name: '录入',num: inputNum, total: inputTotalNum},
            {value: inputTotalNum - inputNum, name: '未录入',num: inputNum, total: inputTotalNum},
        ]
        return res;
    }

    getOption2 = () => {
        let option = {
            series: [
                {
                    name: '录入',
                    type: 'pie',
                    radius: ['97%', '100%'],
                    hoverAnimation: false,
                    label: {
                        color: 'rgba(0, 0, 0, 0.65)',
                        formatter: (item)=>{
                            const data = item.data;
                            if(data.total != undefined){
                                if(data.total == 0){
                                    return "*%";
                                }else {
                                    return ((data.num/data.total).toFixed(4)*100).toFixed(1) + "%";
                                }
                            }else {
                                return "--";
                            }
                        },
                        position: 'center',
                        fontSize: '14',
                        fontWeight: '500',
                        fontFamily: 'PingFangSC-Medium, PingFang SC'
                    },
                    labelLine: {
                        show: false
                    },
                    data: _this.getOption2Data(),
                    color: ['#6C96F7', '#E9F2FF']
                }
            ]
        }
        return option;
    }

    getOption3Data = () => {
        let {questionReviewNum,reviewTotalNum} = _this.props.projectMonitor;
        const res = [
            {value: questionReviewNum, name: '人工核查',num: questionReviewNum, total: reviewTotalNum},
            {value: reviewTotalNum - questionReviewNum, name: '未人工核查',num: questionReviewNum, total: reviewTotalNum},
        ]
        return res;
    }

    getOption3 = () => {
        let option = {
            series: [
                {
                    name: '人工核查',
                    type: 'pie',
                    radius: ['97%', '100%'],
                    hoverAnimation: false,
                    label: {
                        color: 'rgba(0, 0, 0, 0.65)',
                        formatter: (item)=>{
                            const data = item.data;
                            if(data.total != undefined){
                                if(data.total == 0){
                                    return "*%";
                                }else {
                                    return ((data.num/data.total).toFixed(4)*100).toFixed(1) + "%";
                                }
                            }else {
                                return "--";
                            }
                        },
                        position: 'center',
                        fontSize: '14',
                        fontWeight: '500',
                        fontFamily: 'PingFangSC-Medium, PingFang SC'
                    },
                    labelLine: {
                        show: false
                    },
                    data: _this.getOption3Data(),
                    color: ['#6C96F7', '#E9F2FF']
                }
            ]
        }
        return option;
    }

    getOption4Data = () => {
        let {examineReviewNum,reviewTotalNum} = _this.props.projectMonitor;
        const res = [
            {value: examineReviewNum, name: '数据核查',num: examineReviewNum, total: reviewTotalNum},
            {value: reviewTotalNum - examineReviewNum, name: '未数据核查',num: examineReviewNum, total: reviewTotalNum},
        ]
        return res;
    }

    getOption4 = () => {
        let option = {
            series: [
                {
                    name: '数据核查',
                    type: 'pie',
                    radius: ['97%', '100%'],
                    hoverAnimation: false,
                    label: {
                        color: 'rgba(0, 0, 0, 0.65)',
                        formatter: (item)=>{
                            const data = item.data;
                            if(data.total != undefined){
                                if(data.total == 0){
                                    return "*%";
                                }else {
                                    return ((data.num/data.total).toFixed(4)*100).toFixed(1) + "%";
                                }
                            }else {
                                return "--";
                            }
                        },
                        position: 'center',
                        fontSize: '14',
                        fontWeight: '500',
                        fontFamily: 'PingFangSC-Medium, PingFang SC'
                    },
                    labelLine: {
                        show: false
                    },
                    data: _this.getOption4Data(),
                    color: ['#6C96F7', '#E9F2FF']
                }
            ]
        }
        return option;
    }


    getOption5Data = () => {
        let {freezeNum,freezeTotalNum} = _this.props.projectMonitor;
        const res = [
            {value: freezeNum, name: '冻结',num: freezeNum, total: freezeTotalNum},
            {value: freezeTotalNum - freezeNum, name: '未冻结',num: freezeNum, total: freezeTotalNum},
        ]
        return res;
    }

    getOption5 = () => {
        let option = {
            series: [
                {
                    name: '冻结',
                    type: 'pie',
                    radius: ['97%', '100%'],
                    hoverAnimation: false,
                    label: {
                        color: 'rgba(0, 0, 0, 0.65)',
                        formatter: (item)=>{
                            const data = item.data;
                            if(data.total != undefined){
                                if(data.total == 0){
                                    return "*%";
                                }else {
                                    return ((data.num/data.total).toFixed(4)*100).toFixed(1) + "%";
                                }
                            }else {
                                return "--";
                            }
                        },
                        position: 'center',
                        fontSize: '14',
                        fontWeight: '500',
                        fontFamily: 'PingFangSC-Medium, PingFang SC'
                    },
                    labelLine: {
                        show: false
                    },
                    data: _this.getOption5Data(),
                    color: ['#6C96F7', '#E9F2FF']
                }
            ]
        }
        return option;
    }


    getOption6Data = () => {
        let {signNum,signTotalNum} = _this.props.projectMonitor;
        const res = [
            {value: signNum, name: '签名',num: signNum, total: signTotalNum},
            {value: signTotalNum - signNum, name: '未签名',num: signNum, total: signTotalNum},
        ]
        return res;
    }

    getOption6 = () => {
        let option = {
            series: [
                {
                    name: '签名',
                    type: 'pie',
                    radius: ['97%', '100%'],
                    hoverAnimation: false,
                    label: {
                        color: 'rgba(0, 0, 0, 0.65)',
                        formatter: (item)=>{
                            const data = item.data;
                            if(data.total != undefined){
                                if(data.total == 0){
                                    return "*%";
                                }else {
                                    return ((data.num/data.total).toFixed(4)*100).toFixed(1) + "%";
                                }
                            }else {
                                return "--";
                            }
                        },
                        position: 'center',
                        fontSize: '14',
                        fontWeight: '500',
                        fontFamily: 'PingFangSC-Medium, PingFang SC'
                    },
                    labelLine: {
                        show: false
                    },
                    data: _this.getOption6Data(),
                    color: ['#6C96F7', '#E9F2FF']
                }
            ]
        }
        return option;
    }

    getOption7Data = () => {
        let {lockNum,lockTotalNum} = _this.props.projectMonitor;
        const res = [
            {value: lockNum, name: '锁定',num: lockNum, total: lockTotalNum},
            {value: lockTotalNum - lockNum, name: '未锁定',num: lockNum, total: lockTotalNum},
        ]
        return res;
    }

    getOption7 = () => {
        let option = {
            series: [
                {
                    name: '锁定',
                    type: 'pie',
                    radius: ['97%', '100%'],
                    hoverAnimation: false,
                    label: {
                        color: 'rgba(0, 0, 0, 0.65)',
                        formatter: (item)=>{
                            const data = item.data;
                            if(data.total != undefined){
                                if(data.total == 0){
                                    return "*%";
                                }else {
                                    return ((data.num/data.total).toFixed(4)*100).toFixed(1) + "%";
                                }
                            }else {
                                return "--";
                            }
                        },
                        position: 'center',
                        fontSize: '14',
                        fontWeight: '500',
                        fontFamily: 'PingFangSC-Medium, PingFang SC'
                    },
                    labelLine: {
                        show: false
                    },
                    data: _this.getOption7Data(),
                    color: ['#6C96F7', '#E9F2FF']
                }
            ]
        }
        return option;
    }

    render() {
        let data = _this.props.projectMonitor;
        if (JSON.stringify(data) !== '{}') {
            return (<div className={'e_Group1'}>
                <ul>
                    <li>
                        <ReactEcharts
                            id={'id1'}
                            option={_this.getOption1()}
                            style={{width: 74, height: 74, margin: '0 auto'}}/>
                        <div className={'echarts_title'}>
                            <p><span>{data.groupNum}</span>/<span>{data.groupTotalNum !=undefined?data.groupTotalNum:"--"}</span></p>
                            <span>入组{sessionStorage.getItem('targetName') || '受试者'}</span>
                        </div>
                    </li>
                    <li>
                        {

                        }
                        <ReactEcharts
                            id={'id2'}
                            option={_this.getOption2()}
                            style={{width: 74, height: 74, margin: '0 auto'}}/>
                        <div className={'echarts_title'}>
                            <p><span>{data.inputNum}</span>/<span>{data.inputTotalNum !=undefined?data.inputTotalNum:"--"}</span></p>
                            <span>录入表单</span>
                        </div>
                    </li>
                    <li>
                        <ReactEcharts
                            id={'id3'}
                            option={_this.getOption3()}
                            style={{width: 74, height: 74, margin: '0 auto'}}/>
                        <div className={'echarts_title'}>
                            <p><span>{data.questionReviewNum}</span>/<span>{data.reviewTotalNum !=undefined?data.reviewTotalNum:"--"}</span></p>
                            <span>人工核查</span>
                        </div>
                    </li>
                    <li>
                        <ReactEcharts
                            id={'id4'}
                            option={_this.getOption4()}
                            style={{width: 74, height: 74, margin: '0 auto'}}/>
                        <div className={'echarts_title'}>
                            <p><span>{data.examineReviewNum}</span>/<span>{data.reviewTotalNum !=undefined?data.reviewTotalNum:"--"}</span></p>
                            <span>数据核查</span>
                        </div>
                    </li>
                    <li>
                        <ReactEcharts
                            id={'id5'}
                            option={_this.getOption5()}
                            style={{width: 74, height: 74, margin: '0 auto'}}/>
                        <div className={'echarts_title'}>
                            <p><span>{data.freezeNum}</span>/<span>{data.freezeTotalNum !=undefined?data.freezeTotalNum:"--"}</span></p>
                            <span>冻结表单</span>
                        </div>
                    </li>
                    <li>
                        <ReactEcharts
                            id={'id6'}
                            option={_this.getOption6()}
                            style={{width: 74, height: 74, margin: '0 auto'}}/>
                        <div className={'echarts_title'}>
                            <p><span>{data.signNum}</span>/<span>{data.signTotalNum !=undefined?data.signTotalNum:"--"}</span></p>
                            <span>签名表单</span>
                        </div>
                    </li>
                    <li>
                        <ReactEcharts
                            id={'id7'}
                            option={_this.getOption7()}
                            style={{width: 74, height: 74, margin: '0 auto'}}/>
                        <div className={'echarts_title'}>
                            <p><span>{data.lockNum}</span>/<span>{data.lockTotalNum !=undefined?data.lockTotalNum:"--"}</span></p>
                            <span>锁定表单</span>
                        </div>
                    </li>
                </ul>
            </div>);
        } else {
            return <div></div>
        }
    }
}

const mapStateToProps = (state) => ({
    projectMonitor: state.getIn(['project', 'projectMonitor'])
})

const mapDispatchToProps = (dispatch) => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(EchartsOne);