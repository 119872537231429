import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { AutoComplete, Input, message, Result, Spin, Tooltip } from "antd";
import { saveFormData } from "../../../../services/data/DataService";
import "./input.less";
import ActionView from "../../com/actionView/actionView";
import verify from "../../utils/verify";
import formAction from "../../utils/formAction";
import mExper from "../../utils/operation.ts";
import DctOcrView from "../../com/dctOcrView/dctOcrView";
import { InfoCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { get as httpGet, post as httpPost } from "../../../../comm/http";
import {
  AutoCompoleteProps,
  FetchMethod,
  GlobalValueItem,
  SearchResult,
  StackData,
  ValueItem,
  mergeServerUrl,
  parseLabelCombine,
  parseParamsValue,
} from "./utils";

function AutoCompleteCom(props: AutoCompoleteProps, ref: any) {
  const [value, setValue] = useState<any>();
  const [data, setData] = useState({} as AutoCompoleteProps["data"]);
  const [formId, setFormId] = useState<any>();
  const [dataActions, setDataActions] = useState<any>([]);
  const [formIsRead, setFormIsRead] = useState<boolean>(true);
  const [timeObject, setTimeObject] = useState<any>();
  const [savedValues, setSavedValues] = useState<any>([]);
  const [updatedValue, setUpdatedValue] = useState<any>();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [inputError, setInputError] = useState<boolean>(false);

  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [delay, setDelay] = useState(500)
  const [stackData, setStackData] = useState<StackData>(
    {} as StackData
  );

  const [projectData, setProjectData] = useState<any>({})

  const [ocrLists, setOcrLists] = useState<any>(null);
  // 手动划词内容
  const [ocrValue, setOcrValue] = useState<any>(null);
  // 超范围异常提醒
  const [annotation, setAnnotation] = useState<any>(null);

  useEffect(()=> {
    // 取项目数据
    const ss = sessionStorage.getItem('photoEditItem') ?? '{}'

    setProjectData(JSON.parse(ss))

  }, [])

  useEffect(() => {
    if (props.data) {
      //设置dataActions
      setDataActions(props.dataActions);
      //表单是否只读
      setFormIsRead(props.formIsRead);
      //获取当前表单的id
      setFormId(props.formId);

      //该条表单元素的数据
      const { properties, ...propsData } = props.data;

      setDelay(properties.delay ?? 500)

      setData((prev) => {
        const value = {
          ...propsData,
          properties: {
            ...{
              method: FetchMethod.GET,
              labelName: "label",
              labelCombine: [],
              query: {},
              payload: {},
              autofillMap: [],
            },
            ...properties,
          },
        };
        if (JSON.stringify(prev) === JSON.stringify(value)) {
          return prev;
        }
        return value;
      });

      //已经保存的表单数据
      setSavedValues(props.savedValues);

      if (props.ocrLists) {
        setOcrLists(props.ocrLists);
      }

      //如果是修改时
      if (props.isUpdate) {
        setIsUpdate(props.isUpdate);
      } else {
        setIsUpdate(false);
      }
      setInputError(false);
      //设置当前表单已存的value
      let currentItem = props.savedValues.find((i: any) => {
        return i.dataCode == props.data.dataCode;
      });
      if (currentItem) {
        setValue(currentItem.value);
        setUpdatedValue(currentItem.value);
      } else {
        setValue(null);
        setUpdatedValue(null);
      }

      if (props.data.isAdd) {
        let Index = props.savedValues.findIndex(
          (v: any) => v.dataCode == props.data.dataCode
        );
        if (Index == -1) {
          let params = {
            formId: props.formId,
            submit: false,
            values: [
              {
                dataCode: props.data.dataCode,
                deCode: props.data.deCode,
                value: "",
                extendAttribute: "isAdd=true",
              },
            ],
          };
          formAction.saveData(params);
        }
      }
      if (props.data.logicOperations && props.data.logicOperations.length > 0) {
        for (let logicOperation of props.data.logicOperations) {
          let result = mExper.handleLogicOperations(logicOperation, {
            savedValues: props.savedValues,
            data: data,
          });
          if (!result || result == "null") {
            continue;
          }
          setValue(result);
          let values = [
            {
              dataCode: data.dataCode,
              deCode: data.deCode,
              value: result,
            },
          ];
          saveData(formId, values);
        }
      }
      // 超范围异常提醒
      if (props.data.remind || props.data.reminds) {
        if (currentItem) {
          setAnnotation(
            verify.checkValueAnnotation(
              currentItem.value,
              props.data,
              props.formCenterCode
            )
          );
        } else {
          setAnnotation(
            verify.checkValueAnnotation(value, props.data, props.formCenterCode)
          );
        }
      }
    }
  }, [props]);

  useEffect(() => {
    const multi = [
      props.globalValues ?? [],
      props.savedValues ?? []
    ]

    const stack: StackData = {}
    
    multi.forEach((v)=> {
      v.reduce(
        (stack, item: GlobalValueItem | ValueItem) => {
          stack[item.dataCode] = item.value ?? "";
          return stack;
        },
        stack
      );
    })
    
    setStackData(stack);
  }, [props.savedValues, props.globalValues]);

  //当提交后显示的value
  const showSavedValue = () => {
    if (value) {
      return value;
    } else {
      return "未录入";
    }
  };

  const debounceChange = useMemo(()=> {
    return debounce((value: string)=> {
      setAnnotation(
        verify.checkValueAnnotation(value, data, props.formCenterCode)
      );
      saveItemData(value);
      if (
        (props.ocrLists && props.ocrLists.length > 0 && ocrValue) ||
        value == ""
      ) {
        deleteFunc();
      }
    }, 500)
  }, [data, props.formCenterCode, props.ocrLists, ocrValue])

  const onChange = (value: string) => {
    setValue(value);
    debounceChange(value)
  }

  const debounceFetchOptions = useMemo(()=> {
    return debounce((keyword: string)=> {
      if(keyword.length === 0) {
        setOptions([])
        setFetching(false);
        return
      }

      const properties = data.properties

      const serverUrl = mergeServerUrl(properties, keyword, stackData, projectData);

      let httpClient;

      if (properties.method === FetchMethod.GET) {
        httpClient = httpGet(serverUrl);
      } else {
        let payload = {};
        for (const name in properties.payload) {
          let value = properties.payload[name];
          if (typeof value === "string") {
            value = parseParamsValue(value, stackData, projectData);
          }
          payload[name] = value;
        }
        httpClient = httpPost(serverUrl, payload);
      }

      httpClient.then(({ code, message: msg, data }: SearchResult) => {

        if(code !== 0) {
          message.error(msg);
          return []
        }

        setFetching(false);

        setOptions(
          data.map((item)=> {
            let label = item[properties.labelName] ?? '';
            if(properties.labelCombine.length > 0) {
              label = parseLabelCombine(properties, stackData, item, projectData)
            }
            return {
              label,
              value: item[properties.labelName] ?? '',
              origin: item
            }
          })
        );
      });
    }, delay)
  }, [data.properties, stackData, projectData, delay])

  const onSearch = (keyword: string)=> {
    setFetching(true);
    debounceFetchOptions(keyword)
  }

  // 选择项目触发修改values
  const onSelect = (value: any, dataSource: any)=> {
    const { properties } = data;

    if(!Array.isArray(properties.autofields)) {
      return
    }

    if(properties.autofields.length === 0) {
      return;
    }

    const { origin } = dataSource

    // values不需要和storage合并处理
    let values: ValueItem[] = [
      {
        dataCode: data.dataCode,
        deCode: data.deCode,
        value: value,
      }
    ]
    properties.autofields.forEach((pieces)=> {
      if(typeof pieces === 'string') {
        pieces = [pieces.split('|')[0], pieces]
      }
      if(Array.isArray(pieces)) {
        const [dataCode, value] = pieces
        const [dynamicDataCode, defaultValue] = value.split('|')

        const mixData = {
          // ...stackData,
          ...origin
        }

        // 原字符输出
        if(dynamicDataCode.startsWith('!')) {
          values.push({
            dataCode: dataCode,
            deCode: dataCode,
            value: dynamicDataCode.substring(1)
          })
          return
        }

        if(typeof mixData[dynamicDataCode] === 'undefined') {
          // 数据源中没有需要的 dataCode 且没有设置默认值不进行数据初始化
          if(typeof defaultValue === 'undefined') {
            return
          }
        }

        values.push({
          dataCode: dataCode,
          deCode: dataCode,
          value: mixData[dynamicDataCode] ?? (defaultValue ?? '')
        })
      }
    })
    saveData(formId, values);
  }

  // 清除划词
  const deleteFunc = () => {
    let currentData = {
      sort: data.sort,
      dataCode: data.dataCode,
      deCode: data.deCode,
    };

    props.deleteDrawAreaFunc &&
      props.deleteDrawAreaFunc(currentData).then((res: String) => {
        console.log("删除划词", res);
      });
  };

  const saveItemData = (filterValue: any) => {

    // setValue(filterValue);

    if (verify.checkValueByRep(filterValue, data)) {
      setInputError(false);
    } else {
      setInputError(true);
    }
    let extendAttribute = "";
    if (props.data.isAdd) {
      extendAttribute = "isAdd=true";
    }

    let values = [
      {
        dataCode: data.dataCode,
        deCode: data.deCode,
        value: filterValue,
        extendAttribute: extendAttribute ? extendAttribute : undefined,
      },
    ];
    if (isUpdate) {
      if (verify.checkValueByRep(filterValue, data)) {
        setUpdatedValue(filterValue);
      } else {
        message.error("请输入正确的格式！", 1);
      }
    }
    if (timeObject) {
      clearTimeout(timeObject);
    }
    let time = setTimeout(() => {
      if (!isUpdate) {
        if (verify.checkValueByRep(filterValue, data)) {
          saveData(formId, values);
        } else {
          message.error("请输入正确的格式！", 1);
        }
      }
    }, 200);
    setTimeObject(time);
  };

  //暂存数据
  const saveData = (formId: any, values: any) => {
    let params = {
      formId: formId,
      submit: false,
      values: values,
    };
    formAction.saveData(params);
    // props.startTimerFun();
    props.updateFun();
    // saveFormData(params).then((res) => {
    //     console.log(1111)
    //     props.updateFun()
    // })
  };

  useImperativeHandle(ref, () => ({
    // changeVal 就是暴露给父组件的方法
    getModifyValue: () => {
      if (!inputError) {
        let params = {
          dataCode: data.dataCode,
          deCode: data.deCode,
          formId: formId,
          value: updatedValue,
        };
        return params;
      } else {
        message.error("请输入正确的格式！", 1);
        return null;
      }
    },
  }));

  // 获取划词信息
  const getOcrValue = (values: any) => {
    setOcrValue(values);
    data["isDelimitation"] = true;
    saveItemData(values && values.text);
  };

  // 获取划词icon信息
  const getOcrIcon = (values: any) => {
    props.getOcrIcon(values);
  };

  return (
    <div className="inputCom">
      <AutoComplete
        allowClear={true}
        disabled={!formIsRead}
        notFoundContent={fetching ? <Spin size="small" /> : <Result icon={<InfoCircleFilled style={{color: '#39f', fontSize: "3rem"}} />} subTitle={ value?.length > 0 ? '未检索到数据' : '请输入名称后检索'} />}
        placeholder={data ? `请输入${data.label}` : ""}
        options={options}
        filterOption={false}
        onSearch={onSearch}
        onChange={onChange}
        onSelect={onSelect}
        value={value}
        style={{
          width: "100%",
          border: inputError ? "1px solid red" : "",
          color:
            annotation && annotation.status ? "red" : "rgba(0, 0, 0, 0.85)",
        }}
      ></AutoComplete>
    </div>
  );
}

export default forwardRef(AutoCompleteCom);
