import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Modal, Upload, message as messager } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import alioss from "ali-oss";
import {
  getProjectCache,
  getUserCache,
  GlobalValueItem,
  makeThumb,
  makeUploadHeaders,
  makeUploadName,
  makeUploadOptions,
  mergeServerUrl,
  OCRAutoCompoleteProps,
  parseListTypeData,
  parseParamsValue,
  ProjectData,
  FormData,
  ValueItem,
  searchFieldScope,
  pickOcrFillFields,
  pickFields,
  ScanResult,
} from "./utils";
import { post as httpPost } from "../../../../comm/http";
import formAction from "../../utils/formAction";
import { UploadFile } from "antd/lib/upload/interface";
import styl from "./OCRAutofill.module.scss";
import { saveFormData } from "../../../../services/data/DataService";

const Picker = () => {
  return (
    <div className={styl.uploadPick}>
      <PlusOutlined />
      <span>点击上传图片</span>
    </div>
  );
};

const OCRAutocomplete = (props: OCRAutoCompoleteProps, ref: any) => {
  // 编辑时标记当前的删除操作
  const [editRemoveFlag, setEditRemoveFlag] = useState(false)
  // 上传文件列表
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  // 后台识别结果
  const [scanResult, setScanResult] = useState<GlobalValueItem[]>([]);
  // 当前表单数据
  const [formData, setFormData] = useState<FormData>({} as FormData);
  // 当前登录者信息
  const [userData] = useState(() => getUserCache());
  // 当前访问的项目信息
  const [projectData] = useState<ProjectData>(() =>
    getProjectCache(props.formId)
  );

  const [preview, setPreview] = useState({
    open: false,
    url: "",
  });

  const pickFileList = ()=> {
    if (Array.isArray(props.savedValues)) {
      for (const item of props.savedValues) {
        if (item.dataCode === props.data.dataCode) {
          if (Array.isArray(item.value)) {
            setFileList(() =>
              (item.value as string[]).map((url) => makeThumb(url))
            );
            break;
          }
        }
      }
    }
  }

  //
  useEffect(pickFileList, []);

  useEffect(() => {
    const data = props.savedValues.reduce((stack, item: GlobalValueItem | ValueItem) => {
      stack[item.dataCode] = item.value ?? "";
      return stack;
    }, {} as FormData);
    setFormData(data);

    pickFileList()
  }, [props.savedValues]);

  //暂存数据
  const saveData = (values: GlobalValueItem[], removeKeys: string[] = []) => {
    formAction.saveData(
      {
        formId: props.formId,
        submit: false,
        values: values,
      },
      removeKeys
    );
    props.updateFun && props.updateFun();
  };

  // 组装Value
  const makeValueItem = (files: UploadFile[]): GlobalValueItem => {
    return {
      deCode: props.data.deCode,
      dataCode: props.data.dataCode,
      value: files.map(({ url }) => url) as string[],
    };
  };

  // 第3步：将识别结果填充到字段
  const fieldFill = (thumb: UploadFile, data: GlobalValueItem[], scopeFormConfig: any[])=> {
    const properties = props.data.properties;
    const delimiters = properties.delimiters ?? ['~', '--', '-', '－', '～']
    const scanValues: GlobalValueItem[] = [];

    // 
    type a = {
      [key: string]: {
        min: string;
        max: string;
        radio: string;
        other: string;
      };
    }
    // 最终缓存
    const ocrMap: a = {
      //
    }

    // 判断当前表单是否是list类型 list类型仅有一项时是没有#分隔符的
    const pieces = props.data.dataCode.split('#')
    
    // 作用域下所有
    const fillFields = pickOcrFillFields(scopeFormConfig)

    for (const { dataCode, deCode, value, reference } of data) {
      if (value === null) {
        continue;
      }

      const newCode = pieces.length === 2 ? `${dataCode}#${pieces[1]}` : dataCode

      const currentField = fillFields[newCode]

      if(!currentField) {
        continue
      }

      let pValue = value
      if (currentField.type === 'unknownDate') {
        // ["2012-9-9", "2012", "-", "9", "-", "9"]
        const result = /^(\d{4})(\-|\/)(\d{1,2})(\-|\/)(\d{1,2})/.exec(value as string)
        if(Array.isArray(result)) {
          // 匹配到的结果前补0
          const pieces: string[] = [1, 3, 5].map((n: number)=> {
            return result[n].padStart(2, '0')
          })
          pValue = pieces.join('-')
        }
      } else {
        // 匹配到的是输入范围或单位时
        if(reference === 'unit' || reference === 'range') {
          // 将识别的结果更新到字段中
          // 目前只做了 input-select 组件
          switch(currentField.type) {
            case 'input-select':
              // 获取原值方便合并
              let old = ocrMap[newCode] ?? {
                min: '',
                max: '',
                radio: '',
                other: ''
              }

              // 化验单参考范围值
              if(reference === 'range') {
                for(const d of delimiters) {
                  const pieces = (value as string).split(d)
                  // 匹配到分隔符号
                  if(pieces.length === 2) {
                    ocrMap[newCode] = {
                      ...old,
                      min: pieces[0],
                      max: pieces[1] ?? '',
                    }
                    break;
                  }
                }
              } else {
                // 没有化验结果单位时跳过
                if(!currentField.optional) {
                  continue;
                }
                // 化验单单位
                let matched = false
                for(const radio in currentField.optional) {
                  // 匹配到单位
                  if(currentField.optional[radio] === value) {
                    matched = true;
                    ocrMap[newCode] = {
                      ...old,
                      radio,
                      other: ''
                    }
                    break;
                  }
                }
                // 没有匹配到则使用其他
                if(!matched) {
                  ocrMap[newCode] = {
                    ...old,
                    radio: currentField.other ? currentField.other.key : '',
                    other: value as string
                  }
                }
              }
              break;
            default:
              break;
          }
          continue;
        }
      }

      //
      scanValues.push({
        dataCode: newCode,
        deCode,
        value: pValue,
      });
    }

    // 将特殊字段写入
    for(const dataName in ocrMap) {
      scanValues.push({
        dataCode: dataName,
        deCode: dataName.split('#').shift() as string,
        value: ocrMap[dataName],
      })
    }
    
    setScanResult(scanValues);
    
    // 非编辑模式进行实时保存
    if(!props.isUpdate) {
      saveData([...scanValues]);
    }

    if(scanResult.length > 0) {
      setEditRemoveFlag(false)
    }

    messager.warning(
      scanValues.length > 0 ? "请确认识别的数据" : "未识别到数据"
    );
  }

  // 第2步：将OSS地址发给后端识别
  const fetchScanResult = async (imageUrl: string, scopeFormConfig: any[]) => {
    const shareData = {
      ...parseListTypeData(formData, pickFields(scopeFormConfig)),
      imageUrl
    };

    const properties = props.data.properties;

    const serverUrl = mergeServerUrl(properties, shareData, userData, projectData);

    let payload: { [key: string]: any } = {};
    for (const name in properties.payload) {
      let value = properties.payload[name];
      if (typeof value === "string") {
        value = parseParamsValue(value, shareData, userData, projectData);
      }
      payload[name] = value;
    }
    const { code, message, data }: ScanResult = await httpPost(
      serverUrl,
      payload
    );
    if (code === 0) {
      return data;
    }
    messager.error(message);
  }

  // 第1步：上传图片
  const startUpload = async (file: File) => {
    const pieces = await makeUploadOptions(messager.error);

    if (!pieces) {
      messager.error("初始化上传参数失败");
      return;
    }

    const [prefix, options] = pieces;

    const client = new alioss(options);

    const pathname = makeUploadName(prefix, file);

    if (!pathname) {
      messager.error("上传缺少必要参数");
      return;
    }

    // 上传图片到OSS
    const result = await client.put(pathname, file, {
      timeout: 600 * 1000,
      headers: makeUploadHeaders(props.formId),
    });

    if (result.res.status !== 200) {
      messager.error("上传失败，请重试！");
      return
    }

    const thumb = makeThumb(result.url);

    setFileList((pre) => [...pre, thumb]);
    saveData([makeValueItem([...fileList, thumb])])

    // 确认表单填充作用域
    const scopeFormConfig = searchFieldScope(props.data.dataCode, props.formConfig)

    // 去后端识别 必须返回 dataCode, deCode, value, reference
    const data = (await fetchScanResult(
      result.url,
      scopeFormConfig
    )) as GlobalValueItem[];

    // 将结果填充到字段
    if (Array.isArray(data) && data.length > 0) {
      fieldFill(thumb, data, scopeFormConfig)
    } else {
      messager.warning('系统未识别到数据')
    }
  }

  // changeVal 就是暴露给父组件的方法
  useImperativeHandle(ref, () => ({
    getModifyValue: () => {

      const onUpdateSuccess = async ()=> {
        const unsets: GlobalValueItem[] = [];
        const scopeConfig = searchFieldScope(props.data.dataCode, props.formConfig)
        const fillFields = pickOcrFillFields(scopeConfig)
        const cleans = Object.keys(fillFields)
        // 获取所有扫描结果
        let values = scanResult.map(({dataCode, deCode, value})=> ({
          dataCode, deCode, value
        })) as GlobalValueItem[]

        // 获取要删除的
        if(editRemoveFlag) {
          // 自动填充不存在的字段
          const makeFill = (dataCode: string)=> {
            const [deCode, _] = dataCode.split('#')
            unsets.push({
              dataCode: dataCode,
              deCode: deCode,
              value: ''
            })
          }
          if(values.length === 0) {
            cleans.forEach((dataCode)=> makeFill(dataCode))
          }
          values.forEach((item)=> {
            if(cleans.includes(item.dataCode)) {
              item.value = ''
            } else {
              makeFill(item.dataCode)
            }
          })
        }
        const params = {
          formId: props.formId,
          submit: true,
          values: [...values, ...unsets]
        }
        const {code, message} = await saveFormData(params)
        if(code === 0) {
          setEditRemoveFlag(false)
          saveData(values, cleans)
        } else {
          messager.error(message)
        }
      }

      // 未识别到数据时直接跳过
      if(scanResult.length === 0) {
        return {
          formId: props.formId,
          deCode: props.data.deCode,
          dataCode: props.data.dataCode,
          value: [],
          // 修改当前字段后回调
          onUpdateSuccess: onUpdateSuccess
        }
      }
      
      return {
        ...makeValueItem(fileList),
        formId: props.formId,
        // 修改当前字段后回调
        onUpdateSuccess: onUpdateSuccess
      };
    },
  }));

  const customRequest = async (options: any) => {
    const key = 'ocr-autofill-loading';
    if(!props.data.properties) {
      messager.error('请确认组件配置项')
      return
    }
    try {
      messager.loading({
        content: '正在识别...',
        duration: 0,
        key
      })
      await startUpload(options.file);
    } catch (error) {
      console.log('?ocr-autofill-upload', error)
    } finally {
      setTimeout(() => messager.destroy(key), 100);
    }
  };

  const beforeUploadControl = (file: File) => {
    const [_, ext] = file.type.split('/')
    if(!['.png', '.jpg', '.jpeg'].includes(`.${ext}`)) {
      messager.error('上传格式不允许')
      return false
    }
  };

  const removeControl = (file: UploadFile) => {
    if(props.isUpdate) {
      let i: string = "0";
      const files: UploadFile[] = [...fileList];
      for (i in files) {
        if (files[i]["uid"] === file.uid) {
          files.splice(Number(i), 1) as UploadFile[];
          setFileList(files);
          setEditRemoveFlag(true)
          break;
        }
      }
      return
    }

    Modal.confirm({
      title: "",
      content: "确定删除",
      okText: "确定",
      cancelText: "取消",
      onOk: ()=> {
        let i: string = "0";
        const files: UploadFile[] = [...fileList];
        for (i in files) {
          if (files[i]["uid"] === file.uid) {
            files.splice(Number(i), 1) as UploadFile[];
    
            const scopeConfig = searchFieldScope(props.data.dataCode, props.formConfig)
            const fillFields = pickOcrFillFields(scopeConfig)
            const removeKeys = Object.keys(fillFields)
    
            setFileList(files);
            
            saveData([makeValueItem(files)], removeKeys);
            break;
          }
        }
      },
    });
  };

  const closePreviewControl = () => {
    setPreview({
      open: false,
      url: "",
    });
  };

  const previewControl = (file: UploadFile) => {
    setPreview({
      open: true,
      url: file.url as string,
    });
  };

  return (
    <>
      <div className={styl.ocrUploader}>
        <Upload
          accept=".png, .jpg, .jpeg"
          listType="picture-card"
          maxCount={20}
          fileList={fileList}
          customRequest={customRequest}
          beforeUpload={beforeUploadControl}
          onPreview={previewControl}
          onRemove={removeControl}
          disabled={!props.formIsRead}
        >
          {fileList.length < 20 && <Picker />}
        </Upload>
      </div>

      <Modal
        width="960px"
        visible={preview.open}
        title="预览图片"
        footer={null}
        onCancel={closePreviewControl}
      >
        <img alt={preview.url} style={{ width: "100%" }} src={preview.url} />
      </Modal>
    </>
  );
};

export default forwardRef(OCRAutocomplete);
