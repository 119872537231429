/**
 * CPDS方案管理模块路由配置
 */
import Research from '../components/cpds/research';// 研究方案列表路由组件
import ResearchDetail from '../components/cpds/research/detail/detail';// 研究方案列表路由组件



const routes = [
    { 'path': '/system/designPlan', 'component': Research },
    { 'path': '/system/designPlan/detail/:id', 'component': ResearchDetail },
];

export default routes