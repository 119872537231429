import { debounce } from "lodash";
import { post } from "../../../comm/http";

export type SimpleValueItem = {
  value: string;
  deCode: string;
  dataCode: string;
  formId: string;
}

export type StandardValueItem = Omit<SimpleValueItem, 'formId'>

export type CheckFieldOptions = {
  duplicateCheckField: string[],
  duplicateCheckFieldServer: string
}

//
export const getAllDuplicateCheckField = (list: any[]): CheckFieldOptions[] => {
  const stack: CheckFieldOptions[] = []
  for(const item of list) {
      if(item.type === 'group') {
          const { userDuplicateCheckField, projectDuplicateCheckField, duplicateCheckFieldServer } = item;
          if(duplicateCheckFieldServer) {
            if(userDuplicateCheckField || projectDuplicateCheckField) {
              stack.push({
                  duplicateCheckField: userDuplicateCheckField || projectDuplicateCheckField,
                  duplicateCheckFieldServer
              })
            }
          }
          stack.push(...getAllDuplicateCheckField(item.children))
      }
  }
  return stack;
}

export const debounceCheckUnique = (formValues: SimpleValueItem[], duplicateCheckField: CheckFieldOptions[], messager: (msg: string)=> void)=> {
  return debounce(({ value, deCode, dataCode, formId }: SimpleValueItem)=> {
    // 查询当前字段有没有配置校验规则
    const rule = duplicateCheckField.find((t)=> t.duplicateCheckField.includes(dataCode))
    if(!rule) {
        return
    }

    const values: StandardValueItem[] = [
        {
            value,
            deCode,
            dataCode,
        }
    ]

    const pureValues: string[] = [value]
    
    // 依赖的数据字段（除当前字段）
    for(const item of formValues) {
        if(pureValues.length === rule.duplicateCheckField.length) {
            break;
        }
        if(item.dataCode === dataCode) {
            continue;
        }
        if(rule.duplicateCheckField.includes(item.dataCode)) {
            pureValues.push(item.value)
            values.push({
                value: item.value,
                deCode: item.deCode,
                dataCode: item.dataCode,
            })
        }
    }

    // 和需要的字段数量不匹配
    if(rule.duplicateCheckField.length !== pureValues.length) {
        return
    }

    // 数值中有空的字段
    if(pureValues.find((v)=> !v && v != '0' )) {
        return
    }
    
    type ValidateResult = {code: number, message: string}
    post(rule.duplicateCheckFieldServer, {formId, values}).then((res)=> {
        const {code, message} = res as ValidateResult
        if(code > 0) {
            messager(message)
        }
    })
}, 800)
}