import React, { useEffect, useMemo, useState } from 'react'

import modStyl from './index.module.scss'
import { Button, Empty, Modal } from 'antd';
import { AuditOutlined, BellOutlined, WarningFilled, WarningOutlined } from '@ant-design/icons';
import { GetAuditInfo } from '../../../../../../services/dct/DctService';
import storageX, { Engine } from '../../../../../../util/storageX';

//#region 获取auduitFail明细
const fetchAuditInfo = async (subjectCode: string): Promise<string[]>=> {
  const { code, data } = await GetAuditInfo({ subjectCode })

  if(code !== 0) {
    return []
  }
  
  const pieces = (data.reasons || '').split(';')

  if(data.remark) {
    pieces.push(data.remark)
  }
  return pieces
}
//#endregion

type Item = {
  type: string;
  state: string;
  number: number;
  message: string;
}

type Props = {
  renderIcon: (type: string, state: string)=> React.ReactElement;
  onClick: (type: string)=> void;
  data: {
    code: string;
    auditState: string;
    singleSignState: number;
    // 
    singleSignRejectReason: null | string;
    singleSignRejectState: null | string;
    tasks: Item[];
  };
}

//#region 签名不合格
const Content: React.FC<Props> = ({data, onClick, renderIcon})=> {
  
  const [currentRole, projectRoleCode] = useMemo(()=> {
    return storageX(Engine.Session).get(['roleCode', 'projectRoleCode']) as string[];
  }, [])

  // 审核失败提示
  const openAuditRejectReasons = async () => {
    const lists = await fetchAuditInfo(data.code)
    Modal.warning({
      width: 600,
      title: '该病例未通过审核',
      okText: '我知道了',
      content: (
        <div className={modStyl.auditModal}>
          <p className={modStyl.auditModalTips}>请您仔细检查数据填写是否有误,修改后审核人员将对您上传的任务进行重新审核</p>
          <div className={modStyl.auditModalContent}>
            <ul>
              <li>未通过原因：</li>
              {
                lists.map((message, i) => <li key={i}>{message}</li>)
              }
            </ul>
          </div>
        </div>
      )
    });
  }

  // 显示签名审核拒绝原因
  const openSignFailReasons = ()=> {
    if(!data.singleSignRejectState) {
      return
    }
    const types = ['', '回传数据不完整','回传数据不匹配', '回传数据不清晰']
    const pieces = data.singleSignRejectState.split(',').map(t=> {
      const index = Number(t)
      if(index === 4) {
        return data.singleSignRejectReason ? `其他(${data.singleSignRejectReason})` : '其他'
      } else if(typeof types[index] === 'string') {
        return types[index]
      }
      return ''
    })
    
    Modal.warning({
      width: 600,
      title: '不合格原因',
      okText: '我知道了',
      content: (
        <div className={modStyl.auditModal}>
          <div className={modStyl.auditModalContent}>
            <ul>
              {
                pieces.map((message, i) => <li key={i}>{message}</li>)
              }
            </ul>
          </div>
        </div>
      )
    });
  }

  if(data.singleSignState === 3) {
    return (
      <span className={modStyl.Question} onClick={openSignFailReasons}>
        <AuditOutlined/>
        您有1条签名不合格任务需处理！
      </span>
    )
  }

  if(data.auditState === 'Fail') {
    const tasks = data.tasks.filter(t=> t.type === 'AuidtFail')

    if(tasks.length === 0) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无待处理事件"/>
    }

    return (
      <ul>
        {
          tasks.map((item, index) => (
            <li key={index} onClick={openAuditRejectReasons} className={modStyl.Question}>
              <WarningOutlined />{item.message}
            </li>
          ))
        }
      </ul>
    )
  }

  if(projectRoleCode == 'ReadOnly' || currentRole == 'EDC_READ' || data.tasks.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无待处理事件"/>
  }
  
  return (
    <ul>
    {
      data.tasks.map(t=> {
        if(t.message) {
          return (
            <li key={t.type} onClick={()=> onClick(t.type)} className={modStyl[t.state]}>
              {renderIcon(t.type, t.state)}{t.message}
            </li>
          )
        }
        return null
      })
    }
    </ul>
  )
}
//#endregion

const Todo: React.FC<Props> = ({data, renderIcon, onClick}) => {
  return (
    <div className={modStyl.wrapper}>
      <div className={modStyl.header}>
        <div className={modStyl.label}><BellOutlined/>待处理事件</div>
      </div>
      <div className={modStyl.content}>
        <Content renderIcon={renderIcon} onClick={onClick} data={data}/>
      </div>
    </div>
  )
}

export default Todo