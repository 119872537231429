import React from 'react';
import { Table, Input, DatePicker, Button, Select, message } from 'antd';
import './index.less'
import { getSensitiveLogList } from "../../../services/log/logService";
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
const { RangePicker } = DatePicker;
const { Option } = Select;
class OperationLog extends React.Component {

    state = {
        user: '',
        startDate: '',
        endDate: '',
        operationType: '',
        data: [],
        page: 1,
        pageSize: 10,
        totalRow: 0,
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        let params = {
            page: this.state.page,
            pageSize: this.state.pageSize,
            operationType: this.state.operationType,
            user: this.state.user,
            ed: this.state.endDate,
            sd: this.state.startDate,
        }
        getSensitiveLogList(params).then(res => {
            this.setState({
                data: res.rows,
                totalRow: res.total,
            })

        }).catch(err => {
            message.error(err.message);
        })
    }


    /**
     * 改变页数
     * @param {*} page 
     */
    changePage = (page) => {
        this.setState({ page }, () => {
            this.getData()
        })
    }


    onChange = (value, dateString) => {
        this.setState({
            startDate: dateString[0],
            endDate: dateString[1]
        })
    }

    handleChange = (val) => {
        this.setState({
            operationType: val
        })
    }

    /**
     * 搜索
     */
    handleSearch = () => {
        this.setState({
            page: 1,
            data: []
        }, () => {
            this.getData()
        })
    }


    render() {
        const columns = [
            {
                title: '操作账号',
                dataIndex: 'createUser'
            },
            {
                title: '操作人',
                dataIndex: 'createName'
            },
            {
                title: '操作时间',
                dataIndex: 'createTime',
                width: 220,
                render: (text, record, index) => {
                    return (
                        <div >
                            {moment(new Date(text)).format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                    )
                }
            },
            {
                title: '操作类型',
                dataIndex: 'operationType',
                render: (text) => {
                    return (
                        <div >
                            {text == 'ADD' ? '新增' : text == 'UPDATE' ? '修改' : text == 'DELETE' ? '删除' : ""}
                        </div>
                    )
                }
            },
            {
                title: '操作模块',
                dataIndex: 'name'
            }
        ];

        const pagination = {
            current: this.state.page,
            pageSize: this.state.pageSize,
            total: this.state.totalRow,
            showSizeChanger: false,
            onChange: this.changePage
        }


        return (
            <div className='patient_container'>
                <div className='search'>
                    <div className='searchItem'>操作人：<Input placeholder="请输入" onChange={e => this.setState({ user: e.target.value })} style={{ width: 200 }} /></div>
                    {/* <div className='searchItem'>操作模块：<Input placeholder="请输入" style={{ width: 200 }} /></div> */}
                    <div className='searchItem'>操作类型：
                        <Select onChange={this.handleChange} style={{ width: 200 }} placeholder='请选择操作类型'>
                            <Option value="ADD">新增</Option>
                            <Option value="UPDATE">修改</Option>
                            <Option value="DELETE">删除</Option>
                        </Select></div>
                    <div className='searchItem'>时间：<RangePicker locale={locale} onChange={this.onChange} /></div>
                    <Button type="primary" onClick={this.handleSearch}>搜索</Button>
                </div>
                <Table rowKey={(record) => record.id} bordered columns={columns} dataSource={this.state.data} pagination={pagination} />

            </div>
        )
    }

}

export default OperationLog;