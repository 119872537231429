import { Button, Divider, Dropdown, Empty, Input, List, message as messager, Popover, Spin, Typography } from "antd";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { ArrowRightOutlined, UserOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";

import "./Chat.less";
import Thumb from "./Thumb";
import Preview from "./Preview";
import AliUpload from "./Upload";

import { createMessage, messageList, MessageListResult } from "../../services/data/chat";
import storageX from "../../util/storageX";
import Quick from "./Quick";

type Props = {
  subjectCode: string;
};

const Chat: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);

  //
  const [subjectCode, setSubjectCode] = useState("");
  const [userCode] = useState(() => {
    return storageX().user().userCode;
  });

  const pageSize = 10;

  // 聊天记录
  const [rows, setRows] = useState<MessageListResult[]>([]);

  const fetchingHistory = useRef(false)

  // 刷新ID
  const lastId = useRef(0);
  // 历史ID
  const lastHistoryId= useRef(0);
  // 是否有历史数据
  const [total, setTotal] = useState(0);

  // 留言
  const [content, setContent] = useState("");

  // 上传
  const [preview, setPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [urls, setUrls] = useState<string[]>([]);

  const removeControl = (index: number) => {
    urls.splice(index, 1);
    setUrls([...urls]);
  };

  const previewControl = (file: UploadFile) => {
    setPreview(true);
    setPreviewUrl(file.url as string);
  };

  const uploadSuccess = (url: string) => {
    setUrls((pre) => [...pre, url]);
  };

  // 发送留言
  const sendMessage = async () => {
    if(content.length === 0 && urls.length === 0) {
      return
    }
    try {
      const { code } = await createMessage({
        sender: userCode,
        subjectCode: subjectCode,
        content: content,
        url: urls.join(","),
      });

      if (code === 0) {
        messager.success("发送成功", 2);
        setContent("");
        setUrls([]);
      }
    } catch (error) {}
  };

  const contentTyping = (event: ChangeEvent) => {
    setContent((event.target as HTMLTextAreaElement).value);
  };

  // 获取更多
  const fetchHistoryMessage = async () => {
    setLoading(true);
    const { code, message, data } = await messageList({
      id: lastHistoryId.current,
      pageSize: pageSize,
      sender: userCode,
      subjectCode: subjectCode,
      refresh: false,
    });

    setLoading(false);

    if (code > 0) {
      messager.error(message);
      return;
    }

    if (data.rows.length === 0) {
      return;
    }

    const messages = data.rows as MessageListResult[];

    messages.reverse()

    lastHistoryId.current = (messages.at(0) as MessageListResult).id;
    fetchingHistory.current = true
    setTotal(data.total);
    setRows((pre) => [...data.rows, ...pre]);

    // 加载历史记录停止自动滚动
    let elHeight = 0;
    const el = document.getElementById('chat-records');
    const lis = function(){
      if(el) {
        const { height } = (el.firstElementChild as HTMLDivElement).getBoundingClientRect()
        if(fetchingHistory.current && el.scrollTop > height - elHeight) {
          el.removeEventListener('scrollend', lis)
          fetchingHistory.current = false;
        }
      }
    }
    
    if (el) {
      elHeight = el.getBoundingClientRect().height + 100
      // 防止多次绑定
      el.removeEventListener('scrollend', lis)
      el.addEventListener('scrollend', lis)
    }
  };

  // 获取最新消息
  const fetchMessage = async (refreshCode: string, refresh=true) => {
    setLoading(true);
    const { code, message, data } = await messageList({
      id: lastId.current,
      pageSize: pageSize,
      sender: userCode,
      subjectCode: refreshCode,
      refresh: refresh,
    });
    setLoading(false);

    if (code !== 0) {
      messager.error(message);
      return;
    }

    if (data.rows.length === 0) {
      return;
    }

    const messages = data.rows as MessageListResult[];

    if(lastId.current === 0) {
      messages.reverse();
    }

    lastId.current = (messages.at(-1) as MessageListResult).id;
    lastHistoryId.current = (messages.at(0) as MessageListResult).id;

    setTotal(data.total);
    setRows((pre) => [...pre, ...messages]);

    // 用户没有点击加载更多时，自动滚动到底
    if(!fetchingHistory.current) {
      setTimeout(()=> {
        const el = document.getElementById('chat-records');
        if (el) {
          const { height } = (el.firstElementChild as HTMLDivElement).getBoundingClientRect();
          el.scrollTo({
            top: height + 50,
            behavior: "smooth",
          });
        }
      }, 300)
    }
  }

  useEffect(()=> {
    setSubjectCode(props.subjectCode);
    fetchMessage(props.subjectCode, false);

    const timer = setInterval(() => fetchMessage(props.subjectCode), 3000);

    return () => {
      clearInterval(timer);
    };
  }, [])

  const dataSource = [
    '本系统支持留言功能，您的信息我已知晓，我会在看到信息后尽快回复您。如您情况比较着急，您也可以到当地医院医生进行咨询了解。'
  ]

  const onConfirmQuick = (value: string)=> {
    setContent(value)
  }

  return (
    <>
      <div className="feat-message--main">
        <div className="feat-message--chat customized-scrollbar" id='chat-records'>
          {rows.length > 0 ? (
            <ul>
              {rows.length < total && (
                <li style={{ padding: 0 }}>
                  <Divider plain>
                    <span onClick={fetchHistoryMessage} style={{cursor: 'pointer'}}>点击加载更多</span>
                  </Divider>
                </li>
              )}
              {rows.map((t, i) => (
                <li key={i} className={t.direction === 0 ? "mine" : ""}>
                  <div className="feat-message--avatar">
                    <UserOutlined style={{ fontSize: 20 }} />
                  </div>
                  <div
                    data-content={`${t.createTime.split('T').join(' ')} ${t.direction === 1 ? t.subjectCode : ''}`}
                    className="feat-message--content"
                  >
                    <p dangerouslySetInnerHTML={{__html: t.content.replaceAll("\n", '<br/>')}}></p>
                    {t.url.length > 0 && (
                      <Thumb
                        closable={false}
                        onPreview={previewControl}
                        urls={t.url.split(",")}
                      />
                    )}
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <Empty
              description="暂无聊天记录"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </div>
        <div className="feat-message--message">
          <div className="feat-message--file">
            <AliUpload disabled={urls.length > 0} onUpload={uploadSuccess} />
          </div>
          <div className="feat-message--typing">
            <div className="feat-message--input">
              <Input
                onChange={contentTyping}
                value={content}
                placeholder="请输入留言内容"
                maxLength={500}
                onPressEnter={sendMessage}
                allowClear
              />
            </div>
            <Thumb
              onRemove={removeControl}
              onPreview={previewControl}
              urls={urls}
            />
          </div>
          <div className="feat-message--shortcut">
            <Quick onConfirm={onConfirmQuick}/>
          </div>
          <div className="feat-message--send">
            <Button disabled={content.length === 0 && urls.length === 0} onClick={sendMessage} type="primary">
              发送
              <ArrowRightOutlined />
            </Button>
          </div>
        </div>
        <Spin spinning={loading} delay={200} className="feat-message--spining" />
      </div>
      {/* 预览图片 */}
      <Preview
        close={() => setPreview(false)}
        visible={preview}
        url={previewUrl}
      />
    </>
  );
};

export default Chat;
