/**
 * 平台管理服务接口
 * meixin
 */
import {requestPost, requestGet} from '../EdcService';

const urlPrefix = "/plat";

/**
 * 登陆
 * @param {} params
 */
export const DoLogin = (params) => {
    const url = urlPrefix + "/user/login";
    return requestPost(url, params)
};

/**
 * 退出
 * @param {} params
 */
export const DoLogout = (params) => {
    const url = urlPrefix + "/user/logout";
    return requestPost(url, params)
};

/**
 * 发送验证码
 * @param {*} params 
 * @returns 
 */
export const sendCode = (params) => {
    const url = urlPrefix + "/user/sms/sendCode1";
    return requestPost(url, params)
};
/**
 * 校验验证码
 * @param {*} params 
 * @returns 
 */
export const checkCode = (params) => {
    const url = urlPrefix + "/user/sms/check/generate_refresh_pass_code";
    return requestPost(url, params)
};

