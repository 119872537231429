import tool from './tool';
import { Toast } from 'antd-mobile';
export const eval2 = (fn: any) => {
    let Fun = Function // 一个变量指向Function，防止有些前端编译工具报错
    return new Fun('return ' + fn)()
}
export default {
    //限制输入格式
    checkValue(val: any, field: any) {
        let fieldType = field.type;
        let inputType = field.inputType;
        let validationRules = field.validationRules;
        //表单类型为number类型
        if (fieldType === 'number') {
            if (validationRules && validationRules.length > 0) {
                for (let validationItem of validationRules) {
                    if (validationItem.type === 'range') {
                        if (validationItem.condition === 'maxLength') {
                            val = val.slice(0, validationItem.rule);
                        }
                    }
                }
            }
            if (val.substr(0, 1) === '0') {
                val = val.substr(1, val.length);
            }
        }
        //表单类型为input-select
        else if (fieldType === 'input-select') {
            if (inputType === 'decimal') {
                // 小数点前的位数
                let maxLength;
                //小数点后的位数
                let maxPrecision;
                if (validationRules && validationRules.length > 0) {
                    for (let validationItem of validationRules) {
                        if (validationItem.type === 'range') {
                            if (validationItem.condition === 'maxLength') {
                                maxLength = validationItem.rule;
                            }
                            else if (validationItem.condition === 'maxPrecision') {
                                maxPrecision = validationItem.rule;
                            }
                        }
                    }
                    val = tool.setFloat(val, maxLength, maxPrecision);
                }
            }
        }
        //表单类型为decimal
        else if (fieldType === 'decimal') {
            // 小数点前的位数
            let maxLength;
            //小数点后的位数
            let maxPrecision;
            if (validationRules && validationRules.length > 0) {
                for (let validationItem of validationRules) {
                    if (validationItem.type === 'range') {
                        if (validationItem.condition === 'maxLength') {
                            maxLength = validationItem.rule;
                        }
                        else if (validationItem.condition === 'maxPrecision') {
                            maxPrecision = validationItem.rule;
                        }
                    }
                }
                val = tool.setFloat(val, maxLength, maxPrecision);
            }
        }
        return val;
    },
    //通过正则表达式提示输入错误信息
    checkValueByRep(val: any, field: any) {
        let fieldType = field.type;
        let inputType = field.inputType;
        let validationRules = field.validationRules;
        //表单类型为number类型
        let judge;
        if (fieldType === 'input') {
            if (validationRules && validationRules.length > 0) {
                for (let validationItem of validationRules) {
                    if (validationItem.type === 'format') {
                        if (validationItem.condition === 'regular') {
                            let regExp = new RegExp(validationItem.rule);
                            if (regExp.test(val)) {
                                judge = true;
                            }
                            else {
                                judge = false
                            }
                        }
                        else {
                            judge = true;
                        }
                    } else {
                        judge = true;
                    }
                }
            }
            else {
                judge = true;
            }
        } else {
            judge = true
        }
        return judge;
    },
    //限制输入区间 提示输入错误信息
    checkValueByRange(val: any, field: any) {
        let fieldType = field.type;
        let validationRules = field.validationRules;
        //表单类型为number类型
        let judge = true;
        if (fieldType === 'number' || fieldType === 'decimal') {
            if (validationRules && validationRules.length > 0) {
                for (let validationItem of validationRules) {
                    if (validationItem.type === 'range') {
                        if (validationItem.condition === 'lte' && !(val <= Number(validationItem.rule))) {
                            Toast.info(`${field.label}需要小于等于${validationItem.rule}`, 1)
                            judge = false
                        } else if (validationItem.condition === 'lt' && !(val < Number(validationItem.rule))) {
                            Toast.info(`${field.label}需要小于${validationItem.rule}`, 1)
                            judge = false
                        } else if (validationItem.condition === 'gte' && !(val >= Number(validationItem.rule))) {
                            Toast.info(`${field.label}需要大于等于${validationItem.rule}`, 1)
                            judge = false
                        } else if (validationItem.condition === 'gt' && !(val > Number(validationItem.rule))) {
                            Toast.info(`${field.label}需要大于${validationItem.rule}`, 1)
                            judge = false
                        }
                    }
                }
            }
            else {
                judge = true;
            }
        } else {
            judge = true
        }
        return judge;
    },
    // 数据异常提醒
    checkValueAnnotation(val: any, field: any) {
        let result: any = {
            status: false,
            message: ''
        };
        if (field.type == 'input' || field.type == 'number' || field.type == 'decimal' || field.type == 'date') {
            if (field.remind) {
                let a = field.remind
                if (a.type && a.type == 'range') {
                    if (a.condition && a.rule) {
                        let aCondition = a.condition
                        let aRule = a.rule
                        if (aCondition.length > 0) {
                            aCondition.map((c: any, i: any) => {
                                if (c == 'lt' && val && val < aRule[i]) {
                                    result = {
                                        status: true,
                                        message: a.message[i]
                                    };
                                } else if (c == 'gt' && val && val > aRule[i]) {
                                    result = {
                                        status: true,
                                        message: a.message[i]
                                    };
                                }
                            })
                        }
                    }
                }
            }
        }
        return result;
    }
}