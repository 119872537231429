/**
 * crc 录入
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './index.less';
import { Modal } from 'antd';
import {
    DashboardOutlined, WarningTwoTone,
} from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import PubSub from 'pubsub-js';

const { confirm } = Modal;
let _this;
class CountDown extends Component {

    constructor(props) {
        super(props);
        _this = this;
        this.state = {
            countDown: null, // 倒计时
            isModalVisible: false
        };
        this.countDownEl = React.createRef();
        this.timer = null
    }
    formControlRef = React.createRef();

    componentDidMount() {
        // alert('componentDidMount')
        if (this.props.countDown) {
            this.handleCountDown(this.props.countDown)
            this.setState({
                countDown: this.props.countDown
            })
        }
    }


    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (!this.state.countDown) {
            this.handleCountDown(nextProps.countDown)
            this.setState({
                countDown: nextProps.countDown
            })
        }

        PubSub.subscribe("timer", (name, data) => {
            // alert('UNSAFE_componentWillReceiveProps')
            clearTimeout(_this.timer)
            this.handleCountDown(data.time)
            this.setState({
                countDown: data.time
            })
        })
    }

    componentWillUnmount() {
        // alert('componentWillUnmount')
        clearTimeout(_this.timer)
        PubSub.unsubscribe("timer");
    }

    // 倒计时 600s=10min
    handleCountDown = (seconds) => {
        let second = seconds;
        const countDown = () => {
            if (second > 0) {
                second--;
                this.setState({
                    countDown: second
                })
            }
            if (second == 0) {
                clearTimeout(_this.timer)
                _this.props.getValue(0)
                second = 0;
                this.setState({
                    countDown: null,
                    isModalVisible: true
                })
                return;
            }
            this.timer = setTimeout(countDown, 1000)
        };
        this.timer = setTimeout(countDown, 1000);
    };

    // 继续接单
    handleOk = () => {
        clearTimeout(_this.timer)
        this.setState({
            isModalVisible: false
        })
        _this.props.getValue('next')
    }

    // 取消接单
    handleCancel = () => {
        clearTimeout(_this.timer)
        this.setState({
            isModalVisible: false
        })
        _this.props.getValue('cancel')
    }

    render() {
        let { countDown, isModalVisible } = this.state;
        countDown = countDown ? Math.abs(countDown) : null
        
        return (
            <div className={'countDown-container'}>
                <div className={'countDown'}>
                    <DashboardOutlined />&nbsp;&nbsp;任务录入倒计时：{countDown} 秒
                </div>
                <Modal title={null}
                    visible={isModalVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText={'继续接单'}
                    cancelText={'取消接单'}
                    className={'countDown-modal'}
                    width={'30%'}
                >
                    <p className={'title'}><WarningTwoTone twoToneColor="#ff3300" />录入超时</p>
                    <p className={'content'}>您在规定的时间内未完成录入任务，当前任务已失败，继续接单将为您派发新的任务，是否继续接单？</p>
                </Modal>
            </div>
        )
    }
}

export default CountDown