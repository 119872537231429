import React, {Component, Fragment} from 'react';
import './codeSetCatalogDetail.less';
import {withRouter} from 'react-router-dom';
import {Form, Input, Select, Button, message} from 'antd';
import {
    GetDataCodesetDirectorySave,
    GetdataCodesetDirectoryInfo,
    GetDataCodesetDirectoryModify
} from '@/services/config/ConfigService'

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};
const {Option} = Select

class CodeSetCatalogDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            flag: 'true'
        };
    }

    formRef = React.createRef()

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.info && nextProps.operation == 'modify') {
            this.getDataThemeDirectoryInfo(nextProps.info.id)
        }
        if (nextProps.operation == 'add') {
            this.formRef.current.setFieldsValue({
                name: ''
            })
        }
    }

    componentDidMount() {
        if (this.props.operation == 'modify') {
            this.getDataThemeDirectoryInfo(this.props.info.id)
        }
        if (this.props.operation == 'add') {
            this.formRef.current.setFieldsValue({
                name: ''
            })
        }
    }

    /**
     * 目录 详情
     */
    getDataThemeDirectoryInfo = (id = '') => {
        GetdataCodesetDirectoryInfo(id).then(res => {
            if (res) {
                if (this.formRef.current) {
                    this.formRef.current.setFieldsValue({
                        name: res.name
                    })
                }
            }
        })
    }

    /**
     * 添加 修改 目录
     */
    handleFinish = (e) => {
        if (this.props.operation == 'modify') {
            let param = {
                "name": e.name.trim(),
                "id": this.props.info.id,
            }
            GetDataCodesetDirectoryModify(param).then(res => {
                if (res.code == 0) {
                    message.success('修改成功！')
                    this.props.interFace()
                }
            })
        } else if (this.props.operation == 'add') {
            let param = {
                "name": e.name.trim(),
                "parentId": this.props.info ? this.props.info.id : '',
                "codesetId": this.props.codesetId
            }
            GetDataCodesetDirectorySave(param).then(res => {
                if (res.code == 0) {
                    message.success('添加成功！')
                    this.props.interFace()
                    this.formRef.current.setFieldsValue({
                        name: ''
                    })
                }
            })
        }
    }

    render() {
        return (
            <Fragment>
                <Form
                    {...layout}
                    name="nest-messages"
                    onFinish={this.handleFinish}
                    requiredMark={true}
                    className={'codeSetCatalogForm'}
                    ref={this.formRef}
                >
                    <Form.Item
                        label="编码集目录名称"
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: '请输入编码集目录名称',
                            },
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ]}
                    >
                        <Input
                            style={{marginLeft: 8}}
                            placeholder="请输入编码集目录名称"
                        />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{span: 24}}
                        style={{textAlign: 'right', marginRight: '-8px'}}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{backgroundColor: '#6287ec', borderColor: '#6287ec'}}
                        >
                            {this.props.operation == 'modify' ? '修 改' : '保 存'}
                        </Button>
                    </Form.Item>

                </Form>
            </Fragment>
        )
    }
}

export default withRouter(CodeSetCatalogDetail);