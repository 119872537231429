/**
 * 项目报表详情
 */
import React, { useState, useEffect } from 'react';
import './index.less';
import { message, Table } from 'antd';

function Detail(props) {

  // 明细列表
  const [detailData, setDetailData] = useState([]);
  //  page
  const [page, setPage] = useState(1);
  //  pageSize
  const [pageSize, setPageSize] = useState(20);
  //  totalNum
  const [totalNum, setTotalNum] = useState(100);
  // tableLoading
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    let userDetailData = JSON.parse(sessionStorage.getItem('userDetailData'))
    if (props.data) {
      setTableLoading(false)
      setDetailData(props.data.rows || userDetailData.rows)
      setTotalNum(props.data.total || userDetailData.total)
      setPage(props.data.page || userDetailData.page);
      setPageSize(props.data.pageSize || userDetailData.pageSize);
    }
  })

  const columns = [
    {
      title: '姓名',
      dataIndex: 'userName',
      key: 'userName',
      fixed: 'left'
    },
    {
      title: '账号',
      dataIndex: 'userCode',
      key: 'userCode'
    },
    {
      title: '图片审核',
      children: [
        {
          title: '总完成数',
          dataIndex: 'imageTotal',
          key: 'imageTotal',
        },
        {
          title: '本期完成数',
          dataIndex: 'imageCurrent',
          key: 'imageCurrent',
        }
      ],
    },
    {
      title: '录入任务',
      children: [
        {
          title: '总完成数',
          dataIndex: 'inputTotal',
          key: 'inputTotal',
        },
        {
          title: '本期完成数',
          dataIndex: 'inputCurrent',
          key: 'inputCurrent',
        }
      ],
    }
  ];

  //当分页change
  const onPageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    props.onPageChange(page, pageSize)
  }

  return (
    <div className='details_detail'>
      <div className='titleStyle'>人员明细</div>
      <Table
        locale={{ emptyText: "暂无数据" }}
        columns={columns}
        dataSource={detailData}
        pagination={detailData.length > 0 && {
          pageSize: pageSize,
          total: totalNum,
          onChange: onPageChange,
          showTotal: total => `共 ${totalNum} 条数据`,
          showSizeChanger: false
        }}
        rowKey={'id'}
        bordered
        size="small"
        loading={tableLoading}
      />
    </div>
  )
}
export default Detail;
