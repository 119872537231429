import React, { useState, useEffect, useRef, forwardRef } from 'react';
import styles from './visitForm.module.scss';
import FormField from '../formComponent/formField/FormField';
import { PhotoSlider } from 'react-photo-view';
import { getLgForm } from '../../../../services/project/ProjectService'



function VisitForm(props: any, ref: any) {

    //设置fieldList
    const [fieldList, setFieldList] = useState<any>();

    //设置当前选择的tab id
    const [selectTabId, setSelectTabId] = useState<string>('');

    //当前表单的状态
    const [formState, setFormState] = useState<any>();

    //表单组件
    const formRef = useRef<any>(null);

    //OCR图片数组
    const [ocrFileList, setOcrFileList] = useState<any>([]);

    //选中的ocr图片index
    const [selectOcrIndex, setSelectOcrIndex] = useState<any>();

    //图片预览是否可见
    const [photoVisible, setPhotoVisible] = useState<boolean>(false);

    // formCode OCR识别需要用到
    const [formCode, setFormCode] = useState<any>();

    // version OCR识别需要用到
    const [version, setVersion] = useState<any>();



    useEffect(() => {
        let sessionStorage_forms = sessionStorage.getItem('sessionStorage_forms');
        if (sessionStorage_forms) {
            let forms = JSON.parse(sessionStorage_forms);
            let activeId = forms.activeId;
            let state = forms.state;
            setSelectTabId(activeId);
            setFormState(state);
        }

    }, [])


    //当选择的tab发生变化时
    useEffect(() => {
        updateFormDataById();
    }, [selectTabId])

    //根据formId更新数据
    const updateFormDataById = () => {
        if (selectTabId) {
            let params: any = {
                id: selectTabId,
                dataTenantCode: props.subjectsData.tenantCode
            }

            let tokenData = sessionStorage.getItem('token')
            if (!tokenData) {
                params.sourceType = 'lg'
            }
            getLgForm(params).then(res => {
                if (res.code == '0' && res.data) {
                    setFormCode(res.data.formCode);
                    setVersion(res.data.version);
                    setFieldList(Object.assign(res.data));
                    setOcrFileList(res.data.ocrFiles);
                }
            })
        }
    }


    return (
        <>
            <div className={styles.visitForm_content}>
                <div className={styles.form_content}>
                    {
                        fieldList && fieldList.body && <FormField
                            formId={selectTabId}
                            formCode={formCode}
                            version={version}
                            fieldList={fieldList}
                            ref={formRef}
                            updateData={updateFormDataById}
                            readonly={true}
                        ></FormField>
                    }
                </div>

                {/* 图片预览 */}
                <PhotoSlider
                    photoClosable
                    bannerVisible={true}
                    images={ocrFileList && ocrFileList.map((item: any) => ({ src: item }))}
                    visible={photoVisible}
                    onClose={() => { setPhotoVisible(false) }}
                    index={selectOcrIndex}
                    introVisible={true}
                    onIndexChange={(i: any) => { setSelectOcrIndex(i) }}
                />

            </div>
        </>
    )
}

export default forwardRef(VisitForm);