import modStyl from './subjectBind.module.scss'
import React, { useState, useEffect, useMemo } from "react";
import { Button, Popconfirm, Tooltip} from "antd";
import { ExclamationCircleOutlined, InfoCircleOutlined, InfoOutlined, LinkOutlined, UnlockOutlined} from "@ant-design/icons";
import { QRCodeCanvas } from "qrcode.react";
import storageX, { Engine } from '../../../../../../util/storageX';
import { getSubjectBinding, getSubjectBindState, subjectUnbind, getUnbindRecord } from '../../../../../../services/data/patientSelfReported';


// #region 绑定按钮
type BindRecordProps = {
  subjectCode: string
  targetName: string
}

const BindRecord: React.FC<BindRecordProps> = ({ subjectCode, targetName })=> {
  const [label, setLabel] = useState('')
  const [title, setTitle] = useState('')

  const fetchBindInfo = async (subjectCode: string) => {
    type Before = {
      code: string
      isDr: boolean
    }
    type DataType = {
      state: 0 | 1
      unbound: null | Before
      bound: null | Before
    }
    // 未绑且不是君实项目时
    const { code, data } : { code: number, data: DataType} = await getUnbindRecord(subjectCode)
    if (code === 0) {
      const { state, bound, unbound } = data
      // 已绑定时显示原绑定记录
      if (state === 1) {
        // bound
        if (bound) {
          if (bound.code) {
            setTitle(`原绑定${bound.code}`)
          }
          setLabel(bound.isDr ? '医生解绑' : `${targetName}解绑`)
        }
      } else {
        // 未绑时显示新绑定记录
        if (unbound) {
          if (unbound.code) {
            setTitle(`绑定至${unbound.code}`)
          }
          setLabel(unbound.isDr ? '医生解绑' : `${targetName}解绑`)
        }
      }
    }
  }

  useEffect(()=> {
    fetchBindInfo(subjectCode);
  }, [subjectCode])

  if (label === '') {
    return null
  }

  return (
    <span className={modStyl.record}>
      <em>{label}</em>
      {
        title.length > 0 && (
          <Tooltip title={title}>
            <InfoOutlined/>
          </Tooltip>
        )
      }
    </span>
  )
}
//#endregion

//#region 知情显示
type SignStateProps = {
  subjectName: string;
  signState: 0 | 1 | 2 | 3;
}
const SignState:React.FC<SignStateProps> = ({signState, subjectName})=> {
  return (
    <div className={modStyl.state}>
      {
        subjectName.length > 0 && (
          <span>{subjectName}</span>
        )
      }
      <span className={modStyl.label}>（已知情）</span>
    </div>
  )
}
//#endregion

//#region 解绑按钮
type UnbindButtonProps = {
  onClick: ()=> void;
}
const UnbindButton: React.FC<UnbindButtonProps> = ({onClick})=> {
  return (
    <Popconfirm
      onConfirm={onClick}
      okText="确定"
      cancelText="取消"
      icon={<ExclamationCircleOutlined />}
      title="您正在解绑患者，解绑之后需要重新绑定患者！">
      <Button size="small" type="ghost" danger icon={<UnlockOutlined/>}>解绑</Button>
    </Popconfirm>
  )
}
//#endregion

// informed: number | null 君实知情签署类型 1线上 2线下
type Props = {
  editModel: string | number;
  informed: null | number;
  subjectCode: string;
  resumeVisitId: string;
  state: string;
  subjectName: string;
  signState: 0 | 1 | 2 | 3;
  refresh: (resumeVisitId: string)=> void;
}
type Info = {
  bindState: number;
  reportUser: string;
}

const SubjectBind: React.FC<Props> = ({editModel, informed, signState, subjectCode, resumeVisitId, refresh, state, subjectName}) => {
  const targetName = sessionStorage.getItem('targetName') || '受试者';
  const [info, setInfo] = useState({} as Info);
  const [codeURL, setCodeURL] = useState(null);
  const currentRole = sessionStorage.getItem('roleCode');
  const projectRoleCode = sessionStorage.getItem('projectRoleCode');
  const fetchBindInfo = async (subjectCode: string) => {
    const { data, code } = await getSubjectBindState(subjectCode);
    
    if(code === 0) {
      setInfo(data ?? {});
    }

    if (data?.bindState === 0 && [null, 2].includes(informed)) {
      const doctorName = storageX(Engine.Session).get('currentuserName')
      const result = await getSubjectBinding({ subjectCode, doctorName });
      setCodeURL(result?.data?.scanCodeUrl)
    }
  }

  const visible = useMemo(()=> {
    if(info.bindState === 0 && 1 === informed) {
      return false
    }
    return ['Active', 'Finish', 'Lose', 'Stop', 'Pause', 'Init'].includes(state) && subjectCode 
  }, [subjectCode, state, info.bindState])

  useEffect(()=> {
    if (subjectCode) {
      fetchBindInfo(subjectCode)
    }
  }, [subjectCode]);
  

  // 解绑
  const handleUnbind = async () => {
    const { code } = await subjectUnbind(subjectCode);
    if (code === 0) {
      await fetchBindInfo(subjectCode)
      refresh(resumeVisitId)
    }
  }

  return (
    <>
      {/* 君实操作权限控制 */}
      {  editModel === 1 && currentRole == 'EDC_CRC' && projectRoleCode !== 'ReadOnly' && visible && (
          <div className={modStyl.container}>
            <div className={modStyl.header}>
              <div className={modStyl.west}>
                <LinkOutlined/>
                {targetName}绑定
              </div>
              <div className={modStyl.east}>
                {
                  info.bindState === 1 && (
                    <>
                      <SignState subjectName={subjectName} signState={signState}/>
                      <UnbindButton onClick={handleUnbind}/>
                 
                    </>
                  )
                }
              </div>
            </div>
            <div className={modStyl.content}>
             
              { 
                 info.bindState === 0 && [null, 2].includes(informed) &&  (
                  <div className={modStyl.qr}>
                    {!!codeURL && <QRCodeCanvas value={codeURL} size={128} />}
                    <p>{targetName}使用微信扫一扫进行绑定</p>
                  </div>
                )
              }
            </div>
          </div>
        )
      }

      {editModel !== 1  &&  visible  && (
          <div className={modStyl.container}>
            <div className={modStyl.header}>
              <div className={modStyl.west}>
                <LinkOutlined/>
                <span>{targetName}绑定</span>
                <BindRecord targetName={targetName} subjectCode={subjectCode}/>
              </div>
              <div className={modStyl.east}>
                {
                  info.bindState === 1 && (
                    <>
                      <SignState subjectName={subjectName} signState={signState}/>
                      <UnbindButton onClick={handleUnbind}/>
                 
                    </>
                  )
                }
              </div>
            </div>
            <div className={modStyl.content}>
              { 
                 info.bindState === 0 && [null, 2].includes(informed) &&  (
                  <div className={modStyl.qr}>
                    {!!codeURL && <QRCodeCanvas value={codeURL} size={128} />}
                    <p>{targetName}使用微信扫一扫进行绑定</p>
                  </div>
                )
              }
              
            </div>
          </div>
        )
      }
    </>
  )
};

export default SubjectBind;
