import { Editor, Toolbar } from "@wangeditor/editor-for-react";
import React from "react";
const toolbarConfig = {};
const editorConfig = {
  placeholder: "请输入内容...",
};
class WangEditor extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    editor: null,
  };
  componentWillUnmount = () => {
    if (this.state.editor == null) return;
    this.state.editor.destroy();
  };
  render() {
    return (
      <div
        style={{
          border: "1px solid #ccc",
          zIndex: 100,
          marginTop: "15px",
        }}
      >
        <Toolbar
          editor={this.state.editor}
          defaultConfig={toolbarConfig}
          mode="default"
          style={{
            borderBottom: "1px solid #ccc",
          }}
        />

       
        <Editor
          value={this.props.defaultValue}
          defaultConfig={editorConfig}
          onCreated={(editor) => {
            this.setState({
              editor: Object.seal(editor),
            });
          }}
          onChange={(editor) => {
            this.props.changeHtml(editor);
          }}
          mode="default"
          style={{ height: "500px", overflowY: "hidden" }}
        />
      </div>
    );
  }
}

export default WangEditor;
