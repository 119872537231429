export enum FetchMethod {
  GET = 'GET',
  POST = 'POST'
}

export type DataAction = 'DataActionUpdate' | 'DataActionAnnotation' | 'DataActionRemark' | 'DataActionAnswer'

export type GlobalValueItem = {
  dataCode: string;
  deCode: string;
  value: string;
  visitName: string;
}

export type ValueItem = {
  dataCode: string;
  deCode: string;
  inputState?: string; // Runing
  projectCode?: string;
  question?: boolean;
  subjectCode?: string; // GYVR100005
  value: string;
}

export type StackData = {
  [key: string]: unknown;
}

export type Properties = {
  // foo/bar?keyword=${keyword}&name=${name}&id=1900
  server: string;
  method: FetchMethod,
  delay: number;
  labelName: string;
  labelCombine: string | string[];
  query: {
    [key: string]: string | number | boolean;
  },
  payload: {
    [key: string]: string | number | boolean;
  },
  // 需要自动填充的字段
  autofields: (string | [string, string])[]
}

export type AutoCompoleteProps = {
  formId: number;
  formCenterCode: string;
  formIsRead: boolean;
  isUpdate?: boolean;
  savedValues: ValueItem[];
  globalValues: GlobalValueItem[];
  dataActions: DataAction[];
  data: {
    col: string;
    isAdd?: boolean;
    dataCode: string;
    deCode: string;
    label: string;
    type: string;
    sort?: any;
    properties: Properties;
    validationRules: any[];
    logicOperations: any;
    remind: any;
    reminds: any[];
  };
  updateFun: ()=> void;
  modifyUpdateFun: ()=> void;
  startTimerFun: ()=> void;
  triggerDrawAreaFunc: any;
  cancelDrawAreaFunc: any;
  deleteDrawAreaFunc: any;
  ocrLists: any[];
  getOcrIcon: (value: any)=> void;
}

// 数值
type NString = string;
// 日期
type DString = string;

//
// export type SearchItem = {
//   // 数值字符串
//   area: NString;
//   createBy: string;
//   createTime: DString;
//   department: NString;
//   id: number;
//   institution: string;
//   nurse: string;
//   proTitle: NString;
//   projectCode: string;
//   province: NString;
//   status: number;
//   updateBy: string;
//   updateTime: DString;
//   userCode: string;
// }

export type SearchResult = {
  code: number;
  message: string;
  success: boolean;
  data: any[]
}

// 解析模板变量 例：/path/to?id=${id}&keyword=${keyword}&title=${title}
const parseTemplateLiterals = (content: string, data: StackData = {}, projectData: StackData = {}) => {
  const re = new RegExp(/(\$\{(\w+)\})/, 'g');

  let defaults = {}
  let match: RegExpExecArray | null;
  while((match = re.exec(content)) !== null) {
    defaults[match[2]] = '';
  }

  const keys = Object.keys({ ...defaults, ...data })
  const values = Object.values({ ...defaults, ...data })
  
  // eslint-disable-next-line no-new-func, prefer-template
  return new Function(...keys, 'project', `
    try {
      return \`${content}\`;
    } catch(e) {
      console.log('template.literals', e)
      // throw new Error('parsed template fail.');
    }
  `)(...values, projectData)
}

// 解析 queryParams,payload 参数值
export const parseParamsValue = (value: string, data: StackData, projectData: StackData={}): string => {
  // ! 表示普通字符串不需要解析
  if(value.startsWith('!')) {
    return value.substring(1)
  }

  let [nameValue, defaultValue] = value.split('|')

  // 从values中查询
  if(nameValue.startsWith('@')) {
    return (projectData[nameValue.substring(1)] as string) ?? (defaultValue ?? '');
  }

  // 从values中查询
  return (data[nameValue] as string) ?? (defaultValue ?? '');
}

// 合并URL参数
export const mergeServerUrl = (props: Properties, keyword: string, data: StackData, projectData: StackData)=> {
  const server = parseTemplateLiterals(props.server, {keyword,  ...data}, projectData)

  // 没有配置queryParams直接跳过
  if(Object.keys(props.query).length === 0) {
    return server
  }

  // 解析地址中的参数
  const canParse = URL.canParse(server)
  const url = new URL(server, canParse ? undefined : window.location.origin)

  // 将参数合并
  for(const name in props.query) {
    let value = props.query[name]
    if(typeof value === 'string') {
      value = parseParamsValue(value, {keyword, ...data}, projectData)
    }
    url.searchParams.set(name, String(value))
  }

  return canParse ? url.toString() : `${url.pathname}${url.search}`
}

// 组合展示的标签
export const parseLabelCombine = (properties: Properties, data: StackData, row: StackData, projectData: StackData)=> {
  const combine = properties.labelCombine

  if(typeof combine === 'string') {
    return parseTemplateLiterals(combine, {...data, ...row}, projectData)
  }

  if(combine.length === 0) {
    combine.push(properties.labelName)
  }

  return combine.map((item)=> parseParamsValue(item, {...data, ...row}, projectData)).join('')
}
