import React, { useRef, useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { Row, Col, Space, Form, Select, Input, Button, Table, Modal } from "antd";
import { getAreaQuery, getAreaSubjects } from "@/services/area/AreaService";
import { GetSubjectDetail, GetFormsById } from "@/services/data/DataService";
import CrfForm from "@/components/crf_form/Index";
import "./AreaManager.less";
const { Option } = Select;


const AreaManager = (props) => {
  const [projectList, setProjectList] = useState([]);
  const [oneAreaList, setOneAreaList] = useState([]);
  const [twoAreaList, setTwoAreaList] = useState([]);
  const [twoAreaFilterList, setTwoAreaFilterList] = useState([]);
  const [threeAreaList, setThreeAreaList] = useState([]);
  const [threeAreaFilterList, setThrerAreaFilterList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [settlementStateList, setSettlementStateList] = useState([]);
  const [projectState, setProjectState] = useState(null);
  const [settlementState, setSettlementState] = useState(null);
  const [columns, setColumns] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
    showSizeChanger: false,
  });
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [tenantCode, setTenantCode] = useState(null);
  const [repeatSubjectCodeList, setRepeatSubjectCodeList] = useState([]);
  const [crfParams, setCrfParams] = useState(null);
  const [form] = Form.useForm();
  const targetName = sessionStorage.getItem("targetName") || "受试者";

  useEffect(() => {
    initQuery();
  }, []);

  const initQuery = async () => {
    const { data, code } = await getAreaQuery();
    if (code === 0) {
      unstable_batchedUpdates(() => {
        const projectList = data ?? [];
        const firstProject = projectList?.[0];
        const oneAreaList = (firstProject?.purviewList ?? []).filter((item) => item.level === 1);
        const twoAreaList = (firstProject?.purviewList ?? []).filter((item) => item.level === 2);
        const threeAreaList = (firstProject?.purviewList ?? []).filter((item) => item.level === 3);
        const stateList = firstProject?.stateList ?? [];
        const settlementStateList = firstProject?.settlementStateList ?? [];
        if (oneAreaList?.length > 1) {
          oneAreaList.unshift({
            id: 'all',
            value: 'all',
            name: '所有'
          })
        }
        const firstOneArea = oneAreaList?.[0];
        form.setFieldsValue({ projectCode: firstProject?.projectCode });
        form.setFieldsValue({ oneArea: firstOneArea?.id });
        setTenantCode(firstProject?.tenantCode);
        setStateList(stateList);
        setSettlementStateList(settlementStateList);
        setProjectList(projectList);
        setOneAreaList(oneAreaList);
        setTwoAreaList(twoAreaList);
        setThreeAreaList(threeAreaList);
        initColumns(twoAreaList?.length, threeAreaList?.length);
        handleOneAreaChange(firstOneArea?.id, twoAreaList, threeAreaList);

        const twoAreaFilterList = getCurLvAreaList(firstOneArea?.id, twoAreaList)
        const firstTwoArea = twoAreaFilterList[0];
        const threeAreaFilterList = getCurLvAreaList(firstTwoArea?.id, threeAreaList)
        const firstThreeArea = threeAreaFilterList?.[0];
        const formValue = {
          ...form.getFieldsValue(),
          twoArea: firstTwoArea?.id,
          threeArea: firstThreeArea?.id,
        };
        const params = getFnQuery(oneAreaList, twoAreaList, threeAreaList, formValue);
        if (!params) return;
        handleGetAreaSubjects(params);
      });
    }
  };

  // 导出函数需要的 query
  const getFnQuery = (oneAreaList, twoAreaList, threeAreaList, formValue, page = 1) => {
    if (formValue?.projectCode && formValue?.oneArea) {
      const { oneArea, twoArea, threeArea, projectCode, subjectCode, account } = formValue;
      const params = {
        page,
        pageSize: pageInfo.pageSize,
        oneArea: oneArea === 'all' ? null : oneArea,
        twoArea: twoArea === 'all' ? null : twoArea,
        threeArea: threeArea === 'all' ? null : threeArea,
        subjectCode: !subjectCode ? null : subjectCode,
        account: !account ? null : account,
        projectCode,
        state: projectState,
        settlementState: settlementState,
      };
      return params;
    }
    return null;
  };

  const handleGetAreaSubjects = async (params) => {
    const { data, code } = await getAreaSubjects(params);
    if (code === 0) {
      const { rows, page, pageSize, total } = data;
      setPageInfo({ ...pageInfo, current: page, pageSize, total });
      setDataList(rows ?? []);
    }
  };

  const openPdf = (row) => {
    if (row?.signUrl) {
      window.open(row.signUrl, "__blank");
    }
  };

  const getStateClass = (row) => {
    const { singleAuditState, auditState, subjectState } = row;
    if (singleAuditState === 0) {
      return "success";
    }
    if (singleAuditState === 1 || singleAuditState === 2) {
      return "primary";
    }
    if (singleAuditState === 3) {
      return "warning";
    }
    if (auditState === 1) {
      return "success";
    }
    if (auditState === 2) {
      return "primary";
    }
    if (auditState === 3) {
      return "warning";
    }

    if ([1, 5, 6].includes(subjectState)) {
      return "success";
    }
    if ([3, 4, 7].includes(subjectState)) {
      return "secondary";
    }
    if (subjectState === 2) {
      return "primary";
    }
  };

  const initColumns = (twoLv, threeLv) => {
    let columns = [
      {
        title: `${targetName}编码`,
        align: "center",
        dataIndex: "subjectCode",
        key: "subjectCode",
        ellipsis: true,
        width: "100px",
      },
      {
        title: "创建时间",
        align: "center",
        dataIndex: "createTime",
        key: "createTime",
        ellipsis: true,
        width: "150px",
      },
      {
        title: `${targetName}状态`,
        align: "center",
        ellipsis: true,
        width: "120px",
        render: (text, record) => (
          <span className={getStateClass(record)}>{record.subjectStateStr}</span>
        ),
      },
      {
        title: "访视进度",
        align: "center",
        width: "200px",
        render: (text, record) => (
          <div className="area-manager--progress">
            {(record?.progress ?? []).map((p) => (
              <div className="progress-item" data-state={p}></div>
            ))}
          </div>
        ),
      },
      {
        title: "结算状态",
        align: "center",
        dataIndex: "settlementStateStr",
        key: "settlementStateStr",
        ellipsis: true,
        width: "100px",
      },
      {
        title: "提交人",
        align: "center",
        dataIndex: "createUser",
        key: "createUser",
        ellipsis: true,
        width: "120px",
      },
      {
        title: "中心",
        align: "center",
        dataIndex: "center",
        key: "center",
        ellipsis: true,
        width: "150px",
      },
      {
        title: "所属分区",
        align: "center",
        dataIndex: "oneArea",
        key: "oneArea",
        ellipsis: true,
        width: "100px",
      },
    ];
    if (twoLv) {
      columns.push({
        title: "所属分区",
        align: "center",
        dataIndex: "twoArea",
        key: "twoArea",
        ellipsis: true,
        width: "100px",
      });
    }
    if (threeLv) {
      columns.push({
        title: "所属分区",
        align: "center",
        dataIndex: "threeArea",
        key: "threeArea",
        ellipsis: true,
        width: "100px",
      });
    }
    columns.push({
      title: "项目",
      align: "center",
      dataIndex: "projectName",
      key: "projectName",
      ellipsis: true,
      width: "150px",
    });
    columns.push({
      title: "操作",
      align: "center",
      width: "100px",
      render: (text, record) => (
        <>
          <Button type="link" onClick={() => toDetail(record)}>
            查看
          </Button>
          <Button type="link" disabled={!record?.signUrl} onClick={() => openPdf(record)}>
            查看PDF
          </Button>
        </>
      ),
    });
    setColumns(columns);
  };

  const getCurLvAreaList = (parentId, curList = []) => {
    const allItem = {
      id: 'all',
      value: 'all',
      name: '所有'
    }
    if (parentId === 'all') {
      return [allItem]
    }
    let filteList = curList.filter(c => c.parentId === parentId)
    if (filteList?.length > 1) {
      filteList.unshift(allItem)
    }
    return filteList
  }

  const handleProjectChange = (projectCode) => {
    const currentProject = projectList.find((p) => p.projectCode === projectCode);
    const stateList = currentProject?.stateList ?? [];
    const settlementStateList = currentProject?.settlementStateList ?? [];
    form.resetFields(["projectState"]);
    form.resetFields(["settlementState"]);
    setTenantCode(currentProject?.tenantCode);
    setStateList(stateList);
    setSettlementStateList(settlementStateList);
    const oneAreaList = (currentProject?.purviewList ?? []).filter((item) => item.level === 1);
    const twoAreaList = (currentProject?.purviewList ?? []).filter((item) => item.level === 2);
    const threeAreaList = (currentProject?.purviewList ?? []).filter((item) => item.level === 3);
    if (oneAreaList?.length > 1) {
      oneAreaList.unshift({
        id: 'all',
        value: 'all',
        name: '所有'
      })
    }
    setOneAreaList(oneAreaList);
    setTwoAreaList(twoAreaList);
    setThreeAreaList(threeAreaList);
    const firstOneArea = oneAreaList?.[0];
    form.setFieldsValue({ oneArea: firstOneArea?.id });
    handleOneAreaChange(firstOneArea?.id, twoAreaList, threeAreaList);
    setProjectState(null);
    setSettlementState(null);
  };

  const handleOneAreaChange = (oneAreaId, twoAreaList, threeAreaList) => {
    const twoAreaFilterList = getCurLvAreaList(oneAreaId, twoAreaList)
    const firstTwoArea = twoAreaFilterList?.[0];
    setTwoAreaFilterList(twoAreaFilterList);
    form.setFieldsValue({ twoArea: firstTwoArea?.id });
    handleTwoAreChange(firstTwoArea?.id, threeAreaList);
  };

  const handleTwoAreChange = (twoAreaId, threeAreaList) => {
    const threeAreaFilterList = getCurLvAreaList(twoAreaId, threeAreaList)
    const firstThreeArea = threeAreaFilterList?.[0];
    setThrerAreaFilterList(threeAreaFilterList);
    form.setFieldsValue({ threeArea: firstThreeArea?.id });
  };

  const handleChange = (value, option) => {
    setProjectState(option?.state);
  };

  const settlementStateChange = (value, option) => {
    setSettlementState(option?.state);
  }

  const handleQuery = () => {
    const params = getFnQuery(oneAreaList, twoAreaList, threeAreaList, form.getFieldsValue());
    handleGetAreaSubjects(params);
  };

  const handlePageChange = (page) => {
    const params = getFnQuery(oneAreaList, twoAreaList, threeAreaList, form.getFieldsValue(), page);
    handleGetAreaSubjects(params);
  };

  const toGetFormsById = (id) => {
    return new Promise((resolve) => {
      GetFormsById(id).then((res) => {
        if (res.code == 0) {
          return resolve(res.data || {});
        }
      });
    });
  };

  const toDetail = async (row) => {
    const { code, data } = await GetSubjectDetail(row.subjectCode);
    if (code == 0) {
      setRepeatSubjectCodeList(data?.repeatSubjectCodeList ?? null);
      if (data?.visits?.length) {
        let id = data.visits?.[0]?.id;
        let spreadList = [];
        data.visits.forEach((item) => {
          spreadList.push(toGetFormsById(item.id));
        });
        Promise.all(spreadList).then((res) => {
          let dataForms = [];
          res.forEach((v) => {
            dataForms = dataForms.concat(v.forms);
          });
          let filterArr = (dataForms || []).map((item) => {
            return {
              title: item.name,
              id: item.id,
              formCode: item.code,
            };
          });
          let forms = {
            activeId: filterArr[0] && filterArr[0].id,
            forms: filterArr,
            state: dataForms[0] && dataForms[0].inputState,
          };
          localStorage.setItem("localStorage_forms", JSON.stringify(forms));

          setFormModalVisible(true);

          // crf
          if (dataForms[0] && dataForms[0].type == "CRF") {
            let d = dataForms[0];
            let photoEditItem = sessionStorage.getItem("photoEditItem");
            let crfParams = {
              caseCode: row.subjectCode,
              crfCode: d.code,
              crfVersion: d.version,
              eventCode: id,
              headers: {},
              projectCode: row.projectCode,
              recordCode: d.id,
              tenantCode: row.tenantCode,
              userCode: row.userCode,
            };
            setCrfParams(crfParams);
          } else {
            setCrfParams(null);
          }
        });
      }
    }
  };

  const closeFormModal = () => {
    setFormModalVisible(false);
  };

  const getOneAreaDisabled = () => {
    return (oneAreaList ?? []).some((t) => t.isDisabled);
  };

  const getTwoAreaDisabled = () => {
    return (twoAreaFilterList ?? []).some((t) => t.isDisabled);
  };

  return (
    <div className="area-manager--container">
      <Form labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} form={form}>
        <Row gutter={[8, 8]}>
          <Col span={20}>
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
              <Row gutter={[8, 16]}>
                <Col span={6}>
                  <Form.Item label="选择项目" name="projectCode">
                    <Select
                      placeholder="选择项目"
                      optionLabelProp="label"
                      onChange={handleProjectChange}
                    >
                      {projectList.map((p) => {
                        return (
                          <Option
                            value={p.projectCode}
                            label={p.projectName}
                            key={p.id}
                            disabled={p.isDisabled}
                          >
                            <span>{p.projectName}</span>
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="所属分区" name="oneArea">
                    <Select
                      placeholder="所属分区"
                      optionLabelProp="label"
                      onChange={(oneAreaId) =>
                        handleOneAreaChange(oneAreaId, twoAreaList, threeAreaList)
                      }
                      disabled={getOneAreaDisabled()}
                    >
                      {oneAreaList.map((one) => {
                        return (
                          <Option value={one.id} label={one.name} key={one.id}>
                            <span>{one.name}</span>
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                {!!twoAreaFilterList?.length && (
                  <Col span={6}>
                    <Form.Item label="所属分区" name="twoArea">
                      <Select
                        placeholder="所属分区"
                        optionLabelProp="label"
                        onChange={(twoAreaId) => handleTwoAreChange(twoAreaId, threeAreaList)}
                        disabled={getTwoAreaDisabled()}
                      >
                        {twoAreaFilterList.map((two) => {
                          return (
                            <Option value={two.id} label={two.name} key={two.id}>
                              <span>{two.name}</span>
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {!!threeAreaFilterList?.length && (
                  <Col span={6}>
                    <Form.Item label="所属分区" name="threeArea">
                      <Select placeholder="所属分区" optionLabelProp="label">
                        {threeAreaFilterList.map((three) => {
                          return (
                            <Option value={three.id} label={three.name} key={three.id}>
                              <span>{three.name}</span>
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                <Col span={6}>
                  <Form.Item label={`${targetName}编码`} name="subjectCode">
                    <Input placeholder={`${targetName}编码`} allowClear autocomplete="off" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="提交人" name="account">
                    <Input placeholder="提交人姓名/账号" allowClear autocomplete="off" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="状态" name="projectState">
                    <Select
                      placeholder="状态"
                      optionLabelProp="label"
                      allowClear
                      onChange={handleChange}
                    >
                      {stateList.map((s) => {
                        return (
                          <Option
                            value={s.title}
                            label={s.title}
                            key={`${s.key}-${s.code}`}
                            state={{ code: s.code, key: s.key }}
                          ></Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="结算状态" name="settlementState">
                    <Select
                      placeholder="结算状态"
                      optionLabelProp="label"
                      allowClear
                      onChange={settlementStateChange}
                    >
                      {settlementStateList.map((s) => {
                        return (
                          <Option
                            value={s.title}
                            label={s.title}
                            key={`${s.key}-${s.code}`}
                            state={{ code: s.code, key: s.key }}
                          ></Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Space>
          </Col>
          <Col span={2} offset={1}>
            <Button type="primary" onClick={handleQuery}>
              查询
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        className="area-manager--table"
        locale={{ emptyText: "暂无数据" }}
        columns={columns}
        dataSource={dataList}
        pagination={
          dataList?.length && {
            ...pageInfo,
            onChange: handlePageChange,
            showTotal: (total) => `共 ${total} 记录`,
          }
        }
        rowKey={"id"}
        scroll={{ x: 1600 }}
      />

      <Modal
        visible={formModalVisible}
        title={null}
        onCancel={closeFormModal}
        width={"90%"}
        centered
        closable={false}
        destroyOnClose
        maskClosable={false}
        footer={null}
      >
        <CrfForm
          closeModal={closeFormModal}
          textAreaMaxLen={1000}
          fromCaseAudit={true}
          {...props}
          tenantCode={tenantCode}
          crfParams={crfParams}
          repeatCodes={repeatSubjectCodeList}
        ></CrfForm>
      </Modal>
    </div>
  );
};

export default AreaManager;
