/**
 * 项目月报
 */
import React from 'react';
import './monthReport.less';
import moment from 'moment';
import {
    Form, Input, Select, Button, message as Message, InputNumber, Table,
    Popconfirm, AutoComplete, TreeSelect, message, Tabs, DatePicker
} from "antd";
import { createFromIconfontCN, DownloadOutlined, RedoOutlined, FileTextOutlined } from '@ant-design/icons';
import Details from "../details/details";
import debounce from 'lodash/debounce';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import localStorageUtil from "../../../../../comm/localStorage";
import PubSub from "pubsub-js";
import {
    GetProjectAllocationList, getReportData, refreshReport, getReportDownload
} from "../../../../../services/project/ProjectService";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

let _this;
const { Option } = Select;
const { Search } = Input;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_sb9kcktrb1j.js',//icon-shanchu icon-tongji icon-quxiao icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});
const layout = {
    wrapperCol: {
        span: 24,
    },
};

const tran = (type, value)=> {
    if (!value) {
        return
    }
    const pieces =['', '月月报', '周周报', '', '日日报', '月单月月报', '周单周周报', '日单日日报']
    const year = value.substring(0, 4)
    const num = value.substring(4)
    return `${year}年${pieces[type]}`
}

class MonthReport extends React.Component {

    constructor(props) {
        super(props);

        let currentRole = sessionStorage.getItem('roleCode');

        _this = this;
        _this.state = {
            userRole: currentRole,
            monthList: [], // 列表集合
            columns: [

                {
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{tran(props.type, record.reportHeader)}</div>
                                <div className={'sub-title'}>
                                    {record.planBegin && record.planBegin.substring(0, 10)}~{record.planEnd && record.planEnd.substring(0, 10)}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    width: '',
                    render: (text, record, index) => {
                        return currentRole == 'EDC_AM' ? '' : (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.userStartNum != undefined ? record.userStartNum : '--'}</div>
                                <div className={'sub-title'}>启动医生数</div>
                            </div>
                        )
                    }
                },
                {
                    width: '',
                    render: currentRole == 'EDC_AM' ? '' : (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.finishNum != undefined ? record.finishNum : '--'}</div>
                                <div className={'sub-title'}>完成采集数</div>
                            </div>
                        )
                    }
                },
                {
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left3'}>
                                <div className={'title'}>{record.status == 0 ? '待生成' : '已生成'}</div>
                                <div className={'sub-title'}>状态</div>
                            </div>
                        )
                    }
                },
                {
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left4'}>
                                <div className={'title'}>{record.createTime ? record.createTime.slice(0, 19).replace('T', ' ') : '--'}</div>
                                <div className={'sub-title'}>报表生成时间</div>
                            </div>
                        )
                    }
                },

                {
                    width: 290,
                    render: (text, record) =>
                        <>
                            <Button type="primary" size="small" onClick={() => this.onRebuild(record.id)} disabled={record.status == 0} loading={_this.state.isRepeat && _this.state.repeatId == record.id}>
                                <RedoOutlined hidden={_this.state.isRepeat} />重新生成
                            </Button>

                            <Button style={{margin: '0 8px'}} type="primary" size="small" onClick={() => this.downLoad(record.id)} disabled={record.status == 0}>
                                <DownloadOutlined />下载
                            </Button>

                            <Button type="primary" size="small" onClick={() => this.openDetails(record)} disabled={record.status == 0}>
                                <FileTextOutlined />查看
                            </Button>
                        </>
                },
            ],// 表格 行 配置
            page: 1,//当前页码数
            pageSize: 10,//页码大小
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageDataByPageNo(pageNo);
                },
                current: 1,
                pageSize: 10,
                total: 100,
                showSizeChanger: false,
            },
            startTime: '', // 开始时间
            endTime: '', // 结束时间
            selectTabPane: 0, // 默认项目月报
            isRepeat: false, // 重复点击
            repeatId: null
        }
    }

    formRef = React.createRef();
    inputRef = React.createRef();

    componentDidMount() {
        _this.getPageData(this.props.type); // 默认展示月报详情
    }

    /**
     * 获取列表数据
     */
    getPageData = async (type) => {
        let { projectId } = this.props;
        let { page, pageSize } = this.state;
        const params = {
            projectId: projectId,
            page: page,
            pageSize: pageSize,
            reportType: type
        }
        const res = await getReportData(params);
        if (res && res.code == 0) {
            _this.setState({
                monthList: [...res.data],
                page: res.page,
                pagination: {
                    ...this.state.pagination,
                    current: res.page,
                    total: res.totalRow,
                }
            })
        }
    }

    /**
     * 根据页码更新当前表格数据
     */
    getPageDataByPageNo = async (page) => {
        let { projectId } = this.props;
        let { pageSize } = this.state;
        const params = {
            projectId: projectId,
            page: page,
            pageSize: pageSize,
            reportType: this.props.type
        }
        const res = await getReportData(params);
        if (res && res.code == 0) {
            _this.setState({
                monthList: [...res.data],
                page: res.page,
                pagination: {
                    ...this.state.pagination,
                    current: res.page,
                    total: res.totalRow,
                }
            })
        }
    }

    /**
     * 重新生成
     */
    refreshReport = (id) => {
        _this.setState({
            isRepeat: true,
            repeatId: id
        })

        refreshReport(id).then(res => {
            if (res && res.code == 0) {
                message.success(res.message);
                _this.getPageData(this.props.type);
            }
        })

    }

    /**
     * 查看详情
     */
    openDetails = (record) => {
        this.props.history.push({
            pathname: '/project/detail/projectReport/details',
            state: { title: this.props.detailTitle, id: record.id, time: record.reportHeader }
        })
    }

    /**
     * 下载
     */
    downLoad = (id) => {
        getReportDownload(id, this.state.userRole).then(res => {
            if (res && res.code == 0) {
                window.open(res.data);
            }
        })
    }

    /**
     * 重新生成
     */
    onRebuild = (id) => {
        this.refreshReport(id);
    }

    /**
     * 选择月报、季报、结项报告
     */
    onTabClick = (index) => {
        this.setState({
            selectTabPane: index
        })
    }

    render() {
        let { startTime, endTime, selectTabPane, columns, monthList, pagination } = this.state;

        let searchWrapHeight = document.getElementById('search_wrap');
        let sh = searchWrapHeight ? searchWrapHeight.offsetHeight : 0;
        let wh = document.documentElement.clientHeight;
        let th = wh - sh - 400;

        return (
            <div className='monthReport'>
                <div className={'table-list'}>
                    <Table
                        locale={{ emptyText: "暂无数据" }}
                        columns={columns}
                        scroll={{y: window.innerHeight - 366}}
                        dataSource={monthList}
                        showHeader={false}
                        defaultExpandAllRows={true}
                        expandIconAsCell={false}
                        pagination={monthList.length !== 0 && pagination}
                        rowKey={'id'}
                        // scroll={{ y: `${th}px` }}
                    />
                </div>
            </div>
        );
    }
}

export default MonthReport;