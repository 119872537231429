import { requestGetRes, requestPostRes } from "../EdcService";

/**
 * 获取区域受试者查询条件
 * @param {} params
 */
export const getAreaQuery = () => requestGetRes(`/data/area/user/purview`);

/**
 * 区域受试者查询
 * @param {} params
 */
export const getAreaSubjects = (data) => requestPostRes(`/data/area/subject`, data);
