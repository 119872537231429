import React from 'react';
import './index.less';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Input, Select, Button, Table, message, Modal, Col, Row, Spin, Checkbox, TreeSelect, DatePicker } from "antd";
import { auditRollback, getSignAuditList, signAudit, getSignAuditProjectList, getSignAuditTenantList, getSignPdf, getSignAuditStateList } from "../../../services/data/DataService";
import { GetAuditRejectReasons } from "../../../services/dct/DctService";
import locale from 'antd/es/date-picker/locale/zh_CN';
const { RangePicker } = DatePicker;
const { Option } = Select;

const { SHOW_PARENT, SHOW_CHILD } = TreeSelect;


class DctSignAudit extends React.Component {

    state = {
        arrowFold: true,
        isBatch: false,
        selectionType: null,
        selectedRowKeys: null,
        tenantOptions: [],
        projectOptions: [],
        signStateOptions: [],
        patientName: '',
        projectCode: null,
        signState: [],
        subjectCode: '',
        submitUserCode: '',
        submitUserName: '',
        submitStartTime: null,
        submitEndTime: null,
        signStartTime: null,
        signEndTime: null,
        createDate: null,
        signDate: null,
        approveStartTime: null,
        approveEndTime: null,
        approveDate: null,
        tenantCode: null,
        page: 1,
        totalRow: 0,
        dataList: [],
        visible: false,
        subjectData: '',
        pdfUrl: '',
        pdfLoading: false,

        dialogLoading: false,
        dialogVisible: false,
        showExtraReason: false,
        rejectCheckedExtra: '',
        rejectReasonOriginArr: [
            { label: '回传数据不完整', value: '1' },
            { label: '回传数据不匹配', value: '2' },
            { label: '回传数据不清晰', value: '3' },
            { label: '其他', value: '4' }
        ],
        rejectReasonArr: [],
        reasonVisible: false,
        reasonContent: []
    }


    componentDidMount() {
        this.getEmunList()
        this.getData()
    }

    /**
     * 选择时间
     * @param {} date 
     * @param {*} dateString 
     */
    changeDate = (date, dateString) => {
        console.log(dateString)
        this.setState({
            createDate: date,
            submitStartTime: dateString[0],
            submitEndTime: dateString[1]
        })
    }

    // 签名时间
    changeSignDate = (date, dateString) => {
        console.log(dateString)
        this.setState({
            signDate: date,
            signStartTime: dateString[0],
            signEndTime: dateString[1]
        })
    }

    //审核时间
    changeApproveDate = (date, dateString) => {
        console.log(dateString)
        this.setState({
            approveDate: date,
            approveStartTime: dateString[0],
            approveEndTime: dateString[1]
        })
    }

    /**
  * 批量审核
  */
    onBatch = async (value) => {
        let { selectedRowKeys } = this.state;

        if (value == 'batch') {
            this.setState({
                selectionType: 'checkbox',
                isBatch: true
            })
        } else {

            if (!selectedRowKeys || selectedRowKeys.length == 0) {
                message.warning('请先选择数据！');
                return
            }

            console.log(selectedRowKeys)
            console.log(value)
            if (value === 'pass') {
                this.handleAudit('2')
            } else if (value === 'noPass') {
                this.handleReject()
            }
        }
    }

    /**
     * 获取项目和租户下拉框数据
     */
    getEmunList = () => {
        getSignAuditProjectList().then(res => {
            if (res.code == 0 && res.data) {
                this.setState({
                    projectOptions: res.data
                })
            }
        })
        getSignAuditTenantList().then(res => {
            if (res.code == 0 && res.data) {
                this.setState({
                    tenantOptions: res.data
                })
            }
        })
        getSignAuditStateList().then(res => {
            if (res.code == 0 && res.data) {
                this.setState({
                    signStateOptions: res.data
                })
            }
        })
        // GetAuditRejectReasons().then(res => {
        //     if (res.code == 0) {
        //         const msgArr = (res.data || []).map(val => val.text);
        //         console.log(msgArr);
        //         this.setState({
        //             rejectReasonOriginArr: msgArr
        //         });
        //     }
        // })
    }


    getData = (pageNo) => {
        let params = {
            patientName: this.state.patientName,
            projectCode: this.state.projectCode,
            signStates: this.state.signState,
            subjectCode: this.state.subjectCode,
            submitUserCode: this.state.submitUserCode,
            submitUserName: this.state.submitUserName,
            submitStartTime: this.state.submitStartTime,
            submitEndTime: this.state.submitEndTime,
            signStartTime: this.state.signStartTime,
            signEndTime: this.state.signEndTime,
            approveStartTime: this.state.approveStartTime,
            approveEndTime: this.state.approveEndTime,
            signEndTime: this.state.signEndTime,
            tenantCode: this.state.tenantCode,
            page: pageNo ? pageNo : this.state.page,
            pageSize: 10
        }
        getSignAuditList(params).then(res => {
            if (res.code == 0 && res.data) {
                this.setState({
                    dataList: res.data.rows,
                    page: res.data.page,
                    totalRow: res.data.total,
                })
            }
        })
    }


    handleSearch = () => {
        this.getData('1')
    }

    /**
     * 重置搜索
     */
    resetSearch = () => {
        this.setState({
            tenantCode: null,
            projectCode: null,
            signState: [],
            patientName: '',
            subjectCode: '',
            submitUserCode: '',
            submitUserName: '',
            submitStartTime: null,
            submitEndTime: null,
            signStartTime: null,
            signEndTime: null,
            approveStartTime: null,
            approveEndTime: null,
            createDate: null,
            signDate: null,
            approveDate: null
        })
    }



    /**
     * 展示病例签名详情
     * @param {*} data 
     */
    showSign = (data) => {
        this.setState({ pdfLoading: true })

        let params = {
            taskId: data.id
        }

        getSignPdf(params).then(res => {
            if (res.code == 0 && res.data) {
                this.setState({
                    pdfUrl: res.data,
                    visible: true,
                    subjectData: data,
                    pdfLoading: false
                })
            } else {
                message.error(res.error)
                this.setState({ pdfLoading: false })
            }
        })
    }

    /**
     * 拒绝
     */
    handleReject = () => {
        this.setState({
            dialogVisible: true,
            rejectReasonArr: this.state.rejectReasonOriginArr,
            rejectCheckedValues: [],
            rejectCheckedExtra: ''
        });
    }


    rejectResononsChange = (checkedValues) => {
        let showExtraReason = false;
        showExtraReason = (checkedValues || []).some((val) => {
            if (val == '4') {
                return true;
            }
        });
        this.setState({
            rejectCheckedValues: checkedValues,
            showExtraReason: showExtraReason
        })
    }

    /**
     * 病历审批 通过不通过
     * @param {*} auditType 2-审核通过 3-不合格
     */
    handleAudit = (auditType) => {
        let { rejectCheckedValues, rejectCheckedExtra, selectedRowKeys, isBatch } = this.state;
        let params = {
            auditType,
        }
        console.log(selectedRowKeys)
        // 批量审核采用数组
        if (isBatch) {
            params.ids = selectedRowKeys ? selectedRowKeys : ''
        } else {
            params.ids = [this.state.subjectData.id]
        }


        if (auditType == '3') {
            if (rejectCheckedValues && rejectCheckedValues.length == 0) {
                message.info('请选择不通过理由');
                return;
            }

            params.auditRejectType = rejectCheckedValues.join(',')

            let showExtraReason = (rejectCheckedValues || []).some((val) => {
                if (val == '4') {
                    return true;
                }
            });

            if (showExtraReason) {
                if (rejectCheckedExtra) {
                    params.auditRejectDetail = rejectCheckedExtra
                } else {
                    params.auditRejectDetail = ''
                }
            }
        }

        signAudit(params).then(res => {
            if (res.code == 0) {
                message.success('操作成功', 2)
                this.setState({
                    visible: false,
                    dialogVisible: false,
                    isBatch: false,
                    selectionType: null,
                })
                this.getData()
            }
        })
    }


    /**
     * 撤销病历审批
     */
    handleRollback = () => {
        let params = {
            id: this.state.subjectData.id
        }

        auditRollback(params).then(res => {
            if (res.code == 0) {
                message.success('操作成功', 2)
                this.setState({
                    visible: false
                })
                this.getData()

            }
        })
    }

    /**
     * 其他原因填写
     */
    extraReasonChange = (event) => {
        this.setState({
            rejectCheckedExtra: event.target.value
        });
    }

    handleCancel = () => {
        this.setState({ dialogVisible: false, showExtraReason: false });
    };


    showReason = (data) => {
        if (data.signRejectState) {
            let signRejectState = data.signRejectState.split(',')
            let rejectReason = []
            signRejectState.forEach(v => {
                if (v == '1') {
                    rejectReason.push('回传数据不完整')
                } else if (v == '2') {
                    rejectReason.push('回传数据不匹配')
                } else if (v == '3') {
                    rejectReason.push('回传数据不清晰')
                } else if (v == '4') {
                    if (data.signRejectReason) {
                        rejectReason.push('其他（' + data.signRejectReason + '）')
                    } else {
                        rejectReason.push('其他')
                    }
                }
            })
            return rejectReason.join('，')
        }
    }


    //显示拒绝原因模态框
    showReasonModal = (data) => {
        let signRejectState = data.signRejectState.split(',')
        let rejectReason = []
        signRejectState.forEach(v => {
            if (v == '1') {
                rejectReason.push('回传数据不完整')
            } else if (v == '2') {
                rejectReason.push('回传数据不匹配')
            } else if (v == '3') {
                rejectReason.push('回传数据不清晰')
            } else if (v == '4') {
                if (data.signRejectReason) {
                    rejectReason.push('其他（' + data.signRejectReason + '）')
                } else {
                    rejectReason.push('其他')
                }
            }
        })

        if (rejectReason.join('，').length > 7) {
            this.setState({
                reasonContent: rejectReason,
                reasonVisible: true
            })
        }
    }


    render() {
        let { arrowFold, selectionType, dataList, tenantOptions, projectOptions, signStateOptions, totalRow, page, visible, subjectData, pdfUrl, pdfLoading,
            dialogVisible, dialogLoading, rejectReasonArr, showExtraReason, rejectCheckedExtra, rejectCheckedValues, reasonVisible, reasonContent, createDate, signDate, approveDate } = this.state;

        let columns = [
            {
                title: '病例编码',
                dataIndex: 'subjectCode',
                width: '',
            },
            {
                title: '患者',
                dataIndex: 'patientName',
                width: 140,
            },
            {
                title: '签名状态',
                dataIndex: 'signState',
                width: 145,
                render: (text, record) => {
                    return (
                        <div className='apply_state'>
                            {
                                record.signState == 0 ?
                                    <div className='state noStart' >
                                        <span className='dot'></span>
                                        <span>未签名</span>
                                    </div> : record.signState === 1 ?
                                        <div className='state noStart'>
                                            <span className='dot'></span>
                                            <span>提交未审核</span>
                                        </div> : record.signState === 2 ?
                                            <div className='state success'>
                                                <span className='dot'></span>
                                                <span>已审核</span>
                                            </div> : record.signState === 3 ?
                                                <div className='state notPass'>
                                                    <span className='dot'></span>
                                                    <span>不合格</span>
                                                </div> : ""
                            }
                        </div>

                    )
                }
            },
            {
                title: '不合格原因',
                dataIndex: 'signRejectState',
                width: 145,
                render: (text, record) => {
                    return (
                        <div className='apply_state'>
                            {
                                <div className='state running' onClick={() => this.showReasonModal(record)}>
                                    <span className='dot'></span>
                                    <span>{this.showReason(record)}</span>
                                </div>
                            }
                        </div>

                    )
                }
            },
            {
                title: '提交人',
                dataIndex: 'submitUserName',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div>{record.submitUserName}</div>
                        <div>{record.submitUserCode}</div>
                    </div>
                )
            },
            {
                title: '提交时间',
                dataIndex: 'submitTime',
                width: 120,
                render: (text, record) => {
                    if (record.submitTime) {
                        return (
                            <div className={'con_div'}>{record.submitTime ? record.submitTime.replace('T', ' ') : ''}
                            </div>
                        )
                    }
                }
            },
            {
                title: '项目',
                dataIndex: 'projectName',
                width: '',
            },
            {
                title: '租户',
                dataIndex: 'tenantName',
                width: '',
            },
            {
                title: '审核时间',
                dataIndex: 'approveTime',
                width: 120,
                render: (text, record) => {
                    if (record.approveTime) {
                        return (
                            <div className={'con_div'}>{record.approveTime ? record.approveTime.replace('T', ' ') : ''}
                            </div>
                        )
                    }
                }
            },
            {
                title: '审核人',
                dataIndex: 'approveUser',
                width: '',
            },
            {
                title: '签名时间',
                dataIndex: 'signTime',
                width: 120,
                render: (text, record) => {
                    if (record.signTime) {
                        return (
                            <div className={'con_div'}>{record.signTime ? record.signTime.replace('T', ' ') : ''}
                            </div>
                        )
                    }
                }
            },
            {
                title: '操作',
                width: 100,
                fixed: 'right',
                align: 'center',
                render: (text, record) =>
                    <div hidden={record.signState == '0'}>
                        <Button type="link" onClick={() => this.showSign(record)} >
                            查看签名
                        </Button>
                    </div>
            },
        ]

        let pagination = {
            onChange: (pageNo) => {
                this.getData(pageNo);
            },
            current: page,
            pageSize: 10,
            total: totalRow,
            showSizeChanger: false,
            showTotal: total => `共${total} 条记录`,
        }

        const tran = (target)=> {
            target.forEach((t)=> {
                t.value = t.code
                if (Array.isArray(t.children)) {
                    tran(t.children)
                }
            })
        }
        tran(signStateOptions)

        const tProps = {
            treeData: signStateOptions,
            value: this.state.signState,
            onChange: value => this.setState({ signState: value }),
            treeCheckable: true,
            showCheckedStrategy: SHOW_CHILD,
            placeholder: '请选择签名状态',
            style: {
                minWidth: '50%',
            },
        };

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys
                })
            },
            getCheckboxProps: (record) => ({
                disabled: record.signState !== 1,
                name: record.name,
            }),
        };

        return (
            <div className='signAudit_content'>

                <div className={'apply_wrap'}>
                    <div className={'search_wrap_content'}>
                        <div className='search_left'>
                            <Row className='search_row'>
                                {/* {
                                    arrowFold ?
                                        <div className='arrow_fold' onClick={() => this.setState({ arrowFold: false })}>
                                            <span>收起</span>
                                            <UpOutlined style={{ fontSize: '12px', marginLeft: '3px' }} />
                                        </div>
                                        :
                                        <div className='arrow_fold' onClick={() => this.setState({ arrowFold: true })}>
                                            <span>展开</span>
                                            <DownOutlined style={{ fontSize: '12px', marginLeft: '3px' }} />
                                        </div>
                                } */}
                                <Col span={8}>
                                    <span className='search_label'>选择租户</span>
                                    <Select
                                        showSearch
                                        placeholder="请选择租户"
                                        style={{ width: '70%' }}
                                        value={this.state.tenantCode}
                                        allowClear
                                        onChange={value => this.setState({ tenantCode: value })}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {tenantOptions.map(item => (
                                            <Option key={item.code} value={item.code}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={8}>
                                    <span className='search_label'>选择项目</span>
                                    <Select
                                        showSearch
                                        placeholder="请选择项目"
                                        style={{ width: '70%' }}
                                        value={this.state.projectCode}
                                        allowClear
                                        onChange={value => this.setState({ projectCode: value })}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {projectOptions.map(item => (
                                            <Option key={item.projectCode} value={item.projectCode}>{item.projectName}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={8}>
                                    <span className='search_label'>病例编码</span>
                                    <Input
                                        allowClear
                                        placeholder="请输入病例编码"
                                        value={this.state.subjectCode}
                                        onChange={(e) => this.setState({ subjectCode: e.target.value })}
                                        style={{ width: '70%' }}
                                    />
                                </Col>
                                <Col span={8} hidden={!arrowFold}>
                                    <span className='search_label'>患者姓名</span>
                                    <Input
                                        allowClear
                                        placeholder="请输入患者姓名"
                                        value={this.state.patientName}
                                        onChange={(e) => this.setState({ patientName: e.target.value })}
                                        style={{ width: '70%' }}
                                    />
                                </Col>


                                <Col span={8} hidden={!arrowFold}>
                                    <span className='search_label'>提交账号</span>
                                    <Input
                                        allowClear
                                        placeholder="请输入提交账号"
                                        value={this.state.submitUserCode}
                                        onChange={(e) => this.setState({ submitUserCode: e.target.value })}
                                        style={{ width: '70%' }}
                                    />
                                </Col>
                                <Col span={8} hidden={!arrowFold}>
                                    <span className='search_label'>提交姓名</span>
                                    <Input
                                        allowClear
                                        placeholder="请输入提交姓名"
                                        value={this.state.submitUserName}
                                        onChange={(e) => this.setState({ submitUserName: e.target.value })}
                                        style={{ width: '70%' }}
                                    />
                                </Col>
                                <Col span={8} hidden={!arrowFold}>
                                    <span className='search_label'>签名状态</span>
                                    <TreeSelect {...tProps} style={{ width: '70%' }} />
                                </Col>
                                <Col span={8} hidden={!arrowFold}>
                                    <span className='search_label'>提交时间</span>
                                    <RangePicker value={createDate} onChange={this.changeDate} locale={locale} style={{ width: '70%' }} />
                                </Col>
                                <Col span={8} hidden={!arrowFold}>
                                    <span className='search_label'>签名时间</span>
                                    <RangePicker value={signDate} onChange={this.changeSignDate} locale={locale} style={{ width: '70%' }} />
                                </Col>
                                <Col span={8} hidden={!arrowFold}>
                                    <span className='search_label'>审核时间</span>
                                    <RangePicker value={approveDate} onChange={this.changeApproveDate} locale={locale} style={{ width: '70%' }} />
                                </Col>
                            </Row>

                        </div>
                        <div className='right_action'>
                            <Button type="primary" size={'middle'} onClick={this.handleSearch} >
                                查询
                            </Button>
                            <Button type="primary" ghost size={'middle'} onClick={this.resetSearch} >
                                重置
                            </Button>
                        </div>
                    </div>

                    <Spin tip='生成文件中...' spinning={pdfLoading}>
                        <div className={'btns_wrap'}>
                            {
                                selectionType === 'checkbox' ?
                                    <>
                                        <Button type="link" onClick={() => this.onBatch('pass')} >通过</Button>
                                        <Button style={{ 'color': '#F03333' }} type="link" onClick={() => this.onBatch('noPass')} >不通过</Button>
                                        <Button type="link" onClick={() => this.setState({ selectionType: null })} >取消</Button>
                                    </> :
                                    <>
                                        <Button type="primary" ghost onClick={() => this.onBatch('batch')} >批量审核</Button>
                                    </>
                            }
                        </div>
                        <Table
                            locale={{ emptyText: "暂无数据" }}
                            columns={columns}
                            dataSource={dataList}
                            pagination={pagination}
                            rowKey={'id'}
                            scroll={{ x: 'calc(700px + 50%)' }}
                            rowSelection={selectionType ? {
                                type: selectionType,
                                ...rowSelection,
                            } : null}
                        />
                    </Spin>
                </div>

                {/* 查看病例签名 */}
                <Modal
                    title="查看病例签名"
                    visible={visible}
                    onCancel={() => { this.setState({ visible: false }) }}
                    width={1000}
                    footer={[
                        <div className='opertion_warp'>
                            {
                                subjectData.signState == '1' ? (
                                    <>
                                        <Button danger type="primary" onClick={() => this.handleReject()}>
                                            审核不通过
                                        </Button>
                                        <Button type="primary" onClick={() => this.handleAudit('2')}>
                                            审核通过
                                        </Button>
                                    </>
                                ) : subjectData.signState == '2' ? (
                                    <Button type="primary" onClick={this.handleRollback}>
                                        撤销审核通过
                                    </Button>
                                ) : subjectData.signState == '3' ? (
                                    <Button danger type="primary" onClick={this.handleRollback}>
                                        撤销不合格
                                    </Button>
                                ) : ""
                            }
                        </div>
                    ]}
                >
                    <div style={{ display: 'flex' }}>
                        {
                            pdfUrl.includes('pdf') ?
                                <iframe src={pdfUrl} style={{ width: 900, height: 600, margin: '0 auto' }}>
                                    <p>您的浏览器不支持 iframe 标签</p>
                                </iframe> :
                                <div style={{ width: 900, height: 600, margin: '0 auto', overflowY: 'auto' }}>
                                    <img src={pdfUrl} style={{ width: '100%' }}>
                                    </img>
                                </div>
                        }
                    </div>
                </Modal >

                <Modal
                    className='case-audit-checkbox-wrapper'
                    visible={dialogVisible}
                    title="请选择不通过理由"
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            返回
                        </Button>,
                        <Button key="submit" type="primary" loading={dialogLoading} onClick={() => this.handleAudit('3')}>
                            确认
                        </Button>
                    ]}
                >
                    <Checkbox.Group options={rejectReasonArr} onChange={this.rejectResononsChange} value={rejectCheckedValues} />
                    {
                        showExtraReason &&
                        <textarea className='case-audit-text-area' maxLength={100} onChange={this.extraReasonChange} value={rejectCheckedExtra} style={{ resize: 'none' }}></textarea>
                    }
                </Modal>
                <Modal
                    visible={reasonVisible}
                    title="不通过理由"
                    onCancel={() => this.setState({ reasonVisible: false })}
                    footer={null}
                >
                    <div style={{ minHeight: 300 }}>
                        <ul>
                            {reasonContent.map((v, i) => {
                                return (<li style={{ listStyle: 'disc outside none' }} key={i}>{v}</li>)
                            })}
                        </ul>
                    </div>
                </Modal>
            </div >
        )
    }
}

export default DctSignAudit