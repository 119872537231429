import React from 'react';
import { Table, Input, Modal, Button, Form, Select, message, Tooltip, Spin, Checkbox } from 'antd';
import { getAllRoleList, addRole, editRoleStatus, deleteRole, getRoleResources, addRoleResource } from "../../../services/system/RoleService";
import { ExclamationCircleOutlined } from '@ant-design/icons'
import './index.less'
import moment from 'moment';
const { Search } = Input;
const { Option } = Select;

const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 17,
    },
};


const options = [
    {
        label: 'Apple',
        value: 'Apple',
    },
    {
        label: 'Pear',
        value: 'Pear',
    },
    {
        label: 'Orange',
        value: 'Orange',
    },
];

class RoleManagement extends React.Component {
    state = {
        name: '',
        data: [],

        addRoleModal: false,
        submitDisabled: false,

        currentCode: '',
        resourceModal: false,
        roleResource: [],//当前角色权限列表
        allResource: [],//所有角色权限列表
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        let params = {
            name: this.state.name,
        }
        getAllRoleList(params).then(res => {
            this.setState({
                data: res
            })
        }).catch(err => {
            message.error(err.message);
        })
    }


    /**
     * 搜索
     */
    handleSearch = (value) => {
        this.setState({
            name: value
        }, () => {
            this.getData()
        })
    }


    handleAdd = (values) => {
        let params = {
            code: values.code,
            name: values.name,
            type: values.type
        }

        this.setState({
            submitDisabled: true
        })

        addRole(params).then(res => {
            this.setState({
                addRoleModal: false,
                submitDisabled: false
            }, () => {
                this.getData()
            })
        }).catch(err => {
            message.error(err.message);
        })
    };




    disableRole = (data) => {
        let params = {
            code: data.code
        }
        editRoleStatus(params).then(res => {
            this.getData()
        }).catch(err => {
            message.error(err.message)
        })
    }

    handleDelete = (data) => {
        let params = {
            code: data.code
        }
        Modal.confirm({
            title: '确定删除该角色？',
            icon: <ExclamationCircleOutlined />,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                deleteRole(params).then(res => {
                    this.getData()
                }).catch(err => {
                    message.error(err.message)
                })
            }
        });
    }



    showRoleResources = (data) => {
        let params = {
            code: data.code
        }
        getRoleResources(params).then(res => {
            this.setState({
                currentCode: data.code,
                resourceModal: true,
                allResource: res.allResource,
                roleResource: res.roleResource,
            })
        }).catch(err => {
            message.error(err.message)
        })
    }


    firstChange = (data) => {
        let { roleResource } = this.state;
        let Index = roleResource.findIndex(v => v.resourceCode == data.code)
        if (Index > -1) {
            roleResource.splice(Index, 1)
        } else {
            roleResource.push({
                resourceCode: data.code
            })
        }
        this.setState({
            roleResource
        })
    }


    secondChange = (firstData, secondData) => {
        let { roleResource } = this.state;
        let Index = roleResource.findIndex(v => v.resourceCode == firstData.code);  //第一级节点位置
        let Index1 = roleResource.findIndex(v => v.resourceCode == secondData.code); //第二级节点位置

        if (Index1 > -1) {
            roleResource.splice(Index1, 1)
        } else {
            roleResource.push({
                resourceCode: secondData.code
            })

            if (Index < 0) {
                roleResource.push({
                    resourceCode: firstData.code
                })
            }
        }
        this.setState({
            roleResource
        })
    }


    threeChange = (firstData, secondData, threeData) => {
        let { roleResource } = this.state;
        let Index = roleResource.findIndex(v => v.resourceCode == firstData.code);  //第一级节点位置
        let Index1 = roleResource.findIndex(v => v.resourceCode == secondData.code); //第二级节点位置
        let Index2 = roleResource.findIndex(v => v.resourceCode == threeData.code); //第三级节点位置

        if (Index2 > -1) {
            roleResource.splice(Index2, 1)
        } else {
            roleResource.push({
                resourceCode: threeData.code
            })
            if (Index < 0) {
                roleResource.push({
                    resourceCode: firstData.code
                })
            }
            if (Index1 < 0) {
                roleResource.push({
                    resourceCode: secondData.code
                })
            }
        }
        this.setState({
            roleResource
        })
    }


    handleSubmit = () => {
        let { roleResource, currentCode } = this.state;

        let resourceList = roleResource.map(v => v.resourceCode)

        let params = {
            code: currentCode,
            resourceList: resourceList
        }

        addRoleResource(params).then(res => {
            this.setState({
                currentCode: "",
                resourceModal: false,
                allResource: [],
                roleResource: []
            })
        }).catch(err => {
            message.error(err.message)
        })
    }


    render() {
        let { addRoleModal, submitDisabled, resourceModal, allResource, roleResource } = this.state;
        const columns = [
            {
                title: '角色编码',
                dataIndex: 'code'
            },
            {
                title: '角色名称',
                dataIndex: 'name'
            },
            {
                title: '角色类型',
                dataIndex: 'type',
                render: (text) => (
                    <div className='opertion'>
                        {text == 'PROJECT_ROLE' ? '项目角色' : '平台角色'}
                    </div>
                )
            },
            {
                title: '角色状态',
                dataIndex: 'roleStatus',
                render: (text) => (
                    <div className='opertion'>
                        {text == '0' ? '禁用' : '启用'}
                    </div>
                )
            },
            {
                title: '操作',
                dataIndex: 'operation',
                width: 200,
                render: (text, record) => (
                    record.status != 0 && <div className='opertion'>
                        <div className='jurisdiction' onClick={() => this.showRoleResources(record)}>角色权限</div>
                        <div className='disable' onClick={() => this.disableRole(record)}>{record.roleStatus == '0' ? '启用' : '禁用'}</div>
                        <div className='delete' onClick={() => this.handleDelete(record)}>删除</div>
                    </div>
                )
            }
        ];

        return (
            <div className='user_container'>
                <div className='search'>
                    <div className='searchItem'>
                        角色：
                        <Search placeholder="请输入角色" onSearch={this.handleSearch} style={{ width: 200 }} />
                    </div>
                    <Button type="primary" className='addBtn' onClick={() => this.setState({ addRoleModal: true })}>新增角色</Button>
                </div>

                <Table rowKey={(record) => record.code} bordered columns={columns} dataSource={this.state.data} />


                <Modal
                    visible={addRoleModal}
                    title="新增用户"
                    onCancel={() => this.setState({ addRoleModal: false })}
                    centered
                    destroyOnClose
                    footer={false}
                >
                    <div>
                        <Form
                            {...layout}
                            name="nest-messages"
                            onFinish={this.handleAdd}
                        >
                            <Form.Item label="角色编码" name='code'
                                rules={[
                                    { required: true, message: '请输入角色编码!' }
                                ]}
                            >
                                <Input style={{ width: 300 }} placeholder="请输入角色编码" autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="角色名称" name='name' rules={[{ required: true, message: '请输入角色名称!' }]}>
                                <Input style={{ width: 300 }} placeholder="请输入角色名称" autoComplete="off" />
                            </Form.Item>
                            <Form.Item
                                label="角色类型"
                                name='type'
                                rules={[{ required: true, message: '请选择角色类型!' }]}>
                                <Select
                                    placeholder='请选择角色类型'
                                    style={{ width: 300 }}
                                >
                                    <Option value='PLAT_ROLE'>平台角色</Option>
                                    <Option value='PROJECT_ROLE'>项目角色</Option>
                                </Select>
                            </Form.Item>


                            <Form.Item style={{ textAlign: 'center', width: '100%', marginBottom: 0, marginTop: 45, }} wrapperCol={24}>
                                <Button htmlType="submit" disabled={submitDisabled} type="primary">
                                    确认
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>


                <Modal
                    visible={resourceModal}
                    title="角色权限"
                    onCancel={() => this.setState({ resourceModal: false })}
                    destroyOnClose
                    width={1000}
                    className='resourceModal'
                    onOk={this.handleSubmit}
                    okText="确定"
                    cancelText="取消"
                >
                    <div className='resource'>
                        {
                            allResource.map((item, index) => (
                                <div className='firstResource' key={index}>
                                    <Checkbox
                                        key={index}
                                        className='firstTitle'
                                        onChange={() => this.firstChange(item)}
                                        checked={roleResource.findIndex((data) => data.resourceCode == item.code) > -1 ? true : false}
                                    >
                                        {item.name}
                                    </Checkbox>

                                    <div className='secondResource_content'>
                                        {
                                            item.children.map((v, i) => (
                                                <div className='secondResource' key={i}>

                                                    <Checkbox
                                                        key={index}
                                                        className='secondTitle'
                                                        onChange={() => this.secondChange(item, v)}
                                                        checked={roleResource.findIndex((data) => data.resourceCode == v.code) > -1 ? true : false}
                                                    >
                                                        {v.name}
                                                    </Checkbox>

                                                    <div className='threeResource_content'>
                                                        {
                                                            v.children.map((vv, ii) => (
                                                                <div className='threeResource' key={ii}>
                                                                    <Checkbox
                                                                        checked={roleResource.findIndex((data) => data.resourceCode == vv.code) > -1 ? true : false}
                                                                        key={ii}
                                                                        onChange={() => this.threeChange(item, v, vv)}
                                                                    >
                                                                        {vv.name}
                                                                    </Checkbox>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                </Modal>
            </div>
        )
    }

}

export default RoleManagement;
