import React, {Component, Fragment} from 'react';
import './crfForm.less';
import {Form, Row, Col, Input, Button, Select, Table} from 'antd';
import {GetDesignFormList, GetStatusList} from "../../../services/design/DesignService";
import PubSub from "pubsub-js";
import localStorageUtil from "../../../comm/localStorage";
import {DictionaryPageList} from "../../../services/config/ConfigService";

let _this;
const FormItem = Form.Item;
const {Option} = Select;

class CrfForm extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            /** 页面查询条件元素*/
            formLibraryState: null,
            name: '',
            page: 1,//当前页码数
            pageSize: 10,//页码大小
            /** 接口列表元素*/
            crfList: [], // crf数据集合
            statusList: [], // 状态下拉集合
            pagination: {
                onChange: (pageNo) => {
                    this.getPageData(pageNo);
                },
                current: 1,
                pageSize: 10,
                total: 100,
                showSizeChanger: false,
            }
        };
    }

    formRef = React.createRef();

    componentDidMount() {
        _this.getStateList()
        _this.getList();

    }

    /**
     * 获取平台字典查询集合
     */
    getList = (currentPage, searchParam) => {
        const param = searchParam ? {
            ...searchParam,
            page: currentPage ? currentPage : this.state.page,
            pageSize: this.state.pageSize,
        } : {
            formLibraryState: this.state.formLibraryState,
            name: this.state.name,
            page: currentPage ? currentPage : this.state.page,
            pageSize: this.state.pageSize,
        }
        GetDesignFormList(param).then((res) => {
            _this.setState({
                crfList: res.rows,
                pagination: {
                    ..._this.state.pagination,
                    total: res.total,
                    pageSize: res.pageSize,
                    current: res.page
                }
            })
        })
    }

    /**
     * 页码跳转查询列表
     * @param fieldsValue
     */
    getPageData = (pageNo) => {
        _this.getList(pageNo)
    }

    /**
     * 查询 状态列表
     * @param fieldsValue
     */
    getStateList = () => {
        GetStatusList().then((res) => {
            let statusList = [];
            res.stateEnumList.forEach(x => {
                if (x == "USE") {
                    statusList.push({code: x, message: "使用中"})
                } else if (x == "UNUSE") {
                    statusList.push({code: x, message: "禁用中"})
                } else if (x == "CREATE") {
                    statusList.push({code: x, message: "创建中"})
                }
            })
            _this.setState({
                statusList
            })
        })
    }

    /**
     * 查询提交方法
     * @param fieldsValue
     */
    onSearch = (searchParam) => {
        _this.getList(_this.state.page, searchParam);
    }

    /**
     * 查看方法
     */
    handleItemDetail = (item) => {
        let menuModule = localStorageUtil.get('menuModule', true);
        menuModule.levelThreeTitle = 'CRF表单详情';
        menuModule.levelTwoUrl = '/design/crfForm';
        localStorageUtil.set('menuModule', menuModule, true);
        PubSub.publish('onMenuModuleChange', menuModule);
        _this.props.history.push({pathname: '/design/crfForm/detail', state: {...item}})
    }

    /**
     * 编辑方法
     */
    handleItemEdit = (item) => {
        console.log(item)
    }

    /**
     * 删除方法
     */
    handleItemDelete = (item) => {
        console.log(`handleItemDelete`, item);
    }

    render() {
        const columns = [
            {
                align: 'center',
                title: '表单库名称',
                key: 'name',
                width: 120,
                dataIndex: 'name'
            },
            {
                align: 'center',
                title: '分类',
                key: 'formLibraryType',
                width: 120,
                dataIndex: 'formLibraryType'
            },
            {
                align: 'center',
                title: '表单数量',
                key: 'formCount',
                width: 120,
                dataIndex: 'formCount'
            },
            {
                align: 'center',
                title: '表单库状态',
                key: 'formLibraryState',
                width: 120,
                dataIndex: 'formLibraryState'
            },
            {
                align: 'center',
                title: '创建时间',
                key: 'createTime',
                width: 150,
                dataIndex: 'createTime'
            },
            {
                align: 'center',
                title: '创建人',
                key: 'createUser',
                width: 120,
                dataIndex: 'createUser'
            },
            {
                align: 'center',
                title: '操作',
                width: 150,
                render: (text, item) => {
                    return <div>
                        <Button size="small" onClick={() => {
                            this.handleItemDetail(item)
                        }}>查看</Button>&nbsp;&nbsp;
                        <Button size="small" onClick={() => {
                            this.handleItemEdit(item)
                        }}>编辑</Button>
                    </div>
                }
            }
        ]
        return (
            <div className={'crfForm'}>
                <Fragment>
                    <Form
                        layout="inline"
                        className={'searchItem'}
                        onFinish={this.onSearch}
                        ref={_this.formRef}
                        autoComplete={'off'}
                    >
                        <Row className={'searchRow'}>
                            <Col span={5}>
                                <FormItem
                                    name="name"
                                    label="名称"
                                    style={{marginLeft: '18px'}}
                                >
                                    <Input placeholder=""/>
                                </FormItem>
                            </Col>
                            <Col span={5}>
                                <FormItem
                                    name="formLibraryState"
                                    label="状态"
                                >
                                    <Select allowClear>
                                        {
                                            _this.state.statusList.map(i => {
                                                return <Option
                                                    value={i.code}
                                                    key={i.code}>{i.message}</Option>
                                            })
                                        }
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={4}>
                                <FormItem>
                                    <Button type="primary" htmlType="submit">查询</Button>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                    <Table
                        className={'table-list'}
                        bordered
                        columns={columns}
                        dataSource={this.state.crfList}
                        pagination={this.state.pagination}
                        scroll={{y: 400}}
                        rowKey={'id'}
                    />
                </Fragment>
            </div>
        )
    }
}

export default CrfForm