/**
 * 各区域完成情况概览
 */
 import React from 'react';
 import { connect } from 'react-redux';
 import './echartsAreaCondition.less';
 import ReactEcharts from '../../../../base/echarts/ReactEcharts';
 
 let _this;
 
 const optionsData = [
     {color: '#73A0FA', colorGroup: '#EAF1FF, #73A0FA', name: '目标数', value: 100, num: 100, id: 'id1'},
     {color: '#73A0FA', colorGroup: '#EAF1FF, #73A0FA', name: '已完成', value: 90, num: 90, id: 'id2'},
     {color: '#73A0FA', colorGroup: '#EAF1FF, #73A0FA', name: '采集中', value: 80, num: 80, id: 'id3'},
     {color: '#73A0FA', colorGroup: '#EAF1FF, #73A0FA', name: '待入排', value: 70, num: 70, id: 'id4'},
     {color: '#73A0FA', colorGroup: '#EAF1FF, #73A0FA', name: '已脱落', value: 60, num: 60, id: 'id5'},
     {color: '#73A0FA', colorGroup: '#EAF1FF, #73A0FA', name: '已排除', value: 50, num: 50, id: 'id6'},
 ]
 
 class EchartsAreaCondition extends React.Component {
 
     constructor(props) {
         super(props);
         _this = this;
         _this.state = {
         }
     }
 
     componentDidMount() {
     }
     
     getOptions = (item) => {

         let option = {
             grid: {
                x: '8%',
                y: 60,
                x2: 50,
                y2: 30,
            },
             dataset: {
                 source: [
                     ['地区', '目标数', '已完成'],
                     ['华南大区', 143.3, 85.8],
                     ['华北大区', 83.1, 173.4],
                     ['西南大区', 86.4, 265.2],
                     ['西北大区1', 72.4, 130.9],
                     ['西北大区2', 172.4, 53.9],
                     ['西北大区3', 222.4, 100.9],
                     ['西北大区4', 200.4, 100.9],
                     ['西北大区5', 150.4, 80.9],
                 ]
             },
             tooltip: {
                trigger: 'axis'
            },
             toolbox: {
                 show: true,
                 orient: 'horizontal',
                 left: 'right',
                 top: 'top',
                 feature: {
                     mark: { show: true },
                     dataView: { show: true, readOnly: false },
                     magicType: { show: true, type: ['line', 'bar'] },
                     restore: { show: true },
                     saveAsImage: { show: true }
                 }
             },
             xAxis: {
                 type: 'category',
                 min: 0,
                //  max: 1000,
                 splitNumber: 3,
             },
             yAxis: {
                type: 'value',
                min: 0,
                max: 300,
             },
             series: [
                 { type: 'bar', barWidth: '20%', },
                 { type: 'bar', barWidth: '20%', },
             ],
            color: ['#6C96F7', '#5EBCBC']

         };
         return option;
     }
 
   render() {
     let data = _this.props.projectMonitor;
     if (JSON.stringify(data) !== '{}') {
       return (<div className={'overall'}>
         <ReactEcharts
            id={'bar'}
            option={_this.getOptions()}
            style={{ width: '100%', height: 300, margin: '0 auto' }}
         />
       </div>);
     } else {
       return <div></div>
     }
   }
 }
 
 const mapStateToProps = (state) => ({
     projectMonitor: state.getIn(['project', 'projectMonitor'])
 })
 
 const mapDispatchToProps = (dispatch) => ({
 })
 export default connect(mapStateToProps, mapDispatchToProps)(EchartsAreaCondition);