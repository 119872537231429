/**
 * 批量编辑
 */
import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import { createFromIconfontCN } from '@ant-design/icons';
import './modalLabor.less'
import ReactWEditor from 'wangeditor-for-react';

let timeInterval;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

class modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editorContent: '',
            hospital: ''
        }
    }

    editorRef = React.createRef();

    componentDidMount() {
        this.setState({
            editorContent: this.props.content
        })
    }

    /**
     * 关闭
     */
    handleCancel = (value) => {
        this.setState({
            loading: false,
        }, () => {
            this.props.closeModal();
        })

        // 确认
        if (value) {
            this.props.getValue(this.state.editorContent)
        }

    };

    render() {
        const { editorContent } = this.state;
        const { visible } = this.props;

        return (
            <>
                <Modal
                    className={'modalLabor'}
                    visible={visible}
                    title="服务协议"
                    onCancel={() => this.handleCancel(false)}
                    width={'60%'}
                    centered
                    destroyOnClose={true}
                    footer={this.props.readOnly ? null : [
                        <Button
                            key="back"
                            onClick={() => this.handleCancel(false)}
                            className={'cancelBtn'}
                        >关闭</Button>,
                        <Button
                            key="submit"
                            type="primary"
                            onClick={() => this.handleCancel(true)}
                        >确认</Button>
                    ]}
                >
                    {
                        this.props.readOnly ? <div dangerouslySetInnerHTML={{ __html: editorContent }}></div> :
                            <ReactWEditor
                                defaultValue={editorContent}
                                linkImgCallback={(src, alt, href) => {
                                    // 插入网络图片的回调事件
                                    // console.log('图片 src ', src)
                                    // console.log('图片文字说明', alt)
                                    // console.log('跳转链接', href)
                                }}
                                onlineVideoCallback={(video) => {
                                    // 插入网络视频的回调事件
                                    // console.log('插入视频内容', video)
                                }}
                                onChange={(html) => {
                                    console.log('onChange html:', html)
                                    this.setState({
                                        editorContent: html
                                    })
                                }}
                                onBlur={(html) => {
                                    // console.log('onBlur html:', html)
                                }}
                                onFocus={(html) => {
                                    // console.log('onFocus html:', html)
                                }}
                            />
                    }

                </Modal>
            </>
        );
    }
}

export default modal;