/**
 * 添加备注 - 模态框
 */
import React, { Component } from 'react';
import { Modal, Button, Form, Input, Select, Radio } from 'antd';
import {
    AddRemark,
    GetRemarkType
} from "../../../../../../services/data/DataService";
import './remarksModal.less'

const layout = {
    labelCol: {
        span: 2,
    },
    wrapperCol: {
        span: 22,
    },
};
const { Option } = Select;

class RemarksModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            getRemarkType: [],//备注类型List
            btnDisabled: false
        };
    }

    componentDidMount() {
        this.getRemarkType()
    }

    form = React.createRef();

    /**
     * 备注类型List
     */
    getRemarkType = () => {
        GetRemarkType().then(res => {
            this.setState({
                getRemarkType: res.data
            })
        })
    }

    /**
     * 添加
     */
    handleOk = () => {
        let param = this.form.current.getFieldsValue(['remarkType', 'remark', 'isWarning'])
        param.subjectCode = this.props.visibleId
        this.setState({
            btnDisabled: true
        })
        AddRemark(param).then(() => {
            this.setState({ loading: false }, () => {
                this.props.closeModal();
                this.props.getSubjectDetail()
            });
            this.setState({
                btnDisabled: false
            })
        })
    };

    render() {
        const { loading } = this.state;
        const { visible } = this.props;
        return (
            <>
                <Modal
                    className={'subModal'}
                    visible={visible}
                    title="添加备注"
                    onCancel={this.props.closeModal}
                    width={840}
                    centered
                    destroyOnClose
                    footer={[]}
                >
                    <div>
                        <Form
                            {...layout}
                            name="nest-messages"
                            requiredMark={false}
                            ref={this.form}
                            onFinish={this.handleOk}
                        >
                            <Form.Item
                                label="备注类型"
                                name='remarkType'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择备注类型!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='请选择备注类型'
                                    style={{ width: 224, marginLeft: 14 }}
                                >
                                    {
                                        this.state.getRemarkType && this.state.getRemarkType.length != 0 ?
                                            this.state.getRemarkType.map(v => (
                                                <Option
                                                    value={v.code}
                                                    key={v.code}>{v.name}</Option>
                                            )) : ''
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="备注内容"
                                name='remark'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入备注内容',
                                    },
                                    {
                                        type: 'string',
                                        max: 100,
                                        message: '请输入100字以内',
                                    }
                                ]}
                            >
                                <Input.TextArea
                                    placeholder="请输入备注内容"
                                    style={{ width: 562, marginLeft: 14, height: 169 }}
                                />
                            </Form.Item>

                            <Form.Item
                                name="isWarning"
                                label="是否警示"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择是否警示',
                                    },
                                ]}
                            >
                                <Radio.Group
                                    style={{ marginLeft: 14 }}
                                >
                                    <Radio value="1">警示</Radio>
                                    <Radio value="0">非警示</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                style={{
                                    textAlign: 'right',
                                    width: '100%',
                                    marginBottom: 0,
                                    marginTop: 40,
                                }}
                                wrapperCol={24}
                            >
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={this.state.btnDisabled}
                                >
                                    确认
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
                
            </>
        );
    }
}

export default RemarksModal;