import React from "react";
import utils from '../../../util/utils';
import { Modal, Upload, Image, ConfigProvider, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { GetCaseSts } from '../../../services/data/DataService';

import zh_CN from 'antd/lib/locale-provider/zh_CN';
import OSS from "ali-oss";
let client = null;
let timerId;

class Photo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            fileList: props.logo ? [{ url: props.logo }] : [],
            setVedioProgress: [null],
            ossData: {},
            previewVisible: false,
            previewImage: '',
            previewTitle: ''
        }
    }

    componentDidMount() {
        this.getOssData()
    }
    /**
     * 提取上传类型
     * @param json 
     * @returns 
     */
    filterPathPre = (json, type) => {
        let newJson = json;
        let data = [];
        data = newJson.pathPre.split(',')
        data.map((i) => {
            if (i.includes(type)) {
                newJson.pathPre = i
            }
        })

        return newJson
    }

    /**
     * 获取OSS访问令牌
    */
    getOssData = () => {
        let loading = sessionStorage.getItem("loadingOssToken");
        if (loading == "true") {
            setTimeout(this.getOssData, 500);
            return;
        } else {
            sessionStorage.setItem("loadingOssToken", "true");
        }

        GetCaseSts().then(res => {
            if (res.code == 0) {
                sessionStorage.setItem("ossToken", JSON.stringify(res.data));
                sessionStorage.setItem("loadingOssToken", "false");
                if (!res.data) {
                    return
                }
                this.setState({
                    ossData: this.filterPathPre(res.data, 'image')
                })

                timerId = setTimeout(() => {
                    sessionStorage.removeItem("ossToken");
                }, 3600 * 1000)

                client = new OSS({ ...this.filterPathPre(res.data, 'image') });
            }
        })
    };


    //图片预览
    handlePreview = (e) => {
        if (e.url) {
            this.setState({
                previewImage: e.url
            })


        } else if (e.response) {
            this.setState({
                previewImage: e.response.data.url
            })
        }
        this.setState({
            previewVisible: true,
            previewTitle: "图片预览"
        })
    }

    handleChange = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        let start = Date.now();
        reader.onloadend = (e) => {
            const suffix = file.name.slice(file.name.lastIndexOf('.'));
            let time = new Date().getTime();
            console.log(this.state.ossData.pathPre)
            const path = `${this.state.ossData.pathPre}/activity/${time}${suffix}`;
            // oss上传
            client.multipartUpload(
                path,
                file,
                {
                    // 获取分片上传进度、断点和返回值。
                    progress: (p, cpt, res) => {
                        let timer;
                        if (p > 0) {
                            timer = Math.floor((Date.now() - start) * (1 - p) / p / 1000);
                            timer = timer > 60 ? Math.floor(timer / 60) + "分" + timer % 60 + "秒" : timer + "秒";
                        } else {
                            timer = '计算中';
                        }
                        this.setState({
                            vedioProgress: {
                                pre: Math.floor(p * 1000) / 10 + '%',
                                rt: Math.floor((res?.rt || 0) / 100) + 'kb/s',
                                timer: timer
                            }
                        })

                    },
                    // 设置并发上传的分片数量。
                    parallel: 4,
                    // 设置分片大小。默认值为1 MB，最小值为100 KB。
                    partSize: 1024 * 1024 * 10,
                    mime: "text/plain",
                    timeout: 600 * 1000
                }
            ).then((res) => {
                this.setState({
                    vedioProgress: null
                })

                if (res.res.status !== 200) {
                    message.error('上传失败，请重试！')
                    return;
                }
                let url = res.res.requestUrls[0];

                if (url) {
                    if (url.indexOf("?") >= 0) {
                        url = url.substring(0, url.indexOf("?"));
                    }
                    this.props.onChange(url)
                    this.setState({
                        fileList: [{ url: url, uid: file.uid }]
                    })
                }

            }).catch((err) => {
                message.error('上传失败，请重试！')
            })
        }
    }



    handleCancel = () => {
        this.setState({
            previewVisible: false
        })
    }



    removeChange = (file) => {

        console.log(this.state.fileList)
        let _fileList = this.state.fileList.filter((v) => v.uid !== file.uid)

        this.setState({
            fileList: _fileList
        })
        this.props.onChange()
    }

    render() {
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>点击上传图片</div>
            </div>
        );

        const { fileList, vedioProgress, ossData, previewVisible, previewImage, previewTitle, visible } = this.state

        return (
            <div className="photoCom" >
                {
                    < div >
                        <Upload
                            customRequest={() => { }}
                            accept="image/*"
                            // headers={utils.getHeaders(extraHeader)}
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={this.handlePreview}
                            beforeUpload={this.handleChange}
                            onRemove={this.removeChange}

                        >
                            {fileList.length >= 1 ? null : uploadButton}
                        </Upload>
                        {vedioProgress && vedioProgress.pre && (
                            <div>
                                <div>离开页面会导致上传失败，请耐心等待上传完成</div>
                                <div className={"item"}>剩余时间：{vedioProgress.timer}</div>
                                <div className={"item"}>上传进度：{vedioProgress.pre}</div>
                                <div className={"item"}>上传速度：{vedioProgress.rt}</div>
                            </div>
                        )}
                        <Modal
                            visible={previewVisible}
                            title={previewTitle}
                            footer={null}
                            // zIndex={99999}
                            onCancel={this.handleCancel}
                        >
                            <ConfigProvider locale={zh_CN}>

                                <Image
                                    width={'100%'}
                                    src={previewImage}
                                    preview={{ visible: false }}
                                    onClick={() => this.setState({
                                        visible: true
                                    })}
                                />

                                <div style={{ display: 'none' }}>
                                    <Image.PreviewGroup preview={{
                                        visible, onVisibleChange: vis => this.setState({
                                            visible: vis
                                        })
                                    }}>
                                        <Image src={previewImage} />
                                        {
                                            fileList && fileList.length > 0 && fileList.map((file) => (
                                                previewImage != file.url && <Image src={file.url} />
                                            ))
                                        }
                                    </Image.PreviewGroup>
                                </div>
                            </ConfigProvider>
                        </Modal>
                    </div >
                }
            </div >
        )
    }
}
export default Photo
