/**
 * 审核操作记录
 */
import React from 'react';
import './index.less';
import { Tabs, Select, DatePicker, Button, Input } from "antd";
import CaseAuditList from "./list";
import 'moment/locale/zh-cn';
import moment from 'moment';
import { GetProjectEnume } from '../../services/approveRecord/ApproveRecordService';
import { DownloadOutlined } from '@ant-design/icons';
import utils from "../../util/utils";
import locale from 'antd/es/date-picker/locale/zh_CN';

const { TabPane } = Tabs;
const { Option } = Select;
let _this;

const reportTypeList = [
    { key: 1, text: "全部病例", remark: "" },
]
class DctApproveRecord extends React.Component {

    constructor(props) {
        super(props);
        //  创建ref容器
        this.childRef = React.createRef();

        _this = this;
        _this.tenantProjectMap = {};
        _this.state = {
            selectTabPane: '1', // 报表类型 1DAY,2WEEK,3MONTH
            projects: [], // 项目枚举
            approveDate: '', // 检索日期
            approveUser: '', // 审核人
            doctorCode: '', // 医生账号
            projectCode: '', // 项目code
        }
    }

    componentDidMount() {
        this.getProjectEnume();
    }

    // 获取项目枚举
    getProjectEnume = () => {
        GetProjectEnume().then(res => {
            if (res) {
                this.setState({
                    projects: res
                })
            }
        })
    }

    /**
     * 切换tab
     */
    onTabClick = (val) => {
        this.setState({
            selectTabPane: Number(val)
        })
    }

    // 选择项目
    handleSelectChange = (value) => {
        this.setState({
            projectCode: value
        })
        this.childRef.current.getPageData(1, {
            projectCode: value,
            approveUser: this.state.approveUser,
            doctorCode: this.state.doctorCode,
            approveDate: this.state.approveDate,
        })
    }

    // 审核人
    handleApproveUserChange = (e) => {
        let value = e.currentTarget.value;
        this.setState({
            approveUser: value
        })
        this.childRef.current.getPageData(1, {
            approveUser: value,
            projectCode: this.state.projectCode,
            doctorCode: this.state.doctorCode,
            approveDate: this.state.approveDate,
        })
    }

    // 医生账号
    handleDoctorChange = (e) => {
        let value = e.currentTarget.value;
        this.setState({
            doctorCode: value
        }, () => {
            this.childRef.current.getPageData(1, {
                doctorCode: value,
                projectCode: this.state.projectCode,
                approveUser: this.state.approveUser,
                approveDate: this.state.approveDate,
            })
        })
    }

    // 选择日期
    onDateChange = (date, dateString) => {
        this.setState({
            approveDate: dateString
        }, () => {
            this.childRef.current.getPageData(1, {
                approveDate: dateString,
                projectCode: this.state.projectCode,
                approveUser: this.state.approveUser,
                doctorCode: this.state.doctorCode,
            })
        })
    };

    render() {
        let { selectTabPane, projectCode, projects, approveDate, approveUser, doctorCode } = this.state;

        return (
            <div className={'dctRecord'}>
                <div className='tabsTitle'>
                    <div className='search'>
                        {/* 按照项目、审核人、医生账号、审核时间筛选 */}
                        <DatePicker
                            onChange={this.onDateChange}
                            locale={locale}
                            value={(approveDate || approveDate != '') ? moment(approveDate, 'YYYY-MM-DD') : null}
                        />&nbsp;&nbsp;&nbsp;

                        <Input
                            placeholder="请输入审核人"
                            onChange={this.handleApproveUserChange}
                            size='middle'
                            allowClear
                            value={approveUser}
                        />&nbsp;&nbsp;&nbsp;

                        <Input
                            placeholder="请输入医生账号"
                            onChange={this.handleDoctorChange}
                            size='middle'
                            allowClear
                            value={doctorCode}
                        />&nbsp;&nbsp;&nbsp;

                        <Select
                            showSearch
                            defaultValue="所有项目"
                            value={projectCode}
                            onChange={this.handleSelectChange}
                            size='middle'
                            style={{ minWidth: '30%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            allowClear
                        >
                            <Option value={''}>所有项目</Option>
                            {projects && projects.length > 0 && projects.map(project => (
                                <Option key={project.code} value={project.code}>{project.name}</Option>
                            ))}
                        </Select>
                    </div>
                    <Tabs defaultActiveKey="0" tabPosition='top' onTabClick={this.onTabClick}>
                        {reportTypeList.map(i => (
                            <TabPane tab={i.text} key={i.key} >
                                {
                                    selectTabPane == i.key && <CaseAuditList
                                        ref={this.childRef}
                                        {...this.props}
                                        type={selectTabPane}
                                        projectCode={projectCode}
                                        selectTabPane={selectTabPane}
                                        item={i}
                                    />
                                }
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default DctApproveRecord;