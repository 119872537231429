import React, { useCallback, useContext, useMemo, useRef } from "react"
import { Button, Col, Form, Input, Row, Select, TreeDataNode, TreeSelect } from "antd"
import { useEffect, useState } from "react"

import modStyl from './condition.module.scss'
import utils from "../../../../../util/utils"
import TenantConfiguration from "../../../../../util/tenantConfiguration"
import { AreaResult, DoctorResult, getAreaOptions, getDoctorOptions, getHospitalOptions, getStageOptions, QueryParams } from "../../../../../services/data/dashboard"
import { DataBoardContext } from "../index.context"

export const StatusEnum = {
  '': "全部",
  '1': '待回复',
  '2': '已回复',
  '3': '已关闭',
}

export type QuestionStatus = keyof typeof StatusEnum

type ConditionProps = {
  activeKey: string
  onSearch: (params: QueryParams)=> void
}

type OptionType = {
  label: string;
  value: string;
}

const colCols = {
  span: 6
}
const fieldCols = {
  labelCol: {
    span: 6
  },
  wrapperCol: {
    span: 18
  }
}

const Condition: React.FC<ConditionProps> = ({ activeKey, onSearch })=> {

  const ctx = useContext(DataBoardContext)

  //
  const areaCodes = useRef<string[]>([])

  // 所属区域
  const [areaOptions, setAreaOptions] = useState<AreaResult[]>([])
  // 研究中心
  const [hospitalOptions, setHospitalOptions] = useState<OptionType[]>([])
  // 研究者
  const [doctorOptions, setDoctorOptions] = useState<OptionType[]>([])
  // 患者治疗阶段
  const [stageOptions, setStageOptions] = useState<OptionType[]>()
  //
  const [loading, setLoading] = useState<boolean>(false)

  // 
  const targetName = useMemo(()=> TenantConfiguration.read('label'), [])

  const [form] = Form.useForm<QueryParams>()
  
  const fetchArea = async ()=> {
    const { data } = await getAreaOptions(ctx.projectCode)
    // const { data } = await getAreaOptions('PROJECT10000634')
    if (Array.isArray(data)) {
      const tran = (data: AreaResult[])=> {
        data.forEach((item: AreaResult)=> {
          item.label = item.name
          item.value = item.code
          if (Array.isArray(item.children)) {
            tran(item.children)
          }
        })
      }

      tran(data)

      setAreaOptions(data)
    }
  }

  const fetchHospitalOptions = async (areaCodes: string[] = [])=> {
    const { data } = await getHospitalOptions(ctx.projectCode, areaCodes)
    if (Array.isArray(data)) {
      setHospitalOptions(
        data.map(({ name, code })=> ({
          label: name,
          value: code
        }))
      )
    }
  }

  const fetchDoctorOptions = async (areaCodes: string[] = [], centerCode = '')=> {
    const { data } = await getDoctorOptions(ctx.projectCode, areaCodes, centerCode)
    if (Array.isArray(data)) {
      setDoctorOptions(
        data.map(({ name, code })=> ({
          label: name,
          value: code
        }))
      )
    }
  }

  const fetchStageOptions = async ()=> {
    const { data }  = await getStageOptions()
    if (Array.isArray(data)) {
      setStageOptions(
        data.map(({ name, code })=> ({
          label: name,
          value: code
        }))
      )
    }
  }

  const exportExcel = useCallback(()=> {
    const params = form.getFieldsValue()
    const URIs = [
      '',
      `data/dashboard/subject/export/${ctx.projectCode}`,
      `data/dashboard/page/export/${ctx.projectCode}`
    ]
    setLoading(true)
    utils.exportPostFun(
      URIs[Number.parseInt(activeKey)],
      {
        ...params,
        areaCodes: areaCodes.current
      },
      null,
      ()=> {
        setLoading(false)
      }
    )
  }, [activeKey])

  // 选择所属区域
  const onTreeSelect = (value: string, node: AreaResult)=> {
    
    const codes: string[] = []
    const pickCodes = (node: AreaResult)=> {
      codes.push(node.code)
      if (Array.isArray(node.children)) {
        node.children.forEach((child: AreaResult)=> {
          pickCodes(child)
        })
      }
    }

    pickCodes(node)

    areaCodes.current = codes

    // 清空医院和研究者条件
    // form.setFieldsValue({
    //   centerCode: '',
    //   doctorCode: ''
    // })
    // 重新获取医院和研究者
    // fetchHospitalOptions(codes)
    // fetchDoctorOptions(codes, '')
  }

  const onTreeClear = ()=> {
    areaCodes.current = []
    form.setFieldValue('areaCodes', [])

    // 清空医院和研究者条件
    // form.setFieldsValue({
    //   areaCodes: [],
    //   centerCode: '',
    //   doctorCode: ''
    // })
    // 重新获取医院和研究者
    // fetchHospitalOptions([])
    // fetchDoctorOptions([], '')
  }

  const onCenterChange = (value: string)=> {
    form.setFieldsValue({
      doctorCode: ''
    })

    fetchDoctorOptions(areaCodes.current, value)
  }

  const onFinish = (values: QueryParams)=> {
    onSearch({
      ...values,
      areaCodes: areaCodes.current
    })
  }

  const filterEach = (value: string, option: any)=> option.label.includes(value)

  useEffect(()=> {
    fetchArea()
    fetchHospitalOptions([])
    fetchDoctorOptions([], '')
    fetchStageOptions()
  }, [])

  return (
    <div className={modStyl.wrapper}>
      <Form onFinish={onFinish} form={form}>
        <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="areaCodes" label="所属区域">
              <TreeSelect onClear={onTreeClear} onSelect={onTreeSelect} treeData={areaOptions} style={{width: '100%'}} placeholder="请选择" allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="centerCode" label="研究中心">
              <Select filterOption={filterEach} options={hospitalOptions} placeholder="请选择" showSearch allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="doctorCode" label="研究者">
              <Select filterOption={filterEach} options={doctorOptions} placeholder="请选择" showSearch allowClear/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="subjectCode" label={`${targetName}编号`}>
              <Input placeholder={'请输入'+targetName+'姓名缩写/编号查找'} allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} name="stage" label="患者治疗阶段">
              <Select options={stageOptions} placeholder="请选择" allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button type="primary" htmlType="submit">搜索</Button>
            <Button htmlType="reset" style={{marginLeft: 10}}>重置</Button>
            {
              ['1', '2'].includes(ctx.tabIndex) && (
                <Button loading={loading} onClick={exportExcel} style={{marginLeft: 10}} type="link">导出数据</Button>
              )
            }
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Condition