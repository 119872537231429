import { requestGetRes, requestPostRes } from "../EdcService";

export type CreateLabelPayload = {
  id: number
  parentId: number
  labelType: 1 | 2 // 1: 内容标签 2：用户标签
  name: string
}

export type MessageListParams = {
  id: number;
  pageSize: number;
  sender: string;
  subjectCode: string;
  refresh: boolean;
}

export type DataType = {
  id: number
  name: string
  parentId: number
  labelType: 0 | 1
  labelLevel: number
  createTime: string
  modifyTime: string
  createUserName: string
  children: DataType[]
}

/**
 * 给患者留言
 * @param params 
 * @returns 
 */
export const createLabel = (payload: CreateLabelPayload)=> {
  return requestPostRes('/knowledge/label/create', payload)
}

// 编辑标签
export const updateLabel = (payload: CreateLabelPayload)=> {
  return requestPostRes('/knowledge/label/edit', payload)
}

// 删除标签
export const deleteLabel = (id: number)=> {
  return requestPostRes('knowledge/label/delete', { id })
}

// 查看标签
export const showLabel = (id: number)=> {
  return requestGetRes(`/knowledge/label/detail/${id}`)
}

export type LabelListParams = {
  name: string
}
export type LabelListResult = {
  page: number
  pageSize: number
  rows: DataType[]
  total: number
}
export const getLabelList = (params: LabelListParams, pageNum = 1, pageSize = 10)=> {
  return requestGetRes('/knowledge/label/list', {
    ...params,
    pageNum,
    pageSize
  }) as Promise<{ code: number, data: LabelListResult}>
}

export type LabelTopLevel = {
  id: number
  name: string
}
export type LabelTopLevelResult = {
  code: number
  message: string
  data: LabelTopLevel[]
}
export const getLabelListTopLevel = (type: number)=> {
  return requestPostRes(`/knowledge/label/first-level/${type}`)
}

export const labelTypeOptions = [{value: 1, label: '内容标签'}, {value: 2, label: '用户标签'}]

export const labelTypeTransform = (type: number)=> {
  return type === 1 ? '内容标签' : '用户标签'
}