/**
 * 派发任务
 */
import React, { Component } from 'react';
import { Button, message } from 'antd';
import beginImg from "../../static/images/begin.png";
import waiting from "../../static/images/waiting.png";
import { GetDctAssignTask } from "../../../../services/dct/DctService";
import '../../components/comm.less';
import './index.less';

let _this;

class ImgTaskComponent extends Component {

    constructor(props) {
        super(props);
        _this = this;
        this.state = {
            checkTextData: {
                title: '暂未开始审核图片',
                subTitle: '点击开始审核按钮，系统将为您分派图片审核任务',
                button: '开始审核',
                img: beginImg,
            },
        };
        this.timerCheck = null
    }

    componentDidMount() {
        
    }

    componentWillUnmount = () => {
        clearTimeout(this.timerCheck)
    }

    // 开始审核
    handleCheck = async (value) => {
        
        if (value == '开始审核') {
            this.setState({
                checkTextData: {
                    title: '等待系统派单中......',
                    subTitle: ' ',
                    button: '取消等待',
                    img: waiting
                }
            })
            this.startCheck()
        } else if (value == '取消等待'){
            clearTimeout(this.timerCheck)
            _this.setState({
                checkTextData: {
                    title: '暂未开始审核图片',
                    subTitle: '点击开始审核按钮，系统将为您分派图片审核任务',
                    button: '开始审核',
                    img: beginImg
                }
            })
        }
        
    }

    // 开始审核
    startCheck = async () => {
        const res = await GetDctAssignTask();
        if (res && res.code == 0) {
            if (!res.data || res.data.length == 0) {
                this.timerCheck = setTimeout(() => {
                    this.startCheck()
                }, 10000)
                return
            }
            clearTimeout(this.timerCheck)
            this.props.getStatus(res.data)
        } else {
            clearTimeout(this.timerEnter)
            _this.setState({
                checkTextData: {
                    title: '暂未开始审核图片',
                    subTitle: '点击开始审核按钮，系统将为您分派图片审核任务',
                    button: '开始审核',
                    img: beginImg
                }
            })
        }
    }

    render() {
        let { checkTextData } = this.state;
        return (
            <div className={'ImgTask_wrap'}>
                <img src={beginImg} width='25%' alt='' />
                <div className={'txt'}>{checkTextData.title}</div>
                <span className={'hint'}>{checkTextData.subTitle}</span>
                <Button type='primary' onClick={() => this.handleCheck(checkTextData.button)} className={'checkBtn'}>
                    {checkTextData.button}
                </Button>
            </div>
        )
    }
}
export default ImgTaskComponent