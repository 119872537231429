
import { getAgentUserInfo } from "../../../../../services/dct/visit"
import storageX, { Engine } from "../../../../../util/storageX"

type AgentInfo = {
  // 坐席分机
  agentDn: string
  // 坐席工号
  agentId: string
  // 坐席密码 MD5加密
  agentPwd: string
  // 企业ID
  entId: string
  // 外显号码
  disNumber: string
}

export enum EventEnum {
  EVENT_AGENT_NOTREADY = '置忙事件',
  EVENT_AGENT_READY = '置闲事件',
  EVENT_AGENT_LOGIN = '登陆成功事件',
  EVENT_AGENT_LOGIN_FAIL = '登陆失败事件',
  EVENT_WEBAGENT_LOGOUT = '登出成功事件',
  EVENT_WEBAGENT_LOGOUT_FAIL = '登出失败事件',
  EVENT_OUTBOUND_ALERTING_TP = '坐席振铃事件',
  EVENT_OUTBOUND_CONNECTED_TP = '坐席接听事件',
  EVENT_OUTBOUND_ALERTING_OP = '外呼客户振铃事件',
  EVENT_OUTBOUND_CONNECTED_OP = '坐席接听事件',
  EVENT_TP_DISCONNECT = '坐席挂断事件',
  EVENT_OP_DISCONNECT = '客户挂断事件',
}

const key = 'web-agent-v2'

const ssx = storageX(Engine.Session)

// 获取当前绑定坐席并缓存到本地
const getUserWebAgentInfo = async(): Promise<null | AgentInfo>=> {
  // 优先从本地缓存获取
  const userInfo = ssx.json().get(key) as AgentInfo
  if(userInfo) {
    return userInfo
  }

  // 本地缓存没有去请求接口
  const { code, data } = await getAgentUserInfo<AgentInfo[]>()
  if(code === 0) {
    if (Array.isArray(data) && data.length > 0) {
      const value = data.shift() as AgentInfo
      ssx.set(key, value)
      return value
    }
  }

  return null
}

export const start = async (eventHandler: (event: WebAgentNS.SimpleEvent<WebAgentNS.ConnectedEventExt>)=> void, resultHandler: (result: WebAgentNS.SimpleResult)=> void)=> {
  // 获坐席信息
  const agentInfo = await getUserWebAgentInfo()
  if (null === agentInfo) {
    throw new Error('未获取到坐席信息')
  }

  window.WebAgent.init({
    useLocal: false,
    isUseCChat: false,
    loadBootstrap: 'unload',
    webRTCConfig: {
      tag: 'video-container',
      ringFile: 'https://cloud.ccod.com:1443/webagent/WA/sounds/bell_ring2.wav',
      ringTag: 'belling-ring',
      localTag: 'local-video'
    },
    WaParams: {
      ui: false,
      sipUseCphone: false
    },
    ChatParams: {},
    callback() {
      window.WebAgent.removeEventHandler(eventHandler)
      window.WebAgent.removeResultHandler(resultHandler)
      window.WebAgent.registerEventHandler(eventHandler)
      window.WebAgent.registerResultHandler(resultHandler)
    }
  })
}

export const login = ()=> {
  const data = ssx.json().get(key) as AgentInfo
  const entId = '2411050003'
  const { agentId, agentPwd: agentPassword } = data
  
  window.WebAgent.multiChannelLogin(window.WebAgent, {
    entId,
    agentId,
    agentPassword,
    waAutoLoginResult: false,
    isForce: true,
    isLocking() {
      console.log('?locking', arguments)
    },
    unLocking() {
      console.log('?unlocking', arguments)
    }
  })
}

export const busy = ()=> window.WebAgent.multiChannelState.agentBusy()

export const ready = ()=> window.WebAgent.multiChannelState.agentReady()

export const logout = ()=> window.WebAgent.multiChannelLogout()

export const hangup = ()=> window.WebAgent.extend.hangup()

export const makeCall = (outCallNumber: string)=> {
  window.WebAgent.extend.makeCall({
    isIntellectCall: true,
    outCallNumber,
  })
}