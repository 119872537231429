import React from 'react';
import { Button, Select, DatePicker, Space, Table, Modal, Form, Input, Spin, message, Radio, Image, ConfigProvider } from 'antd';
import { InfoCircleOutlined, FileTextOutlined } from '@ant-design/icons';
import {
    GetCsoOrderList, GetUserSelectsByName, GetCSOorderDetail, getCsoProjectList,
    getCsoDoctorList, getCsoPatientList, getDesensitizationOrderList
} from '../../../services/cso/CsoService'
import { getTenantList } from "../../../services/system/UserService";
import './index.less'
import locale from 'antd/es/date-picker/locale/zh_CN';
import utils from '../../../util/utils';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import UserIcon from '../../../static/csoImages/user_icon.png'
import FileText from '../../../static/csoImages/file_text.png'
import FilePlus from '../../../static/csoImages/file_plus.png'
import BottomArrow from '../../../static/csoImages/bottom_arrow.png'
import TopArrow from '../../../static/csoImages/bottom_top.png'
import EditFileText from '../../../static/csoImages/edit_file_text.png'
import customer from '../../../static/csoImages/customer.png'
import ask from '../../../static/csoImages/ask.png'
import no_record from '../../../static/csoImages/no_record.png'
import $ from 'jquery';
import { debounce } from 'lodash';
import { BASE_URL } from '../../../comm/config';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

let typeOptions = [{
    label: '荐方',
    value: '1'
}, {
    label: '问诊',
    value: '2'
}, {
    label: '开方',
    value: '3'
}, {
    label: '下单',
    value: '4'
}, {
    label: '支付',
    value: '5'
}, {
    label: '发货',
    value: '6'
},  {
    label: '收货',
    value: '7'
}]

let roleOptions = [{
    label: '医生',
    value: '1'
}, {
    label: '药师',
    value: '2'
}, {
    label: '护士',
    value: '3'
}, {
    label: '非医疗专业人士',
    value: '4'
}]

const processName = ['荐方', '问诊', '开方', '下单', '支付', '发货', '收货'];
const processList = [
    {
        processCode: 1,
        processState: 3,
        processName: '荐方',
    },
    {
        processCode: 2,
        processState: 3,
        processName: '问诊',
    },
    {
        processCode: 3,
        processState: 3,
        processName: '开方',
    },
    {
        processCode: 4,
        processState: 3,
        processName: '下单',
    },
    {
        processCode: 5,
        processState: 3,
        processName: '支付',
    },
    {
        processCode: 6,
        processState: 3,
        processName: '发货',
    },
    {
        processCode: 7,
        processState: 3,
        processName: '收货',
    }
];

class Research extends React.Component {

    constructor(props) {
        super(props);
        this.lastFetchId = 0;
        this.listTimeOut = null;
        this.detailTimeOut = null;
        this.fetchUser = debounce(this.fetchUser, 800);
        this.fetchDoctorUser = debounce(this.fetchDoctorUser, 800);
        this.fetchPatientUser = debounce(this.fetchPatientUser, 800);
        this.fetchPatientList = debounce(this.fetchPatientList, 800);
        this.fetchDoctorList = debounce(this.fetchDoctorList, 800);
    }


    state = {
        userList: [],
        doctorList: [],
        patientList: [],
        fetching: false,
        typeOptions: typeOptions,
        roleOptions: roleOptions,
        tenantOptions: [],
        loading: false,
        createUser: null, // 创建人手机号
        doctorName: null, // 所属医生 (姓名)
        patientCode: null, // 所属患者 (手机号)
        patientName: null, // 所属患者 (名称)
        endDate: null, // 结束时间
        processState: null, // 当前状态
        startDate: null, // 开始时间
        createDate: null, // 创建时间
        tenantCode: '',
        code: null, // 所属项目
        identityType: null, // 身份
        orderId: null, // 身份
        page: 1,
        pageSize: 10,
        pageList: [1],
        data: [],
        totalRow: 0,

        visible: false,
        visible2: false,
        visible3: false,
        visible4: false,

        patientInfo: null,
        orderInfo: null,

        createUser: null, 
        createUserLfv: null, 
        outPatientFirstName: null, 
        outPatientLastName: null, 
        doctorLfv: null, 
        doctorFirstName: null, 
        doctorLastName: null,

        arrow1: true, //true是展开 false是折叠
        arrow2: true,
        arrow3: true,
        arrow4: true,
        radio1: null,
        radio2: null,
        radio3: null,
        textArea: '',
        recordList: [1, 2, 3],
        nowRecord: {}, // 当前点击的条目

        dStartDate: '', //下载开始时间
        dEndDate: '', //下载结束时间
        downloadDate: ''
    }



    formRef = React.createRef();

    componentDidMount() {
        this.getProjectList()
        this.getData()
        // this.getDataLoop()
    }

    componentWillUnmount() {
        this.listTimeOut && clearTimeout(this.listTimeOut)
        this.detailTimeOut && clearTimeout(this.detailTimeOut)
    }

    //获取项目code列表
    getProjectList = () => {
        // getTenantList().then(res => {
        getCsoProjectList({
            page: 1,
            pageSize: 100 // 不传默认20条
        }).then(res => {
            let tenantOptions = []
            // res.forEach(v => {
            res.rows.forEach(v => {
                tenantOptions.push({
                    label: v.name,
                    value: v.code
                })
            });
            this.setState({
                tenantOptions
            })

        })
    }

    // 列表->因为进度更新等，需要5秒轮询
    getDataLoop = async () => {
        this.listTimeOut = setTimeout(async () => {
            const successRes = await this.getData({ needLoading: false })
            successRes && this.getDataLoop() // 成功返回时才可以继续查询以及更新
        }, 5 * 1000);
    }

    // 详情->因为进度更新等，需要5秒轮询
    getDetailLoop = (detailParam) => {
        this.detailTimeOut = setTimeout(() => {
            this.jumpDetail(detailParam)
            this.getDetailLoop(detailParam)
        }, 5 * 1000);
    }

    getData = (ops) => {
        return new Promise((resolve, reject) => {

            let needLoading = true;
            if (ops) {
                needLoading = ops.needLoading
            }
            let params = {
                page: this.state.page,
                pageSize: this.state.pageSize,
                orderId: this.state.orderId, 
                createUser: this.state.createUser, 
                createUserLfv: this.state.createUserLfv, 
                outPatientFirstName: this.state.outPatientFirstName, 
                outPatientLastName: this.state.outPatientLastName, 
                doctorLfv: this.state.doctorLfv, 
                doctorFirstName: this.state.doctorFirstName, 
                doctorLastName: this.state.doctorLastName,
            }

            this.setState({ loading: needLoading }, () => {
                getDesensitizationOrderList(params).then(res => {
                    if (res.code == 0) {
                        this.setState({
                            data: res.data.rows,
                            totalRow: res.data.total,
                            loading: false
                        })
                        resolve(true)
                    } else {
                        this.setState({
                            loading: false
                        })
                        resolve(false)
                    }
                })
            })
        });

    }

    changeDate = (date, dateString) => {
        this.setState({
            startDate: dateString[0],
            endDate: dateString[1],
            createDate: date
        })
    }



    fetchUser = value => {
        if (!value) {
            this.setState({ userList: [], fetching: false });
            return;
        }
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ userList: [], fetching: true });

        let params = {
            userName: value
        }

        GetUserSelectsByName(params).then(res => {
            if (fetchId !== this.lastFetchId) {
                // for fetch callback order
                return;
            }
            const data = res.data.map(user => ({
                text: user.userName,
                value: user.userCode
            }));
            this.setState({ userList: data, fetching: false });
        });
    };

    fetchDoctorUser = value => {
        if (!value) {
            this.setState({ doctorList: [], fetching: false });
            return;
        }
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ doctorList: [], fetching: true });

        let params = {
            page: 1,
            pageSize: 100,
            content: value
        }

        getCsoDoctorList(params).then(res => {
            if (fetchId !== this.lastFetchId) {
                // for fetch callback order
                return;
            }
            const data = res.rows.map(user => ({
                text: user.name,
                value: user.code
            }));
            this.setState({ doctorList: data, fetching: false });
        });
    };

    fetchPatientUser = value => {
        if (!value) {
            this.setState({ patientList: [], fetching: false });
            return;
        }
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ patientList: [], fetching: true });

        let params = {
            page: 1,
            pageSize: 100,
            content: value
        }

        getCsoPatientList(params).then(res => {
            if (fetchId !== this.lastFetchId) {
                // for fetch callback order
                return;
            }
            const data = res.rows.map(user => ({
                text: user.name,
                value: user.code
            }));
            this.setState({ patientList: data, fetching: false });
        });
    };

    fetchPatientList = value => {
        if (!value) {
            this.setState({ patientList: [], fetching: false });
            return;
        }
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;

        let params = {
            page: 1,
            pageSize: 100,
            content: value
        }

        getCsoPatientList(params).then(res => {
            if (fetchId !== this.lastFetchId) {
                // for fetch callback order
                return;
            }
            const data = res.rows.map(user => ({
                text: user.name,
                value: user.code
            }));
            this.setState({ patientList: data, fetching: false });
        });
    };

    fetchDoctorList = value => {
        if (!value) {
            this.setState({ doctorList: [], fetching: false });
            return;
        }
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;

        let params = {
            page: 1,
            pageSize: 100,
            content: value
        }

        getCsoDoctorList(params).then(res => {
            if (fetchId !== this.lastFetchId) {
                // for fetch callback order
                return;
            }
            const data = res.rows.map(user => ({
                text: user.name,
                value: user.code
            }));
            this.setState({ doctorList: data, fetching: false });
        });
    };

    wrapperClick = e => {
        this.setState({
            patientList: [],
            doctorList: []
        });
    }

    //患者下拉框改变
    handlePatientChange = data => {
        this.setState({
            patientCode: data ? data.value : null,
            patientName: data ? data.label : null,
            userList: [],
            patientList: [],
            fetching: false,
        });
    };
    //医生下拉框改变
    handleDoctorChange = data => {
        this.setState({
            doctorCode: data ? data.value : null,
            doctorName: data ? data.label : null,
            userList: [],
            doctorList: [],
            fetching: false,
        });
    };

    handlePatientInfoChange = event => {
        this.setState({
            patientCode: event.target.value,
            patientName: event.target.value
        });
        this.fetchPatientList(event.target.value);
    };

    handleDoctorInfoChange = event => {
        this.setState({
            doctorCode: event.target.value,
            doctorName: event.target.value
        });
        this.fetchDoctorList(event.target.value);
    };

    patientClick = (data) => {
        this.setState({
            patientCode: data ? data.value : null,
            patientName: data ? data.text : null,
            patientList: []
        });
    }

    doctorClick = (data) => {
        this.setState({
            doctorCode: data ? data.value : null,
            doctorName: data ? data.text : null,
            doctorList: []
        });
    }

    // 上一页筛选
    lastPageSearch = () => {
        const nowPageList = this.state.pageList;
        const lastPage = nowPageList.length == 1 ? nowPageList[0] : nowPageList[nowPageList.length - 2]; // 回到上一个页码
        const pageList = nowPageList.length == 1 ? nowPageList : nowPageList.slice(0, nowPageList.length - 1); // 截短最后一条
        this.setState({
            page: lastPage,
            pageList: pageList
        }, () => {
            this.getData()
        })
    }

    // 重置查询条件
    resetSearch = () => {
        this.setState({
            page: 1,
            code: null,
            processState: null,
            startDate: null,
            endDate: null,
            createDate: null,
            patientCode: null,
            patientName: null,
            doctorCode: null,
            doctorName: null,
            identityType: null,
            orderId: null
        }, () => {
            this.getData()
        })
    }

    handleSearch = () => {
        this.setState({
            page: 1
        }, () => {
            this.getData()
        })
    }


    changePage = (page) => {
        // pageList
        this.setState({
            pageList: [...this.state.pageList, page]
        });
        this.setState({ page }, () => {
            this.getData()
        })
    }
    changePageSize = (current, size) => {
        this.setState({ page: 1, pageSize: size }, () => {
            this.getData()
        })
    }


    jumpDetail = (data) => {
        let params = {
            orderCode: data.orderCode
        }
        this.setState({ nowRecord: data })
        GetCSOorderDetail(params).then(res => {
            if (res.code == 0) {
                let orderInfo = res.data.orderInfoDto;
                let orderProcessList = orderInfo.processVoList;
                let showData = orderProcessList;
                showData = processList.map((val) => {
                    // 寻找对应数据
                    let item = orderProcessList.find((item) => (item.processCode == val.processCode));
                    // 缺失用默认数据
                    return item ? item : val;
                });
                orderInfo.processVoList = showData;
                this.setState({
                    patientInfo: res.data.patientInfoDto,
                    orderInfo: orderInfo,
                    recommendInfo: res.data.recommendDtoList ? res.data.recommendDtoList[0] : {},
                    treatmentInfo: res.data.treatmentInfoDto,
                })
            }
        })
        // this.getDetailLoop(data);
        this.setState({
            visible: true
        })
    }

    operationRecordDetail = (data) => {
        this.setState({
            visible3: true
        })
    }

    radioOnChange = (e, type) => {
        const targetValue = [];
        targetValue[type] = e.target.value;
        this.setState(targetValue)
    };

    textAreaOnChange = (e) => {
        const targetValue = [];
        targetValue['textArea'] = e.target.value;
        this.setState(targetValue)
    };

    handleSubmit = () => {
        this.detailTimeOut && clearTimeout(this.detailTimeOut);
        this.setState({
            visible: false,
            visible2: false
        });
    }



    changeDownloadDate = (date, dateString) => {
        this.setState({
            dStartDate: dateString[0],
            dEndDate: dateString[1],
            downloadDate: date
        })
    }


    handleDownload = () => {
        let _this = this;
        let params = {
            startDate: this.state.dStartDate,
            endDate: this.state.dEndDate,
        }
        let tokenData = sessionStorage.getItem('token')
        tokenData = JSON.parse(tokenData);
        const url = BASE_URL + `order/operateManage/downloadOrder`;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.responseType = "blob";// 返回类型blob
        xhr.setRequestHeader("x-auth-token", tokenData.token);
        xhr.setRequestHeader("Content-Type", 'application/json;charset=UTF-8');
        xhr.onload = function () {
            if (this.status === 200) {
                let blob = this.response;
                let reader = new FileReader();
                reader.readAsDataURL(blob);    // 转换为base64，可以直接放入a表情href
                reader.onload = function (e) {
                    // 转换完成，创建一个a标签用于下载
                    var a = document.createElement('a');
                    a.download = '订单追踪.xls';
                    a.href = e.target.result;
                    $("body").append(a);// 修复firefox中无法触发click
                    a.click();
                    $(a).remove();
                    _this.setState({
                        dStartDate: '',
                        dEndDate: '',
                        downloadDate: '',
                        visible4: false
                    })
                }
            }
        };

        xhr.send(JSON.stringify(params));
    }

    render() {
        let { data, typeOptions, tenantOptions, visible, loading, patientName, doctorName, fetching, userList, createDate,
            arrow1, arrow2, arrow3, arrow4, patientInfo, orderInfo, recommendInfo, treatmentInfo, roleOptions,orderId, downloadDate,
            createUser, createUserLfv, outPatientFirstName, outPatientLastName, doctorLfv, doctorFirstName, doctorLastName,
            radio1, radio2, radio3, visible2, visible3, recordList, patientList, doctorList, visible4 } = this.state;
        const columns = [
            {
                title: '订单号',
                dataIndex: 'orderId',
                render: (text, record) =>
                    <div >
                        {record.orderId || '暂无信息'}
                    </div>
            },
            {
                title: '订单时间',
                dataIndex: 'orderTime',
                render: (text, record) =>
                    <div >
                        {record.orderTime || '暂无信息'}
                    </div>
            },
            {
                title: '创建人',
                width: 80,
                dataIndex: 'patientName'
            },
            {
                title: '创建人手机',
                dataIndex: 'patientCode'
            },
            {
                title: '就诊人',
                width: 80,
                dataIndex: 'outPatientName'
            },
            {
                title: '性别',
                width: 80,
                dataIndex: 'sex',
                render: (text, record) =>
                <div>
                    {text == 1 ? '男' : text == 2 ? '女' : '' }
                </div>
            },
            {
                title: '年龄',
                width: 80,
                dataIndex: 'age'
            },
            {
                title: '荐方医生',
                width: 120,
                render: (text, record) =>
                    <div>
                        {(record.doctorName || '') + `${record.identityName ? ` (${record.identityName})` : ''}`}
                    </div>
            },
            {
                title: '志愿者',
                width: 80,
                dataIndex: 'invitationUser',
                render: (text, record) =>
                    <div>
                        { record.invitationUser ? record.invitationUser : '无' }
                    </div>
            },
            {
                title: () => (
                    <div className='progress'>
                        <div>进度</div>
                        <div className='status'>
                            <span>荐方</span>
                            <span>问诊</span>
                            <span>开方</span>
                            <span>下单</span>
                            <span>支付</span>
                            <span>发货</span>
                            <span>收货</span>
                        </div>
                    </div>
                ),
                width: 460,
                dataIndex: 'processVoList',
                render: (data, record) => {
                    let orderProcessList = data;
                    let showData = orderProcessList;
                    showData = processList.map((val) => {
                        // 寻找对应数据
                        let item = orderProcessList.find((item) => (item.processCode == val.processCode));
                        // 缺失用默认数据
                        return item ? item : val;
                    });
                    while (showData.length < processName.length) {
                        showData.push({});
                    }
                    return (
                        <div className='block_list'>
                            {
                                (showData || []).map((val, index) =>
                                    <div key={index} className={`block ${val ? (val.processState == '1' ? 'success' : val.processState == '2' ? 'wait' : val.processState == '4' ? 'error' : val.processState == '5' ? 'refund' : 'notStarted'
                                    ) : 'notStarted'}`}>
                                        <div className="time">{val ? val.duringTime : ''}</div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            },
            {
                title: '发货地',
                dataIndex: 'storeName',
                render: (text, record) =>
                <div>
                    { record.storeName ? record.storeName : '暂无信息' }
                </div>
            }
        ];


        const pagination = {
            current: this.state.page,
            pageSize: this.state.pageSize,
            total: this.state.totalRow,
            showSizeChanger: true,
            onChange: this.changePage,
            onShowSizeChange: this.changePageSize
        }


        return (
            <>
                <div className='cso_info_record_container' onClick={this.wrapperClick}>
                    <div className='title'>请输入要搜索的内容</div>
                    <div className='search'>
                        <img className='customer' src={customer} />
                        <img className='ask' src={ask} />
                        <div className='search_con'>
                            <div className='search_item'>
                                <span className='label'>订单号</span>
                                <Input
                                    allowClear
                                    style={{ width: 280 }}
                                    value={orderId}
                                    placeholder="请输入要搜索的订单号"
                                    onChange={e => this.setState({ orderId: e.target.value })}
                                >
                                </Input>
                            </div>
                            <div className='search_item'>
                                <span className='label'>创建人手机号</span>
                                <Input
                                    allowClear
                                    style={{ width: 280 }}
                                    value={createUser}
                                    placeholder="请输入完整的手机号"
                                    onChange={e => this.setState({ createUser: e.target.value })}
                                >
                                </Input>
                            </div>
                            <div className='line_gap'></div>
                            <div className='search_item'>
                                <span className='label'>创建人手机号后四位</span>
                                <Input
                                    allowClear
                                    style={{ width: 280 }}
                                    value={createUserLfv}
                                    placeholder="请输入手机号后四位"
                                    onChange={e => this.setState({ createUserLfv: e.target.value })}
                                >
                                </Input>
                            </div>
                            <div className='search_item'>
                                <span className='label'>就诊人姓名</span>
                                <div className='search_item_input_pre_suffix'>
                                    <Input
                                        allowClear
                                        style={{ width: 110 }}
                                        value={outPatientFirstName}
                                        placeholder="第一个字"
                                        onChange={e => this.setState({ outPatientFirstName: e.target.value })}
                                    >
                                    </Input>
                                    <div style={{margin: '0 10px'}}>*</div>
                                    <Input
                                        allowClear
                                        style={{ width: 145 }}
                                        value={outPatientLastName}
                                        placeholder="最后一个字"
                                        onChange={e => this.setState({ outPatientLastName: e.target.value })}
                                    >
                                    </Input>
                                </div>
                            </div>
                            <div className='search_item'>
                                <span className='label'>荐方人手机号后四位</span>
                                <Input
                                    allowClear
                                    style={{ width: 280 }}
                                    value={doctorLfv}
                                    placeholder="请输入手机号后四位"
                                    onChange={e => this.setState({ doctorLfv: e.target.value })}
                                >
                                </Input>
                            </div>
                            <div className='search_item'>
                                <span className='label'>荐方人姓名</span>
                                <div className='search_item_input_pre_suffix'>
                                    <Input
                                        allowClear
                                        style={{ width: 110 }}
                                        value={doctorFirstName}
                                        placeholder="第一个字"
                                        onChange={e => this.setState({ doctorFirstName: e.target.value })}
                                    >
                                    </Input>
                                    <div style={{margin: '0 10px'}}>*</div>
                                    <Input
                                        allowClear
                                        style={{ width: 145 }}
                                        value={doctorLastName}
                                        placeholder="最后一个字"
                                        onChange={e => this.setState({ doctorLastName: e.target.value })}
                                    >
                                    </Input>
                                </div>
                            </div>
                        </div>
                        <div className='search_btn'>
                            <div>
                                <Button type="primary" style={{ width: 88, marginRight: 6, borderRadius: '8px' }} onClick={this.handleSearch}>查询</Button>
                            </div>
                            <div>
                                <Button type="hollow" style={{ width: 88, marginRight: 6, borderRadius: '8px', marginTop: 16, color: '#1890ff', border: '#1890ff 1px solid' }} onClick={this.resetSearch}>重置</Button>
                            </div>
                        </div>
                    </div>

                    <div className='cpds_content'>
                        <Spin tip="数据加载中..." spinning={loading}>
                            {
                                data.length > 0 ?
                                <Table rowKey={(record) => record.id} dataSource={data} columns={columns} pagination={pagination} scroll={{ x: 'calc(700px + 50%)' }} />
                                :
                                <div className='no_record'>
                                    <img src={no_record} />
                                    <div className='text'>
                                        暂无搜索结果
                                    </div>
                                </div>
                            }
                        </Spin>
                    </div>

                </div>
            </>
        )

    }

}

export default Research