import {STATE_SELECT_LIST, META_STATE_AND_TYPE_SELECT_LIST, CODESET_SELECT_LIST} from './actionTypes';
import {DictionarySearchSelect} from '../../../services/config/ConfigService'

import {fromJS} from 'immutable';

const stateSelectListAction = (stateSelectList, typeSelectList) => ({
    type: STATE_SELECT_LIST,
    stateSelectList: fromJS(stateSelectList),
    typeSelectList: fromJS(typeSelectList),
})

export const dataThemeSelectListAction = (stateSelectList, typeSelectList) => ({
    type: META_STATE_AND_TYPE_SELECT_LIST,
    dataThemeStateSelectList: fromJS(stateSelectList),
    dataThemeTypeSelectList: fromJS(typeSelectList),
})

export const codesetSelectListAction = (stateSelectList, typeSelectList, useScopeSelectList) => ({
    type: CODESET_SELECT_LIST,
    codesetStateSelectList: fromJS(stateSelectList),
    codesetTypeSelectList: fromJS(typeSelectList),
    codesetUseScopeSelectList: fromJS(useScopeSelectList),
})

/**
 * 字典 状态 下拉集合
 * @param stateList
 * @returns {function(*): void}
 */
export const getStateSelectListAction = () => {
    return (dispatch) => {
        let stateSelectList = [];
        let typeSelectList = [];
        DictionarySearchSelect().then(res => {
            const {stateEnumList, typeEnumList} = res;
            stateEnumList.forEach(x => {
                if (x == "USE") {
                    stateSelectList.push({code: x, message: "使用中"});
                } else if (x == "UNUSE") {
                    stateSelectList.push({code: x, message: "禁用"});
                }
            });
            typeEnumList.forEach(x => {
                if (x == "LESSVALUE") {
                    typeSelectList.push({code: x, message: "租户值域字典"});
                } else if (x == "PLATVALUE") {
                    typeSelectList.push({code: x, message: "平台值域字典"});
                } else if (x == "FUNC") {
                    typeSelectList.push({code: x, message: "功能字典"});
                } else if (x == "PLAT") {
                    typeSelectList.push({code: x, message: "平台字典"});
                }
            });
            const action = stateSelectListAction(stateSelectList, typeSelectList)
            dispatch(action)
        })
    }
}



