/**
 * 统计数据
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './index.less';
import { Button, Input, Select, Tabs, message, Row, Col, Switch, List, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
let _this;
const { Option } = Select;

class DataComponent extends Component {

    constructor(props) {
        super(props);
        _this = this;
        this.state = {

        };
        this.inputEl = React.createRef();
    }

    componentDidMount() {

    }

    render() {
        let { data } = this.props;
        return (
            <div className={'data_wrap'}>
                {
                    data && data.length > 0 && data.map((i, index) => {
                        return (
                            <>
                                <div className={'cols-con'} key={index}>
                                    <div className={'subjects-left'}>
                                        <div className={'item_icon'}>
                                            <img src={i.icon} alt='' width='100%' />
                                        </div>
                                    </div>
                                    <div className={'subjects-right'}>
                                        <div className={'number'} style={{ color: i.color }}>{i.number ? i.number : 0}</div>
                                        <div className={'name'}>{i.name}</div>
                                    </div>
                                </div>
                                <div className={'rline'}></div>
                            </>
                        )
                    })
                }
            </div>
        )
    }
}
export default DataComponent