import React, { Component } from 'react';
import { Modal, Tabs } from 'antd';
import Add from '../add/add.js';
import List  from '../list/list.js';
import './modal.less';

const TabPane = Tabs.TabPane;

class MyModal extends Component {

    constructor(props) {
        super(props);
        const { nowRecord } = props;
        let tabsData = [
            { title: '已绑定医生' },
            { title: '新增绑定' }
        ];
        if (nowRecord.type == 1) {
            // 公开项目不能新增绑定
            tabsData = [
                { title: '已绑定医生' }
             ];
        }
        this.state = {
            activeKey: '0',
            tabsData: tabsData
        }
    }

    /**
     * 关闭
     */
    handleCancel = () => {
        this.props.closeModal();
    };

    // 切换tab
    handleTabs = (value) => {
        this.setState({
            activeKey: value
        });
    }

    render() {
        const { visible } = this.props;
        let { tabsData, activeKey } = this.state;
        return (
            <Modal
                    className={'project_manage_modal'}
                    visible={visible}
                    title=""
                    onCancel={this.handleCancel}
                    width={'1300px'}
                    centered
                    destroyOnClose
                    footer={null}
                >
                    <div style={{ paddingBottom: 20,overflow: 'auto' }}>
                        <Tabs defaultActiveKey={activeKey} onChange={this.handleTabs}>
                            {tabsData.map((item, index) => (
                                <TabPane tab={item.title} key={index}>
                                    {
                                        activeKey == '0' ?
                                        <List projectCode={this.props.projectCode} closeModal={this.handleCancel} />
                                        :
                                        <Add projectCode={this.props.projectCode} closeModal={this.handleCancel} />
                                    }
                                </TabPane>
                            ))}
                        </Tabs>
                    </div>
                </Modal>
        );
    }
}

export default MyModal;
