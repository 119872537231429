import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Input, Button, message } from 'antd';
import { getEvidenceSubjectList, getLgSubjectDetail, getLgVisit } from '../../../services/project/ProjectService'
import './index.less'
import VIDEOSOLICIT from '../../../static/images/videosolicit.png';
import CASESOLICIT from '../../../static/images/casesolicit.png';
import QUESTIONSURVEY from '../../../static/images/questionsurvey.png';
import LISTEDSTYDY from '../../../static/images/listedstydy.png';
import Not_settlementEvidence from '../../../static/images/not_settlementEvidence.png';

import ICON_VIDEOSOLICIT from '../../../static/images/iconVideosolicit.png';
import ICON_CASESOLICIT from '../../../static/images/iconCasesolicit.png';
import ICON_QUESTIONSURVEY from '../../../static/images/iconQuestionsurvey.png';
import ICON_LISTEDSTYDY from '../../../static/images/iconListedstydy.png';
import LoadMore from '../../../static/images/load_more.png';
import Slider from "react-slick";
import PasscodeInput from './passcodeInput';
import { LeftCircleOutlined } from '@ant-design/icons';
import moment from 'moment'
import util from '../../../util/utils'
import VisitForm from './visitForm/visitForm';

const { Search } = Input

const SettlementEvidence = (props) => {

    const [visible, setVisible] = useState(true)
    const [passWord, setPassWord] = useState('')
    const [subjectCode, setSubjectCode] = useState('')
    const [page, setPage] = useState(1)
    const [subjectList, setSubjectList] = useState([])
    const [total, setTotal] = useState(0)


    const [type, setType] = useState('')  //项目类型
    const [subjectStatus, setSubjectStatus] = useState('')  //受试者状态
    const [subjectData, setSubjectData] = useState('')

    const [visitList, setVisitList] = useState([])
    const [currentVisit, setCurrentVisit] = useState(0)
    const [visitData, setVisitData] = useState(null)

    const [formList, setFormList] = useState([])

    const [showSignleForm, setShowSignleForm] = useState(false)
    const [viewForms, setViewForms] = useState(false)

    const [pageError, setPageError] = useState(false)

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
    }

    const codeInputRef = useRef()

    const getCodeNum = useCallback((code) => {
        setPassWord(code);
    }, []);

    useEffect(() => {
        const { pwd, uuid } = util.getUrlParams()

        if (!uuid) {
            setPageError(true)
        }

        if (pwd) {
            setVisible(false)
            setPassWord(pwd)
            getSubjectList(1, pwd)
        }
    }, [])


    //输入密码确定
    const handleSubmit = () => {
        getSubjectList()
    }


    const getSubjectList = (current, _passWord) => {
        const { uuid } = util.getUrlParams()
        if (uuid) {
            let params = {
                page: current ? current : page,
                pageSize: 6,
                subjectCode: subjectCode,
                passWord: _passWord ? _passWord : passWord,
                uuid: uuid
            }

            getEvidenceSubjectList(params).then(res => {
                if (res.code == 0) {
                    setPage(res.data.page)
                    setVisible(false)
                    setSubjectList(params.page == 1 ? res.data.rows : [...subjectList, ...res.data.rows])
                    setTotal(res.data.total)
                } else {
                    if (res.code == '10020410') {
                        setVisible(true)
                    } else if (res.code == '10020409') {
                        setPageError(true)
                    }
                }
            })
        }
    }

    //搜索
    const onSearch = () => {
        getSubjectList(1)
    }

    //加载更多
    const loadMore = () => {
        getSubjectList(page + 1)
    }


    //获取详情
    const getSubjectDetail = (data) => {
        let params = {
            subjectCode: data.subjectCode
        }

        getLgSubjectDetail(params).then(res => {
            if (res.code == 0) {
                setType(data.projectType)
                setSubjectStatus(data.status)

                setSubjectData(res.data)
                setVisitList(res.data.visits)

                // if ((data.projectType == 'LISTEDSTYDY' || data.projectType == 'CASESOLICIT') && res.data.visits.length > 0) {
                //     getVisitInfo(res.data.visits[0])
                // }
                // if (data.projectType == 'QUESTIONSURVEY' && res.data.visits.length > 0) {
                //     getVisitInfo(res.data.visits[0])
                // }
                if (res.data.visits.length > 0) {
                    getVisitInfo(res.data.visits[0])
                }
            }
        })
    }


    //获取visit访视节点
    const getVisitInfo = (data) => {
        let params = {
            id: data.id,
            sourceType: 'lg'
        }
        getLgVisit(params).then(res => {
            if (res.code == 0) {
                setVisitData(res.data)
                setFormList(res.data.forms)

                if (res.data && res.data.forms && res.data.type === 'SingleForm') {
                    clickForm(res.data, res.data.forms[0]);
                }
            }
        })
    }

    //切换访视节点
    const changeVisit = (index) => {
        if (index !== currentVisit) {
            getVisitInfo(visitList[index])
            setCurrentVisit(index)
        }
    }

    //返回
    const handleBack = () => {
        setType('')
        setShowSignleForm(false)
        setViewForms(false)
    }

    //点击表单
    const clickForm = (_visitData, formItem) => {

        let filterArr = [{
            title: formItem.name,
            id: formItem.id,
            code: formItem.code,
            inputState: formItem.inputState
        }];
        // for (let i of _visitData.forms) {
        //     filterArr.push({
        //         title: i.name,
        //         id: i.id,
        //         code: i.code,
        //         inputState: i.inputState
        //     })
        // }

        let forms = {
            activeId: formItem.id,
            state: _visitData.state,
            forms: filterArr,
        };
        sessionStorage.setItem('sessionStorage_forms', JSON.stringify(forms));

        // 单表单模式
        if (_visitData.type == 'SingleForm') {
            setShowSignleForm(true);
        } else {
            setViewForms(true);
        }

    }

    return (
        <div className='settlementEvidence_container'>
            <div className='evidenceContent'>
                <div className='title'>结算服务证据列表（共{total}条）</div>
                <div className='search'>
                    <Search placeholder='搜索服务名称' width={244} onSearch={onSearch} onChange={(e) => setSubjectCode(e.target.value)} />
                    {/* <div className='filter'>
                        <span>筛选</span>
                        <span className='icon'></span>
                    </div> */}
                </div>
                <div className='evidenceList' hidden={type}>
                    {
                        subjectList.map((item, index) => {
                            let itemClass = item.projectType == 'VIDEOSOLICIT' ? 'evidence_item videosolicit' :
                                item.projectType == 'QUESTIONSURVEY' ? 'evidence_item questionsurvey' :
                                    item.projectType == 'CASESOLICIT' ? 'evidence_item casesolicit' : 'evidence_item';

                            return (
                                <div className={itemClass} key={index} onClick={() => getSubjectDetail(item)}>
                                    <img className='item_image' src={item.projectType == 'VIDEOSOLICIT' ? VIDEOSOLICIT : item.projectType == 'QUESTIONSURVEY' ? QUESTIONSURVEY : item.projectType == 'CASESOLICIT' ? CASESOLICIT : LISTEDSTYDY} alt="" />
                                    <div className='item_info'>
                                        <div className='subjectCode'>{item.subjectCode}</div>
                                        <div className='subject_title'>{item.projectName}</div>
                                        <div className='subject_status'>
                                            <div className='status_left'>
                                                <span className='status_icon'></span>
                                                <span>{item.status == '1' ? '已完成' : item.status == '2' ? '审核通过' : ''}</span>
                                            </div>
                                            <div style={{ color: '#999999' }}>{moment(new Date(item.uploadTime)).format('YYYY-MM-DD')}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className='loadMore' onClick={loadMore} hidden={subjectList.length >= total}>
                        <img src={LoadMore} alt="" />加载更多
                    </div>

                </div>
                <div className='passcodeMask' hidden={!visible}>
                    <div className='mask_content'>
                        <div className='mask_header'>输入密码</div>
                        <div style={{ marginTop: 24 }}>
                            <PasscodeInput onChange={getCodeNum} ref={codeInputRef} />
                        </div>
                        <div className='button_content' onClick={handleSubmit}>
                            <Button type='primary'>确定</Button>
                        </div>
                    </div>
                </div>
                <div className='pageErrorMask' hidden={!pageError}>
                    <div className='mask_content'>
                        <img className='errorImg' src={Not_settlementEvidence} alt="" />
                        <div>证件不存在！</div>
                    </div>
                </div>

                <div className='evidenceDetail' hidden={!type}>
                    <div className='mask_header' onClick={handleBack}>
                        <LeftCircleOutlined />
                        <span>{type == 'LISTEDSTYDY' ? 'RWS' : type == 'CASESOLICIT' ? '病例征集' : type == 'QUESTIONSURVEY' ? '调研问卷' : type == 'VIDEOSOLICIT' ? '视频征集' : ''}</span>
                    </div>
                    {type == 'LISTEDSTYDY' && <div className='rws_content'>
                        <div className='evidenceInfo'>
                            <img src={ICON_LISTEDSTYDY} alt="" />
                            <div className='evidenceInfo_left'>
                                <div>{subjectData.code}</div>
                                <div className='p_name'>{subjectData.projectName}</div>
                            </div>
                            <div className='item_status'>
                                <span className='status_icon'></span>
                                <span>{subjectStatus == '1' ? '已完成' : subjectStatus == '2' ? '审核通过' : ''}</span>
                            </div>
                        </div>
                        <div className='form_content'>
                            <Slider {...settings} className='form_list'>
                                {
                                    visitList.map((item, index) => (
                                        <div className='nodeInfo_box' key={index}>
                                            <div className={currentVisit == index ? 'form_info active' : 'form_info'} onClick={() => changeVisit(index)}>
                                                <div className='visitName'>{item.name}</div>
                                                <div className='time'>{item.visitDate}</div>
                                            </div>
                                            <div className='block'></div>
                                        </div>
                                    ))
                                }
                            </Slider>

                            <div className='dotlist'>
                                {
                                    visitList.map((item, index) => (
                                        <span className={currentVisit == index ? 'dot current' : 'dot'} key={index}></span>
                                    ))
                                }
                            </div>
                            <div className='formNode_list'>
                                <div className='formNode_title'>{visitList.length > 0 && visitList[currentVisit].name}</div>
                                <div className='formNodes'>
                                    {
                                        formList.map((item, index) => {
                                            return (
                                                <div className='formNode' key={index} onClick={() => clickForm(visitData, item, index)}>
                                                    <div className='node_name'>{item.name}</div>
                                                    <div className='clock_icon'></div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>}

                    {type == 'CASESOLICIT' && <div className='casesolicit_content'>
                        <div className='evidenceInfo'>
                            <img src={ICON_CASESOLICIT} alt="" />
                            <div className='evidenceInfo_left'>
                                <div>{subjectData.code}</div>
                                <div className='p_name'>{subjectData.projectName}</div>
                            </div>
                            <div className='item_status'>
                                <span className='status_icon'></span>
                                <span>{subjectStatus == '1' ? '已完成' : subjectStatus == '2' ? '审核通过' : ''}</span>
                            </div>
                        </div>
                        <div className='form_content'>
                            <div className='formNode_list'>
                                <div className='formNode_title'>病例数据表</div>
                                <div className='formNodes'>
                                    {
                                        formList.map((item, index) => {
                                            return (
                                                <div className='formNode' key={index} onClick={() => clickForm(visitData, item, index)}>
                                                    <div className='node_name'>{item.name}</div>
                                                    <div className='clock_icon'></div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>}
                    {type == 'VIDEOSOLICIT' && <div className='videosolicit_content'>
                        <div className='evidenceInfo'>
                            <img src={ICON_VIDEOSOLICIT} alt="" />
                            <div className='evidenceInfo_left'>
                                <div>{subjectData.code}</div>
                                <div className='p_name'>{subjectData.projectName}</div>
                            </div>
                            <div className='item_status'>
                                <span className='status_icon'></span>
                                <span>{subjectStatus == '1' ? '已完成' : subjectStatus == '2' ? '审核通过' : ''}</span>
                            </div>
                        </div>
                        <div className='form_content'>
                            {showSignleForm && <VisitForm subjectsData={subjectData} projectType={type} />}
                        </div>
                    </div>}

                    {type == 'QUESTIONSURVEY' && <div className='questionsurvey_content'>
                        <div className='evidenceInfo'>
                            <img src={ICON_QUESTIONSURVEY} alt="" />
                            <div className='evidenceInfo_left'>
                                <div>{subjectData.code}</div>
                                <div className='p_name'>{subjectData.projectName}</div>
                            </div>
                            <div className='item_status'>
                                <span className='status_icon'></span>
                                <span>{subjectStatus == '1' ? '已完成' : subjectStatus == '2' ? '审核通过' : ''}</span>
                            </div>
                        </div>
                        <div className='question_content'>
                            {showSignleForm && <VisitForm subjectsData={subjectData} projectType={type} />}
                        </div>
                    </div>}
                </div>

                <div className='formViewDetail' style={{ display: viewForms ? '' : 'none' }}>
                    <div className='view_forms'>
                        {viewForms && <VisitForm subjectsData={subjectData} projectType={type} />}
                    </div>

                    <div className='closeFormBtn'>
                        <Button style={{ width: 200, height: 40, background: '#6c96f7', color: '#fff' }} onClick={() => setViewForms(false)}>返回</Button>
                    </div>
                </div>



            </div>

        </div>
    )
}

export default SettlementEvidence