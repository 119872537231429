import {DESIGN, SELECTSLISTS} from './actionTypes';
import {fromJS} from 'immutable';

const defaultState = fromJS({
    tipList: [],
    pageNo: 1,
    statusList: [],
    typeList: []
})

export default (preState = defaultState, action) => {
    switch (action.type) {
        case DESIGN:
            return preState.set('pageNo', action.pageNo);
        case SELECTSLISTS:
            return preState.set('statusList', action.statusList).set('typeList', action.typeList);
        // return preState.merge({
        //   focused: action.focused,
        //   cacheTipList: action.cacheTipList,
        //   totalPage: action.totalPage,
        //   tipList: action.tipList
        // })
        // return preState.set('focused', action.focused)
        //   .set('cacheTipList', action.cacheTipList)
        //   .set('totalPage', action.totalPage)
        //   .set('tipList', action.tipList) // 设置值，返回新的immutable对象
        default:
            return preState
    }

}