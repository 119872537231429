/**
 * 批量审核
 */
 import { requestPostFile, requestPost, requestGet, requestPostRes, requestGetRes, requestPatchRes,requestPostFileRes } from '../EdcService';

 const urlPrefix = "/data";

// 项目枚举 
export const GetProjectEnume = (params) => {
    const url = `${urlPrefix}/batchAudit/projectList`;
    return requestGet(url, params)
};

// 模版地址
export const GetBatchAuditModule = (params) => {
    const url = `${urlPrefix}/batchAudit/module`;
    return requestGet(url, params)
};

// 批量审核任务列表
export const GetBatchAuditTaskList = (params) => {
    const url = `${urlPrefix}/batchAudit/batchAuditTaskList`;
    return requestPostRes(url, params)
};

// 获取下载链接
export const GetBatchAuditTaskUrl = (params) => {
    const url = `${urlPrefix}/batchAudit/taskUrl`;
    return requestPostRes(url, params)
};

// 批量审核任务创建
export const BatchAuditCreateTask = (params) => {
    const url = `${urlPrefix}/batchAudit/createTask`;
    return requestPostFileRes(url, params)
};