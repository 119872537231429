import React, {
    memo,
    useImperativeHandle,
    forwardRef,
    useState,
    useRef,
    useCallback,
  } from "react";
  import styles from "./passcodeInput.module.less";
  
  export default memo(
    forwardRef(({ onChange }, ref) => {
      const inputRef = useRef(null);
      const fieldList = useRef(null);
      const [inputVal, setInputVal] = useState("");
  
      useImperativeHandle(ref, () => {
        return {
          changeInput: (val) => {
            changeHandle({
              target: {
                value: val,
              },
            });
          },
        };
      });
  
      const calcCursorPosition = useCallback(() => {
        // 这里需要获取ref的value值，不然onchange的时候会有异步问题
        const length = inputRef.current.value.length;
        if (length < 4) {
          fieldList.current.children[length].classList.add(
            styles["field-item-focus"]
          );
        }
      }, [inputVal]);
  
      const removeCursor = useCallback(() => {
        let newArr = Array.from(fieldList.current.children);
        newArr.forEach((item) => {
          if (
            Array.from(item["classList"]).some((r) =>
              r.includes("field-item-focus")
            )
          ) {
            item.classList.remove(
              Array.from(item["classList"]).find((r) =>
                r.includes("field-item-focus")
              )
            );
          }
        });
      }, [inputVal]);
  
      const changeHandle = useCallback(
        (e) => {
          // 监听input输入事件，只支持输入数字，过滤非数字字符
          let v = e.target.value.replace(/[^\d]/g, "");
  
          v = v.length > 4 ? v.substr(0, 4) : v;
          // 这里需要改变inputRef.currnet的值，不然input的值会超出四位
          inputRef.current.value = v;
          onChange && onChange(v); //传递给父组件
          setInputVal(v);
          // 考虑粘贴情况，循环赋值
          // 移除旧光标
          removeCursor();
          // 计算新光标出现位置
          calcCursorPosition();
        },
        [inputVal]
      );
  
      const blurHandle = useCallback(() => {
        removeCursor();
      }, [inputVal]);
  
      const focusHandle = useCallback(() => {
        calcCursorPosition();
      }, [inputVal]);
  
      return (
        <div className={styles.inputBox}>
          <div className={styles["field-list"]} ref={fieldList}>
            <div className={styles["field-item"]}>{inputVal[0]}</div>
            <div className={styles["field-item"]}>{inputVal[1]}</div>
            <div className={styles["field-item"]}>{inputVal[2]}</div>
            <div className={styles["field-item"]}>{inputVal[3]}</div>
          </div>
          <input
            ref={inputRef}
            className={styles["field-input"]}
            onChange={changeHandle}
            onBlur={blurHandle}
            onFocus={focusHandle}
            type="text"
          />
        </div>
      );
    })
  );
  
  