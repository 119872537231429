/**
 * 图片预览
 */
import React, { Component } from 'react';
import './index.less';
import { Button, Input, message, Row, Col, Switch, List, Modal, Image, ConfigProvider, Spin } from 'antd';
import {
    ExclamationCircleOutlined, QuestionCircleTwoTone, CheckCircleTwoTone,
    EyeOutlined, CoffeeOutlined, FundProjectionScreenOutlined, FacebookFilled
} from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import ImgCanvas from '../../../../img_canvas/drawCanvas.jsx';
import { GetDctApprovalOperation, CancelDctApprovalOperation } from "../../../../../services/dct/DctService";
import sensitiveinfo from '../../../static/images/sensitiveinfo.svg';

let _this;

class PreviewComponent extends Component {

    constructor(props) {
        super(props);
        _this = this;
        this.state = {
            isVisible: false, // 图片列表显隐
            visible: false,// modal显隐
            currId: null,
            ImgList: null,
            listItem: null, // 选中信息
            photoVisible: true, // 现在为脱敏，暂时不需要预览
            lastImgUrl: '',
        };
        this.currId = null
        this.rejectReason = '' // 拒绝原因
    }

    componentDidMount() {
        if (this.props.data) {
            let { data, listItem } = this.props;
            this.setState({
                ImgList: data,
                listItem
            })
            this.renderImgInfo(listItem || data[0])
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        
        if (nextProps.data) {
            this.setState({
                ImgList: nextProps.data,
                listItem: nextProps.listItem
            })
        }
    }

    // 预览
    onPreview = () => {
        this.setState({
            isVisible: true
        })
    }
    onVisibleChange = (val) => {
        this.setState({
            isVisible: val
        })
    }

    // 通过
    onChecked = async (val, values) => {
        let { currId } = this.state
        let params = {
            id: currId ? currId : this.currId
        }

        if (val === 'REJECT') {
            
            if (values && values.approveStatus == 'REJECT') {
                message.warn('该图片已质疑，请勿重复质疑！')
                return
            }

            this.setState({
                visible: true,
            });

            if (this.rejectReason == '') {
                return
            }

            params['rejectReason'] = this.rejectReason
            params['approveStatus'] = 'REJECT'

            this.passOperation(params)

        } else if (val === 'RUNNING') {
            this.setState({
                visible: false,
            });

            params['approveStatus'] = 'AGREE'
            this.passOperation(params)

        } else if (val === 'AGREE') { // 取消通过
            this.setState({
                visible: false,
            });
            this.CancelOperation(params)
        }
    }

    // 通过/质疑
    passOperation = async (params) => {
        const res = await GetDctApprovalOperation(params);
        if (res && res.code == 0) {
            message.success(res.message)
            this.rejectReason = ''
            _this.props.getValue(true)
        }
    }

    // 取消通过 
    CancelOperation = async (params) => {
        const res = await CancelDctApprovalOperation(params);
        if (res && res.code == 0) {
            message.success(res.message)
            _this.props.getValue(true)
        }
    }

    // 渲染图片信息
    renderImgInfo = (item) => {
        let ImgInfoData = []
        if (!item || item.length == 0) {
            return
        }
        ImgInfoData = [
            { label: '', value: <><span className="sign" hidden={!(item.desensitization == '1')}>需脱敏</span>{item.formName}</>, s: item.sensitiveInfo },
            { label: '所属项目：', value: item.projectName, s: item.sensitiveInfo },
            { label: '所属中心：', value: item.centerName, s: item.sensitiveInfo },
            { label: '采集人：', value: item.collector ? item.collector : item.collectorCode, s: item.sensitiveInfo },
            { label: `${sessionStorage.getItem('targetName') || '受试者'}：`, value: `${item.subjectName || ''}（${item.subjectCode}）`, s: item.sensitiveInfo },
            { label: '访视：', value: item.visitName, s: item.sensitiveInfo },
            { label: '表单：', value: item.formName, s: item.sensitiveInfo }
        ]
        this.currId = item.id
        return ImgInfoData
    }

    // 渲染图片信息
    renderInfoDom = (values) => {
        let dom = <>
            <div className="info_left">
                {this.renderImgInfo(values).map((item, index) => (
                   <div className={item.label == '' ? 'tit' : 'subtit'} key={index}>{item.label}{item.value}</div>
                ))}
            </div>

            <div className="operate_right">
                <img hidden={!(values.sensitiveInfo == '1')} src={sensitiveinfo} alt="" className="sensitiveinfo"/>
            </div>
        </>
        return dom
    }

    render() {
        let { ImgList, isVisible, listItem, photoVisible } = this.state;
        let activeImgUrl = listItem ? listItem.url : ImgList && ImgList[0].url;
        return (
            <div className={'preview_content'}>
                <div className="left_magnify">
                    <div className="magnify_wrap">
                        <ConfigProvider locale={zhCN}>
                            <div className="ImageBox">
                                {
                                    activeImgUrl &&
                                    <ImgCanvas
                                        ifGetDrawArea = {false}
                                        needUpdate = {true}
                                        imgData = {listItem ? listItem : ImgList[0]}
                                        imgUrl = {activeImgUrl}
                                />
                                }
                                {/* <Image
                                    preview={{ visible: false }}
                                    src={listItem ? listItem.url : ImgList && ImgList[0].url}
                                    onClick={this.onPreview}
                                /> */}
                            </div>
                        {
                            !photoVisible && 
                            <div style={{ display: 'none'}}>
                                <Image.PreviewGroup preview={{ visible: isVisible, onVisibleChange: vis => this.onVisibleChange(vis) }}>
                                    <Image src={listItem ? listItem.url : ImgList && ImgList[0].url} alt='' />
                                    {
                                        ImgList && ImgList.map((item, index) => (
                                            <Image src={item.url} alt='' />
                                        ))
                                    }
                                </Image.PreviewGroup>
                            </div>
                        }
                        </ConfigProvider>
                    </div>
                    <div className="info_wrap">
                        {ImgList && ImgList.length > 0 && this.renderInfoDom(listItem || ImgList[0])}
                    </div>
                </div>
            </div>
        )
    }
}
export default PreviewComponent