/**
 * 卫健委审核
 */
import React from 'react';
import './index.less';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
    Form, Input, Select, Button, message as Message, Table, message, Modal, DatePicker, Upload
} from "antd";
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { GetMemberSyncTaskList, GetMemberSyncTaskUrl, batchMemberSync } from "../../../services/apply/ApplyService";
import { createFromIconfontCN, UploadOutlined } from '@ant-design/icons';


const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});
let _this;
const { Option } = Select;


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

class DoctorApprove extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            dataLists: [], // 列表集合
            columns: [
                {
                    title: '创建人',
                    width: '8%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.createUser}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '任务名称',
                    width: '8%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.name}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '创建时间',
                    width: '',
                    render: (text, record, index) => {
                        if (record.createTime) {
                            return (
                                <div className={'con_div'}>
                                    <div className={'sub-title'}>{record.createTime ? record.createTime.replace('T', ' ') : ''}</div>
                                </div>
                            )
                        }
                    }
                },
                {
                    title: '完成时间',
                    width: '',
                    render: (text, record, index) => {
                        if (record.finishTime) {
                            return (
                                <div className={'con_div'}>
                                    <div className={'sub-title'}>{record.finishTime ? record.finishTime.replace('T', ' ') : ''}</div>
                                </div>
                            )
                        }
                    }
                },
                {
                    title: '状态',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div current_state'}>
                                {/* 0-生成中 1-已完成 */}
                                <Button type={record.taskState == '0' ? 'link' : 'text'}>
                                    {
                                        record.taskState == '0' ? '生成中' : '已完成'
                                    }
                                </Button>
                            </div>
                        )
                    }
                },
                {
                    title: '操作',
                    width: '',
                    render: (text, record) =>
                        <div
                            onClick={eve => {
                                eve.stopPropagation();
                            }}
                            className={'partPop'}
                        >
                            <Button type="link" onClick={() => this.downLoadData(record)} disabled={this.state.disableDownLoad || record.downloadState == '0'}>
                                下载数据
                            </Button>
                        </div>
                },
            ],// 表格 行 配置
            page: 1,//当前页码数
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo);
                },
                current: 1,
                pageSize: 10,
                total: 100,
                showSizeChanger: false,
            },
            isCreateVisible: false, // 创建数据下载任务模态框
            isDownVisible: false, // 下载数据模态框
            currentDate: null, // 检索日期
            rowId: '',
            disableDownLoad: false,

            fileList: [],
            uploadBtnFlag: false,
            noFileTips: false,//未选择文件提示
            uploadFlag: true,
        }
    }

    formRef = React.createRef();
    formRefDownLoad = React.createRef();
    inputRef = React.createRef();

    componentDidMount = () => {
        // 列表
        this.getPageData();
    }



    /**
     * 下载数据
     */
    downLoadData = async (record) => {

        if (this.formRefDownLoad.current) {
            this.formRefDownLoad.current.resetFields()
        }

        this.setState({
            isDownVisible: true,
            rowId: record.id,
            disableDownLoad: true
        })
    }

    /**
     * 下载数据-关闭
     */
    handleDownCancel = () => {
        this.setState({
            isDownVisible: false,
            disableDownLoad: false
        })
    }

    /**
     * 获取列表数据 
     */
    getPageData = async (curPage) => {
        let { page, currentDate } = this.state;

        const params = {
            page: curPage ? curPage : page,
            pageSize: this.state.pagination.pageSize,
            date: currentDate || ''
        }

        const res = await GetMemberSyncTaskList(params);
        if (res && res.code == 0) {
            _this.setState({
                dataLists: [...res.data.rows],
                page: res.data.page,
                pageSize: res.data.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })
        }
    }

    // 取消
    handleCancel = async () => {
        this.setState({
            isCreateVisible: false,
            uploadBtnFlag: false,
            noFileTips: false,
            fileList: []
        })
    }

    // form过滤空格
    getValueFromEvent = (event) => {
        return event.target.value.replace(/\s+/g, "")
    }

    // 创建下载任务
    createTasks = () => {
        if (this.formRef.current) {
            this.formRef.current.resetFields()
        }

        this.setState({
            isCreateVisible: true
        })
    }


    // 选择日期
    onDateChange = (date, dateString) => {
        this.setState({
            currentDate: dateString
        }, () => {
            this.getPageData()
        })
    };

    // 上传文件
    onSubmit = async (values) => {
        if (!this.state.uploadFlag) {
            return false
        }
        const { fileList } = this.state;
        if (fileList.length === 0) {
            this.setState({
                noFileTips: true
            })
            return;
        }
        const uploadFormData = new FormData();
        fileList.forEach(file => {
            uploadFormData.append('file', file);
        });

        const form = this.formRef.current;
        const formValues = form.getFieldsValue(
            ['passWord']);

        uploadFormData.append('pwd', formValues.passWord);
        this.setState({ uploadFlag: false })
        const res = await batchMemberSync(uploadFormData);
        if (res && res.code == 0) {
            message.success('上传成功！');
            _this.setState({
                isCreateVisible: false,
                uploadBtnFlag: false,
                noFileTips: false,
                fileList: [],
                uploadFlag: true
            }, () => {
                this.getPageData()
            })
        } else {
            _this.setState({
                uploadFlag: true
            })
        }
    }


    // 下载/提交
    onDownloadSubmit = async (values) => {
        const form = this.formRefDownLoad.current;
        const formValues = form.getFieldsValue(['passWord']);

        let params = {
            id: this.state.rowId,
            pwd: formValues.passWord
        }

        GetMemberSyncTaskUrl(params).then(res => {
            if (res && res.code == 0) {
                window.open(res.data);
                this.setState({
                    disableDownLoad: false,
                    isDownVisible: false
                })
            }
        })
    }


    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    render() {
        let { columns, dataLists, pagination, isCreateVisible, isDownVisible, currentDate } = this.state;

        dataLists.length > 0 && dataLists.map((item, index) => {
            item['key'] = index + 1
        })

        const props = {
            name: 'file',
            accept: ".xlsx,.xls",
            action: this.state.GetProjectUploadUser,
            headers: {
                authorization: 'authorization-text',
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }), () => {
                    if (this.state.fileList.length === 1) {
                        this.setState({
                            uploadBtnFlag: true,
                            noFileTips: false
                        })
                    }
                });
                return false
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
                this.setState({
                    uploadBtnFlag: false
                })
            },
        }

        return (
            <div className='sourceData'>
                <div className={'download_wrap'}>
                    <div className={'title_wrap'}>
                        <div></div>
                        <div className={'search_wrap'}>
                            <DatePicker
                                onChange={this.onDateChange}
                                locale={locale}
                                value={currentDate ? moment(currentDate, 'YYYY-MM-DD') : null}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;

                            <Button type="primary" size={'middle'} onClick={() => this.createTasks()}>
                                创建审核任务
                            </Button>
                        </div>
                    </div>

                    <Table
                        locale={{ emptyText: "暂无数据" }}
                        columns={columns}
                        dataSource={dataLists}
                        pagination={dataLists.length !== 0 && pagination}
                        rowKey={'id'}
                    />
                </div>

                {/* 创建数据下载任务 */}
                <Modal
                    title="创建数据下载任务"
                    visible={isCreateVisible}
                    onOk={this.onSubmit}
                    okText='确认创建'
                    cancelText='关闭'
                    onCancel={this.handleCancel}
                    width='30%'
                >
                    <Form
                        {...layout}
                        name="nest-messages"
                        onFinish={this.onSubmit}
                        requiredMark={true}
                        ref={this.formRef}
                        autoComplete="off"
                        initialValues={{
                            'passWord': '',
                        }}
                    >
                        <Form.Item
                            label="请选择文件"
                            name='file'
                        >
                            <div className={'upFile'} >
                                <Upload
                                    {...props}
                                >
                                    <Button
                                        icon={<UploadOutlined />}
                                        disabled={this.state.uploadBtnFlag}
                                    >
                                        上传文件
                                    </Button>
                                </Upload>
                                <div
                                    className={'tips'}
                                    style={{ display: `${this.state.noFileTips ? 'block' : 'none'}` }}
                                >
                                    {this.formatIcon('icon-guanbi')}
                                    <span style={{ marginLeft: 4 }}>请先选择文件</span>
                                </div>
                                <div className={'tip'} style={{ marginTop: 4 }}>支持扩展名：.xlsx</div>

                            </div>
                        </Form.Item>

                        <Form.Item
                            label="请输入超级密码"
                            name="passWord"
                            rules={[{ required: false, message: '请输入超级密码!' }]}
                        >
                            <Input type="password" allowClear />
                        </Form.Item>
                    </Form>
                </Modal>

                {/* 下载数据 */}
                <Modal
                    title="验证密码"
                    visible={isDownVisible}
                    onOk={this.onDownloadSubmit}
                    okText='开始下载'
                    cancelText='关闭'
                    onCancel={this.handleDownCancel}
                    width='30%'
                >
                    <Form
                        {...layout}
                        name="nest-messages"
                        onFinish={this.onDownloadSubmit}
                        requiredMark={true}
                        ref={this.formRefDownLoad}
                        initialValues={{
                            'passWord': '',
                        }}
                    >
                        <Form.Item
                            label="请输入超级密码"
                            name="passWord"
                            rules={[{ required: false, message: '请输入超级密码!' }]}
                        >
                            <Input type="password" />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}
export default withRouter(DoctorApprove);