import { Button, Form, Input, Modal, Select, message as messager } from 'antd'
import modStyl from './edit-modal.module.scss'

import React, { useEffect, useState } from 'react'
import { CreateLabelPayload, DataType, getLabelListTopLevel, LabelTopLevelResult, labelTypeOptions, updateLabel } from '../../../../services/knowledge/label';

type SelectOption = {
  label: string;
  value: number;
}

type Props = {
  record: DataType
  onClose: (refresh: boolean)=> void
}

const EditModal: React.FC<Props> = ({record, onClose})=> {

  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  // 所属项目
  const [parentList, setParentList] = useState<SelectOption[]>([])

  //
  const labelTypeChange = (value: number)=> {
    form.setFieldsValue({
      parentId: 0,
    })
    fetchParent(value)
  }

  const fetchParent = async (labelType: number)=> {
    const { code, data }: LabelTopLevelResult = await getLabelListTopLevel(labelType)
    if(code === 0) {
      setParentList([
        {
          label: '顶级标签',
          value: 0,
        },
        ...data.map(({name, id})=> ({
          value: id,
          label: name,
        }))
      ])
    }
  }

  const handleFinish = async (data: CreateLabelPayload)=> {
    if (record) {
      data.id = record.id
    }
    setLoading(true)
    const { code } = await updateLabel(data)
    setLoading(false)
    if(code === 0) {
      onClose(true)
      messager.success('编辑成功')
    }
  }

  useEffect(()=> {
    fetchParent(record.labelType)

  }, [])

  return (
    <Modal onCancel={()=> onClose(false)} visible={true} title={`${record ? '编辑' : '新增'}标签`} footer={null} closable maskClosable={false} destroyOnClose>
      <Form onFinish={handleFinish} form={form} initialValues={ record || {}} colon>
        <Form.Item label="标签名称" name="name" labelCol={{span: 8}} rules={[{required: true, message: '请输入备注信息'}]}>
          <Input placeholder='请输入标签名称'></Input>
        </Form.Item>
        <Form.Item label="标签类型" name="labelType" labelCol={{span: 8}} rules={[{required: true, message: '请选择所属项目'}]}>
          <Select onChange={labelTypeChange} options={labelTypeOptions} placeholder="请选择" showSearch allowClear/>
        </Form.Item>
        <Form.Item label="上级标签" name="parentId" labelCol={{span: 8}} rules={[{required: true, message: '请选择所属项目'}]}>
          <Select options={parentList} placeholder="请选择" showSearch allowClear/>
        </Form.Item>
        <div className={modStyl.footer}>
          <Button onClick={()=> onClose(false)}>取消</Button>
          <Button loading={loading} htmlType="submit" type="primary">确认</Button>
        </div>
      </Form>
    </Modal>
  )
}

export default EditModal