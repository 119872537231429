import React, { Component } from "react";
import './imageCanvas.less';
import cover from '../../static/images/cover.png';
import canceltuoMin from '../../static/images/cancelTuoMin.gif';
import revert from '../../static/images/revert.png';
import zoomIn from '../../static/images/zoomIn.png';
import zoomOut from '../../static/images/zoomOut.png';
import icon_cross from '../../static/images/icon_cross.png';
import icon_cross_cancel from '../../static/images/icon_cross_cancel.gif';
import icon_left_back_arrow from '../../static/images/icon_left_back_arrow.png';
import icon_rotate_left from '../../static/images/icon_rotate_left.png';
import icon_rotate_right from '../../static/images/icon_rotate_right.png';
import icon_to_discard from '../../static/images/icon_to_discard.png';
import icon_tuomin from '../../static/images/icon_tuomin.png';
import icon_zoomIn from '../../static/images/icon_zoomIn.png';
import icon_zoomOut from '../../static/images/icon_zoomOut.png';
import icon_zoomRevert from '../../static/images/icon_zoomRevert.png';

import handlerCanvas from './imageCanvas.js';
import axios from 'axios';
import { message } from "antd";

let toRemoveEvent = () => {};
let preImgUrl = '';

export default class DrawCanvas extends React.Component {
    state = {
      showTuoMin: false,
      showCancel: true,
      showMainMenu: true,
      needTuoMin: false
    };
    constructor(props) {
        super(props);
    }
    shouldComponentUpdate(props) {
        return true;
    }
    componentDidMount() {
        if (!this.props.imgUrl) {
          return;
        }
        preImgUrl = this.props.imgUrl;
        const { getDrawArea, cancelDrawArea, removeEvent, deleteDrawArea,
          getSubmitAreaListParam } = handlerCanvas(
          this.props.imgUrl,
          this.refs.canvas,
          this.refs.drawCanvas,
          this.refs.edit,
          this.refs.cancelEdit,
          this.refs.delete,
          this.refs.revert,
          this.refs.toBack,
          this.refs.zoomIn,
          this.refs.zoomOut,
          this.refs.rotateLeft,
          this.refs.rotateRight,
          this.refs.complete,
          {
            ifGetDrawArea: this.props.ifGetDrawArea, // 划词，只能绘制一个遮罩
            imgData: this.props.imgData, // 每张图片携带的信息
            isRecognize: this.props.ifGetDrawArea // 划词
          },
          (type, res, action) => {
            // 屏幕划词回调
            if (type === 'GetDrawArea') {
                this.props.getDrawAreaWord && this.props.getDrawAreaWord(res);
            }
            if (type === 'Complete') {
                this.completeAll(res);
            }
            if (type === 'ChangeTuoMin') {
                if (action === 'HuaCiStart') {
                  this.setState({
                      showCancel: false
                  })
                } else if(action === 'HuaCiFinish') {
                  this.setState({
                    showCancel: true
                  })
                }
                this.changeTuoMin(res);
            }
            if (type === 'ToBack') {
              this.toBack(!res);
            }
          }
        );
        this.getDrawArea = getDrawArea;
        this.cancelDrawArea = cancelDrawArea;
        this.deleteDrawArea = deleteDrawArea;
        this.getSubmitAreaListParam = getSubmitAreaListParam;
        toRemoveEvent = removeEvent;
    }
    componentDidUpdate(props) {
      // 放轮播图里会一直更新, 所以需要标识
      if (!this.props.needUpdate) {
          // 数据采集，图片轮播时只需要渲染一次，不需要update
          return;
      }
      // 图片审核需要多次用到同一个组件
      if (!this.props.imgUrl || preImgUrl === this.props.imgUrl) {
        return;
      }
      preImgUrl = this.props.imgUrl;
      // 移除上次的绑定事件
      toRemoveEvent && toRemoveEvent();
      const { getDrawArea, cancelDrawArea, removeEvent, deleteDrawArea,
        getSubmitAreaListParam } = handlerCanvas(
        this.props.imgUrl,
        this.refs.canvas,
        this.refs.drawCanvas,
        this.refs.edit,
        this.refs.cancelEdit,
        this.refs.delete,
        this.refs.revert,
        this.refs.toBack,
        this.refs.zoomIn,
        this.refs.zoomOut,
        this.refs.rotateLeft,
        this.refs.rotateRight,
        this.refs.complete,
        {
          ifGetDrawArea: this.props.ifGetDrawArea,
          imgData: this.props.imgData
        },
        (type, res, action) => {
          if (type === 'GetDrawArea') {
            this.props.getDrawAreaWord && this.props.getDrawAreaWord(res);
          }
          if (type === 'Complete') {
            this.completeAll(res);
          }
          if (type === 'ChangeTuoMin') {
            if (action === 'HuaCiStart') {
              this.setState({
                  showCancel: false
              })
            } else if(action === 'HuaCiFinish') {
              this.setState({
                showCancel: true
              })
            }
            this.changeTuoMin(res);
          }
          if (type === 'ToBack') {
            this.toBack(!res);
          }
        }
      );
      this.getDrawArea = getDrawArea;
      this.cancelDrawArea = cancelDrawArea;
      this.deleteDrawArea = deleteDrawArea;
      this.getSubmitAreaListParam = getSubmitAreaListParam;
      toRemoveEvent = removeEvent;
      console.log('toRemoveEvent2', toRemoveEvent);
    }
    componentWillUnmount() {
      toRemoveEvent = () => {};
      preImgUrl = '';
    }
    showNeedTuoMin() {
        let _imgData = this.props.imgData || {};
        // let ifNeedTuoMin = _imgData.desensitization == 1 ? true : false;
        // desensitizationPass 脱敏图片是否已通过 1需要脱敏 ,2不需要脱敏
        let ifNeedTuoMin = _imgData.desensitizationPass == 1 ? true : false;
        this.setState({
          needTuoMin: ifNeedTuoMin
        });
    }
    changeTuoMin(mState) {
      this.setState({
        showTuoMin: mState
      })
    }
    toBack(mState) {
      this.setState({
        showMainMenu: mState
      })
    }
    completeAll(res) {
      const _imageData = this.props.imgData;
        const postData = {
          "areaList": res,
          "fileUrl": this.props.imgUrl,
          "formId": _imageData.id,
          "platform": 0, // 1为h5, 0为pc
          "subjectCode": _imageData.subjectCode
        }
        axios.post("/file/image/desensitizate", postData).then(res => {
          this.setState({
            loading: false,
            loadingMsg: ''
          });
          if ((res.data.code + '') === '0') {
            message.success('脱敏完成');
          } else {
            const msg = (res.data && res.data.message) || '脱敏异常';
            message.error(msg);
          }
      })
    }
    render() {
      let _imgData = this.props.imgData || {};
      let ifNeedTuoMin = _imgData.desensitization == 1 ? true : false; // 1 脱敏，0不能
      if (!this.props.ifGetDrawArea) {
        // desensitizationPass 脱敏图片是否已通过 1需要脱敏 ,2不需要脱敏
        ifNeedTuoMin = _imgData.desensitizationPass == 1 ? true : false; // 切换通过与质疑状态时
      }
      const { showMainMenu } = this.state;
      return (
        <div ref="drawCanvas" className="image-canvas_content">
          <div className="right-area" style={{display: showMainMenu ? '' : 'none'}}>
              <div className="tuomin-and-cancel" style={{ display: ifNeedTuoMin ? '': 'none' }}>
                <img className="img" style={{display: (this.state.showTuoMin && this.state.showCancel) ? '' : 'none'}} src={icon_cross} alt="" ref="cancelEdit" id="cancelEdit"  />
                <img className="img tuomin" style={{display: this.state.showTuoMin ? 'none' : ''}} ref="edit" id="edit" src={icon_tuomin} alt="" />
              </div>
              <img className="img zoom-in" ref="zoomIn" id="zoomIn" src={icon_zoomIn} alt="" />
              <img className="img zoom-out" ref="zoomOut" id="zoomOut" src={icon_zoomOut} alt="" />
              <img className="img zoom-revert" ref="revert" id="revert" src={icon_zoomRevert} alt="" />
              <img className="img" src={icon_rotate_left} alt="" ref="rotateLeft" id="rotateLeft" />
              <img className="img" src={icon_rotate_right} alt="" ref="rotateRight" id="rotateRight"  />
          </div>
          {/* 返回以及删除 */}
          <div className="left-area" style={{display: showMainMenu ? 'none' : ''}}>
            <img className="img" src={icon_left_back_arrow} alt="" ref="toBack" id="toBack"  />
          </div>
          <div className="right-area" style={{display: showMainMenu ? 'none' : ''}}>
              <img className="img delete" ref="delete" id="delete" src={icon_to_discard} alt="" />
          </div>
          <div className="image-canvas_main">
            <canvas ref="canvas" id="canvas">
            </canvas>
          </div>
        </div>
      );
    }
}