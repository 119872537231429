/**
 * 项目报表
 */
import React from 'react';
import './index.less';
import moment from 'moment';
import {
    Form, Input, Select, Button, message as Message, InputNumber, Table,
    Popconfirm, AutoComplete, TreeSelect, message, Tabs, DatePicker
} from "antd";
import { createFromIconfontCN, RedoOutlined } from '@ant-design/icons';
import Details from "../details";
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import PubSub from "pubsub-js";
import {
    getReportData, getReportDownload
} from "../../../../../services/project/ProjectService";

import {
    GetDCTProjectReport, RebuildReport
} from "../../../../../services/dct/DctService";
import { BASE_URL } from '../../../../../../src/comm/config';
import $ from 'jquery';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

let _this;
const { Option } = Select;
const { Search } = Input;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_sb9kcktrb1j.js',//icon-shanchu icon-tongji icon-quxiao icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});
const layout = {
    wrapperCol: {
        span: 24,
    },
};

class ProjectReport extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            dataList: [], // 列表集合
            columns: [

                {
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    <strong>{this.props.selectTabPane == 1 ? 
                                    `${record.date}${record.startDate ? `${record.startDate.substring(8,10)}日` : ''}` : record.date}</strong>
                                </div>
                                <div className={'sub-title'}>
                                    {`${record.startDate ? record.startDate.substring(0,10) : ''} ~ ${record.endDate ? record.endDate.substring(0,10) : ''}`}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'sub-title'}>所属项目</div>
                                <div className={'title'}>{record.projectName}</div>
                            </div>
                        )
                    }
                },
                {
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'sub-title'}>状态</div>
                                <div className={'title'}>
                                    <div className={record.status == '1' ? 'tag taggreen' : 'tag tagbule'}></div>
                                    {record.status == 1 ? '已生成' : '未生成'}
                                </div>
                            </div>
                        )
                    }
                },

                {
                    width: '',
                    render: (text, record) =>
                        <div
                            onClick={eve => {
                                eve.stopPropagation();
                            }}
                            className={'partPop'}
                            // hidden={record.status == 0}
                        >
                            <Button type="link" onClick={() => this.rebuild(record.id)} >
                                重新生成
                            </Button>
                            <Button type="link" onClick={() => this.downLoad(record)} >
                                下载
                            </Button>

                            <Button type="link" onClick={() => this.openDetails(record)} >
                                查看
                            </Button>
                        </div>
                },
            ],// 表格 行 配置
            page: 1,//当前页码数
            pageSize: 10,//页码大小
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo);
                },
                page: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: false,
            }
        }
    }

    formRef = React.createRef();
    inputRef = React.createRef();

    componentDidMount() {
        let { page } = _this.state.pagination;
        _this.getPageData();
    }

    /**
     * 获取列表数据
     */
    getPageData = async (currPage, currType, currstartDate, currendDate, currCode) => {
        
        let { page, pageSize } = this.state.pagination;
        let {type, startDate, endDate, projectCode} = this.props;
        
        const params = {
            page: currPage || page,
            pageSize: pageSize,
            type: currType || type,
            startDate: currstartDate || startDate,
            endDate: currendDate || endDate,
            projectCode: currCode || projectCode
        }
        const res = await GetDCTProjectReport(params);
        if (res && res.code == 0) {
            _this.setState({
                dataList: [...res.data.rows],
                pagination: {
                    ...this.state.pagination,
                    page: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })
        }
    }

    /**
     * 查看详情
     */
    openDetails = (record) => {
        this.props.history.push({
            pathname: `/dct/project/report/details/${record.date}/${record.id}/${record.projectName}`,
            state: { title: record.date, id: record.id, name: record.projectName }
        })
    }

    /**
     * 下载
     */
    downLoad = (record) => {
        let { selectTabPane } = this.props;
        let tokenData = sessionStorage.getItem('token')
        tokenData = JSON.parse(tokenData);
        const url = BASE_URL + `dct/projectReport/project/report/download?id=${record.id}`;

        let xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = "blob";// 返回类型blob
        xhr.setRequestHeader("x-auth-token", tokenData.token);
        xhr.onload = function () {
            if (this.status === 200) {
                let blob = this.response;
                let reader = new FileReader();
                reader.readAsDataURL(blob);    // 转换为base64，可以直接放入a表情href
                reader.onload = function (e) {
                    // 转换完成，创建一个a标签用于下载
                    var a = document.createElement('a');
                    // selectTabPane
                    a.download = `${record.projectName}${selectTabPane == 1 ? 
                        `日报-${record.date}${record.startDate ? `${record.startDate.substring(8,10)}日`:''}.xls` :
                        selectTabPane == 2 ? `周报-${record.date}.xls` : `月报-${record.date}.xls`}`
                    a.href = e.target.result;
                    $("body").append(a);// 修复firefox中无法触发click
                    a.click();
                    $(a).remove();
                }
            }
        };
        xhr.send();
    }

    // 重新生成
    rebuild = async (id) => {
        const params = {
            id
        }
        const res = await RebuildReport(params);
        if (res && res.code == 0) {
            _this.getPageData();
        }
    }

    render() {
        let { columns, dataList, pagination } = this.state;
        return (
            <div className='dctReport_list'>
                <div className={'table-list'}>
                    <Table
                        locale={{ emptyText: "暂无数据" }}
                        columns={columns}
                        dataSource={dataList}
                        showHeader={false}
                        defaultExpandAllRows={true}
                        expandIconAsCell={false}
                        pagination={dataList.length !== 0 && pagination}
                        rowKey={'id'}
                    />
                </div>
            </div>
        );
    }
}

export default ProjectReport;