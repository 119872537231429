/**
  * 
  * @param {审核记录下载} params 
  * @returns 
  */

 import { requestPostFile, requestPost, requestGet, requestPostRes, requestGetRes, requestPatchRes } from '../EdcService';

 const urlPrefix = "/data";

 // 审核下载记录列表
 export const approveTaskList = (params) => {
    const url = `${urlPrefix}/approve/download/approveTaskList`;
    return requestPostRes(url, params)
};

// 项目枚举
export const approveProjectEnume = (params) => {
    const url = `${urlPrefix}/approve/download/projectList`;
    return requestGet(url, params)
};

// 获取下载链接
export const downloadTaskUrl = (params) => {
    const url = `${urlPrefix}/approve/download/taskUrl`;
    return requestPostRes(url, params)
};

// 创建稽查数据下载任务
export const createAuditDownloadTask = (params)=> {
    return requestPostRes(`${urlPrefix}/operation/record`, params)
}

// 创建下载任务 
export const createDownloadTask = (params) => {
    const url = `${urlPrefix}/approve/download/createTask`;
    return requestPostRes(url, params)
};

// 创建批量下载任务 
export const createBatchDownloadTask = (params) => {
    const url = `${urlPrefix}/approve/download/createTask/projects`;
    return requestPostRes(url, params)
};
