import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Input, message, Popover, Radio } from 'antd';
import verify from '../../utils/verify';
import './actionView.less';
import { createFromIconfontCN, ScanOutlined } from '@ant-design/icons';
import UnknownDateCom from '../../components/unknownDate/UnknownDate';
import SelectCom from '../../components/select/Select';
import InputCom from '../../components/input/Input';
import NumberCom from '../../components/number/Number';
import DateCom from '../../components/date/Date';
import TextareaCom from '../../components/textarea/Textarea';
import PhotoCom from '../../components/photo/Photo';
import PhotoOssCom from '../../components/photoOss/Photo';
import VideoCom from '../../components/video/video';
import CheckboxCom from '../../components/checkbox/checkbox';
import InputSelectCom from '../../components/inputSelect/InputSelect';
import DecimalCom from '../../components/decimal/Decimal';
import { annotationForm, answerQuestion, closeQuestion, getAnnotationType, getQuestionType, getTrance, questionForm, dataQuestionings, remarkForm, reOpenQuestionRes, updateFormData } from '../../../../services/data/DataService';
import { Select } from 'antd';

import formAction from '../../utils/formAction'
import OCRAutofill from '../../components/ocr-autofill/OCRAutofill';

const { TextArea } = Input;

const { Option } = Select;

const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2343612_ej80brkyy29.js',
    ],
});

function ActionView(props: any) {
    const formCom = useRef<any>(null);
    const [data, setData] = useState<any>();
    const [formId, setFormId] = useState<string>();
    const [dataActions, setDataActions] = useState<any>([]);
    const [formActions, setFormActions] = useState<any>([]);
    const [actions, setActions] = useState<any>([]);
    //修改操作显隐
    const [updateVisible, setUpdateVisible] = useState<boolean>(false);
    //备注操作显隐
    const [remarkVisible, setRemarkVisible] = useState<boolean>(false);
    //备注只读操作显隐
    const [remarkReadVisible, setRemarkReadVisible] = useState<boolean>(false);
    //注释操作显隐
    const [annotationVisible, setAnnotationVisible] = useState<boolean>(false);
    //注释只读操作显隐
    const [annotationReadVisible, setAnnotationReadVisible] = useState<boolean>(false);
    //质疑操作显隐
    const [questionVisible, setQuestionVisible] = useState<boolean>(false);
    //回复质疑显隐
    const [answerVisible, setAnswerVisible] = useState<boolean>(false);
    //痕迹稽查操作显隐
    const [traceVisible, setTraceVisible] = useState<boolean>(false);
    //保存后的savedValues
    const [savedValues, setSavedValues] = useState<any>();
    //备注值
    const [remarkValue, setRemarkValue] = useState<any>();
    //注释的type
    const [annotationTypes, setAnnotationTypes] = useState<any>([]);
    //注释的值
    const [annotationValue, setAnnotationValue] = useState<any>();
    //质疑类型
    const [questionTypes, setQuestionTypes] = useState<any>([]);
    //选择的质疑类型
    const [selectQuestionType, setSelectQuestionType] = useState<any>();
    //质疑value
    const [questionValue, setQuestionValue] = useState<any>();
    //保存的质疑
    const [questionList, setQuestionList] = useState<any>([]);
    //设置选中的质疑id
    const [selectQuestionItemId, setSelectQuestionItemId] = useState<any>();
    //设置当前质疑操作回复质疑的操作类型
    const [currentQuestionActionType, setCurrentQuestionActionType] = useState<any>('Retain');
    //质疑操作中的补充说明
    const [supplementInf, setSupplementInf] = useState<any>();
    //质疑操作中修改的值
    const [questionModifyValue, setQuestionModifyValue] = useState<any>();
    //痕迹稽查
    const [traceList, setTraceList] = useState<any>([]);
    // 划词识别
    const [text, setText] = useState<String>('');
    // OCRAutofill
    const [formConfig, setFormConfig] = useState<any[]>([])
    useEffect(() => {
        if (props.text) {
            setText(props.text);
        }
    }, [props.text])
    useEffect(() => {
        setData(props.data);
        setFormId(props.formId);
        props.dataActions && setDataActions(props.dataActions);
        setSavedValues(props.savedValues);
        setFormActions(props.formActions);
        setFormConfig(props.formConfig);
        let filterArr = [];
        let currentRole = sessionStorage.getItem('roleCode');
        let projectRoleCode = sessionStorage.getItem('projectRoleCode');
        if (props.dataActions && projectRoleCode != 'ReadOnly' && currentRole != 'EDC_READ' &&
            (currentRole === 'CALL_CENTER' || props?.fromCode !== 'FMJSNSCLC01CC01') &&
            (currentRole !== 'CALL_CENTER' || props?.fromCode === 'FMJSNSCLC01CC01')
        ) {
            for (let item of props.dataActions) {
                if (item == 'DataActionRead') {
                    filterArr.push({
                        label: '数据值',
                        key: 'DataActionRead',
                    })
                }
                else if (item == 'DataActionRemarkRead') {
                    filterArr.push({
                        label: '备注',
                        key: 'DataActionRemarkRead',
                    })
                }
                else if (item == 'DataActionAnnotationRead') {
                    filterArr.push({
                        label: '注释',
                        key: 'DataActionAnnotationRead',
                    })
                }
                else if (item == 'DataActionInput') {
                    filterArr.push({
                        label: '录入',
                        key: 'DataActionInput',
                    })
                }
                else if (item == 'DataActionUpdate') {
                    filterArr.push({
                        label: '修改',
                        key: 'DataActionUpdate',
                    })
                }
                else if (item == 'DataActionRemark') {
                    filterArr.push({
                        label: '备注',
                        key: 'DataActionRemark',
                    })
                }
                else if (item == 'DataActionAnnotation') {
                    filterArr.push({
                        label: '注释',
                        key: 'DataActionAnnotation',
                    })
                }
                else if (0 === item.indexOf('DataActionQuestion')) {
                    // 'DataActionQuestion!photo-oss,'
                    // 排除的组件类型
                    const partial = {
                        excludes: [] as string[],
                        includes: [] as string[]
                    }
                    if(item.indexOf('=') >  0) {
                        partial.includes = item.split('=').pop().split(',')
                    }
                    if(item.indexOf('!') > 0) {
                        partial.excludes = item.split('!').pop().split(',')
                    }
                    filterArr.push({
                        label: '质疑',
                        key: 'DataActionQuestion',
                        ...partial,
                    })
                }
                else if (item == 'DataActionAnswer') {
                    filterArr.push({
                        label: '回复质疑',
                        key: 'DataActionAnswer',
                    })
                }
                else if (item == 'DataActionTrace') {
                    filterArr.push({
                        label: '痕迹稽查',
                        key: 'DataActionTrace',
                    })
                }
                else if (item == 'DataActionOcr') {
                    filterArr.push({
                        label: 'OCR识别',
                        key: 'DataActionOcr',
                    })
                }
            }
        }

        
        setActions(filterArr);


        //当前保存的数据
        let currentItem = props.savedValues.find((i: any) => {
            return i.dataCode == props.data.dataCode
        });
        if (currentItem) {
            //设置备注信息
            setRemarkValue(currentItem.remark);
            //设置注释信息
            setAnnotationValue(currentItem.annotation);
            //设置质疑信息
            setQuestionList(currentItem.questions);
        }
    }, [props])

    //根据action选择不同操作
    const switchAction = (item: any) => {
        switch (item.key) {
            case 'DataActionRemarkRead':
                return (
                    <Popover
                        content={remarkReadView}
                        title={`备注:${data && data.label ? data.label : ''}`}
                        trigger="click"
                        placement="right"
                        visible={remarkReadVisible}
                        onVisibleChange={(visible) => { setRemarkReadVisible(visible) }}
                    >
                        <IconFont type="iconDataActionRemark"></IconFont>
                    </Popover>
                )
                break;
            case 'DataActionAnnotationRead':
                return (
                    <Popover
                        content={annotationReadView}
                        title={`注释:${data && data.label ? data.label : ''}`}
                        trigger="click"
                        placement="right"
                        visible={annotationReadVisible}
                        onVisibleChange={(visible) => { getAnnotationType().then(res => { if (res.code === 0) { setAnnotationTypes(res.data) } }); setAnnotationReadVisible(visible); }}
                    >
                        <IconFont type="iconDataActionAnnotation"></IconFont>
                    </Popover>
                )
                break;
            case 'DataActionUpdate':
                return (
                    <Popover
                        content={UpdateView}
                        title={`修改:${data && data.label ? data.label : ''}`}
                        trigger="click"
                        placement="right"
                        visible={updateVisible}
                        onVisibleChange={(visible) => { setUpdateVisible(visible) }}
                    >
                        <IconFont type="iconDataActionUpdate"></IconFont>
                    </Popover>
                )
                break;
            case 'DataActionRemark':
                return (
                    <Popover
                        content={remarkView}
                        title={`备注:${data && data.label ? data.label : ''}`}
                        trigger="click"
                        placement="right"
                        visible={remarkVisible}
                        onVisibleChange={(visible) => { setRemarkVisible(visible) }}
                    >
                        <IconFont type="iconDataActionRemark"></IconFont>
                    </Popover>
                )
                break;
            case 'DataActionAnnotation':
                return (
                    <Popover
                        content={annotationView}
                        title={`注释:${data && data.label ? data.label : ''}`}
                        trigger="click"
                        placement="right"
                        visible={annotationVisible}
                        onVisibleChange={(visible) => { getAnnotationType().then(res => { if (res.code === 0) { setAnnotationTypes(res.data) } }); setAnnotationVisible(visible); }}
                    >
                        <IconFont type="iconDataActionAnnotation"></IconFont>
                    </Popover>
                )
                break;
            case 'DataActionQuestion':
                // 排除
                if(Array.isArray(item.excludes) && item.excludes.length > 0) {
                    if(item.excludes.includes(data.type)) {
                        return null;
                    }
                }
                // 不排除
                if(Array.isArray(item.includes) && item.includes.length > 0) {
                    if(!item.includes.includes(data.type)) {
                        return null;
                    }
                }
                return (
                    <Popover
                        content={questionView('DataActionQuestion')}
                        title={`质疑:${data && data.label ? data.label : ''}`}
                        trigger="click"
                        placement="right"
                        visible={questionVisible}
                        onVisibleChange={(visible) => { getQuestionType().then(res => { if (res.code === 0) { setQuestionTypes(res.data) } }); setQuestionVisible(visible) }}
                    >
                        <IconFont type="iconDataActionQuestion"></IconFont>
                    </Popover>
                )
                break;
            case 'DataActionAnswer':
                return (
                    <Popover
                        content={questionView('DataActionAnswer')}
                        title={`回复质疑:${data && data.label ? data.label : ''}`}
                        trigger="click"
                        placement="right"
                        visible={answerVisible}
                        onVisibleChange={(visible) => { setAnswerVisible(visible) }}
                    >
                        <IconFont type="iconDataActionQuestion"></IconFont>
                    </Popover>
                )
                break;
            case 'DataActionTrace':
                return (
                    <Popover
                        content={traceView}
                        title={`痕迹稽查:${data && data.label ? data.label : ''}`}
                        trigger="click"
                        placement="right"
                        visible={traceVisible}
                        onVisibleChange={(visible) => { getTrance({ formId: formId }).then(res => { if (res.code === 0) { setTraceList(res.data) } }); setTraceVisible(visible) }}
                    >
                        <IconFont type="iconDataActionTrace"></IconFont>
                    </Popover>
                )
                break;
            default:
                break;
        }
    }

    //当修改值
    const onModify = () => {
        if (formCom.current) {
            let {onUpdateSuccess, ...params} = formCom.current.getModifyValue();
            if (params) {
                let selectField = props.data;
                if (selectField.type == 'number' || selectField.type == 'decimal' || selectField.type == 'input') {
                    let judge = verify.checkValueByRange(params.value, selectField);
                    if (!judge) {
                        return false;
                    }
                }
                
                updateFormData(params).then(async (res) => {
                    if (res.code === 0) {
                        // 特殊组件OCRAutofill 需要将识别的数据同时入库保存
                        onUpdateSuccess && await onUpdateSuccess()

                        // 修改后更新
                        props.modifyUpdateFun(params);
                        setUpdateVisible(false);
                        let sparams = {
                            formId: params.formId,
                            submit: false,
                            values: [params]
                        }
                        formAction.saveData(sparams)
                    }
                    else {
                        // 该受试者信息已存在，重复受试者编码为：[QLXH200015]
                        if(res.code === 50031411) {
                            return
                        }
                        // if (res.message) {
                        //     message.error(`${res.message}`, 1)
                        // }
                        // else {
                        //     message.error(`修改失败`, 1)
                        // }
                    }
                })
            }
        }
    }

    //UpdateView
    const UpdateView = () => {
        let formType = data.type;
        switch (formType) {
            case 'select':
                return (
                    <div className="popover_content">
                        <SelectCom isUpdate={true} formId={formId} formIsRead={true} savedValues={savedValues} dataActions={dataActions} data={data} ref={formCom}></SelectCom>
                        <div className="action_btn">
                            <Button onClick={onModify} type="primary">修改</Button>
                        </div>
                    </div>
                )
                break;
            case 'input':
                return (
                    <div className="popover_content">
                        <InputCom isUpdate={true} formId={formId} formIsRead={true} savedValues={savedValues} dataActions={dataActions} data={data} ref={formCom}></InputCom>

                        <div className="action_btn">
                            <Button onClick={onModify} type="primary">修改</Button>
                        </div>
                    </div>
                )
                break;
            case 'number':
                return (
                    <div className="popover_content">
                        <NumberCom isUpdate={true} formId={formId} formIsRead={true} savedValues={savedValues} dataActions={dataActions} data={data} ref={formCom}></NumberCom>
                        <div className="action_btn">
                            <Button onClick={onModify} type="primary">修改</Button>
                        </div>
                    </div>
                )
                break;
            case 'unknownDate':
                return (
                    <div className="popover_content">
                        <UnknownDateCom type={formType} isUpdate={true} formId={formId} formIsRead={true} savedValues={savedValues} dataActions={dataActions} data={data} ref={formCom}></UnknownDateCom>
                        <div className="action_btn">
                            <Button onClick={onModify} type="primary">修改</Button>
                        </div>
                    </div>
                )
                break;
            case 'date':
            case 'datetime':
                return (
                    <div className="popover_content">
                        <DateCom type={formType} isUpdate={true} formId={formId} formIsRead={true} savedValues={savedValues} dataActions={dataActions} data={data} ref={formCom}></DateCom>
                        <div className="action_btn">
                            <Button onClick={onModify} type="primary">修改</Button>
                        </div>
                    </div>
                )
                break;
            case 'textarea':
                return (
                    <div className="popover_content">
                        <TextareaCom isUpdate={true} formId={formId} formIsRead={true} savedValues={savedValues} dataActions={dataActions} data={data} ref={formCom}></TextareaCom>
                        <div className="action_btn">
                            <Button onClick={onModify} type="primary">修改</Button>
                        </div>
                    </div>
                )
                break;
            case 'photo':
                return (
                    <div className="popover_content">
                        <PhotoCom isUpdate={true} formId={formId} formIsRead={true} savedValues={savedValues} dataActions={dataActions} data={data} ref={formCom}></PhotoCom>

                        <div className="action_btn">
                            <Button onClick={onModify} type="primary">修改</Button>
                        </div>
                    </div>
                )
                break;
            case 'photo-oss':
                return (
                    <div className="popover_content">
                        <div className='' style={{width: 560, height: 110}}>
                            <PhotoOssCom isUpdate={true} formId={formId} formIsRead={true} savedValues={savedValues} dataActions={dataActions} data={data} ref={formCom}></PhotoOssCom>
                        </div>
                        <div className="action_btn">
                            <Button onClick={onModify} type="primary">修改</Button>
                        </div>
                    </div>
                )
            case 'ocr-autofill':
                return (
                    <div className="popover_content">
                        <div className='' style={{width: 560, height: 110}}>
                            <OCRAutofill isUpdate={true} formId={Number(formId)} formIsRead={true} savedValues={savedValues} dataActions={dataActions} data={data} ref={formCom} formConfig={formConfig}/>
                        </div>
                        <div className="action_btn">
                            <Button onClick={onModify} type="primary">修改</Button>
                        </div>
                    </div>
                )
                break;
            case 'video':
                return (
                    <div className="popover_content">
                        <VideoCom isUpdate={true} formId={formId} formIsRead={true} savedValues={savedValues} dataActions={dataActions} data={data} ref={formCom}></VideoCom>

                        <div className="action_btn">
                            <Button onClick={onModify} type="primary">修改</Button>
                        </div>
                    </div>
                )
                break;
            case 'checkbox':
                return (
                    <div className="popover_content">
                        <CheckboxCom isUpdate={true} formId={formId} formIsRead={true} savedValues={savedValues} dataActions={dataActions} data={data} ref={formCom}></CheckboxCom>
                        <div className="action_btn">
                            <Button onClick={onModify} type="primary">修改</Button>
                        </div>
                    </div>
                )
                break;
            case 'input-select':
                return (
                    <div className="popover_content">
                        <InputSelectCom isUpdate={true} formId={formId} formIsRead={true} savedValues={savedValues} dataActions={dataActions} data={data} ref={formCom}></InputSelectCom>
                        <div className="action_btn">
                            <Button onClick={onModify} type="primary">修改</Button>
                        </div>
                    </div>
                )
                break;
            case 'decimal':
                return (
                    <div className="popover_content">
                        <DecimalCom isUpdate={true} formId={formId} formIsRead={true} savedValues={savedValues} dataActions={dataActions} data={data} ref={formCom}></DecimalCom>
                        <div className="action_btn">
                            <Button onClick={onModify} type="primary">修改</Button>
                        </div>
                    </div>
                )
                break;
            default:
                break;
        }
    }

    //备注view
    const remarkView = () => {
        return (
            <div className="popover_content">
                <TextArea style={{ width: '300px' }} value={remarkValue} rows={4} maxLength={100} allowClear={true} showCount={true} onChange={(e) => { setRemarkValue(e.target.value) }} />
                <div className="action_btn">
                    <Button onClick={onRemark} type="primary">提交备注</Button>
                </div>
            </div>
        )
    }
    //备注只读view
    const remarkReadView = () => {
        return (
            <div className="popover_content">
                <TextArea disabled={true} style={{ width: '300px' }} value={remarkValue} rows={4} maxLength={100} allowClear={true} showCount={true} onChange={(e) => { setRemarkValue(e.target.value) }} />
            </div>
        )
    }
    //当提交备注
    const onRemark = () => {
        let params = {
            dataCode: data.dataCode,
            deCode: data.deCode,
            formId: formId,
            remark: remarkValue,
        }
        remarkForm(params).then(res => {
            if (res.code === 0) {
                //更新
                props.modifyUpdateFun();
                setRemarkVisible(false);
            } else {
                if (res.message) {
                    message.error(`${res.message}`, 1)
                }
                else {
                    message.error(`提交失败`, 1)
                }
            }
        })
    }

    //注释view
    const annotationView = () => {
        return (
            <div className="popover_content">
                <Radio.Group size="large" onChange={(e) => { setAnnotationValue(e.target.value) }} value={annotationValue}>
                    {
                        annotationTypes && annotationTypes.map((item: any, index: any) => {
                            return <Radio key={index} value={item.key}>{item.text}</Radio>
                        })
                    }
                </Radio.Group>
                <div className="action_btn">
                    <Button onClick={onAnnotation} type="primary">提交注释</Button>
                </div>
            </div>
        )
    }
    //当注释时
    const onAnnotation = () => {
        let params = {
            annotation: annotationValue,
            dataCode: data.dataCode,
            deCode: data.deCode,
            formId: formId,
        }
        annotationForm(params).then(res => {
            if (res.code === 0) {
                //更新
                props.modifyUpdateFun();
                setAnnotationVisible(false);
            } else {
                if (res.message) {
                    message.error(`${res.message}`, 1)
                }
                else {
                    message.error(`提交失败`, 1)
                }
            }
        })
    }
    //注释只读view
    const annotationReadView = () => {
        return (
            <div className="popover_content">
                <Radio.Group size="large" disabled={true} onChange={(e) => { setAnnotationValue(e.target.value) }} value={annotationValue}>
                    {
                        annotationTypes && annotationTypes.map((item: any, index: any) => {
                            return <Radio key={index} value={item.key}>{item.text}</Radio>
                        })
                    }
                </Radio.Group>
            </div>
        )
    }

    //质疑view
    const questionView = (type: any) => {
        if (type === 'DataActionQuestion') {
            return (
                <div className="popover_content">
                    <div className="questionList">
                        {
                            questionList && questionList.length > 0 && questionList.map((item: any, index: any) => {
                                return (
                                    <React.Fragment>
                                        {
                                            switchQuestionList(type, item)
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                        {
                            !questionList || questionList.length === 0 ? <div style={{ textAlign: 'center' }}>暂无质疑</div> : null
                        }
                    </div>
                    <Select allowClear placeholder="请选择检查类型" style={{ width: 300 }} onChange={(value) => { setSelectQuestionType(value) }}>
                        {
                            questionTypes && questionTypes.map((item: any, index: any) => {
                                return <Option value={item.key} key={index}>{item.text}</Option>
                            })
                        }
                    </Select>
                    <div style={{ marginTop: '10px' }}>
                        <TextArea placeholder="请输入检查说明" style={{ width: '300px' }} value={questionValue} rows={4} maxLength={100} allowClear={true} showCount={true} onChange={(e) => { setQuestionValue(e.target.value) }} />
                    </div>
                    <div className="action_btn">
                        <Button onClick={onQuestion} type="primary">添加质疑</Button>
                    </div>
                </div>
            )
        } else if (type === 'DataActionAnswer') {
            return (
                <div className="popover_content">
                    <div className="questionList">
                        {
                            questionList && questionList.length > 0 && questionList.map((item: any, index: any) => {
                                return (
                                    <React.Fragment>
                                        {
                                            switchQuestionList(type, item)
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                        {
                            !questionList || questionList.length === 0 ? <div style={{ textAlign: 'center' }}>暂无质疑</div> : null
                        }
                    </div>
                </div>
            )
        }
    }

    //根据不同state选择不同的质疑列表view
    const switchQuestionList = (type: any, item: any) => {
        let state = item.state;
        if (type === 'DataActionQuestion') {
            switch (state) {
                case 'Create':
                    return (
                        <div className="questionItem">
                            <div className="questionItem_top">
                                <div className="left">
                                    <div className="left_top">
                                        <span className="question_type">
                                            <IconFont type="iconquestion_open"></IconFont>
                                            {item.typeText}：
                                        </span>
                                        <span className="questionContent">
                                            {
                                                item.content
                                            }
                                        </span>
                                    </div>
                                    <div style={{ color: '#EF8767' }} className="left_bottom">
                                        (质疑未回复)
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="closeBtn" onClick={onCloseQuestion(item.id)}>
                                        关闭
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    break;
                case 'Reopen':
                    return (
                        <div className="questionItem">
                            <div className="questionItem_top">
                                <div className="left">
                                    <div className="left_top">
                                        <span className="question_type">
                                            <IconFont type="iconquestion_open"></IconFont>
                                            {item.typeText}：
                                        </span>
                                        <span className="questionContent">
                                            {
                                                item.content
                                            }
                                        </span>
                                    </div>
                                    <div style={{ color: '#EF8767' }} className="left_bottom">
                                        (质疑未回复)
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="closeBtn" onClick={onCloseQuestion(item.id)}>
                                        关闭
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    break;
                case 'Answer':
                    return (
                        <div className="questionItem">
                            <div className="questionItem_top">
                                <div className="left">
                                    <div className="left_top">
                                        <span className="question_type">
                                            <IconFont type="iconquestion_reopen"></IconFont>
                                            {item.typeText}：
                                        </span>
                                        <span className="questionContent">
                                            {
                                                item.content
                                            }
                                        </span>
                                    </div>
                                    <div className="left_bottom">
                                        <IconFont type="iconquestion_prompt_reopen"></IconFont>
                                        <span style={{ whiteSpace: 'nowrap' }}>已修复</span>
                                        <div className="message">
                                            {item.answer ? item.answer.content : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="reopenBtn" onClick={reopenQuestion(item.id)}>
                                        重新打开
                                    </div>
                                    <div className="closeBtn" onClick={onCloseQuestion(item.id)}>
                                        关闭
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    break;
                case 'Closed':
                    return (
                        <div className="questionItem">
                            <div className="questionItem_top">
                                <div className="left">
                                    <div className="left_top">
                                        <span className="question_type">
                                            <IconFont type="iconquestion_close"></IconFont>
                                            {item.typeText}：
                                        </span>
                                        <span className="questionContent">
                                            {
                                                item.content
                                            }
                                        </span>
                                    </div>
                                    <div style={{ color: '#5EBCBC' }} className="left_bottom">
                                        <IconFont type="iconquestion_prompt"></IconFont>
                                        已修复
                                        <div className="message">
                                            {item.answer ? item.answer.content : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    break;
                default:
                    break;
            }
        }
        else if (type === 'DataActionAnswer') {
            let answerType = [
                {
                    label: '保留原值',
                    value: 'Retain'
                },
                {
                    label: '修改原值',
                    value: 'Update'
                },
                {
                    label: '置空原值',
                    value: 'Empty'
                }
            ]
            switch (state) {
                case 'Create':
                    return (
                        <div className="questionItem">
                            <div className="questionItem_top">
                                <div className="left">
                                    <div className="left_top">
                                        <span className="question_type">
                                            <IconFont type="iconquestion_open"></IconFont>
                                            {item.typeText}：
                                        </span>
                                        <span className="questionContent">
                                            {
                                                item.content
                                            }
                                        </span>
                                    </div>
                                    <div style={{ color: '#EF8767' }} className="left_bottom">
                                        (质疑未回复)
                                    </div>
                                </div>
                                {
                                    selectQuestionItemId !== item.id ? <div className="right">
                                        <div className="answerBtn" onClick={() => { setSelectQuestionItemId(item.id) }}>
                                            回复
                                        </div>
                                    </div> : null
                                }
                            </div>
                            {
                                selectQuestionItemId === item.id ? <div className="questionAction">
                                    <div className="action_taps" hidden={true}>
                                        {
                                            answerType.map((item, index) => {
                                                return (<div style={{ background: item.value === currentQuestionActionType ? '#6287EC' : '#ffffff', color: item.value === currentQuestionActionType ? '#ffffff' : '#6287EC' }} key={index} className="questionAction_content_tapItem" onClick={() => { setCurrentQuestionActionType(item.value); setSupplementInf(null); setQuestionModifyValue(null); }}>
                                                    {item.label}
                                                </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        switchActionContent(currentQuestionActionType, item)
                                    }
                                </div> : null
                            }
                        </div>
                    )
                    break;
                case 'Reopen':
                    return (
                        <div className="questionItem">
                            <div className="questionItem_top">
                                <div className="left">
                                    <div className="left_top">
                                        <span className="question_type">
                                            <IconFont type="iconquestion_open"></IconFont>
                                            {item.typeText}：
                                        </span>
                                        <span className="questionContent">
                                            {
                                                item.content
                                            }
                                        </span>
                                    </div>
                                    <div style={{ color: '#EF8767' }} className="left_bottom">
                                        (质疑未回复)
                                    </div>
                                </div>
                                {
                                    selectQuestionItemId !== item.id ? <div className="right">
                                        <div className="answerBtn" onClick={() => { setSelectQuestionItemId(item.id) }}>
                                            回复
                                        </div>
                                    </div> : null
                                }
                            </div>
                            {
                                selectQuestionItemId === item.id ? <div className="questionAction">
                                    <div className="action_taps" hidden={true}>
                                        {
                                            answerType.map((item, index) => {
                                                return (<div style={{ background: item.value === currentQuestionActionType ? '#6287EC' : '#ffffff', color: item.value === currentQuestionActionType ? '#ffffff' : '#6287EC' }} key={index} className="questionAction_content_tapItem" onClick={() => { setCurrentQuestionActionType(item.value); setSupplementInf(null); setQuestionModifyValue(null); }}>
                                                    {item.label}
                                                </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        switchActionContent(currentQuestionActionType, item)
                                    }
                                </div> : null
                            }
                        </div>
                    )
                    break;
                case 'Answer':
                    return (
                        <div className="questionItem">
                            <div className="questionItem_top">
                                <div className="left">
                                    <div className="left_top">
                                        <span className="question_type">
                                            <IconFont type="iconquestion_reopen"></IconFont>
                                            {item.typeText}：
                                        </span>
                                        <span className="questionContent">
                                            {
                                                item.content
                                            }
                                        </span>
                                    </div>
                                    <div className="left_bottom">
                                        <IconFont type="iconquestion_prompt_reopen"></IconFont>
                                        已修复
                                        <div className="message">回复待确认</div>
                                    </div>
                                </div>
                                {
                                    selectQuestionItemId !== item.id ? <div className="right">
                                        <div className="answerBtn" onClick={() => { setSelectQuestionItemId(item.id) }}>
                                            修改
                                        </div>
                                    </div> : null
                                }
                            </div>
                            {
                                selectQuestionItemId === item.id ? <div className="questionAction">
                                    <div className="action_taps" hidden={true}>
                                        {
                                            answerType.map((item, index) => {
                                                return (<div style={{ background: item.value === currentQuestionActionType ? '#6287EC' : '#ffffff', color: item.value === currentQuestionActionType ? '#ffffff' : '#6287EC' }} key={index} className="questionAction_content_tapItem" onClick={() => { setCurrentQuestionActionType(item.value); setSupplementInf(null); setQuestionModifyValue(null); }}>
                                                    {item.label}
                                                </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        switchActionContent(currentQuestionActionType, item)
                                    }
                                </div> : null
                            }
                        </div>
                    )
                    break;
                case 'Closed':
                    return (
                        <div className="questionItem">
                            <div className="questionItem_top">
                                <div className="left">
                                    <div className="left_top">
                                        <span className="question_type">
                                            <IconFont type="iconquestion_close"></IconFont>
                                            {item.typeText}：
                                        </span>
                                        <span className="questionContent">
                                            {
                                                item.content
                                            }
                                        </span>
                                    </div>
                                    <div style={{ color: '#5EBCBC' }} className="left_bottom">
                                        <IconFont type="iconquestion_prompt"></IconFont>
                                        已修复
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    break;
                default:
                    break;
            }
        }
    }

    //当质疑操作
    const onQuestion = () => {
        let params = {
            label: data.label,
            content: questionValue,
            dataCode: data.dataCode,
            deCode: data.deCode,
            // formActions: formActions,
            formActions: ["FormActionExamine"],
            formId: formId,
            type: selectQuestionType,
        }
     
        if(!selectQuestionType) return message.error(`请选择检查类型`, 1)
        dataQuestionings(params).then(res => {
            if (res.code === 0) {
                //更新
                 props.modifyUpdateFun();
                setQuestionValue(null);
            }
        })
    }

    //关闭质疑
    const onCloseQuestion = (id: any) => {
        console.log(props.tenantCode)
        return () => {
            let params = {
                tenantCode: props.tenantCode,
                dataCode: data.dataCode,
                deCode: data.deCode,
                formId: formId,
                questionId: id,
            }
            closeQuestion(params).then(res => {
                if (res.code === 0) {
                    //更新
                    props.modifyUpdateFun();
                    message.success('关闭成功！', 1);
                } 
            })
        }
    }

    //重新打开质疑
    const reopenQuestion = (id: any) => {
        return () => {
            let params = {
                tenantCode: props.tenantCode,
                dataCode: data.dataCode,
                deCode: data.deCode,
                formId: formId,
                questionId: id,
            }
            reOpenQuestionRes(params).then(res => {
                if (res.code === 0) {
                    //更新
                    props.modifyUpdateFun();
                    message.success('重新打开成功！', 1);
                } 
            })
        }
    }

    //根据点击的质疑操作类型展示操作内容
    const switchActionContent = (currentType: any, item: any) => {
        console.log(item)
        if (currentType) {
            switch (currentType) {
                case 'Retain':
                    return (
                        <div className="QuestionActionContent">
                            <TextArea showCount maxLength={100} allowClear={true} style={{ width: '100%', maxWidth: '300px' }} defaultValue={item.answer?.content} placeholder={'补充说明'} onChange={(e) => { setSupplementInf(e.target.value) }} />
                            <div className="actionBtn">
                                <div className="cancelBtn" onClick={() => { setSelectQuestionItemId(null) }}>
                                    取消
                                </div>
                                <div className="saveBtn" onClick={onAnswerQuestion}>
                                    保存
                                </div>
                            </div>
                        </div>
                    )
                    break;
                case 'Update':
                    return (
                        <div className="QuestionActionContent">
                            <Input allowClear={true} style={{ width: '100%', maxWidth: '300px', marginBottom: '10px' }} value={questionModifyValue} placeholder={'请输入修改值'} onChange={(e) => { setQuestionModifyValue(e.target.value) }} />
                            <TextArea showCount maxLength={100} allowClear={true} style={{ width: '100%', maxWidth: '300px' }} defaultValue={item.answer?.content} placeholder={'补充说明'} onChange={(e) => { setSupplementInf(e.target.value) }} />
                            <div className="actionBtn">
                                <div className="cancelBtn" onClick={() => { setSelectQuestionItemId(null) }}>
                                    取消
                                </div>
                                <div className="saveBtn" onClick={onAnswerQuestion}>
                                    保存
                                </div>
                            </div>
                        </div>
                    )
                    break;
                case 'Empty':
                    return (
                        <div className="QuestionActionContent">
                            <TextArea showCount maxLength={100} allowClear={true} style={{ width: '100%', maxWidth: '300px' }} defaultValue={item.answer?.content} placeholder={'补充说明'} onChange={(e) => { setSupplementInf(e.target.value) }} />
                            <div className="actionBtn">
                                <div className="cancelBtn" onClick={() => { setSelectQuestionItemId(null) }}>
                                    取消
                                </div>
                                <div className="saveBtn" onClick={onAnswerQuestion}>
                                    保存
                                </div>
                            </div>
                        </div>
                    )
                    break;

                default:
                    break;
            }
        }
    }
    //回复质疑
    const onAnswerQuestion = () => {
        let params = {
            content: supplementInf,
            dataCode: data.dataCode,
            deCode: data.deCode,
            formId: formId,
            questionId: selectQuestionItemId,
            type: currentQuestionActionType,
            value: questionModifyValue
        }
        answerQuestion(params).then(res => {
            if (res.code === 0) {
                //更新
                props.modifyUpdateFun();
                message.success('保存成功！');
            } else {
                if (res.message) {
                    message.error(`${res.message}`, 1)
                }
                else {
                    message.error(`保存失败`, 1)
                }
            }
        })
    }

    //痕迹稽查
    const traceView = () => {
        return (
            <div className="popover_content">
                <div className="traceTable">
                    <table width="100%">
                        <thead>
                            <tr>
                                <th>操作时间</th>
                                <th>操作类型</th>
                                <th>操作后值</th>
                                <th>操作备注</th>
                                <th>操作人</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                traceList && traceList.length > 0 && traceList.map((item: any, index: any) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.operationTime}</td>
                                            <td>{item.operationTypeText}</td>
                                            <td>{item.newValue}</td>
                                            <td>{item.remark}</td>
                                            <td>{item.operator}</td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                traceList.length === 0 ? <tr>
                                    <td>{'暂无'}</td>
                                    <td>{'暂无'}</td>
                                    <td>{'暂无'}</td>
                                    <td>{'暂无'}</td>
                                    <td>{'暂无'}</td>
                                </tr> : null
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    return (
        <div className="actionView">
            <div className="actionList">
                {
                    actions.length > 0 && actions.map((actionItem: any, index: any) => {
                        return (
                            <div className="actionItem" key={index}>
                                {
                                    switchAction(actionItem)
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ActionView;
