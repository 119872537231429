/**
 * 拒绝
 */
import React, { Component } from 'react';
import { Modal, Button, Form, Input, Select, Radio, message } from 'antd';
import { createFromIconfontCN, EditOutlined, CloseOutlined } from '@ant-design/icons';
import { getBillBatchSettle } from '../../../../../services/project/ProjectService';
import './index.less'

const { Option } = Select;
const { TextArea } = Input;
let timeInterval;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

const optionsValues = [
    { label: '图片与任务内容不匹配', value: '图片与任务内容不匹配' },
    { label: '图片不清晰，无法辨认', value: '图片不清晰，无法辨认' },
    { label: '图片未脱敏', value: '图片未脱敏' },
    { label: '其它', value: '其它' },
]

class RefuseModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selectValue: null, // 选中的value
        }
    }
    formRef = React.createRef();

    /**
     * 确认
     */
    handleOk = (values) => {
        this.setState({
            selectValue: values.reason
        })
        this.props.getValue(values.reason)
        this.props.closeModal();
    };

    /**
     * 取消
     */
    handleCancel = () => {
        this.props.closeModal();
    };

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    onChange = (e) => {
        this.setState({
            selectValue: e.target.value
        })
    }

    render() {
        const { loading, selectValue } = this.state;
        const { visible } = this.props;

        return (
            <>
                <Modal
                    className={'refuseModals'}
                    visible={visible}
                    title="拒绝图片"
                    onCancel={this.handleCancel}
                    width={'30%'}
                    centered
                    destroyOnClose={true}
                    footer={[
                        <Button
                            key="back"
                            onClick={this.handleCancel}
                            style={{marginRight: 80}}
                        >取消</Button>
                    ]}
                >
                    <div className='refuseContent'>
                        <Form
                            name="batch-messages"
                            onFinish={this.handleOk}
                            requiredMark={true}
                            ref={this.formRef}
                            initialValues={{
                                'reason': ''
                            }}
                        >
                            <Form.Item
                                label="请选择拒绝理由"
                                name='reason'
                                rules={[
                                    {
                                        required: this.state.selectValue == '其它' ? false:true,
                                        message: '请选择拒绝理由',
                                    }
                                ]}
                            >
                                <Radio.Group
                                    options={optionsValues}
                                    onChange={this.onChange}
                                />
                            </Form.Item>

                            {

                                this.state.selectValue == '其它' && <Form.Item
                                    name='reason'
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入拒绝理由',
                                        },
                                        {
                                            whitespace: true,
                                            message: '不能输入空格',
                                        }
                                    ]}
                                >
                                    <TextArea maxLength={200}/>
                                </Form.Item>
                            }

                            <Form.Item wrapperCol={24} style={{bottom: this.state.selectValue == '其它'? '-115px':'-100px'}}>
                                <Button
                                    key="submit"
                                    type="primary"
                                    htmlType="submit"
                                > 确认</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
            </>
        );
    }
}

export default RefuseModal;