// IE polyfill
import  "./polyfill";
import  "core-js/es";
import  "mutation-observer";
// import  "react-app-polyfill/ie9";
// import  "react-app-polyfill/stable";

// import  "es5-shim";
// import  "fetch-ie8";


import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Provider } from 'react-redux';

ReactDOM.render(
      <Provider store={store}>
          <App />
      </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
