import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Input, Radio } from "antd";
import "./inputSelect.less";
import verify from "../../utils/verify";
import formAction from "../../utils/formAction";
import debounce from "lodash/debounce";

type RangeValueType = {
  min: string;
  max: string;
  radio: string;
  other: string;
}
// 因input-select组件的value由数组改为对象 这里是专门处理兼容问题
const compatibleInputSelectValue = (item: any, value: string[] | RangeValueType | null | '')=> {
  const newValue: RangeValueType = {
    min: '',
    max: '',
    radio: '',
    other: ''
  }
  if(!value) {
    return newValue
  }

  if(Object.keys(value).length > 0) {
    const v = value as RangeValueType
    // 强制清空其他值信息
    if(item.other) {
      if(v.radio !== item.other.key) {
        v.other = ''
      }
    }
    return value as RangeValueType;
  }

  let tmp = [...(value as string[])];

  // 处理 ['1-1', '1']
  if(tmp.length === 2 && tmp[0].includes('-')) {
    tmp = [...(tmp[0].split('-')), tmp[1]]
  }

  // 字段有其他选项处理
  if(item.other) {
    const last = tmp.pop() as string
    // 最后一项数据非其他时
    if(item.optional[last]) {
      newValue.other = '';
      newValue.radio = last;
    } else {
      newValue.radio = item.other.key;
      newValue.other = last;
    }
  }
  // 设置最小值
  if(tmp.length > 0) {
    newValue.min = tmp.shift() as string
  }
  // 如果还有那必定是最大值
  if(tmp.length > 0 && item.inputType === 'inputrange') {
    newValue.max = tmp.shift() as string
  }
  return newValue as RangeValueType
}

function InputSelectCom(props: any, ref: any) {
  const [data, setData] = useState<any>();
  const [formId, setFormId] = useState<any>();
  const [formIsRead, setFormIsRead] = useState<boolean>(true);
  const [optional, setOptional] = useState<any>([]);

  const [updatedValue, setUpdatedValue] = useState<any>();

  const [minValue, setMinValue] = useState<any>("");
  const [maxValue, setMaxValue] = useState<any>("");
  const [radioValue, setRadioValue] = useState<any>("");
  const [otherValue, setOtherValue] = useState<any>("");

  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (props.data) {
      //表单是否只读
      setFormIsRead(props.formIsRead);
      //获取当前表单的id
      setFormId(props.formId);
      //该条表单元素的数据
      setData(props.data);
      //如果是修改时
      if (props.isUpdate) {
        setIsUpdate(props.isUpdate);
      } else {
        setIsUpdate(false);
      }

      //设置options
      type Option = {
        label: string;
        value: string;
      };
      const options = Object.keys(props.data.optional).reduce(
        (options: Option[], value: string) => {
          return [
            ...options,
            {
              label: props.data.optional[value],
              value: value,
            },
          ];
        },
        []
      );

      setOptional(options);

      //设置当前表单已存的value
      const currentItem = props.savedValues.find(
        ({ dataCode }: any) => dataCode == props.data.dataCode
      );

      if (currentItem && currentItem.value) {
        const { min, max, radio, other } = compatibleInputSelectValue(props.data, currentItem.value)

        setUpdatedValue(currentItem.value);

        setMinValue(min);
        setMaxValue(max);
        setRadioValue(radio);
        setOtherValue(other);
      } else {
        setUpdatedValue(null);
        setMinValue("");
        setMaxValue("");
        setRadioValue("");
        setOtherValue("");
      }
    }
  }, [props]);

  // 如果是选中状态则取消选中
  const handleRadioCheck = (e:any)=>{
    if (radioValue === e.target.value) {
        e.target.checked = false;
        setRadioValue("");
        updateValue({
          min: minValue,
          max: maxValue,
          radio: "",
          other: otherValue,
      });
    } 
}

  const updateValue = useMemo(() => {
    const callback = (item: any) => {
      if (isUpdate) {
        setUpdatedValue(item);
      }
      saveData(formId, [
        {
          dataCode: data.dataCode,
          deCode: data.deCode,
          value: item,
        },
      ]);
    };
    return debounce(callback, 500);
  }, [data, formId]);

  // 最小值
  const onInputMinChange = (e: any) => {
    const value = verify.checkValue(e.target.value, data);
    setMinValue(value)
    updateValue({
        min: value,
        max: maxValue,
        radio: radioValue,
        other: otherValue,
    });
  };
  // 最大值
  const onInputMaxChange = (e: any) => {
    const value = verify.checkValue(e.target.value, data);
    setMaxValue(value)
    updateValue({
        min: minValue,
        max: value,
        radio: radioValue,
        other: otherValue,
    });
  };

  //当选择change
  const onSelectChange = (e: any) => {
    const value = e.target.value
    setRadioValue(value)

    let other = otherValue
    if(value === data?.other?.key) {
        setOtherValue('')
        other = ''
    }
    updateValue({
        min: minValue,
        max: maxValue,
        radio: value,
        other: other,
    });
  };

  //当other输入change
  const onOtherInputChange = (e: any) => {
    const value = e.target.value
    setOtherValue(value)
    updateValue({
        min: minValue,
        max: maxValue,
        radio: radioValue,
        other: value,
    });
  }

  //暂存数据
  const saveData = (formId: any, values: any) => {
    formAction.saveData({
      formId: formId,
      submit: false,
      values: values,
    });
    props.updateFun();
  };

  useImperativeHandle(ref, () => ({
    // changeVal 就是暴露给父组件的方法
    getModifyValue: () => {
      return {
        dataCode: data.dataCode,
        deCode: data.deCode,
        formId: formId,
        value: updatedValue,
      };
    },
  }))

  //选择other对应的表单
  const switchOtherField = () => {
    if (data && data.other) {
      if (radioValue && radioValue === data.other.key) {
        const { type, placeholder } = data.other
        
        let realPlaceholder = `请输入${data.label}`
        if(typeof placeholder === 'string') {
          realPlaceholder = placeholder.startsWith('~') ? realPlaceholder + placeholder.substring(1) : placeholder
        }

        switch (type) {
          //input
          case "input":
            return (
              <div style={{ marginTop: "10px" }}>
                <Input
                  allowClear={true}
                  autoFocus={true}
                  disabled={!formIsRead}
                  style={{ width: "100%" }}
                  value={otherValue}
                  placeholder={realPlaceholder}
                  suffix={data && data.unit ? data.unit : ""}
                  onChange={onOtherInputChange}
                />
              </div>
            );
          default:
            break;
        }
      }
    } else {
      return null;
    }
  };

  return (
    <div className="inputSelectCom">
      <React.Fragment>
        <div>
          {data && data.inputType == "inputrange" ? (
            <Input.Group compact>
              <Input
                style={{
                  width: 100,
                  textAlign: "center",
                }}
                placeholder="最小值"
                value={minValue}
                onChange={onInputMinChange}
                disabled={!formIsRead}
              />
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: "none",
                }}
                placeholder="~"
                disabled
              />
              <Input
                className="site-input-right"
                style={{
                  width: 100,
                  textAlign: "center",
                  borderLeft: 0,
                }}
                placeholder="最大值"
                value={maxValue}
                onChange={onInputMaxChange}
                disabled={!formIsRead}
              />
            </Input.Group>
          ) : (
            <Input
              allowClear={true}
              style={{ width: "100%" }}
              disabled={!formIsRead}
              value={minValue}
              placeholder={data ? `请输入${data.label}` : ""}
              onChange={onInputMinChange}
              suffix={data && data.unit ? data.unit : ""}
            />
          )}
        </div>
        <div style={{ marginTop: "10px" }}>
          <Radio.Group
            size="large"
            onChange={onSelectChange}
            value={radioValue}
          >
            {optional &&
              optional.map((item: any, index: any) => {
                return (
                  <Radio disabled={!formIsRead} key={index} value={item.value} onClick={handleRadioCheck}>
                    {item.label}
                  </Radio>
                );
              })}
          </Radio.Group>
        </div>
        {switchOtherField()}
      </React.Fragment>
    </div>
  );
}

export default forwardRef(InputSelectCom);
