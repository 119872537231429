import React from "react";
import "./index.less";
import utils from "../../../../../src/util/utils";
import {
  Input,
  Select,
  Button,
  Table,
  message,
  Modal,
  Col,
  Row,
  Spin,
  Tag,
  TreeSelect,
} from "antd";
import {
  getContentList,
  deleteContent,
  getLocalList,
  contentOnOff,
  getTagList,
} from "../../../../services/apply/ApplyService";
import { ExclamationCircleFilled } from "@ant-design/icons";
import ContentModal from "./formModal";
const { Option } = Select;
const { confirm } = Modal;

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      code: null,
      labelId: null,
      mediaType: null,
      loading: false,
      title: null,
      state: null,
      page: 1,
      totalRow: 0,
      dataList: [],
      contentOptions: [
        {
          value: 1,
          label: "资讯",
        },
        {
          value: 2,
          label: "外链",
        },
      ],
      stateOptions: [
        {
          value: 0,
          label: "草稿",
        },
        {
          value: 1,
          label: "待上线",
        },
        {
          value: 2,
          label: "上线",
        },
        {
          value: 3,
          label: "下线",
        },
      ],
      dialogVisible: false,
      isEdit: 0,
      localOptions: [],
      labelOptions: [],
    };
  }

  formRefDialog = React.createRef();
  componentDidMount() {
    this.getLocalOptions();

    this.getData();
  }
  // 表单提交更新数据
  refleshData = () => {
    this.setState({
      dialogVisible: false,
    });
    this.getData();
  };

  // 获取位置列表
  getLocalOptions = () => {
    getLocalList({ pageType: 1 }).then((res) => {
      if (res.code == 0) {
        this.setState({
          localOptions: res.data,
        });
      }
    });
    //
    getTagList(1).then((res) => {
      if (res.code == 0) {
        this.setState({
          labelOptions: this.transformTree(res.data || []),
        });
      }
    });
  };
  // 定义一个递归函数来转换树结构
  transformTree = (data) => {
    return data.map((item) => {
      const transformedItem = {
        label: item.name,
        value: item.id,

        children: item.children ? this.transformTree(item.children) : null,
      };
      return transformedItem;
    });
  };

  //删除报名
  deleteItem = (id) => {
    let that = this;
    confirm({
      title: "确定要删除该内容吗?",
      icon: <ExclamationCircleFilled />,
      okText: "确定",
      cancelText: "取消",
      onOk() {
        deleteContent(id).then((res) => {
          if (res.code == 0) {
            message.success("删除成功", 1);
            that.getData();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  getData = (pageNo) => {
    let params = {
      code: this.state.code,
      title: this.state.title,
      mediaType: this.state.mediaType,
      labelId: this.state.labelId,
      state: this.state.state,
      page: pageNo ? pageNo : this.state.page,
      pageSize: 10,
    };
    this.setState(
      {
        loading: true,
      },
      () => {
        getContentList(params).then((res) => {
          if (res.code == 0 && res.data) {
            this.setState({
              dataList: res.data.rows,
              page: res.data.page,
              totalRow: res.data.total,
              loading: false,
            });
          }
        });
      }
    );
  };

  handleSearch = () => {
    this.getData();
  };

  /**
   * 重置搜索
   */
  resetSearch = () => {
    this.setState(
      {
        code: null,
        title: null,
        mediaTye: null,
        state: null,
        labelId: null,
        page: 1,
        totalRow: 0,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  /**
   *创建与编辑
   */
  createOrEdit = (val, record) => {
    this.setState({ dialogVisible: true, isEdit: val }, () => {
      if (val) {
        this.formRefDialog.current.setEditData(record.id);
      }
    });
  };
  // 上线或者下线
  handleOperate = async (id, isPublish) => {
    const res = await contentOnOff({
      id,
      isPublish,
    });
    if (res.code == 0) {
      message.success("操作成功！");
      this.getData();
    }
  };

  handleCancel = () => {
    this.setState({ dialogVisible: false });
    this.formRefDialog.current.resetFields();
  };

  // 表单提交或者修改
  handleFinish = (state) => {
    console.log(this.formRefDialog);
    this.formRefDialog.current.saveData(state);
  };

  // 数据导出
  exportData = () => {
    let url = `/knowledge/content/download`;
    let params = {
      code: this.state.code,
      title: this.state.title,
      mediaType: this.state.mediaType,
      labelId: this.state.labelId,
      state: this.state.state,
    };
    utils.exportPostFun(url, params);
  };

  render() {
    let {
      code,
      title,
      mediaType,
      state,
      loading,
      labelId,
      stateOptions,
      dialogVisible,
      dialogLoading,
      page,
      totalRow,
      dataList,
      localOptions,
      labelOptions,
      contentOptions,
      isEdit,
    } = this.state;

    let columns = [
      {
        title: "内容ID",
        dataIndex: "code",
        width: 200,
      },
      {
        title: "内容标题",
        dataIndex: "title",
        width: 200,
      },
      {
        title: "所属页面",
        dataIndex: "pageLocalName",
        width: 200,
      },
      {
        title: "内容类型",
        dataIndex: "mediaType",
        width: 140,
        render: (text, record) => {
          return (
            <div>
              {record.mediaTye == 1
                ? "资讯"
                : record.mediaTye == 2
                ? "外链"
                : record.mediaTye == 3
                ? "文章"
                : "视频"}
            </div>
          );
        },
      },
      {
        title: "内容标签",
        dataIndex: "labels",
        width: 140,
        render: (text, record) => (
          <div>
            {record.labels?.map((e) => {
              return (
                <>
                  <div className="tag-content">
                    <Tag color="blue">{e.name}</Tag>
                  </div>
                </>
              );
            })}
          </div>
        ),
      },

      {
        title: "上线时间",
        dataIndex: "onlineTime",
        width: 200,
      },

      {
        title: "状态",
        dataIndex: "state",
        width: 140,
        render: (text, record) => (
          <div>
            <div>
              {record.state == 0
                ? "草稿"
                : record.state == 1
                ? "待上线"
                : record.state == 2
                ? "上线"
                : "下线"}
            </div>
          </div>
        ),
      },

      {
        title: "浏览人次-PV",
        dataIndex: "pv",
        width: 80,
      },
      {
        title: "点赞数",
        dataIndex: "lv",
        width: 80,
      },
      {
        title: "评论数",
        dataIndex: "cv",
        width: 80,
      },
      {
        title: "收藏数",
        dataIndex: "fv",
        width: 80,
      },
      {
        title: "转发点击",
        dataIndex: "tv",
        width: 80,
      },
      {
        title: "浏览人数-UV",
        dataIndex: "uv",
        width: 80,
      },
      {
        title: "其他",
        width: 200,
        render: (text, record) => {
          return (
            <div>
              创建人：{record.createUserName}
              <br />
              最后修改时间：{record.modifyTime}
            </div>
          );
        },
      },

      {
        title: "操作",
        width: 250,
        fixed: "right",
        align: "center",
        render: (text, record) => (
          <div className="btn-operate">
            <Button
              type="primary"
              onClick={() => this.createOrEdit(1, record)}
              size="small"
            >
              编辑
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                message.info("敬请期待！");
              }}
            >
              预览
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                this.handleOperate(
                  record.id,
                  record.state == 0 || record.state == 3 ? true : false
                );
              }}
            >
              {record.state == 0 || record.state == 3 ? "上线" : "下线"}
            </Button>
            <Button
              type="primary"
              size="small"
              danger
              onClick={() => {
                this.deleteItem(record.id);
              }}
            >
              删除
            </Button>
          </div>
        ),
      },
    ];

    let pagination = {
      onChange: (pageNo) => {
        this.getData(pageNo);
      },
      current: page,
      pageSize: 10,
      total: totalRow,
      showSizeChanger: false,
      showTotal: (total) => `共${total} 条记录`,
    };

    return (
      <div className="content_list">
        <div className={"apply_wrap"}>
          <div className={"search_wrap_content"}>
            <div className="search_left">
              <Row className="search_row">
                <Col span={8}>
                  <span className="search_label">内容ID</span>

                  <Input
                    allowClear
                    placeholder="请输入内容ID"
                    value={code}
                    onChange={(e) => this.setState({ code: e.target.value })}
                    style={{ width: "70%" }}
                  />
                </Col>
                <Col span={8}>
                  <span className="search_label">内容标题</span>
                  <Input
                    allowClear
                    placeholder="请输入内容标题"
                    value={title}
                    onChange={(e) => this.setState({ title: e.target.value })}
                    style={{ width: "70%" }}
                  />
                </Col>
                <Col span={8}>
                  <span className="search_label">内容类型</span>
                  <Select
                    showSearch
                    placeholder="请选择"
                    style={{ width: "70%" }}
                    value={mediaType}
                    allowClear
                    onChange={(value) => this.setState({ mediaType: value })}
                    optionFilterProp="children"
                  >
                    {contentOptions.map((item) => (
                      <Option value={item.value}>{item.label}</Option>
                    ))}
                  </Select>
                </Col>
              </Row>
              <Row className="search_row">
                <Col span={8}>
                  <span className="search_label">内容标签</span>
                  <TreeSelect
                    showSearch
                    treeNodeFilterProp="label"
                    style={{
                      width: "70%",
                    }}
                    value={labelId}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    onChange={(value) => this.setState({ labelId: value })}
                    placeholder="请选择"
                    allowClear
                    treeData={labelOptions}
                  />
                </Col>

                <Col span={8}>
                  <span className="search_label">状态</span>
                  <Select
                    showSearch
                    placeholder="请选择"
                    style={{ width: "70%" }}
                    value={state}
                    allowClear
                    onChange={(value) => this.setState({ state: value })}
                    optionFilterProp="children"
                  >
                    {stateOptions.map((item) => (
                      <Option value={item.value}>{item.label}</Option>
                    ))}
                  </Select>
                </Col>
                <Col span={8} className="right_action">
                  <Button
                    type="primary"
                    size={"middle"}
                    onClick={this.handleSearch}
                  >
                    查询
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    size={"middle"}
                    onClick={this.resetSearch}
                  >
                    重置
                  </Button>
                </Col>
              </Row>
            </div>
          </div>

          <div className={"btns_wrap"}>
            <Button type="primary" onClick={() => this.createOrEdit(0)}>
              新增
            </Button>
            <Button type="primary" onClick={this.exportData}>
              导出数据
            </Button>
          </div>
          <Spin tip="数据加载中..." spinning={loading}>
            <Table
              locale={{ emptyText: "暂无数据" }}
              columns={columns}
              dataSource={dataList}
              pagination={pagination}
              rowKey={"id"}
              scroll={{ x: "calc(700px + 50%)" }}
            />
          </Spin>
        </div>
        <Modal
          className="case-audit-checkbox-wrapper"
          visible={dialogVisible}
          title={isEdit ? "编辑" : "新建"}
          onCancel={this.handleCancel}
          afterClose={() => {
            this.formRefDialog.current.resetFields();
          }}
          width="50%"
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              取消
            </Button>,
            <Button
              htmlType="submit"
              type="primary"
              loading={dialogLoading}
              onClick={() => {
                this.handleFinish(false);
              }}
            >
              保存
            </Button>,
            <Button
              htmlType="submit"
              type="primary"
              loading={dialogLoading}
              onClick={() => {
                this.handleFinish(true);
              }}
            >
              保存并发布
            </Button>,
          ]}
        >
          <ContentModal
            localOptions={localOptions}
            ref={this.formRefDialog}
            labelOptions={labelOptions}
            refleshData={this.refleshData}
          />
        </Modal>
      </div>
    );
  }
}

export default Content;
