/**
 * dct 运营监控
 */
import React, { Component } from 'react';

import { Button, Tabs, message, Row, Col, Card, Switch, List, Avatar, Tag, Modal, Checkbox } from 'antd';
import { GetDctMonitor, GetDctMonitorModal } from "../../../../services/dct/DctService";
import './index.less';

const { TabPane } = Tabs;
let _this;
const CheckboxGroup = Checkbox.Group;
const tabPaneList = ['运营监控', '新任务']

class DctMOnitorComponent extends Component {

    constructor(props) {
        super(props);
        _this = this;
        this.state = {
            /** 页面查询条件元素*/
            page: 1,//当前页码数
            pageSize: 10,//页码大小
            total: 100,
            monitorData: [], // 列表数据
            isModalVisible: false, // 全部监控
            checkedList: [], // 默认选择
            indeterminate: true,
            checkAll: false, // 全选
            plainOptions: [], // 所有选项
        };
        this.inputEl = React.createRef();
        this.timer = null // 自动刷新
        this.activeKey = 0 // tabs 切换的key
    }

    componentDidMount() {
        this.getList();
    }

    // 获取数据
    getList = (currPage) => {
        let { page, pageSize, checkedList } = this.state;
        let params = {
            page: currPage ? currPage : page,
            pageSize: pageSize
        }

        if (this.activeKey === '1') {
            params['taskStatus'] = this.activeKey
        }
        
        if (checkedList.length > 0) {
            checkedList = checkedList.join(',').replace('新任务', '1').replace('已分配', '2').replace('不合格', '3').replace('DC失败', '4').replace('已完成', '5').replace('失败超限', '6').replace('已放弃', '7').replace('已超时', '8').replace('已关闭', '9').replace('合格', '10').replace('失败', '11');
            params['taskStatus'] = checkedList
        }

        GetDctMonitor(params).then(res => {
            if (res.code == 0) {
                if (!res.data || res.data.length == 0) {
                    return;
                }
                this.setState({
                    monitorData: res.data.rows,
                    page: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total
                })
            }
        })
    }


    /**
     * Tabs 切换 events
     * @param key
     */
    handleChange = (key) => {
        this.activeKey = key
        this.getList()
    }

    // 全部监控模态框
    showModal = () => {

        this.setState({
            isModalVisible: true
        })

        GetDctMonitorModal().then(res => {
            if (res.code == 0) {
                if (!res.data || res.data.length == 0) {
                    return;
                }

                let plainOptions = []
                res.data.map(item => {
                    plainOptions.push(item.name)
                })
                this.setState({
                    plainOptions
                })
            }
        })
    };

    // 确认
    handleOk = () => {
        this.setState({
            isModalVisible: false
        })
        this.getList()
    };

    // 取消
    handleCancel = () => {
        this.setState({
            isModalVisible: false
        })
    };


    // 全选
    onCheckAllChange = e => {
        this.setState({
            indeterminate: false,
            checkedList: e.target.checked ? this.state.plainOptions : [],
            checkAll: e.target.checked
        })
    };
    onCheckboxGroup = list => {
        this.setState({
            indeterminate: !!list.length && list.length < this.state.plainOptions.length,
            checkedList: list,
            checkAll: list.length === this.state.plainOptions.length
        })
    };

    // 自动刷新
    autoRefresh = (checked, e) => {
        if (!checked) {
            clearTimeout(this.timer)
            this.props.onRefresh(false)
            return
        }
        this.timer = setTimeout(() => {
            this.props.onRefresh(true)
            this.getList()
        }, 60000)
    }

    render() {

        let { monitorData, isModalVisible, indeterminate, checkedList, checkAll, plainOptions, total, pageSize } = this.state;
        monitorData.map(item => {
            if (item.taskStatus === '3') {
                item['color'] = 'error'
                item.taskStatus = '不合格'
            }
            else if (item.taskStatus === '4') {
                item['color'] = 'error'
                item.taskStatus = 'DC失败'
            }
            else if (item.taskStatus === '6') {
                item['color'] = 'error'
                item.taskStatus = '失败超限'
            }
            else if (item.taskStatus === '1') {
                item['color'] = 'processing'
                item.taskStatus = '新任务'
            }
            else if (item.taskStatus === '5') {
                item['color'] = 'warning'
                item.taskStatus = '已完成'
            }
            else if (item.taskStatus === '2') {
                item['color'] = 'purple'
                item.taskStatus = '已分配'
            }
            else if (item.taskStatus === '9') {
                item['color'] = 'warning'
                item.taskStatus = '合格'
            }
            else if (item.taskStatus === '7') {
                item['color'] = 'error'
                item.taskStatus = '已超时'
            }
            else if (item.taskStatus === '8') {
                item['color'] = 'default'
                item.taskStatus = '已关闭'
            }
            else if (item.taskStatus === '12') {
                item['color'] = 'magenta'
                item.taskStatus = '图片不完整'
            }
        })

        return (
            <div className={'dctMOnitor_content'}>
                <div className={'dct_bottom'}>
                    <div className={'searchs'}>
                        <span className={'autoTxt'}>自动刷新&nbsp;&nbsp;</span>
                        <Switch defaultChecked={false} onChange={this.autoRefresh} />&nbsp;&nbsp;

                        <Button type="link" onClick={this.showModal}>全部监控</Button>
                        <Modal title={
                            <Checkbox
                                indeterminate={indeterminate}
                                onChange={this.onCheckAllChange}
                                checked={checkAll}
                            >全选</Checkbox>
                        }
                            visible={isModalVisible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            okText={'确认'}
                            cancelText={'取消'}
                            width={260}
                            mask={false}
                            wrapClassName={'monitor_modal'}
                        >
                            <CheckboxGroup options={plainOptions} value={checkedList} onChange={this.onCheckboxGroup} />
                        </Modal>
                    </div>

                    <Tabs
                        onChange={this.handleChange}
                        activeKey={_this.state.activeKey}
                    >

                        {tabPaneList.map((item, index) => (
                            <TabPane tab={item} key={index}>
                                <List
                                    dataSource={monitorData}
                                    pagination={monitorData.length == 0 ? null : {
                                        onChange: page => {
                                            this.getList(page)
                                        },
                                        pageSize,
                                        total,
                                        showSizeChanger: false,
                                    }}
                                    renderItem={(item, index) => (
                                        <List.Item key={index}>
                                            <List.Item.Meta
                                                avatar={
                                                    <Tag color={item.color}>{item.taskStatus}</Tag>
                                                }
                                                title={
                                                    <div >
                                                        {`【${item.projectName}】`}{`${item.centerName}${item.userName}${item.label}${item.taskStatus}`}
                                                    </div>
                                                }
                                            />
                                            <div className="timeTxt">{item.createDate ? item.createDate.substring(5, 16) : ''}</div>
                                        </List.Item>
                                    )}
                                />
                            </TabPane>
                        ))}
                    </Tabs>
                </div>

            </div>
        )
    }
}
export default DctMOnitorComponent