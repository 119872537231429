/**
 * 签名审核权限管理服务接口
 */
import { requestPost, requestGet } from '../EdcService';

const urlPrefix = "/plat";

/**
* 获取角色列表
*/
export const getAllRoleList = (params) => {
    const url = urlPrefix + "/audit/sign/roleList";
    return requestGet(url, params)
};

/**
 *可选项目列表
 */
export const getProjectList = (params) => {
    const url = urlPrefix + "/audit/sign/projectList";
    return requestGet(url, params)
};

/**
 * 签名审核权限管理列表
 * @param {} params
 */
export const getAuditPermissionList = (params) => {
    const url = urlPrefix + "/audit/sign/list";
    return requestPost(url, params)
};

/**
 * 签名审核权限项目设置
 */
export const getAuditPermissionSet = (params) => {
    const url = urlPrefix + "/audit/sign/set";
    return requestPost(url, params)
};






