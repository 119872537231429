/**
 * 项目报表详情
 */
import React, { useState, useEffect } from 'react';
import './details.less';
import { Modal, Button, Form, Input, Select, Radio, Upload, message, Progress, InputNumber, Table } from 'antd';
import { createFromIconfontCN, UploadOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getReportSummary, getReportDetailData } from "../../../../../services/project/ProjectService";

function Details(props) {

  //报告类型
  const [reportType, setReportType] = useState(0)

  // 概要列表
  const [outlineData, setOutlineData] = useState([]);
  // 明细列表
  const [detailData, setDetailData] = useState([]);
  //  积分兑换明细
  const [settleDetailList, setSettleDetailList] = useState([]);
  //  page
  const [page, setPage] = useState(1);
  //  pageSize
  const [pageSize, setPageSize] = useState(20);
  //  totalNum
  const [totalNum, setTotalNum] = useState(0);
  // tableLoading
  const [tableLoading, setTableLoading] = useState(false);

  const currentRole = sessionStorage.getItem('roleCode');

  let childrenList = [];
  let InfoList = [];
  let settleData = {};

  /**月报概要 */
  const ReportSummary = () => {
    let { id } = props.location.state;
    getReportSummary(id).then(res => {
      if (res && res.code == 0) {
        let resList = [];
        res.data['cycle'] = `${(res.data.planBegin && res.data.planBegin.substring(0, 10)) || ''}~${(res.data.planEnd && res.data.planEnd.substring(0, 10)) || ''}`;
        resList.push(res.data);
        setReportType(res.data.reportType)
        setOutlineData(resList);
      }
    })
  }

  /**月报明细 */
  const getReportDetail = (page, pageSize) => {
    let { id } = props.location.state;
    let params = {
      page: page,
      pageSize: pageSize,
      reportId: id
    }
    getReportDetailData(params, currentRole).then(res => {
      if (res && res.code == 0) {
        let InfoDetailList = [];

        res.data.length > 0 && res.data.map((item, index) => {
          // 积分兑换明细
          // InfoList = [...item.monthReportSettleDetailDtos];
          settleData = item.monthReportInfoDto;

          // InfoList.length > 0 && InfoList.map((i, d) => {
          //   settleData[`exchangedNum${d}`] = i.exchangedNum;
          //   i[`reportHeader${d}`] = i.reportHeader ? i.reportHeader.slice(4) !== '01' ? `${i.reportHeader.slice(4)}月` : `${i.reportHeader.slice(0, 4)}年${i.reportHeader.slice(4)}月` : ''
          // })

          InfoDetailList.push(settleData);
          return settleData;
        })

        // InfoList.length > 0 && InfoList.map((i, d) => {
        //   childrenList.push({
        //     title: i[`reportHeader${d}`],
        //     dataIndex: `exchangedNum${d}`,
        //     key: `exchangedNum${d}`
        //   })
        // })

        setDetailData(InfoDetailList);
        setSettleDetailList(childrenList);

        setTotalNum(res.totalRow);
        setTableLoading(false);

      }
    })
  }

  useEffect(() => {
    ReportSummary();
    getReportDetail(page, pageSize);
  }, [])

  // 月报概要
  const outlineColumns = [
    {
      title: '统计周期',
      dataIndex: 'cycle',
    },
    {
      title: '登记中心数量',
      dataIndex: 'centerNum',
    },
    {
      title: '登记医生数量',
      dataIndex: 'userNum',
    },
    {
      title: '启动中心数量',
      dataIndex: 'centerStartNum',
    },
    {
      title: '启动医生数量',
      dataIndex: 'userStartNum',
    },
    {
      title: '目标例数',
      dataIndex: 'goalNum',
    },
    {
      title: '录入例数',
      dataIndex: 'inputNum',
    },
    {
      title: '完整例数',
      dataIndex: 'finishNum',
    }
    // ,
    // {
    //   title: '已兑换积分',
    //   dataIndex: 'exchangedNum',
    // },
  ];

  const detailColumns = [
    {
      title: '大区',
      dataIndex: 'bigAreaName',
    },
    {
      title: '省份',
      dataIndex: 'provinceName',
    },
    {
      title: '城市',
      dataIndex: 'cityName',
    },
    {
      title: '研究中心',
      dataIndex: 'centerName',
    },
    {
      title: '研究者姓名',
      dataIndex: 'userName',
    },
    {
      title: 'EDC账号',
      dataIndex: 'userCode',
    },
    {
      title: '目标例数',
      dataIndex: 'goalNum',
      render: (text) => {
        return (
          <div>{text || '--'}</div>
        )
      }
    },
    {
      title: '录入例数',
      dataIndex: 'inputNum',
    },
    {
      title: '完整例数',
      dataIndex: 'finishNum',
    }
    // ,
    // {
    //   title: '未兑换积分',
    //   dataIndex: 'remainingNum',
    // },
    // {
    //   title: '已兑换积分',
    //   dataIndex: 'exchangedNum',
    // },
    // {
    //   title: '积分兑换明细',
    //   dataIndex: 'monthReportSettleDetailDtos',
    //   children: settleDetailList,
    // },
  ]

  // 返回
  const goBack = () => {
    const id = sessionStorage.getItem('projectId');
    props.history.push({
      pathname: '/project/detail',
      state: {
        id: id,
        activeKey: '7', // 定位到项目报表
      }
    })
  }

  //当分页change
  const onPageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    setTableLoading(true);
    getReportDetail(page, pageSize);

  }

  return (
    <div className='project-details'>

      <div className={'title-con'}>
        <div className={'title'}>
          {`${props.location.state.title}${props.location.state.time.substring(0, 4)}年${reportType === 4 ?
            Number(props.location.state.time.substring(4, 7)) : props.location.state.time.substring(4)}${reportType === 1 ? '月' : 
            reportType === 2 ? '周' : reportType === 4 ? '日' : reportType == 5 ? '月单月' : reportType == 6 ? '周单周' : reportType == 7 ? '日单日' : '年'}数据收集${reportType === 1 ? '月' : 
            reportType === 2 ? '周' : reportType === 4 ? '日' : reportType == 5 ? '单月月' : reportType == 6 ? '单周周' : reportType == 7 ? '单日日' : '年'}度报告`}
        </div>
        <Button type='primary' onClick={goBack}>返回</Button>
      </div>

      <div className='detailContent'>
        <div className='outline' hidden={currentRole == 'EDC_AM'}>
          <div className={'titleStyle'}>项目
            {
              reportType === 1 ? '月' : reportType === 2 ? '周' : reportType === 4 ? '日' :
                reportType == 5 ? '单月月' : reportType == 6 ? '单周周' : reportType == 7 ? '单日日' : '年'
            }报概要</div>
          <Table
            columns={outlineColumns}
            dataSource={outlineData}
            bordered
            style={{ marginBottom: 15 }}
          />
        </div>

        <div className='tab-details'>
          <div className='titleStyle'>项目
            {
              reportType === 1 ? '月' : reportType === 2 ? '周' : reportType === 4 ? '日' :
                reportType == 5 ? '单月月' : reportType == 6 ? '单周周' : reportType == 7 ? '单日日' : '年'
            }报明细</div>
          <Table
            locale={{ emptyText: "暂无数据" }}
            columns={detailColumns}
            dataSource={detailData}
            pagination={{
              pageSize: pageSize,
              total: totalNum,
              onChange: onPageChange,
              showTotal: total => `共 ${totalNum} 条数据， ${pageSize} / 页`,
              showSizeChanger: false
            }}
            rowKey={'id'}
            scroll={{ x: settleDetailList.length > 0 ? `120%` : '100%', }}
            bordered
            size="middle"
            loading={tableLoading}
          />
        </div>
      </div>

    </div>
  )
}
export default Details;
