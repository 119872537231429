
import cover from '../../../../../static/images/minCover.png';
const cachesImgMap = {};

export default function handlerCanvas(
    imgUrl,
    refCanvas,
    refDrawCanvas,
    refEdit,
    refCancelEdit,
    refDelete,
    refRevert,
    refBack,
    refZoomIn,
    refZoomOut,
    refRotateLeft,
    refRotateRight,
    refComplete,
    getDrawAreaConfig,
    callBack
) {
    let canvas = refCanvas;
    let editBtn = refEdit;
    let cancelEditBtn = refCancelEdit;
    let deleteBtn = refDelete;
    let revertBtn = refRevert;
    let backBtn = refBack;
    let zoomInBtn = refZoomIn;
    let zoomOutBtn = refZoomOut;
    let completeBtn = refComplete;
    let ctx = canvas.getContext('2d');
    let minWidth = refDrawCanvas.offsetWidth; // 容器的宽度 offsetWidth clientWidth
    let minHeight = refDrawCanvas.offsetHeight; // 容器的高度 offsetHeight clientHeight
    let image = new Image();
    let imageRect = new Image();
    let layers = []; // 图层
    let canvasRect = canvas.getBoundingClientRect();
    let canMove = false; // down -> move -> up 为一个流程 pc端逻辑
    let dragDirection = ''; // 矩形八边
    let pattern; // 脱敏图案
    let isGetDrawArea = getDrawAreaConfig ? getDrawAreaConfig.ifGetDrawArea : false; // 是否数据采集页面
    let isHuaCi = false; // 划词
    let imgData = getDrawAreaConfig ? getDrawAreaConfig.imgData : {};
    let isRecognize = getDrawAreaConfig.isRecognize;
    // 标注
    let lastClicked = false; //是否上次点击过,为取消删除使用
     // 识别标注
    let drawAreaResolve = () => {};
    let drawOnlyId = ''; // 划词唯一Id
    let drawAreaData = {}; // 更新手动触发的划词信息
    let scrollElement = canvas.parentNode; // 滚动元素
    let isImgScroll= false; // 初始图片滚动状态为false
    let firstLoadCoverImg = true; // 防止图片加载空白
    let isCoverSelected = false; // 处于被选中状态
    let rotateDirection = 'top'; // 默认或者缓存展示时的坐标
    let xInitWidth = 0;
    let xInitHeight = 0;
    let yInitWidth = 0;
    let yInitHeight = 0;
    // 背景图片
    image.src = imgUrl + '?id=' + Math.random();
    // 遮罩图片
    imageRect.src = cover;
    // 背景图片加载
    image.onload = function() {
        initCanvasPosition();
        initXYWidthHeight();
        // canvas 位置改变需要重新赋值
        canvasRect = canvas.getBoundingClientRect();
        // 初始化画布等信息
        initBackground();

        pattern = ctx.createPattern(imageRect, 'repeat');
        // 轮播切换更新缓存
        if (!isGetDrawArea && cachesImgMap[imgUrl] && cachesImgMap[imgUrl].layers) {
            layers = cachesImgMap[imgUrl].layers;
            drawAllRect({ moveX: 0, moveY: 0});
        }
        // OCR识别标注
        // getOcrReconizeArea().then(res => {
        //     const recArr = calcCanvasPositionLayers(res);
        //     layers = [...layers, ...recArr];
        //     drawAllGetAreaRect();
        // });
        // getOcrSensitiveArea().then(res => {
        //     const senArr = calcCanvasPositionLayers(res);
        //     layers = [...layers, ...senArr];
        //     drawAllGetAreaRect();
        // });

        canvas.addEventListener("mousedown", mouseDown);
        canvas.addEventListener("mousemove", mouseMove);
        canvas.addEventListener("mouseup", mouseUp);
        canvas.addEventListener("mouseleave", mouseLeave);
        scrollElement.addEventListener('mousewheel', mouseWheel);
        deleteBtn && deleteBtn.addEventListener("click", deleteSelectRect);
        editBtn && editBtn.addEventListener("click", editRect);
        cancelEditBtn && cancelEditBtn.addEventListener("click", cancelEditRect);
        zoomInBtn && zoomInBtn.addEventListener("click", zoomInRect);
        zoomOutBtn && zoomOutBtn.addEventListener("click", zoomOutRect);
        refRotateLeft && refRotateLeft.addEventListener("click", rotateLeftRect);
        refRotateRight && refRotateRight.addEventListener("click", rotateRightRect);
        revertBtn && revertBtn.addEventListener("click", revertRect);
        backBtn && backBtn.addEventListener("click", toBack);
        completeBtn && completeBtn.addEventListener("click", completeOperation);
    }
    
    let beginX = 0, // 拖动或绘制图片的起始坐标X
        beginY = 0; // 拖动或绘制图片的起始坐标Y
    let beginMoveX = 0, // 拖拽图片的起始坐标X
        beginMoveY = 0; // 拖拽图片的起始坐标Y
    let id = 0;
    let clickedIndex = -1; // 点击选中拖拽使用
    let selectIndex = -1; // 点击选中删除待用
    let moveX, // 拖动水平偏移量
        moveY; // 拖动垂直偏移量
    let ifEdit = false; // 是否可以脱敏
    const scaleStep = 1.25; // 每次放缩的倍数
    let scale = 1; // 放缩比例
    let scaleStepCount = 0; // 放大+1，缩小-1，还原为0
    let dragOffset = 5; // 与边的距离

    // 初始化背景图
    function initBackground() {
        // 已经算好的canvas比例
        setCanvasInRotate();
        // 图片过大或者过小等比缩放
        // ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, minWidth,  minWidth * (image.height / image.width));
    }

    // 注意，往左边画交界矩形框，会影响，拖拽不出来
    function mouseDown (event) {
        // if (ifEdit) {
        //     event.preventDefault();
        // } else {
        //     return;
        // }
        canvasRect = canvas.getBoundingClientRect();
        const x = (event.pageX - canvasRect.left) / scale;
        const y = (event.pageY - canvasRect.top) / scale;
        beginX = x;
        beginY = y;
        beginMoveX = (event.pageX - canvasRect.left);
        beginMoveY = (event.pageY - canvasRect.top);
        clickedIndex = -1; // 每次点击重置
        selectIndex = -1; // 每次点击重置
        !isHuaCi && dragedRectIndex(event);
        canMove = true;
        isImgScroll = true;
    }

    function mouseMove (event) {
        canvasRect = canvas.getBoundingClientRect();
        if (ifEdit) {
            // 禁止滑动时跟随滚动条滚动
            event.preventDefault();
        } else if (isCoverSelected) {
            // 选中和点击，继续进行拖动放大
        } else if (canMove) {
            if (isImgScroll && ((scrollElement.scrollWidth > scrollElement.clientWidth)
                || (scrollElement.scrollHeight > scrollElement.clientHeight))) 
            {
                // 未编辑状态，点击以后，且有滚动元素
                let endMoveX = (event.pageX - canvasRect.left);
                let endMoveY = (event.pageY - canvasRect.top);
                let diffX = endMoveX - beginMoveX; // 把照片向左拉时图片实际向右滚动，此时为负值
                let diffY = endMoveY - beginMoveY; // 把照片向上拉时图片实际向下滚动，此时为负值
                beginMoveX = endMoveX - diffX; // 因为往右边移动了，实际的起始坐标变大了，减去移动的负值
                beginMoveY = endMoveY - diffY; // 因为往下边移动了，实际的起始坐标变大了，减去移动的负值
                scrollElement.scrollBy(-diffX, -diffY);
            }
            return;
        } else {
            return;
        }
        // 组件放入轮播时视口位置会实时变化，需要重新计算
        canvas.style.cursor = 'crosshair';
        let x = (event.pageX - canvasRect.left) / scale;
        let y = (event.pageY - canvasRect.top) / scale;
        if (!isHuaCi && isCoverSelected) {
            const item = hoverItem(event);
            item && dragCursor({ x, y }, item);
        }
        // 点了脱敏按钮后必须得mouseDown才能开始操作
        if (!canMove) {
            return;
        }
        ctx.save();
        if ((clickedIndex > -1) && !isHuaCi && isCoverSelected) {
            // 选中矩形 并且非划词状态
            moveX = x - beginX;
            moveY = y - beginY;
            clearAllRect();
            drawAllRect({ moveX: moveX, moveY: moveY, x: x, y: y});
        } else {
            // 绘制新的矩形需要清空之前的信息,因为拉的矩形框一直在生长
            if (isHuaCi) {
                // 画矩形会清空背景
                initBackground();
                // 更新之前的矩形
                drawAllGetAreaRect();
                // 绘制白色的新的矩形
                ctx.strokeStyle = '#ffffff';
                ctx.strokeRect(beginX, beginY, x - beginX, y - beginY);
            } else {
                    // 会反向拉，得清空数据
                    initBackground();
                    drawAllRect({ moveX: 0, moveY: 0});
                    drawRect(beginX, beginY, x - beginX, y - beginY);
            }
        }
        ctx.restore();
    }
    
    function mouseUp (event) {
        // 重置拖动状态
        canMove = false;
        canvas.style.cursor = 'default';
        canvasRect = canvas.getBoundingClientRect();
        let x = (event.pageX - canvasRect.left) / scale;
        let y = (event.pageY - canvasRect.top) / scale;
        let isClick = (beginX === x && beginY === y);
        let isSelected = (selectIndex > -1);
        if (ifEdit) {
            // 禁止滑动时跟随滚动条滚动
            event.preventDefault();
            ifEdit = false; // 一次只能脱敏一次
        } else if (isCoverSelected) {
            // 选中和点击，继续进行拖动放大
            ifEdit = false;
            isCoverSelected = false; // 选中扩大时，一次只能脱敏一次
            callBack('ToBack', false);
        } else if (!isClick) {
            return;
        }
        // 选中矩形拖动结束，更新缓存中被选中元素的坐标
        if (clickedIndex > -1) {
            setDragRectPosition();
        }
        dragDirection = '';
        // 如果从右到左绘制的话，将坐标点设置为左上顶点、右下顶点
        // 以便于判断点击与选中区域，以及传入给后端
        let startX = Math.min(beginX, x);
        let startY = Math.min(beginY, y);
        let stopX = Math.max(beginX, x);
        let stopY = Math.max(beginY, y);
        let xyAxis = {};
        if (rotateDirection === 'top') {
            xyAxis = {
                beginX: startX,
                beginY: startY,
                endX: stopX,
                endY: stopY
            }
        } else if(rotateDirection === 'right') {
            xyAxis = {
                rBeginX: startX,
                rBeginY: startY,
                rEndX: stopX,
                rEndY: stopY
            }
        } else if (rotateDirection === 'bottom') {
            xyAxis = {
                bBeginX: startX,
                bBeginY: startY,
                bEndX: stopX,
                bEndY: stopY
            }
        } else if (rotateDirection === 'left') {
            xyAxis = {
                lBeginX: startX,
                lBeginY: startY,
                lEndX: stopX,
                lEndY: stopY
            }
        }
        // 更新最新绘制的矩形, 有绘制面积的大小
        if (((x - beginX) !== 0 && (y - beginY) !== 0) && (clickedIndex === -1)) {
            if (!isHuaCi) {
                layers.push({
                    id: id++,
                    ...xyAxis,
                    rotateDirection: rotateDirection,
                    mWidth: minWidth,
                    mHeight: minHeight
                });
            } else {
                // 划词识别
                let drawedIndex = -1;
                for (let index = 0; index < layers.length; index++) {
                    const element = layers[index];
                    if (drawOnlyId === element.drawOnlyId) {
                        drawedIndex = index;
                        break;
                    }
                }
                // 如果已经存在此唯一id，则删除对应的此数据，然后追加到最后（因为此时坐标会更新的）
                if ((drawedIndex > -1) && drawOnlyId) { // 存在sort为undefied的
                    layers = layers.filter(val => {
                        return !(drawOnlyId === val.drawOnlyId);
                    });
                }
                // 没有drawOnlyId是纯粹划词识别，不添加页面记录和痕迹的
                // 然后新增 此时OCR的也会变回划词的
                layers.push({
                    id: id++,
                    ...xyAxis,
                    drawOnlyId: drawOnlyId,
                    sort: drawOnlyId,
                    rotateDirection: rotateDirection,
                    isDelimitation: true
                });
                // 旋转提交前需要转换数据
                setNowRotateLayers();
                // 划词处理,清空画布以及数据
                // getDrawAreaOcrResult().then(res => {
                //     // 第一次直接返回，后续每次给回调{
                //     // 每次划词成功给回调
                //     // 被动触发回调
                //     callBack('GetDrawArea', res);
                //     // 手动触发
                //     drawAreaResolve(res);
                //     if (!drawOnlyId) {
                //         // 删除没有对应角标的，纯粹划词记录
                //         layers.pop();
                //     }
                //     // 因为每次划词是白色，结束后是蓝色，此时需要清空以及重新绘制
                //     clearAllRect();
                //     initBackground();
                //     drawAllGetAreaRect();
                //     canvas.style.cursor = 'default';
                // });
            }
        }
        if (!isHuaCi) {
            // 上次点中过，点其他区域隐藏取消，删除按钮
            if (selectIndex === -1 && lastClicked) {
                lastClicked = false;
                clickedIndex = -1;// 重置，以防触发点击时 鼠标悬浮效果
                clearAllRect();
                drawAllRect({ moveX: 0, moveY: 0});
                callBack('ToBack', false);
            }
            // 点击事件，点击选中的浮层
            if (isClick && isSelected) {
                isCoverSelected = true;
                ifEdit = false; // 禁止mousemove进去
                lastClicked = true;
                const val = layers[selectIndex];
                clickedIndex = -1; // 重置，以防触发点击时 鼠标悬浮效果
                clearAllRect();
                drawAllRect({ moveX: 0, moveY: 0});
                let _beginX = '';
                let _beginY = '';
                let _endX = '';
                let _endY = '';
                if (rotateDirection === 'top') {
                    _beginX = val.beginX;
                    _beginY = val.beginY;
                    _endX = val.endX;
                    _endY = val.endY;
                } else if (rotateDirection === 'right') {
                    _beginX = val.rBeginX;
                    _beginY = val.rBeginY;
                    _endX = val.rEndX;
                    _endY = val.rEndY;
                } else if (rotateDirection === 'bottom') {
                    _beginX = val.bBeginX;
                    _beginY = val.bBeginY;
                    _endX = val.bEndX;
                    _endY = val.bEndY;
                } else if (rotateDirection === 'left') {
                    _beginX = val.lBeginX;
                    _beginY = val.lBeginY;
                    _endX = val.lEndX;
                    _endY = val.lEndY;
                }
                drawStrokeRect(_beginX- 1, _beginY - 1, (_endX - _beginX + 2), (_endY - _beginY + 2), '#db8208');
                callBack('ToBack', true);
            }
        }
        // 重置初始状态
        clickedIndex = -1;
        moveX = 0;
        moveY = 0;
        callBack('ChangeTuoMin', ifEdit, 'HuaCiFinish');
        // 旋转提交前需要转换数据
        setNowRotateLayers();
        if (!isGetDrawArea) {
            let areaList = layers.filter(val => !val.drawOnlyId);
            // areaList = getCompleteArealist(areaList);
            areaList = calcRatioLayersInImg(areaList);
            cachesImgMap[imgUrl] = {
                imgData: imgData,
                layers: layers,
                areaList: areaList
            };
            sessionStorage.setItem('CheckTuoMinImgMap', JSON.stringify(cachesImgMap));
        }
        isHuaCi = false; // 划词处理结束, 此时可点击色块删除等操作
    }

    function mouseLeave() {
        isImgScroll = false;
    }

    // 滚轮滚动进行用户视野中心的放大和缩小，每次进行到放大或缩小的百分之十
    function mouseWheel(event) {
        let curWidth = scrollElement.scrollWidth;
        let curHeight = scrollElement.scrollHeight;
        event.preventDefault(); // 禁止自然的滚动
        canvasRect = canvas.getBoundingClientRect();
        let x = (event.pageX - canvasRect.left); // 滚轮距离左边的距离
        let y = (event.pageY - canvasRect.top); // 滚轮距离左边的距离
        let xOPosition = x / curWidth; // 滚轮占X比例
        let yOPosition = y / curHeight; // 滚轮占y比例
        // 以鼠标滚轮作为
        if (event.deltaY < 0) {
            // 向上滚动,放大
            // 屏幕未撑出框前
            if (scrollElement.offsetWidth == curWidth || scrollElement.offsetHeight == curHeight) {
                zoomInRect(true);
            } else {
                zoomInRect(false);
                // 放大之后原先滚轮对应的位置
                let scaleX = xOPosition * scrollElement.scrollWidth;
                let scaleY = yOPosition * scrollElement.scrollHeight;
                scrollElement.scrollBy((scaleX - x), (scaleY - y));
            }
        } else {
            // 向下滚动,缩小
            // 屏幕未撑出框前
            if (scrollElement.offsetWidth == curWidth || scrollElement.offsetHeight == curHeight) {
                zoomOutRect(true);
            } else {
                zoomOutRect(false);
                let scaleX = xOPosition * scrollElement.scrollWidth;
                let scaleY = yOPosition * scrollElement.scrollHeight;
                scrollElement.scrollBy((scaleX - x), (scaleY - y));
            }
        }
    }
    
    function initCanvasFullScreen(imgWidth = '', imgHeight = '') {
        let initWidth = 0;
        let initHeight = 0;
        // 横屏铺满后的宽高
        if (imgWidth >= imgHeight) {
            // 画布横屏父级元素百分之百
            // 满屏屏幕宽度图片对应的屏幕高度
            const fullScreenHeight = refDrawCanvas.offsetWidth * imgHeight / imgWidth;
            // 不能用clientWidth或者clientHeight,因为有轮播的情况会被挡住
            if (refDrawCanvas.offsetHeight >= fullScreenHeight) {
                // 横屏正好可以放进去
                initWidth = refDrawCanvas.offsetWidth;
                initHeight = initWidth * (imgHeight / imgWidth);
            } else {
                // 图片宽度百分之百高度突出去
                initHeight = refDrawCanvas.offsetHeight;
                let fullScreenHeightToWidth = initHeight  * (imgWidth / imgHeight);
                initWidth = fullScreenHeightToWidth;
            }
        } else {
            // 满屏屏幕高度图片对应的屏幕宽度
            const fullScreenWidth = refDrawCanvas.offsetHeight * imgWidth / imgHeight;
            // 画布横屏父级元素百分之百
            if (refDrawCanvas.offsetWidth >= fullScreenWidth) {
                // 竖屏百分之百能放进去
                initHeight = refDrawCanvas.offsetHeight;
                // 按比例设置横屏铺满的宽高
                initWidth = initHeight * (imgWidth / imgHeight);
            } else {
                // 竖屏百分之百，横屏图片超出
                initWidth = refDrawCanvas.offsetWidth;
                // 按比例设置横屏铺满的宽高
                initHeight = initWidth * (imgHeight / imgWidth);
            }
        }
        return {
            initWidth: initWidth,
            initHeight: initHeight
        }
    }

    function initXYWidthHeight() {
        const xInit = initCanvasFullScreen(image.width, image.height);
        xInitWidth = xInit.initWidth;
        xInitHeight = xInit.initHeight;
        const yInit = initCanvasFullScreen(image.height, image.width);
        yInitWidth = yInit.initWidth;
        yInitHeight = yInit.initHeight;
        // canvas 宽高会缩减小数点后的位置
    }

    function setNowRotateLayers() {
        layers = layers.map(val => {
            // 初始朝上但是当前旋转至于朝右的
            if (val.rotateDirection === 'top') {
                // ——>
                let ratio = yInitHeight / xInitWidth;
                let reRatio = xInitWidth / yInitHeight;
                // 竖屏转横屏
                // beginX, beginY 本来就是按比例来的
                let rAxis = {
                    rBeginX: yInitWidth - val.endY * ratio,
                    rBeginY: val.beginX * ratio,
                    rEndX: yInitWidth - val.beginY * ratio,
                    rEndY: val.endX * ratio
                };
                let bAxis = {
                    bBeginX: xInitWidth - rAxis.rEndY * reRatio,
                    bBeginY: rAxis.rBeginX * reRatio,
                    bEndX: xInitWidth - rAxis.rBeginY * reRatio,
                    bEndY: rAxis.rEndX * reRatio
                };
                let lAxis = {
                    lBeginX: yInitWidth - bAxis.bEndY * ratio,
                    lBeginY: bAxis.bBeginX * ratio,
                    lEndX: yInitWidth - bAxis.bBeginY * ratio,
                    lEndY: bAxis.bEndX * ratio
                };
                // if (val.updateBAxis) {
                //     let bAxis = {
                //         bBeginX: xInitWidth - rAxis.rEndY * reRatio,
                //         bBeginY: rAxis.rBeginX * reRatio,
                //         bEndX: xInitWidth - rAxis.rBeginY * reRatio,
                //         bEndY: rAxis.rEndX * reRatio
                //     };
                // } else if (val.updateLAxis) {
                // }
                return { 
                    ...val,
                    ...rAxis,
                    ...bAxis,
                    ...lAxis
                };
            // 初始朝右但是当前旋转至于朝上的
            } else if (val.rotateDirection === 'right') {
                let ratio = yInitHeight / xInitWidth;
                let reRatio = xInitWidth / yInitHeight;
                let bAxis = {
                    bBeginX: xInitWidth - val.rEndY * reRatio,
                    bBeginY: val.rBeginX * reRatio,
                    bEndX: xInitWidth - val.rBeginY * reRatio,
                    bEndY: val.rEndX * reRatio
                };
                let lAxis = {
                    lBeginX: yInitWidth - bAxis.bEndY * ratio,
                    lBeginY: bAxis.bBeginX * ratio,
                    lEndX: yInitWidth - bAxis.bBeginY * ratio,
                    lEndY: bAxis.bEndX * ratio
                };
                let tAxis = {
                    beginX: xInitWidth - lAxis.lEndY * reRatio,
                    beginY: lAxis.lBeginX * reRatio,
                    endX: xInitWidth - lAxis.lBeginY * reRatio,
                    endY: lAxis.lEndX * reRatio
                };
                return {
                    ...val,
                    ...bAxis,
                    ...lAxis,
                    ...tAxis
                }
            } else if (val.rotateDirection === 'bottom') {
                let ratio = yInitHeight / xInitWidth;
                let reRatio = xInitWidth / yInitHeight;
                let lAxis = {
                    lBeginX: yInitWidth - val.bEndY * ratio,
                    lBeginY: val.bBeginX * ratio,
                    lEndX: yInitWidth - val.bBeginY * ratio,
                    lEndY: val.bEndX * ratio
                };
                let tAxis = {
                    beginX: xInitWidth - lAxis.lEndY * reRatio,
                    beginY: lAxis.lBeginX * reRatio,
                    endX: xInitWidth - lAxis.lBeginY * reRatio,
                    endY: lAxis.lEndX * reRatio
                };
                let rAxis = {
                    rBeginX: yInitWidth - tAxis.endY * ratio,
                    rBeginY: tAxis.beginX * ratio,
                    rEndX: yInitWidth - tAxis.beginY * ratio,
                    rEndY: tAxis.endX * ratio
                };
                return {
                    ...val,
                    ...lAxis,
                    ...tAxis,
                    ...rAxis
                }
            } else if (val.rotateDirection === 'left') {
                let ratio = yInitHeight / xInitWidth;
                let reRatio = xInitWidth / yInitHeight;
                let tAxis = {
                    beginX: xInitWidth - val.lEndY * reRatio,
                    beginY: val.lBeginX * reRatio,
                    endX: xInitWidth - val.lBeginY * reRatio,
                    endY: val.lEndX * reRatio
                };
                let rAxis = {
                    rBeginX: yInitWidth - tAxis.endY * ratio,
                    rBeginY: tAxis.beginX * ratio,
                    rEndX: yInitWidth - tAxis.beginY * ratio,
                    rEndY: tAxis.endX * ratio
                };
                let bAxis = {
                    bBeginX: xInitWidth - rAxis.rEndY * reRatio,
                    bBeginY: rAxis.rBeginX * reRatio,
                    bEndX: xInitWidth - rAxis.rBeginY * reRatio,
                    bEndY: rAxis.rEndX * reRatio
                };
                return {
                    ...val,
                    ...tAxis,
                    ...rAxis,
                    ...bAxis
                }
            }
            else {
                return val;
            }
        })
    }

    function initCanvasPosition() {
        // 横屏铺满
        if (image.width >= image.height) {
            // 画布横屏父级元素百分之百
            // 满屏屏幕宽度图片对应的屏幕高度
            const fullScreenHeight = refDrawCanvas.offsetWidth * image.height / image.width;
            // 不能用clientWidth或者clientHeight,因为有轮播的情况会被挡住
            if (refDrawCanvas.offsetHeight >= fullScreenHeight) {
                // 横屏正好可以放进去
                minWidth = refDrawCanvas.offsetWidth;
                //画布横屏父级元素百分之百
                canvas.width = minWidth;
                // 按比例设置横屏铺满的宽高
                minHeight = minWidth * (image.height / image.width);
                canvas.height = minHeight;
                // 设置图片在居中的位置
                // parentElement
                canvas.style.marginLeft = '0px'; //  因为复用一张图片，重置状态
                canvas.style.marginTop = refDrawCanvas.offsetHeight / 2 - canvas.height / 2 + 'px';
                canvas.style.backgroundSize =`${canvas.width}px ${canvas.height}px`;
            } else {
                // 图片宽度百分之百高度突出去
                minHeight = refDrawCanvas.offsetHeight;
                let fullScreenHeightToWidth = minHeight  * (image.width / image.height);
                minWidth = fullScreenHeightToWidth;
                canvas.width = minWidth;
                canvas.height = minHeight;
                // 设置图片在居中的位置
                // 不能如下设置，不然会滚动
                canvas.style.marginTop = '0px'; //  因为复用一张图片，重置状态
                canvas.style.marginLeft = refDrawCanvas.offsetWidth / 2 - canvas.width / 2 + 'px';
                canvas.style.backgroundSize =`${canvas.width}px ${canvas.height}px`;
            }
        } else {
            // 满屏屏幕高度图片对应的屏幕宽度
            const fullScreenWidth = refDrawCanvas.offsetHeight * image.width / image.height;
            // 画布横屏父级元素百分之百
            if (refDrawCanvas.offsetWidth >= fullScreenWidth) {
                // 竖屏百分之百能放进去
                minHeight = refDrawCanvas.offsetHeight;
                canvas.height = minHeight;
                // 按比例设置横屏铺满的宽高
                minWidth = minHeight * (image.width / image.height);
                canvas.width = minWidth;
                canvas.style.marginTop = '0px'; //  因为复用一张图片，重置状态
                // 设置图片在居中的位置
                canvas.style.marginLeft = refDrawCanvas.offsetWidth / 2 - canvas.width / 2 + 'px';
                canvas.style.backgroundSize =`${canvas.width}px ${canvas.height}px`;
            } else {
                // 竖屏百分之百，横屏图片超出
                minWidth = refDrawCanvas.offsetWidth;
                //画布横屏父级元素百分之百
                canvas.width = minWidth;
                // 按比例设置横屏铺满的宽高
                minHeight = minWidth * (image.height / image.width);
                canvas.height = minHeight;
                canvas.style.marginLeft = '0px'; //  因为复用一张图片，重置状态
                // 设置图片在居中的位置
                canvas.style.marginTop = refDrawCanvas.offsetHeight / 2 - canvas.height / 2 + 'px';
                canvas.style.backgroundSize =`${canvas.width}px ${canvas.height}px`;
            }
        }
    }

    // 旋转重新设置canvas容器
    function initCanvasRotatePosition() {
        // 旋转后交换图片宽高
        let rImgWidth = image.height;
        let rImgHeight = image.width;
        // 横屏铺满
        if (rImgWidth >= rImgHeight) {
            // 画布横屏父级元素百分之百
            // 满屏屏幕宽度图片对应的屏幕高度
            const fullScreenHeight = refDrawCanvas.offsetWidth * rImgHeight / rImgWidth;
            // 不能用clientWidth或者clientHeight,因为有轮播的情况会被挡住
            if (refDrawCanvas.offsetHeight >= fullScreenHeight) {
                // 横屏正好可以放进去
                minWidth = refDrawCanvas.offsetWidth;
                //画布横屏父级元素百分之百
                canvas.width = minWidth;
                // 按比例设置横屏铺满的宽高
                minHeight = minWidth * (rImgHeight / rImgWidth);
                canvas.height = minHeight;
                // 设置图片在居中的位置
                // parentElement
                canvas.style.marginLeft = '0px'; //  因为复用一张图片，重置状态
                canvas.style.marginTop = refDrawCanvas.offsetHeight / 2 - canvas.height / 2 + 'px';
                canvas.style.backgroundSize =`${canvas.width}px ${canvas.height}px`;
            } else {
                // 图片宽度百分之百高度突出去
                minHeight = refDrawCanvas.offsetHeight;
                let fullScreenHeightToWidth = minHeight  * (rImgWidth / rImgHeight);
                minWidth = fullScreenHeightToWidth;
                canvas.width = minWidth;
                canvas.height = minHeight;
                // 设置图片在居中的位置
                // 不能如下设置，不然会滚动
                canvas.style.marginTop = '0px'; //  因为复用一张图片，重置状态
                canvas.style.marginLeft = refDrawCanvas.offsetWidth / 2 - canvas.width / 2 + 'px';
                canvas.style.backgroundSize =`${canvas.width}px ${canvas.height}px`;
            }
        } else {
            // 满屏屏幕高度图片对应的屏幕宽度
            const fullScreenWidth = refDrawCanvas.offsetHeight * rImgWidth / rImgHeight;
            // 画布横屏父级元素百分之百
            if (refDrawCanvas.offsetWidth >= fullScreenWidth) {
                // 竖屏百分之百能放进去
                minHeight = refDrawCanvas.offsetHeight;
                canvas.height = minHeight;
                // 按比例设置横屏铺满的宽高
                minWidth = minHeight * (rImgWidth / rImgHeight);
                canvas.width = minWidth;
                canvas.style.marginTop = '0px'; //  因为复用一张图片，重置状态
                // 设置图片在居中的位置
                canvas.style.marginLeft = refDrawCanvas.offsetWidth / 2 - canvas.width / 2 + 'px';
                canvas.style.backgroundSize =`${canvas.width}px ${canvas.height}px`;
            } else {
                // 竖屏百分之百，横屏图片超出
                minWidth = refDrawCanvas.offsetWidth;
                //画布横屏父级元素百分之百
                canvas.width = minWidth;
                // 按比例设置横屏铺满的宽高
                minHeight = minWidth * (rImgHeight / rImgWidth);
                canvas.height = minHeight;
                canvas.style.marginLeft = '0px'; //  因为复用一张图片，重置状态
                // 设置图片在居中的位置
                canvas.style.marginTop = refDrawCanvas.offsetHeight / 2 - canvas.height / 2 + 'px';
                canvas.style.backgroundSize =`${canvas.width}px ${canvas.height}px`;
            }
        }
    }

    // 放大后超出屏幕按中心查看以及设置滚动
    function setScrollPositionCenter() {
        let _imgWidth = image.width;
        let _imgHeight = image.height;
        let times = (rotateLeftTimes + rotateRightTimes) % 4;
        let absTimes = Math.abs(times);
        if (absTimes == 1 || absTimes == 3) {
            // 旋转正负90度
            _imgWidth = image.height;
            _imgHeight = image.width;
        }
        // 画布大小在矩形框内时
        if (canvas.width <= refDrawCanvas.offsetWidth && canvas.height <= refDrawCanvas.offsetHeight) {
            canvas.style.marginTop = refDrawCanvas.offsetHeight / 2 - canvas.height / 2 + 'px';
            canvas.style.marginLeft = refDrawCanvas.offsetWidth / 2 - canvas.width / 2 + 'px';
        // 横长方形
        } else if (_imgWidth >= _imgHeight) {
            // 放大后横向超出，竖直仍未超出（放大两次再缩小一次也是这样，下同）
            if (refDrawCanvas.offsetHeight >= canvas.height) {
                canvas.style.marginLeft = '0px';
                canvas.style.marginTop = refDrawCanvas.offsetHeight / 2 - canvas.height / 2 + 'px';
                scrollElement.scrollTo((canvas.width - minWidth) / 2, 0);
            } else {
                // 放大后横向超出，竖直也超出
                canvas.style.marginLeft = '0px';
                canvas.style.marginTop = '0px';
                scrollElement.scrollTo((canvas.width - refDrawCanvas.offsetWidth) / 2, (canvas.height - refDrawCanvas.offsetHeight) / 2);
            }
        // 竖长方形
        } else {
            // 放大后竖直超出，横向仍未超出
            if (refDrawCanvas.offsetWidth >= canvas.width) {
                canvas.style.marginTop = '0px';
                canvas.style.marginLeft = refDrawCanvas.offsetWidth / 2 - canvas.width / 2 + 'px';
                scrollElement.scrollTo(0, (canvas.height - minHeight) / 2);
            } else {
                // 放大后竖直超出，横向仍也超出
                canvas.style.marginLeft = '0px';
                canvas.style.marginTop = '0px';
                scrollElement.scrollTo((canvas.width - refDrawCanvas.offsetWidth) / 2, (canvas.height - refDrawCanvas.offsetHeight) / 2);
            }
        }
    }

    // 旋转时最长边贴近，此时图片回复到原始相框大小
    function setRotatePositionCenter(times) {
        // 旋转正负九十度，长宽比例互换
        let absTimes = Math.abs(times);
        if (absTimes == 1 || absTimes == 3) {
            initCanvasRotatePosition();
        }
        // 旋转180度，360度，宽高与初始相同
        if (absTimes == 2 || absTimes == 0) {
            initCanvasPosition();
        }
    }
    
    function drawRect(
        beginX,
        beginY,
        width,
        height,
    ) {
        // 图片铺满
        // 划词需要同时脱敏正常
        if (isHuaCi) {
            ctx.strokeRect(beginX, beginY, width, height);
        } else {
            if (firstLoadCoverImg) {
                // 防止这个图片没加载出来
                pattern = ctx.createPattern(imageRect, 'repeat');
                firstLoadCoverImg = false;
            }
            ctx.fillStyle = pattern;
            ctx.fillRect(beginX, beginY, width, height);
        }
    }

    function drawHuaCiAndTuoMinRect(
        beginX,
        beginY,
        width,
        height,
        val
    ) {
        // 划词的矩形区域
        if (val && val.drawOnlyId) {
            // 重新绘制划词
           if (val.isDelimitation) {
                // 划词、绘制为蓝色
                drawRectAfterNumber(val.beginX, val.beginY, (val.endX - val.beginX), (val.endY - val.beginY), "#1890ff"); // 蓝色
                drawNumberBeforeRect(val.beginX, val.beginY, val.sort, '#1890ff'); // 蓝色
            } else {
                // OCR自动识别，经过划词后就变为蓝色了
                drawRectAfterNumber(val.beginX, val.beginY, (val.endX - val.beginX), (val.endY - val.beginY), "#1fcc23"); // 绿色
                drawNumberBeforeRect(val.beginX, val.beginY, val.sort, '#1fcc23'); // 绿色
            }
        } else {
            // 脱敏图片背景
            ctx.fillStyle = pattern;
            ctx.fillRect(beginX, beginY, width, height); 
        }
    }
    
    // 判断点击的是否在选中输入框内
    // 坐标存储需从左边，因为可能是从右到左画的，从下到上画的。
    function dragedRectIndex (event) {
        const clickX = (event.pageX  - canvasRect.left) / scale;
        const clickY = (event.pageY - canvasRect.top) / scale;
        layers.forEach((item, index) => {
            let _beginX = '';
            let _beginY = '';
            let _endX = '';
            let _endY = '';
            if (rotateDirection === 'top') {
                _beginX = item.beginX;
                _beginY = item.beginY;
                _endX = item.endX;
                _endY = item.endY;
            } else if (rotateDirection === 'right') {
                _beginX = item.rBeginX;
                _beginY = item.rBeginY;
                _endX = item.rEndX;
                _endY = item.rEndY;
            } else if (rotateDirection === 'bottom') {
                _beginX = item.bBeginX;
                _beginY = item.bBeginY;
                _endX = item.bEndX;
                _endY = item.bEndY;
            } else if (rotateDirection === 'left') {
                _beginX = item.lBeginX;
                _beginY = item.lBeginY;
                _endX = item.lEndX;
                _endY = item.lEndY;
            }
            if ((clickX >= _beginX && clickX <= _endX)
             && (clickY >= _beginY && clickY <= _endY)) {
                 // OCR识别以及敏感信息矩形框不应被选中
                 if (!item.drawOnlyId) {
                    clickedIndex = index;
                    selectIndex = index;
                 }
            }
        });
    }

    // 悬浮选中的矩形
    function hoverItem (event) {
        const clickX = (event.pageX - canvasRect.left) / scale;
        const clickY = (event.pageY - canvasRect.top) / scale;
        let target = null;
        layers.forEach((item) => {
            let _beginX = '';
            let _beginY = '';
            let _endX = '';
            let _endY = '';
            if (rotateDirection === 'top') {
                _beginX = item.beginX;
                _beginY = item.beginY;
                _endX = item.endX;
                _endY = item.endY;
            } else if (rotateDirection === 'right') {
                _beginX = item.rBeginX;
                _beginY = item.rBeginY;
                _endX = item.rEndX;
                _endY = item.rEndY;
            } else if (rotateDirection === 'bottom') {
                _beginX = item.bBeginX;
                _beginY = item.bBeginY;
                _endX = item.bEndX;
                _endY = item.bEndY;
            } else if (rotateDirection === 'left') {
                _beginX = item.lBeginX;
                _beginY = item.lBeginY;
                _endX = item.lEndX;
                _endY = item.lEndY;
            }
            if ((clickX >= _beginX && clickX <= _endX)
                && (clickY >= _beginY && clickY <= _endY)) {
                target = item;
            }
        });
        return target;
    }

    // 鼠标悬浮时鼠标变化
    function dragCursor({ x = 0, y = 0 }, item) {
        let _beginX = '';
        let _beginY = '';
        let _endX = '';
        let _endY = '';
        if (rotateDirection === 'top') {
            _beginX = item.beginX;
            _beginY = item.beginY;
            _endX = item.endX;
            _endY = item.endY;
        } else if (rotateDirection === 'right') {
            _beginX = item.rBeginX;
            _beginY = item.rBeginY;
            _endX = item.rEndX;
            _endY = item.rEndY;
        } else if (rotateDirection === 'bottom') {
            _beginX = item.bBeginX;
            _beginY = item.bBeginY;
            _endX = item.bEndX;
            _endY = item.bEndY;
        } else if (rotateDirection === 'left') {
            _beginX = item.lBeginX;
            _beginY = item.lBeginY;
            _endX = item.lEndX;
            _endY = item.lEndY;
        }
        if (dragDirection === 'leftTop' || ((x - _beginX) <= dragOffset && (y - _beginY) <= dragOffset)) {
            canvas.style.cursor = 'se-resize';
        }
        // 向左下拉
        else if (dragDirection === 'leftBottom' || ((x - _beginX) <= dragOffset && (_endY - y) <= dragOffset)) {
            canvas.style.cursor = 'ne-resize';
        }
        // 向右上拉
        else if (dragDirection === 'rightTop' || ((_endX - x) <= dragOffset && (y - _beginY) <= dragOffset)) {
            canvas.style.cursor = 'ne-resize';
        }
        // 向右下拉
        else if (dragDirection === 'rightBottom' || ((_endX - x) <= dragOffset && (_endY - y) <= dragOffset)) {
            canvas.style.cursor = 'se-resize';
        }
        // 向上拉
        else if (dragDirection === 'top' || ((y - _beginY) <= dragOffset)) {
            canvas.style.cursor = 's-resize';
        }
        // 向下拉
        else if (dragDirection === 'bottom' || ((_endY - y) <= dragOffset)) {
            canvas.style.cursor = 's-resize';
        }
        // 向左拉
        else if (dragDirection === 'left' || ((x - _beginX) <= dragOffset)) {
            canvas.style.cursor='w-resize';
        }
        // 向右拉
        else if (dragDirection === 'right' || ((_endX - x) <= dragOffset)) {
            canvas.style.cursor='w-resize';
        }
        // 整个拖动
        else {
            canvas.style.cursor= 'move';
        }
    }

    // 重新绘制所有矩形
    function drawAllRect({ moveX = 0, moveY = 0, x = 0, y = 0}) {
        initBackground();
        layers.forEach((item, index) => {
            let _beginX = '';
            let _beginY = '';
            let _endX = '';
            let _endY = '';
            if (rotateDirection === 'top') {
                _beginX = item.beginX;
                _beginY = item.beginY;
                _endX = item.endX;
                _endY = item.endY;
            } else if (rotateDirection === 'right') {
                _beginX = item.rBeginX;
                _beginY = item.rBeginY;
                _endX = item.rEndX;
                _endY = item.rEndY;
            } else if (rotateDirection === 'bottom') {
                _beginX = item.bBeginX;
                _beginY = item.bBeginY;
                _endX = item.bEndX;
                _endY = item.bEndY;
            } else if (rotateDirection === 'left') {
                _beginX = item.lBeginX;
                _beginY = item.lBeginY;
                _endX = item.lEndX;
                _endY = item.lEndY;
            }
            if ((clickedIndex === index) && isCoverSelected) {
                // 选中的矩形随着偏移量重新绘制以实现拖动效果
                // 抓住矩形时，防止抓住移动过程中碰到别的矩形进行放大、缩小等操作
                if (dragDirection === 'center') {
                    drawRect(_beginX + moveX, _beginY + moveY, _endX - _beginX, _endY - _beginY);
                }
                // 向左上拉
                else if (dragDirection === 'leftTop' || ((x - _beginX) <= dragOffset && (y - _beginY) <= dragOffset)) {
                    dragDirection = 'leftTop';
                    canvas.style.cursor = 'se-resize';
                    // moveX 为负值 moveY 负值
                    drawRect(_beginX + moveX, _beginY + moveY, _endX - _beginX - moveX, _endY - _beginY - moveY);
                }
                // 向左下拉
                else if (dragDirection === 'leftBottom' || ((x - _beginX) <= dragOffset && (_endY - y) <= dragOffset)) {
                    dragDirection = 'leftBottom';
                    canvas.style.cursor = 'ne-resize';
                    // moveX 为负值 moveY 正值
                    drawRect(_beginX + moveX, _beginY, _endX - _beginX - moveX, _endY - _beginY + moveY);
                }
                // 向右上拉
                else if (dragDirection === 'rightTop' || ((_endX - x) <= dragOffset && (y - _beginY) <= dragOffset)) {
                    dragDirection = 'rightTop';
                    canvas.style.cursor = 'ne-resize';
                    // moveX 为正值 moveY 负值
                    drawRect(_beginX, _beginY + moveY, _endX - _beginX + moveX, _endY - _beginY - moveY);
                }
                // 向右下拉
                else if (dragDirection === 'rightBottom' || ((_endX - x) <= dragOffset && (_endY - y) <= dragOffset)) {
                    dragDirection = 'rightBottom';
                    canvas.style.cursor = 'se-resize';
                    // moveX 为正值 moveY 正值
                    drawRect(_beginX, _beginY, _endX - _beginX + moveX, _endY - _beginY + moveY);
                }
                // 向上拉
                else if (dragDirection === 'top' || ((y - _beginY) <= dragOffset)) {
                    dragDirection = 'top';
                    canvas.style.cursor = 's-resize';
                    drawRect(_beginX, _beginY + moveY, _endX - _beginX, _endY - _beginY - moveY);
                }
                // 向下拉
                else if (dragDirection === 'bottom' || ((_endY - y) <= dragOffset)) {
                    dragDirection = 'bottom';
                    canvas.style.cursor = 's-resize';
                    drawRect(_beginX, _beginY, _endX - _beginX, _endY - _beginY + moveY);
                }
                // 向左拉
                else if (dragDirection === 'left' || ((x - _beginX) <= dragOffset)) {
                    dragDirection = 'left';
                    canvas.style.cursor= 'w-resize';
                    drawRect(_beginX + moveX, _beginY, _endX - _beginX - moveX, _endY - _beginY);
                }
                // 向右拉
                else if (dragDirection === 'right' || ((_endX - x) <= dragOffset)) {
                    dragDirection = 'right';
                    canvas.style.cursor= 'w-resize';
                    drawRect(_beginX, _beginY, _endX - _beginX + moveX, _endY - _beginY);
                }
                // 整个拖动
                else {
                    dragDirection = 'center';
                    canvas.style.cursor= 'move';
                    drawRect(_beginX + moveX, _beginY + moveY, _endX - _beginX, _endY - _beginY);
                }
            } else {
                    const strokeColor =  item.drawOnlyId >= 10000 ? '#db8208' : item.isDelimitation ? '#1890ff': '#1fcc23'; // 橙、蓝、绿
                    // 绘制划词区域
                    item.drawOnlyId && drawRectAfterNumber(_beginX, _beginY, (_endX - _beginX), (_endY - _beginY), strokeColor);
                    item.drawOnlyId && item.drawOnlyId < 10000 && drawNumberBeforeRect(_beginX, _beginY, item.drawOnlyId, strokeColor);
                    // 绘制脱敏区域
                    !item.drawOnlyId && drawHuaCiAndTuoMinRect(_beginX, _beginY, (_endX - _beginX), (_endY - _beginY), item);
            }
        });
    }

    // 重新绘制所有划词矩形
    function drawAllGetAreaRect() {
        layers.forEach(val => {
            let _beginX = '';
            let _beginY = '';
            let _endX = '';
            let _endY = '';
            if (rotateDirection === 'top') {
                _beginX = val.beginX;
                _beginY = val.beginY;
                _endX = val.endX;
                _endY = val.endY;
            } else if (rotateDirection === 'right') {
                _beginX = val.rBeginX;
                _beginY = val.rBeginY;
                _endX = val.rEndX;
                _endY = val.rEndY;
            } else if (rotateDirection === 'bottom') {
                _beginX = val.bBeginX;
                _beginY = val.bBeginY;
                _endX = val.bEndX;
                _endY = val.bEndY;
            } else if (rotateDirection === 'left') {
                _beginX = val.lBeginX;
                _beginY = val.lBeginY;
                _endX = val.lEndX;
                _endY = val.lEndY;
            }
            const strokeColor = val.drawOnlyId >= 10000 ? '#db8208' : val.isDelimitation ? '#1890ff': '#1fcc23'; // 橙、蓝、绿
            val.drawOnlyId && drawRectAfterNumber(_beginX, _beginY, (_endX - _beginX), (_endY - _beginY), strokeColor);
            val.drawOnlyId && val.drawOnlyId < 10000 && drawNumberBeforeRect(_beginX, _beginY, val.drawOnlyId, strokeColor);
            // 绘制脱敏区域
            !val.drawOnlyId && drawHuaCiAndTuoMinRect(_beginX, _beginY, (_endX - _beginX), (_endY - _beginY), val);
        });
    }
    
    // 更新缓存中选中的矩形坐标
    function setDragRectPosition() {
        const _moveX = moveX ? moveX : 0;
        const _moveY = moveY ? moveY : 0;
        layers.forEach((item, index) => {
            if (clickedIndex === index) {
                let _beginX = '';
                let _beginY = '';
                let _endX = '';
                let _endY = '';
                if (rotateDirection === 'top') {
                    _beginX = 'beginX';
                    _beginY = 'beginY';
                    _endX = 'endX';
                    _endY = 'endY';
                } else if (rotateDirection === 'right') {
                    _beginX = 'rBeginX';
                    _beginY = 'rBeginY';
                    _endX = 'rEndX';
                    _endY = 'rEndY';
                } else if (rotateDirection === 'bottom') {
                    _beginX = 'bBeginX';
                    _beginY = 'bBeginY';
                    _endX = 'bEndX';
                    _endY = 'bEndY';
                } else if (rotateDirection === 'left') {
                    _beginX = 'lBeginX';
                    _beginY = 'lBeginY';
                    _endX = 'lEndX';
                    _endY = 'lEndY';
                }
                if (dragDirection === 'leftTop') {
                    // 左上斜拉时右下是不变，endX,endY,不变
                    item[_beginX] = item[_beginX] + _moveX;
                    item[_beginY] = item[_beginY] + _moveY;
                } else if (dragDirection === 'leftBottom') {
                    // 左下斜拉时右上不变，beginY,endX不变
                    item[_beginX] = item[_beginX] + _moveX;
                    item[_endY] = item[_endY] + _moveY;
                } else if (dragDirection === 'rightTop') {
                    // 右上斜拉时右上不变，beginX,endY不变
                    item[_beginY] = item[_beginY] + _moveY;
                    item[_endX] = item[_endX] + _moveX;
                } else if(dragDirection === 'rightBottom') {
                    // 右下斜拉时左上不变，beginX,beginY不变
                    item[_endX] = item[_endX] + _moveX;
                    item[_endY] = item[_endY] + _moveY;
                } else if(dragDirection === 'top') {
                    // 上拉只变beginY
                    item[_beginY] = item[_beginY] + _moveY;
                } else if(dragDirection === 'bottom') {
                    // 下拉只变endY
                    item[_endY] = item[_endY] + _moveY;
                } else if(dragDirection === 'left') {
                    // 左拉只变beginX
                    item[_beginX] = item[_beginX] + _moveX;
                } else if(dragDirection === 'right') {
                    // 右拉只变endX
                    item[_endX] = item[_endX] + _moveX;
                }
                else {
                    // 选中和拖动
                    item[_beginX] = item[_beginX] + _moveX;
                    item[_beginY] = item[_beginY] + _moveY;
                    item[_endX] = item[_endX] + _moveX;
                    item[_endY] = item[_endY] + _moveY;
                }
                // 注意，此时，top绘制矩形，然后旋转后，再拖动扩大此矩形区域，但是其他对应的坐标计算未更新。move拖动也是一样
                // 在别的转向绘制，然后此当前转向拖动，扩大后要更新所依赖的计算项
            }
        });
    }
    
    // 清除所有矩形
    function clearAllRect() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
    
    // 点击删除
    function deleteSelectRect(event) {
        layers = layers.filter((val, index) => {
            return selectIndex !== index;
        })
        if (selectIndex > -1) {
            drawAllRect({ moveX: 0, moveY: 0});
        }
        selectIndex = -1;
        if (!isGetDrawArea) {
            let areaList = layers.filter(val => !val.drawOnlyId);
            // areaList = getCompleteArealist(areaList);
            areaList = calcRatioLayersInImg(areaList);
            // 存储更新当前的脱敏遮罩
            cachesImgMap[imgUrl] = {
                imgData: imgData,
                layers: layers,
                areaList: areaList
            };
            sessionStorage.setItem('CheckTuoMinImgMap', JSON.stringify(cachesImgMap));
        }
        callBack('ToBack', false);
        isCoverSelected = false;
        canvas.style.cursor = 'default';
    }

    // 点击脱敏（此时可以绘制图片）
    function editRect(event) {
        canvas.style.cursor = 'crosshair';
        ifEdit = !ifEdit;
        // 脱敏则取消划词状态
        ifEdit && (isHuaCi = false);
        callBack('ChangeTuoMin', ifEdit);
    }

    function cancelEditRect() {
        canvas.style.cursor = 'default';
        ifEdit = !ifEdit;
        callBack('ChangeTuoMin', ifEdit);
    }

    function toBack() {
        // 取消编辑，清除画线矩形
        if (isHuaCi) {
            clearAllRect();
            initBackground();
            drawAllGetAreaRect();
        } else {
            clearAllRect();
            initBackground();
            drawAllRect({ moveX: 0, moveY: 0});
        }
        canvas.style.cursor = 'default';
        isCoverSelected = false;
        callBack('ToBack', false);
    }
    
    function zoomInRect(event) {
        scaleStepCount++;
        if (scaleStepCount === 0) {
            canvas.width = minWidth;
            canvas.height = minHeight;
        } else {
            canvas.width *= scaleStep;
            canvas.height *= scaleStep;
        }
        scale = canvas.height / minHeight;
        ctx.scale(scale,scale);
        canvas.style.backgroundSize =`${canvas.width}px ${canvas.height}px`;
        if (isHuaCi) {
            initBackground();
            drawAllGetAreaRect();
        } else {
            drawAllRect({ moveX: 0, moveY: 0});
        }
        if (event) {
            setScrollPositionCenter();
        }
    }

    function zoomOutRect(event) {
        scaleStepCount--;
        if (scaleStepCount === 0) {
            canvas.width = minWidth;
            canvas.height = minHeight;
        } else {
            canvas.width /= scaleStep;
            canvas.height /= scaleStep;
        }
        scale = canvas.height / minHeight;
        ctx.scale(scale, scale);
        canvas.style.backgroundSize =`${canvas.width}px ${canvas.height}px`;
        if (isHuaCi) {
            initBackground();
            drawAllGetAreaRect();
        } else {
            drawAllRect({ moveX: 0, moveY: 0});
        }
        if (event) {
            setScrollPositionCenter();
        }
    }
    
    function revertRect(event) {
        rotateDirection = 'top';
        scaleStepCount = 0;
        // initCanvasPosition();
        let times = (rotateLeftTimes + rotateRightTimes) % 4;
        let absTimes = Math.abs(times);
        // 旋转180度，360度，宽高与初始相同
        if (absTimes == 0) {
            // 正直
            canvas.width = xInitWidth; // minWidth
            canvas.height = xInitHeight; // minHeight
            scale = canvas.height / xInitHeight; // minHeight
            ctx.scale(scale, scale);
        } else {
            // 旋转后放缩归零
            canvas.width = xInitWidth;
            canvas.height = xInitHeight;
            scale = 1;
            initCanvasPosition();
        }
        canvas.style.backgroundSize =`${canvas.width}px ${canvas.height}px`;
        rotateLeftTimes = 0;
        rotateRightTimes = 0;
        if (isHuaCi) {
            initBackground();
            drawAllGetAreaRect();
        }  else {
            drawAllRect({ moveX: 0, moveY: 0});
        }
        setScrollPositionCenter();
    }

    let rotateLeftTimes = 0;
    // 左旋转
    function rotateLeftRect() {
        scale = 1;
        rotateLeftTimes--;
        clearAllRect();
        let times = (rotateLeftTimes + rotateRightTimes) % 4;
        // 设置旋转canvas百分之百撑满
        setRotatePositionCenter(times);
        // 设置旋转后的图片撑满
        setCanvasInRotate();
        // 需要计算，可能一开始只有敏感信息或者OCR识别记录
        setNowRotateLayers();
        drawAllGetAreaRect();
    }

    let rotateRightTimes = 0;
    // 右旋转
    function rotateRightRect() {
        scale = 1;
        rotateRightTimes++;
        clearAllRect();
        let times = (rotateLeftTimes + rotateRightTimes) % 4;
        // 设置旋转canvas百分之百撑满
        setRotatePositionCenter(times);
        // 设置旋转后的图片撑满
        setCanvasInRotate();
        // 需要计算，可能一开始只有敏感信息或者OCR识别记录
        setNowRotateLayers();
        drawAllGetAreaRect();
    }

    // 设置旋转的脱敏坐标。
    function setCanvasInRotate() {
        ctx.save();
        let times = (rotateLeftTimes + rotateRightTimes) % 4;
        if (times == 1 || times == -3) {
            // 右上角开始绘制
            ctx.translate(minWidth, 0);
            ctx.rotate(times * 90 * Math.PI / 180);
            ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, minHeight,  minWidth); // 旋转，比例反向变化
            rotateDirection = 'right';
        }
        if (times == 2 || times == -2) {
            // 右下角开始绘制
            ctx.translate(minWidth, minHeight);
            ctx.rotate(times * 90 * Math.PI / 180);
            ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, minWidth,  minHeight);
            rotateDirection = 'bottom';
        }
        if (times == 3 || times == -1) {
            // 左下角开始绘制
            ctx.translate(0, minHeight);
            ctx.rotate(times * 90 * Math.PI / 180);
            ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, minHeight,  minWidth); // 旋转，比例反向变化
            rotateDirection = 'left';
        }
        if (times == 0) {
            // 原点开始绘制
            ctx.translate(0, 0);
            ctx.rotate(times * 90 * Math.PI / 180);
            ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, minWidth,  minWidth * (image.height / image.width));
            rotateDirection = 'top';
        }
        ctx.restore();
    }

    // 提交数据时还原对应的坐标比例
    function resetRelativeRect(resetLayers) {
        const scaleable = resetLayers.map(val => {
            return {
                id: val.id,
                beginX: val.beginX * scale,
                beginY: val.beginY * scale,
                endX: val.endX * scale,
                endY: val.endY * scale
            };
        });
        return scaleable;
    }

    function getCompleteArealist(arr) {
        let targetLayers = arr;
        // 针对于放大缩小，还原屏幕绘制的在图片位置的相对坐标
        if (scale !== 1) {
            targetLayers = resetRelativeRect(targetLayers);
        }
        return calcRatioLayers(targetLayers);
    }

    function completeOperation(event) {
        let targetLayers = layers;
        // 针对于放大缩小，还原屏幕绘制的在图片位置的相对坐标
        if (scale !== 1) {
            targetLayers = resetRelativeRect(layers);
        }
        callBack('Complete', calcRatioLayers(targetLayers));
    }

    function removeEvent() {
        canvas.removeEventListener("mousedown", mouseDown);
        canvas.removeEventListener("mousemove", mouseMove);
        canvas.removeEventListener("mouseup", mouseUp);
        canvas.removeEventListener("mouseleave", mouseLeave);
        scrollElement.removeEventListener('mousewheel', mouseWheel);
        deleteBtn && deleteBtn.removeEventListener("click", deleteSelectRect);
        editBtn && editBtn.removeEventListener("click", editRect);
        cancelEditBtn && cancelEditBtn.removeEventListener("click", cancelEditRect);
        zoomInBtn && zoomInBtn.removeEventListener("click", zoomInRect);
        zoomOutBtn && zoomOutBtn.removeEventListener("click", zoomOutRect);
        refRotateLeft && refRotateLeft.removeEventListener("click", rotateLeftRect);
        refRotateRight && refRotateRight.removeEventListener("click", rotateRightRect);
        revertBtn && revertBtn.removeEventListener("click", revertRect);
        backBtn && backBtn.removeEventListener("click", toBack);
        completeBtn && completeBtn.removeEventListener("click", completeOperation);
    }

    // 计算坐标在图片的相对位置
    function calcRatioLayers(calcLayers) {
        const calcResultLayers = calcLayers.map((val, index) => {
            // 百分比乘以实际图片宽高 -> (val.beginX / canvas.width)  * image.width 
            return {
                beginX: val.beginX  * image.width / canvas.width, // minWidth
                beginY: val.beginY * image.height / canvas.height, // mHeight
                endX: val.endX * image.width / canvas.width, // minWidth
                endY: val.endY * image.height / canvas.height // mHeight
            }
        });
        return calcResultLayers;
    }

    // 计算坐标在图片的相对位置
    function calcRatioLayersInImg(calcLayers) {
        const calcResultLayers = calcLayers.map((val, index) => {
            // 百分比乘以实际图片宽高 -> (val.beginX / canvas.width)  * image.width 
            return {
                beginX: val.beginX  * image.width / xInitWidth, // minWidth
                beginY: val.beginY * image.height / xInitHeight, // mHeight
                endX: val.endX * image.width / xInitWidth, // minWidth
                endY: val.endY * image.height / xInitHeight // mHeight
            }
        });
        return calcResultLayers;
    }

    // 还原图片的真实位置在canvas中的位置
    function calcCanvasPositionLayers(calcLayers) {
        const calcResultLayers = calcLayers.map((val, index) => {
            // 按比例还原图片真实宽高
            return {
                ...val,
                rotateDirection: 'top',
                beginX: (val.beginX / image.width) * canvas.width,
                beginY: (val.beginY / image.height) * canvas.height,
                endX: (val.endX / image.width) * canvas.width,
                endY: (val.endY / image.height) * canvas.height
            }
        });
        return calcResultLayers;
    }

    // 划词处理
    function getDrawArea(param) {
        // 开始滑屏处理
        // 主动触发滑屏处理
        drawAreaData = param;
        drawOnlyId = param.sort;
        ifEdit = true;
        isHuaCi = true; // 划词开始
        // 光标变化
        canvas.style.cursor = 'crosshair';
        ctx.strokeStyle = '#ffffff';
        callBack('ChangeTuoMin', ifEdit, 'HuaCiStart');
        return new Promise((resolve, reject) => {
            drawAreaResolve = resolve;
        });
    }

    // 取消划词处理
    function cancelDrawArea() {
        ifEdit = false;
        isHuaCi = false;
        canvas.style.cursor = 'default';
        callBack('ChangeTuoMin', ifEdit, 'HuaCiFinish');
        return new Promise((resolve, reject) => {
            resolve('');
        });
    }

    // 删除对应划词数据的矩形框
    function deleteDrawArea(param) {
        layers = layers.filter(val => {
            return !(val.drawOnlyId === param.sort);
        });
        clearAllRect();
        initBackground();
        drawAllGetAreaRect();
        return new Promise((resolve, reject) => {
            resolve('');
        });
    }

    // 划词识别处理
    function getDrawAreaOcrResult() {
        isHuaCi = true;
        // 开始滑屏处理
        return new Promise((resolve, reject) => {
            // 没有画出矩形区域则返回为空
            if (layers && (layers.length === 0)) {
                resolve('');
            }
            let _dataCodeDecode = Object.keys(drawAreaData).length > 0 ? drawAreaData : {};
            // 是绘制的最后一个
            let ocrArea = layers[layers.length - 1];
            ocrArea = calcRatioLayersInImg([ocrArea]);
            canvas.style.cursor = 'wait'; // 划词开始为等待状态
            // GetOcrDelimitation({
            //     formId: imgData.formId,
            //     formCode: imgData.formCode,
            //     imgUrl: imgData.url,
            //     version: imgData.version,
            //     dataCode: _dataCodeDecode.dataCode || '',
            //     deCode: _dataCodeDecode.deCode || '',
            //     coordinate: {
            //         beginX: ocrArea[0].beginX,
            //         beginY: ocrArea[0].beginY,
            //         endX: ocrArea[0].endX,
            //         endY: ocrArea[0].endY
            //     }
            // }).then(res => {
            //     canvas.style.cursor = 'crosshair';  // 划词结束恢复之前状态
            //     let _status = res && res.data && res.data.status;
            //     let _value = res && res.data && res.data.value;
            //     let text = _status ? _value : '';
            //     resolve(text);
            // })
        });
    }

    // 获取页面标注（绿色）
    function getOcrReconizeArea() {
        return new Promise((resolve, reject) => {
            let params = {
                formCode: imgData.formCode,
                imageUrl: [imgUrl],
                version: imgData.version
            }
            // GetDataOcrSort(params).then(res => {
            //     let _arr = (res && res.data) || [];
            //     _arr = _arr.filter(val => {
            //         return !!val.coordinate;
            //     });
            //     _arr = _arr.map(val => {
            //         return {
            //             rotateDirection: 'top',
            //             drawOnlyId: val.sort,
            //             ...val.coordinate,
            //             ...val
            //         }
            //     });
            //     resolve(_arr);
            // })
        });
    }

    let sensitiveId = 10000;
    // 获取敏感信息标注
    function getOcrSensitiveArea() {
        return new Promise((resolve, reject) => {
            // GetOcrSensitiveRecord({
            //     imgUrl: [imgUrl],
            // }).then(res => {
            //     let _arr = (res && res.data && res.data[0] && res.data[0].coordinate) || [];
            //     _arr = _arr.map(val => {
            //         return {
            //             drawOnlyId: sensitiveId++,
            //             ...val
            //         }
            //     });
            //     resolve(_arr);
            // })
        }); 
    }

    // 画矩形左边的数字
    function drawNumberBeforeRect(x, y, text = '', style) {
        let diffWidth = 0;
        if (text > 100) {
            diffWidth += 12;
        } else if (text > 10) {
            diffWidth += 6;
        }
        let originWidth = 15; // 文字矩形宽度
        let originHeight = 15; // 文字矩形高度
        let textWidth = 5; // 文字矩形宽度
        let textHeight = 12; // 文字矩形高度
        x -= originWidth + diffWidth; // 需要向矩形左边偏移
        y -= 0.5; // 矩形线条宽度 / 2
        ctx.beginPath();
        if (style) { ctx.fillStyle = style; }
        ctx.fillRect(x, y, originWidth + diffWidth, originHeight);
        ctx.fillStyle = '#ffffff';
        ctx.fillText(text, x + textWidth, y + textHeight);
        ctx.font = '12px "PingFangSC-Regular"';
        ctx.closePath();
    }

    // 画数字右边的矩形
    function drawRectAfterNumber(x, y, width, height, style) {
        ctx.beginPath();
        if (style) { ctx.strokeStyle = style; }
        ctx.strokeRect(x, y, width, height);
        ctx.closePath();
    }

    function drawStrokeRect(x, y, width, height, style) {
        ctx.beginPath();
        style && (ctx.strokeStyle = style); // '#1fcc23' : '#db8208' 绿、橙
        ctx.strokeRect(x, y, width, height);
        ctx.closePath();
    }

    function getSubmitAreaListParam () {
        // 过滤多余的OCR和敏感信息的
        const targetArr = layers.filter(val => !val.drawOnlyId );
        // const areaList = getCompleteArealist(targetArr);
        const areaList  = calcRatioLayersInImg(targetArr);
        return {
            fileUrl: imgUrl,
            areaList: areaList,
            formId: imgData.id,
            platform: 0, // 1为h5, 0为pc
            subjectCode: imgData.subjectCode
        };
    }

    return {
        getDrawArea: getDrawArea,
        cancelDrawArea: cancelDrawArea,
        deleteDrawArea: deleteDrawArea,
        getSubmitAreaListParam: getSubmitAreaListParam,
        removeEvent: removeEvent
    }
}