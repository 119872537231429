import React, { useMemo } from "react"
import { Button, Col, Form, Input, Row, Select } from "antd"
import { useEffect, useState } from "react"
import { getTaskDistributeProjectList } from "../../../../../services/dct/DctService"

import modStyl from './condition.module.scss'
import utils from "../../../../../util/utils"
import TenantConfiguration from "../../../../../util/tenantConfiguration"

export const StatusEnum = {
  '': "全部",
  '1': '待回复',
  '2': '已回复',
  '3': '已关闭',
}

export type QuestionStatus = keyof typeof StatusEnum

export type Params = {
  projectName: string;
  subjectCode: string;
  questionStatus: QuestionStatus;
}

type ConditionProps = {
  onSearch: (params: Params)=> void
}

type SelectOption = {
  label: string;
  value: string;
}

const colCols = {
  span: 6
}
const fieldCols = {
  labelCol: {
    span: 5
  },
  wrapperCol: {
    span: 19
  }
}

const Condition: React.FC<ConditionProps> = (props)=> {

  const [projectName, setProjectName] = useState<string>()
  const [subjectCode, setSubjectCode] = useState('')
  const [questionStatus, setQuestionStatus] = useState<QuestionStatus>('')

  // 所属项目
  const [projs, setProjs] = useState<SelectOption[]>([])

  // 
  const targetName = useMemo(()=> TenantConfiguration.read('label'), [])

  // 状态
  const [status] = useState(()=> {
    return Object.keys(StatusEnum).map((value: string)=> ({
      label:  StatusEnum[value as QuestionStatus],
      value: value,
    }))
  })
  
  // 项目列表
  type ProjectResult = {
    code: number;
    message?: string;
    data: string[];
  }
  const fetchProjs = async ()=> {
    const { code, data }: ProjectResult = await getTaskDistributeProjectList()
    if(code === 0) {
      if(data.length === 1) {
        setProjectName(data[0])
      }
      setProjs(
        data.map((name: string)=> ({
          label: name,
          value: name
        }))
      )
    }
  }

  const makeParams = (init={} as Partial<Params>)=> {
    return {
      questionStatus,
      projectName: projectName ?? '',
      subjectCode,
      ...init
    }
  }

  const submitControl = ()=> {
    props.onSearch(makeParams())
  }

  const resetControl = ()=> {
    const projName = projs.length === 1 ? projs[0]['value'] : undefined

    setProjectName(projName)
    setQuestionStatus('')
    setSubjectCode('')

    props.onSearch({
      questionStatus: "",
      projectName: projName ?? '',
      subjectCode: '',
    })
  }

  const exportControl = ()=> {
    utils.exportPostFun('dct/project/cra/downLoadImagetList', makeParams())
  }
  
  useEffect(()=> {
    fetchProjs()
  }, [])

  return (
    <div className={modStyl.wrapper}>
      <Form>
        <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="所属项目">
              <Select onChange={(value)=> setProjectName(value)} value={projectName} options={projs} placeholder="请选择" showSearch allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label={`${targetName}编号`}>
              <Input onChange={({target})=> setSubjectCode(target.value)} value={subjectCode} placeholder={'请输入'+targetName+'姓名缩写/编号查找'} allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="状态">
              <Select onChange={(value)=> setQuestionStatus(value)} value={questionStatus} options={status} placeholder="请选择"/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Button onClick={submitControl} type="primary">搜索</Button>
            <Button onClick={resetControl} style={{marginLeft: 10}}>重置</Button>
            <Button onClick={exportControl} style={{marginLeft: 10}} type="link">导出数据</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Condition