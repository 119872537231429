import React, { useEffect, useRef, useState } from "react"

import indexStyl from './index.module.scss'
import Condition from "./components/condition"
import { Button, Empty, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import EditModal from "./components/edit-modal"
import { CareOfficerDataType, CareOfficerListParams, getCareOfficerList } from "../../../../services/apply/patient"
import { formatDateTime } from "./components/utils"

const CareOfficer = ()=> {

  const [record, setRecord] = useState<CareOfficerDataType>()
  // 详情
  const [visible, setVisible] = useState(false)
  
  const [pageSize] = useState(20)
  const [pageNum, setPageNum] = useState(1)
  
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const [dataSource, setDataSource] = useState<CareOfficerDataType[]>([])

  const conditionRef = useRef<CareOfficerListParams>({
    careOfficerName: '',
    phone: '',
  })
  
  //#region 表格列
  const columns: ColumnsType<CareOfficerDataType> = [
    {
      width: 120,
      title: '所属项目',
      ellipsis: true,
      dataIndex: 'projectName',
      render: (text: string) => <a>{text}</a>,
    },
    {
      width: 120,
      title: '关爱专员姓名',
      dataIndex: 'careOfficerName',
    },
    {
      width: 120,
      title: '手机号',
      dataIndex: 'phone',
    },
    {
      width: 160,
      title: '企业微信号',
      dataIndex: 'wechatCode',
    },
    {
      width: 100,
      title: '绑定医助',
      dataIndex: 'inviteAssistantNumber',
      render: (value)=> `${value|0}个`
    },
    {
      width: 100,
      title: '绑定医助',
      dataIndex: 'inviteDoctorNumber',
      render: (value)=> `${value|0}个`
    },
    {
      width: 100,
      ellipsis: true,
      title: '绑定患者',
      dataIndex: 'invitePatientNumber',
      render: (value)=> `${value|0}个`
    },
    {
      title: '其他',
      render(value, record: CareOfficerDataType) {
        return `创建人：${record.createUser}，最后修改时间：${formatDateTime(record.createTime)}`
      }
    },
    {
      width: 140,
      title: '操作',
      key: 'action',
      render: (_: any, record: CareOfficerDataType) => (
        <Button onClick={()=> {
          setRecord(record)
          setVisible(true)
        }} type="primary" size="small">编辑</Button>
      ),
    },
  ];
  //#endregion

  const fetchData = async (params: CareOfficerListParams)=> {
    setLoading(true)
    const {code, data} = await getCareOfficerList(params, pageNum, pageSize)
    setLoading(false)
    if(code === 0) {
      setTotal(data.total)
      setDataSource(data.rows)
    }
  }

  const onSearch = (params: CareOfficerListParams)=> {
    setPageNum(1)
    conditionRef.current = params
    // 修复当前页面为第1页面不自动刷新问题
    if(pageNum == 1) {
      fetchData(conditionRef.current)
    }
  }

  const reload = ()=> {
    setVisible(false)
    if(pageNum === 1) {
      fetchData(conditionRef.current)
    } else {
      setPageNum(1)
    }
  }

  useEffect(()=> {
    fetchData(conditionRef.current)
  }, [pageNum])

  return (
    <>
      <div className={indexStyl.wrapper}>
        <Condition onSearch={onSearch}/>
        <div className={indexStyl.tableWrapper}>
          <Table
            loading={loading}
            size="middle"
            rowKey="id"
            scroll={{y: window.innerHeight - 350}}
            columns={columns}
            dataSource={dataSource}
            locale={{emptyText: <Empty description="暂无数据"/>}}
            pagination={{
              total: total,
              current: pageNum,
              pageSize: pageSize,
              onChange: (page: number)=> setPageNum(page),
              showSizeChanger: false,
              showQuickJumper: false,
              showTotal: total=> `共${total}条，每页${pageSize}条`,
              position: ["bottomRight"]}}/>
        </div>
      </div>
      {
        visible && record && (
          <EditModal record={record} onClose={reload}/>
        )
      }
    </>
  )
}

export default CareOfficer
