/**
 * 重置密码
 */

import React from 'react';
import { Form, Input, Button, Radio, Checkbox, message, Row, Col, Select } from 'antd';
// import { GetChangePassWord } from '../../../services/dct/DctService';
import { getTenantList, tenantDetail, getDesignMainSelects, tenantUpdate, getDesignPlanSelects, getVisitGroupRangeEnums } from "../../../../services/tenant/Tenant";
import { Encrypt, Decrypt } from "../../../../util/crypto";
import './index.less';
const { Option } = Select;

class TenantSetting extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            submitLoading: false,
            isOpenFollowUp: null,
            isOpenMenu: null,
            tenantHomeTemplateList: [],
            tenantHomeTemplateMap: {},
            initDataValue: {
                isOpenFollowUp: '',
                isOpenMenu: '',
                newUiSwitch: '',
                tenantBrief: '',
                tenantHomeRouter: '',
                tenantHomeTemplate: '',
                tenantName: '',   
            },
            savedValues: {
                isOpenFollowUp: '',
                isOpenMenu: '',
                newUiSwitch: '',
                tenantBrief: '',
                tenantHomeRouter: '',
                tenantHomeTemplate: '',
                tenantName: '',
            }
        }
    }

    componentDidMount() {
        // getTenantList();
        this.initData();
    }

    initData = () => {
        tenantDetail({
            code: this.props.tenantCode
        }).then(res => {
            this.setState({
                savedValues: {
                    isOpenFollowUp: res.dotSwitch,
                    isOpenMenu: res.menuSwitch,
                    newUiSwitch: res.newUiSwitch,
                    tenantBrief: res.designation,
                    tenantHomeRouter: res.homePageRoute,
                    tenantHomeTemplate: res.homePageTemplate,
                    tenantName: res.name
                }
            });
        })
        getDesignMainSelects().then(res => {
            const _tenantHomeTemplateMap = {};
            (res || []).forEach(item => {
                _tenantHomeTemplateMap[item.code] = item.name;
            });
            this.setState({
                tenantHomeTemplateList: res,
                tenantHomeTemplateMap: _tenantHomeTemplateMap
            });
        })
    }

    onFinish = (values) => {
        const _param = {
            "code": this.props.tenantCode,
            "designation": values.tenantBrief,
            "dotSwitch": !!values.isOpenFollowUp,
            "homePageRoute": values.tenantHomeRouter,
            "homePageTemplate": values.tenantHomeTemplate,
            "menuSwitch": !!values.isOpenMenu,
            "newUiSwitch": !!values.newUiSwitch,
            "name": values.tenantName
        };
        if (this.state.submitLoading) {
            return;
        }
        this.setState({
            submitLoading: true
        });
        tenantUpdate(_param).then(res => {
            if (res.code == 0) {
                message.success('保存成功', 1);
                this.setState({
                    savedValues: values,
                    submitLoading: false
                });
                this.toEdit();
            }

        });

        // let params = {
        //     newPass: Encrypt(values.newpassword),
        //     resetType: 'SUPER',
        //     userCode: values.userCode,
        //     verifyPass: Encrypt(values.superpassword)
        // }
        
        // GetChangePassWord(params).then(res => {
            
        //     if (res && res.code == 0) {
        //         message.success('重置成功！')
        //     } else {
        //         message.error(res.message)
        //     }
        // })
    };

    onFinishFailed = (errorInfo) => {
    };

    toEdit = () => {
        this.setState({
            isEdit: !this.state.isEdit
        })
    }

    isOpenMenuChange = (event) => {
        this.setState({
            isOpenMenu: event.target.value
        });
    }

    isOpenFollowUpChange = (event) => {
        this.setState({
            isOpenFollowUp: event.target.value
        });
    }

    newUiSwitchChange = (event) => {
        this.setState({
            newUiSwitch: event.target.value
        });
    }

    render() {

        const { isEdit, savedValues, tenantHomeTemplateList, tenantHomeTemplateMap, submitLoading }= this.state;
        // savedValues: {
        //     isOpenFollowUp: '',
        //     isOpenMenu: '',
        //     tenantBrief: '',
        //     tenantHomeRouter: '',
        //     tenantHomeTemplate: '',
        //     tenantName: '',
        // }
        const editContent = [
            { name: '租户名称：', text: savedValues.tenantName },
            { name: '租户简称：', text: savedValues.tenantBrief },
            { name: '租户首页模板：', text: tenantHomeTemplateMap[savedValues.tenantHomeTemplate] || '' },
            { name: '租户首页路由：', text: savedValues.tenantHomeRouter },
            { name: '是否开启菜单：', text: savedValues.isOpenMenu === true ? '是' : (savedValues.isOpenMenu === false) ? '否' : '' },
            { name: '是否开启随访：', text: savedValues.isOpenFollowUp === true ? '是' : (savedValues.isOpenFollowUp === false) ? '否' : '' },
            { name: '是否开启新UI更换：', text: savedValues.newUiSwitch === true ? '是' : savedValues.newUiSwitch === false ? '否' : '' }
        ]


        return (
            <div className={'tenantSetting_wrap'}>
                <div hidden={isEdit} className={'pTop'}>
                    {
                        editContent.map(item => (
                            <Row className={'rowBottom'}>
                                <Col span={10} className={'leftLable'}>
                                    {item.name}
                                </Col>
                                <Col span={14} className={'rightText'}>
                                    {item.text}
                                </Col>
                            </Row>
                        ))
                    }
                    <Row className={'rowBottom'}>
                        <Col span={7} offset={10}>
                            <Button
                                hidden={isEdit}
                                type="primary"
                                style={{ height: 36 }}
                                onClick={this.toEdit}
                            >编辑</Button>
                        </Col>
                    </Row>
                </div>
                {
                    isEdit && 
                    <Form
                        hidden={!isEdit}
                        name="basic"
                        labelCol={{
                            span:5,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        initialValues={savedValues}
                        // autoComplete="off"
                        // autoComplete={'new-password'}
                    >
                        <Form.Item
                            label="租户名称"
                            name="tenantName"
                            // initialValues={savedValues.tenantName}
                            rules={[
                                {
                                    required: true,
                                    message: '请输入租户名称!',
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>

                        <Form.Item
                            label="租户简称"
                            name="tenantBrief"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入租户简称!',
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>

                        <Form.Item
                            label="租户首页模板"
                            name="tenantHomeTemplate"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入租户首页模板!',
                                },
                            ]}
                        >
                            {/* <Input allowClear /> */}
                            <Select placeholder="请选择租户首页模板" value={this.state.tenantHomeTemplate}>
                            {
                                tenantHomeTemplateList && tenantHomeTemplateList.map((val, index) => (
                                    <Option value={val.code} key={index} >{val.name}</Option>
                                ))
                            }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="租户首页路由"
                            name="tenantHomeRouter"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入租户首页路由!',
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>

                        <Form.Item
                            label="是否开启菜单"
                            name="isOpenMenu"
                        >
                            <Radio.Group
                                onChange={this.isOpenMenuChange}
                                value={this.state.isOpenMenu}
                            >
                                <Radio value={true}>是</Radio>
                                <Radio value={false}>否</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="是否开启随访"
                            name="isOpenFollowUp"
                            // initialValue={1}
                        >
                            <Radio.Group
                                onChange={this.isOpenFollowUpChange}
                                value={this.state.isOpenFollowUp}
                            >
                                <Radio value={true}>是</Radio>
                                <Radio value={false}>否</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                        label="是否开启新UI更换"
                        name="newUiSwitch"
                            // initialValue={1}
                        >
                            <Radio.Group
                                onChange={this.newUiSwitchChange}
                                value={this.state.newUiSwitch}
                            >
                                <Radio value={true}>是</Radio>
                                <Radio value={false}>否</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {/* <Radio.Group onChange={onChange} value={value}>
                            <Radio value={1}>A</Radio>
                            <Radio value={2}>B</Radio>
                            <Radio value={3}>C</Radio>
                            <Radio value={4}>D</Radio>
                        </Radio.Group> */}
                        <Form.Item
                            wrapperCol={{
                                offset: 10,
                                span: 16,
                            }}
                        >
                            <Button type="primary" onClick={this.toEdit}>
                                取消
                            </Button>
                            <Button loading={submitLoading} type="primary" style={{marginLeft: '20px'}} htmlType="submit">
                                保存
                            </Button>
                        </Form.Item>
                    </Form>
                }
            </div>
        )
    }

}

export default TenantSetting;


