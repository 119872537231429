import { Button } from "antd";
import React, { useEffect, useRef } from "react";

import modStyl from './SignPad.module.scss'

type SignPadProps = {
  onClose: ()=> void;
  onConfirm: (img: File)=> void;
}

let context:CanvasRenderingContext2D
let lastTime = 0
let maxWidth = 4
let minWidth = 0.3
let maxSpeed = 0.8
let minSpeed = 0.1
let lastLineWidth = 0
let clientRect = {} as DOMRect
let points: {x: number, y: number}[] = []

const downHandler = ({clientX, clientY}: MouseEvent)=> {
  points.push({
    x: clientX,
    y: clientY,
  })
  window.addEventListener('mousemove', moveHandler)
}

const moveHandler = ({clientX, clientY}: MouseEvent)=> {
  const index = points.length - 1
  let lineWidth = 0
  const diffx = clientX - points[index].x
  const diffy = clientY - points[index].y
  const distance = Math.pow(diffx * diffx + diffy * diffy, 0.5)
  const speed = distance / (Date.now() - lastTime) // 0.1 - 3
  
  if (speed >= maxSpeed) {
    lineWidth = minWidth
  } else if (speed <= minSpeed) {
    lineWidth = maxWidth
  } else {
    lineWidth = maxWidth - (speed / maxSpeed) * maxWidth
  }
  lineWidth = lineWidth * (2 / 3) + lastLineWidth * (2.5 / 3)   // 加上上次笔迹宽度的百分比宽度，保证粗细的均匀感
  lastLineWidth = lineWidth
  points.push({
    x: clientX,
    y: clientY,
  })
  lastTime = Date.now()
  if (points.length > 1) {
    context.beginPath()
    context.moveTo(points[points.length - 2].x - clientRect.left, points[points.length - 2].y - clientRect.top)
    context.lineTo(points[points.length - 1].x - clientRect.left, points[points.length - 1].y - clientRect.top)
    context.lineWidth = lineWidth
    context.stroke()
  }
}

const SignPad: React.FC<SignPadProps> = (props)=> {

  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(()=> {
    if(canvasRef.current) {
      const canvas = canvasRef.current

      clientRect = canvas.getBoundingClientRect()
      
      context = canvas.getContext('2d') as CanvasRenderingContext2D
      context.strokeStyle = '#000'
      context.lineCap = 'round'

      canvas.addEventListener('mousedown', downHandler)
      
      canvas.addEventListener('mouseup', ()=> {
        points.splice(0)
        window.removeEventListener('mousemove', moveHandler)
      })
      canvas.addEventListener('mouseout', ()=> {
        window.removeEventListener('mousemove', moveHandler)
      })
    }
  }, [canvasRef.current])

  const clearCanvas = ()=> {
    if(canvasRef.current) {
      context.beginPath();
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      context.closePath();
    }
  }

  const closeHandler = ()=> {
    props.onClose()
  }

  const transformFile = ()=> {
    (canvasRef.current as HTMLCanvasElement).toBlob((data: Blob | null)=> {
      if(data) {
        props.onClose()
        props.onConfirm(new File([data], '签名.png'))
      }
    }, 'image/png')
  }

  return (
    <div className={modStyl.container}>
      <div className={modStyl.wrapper}>
        <canvas ref={canvasRef} width="480" height="320"></canvas>
        <div className={modStyl.buttons}>
          <Button onClick={closeHandler} size="small" className={modStyl.btn}>关闭</Button>
          <div className={modStyl.east}>
            <Button onClick={clearCanvas} size="small" className={modStyl.btn} danger>清空</Button>
            <Button onClick={transformFile} type="primary" size="small" className={modStyl.btn}>确定</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignPad;