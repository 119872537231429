/**
 * 报名统计
 */
 import React from 'react';
 import './selectArea.less';
 import {
     Input, Select
 } from "antd";
 import { SearchOutlined } from '@ant-design/icons';

 let _this;
 
 class SelectArea extends React.Component {
 
    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            visible: false,
            project: '',
        }
        this.timer = null
    }
 
    componentDidMount() {
    }
 
    handleChange = (type, value) => {
        const toState = {}
        toState[type] = value;
        this.setState(toState);
    };
 
     render() {
 
         return (
            <div className='cso_project_manage_select_wrap'>
                <div>
                    <span className="select_pre_text">项目名册</span>
                    <Select
                        className="select_label"
                        defaultValue="lucy"
                        style={{ width: 120 }}
                        onChange={(val) => { this.handleChange('projectName', val) }}
                        options={[
                            { value: 'jack', label: '众生项目' },
                            { value: 'lucy', label: 'Lucy' },
                            { value: 'Yiminghe', label: 'yiminghe' },
                            { value: 'disabled', label: 'Disabled', disabled: true },
                        ]}
                    />
                    <span className="select_pre_text">当前状态</span>
                    <Select
                        className="select_label"
                        defaultValue="请选择状态"
                        style={{ width: 120 }}
                        onChange={(val) => { this.handleChange('projectState', val) }}
                        options={[
                            { value: 'jack', label: '众生项目' },
                            { value: 'lucy', label: 'Lucy' },
                            { value: 'Yiminghe', label: 'yiminghe' },
                            { value: 'disabled', label: 'Disabled', disabled: true },
                        ]}
                    />
                    <span className="select_pre_text">当前状态</span>
                    <Select
                        className="select_label"
                        defaultValue="请选者状态"
                        style={{ width: 120 }}
                        onChange={(val) => { this.handleChange('projectType', val) }}
                        options={[
                            { value: 'jack', label: '众生项目' },
                            { value: 'lucy', label: 'Lucy' },
                            { value: 'Yiminghe', label: 'yiminghe' },
                            { value: 'disabled', label: 'Disabled', disabled: true },
                        ]}
                    />
                    <span className="select_pre_text">类型</span>
                    <Select
                        className="select_label"
                        defaultValue="封闭项目"
                        style={{ width: 120 }}
                        onChange={(val) => { this.handleChange('company', val) }}
                        options={[
                            { value: 'jack', label: '封闭项目' },
                            { value: 'lucy', label: 'Lucy' },
                            { value: 'Yiminghe', label: 'yiminghe' },
                            { value: 'disabled', label: 'Disabled', disabled: true },
                        ]}
                    />
                    <span className="select_pre_text">合作企业</span>
                    <Select
                        className="select_label"
                        defaultValue="封闭项目"
                        style={{ width: 120 }}
                        onChange={(val) => { this.handleChange('disease', val) }}
                        options={[
                            { value: 'jack', label: '封闭项目' },
                            { value: 'lucy', label: 'Lucy' },
                            { value: 'Yiminghe', label: 'yiminghe' },
                            { value: 'disabled', label: 'Disabled', disabled: true },
                        ]}
                    />
                </div>
                <div>
                    <span className="select_pre_text">疾病领域</span>
                    <Select
                        className="select_label"
                        defaultValue="lucy"
                        style={{ width: 120 }}
                        onChange={(val) => { this.handleChange('seat', val) }}
                        options={[
                            { value: 'jack', label: '众生项目' },
                            { value: 'lucy', label: 'Lucy' },
                            { value: 'Yiminghe', label: 'yiminghe' },
                            { value: 'disabled', label: 'Disabled', disabled: true },
                        ]}
                    />
                    <span className="select_pre_text">分配坐席</span>
                    <Input
                        prefix={<SearchOutlined className="site-form-item-icon" />}
                        onChange={(event) => { this.handleChange('relatedSeat', event.target.value) }}
                        className="input_content"
                    >
                    </Input>
                    <span className="select_pre_text">绑定医生</span>
                    <Input
                        prefix={<SearchOutlined className="site-form-item-icon" />}
                        onChange={(event) => { this.handleChange('relatedDoctor', event.target.value); }}
                        className="input_content"
                    >
                    </Input>
                </div>
            </div>
         );
     }
 }
 export default SelectArea;