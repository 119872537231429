import React, { useRef } from 'react'
import { Button, Modal } from 'antd'
import modStyl from './qr-modal.module.scss'
import Poster, { PosterRef } from './poster';
import { DataType } from '../../../../../services/apply/patient';

type Props = {
  record: DataType;
  onClose: ()=> void;
}

const doctor = {
  title: '项目名称先写死',
  description: '邀请医生加入您的团队，医生将获得您的团队信息，您将获得医生信息医生将获得您的团队信息，您将获得医生信息',
}

const QRModal: React.FC<Props> = ({record, onClose})=> {

  const doctorRef = useRef<PosterRef>(null)
  const patientRef = useRef<PosterRef>(null)

  const dlDoctorPoster = ()=> {
    if (doctorRef.current) {
      doctorRef.current.savePoster()
    }
  }
  const dlPatientPoster = ()=> {
    if (patientRef.current) {
      patientRef.current.savePoster()
    }
  }

  return (
    <Modal onCancel={onClose} visible={true} title="邀请二维码" width={790} footer={null} closable maskClosable={false} destroyOnClose>
      <div className={modStyl.wrapper}>
        <div className={modStyl.card}>
          <h2 className={modStyl.header}>邀请医生</h2>
          <div className={modStyl.body}>
            <Poster {...doctor} id="doctor" ref={doctorRef} name={record.assistantName} data={record.doctorUrl as string}/>
          </div>
          <div className={modStyl.footer}>
            <Button onClick={dlDoctorPoster} type="primary">下载图片</Button>
          </div>
        </div>
        <div className={modStyl.card}>
          <h2 className={modStyl.header}>邀请患者</h2>
          <div className={modStyl.body}>
            <Poster {...doctor} id="patient" ref={patientRef} name={record.assistantName} data={'record.doctorUrl' as string}/>
          </div>
          <div className={modStyl.footer}>
            <Button onClick={dlPatientPoster} type="primary">下载图片</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default QRModal