/**
 * 项目结算(积分管理)
 */
import React from 'react';
import './projectSettlement.less';
import moment from 'moment';
import { MD5 } from 'crypto-js';
import {
    Form, Input, Select, Button, message as Message, Table,
    Popconfirm, AutoComplete, TreeSelect, message, Tabs, DatePicker, Modal,
    Row, Col, Divider, Pagination, ConfigProvider, Switch, Flex, Checkbox, InputNumber, Spin, Upload
} from "antd";
import {
    createFromIconfontCN,
    UploadOutlined
} from '@ant-design/icons';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { BASE_URL } from '../../../../comm/config';
import $ from 'jquery';

import {
    getMainBillList,
    editBillStatus,
    verifyPwd,
    billDetailList,
    getStatusList,
    getBasicInfo,
    billRemove,
    singleBillUpdate,
    settlementAgreementList,
    signSingleCancel,
    signAllCancel,
    downloadAllAgreement,
    immediatelyCreate,
    syncThird,
    rejectSign,
    uploadSettlement,
    cancelSettlement,
    singleCheck,
    confirmClearZero,
    confirmClearZeroNum
} from "../../../../services/project/ProjectService";
import Loading from '../../../plat/loading/loading';
import utils from "../../../../util/utils";


const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const { confirm } = Modal;
let _this;
const { Option } = Select;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_sb9kcktrb1j.js',//icon-shanchu icon-tongji icon-quxiao icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});
const projectCode = sessionStorage.getItem('projectCode')
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};
const tailLayout = {
    wrapperCol: { offset: 14, span: 10 },
};
class ProjectSettlement extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.childrenRef = React.createRef();
        _this.tabCount = 0;
        _this.contentCount = 0;
        _this.state = {
            currentData: null,
            statusModalVisible: false,// 修改账单状态弹窗
            billStatusList: [], //账单状态枚举列表
            billDataList: [], // 账单列表-表格数据源
            billListStatus: null, // 账单列表-账单状态
            billListStartDate: null, // 账单列表-开始时间
            billListEndTime: null, // 账单列表-结束时间
            pageNum: 1,// 账单列表-当前页码数
            pageSize: 10,// 账单列表-页码大小
            // current: 1,// 账单列表-当前页码
            total: 0, // 账单列表-账单数量

            settlementPwdModalVisible: false, // 结算密码模态框
            settlementPwd: '', // 结算密码
            createSettlementModalVisible: false, // 生成账单模态框
            createBasicInfo: {}, // 账单基本信息
            clearZeroLock:false,  //锁定清零按钮
            creatSettlementMentDetailParams: {
                userCode: null,
                billEndTime: null,
                billStartDate: null,
                pageNum: 1,
                pageSize: 10,
                total: "",
            },
            createDataSource: [], // 创建账单数据源
            mainId: '',
            selectedRowKeys: [], // 已选中行的 key
            showCheckbox: false,


            signAgreementModalVisible: false, // 签署协议模态框
            signServiceVisible: false, // 专家服务协议模态框
            signContent: '', // 专家服务协议内容
            signState: '', // 协议状态
            rejctDetailId: '', // 协议detailId
            modalLoading: false, //对话框加载

            fileList: [],
            uploadBtnFlag: false,
            noFileTips: false,//未选择文件提示
            uploadFlag: true,
            isCreateVisible: false,
            uploadLoading: false,
        }
    }

    formRef = React.createRef();
    inputRef = React.createRef();
    editFormRef = React.createRef(); // 编辑状态表单
    componentDidMount() {
        _this.getStatus()
        _this.getMainBillList(); // 账单列表
    }

    /**
    * icon 图标
    */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }


    /**
     * 账单查询
     */
    getMainBillList = async () => {
        let projectCode = sessionStorage.getItem('projectCode');
        let { billListStatus, billListStartDate, billListEndTime, pageNum, pageSize } = _this.state
        let params = {
            projectCode: projectCode,
            pageNum: pageNum,
            pageSize: pageSize,
            billStartDate: billListStartDate,
            billEndTime: billListEndTime,
            status: billListStatus
        }
        const res = await getMainBillList(params);
        if (res && res.code == 0) {
            if (res.data) {
                _this.setState({
                    billDataList: [...res.data.records],
                    pageNum: res.data.current,
                    pageSize: res.data.size,
                    total: res.data.total,
                })
            }
        }
    }

    // 帐单列表-分页器
    listPageSizeChange = (current, pageSize) => {
        _this.setState({ pageNum: current, pageSize: pageSize }, () => { this.getMainBillList() })
    }

    // 账单列表状态-枚举类型
    computed = (record) => {
        switch (record.status) {
            case '创建中':
                return (
                    <div className={'con_status'} style={{ color: '#50c218' }}>{record.status}</div>
                )
            case '生成中':
                return (
                    <div className={'con_status'} style={{ color: '#50c218' }}>{record.status}</div>
                )
            case '已生成':
                return (
                    <div className={'con_status'} style={{ color: '#3392f0' }}>{record.status}</div>
                )
            case '结算成功':
                return (
                    <div className={'con_status'} style={{ color: '#50c218' }}>{record.status}</div>
                )
            case '待签署':
                return (
                    <div className={'con_status'} style={{ color: '#82d35a' }}>{record.status}</div>
                )
            case '签署中':
                return (
                    <div className={'con_status'} style={{ color: '#82d35a' }}>{record.status}</div>
                )
            case '已签署':
                return (
                    <div className={'con_status'} style={{ color: '#3392f0' }}>{record.status}</div>
                )
            case '待结算':
                return (
                    <div className={'con_status'} style={{ color: '#82d35a' }}>{record.status}</div>
                )
            case '结算中':
                return (
                    <div className={'con_status'} style={{ color: '#82d35a' }}>{record.status}</div>
                )
            case '同步失败':
                return (
                    <div className={'con_status'} style={{ color: '#ef832d' }}>{record.status}</div>
                )
            case '结算失败':
                return (
                    <div className={'con_status'} style={{ color: '#ef832d' }}>{record.status}</div>
                )
            case '结算异常':
                return (
                    <div className={'con_status'} style={{ color: '#ef832d' }}>{record.status}</div>
                )
            case '结算协议签约失败':
                return (
                    <div className={'con_status'} style={{ color: '#ef832d' }}>{record.status}</div>
                )
            case '本次不结算':
                return (
                    <div className={'con_status'} style={{ color: '#ef832d' }}>{record.status}</div>
                )
            default:
                return (
                    <div className={'con_status'} style={{ color: '#d7d7d7' }}>失效</div>
                )
        }
    }

    // 账单列表-自定义日期change
    onChangeDate = (dates, dateStrings) => {
        if (dateStrings[0]) {
            _this.setState({ billListStartDate: dateStrings[0] + ' 00:00:00' });
            _this.setState({ billListEndTime: dateStrings[1] + ' 23:59:59' });
        } else {
            _this.setState({ billListStartDate: null });
            _this.setState({ billListEndTime: null });
        }

    }

    // 账单列表-点击下载全部结算单
    onDownloadAllSettlement = (record) => {
        let fileName = record.projectName
        const params = {
            billId: record.id
        }
        const url = BASE_URL + `/settlement/billmain/down/excel/${params.billId}`;
        let tokenData = JSON.parse(sessionStorage.getItem('token'))
        let xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = "blob";// 返回类型blob
        xhr.setRequestHeader("x-auth-token", tokenData.token);
        xhr.setRequestHeader("Content-Type", 'application/json;charset=UTF-8');
        xhr.onload = function () {
            if (this.status === 200) {
                let blob = this.response;
                let reader = new FileReader();
                reader.readAsDataURL(blob);    // 转换为base64，可以直接放入a表情href
                reader.onload = function (e) {
                    // 转换完成，创建一个a标签用于下载
                    var a = document.createElement('a');
                    // a.download = fileName;
                    a.download = `${fileName}.xls`;
                    a.href = e.target.result;
                    $("body").append(a);// 修复firefox中无法触发click
                    a.click();
                    $(a).remove();
                }

            }
        };

        xhr.send(JSON.stringify(params));
    }

    // 帐单列表-点击下载本次结算单
    onDownloadSettlement = (record) => {
        let fileName = record.projectName
        const params = {
            billId: record.id
        }
        const url = BASE_URL + `/settlement/billmain/down/excel/filter/${params.billId}`;
        let tokenData = JSON.parse(sessionStorage.getItem('token'))
        let xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = "blob";// 返回类型blob
        xhr.setRequestHeader("x-auth-token", tokenData.token);
        xhr.setRequestHeader("Content-Type", 'application/json;charset=UTF-8');
        xhr.onload = function () {
            if (this.status === 200) {
                let blob = this.response;
                let reader = new FileReader();
                reader.readAsDataURL(blob);    // 转换为base64，可以直接放入a表情href
                reader.onload = function (e) {
                    // 转换完成，创建一个a标签用于下载
                    var a = document.createElement('a');
                    // a.download = fileName;
                    a.download = `${fileName}.xls`;
                    a.href = e.target.result;
                    $("body").append(a);// 修复firefox中无法触发click
                    a.click();
                    $(a).remove();
                }

            }
        };

        xhr.send(JSON.stringify(params));
    }

    // 账单列表-查询按钮
    handleQuery = () => {
        _this.getMainBillList()
    }

    // 获取账单状态枚举
    getStatus = () => {
        getStatusList().then(res => {
            if (res) {
                _this.setState({ billStatusList: [...res] })
            }
        })
    }

    // 账单状态选中
    onSelectHandler = (value) => {
        _this.setState({ billListStatus: value })
    }

    // 账单状态清空
    onSelectClear = () => {
        _this.setState({ billListStatus: null })
    }

    // 立即创建按钮disabled
    // createDisabled = () => {
    //     const hasStatus = _this.state.billDataList.some((item) => item.status === '创建中' || item.status === '待签署' || item.status === '签署中' || item.status === '已签署' || item.status === '待结算' || item.status === '结算中')
    //     return hasStatus
    // }

    // 结算密码加密
    handlePasswordChange = (e) => {
        this.setState({ settlementPwd: e.target.value });
    }

    // 校验密码-点击取消
    onCreateCancelHandler = () => {
        _this.setState({ settlementPwdModalVisible: false })
    }
    // 修改账单状态-取消
    onCancelStatusHandler = () => {
        _this.setState({ statusModalVisible:false })
    }

   

    // 校验结算密码
    submitSettlementPwd = () => {
        const { settlementPwd } = _this.state
        const projectCode = sessionStorage.getItem('projectCode')
        if (settlementPwd) {
            let params = {
                settlePwd: MD5(settlementPwd).toString(),
                projectCode: projectCode // projectCode
            }
            // 校验密码
            verifyPwd(params).then(res => {
                if (res.code === 0) {
                    _this.setState({ mainId: res.data, settlementPwdModalVisible: false, createSettlementModalVisible: true }, () => {
                        // 获取创建账单下几本数据
                        _this.getBasicInfo()
                        // 获取创建账单下列表-获取校验密码成功后的id，作为参数给创建账单的参与者列表
                        _this.getCreatBillList()
                    })
                }
            })
        }
    }

    // 继续创建
    continueCreate = (record) => {
        this.setState({ mainId: record.id, createSettlementModalVisible: true }, () => {
            this.getBasicInfo()
            this.getCreatBillList();
            // 获取创建账单下基本数据
            this.getBasicInfo()
        });
    }
    // 修改账单状态
    updateStatus = (record)=>{
        
        this.setState({statusModalVisible: true ,currentData: record}, () =>{
            const selected = this.state.billStatusList.find(e=> e.name == record.status)
            setTimeout(()=>{
                this.editFormRef.current.setFieldsValue({status: selected.code})
               
            },500)

        })
       
    }
     // 确认修改账单状态
     submitSetStatus = async () => {
        this.setState({ statusModalVisible:false })
        console.log(this.editFormRef.current)
        const res = await editBillStatus({
            mainId: this.state.currentData.id,
            status: this.editFormRef.current.getFieldValue("status")
        })
        if (res.code === 0) {
            Message.warning("更新账目状态成功");
            this.getMainBillList()
            
        } else {
            Message.error(res.message || "更新账目状态失败")
            return Promise.reject()
        }
    }

    // 账单列表-【查看】
    viewSign = (record) => {
        console.log(record, '账单列表查看协议')
        this.setState({ mainId: record.id, signAgreementModalVisible: true }, () => {
            this.getBasicInfo()
            this.getCreatBillList();
        });
    }


    /**
     * 创建账单
     */
    // 创建账单-获取基本信息
    getBasicInfo = () => {
        const { mainId } = _this.state
        const params = {
            mainId
        }
        getBasicInfo(params).then(res => {
            if (res.data) {
                this.setState({
                    createBasicInfo: { ...res.data }
                })
            }
        }
        )
    }

    // 创建账单-关闭对话框
    onCancelCreate = () => {
        _this.setState({ createSettlementModalVisible: false })
        _this.getMainBillList()
    }

    // 创建账单-参与者列表
    getCreatBillList = () => {
        const { pageNum, pageSize, userCode } = _this.state.creatSettlementMentDetailParams
        const { mainId } = _this.state
        const projectCode = sessionStorage.getItem('projectCode')
        const status = _this.state.billListStatus
        let params = {
            status: status,
            userCode: userCode,
            mainId: mainId,
            pageNum: pageNum,
            pageSize: pageSize,
            projectCode: projectCode,
            // projectCode: 'PROJECT10000215'
        }
        billDetailList(params).then(res => {
            if (res && res.code == 0) {
                if (res.data) {
                    this.setState({
                        createDataSource: [...res.data.records],
                        // pageNum: res.data.current,
                        // pageSize: res.data.pageSize,
                        selectedRowKeys: [],
                        creatSettlementMentDetailParams: {
                            ...this.state.creatSettlementMentDetailParams,
                            total: res.data.total,
                            pageNum: res.data.current,
                            pageSize: res.data.size,
                            // userCode: null,
                            // billEndTime: null,
                            // billStartDate: null,
                        }
                    })
                }
            }
        })
    }

    // 创建表单-查询用户
    inputChange = (e) => {
        let target = this.state.creatSettlementMentDetailParams
        _this.setState({ creatSettlementMentDetailParams: target })
        if (e.target.value === '') {
            target.userCode = null
        } else {
            target.userCode = e.target.value
        }
    }

    // 创建账单-查询列表
    billQueryHandler = () => {
        _this.getCreatBillList()
    }

    // 创建账单-立即创建
    createdBill = () => {
        _this.setState({ createSettlementModalVisible: false }) // 关闭创建账单弹窗
        _this.setState({ signAgreementModalVisible: true }) // 打开签署协议
        _this.getCreatBillList()
    }
    // 创建账单-批量清零
    onClearZero = () => {
            this.setState({ clearZeroLock: true })
            confirmClearZeroNum(this.state.mainId).then(res=>{
                if(res.code===0){
                    this.setState({ clearZeroLock: false })
                    Modal.confirm({
                        title: '批量清零',
                        content: `您本次结算已选中${res.data}条数据,确定将本次结算全部清零？`,
                        okText: '确认',
                        cancelText: '取消',
                        onOk: async () => {
                            try {
                                const res = await confirmClearZero(this.state.mainId)
                                if (res.code === 0) {
                                    Message.warning("清零成功");
                                    this.getCreatBillList()
                                    this.getBasicInfo()
                                } else {
                                    Message.error(res.message || '批量清零失败');
                                    return Promise.reject()
                                }
                            } catch (error) {
                                return Promise.reject()
                            }
                        },
                    });
                } else {
                    this.setState({ clearZeroLock: false })  
                }
            })
        
       

    };

    // 创建账单-批量作废
    onBatchInvalid = () => {
        this.setState({ showCheckbox: true });
    };

    // 创建账单-取消作废
    cancelInvalidHandle = () => {
        this.setState({ showCheckbox: false, selectedRowKeys: [] });
    }

    // 创建账单-作废
    invalidHandle = () => {
        const { selectedRowKeys } = this.state;
        let ids = selectedRowKeys.join(',')
        if (selectedRowKeys.length === 0) {
            Message.warning("请至少选择一条进行作废");
        } else {
            Modal.confirm({
                title: '作废',
                content: `确定作废${selectedRowKeys.length}条数据吗？`,
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    billRemove(ids).then(res => {
                        if (res) {
                            Message.warning("作废成功");
                            _this.getCreatBillList()
                            _this.getBasicInfo()
                            _this.setState({ selectedRowKeys: [] })
                        }
                    })
                },
                onCancel: () => { }
            });
        }
    }

    // 创建账单-选中的数据
    onCheckboxChange = (id) => (e) => {
        let params = {
            settled: e.target.checked ? 1 : 0,
            id
        }
        singleCheck(params).then(res => {
            if (res && res.code === 0) {
                _this.getBasicInfo()
                _this.getCreatBillList()
            }
        })
    };

    // 创建账单-单条参与者修改本次结算
    singleBillUpdate = (e, record) => {
        if (e === null) return
        const newVal = e
        let params = {
            currentSettleNums: newVal,
            id: record.id
        }
        if (!(newVal > record.canSettleNums)) { // 可结算
            singleBillUpdate(params).then(res => {
                if (res) {
                    Message.success("本条结算修改成功");
                    _this.getCreatBillList()
                    _this.getBasicInfo()
                }
            })
        } else {
            message.warning('本次结算数不可大于可结算数')
        }
    }

    // 创建账单-立即生成按钮
    immediatelyCreate = () => {
        _this.setState({ modalLoading: true })
        const noCurrentSettleNums = _this.state.createDataSource.every(item => item.currentSettleNums === 0)
        if (_this.state.createDataSource.length !== 0) {
            const projectCode = sessionStorage.getItem('projectCode')
            const { mainId } = _this.state
            let params = {
                projectCode,
                id: mainId
            }
            immediatelyCreate(params).then(res => {
                if (res && res.code === 0) {
                    _this.setState({ createSettlementModalVisible: false, signAgreementModalVisible: true }) //关闭创建账单弹窗
                    _this.getCreatBillList()
                    _this.getBasicInfo()
                    _this.setState({ modalLoading: false })
                } else {
                    _this.setState({ modalLoading: false })
                }
            })
        } else {
            this.setState({modalLoading: false, createSettlementModalVisible: false})
        }
    }

    // 创建账单-本次不结算
    cancelSettlement = () => {
        const { mainId } = _this.state
        cancelSettlement(mainId).then(res => {
            if (res && res.code === 0) {
                _this.setState({ createSettlementModalVisible: false }, () => { _this.getMainBillList() })
            }
        })
    }

    // 创建账单-上传结算单
    onSubmit = async () => {
        if (!this.state.uploadFlag) {
            return false
        }
        const { fileList } = this.state;
        if (fileList.length === 0) {
            this.setState({
                noFileTips: true
            })
            return;
        }
        const uploadFormData = new FormData();
        fileList.forEach(file => {
            uploadFormData.append('file', file);
        });
        const { pageNum, pageSize } = _this.state.creatSettlementMentDetailParams
        const projectCode = sessionStorage.getItem('projectCode')
        uploadFormData.append('mainId', this.state.mainId);
        uploadFormData.append('pageNum', pageNum);
        uploadFormData.append('pageSize', pageSize);
        uploadFormData.append('projectCode', projectCode);
        this.setState({ uploadFlag: false })
        _this.setState({ uploadLoading: true })
        const res = await uploadSettlement(uploadFormData);
        if (res && res.code == 0) {
            Message.success('上传成功');
            _this.setState({
                isCreateVisible: false,
                uploadBtnFlag: false,
                noFileTips: false,
                fileList: [],
                uploadFlag: true,
                uploadLoading: false,
            }, () => { _this.getCreatBillList() })
            _this.getBasicInfo()
        } else {
            _this.setState({
                uploadFlag: true,
                uploadLoading: false
            })
        }
    }

    // 取消
    handleCancel = () => {
        this.setState({
            isCreateVisible: false,
            uploadBtnFlag: false,
            noFileTips: false,
            uploadFlag: true,
            fileList: []
        })
    }

    // 创建账单/签署协议-分页器
    createPageSizeChange = (current) => {
        const newCreatSettlementMentDetailParams = { ...this.state.creatSettlementMentDetailParams }
        newCreatSettlementMentDetailParams.pageNum = current
        _this.setState({ creatSettlementMentDetailParams: newCreatSettlementMentDetailParams }, () => { _this.getCreatBillList() })
    }


    // 签署协议-下载全部协议
    downloadAgreement = async () => {
        downloadAllAgreement({
            mainId: _this.state.mainId
        }).then((res) => {
            console.log(res)
            if (res.code == 0) {
                // let _imgs = [
                //     "https://huimei-edc.oss-cn-shanghai.aliyuncs.com/edc/active/kv/008.png",
                //     "https://huimei-edc.oss-cn-shanghai.aliyuncs.com/edc/active/kv/007.png"
                // ];
                const _imgs = res.data || [];
                utils.downloadZip(_imgs, '签署协议');
            }
        });
    }

    // 签署协议-关闭对话框
    onCancelSignAgree = () => {
        _this.setState({ signAgreementModalVisible: false })
        _this.getMainBillList()
    }

    // 签署协议-下载证据单
    downloadEvidence = (record) => {
        console.log(record, '---下载证据单', BASE_URL)
        let fileName = record.name
        const params = {
            mainId: record.id
        }
        const url = BASE_URL + `/settlement/settleapi/download/${params.mainId}`;
        let tokenData = JSON.parse(sessionStorage.getItem('token'))
        let xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = "blob";// 返回类型blob
        xhr.setRequestHeader("x-auth-token", tokenData.token);
        xhr.setRequestHeader("Content-Type", 'application/json;charset=UTF-8');
        xhr.onload = function () {
            if (this.status === 200) {
                let blob = this.response;
                let reader = new FileReader();
                reader.readAsDataURL(blob);    // 转换为base64，可以直接放入a表情href
                reader.onload = function (e) {
                    // 转换完成，创建一个a标签用于下载
                    var a = document.createElement('a');
                    // a.download = fileName;
                    a.download = `${fileName}.xls`;
                    a.href = e.target.result;
                    $("body").append(a);// 修复firefox中无法触发click
                    a.click();
                    $(a).remove();
                }

            }
        };
        xhr.send(JSON.stringify(params));
    }


    //签署协议-协议作废全部
    cancellation = () => {
        const { mainId } = _this.state
        console.log(mainId)
        signAllCancel(mainId).then(res => {
            if (res) {
                Message.warning("已全部作废");
                _this.getCreatBillList()
                _this.getBasicInfo()
            }
        })
    }

    // 签署协议-单条查看
    signSingleView = (record) => {
        console.log(record, '单条查看协议')
        settlementAgreementList(record.id).then(res => {
            if (res.code === 0) {
                this.setState({
                    signState: res.data.status,
                    rejctDetailId: res.data.detailId,
                    signContent: res.data.content ? res.data.content.replace('${sign}', '') : '',
                    signServiceVisible: true
                });
            }
        });
    }

    // 拒绝
    rejectSign = () => {
        console.log(_this.state.rejctDetailId, '拒绝协议')
        const { rejctDetailId } = _this.state
        let params = {
            detailId: rejctDetailId
        }
        rejectSign(params).then(res => {
            if (res.code === 0) {
                message.success('已拒绝')
                this.setState({
                    signServiceVisible: false
                });
                _this.getCreatBillList()
                _this.getBasicInfo()
            }
        })
    }

    // 签署协议-单条作废
    signSingleInvlid = (record) => {
        const { id, mainId } = record
        signSingleCancel(id).then(res => {
            if (res) {
                Message.success("已作废");
                this.setState({ mainId }, () => {
                    this.getBasicInfo()
                    this.getCreatBillList();
                });
            } else {
                Message.error(res.message);
            }
        })

    }

    // 签署协议-同步至第三方
    syncThird = () => {
        _this.setState({ modalLoading: true })
        const params = {
            mainId: _this.state.mainId
        }
        syncThird(params.mainId).then(res => {
            if (res.code === 0) {
                _this.setState({ signAgreementModalVisible: false })
                message.success(res.message)
                _this.setState({ modalLoading: false })
                _this.getMainBillList()
            } else {
                _this.setState({ modalLoading: false })
                _this.getCreatBillList()
                _this.getBasicInfo()
            }
        })
    }

    // 同步失败-单条作废
    singleInvalid = (record) => {
        const { id } = record
        signSingleCancel(id).then(res => {
            if (res && res.code === 0) {
                Message.success('已作废')
                this.getBasicInfo()
                this.getCreatBillList()
            } else {
                Message.error(res.message)
            }
        })
    }



    render() {
        let { billStatusList, billDataList, total, creatSettlementMentDetailParams, createBasicInfo } = _this.state;

        // 账单列表-表格 行 配置
        const columns = [
            {
                title: '账单状态',
                dataIndex: 'status',
                render: (text, record, index) => {
                    return (
                        this.computed(record)
                    )

                }
            },
            {
                title: '账单名称',
                dataIndex: 'name',
            },
            // {
            //     title: '关联活动',
            //     dataIndex: 'activityName',
            //     align: "center",
            // },
            {
                title: '账单时间段',
                dataIndex: 'billStartDate',
                // width: 200,
                render: (text, record, index) => {
                    const { billStartDate, billEndTime } = record
                    return (
                        billStartDate ?
                            <div>
                                {moment(billStartDate).format('YYYY-MM-DD')}~{moment(billEndTime).format('YYYY-MM-DD')}
                            </div>
                            : '--'
                    )
                }
            },
            {
                title: '账单创建时间',
                dataIndex: 'billStartDate',
                render: (text, record, index) => {
                    const { createdTime } = record
                    return (
                        createdTime ?
                            <div>
                                {moment(createdTime).format('YYYY-MM-DD')}
                            </div>
                            : '--'
                    )
                }
            },
            {
                title: '总完成例数',
                align: 'center',
                dataIndex: 'totalCompleteNums',
            },
            {
                title: '可结算例数',
                align: 'center',
                dataIndex: 'canSettleNums'
            },
            {
                title: '本次结算人数',
                align: 'center',
                dataIndex: 'currentSettlePersons',
            },
            {
                title: '本次结算例数',
                align: 'center',
                dataIndex: 'currentSettleNums',
            },
            {
                title: '同步失败原因',
                width: '130px',
                render: (text, record) => {
                    return (
                        <span>{record.failReason}</span>
                    )
                }
            },
            {
                title: '操作',
                // fixed: 'right',
                width: 400,
                render: (text, record, index) => {
                    const { status, id } = record
                    return (
                        <div
                            onClick={eve => {
                                eve.stopPropagation();
                            }}
                            className={'partPop'}
                        >
                            <Button size="small" type="link" onClick={() => { _this.updateStatus(record)}}>修改账单状态</Button>
                            {status == '创建中' &&
                                <Button size="small" type="link" onClick={() => { _this.continueCreate(record) }}>继续创建</Button>}
                            {(status == '待签署' || status == '签署中' || status == '已签署' || status == '待结算' || status == '结算中' || status == '结算成功' || status == '同步失败' || status == '结算失败' || status == '结算异常') &&
                                <Button size="small" type="link" onClick={() => { _this.viewSign(record) }}>查看</Button>}
                            {(status !== '创建中' && status !== '生成中' && status !== '失效' && status !== '本次不结算') &&
                                <Button size="small" type="link" onClick={(e) => this.onDownloadAllSettlement(record)}>下载全部结算单</Button>}
                            {(status !== '创建中' && status !== '生成中' && status !== '失效' && status !== '本次不结算') &&
                                <Button size="small" type="link" onClick={(e) => this.onDownloadSettlement(record)}>下载本次结算单</Button>}
                            {(status == '同步失败' || status == '结算失败' || status == '结算中' || status == '已签署' || status == '签署中' || status == '待签署') &&
                                <Button
                                    size="small"
                                    type="link"
                                    onClick={() => {
                                        // const { record } = this.props; // 获取 record 对象
                                        this.setState({ mainId: record.id }, this.downloadAgreement);
                                    }}
                                >下载协议</Button>}
                            {status == '结算成功' && <Button size="small" type="link" onClick={() => { this.downloadEvidence(record) }}>下载证据单</Button>}
                        </div>
                    )
                }
            },
        ]

        // 账单列表-分页配置
        const listPaginationConfig = {
            total: total,
            showSizeChanger: true,
            current: _this.state.pageNum,
            showQuickJumper: true,
            showTotal: (total) => `共${total}条`,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            locale: {
                pagination: '分页',
                items_per_page: '/页',
                jump_to: '前往'
            }
        }

        // 创建列表-表格行配置
        const createTableColumns = [
            {
                title: '',
                width: '40px',
                render: (text, record) => {

                    return (
                        <Checkbox onChange={_this.onCheckboxChange(record.id)} checked={record.settled == 0 ? false : true} />
                    )


                }
            },
            { title: '中心', dataIndex: 'centerName' },
            // { title: '参与者', dataIndex: 'userName'},
            { title: '账号', dataIndex: 'userCode' },
            { title: '目标数', dataIndex: 'goalNums' },
            { title: '完成', dataIndex: 'actualCompleteNums' },
            { title: '已结算', dataIndex: 'alreadySettleNums' },
            { title: '可结算', dataIndex: 'canSettleNums' },
            {
                title: '本次结算',
                width: '120px',
                dataIndex: 'nowSettlement',
                render: (text, record) => {
                    const { canSettleNums, currentSettleNums } = record
                    return (
                        <>
                            <InputNumber min={0} max={canSettleNums} value={currentSettleNums} onChange={(e) => { _this.singleBillUpdate(e, record) }} />
                        </>
                    )
                    // <InputNumber value={record.currentSettleNums} onChange={(e) => {_this.singleBillUpdate(e, record)}}/>
                }
            }
        ]
        // 创建列表/签署列表-分页配置
        const createpPaginationConfig = {
            total: creatSettlementMentDetailParams.total,
            showSizeChanger: false,
            showQuickJumper: true,
            current: creatSettlementMentDetailParams.pageNum,
            showTotal: (total) => `共${total}条`,
            locale: {
                pagination: '分页',
                jump_to: '前往'
            }
        }

        // 签署列表-表格行配置
        const signTableColumns = (createBasicInfo.status === '结算成功' || createBasicInfo.status === '结算中' || createBasicInfo.status === '结算失败') ? [
            { title: '中心', dataIndex: 'centerName' },
            { title: '姓名', dataIndex: 'userName' },
            { title: '参与者', dataIndex: 'userCode' },
            { title: '结算例数', dataIndex: 'currentSettleNums' },
            { title: '账单状态', dataIndex: 'status' },
            {
                title: '证据地址',
                dataIndex: 'url',
                ellipsis: true,
                render: (text, record) => (
                    <>
                        <a href={text} target='_blank' rel='noreferrer'>{record.url}</a>
                    </>
                )
            },
            {
                title: '结算流水号',
                render: (text, record) => (
                    <>
                        <div>{createBasicInfo.status === '计算失败' ? record.failReason : record.settleStreamCode}</div>
                    </>
                )
            }

        ] : [
            { title: '中心', dataIndex: 'centerName' },
            { title: '姓名', dataIndex: 'userName' },
            { title: '参与者', dataIndex: 'userCode' },
            { title: '结算例数', dataIndex: 'currentSettleNums' },
            { title: '服务协议签署状态', dataIndex: 'status' },
            { title: '签署时间', dataIndex: 'approveTime' },
            {
                title: '操作',
                width: '180px',
                render: (text, record) => (
                    <>
                        {record.status === '失效' ? '' : <><Button type='link' onClick={() => { _this.signSingleView(record) }}>查看</Button>
                            <Button type='link' onClick={() => { _this.signSingleInvlid(record) }}>作废</Button></>}
                    </>
                )
            }
        ]

        const props = {
            name: 'file',
            accept: ".xlsx,.xls",
            headers: {
                authorization: 'authorization-text',
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }), () => {
                    if (this.state.fileList.length === 1) {
                        this.setState({
                            uploadBtnFlag: true,
                            noFileTips: false
                        })
                    }
                });
                return false
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
                this.setState({
                    uploadBtnFlag: false,
                    uploadFlag: true
                })
            },
        }

        return (
            <>
                <div className={'settlement'}>
                    <div className={'pp_top'}>
                        <div className={'selectQueryGroup'}>
                            <Form
                                onFinish={_this.getMainBillList}
                                layout={'inline'}
                                initialValues={{ layout: 'inline' }}
                                ref={_this.formRef}
                            >
                                <Form.Item
                                    name='settleId'
                                    label="账单状态"
                                >
                                    <Select
                                        style={{ width: 140 }}
                                        placeholder="请选择账单状态"
                                        allowClear
                                        onSelect={this.onSelectHandler}
                                        onClear={this.onSelectClear}
                                    >
                                        <Option value={null}>全部</Option>
                                        {billStatusList && billStatusList.map((item) => (
                                            <Option key={item.code} value={item.code}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    style={{ 'marginLeft': '20px' }}
                                    label="自定义时间查询">
                                    <RangePicker
                                        locale={locale}
                                        onChange={this.onChangeDate}
                                        format={dateFormat}
                                    />
                                </Form.Item>
                                <Form.Item><Button type="primary" style={{ 'marginLeft': '20px' }} onClick={this.handleQuery}>查 询</Button></Form.Item>
                            </Form>
                            <div className={'create_button'}>
                                {
                                    // _this.createDisabled() ? <Button style={{ height: '36px' }} disabled={_this.createDisabled()}>立即创建账单</Button>
                                    //     :
                                    <Button type="primary" onClick={() => { _this.setState({ settlementPwdModalVisible: true }) }}>立即创建账单</Button>
                                }


                                <p className={'tips'}>请准备好结算密码进行创建账单</p>
                            </div>
                        </div>
                    </div>

                    <div className={'pp_content'}>
                        {
                            <div className={'itemList'}>
                                <Table
                                    locale={{ emptyText: "暂无数据" }}
                                    columns={columns}
                                    dataSource={billDataList}
                                    rowKey={'id'}
                                    bordered={false}
                                    size={'small'}
                                    pagination={false}
                                    scroll={{
                                        x: 1800,
                                    }}
                                />
                                <Pagination
                                    className={'pagination'}
                                    onChange={_this.listPageSizeChange}
                                    {...listPaginationConfig}
                                />
                            </div>
                        }
                    </div>
                </div>
                <Modal
                    title="创建账单"
                    visible={_this.state.settlementPwdModalVisible}
                    footer={null}
                    onCancel={_this.onCreateCancelHandler}
                    destroyOnClose
                >
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        style={{ maxWidth: 400 }}
                        preserve={false}
                        autoComplete="off">
                        <Form.Item
                            label="结算密码"
                            name="password"
                            rules={[{ required: true, message: '请输入结算密码' }]}
                        >
                            <Input.Password onChange={_this.handlePasswordChange} />
                        </Form.Item>
                        <Form.Item  {...tailLayout}>
                            <Button htmlType="button" onClick={() => { _this.setState({ settlementPwdModalVisible: false }) }}>
                                取消
                            </Button>
                            <Button type="primary" htmlType="submit" onClick={() => { _this.submitSettlementPwd() }} style={{ marginLeft: '20px' }}>
                                确定
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title="修改账单状态"
                    visible={_this.state.statusModalVisible}
                    footer={null}
                    onCancel={_this.onCancelStatusHandler}
                    destroyOnClose
                >
                    <Form
                        name="status-form"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        style={{ maxWidth: 400 }}
                        ref={_this.editFormRef}
                        >
                        <Form.Item  name='status'  label="账单状态" >
                            <Select
                                style={{ width: 140 }}
                                placeholder="请选择账单状态"
                                
                            >
                        
                                {billStatusList && billStatusList.map((item) => (
                                    <Option key={item.code} value={item.code}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item  {...tailLayout}>
                            <Button htmlType="button" onClick={() => { _this.setState({ statusModalVisible: false }) }}>
                                取消
                            </Button>
                            <Button type="primary" htmlType="submit" onClick={() => { _this.submitSetStatus() }} style={{ marginLeft: '20px' }}>
                                确定
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    closable={true}
                    maskClosable={false}
                    width={'90%'}
                    style={{ height: '80%' }}
                    visible={_this.state.createSettlementModalVisible}
                    onCancel={_this.onCancelCreate}
                    footer={null}
                >
                    <Spin spinning={_this.state.modalLoading}>
                        <div className={'create_nav'}>
                            <h2 style={{ fontSize: '14px', fontWeight: 'bold' }}>{createBasicInfo.name}</h2>
                            {this.computed(createBasicInfo)}
                        </div>
                        <div className={'create_top'}>
                            <Row justify="space-between" gutter={16}>
                                <Col span={12}>
                                    <div className={'top_left'}>
                                        <ul className={'left_container'}>
                                            <li>
                                                <p style={{ color: '#ccc', fontSize: '12px', marginBottom: '8px' }}>创建时间</p>
                                                <div className={'item'}>
                                                    {createBasicInfo.createdTime ? moment(createBasicInfo.createdTime).format('YYYY-MM-DD') : '--'}
                                                </div>
                                            </li>
                                            <li>
                                                <p style={{ color: '#ccc', fontSize: '12px', marginBottom: '8px' }}>结算项目</p>
                                                <div className={'item'}>{createBasicInfo.projectName}</div>
                                            </li>
                                            <li>
                                                <p style={{ color: '#ccc', fontSize: '12px', marginBottom: '8px' }}>结算活动</p>
                                                <div className={'item'}>{createBasicInfo.activityName}</div>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className={'top_right'}>
                                        <ul className={'left_container'}>
                                            <li>
                                                <p style={{ color: '#ccc', fontSize: '12px', marginBottom: '8px' }}>可结算例数</p>
                                                <div className={'item'}>{createBasicInfo.canSettleNums}</div>
                                            </li>
                                            <li>
                                                <p style={{ color: '#ccc', fontSize: '12px', marginBottom: '8px' }}>本次结算人数</p>
                                                <div className={'item'}>{createBasicInfo.currentSettlePersons}</div>
                                            </li>
                                            <li>
                                                <p style={{ color: '#ccc', fontSize: '12px', marginBottom: '8px' }}>本次结算例数</p>
                                                <div className={'item'}>{createBasicInfo.currentSettleNums}</div>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className={'create_content'}>
                            <Row align="middle">
                                <Col span={12} className={'selectQueryGroup'}>
                                    <Form
                                        style={{ marginTop: 14, marginBottom: 12 }}
                                        size="default"
                                        layout={'inline'}
                                        initialValues={{ layout: 'inline' }}
                                    >
                                        <Form.Item>
                                            <Input
                                                value={creatSettlementMentDetailParams.userCode}
                                                onChange={this.inputChange}
                                                allowClear={true}
                                                compact="true"
                                                placeholder="请输入姓名/账号"
                                                style={{ width: 150 }}>
                                            </Input>
                                        </Form.Item>
                                        {/* <Form.Item 
                                            label={'账单时间段'}
                                        >
                                            <RangePicker
                                                onChange={this.createChangeDate}
                                                style={{ width: 200 }}
                                                locale={locale}
                                                format={dateFormat}
                                            />
                                        </Form.Item> */}
                                        <Form.Item><Button type="primary" onClick={this.billQueryHandler}>查 询</Button></Form.Item>
                                    </Form>
                                </Col>
                                <Col offset={7} span={2}>
                                        <Button 
                                         type='primary'
                                         loading={this.state.clearZeroLock}
                                         onClick={
                                            this.onClearZero
                                         }
                                         style={{marginLeft:30}}
                                         >
                                        批量清零
                                        </Button>    
                                </Col>
                                <Col offset={1} span={2}>
                                    <div className={'content_right'}>
                                        <Button
                                            icon={<UploadOutlined style={{ fontSize: '18px' }} />}
                                            type='primary'
                                            onClick={() => _this.setState({ isCreateVisible: true })}
                                        >
                                            上传结算单
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <div className={'item_list'}>

                                <Table
                                    rowKey="id"
                                    size={'small'}
                                    pagination={false}
                                    dataSource={_this.state.createDataSource}
                                    columns={createTableColumns} />
                                <div className={'create_pagination'}>
                                    <Pagination
                                        {...createpPaginationConfig}
                                        onChange={_this.createPageSizeChange}
                                    />
                                    {/* <Flex wrap="wrap" gap="small"> */}
                                    <div className={'create_pagination_right'}>
                                        <Popconfirm
                                            title="你确定要取消结算吗?"
                                            onConfirm={_this.cancelSettlement}
                                            okText="确定"
                                            cancelText="取消"
                                        >
                                            <Button danger >取消结算</Button>
                                        </Popconfirm>
                                        <Button type="primary" onClick={_this.immediatelyCreate}>立即生成</Button>
                                    </div>
                                    {/* </Flex> */}
                                </div>
                            </div>
                        </div>
                    </Spin>
                </Modal >
                <Modal
                    closable={true}
                    width={'90%'}
                    style={{ height: '80%' }}
                    visible={_this.state.signAgreementModalVisible}
                    footer={null}
                    maskClosable={false}
                    onCancel={_this.onCancelSignAgree}
                >
                    <Spin spinning={_this.state.modalLoading}>
                        <div className={'create_nav'}>
                            <h2 style={{ fontSize: '14px', fontWeight: 'bold' }}>{createBasicInfo.name}</h2>
                            {/* {this.computed(createBasicInfo)} */}
                        </div>
                        <div className={'create_top'}>
                            <Row justify="space-between" gutter={16}>
                                <Col span={12}>
                                    <div className={'top_left'}>
                                        <ul className={'left_container'}>
                                            <li>
                                                <p style={{ color: '#ccc', fontSize: '12px', marginBottom: '8px' }}>创建时间</p>
                                                <div className={'item'}>{createBasicInfo.createdTime ? moment(createBasicInfo.createdTime).format('YYYY-MM-DD') : '--'}</div>
                                            </li>
                                            <li>
                                                <p style={{ color: '#ccc', fontSize: '12px', marginBottom: '8px' }}>结算项目</p>
                                                <div className={'item'}>{createBasicInfo.projectName}</div>
                                            </li>
                                            {/* <li>
                                            <p style={{color: '#ccc', fontSize: '12px', marginBottom: '8px'}}>结算活动</p>
                                            <div className={'item'}>{createBasicInfo.activityName}</div>
                                        </li> */}
                                        </ul>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className={'top_right'}>
                                        <ul className={'left_container'}>
                                            <li>
                                                <p style={{ color: '#ccc', fontSize: '12px', marginBottom: '8px' }}>结算人数</p>
                                                <div className={'item'}>{createBasicInfo.currentSettlePersons}</div>
                                            </li>
                                            {(createBasicInfo.status === '已签署' || createBasicInfo.status === '签署中' || createBasicInfo.status === '待签署') &&
                                                <>
                                                    <li>
                                                        <p style={{ color: '#ccc', fontSize: '12px', marginBottom: '8px' }}>结算例数</p>
                                                        <div className={'item'}>{createBasicInfo.currentSettleNums}</div>
                                                    </li>
                                                    <li>
                                                        <p style={{ color: '#ccc', fontSize: '12px', marginBottom: '8px' }}>已签署</p>
                                                        <div className={'item'}>{createBasicInfo.sgdNum}</div>
                                                    </li>
                                                    <li>
                                                        <p style={{ color: '#ccc', fontSize: '12px', marginBottom: '8px' }}>待签署</p>
                                                        <div className={'item'}>{createBasicInfo.waitNum}</div>
                                                    </li>
                                                </>
                                            }
                                            {(createBasicInfo.status === '结算成功' || createBasicInfo.status === '结算中' || createBasicInfo.status === '同步失败' || createBasicInfo.status === '结算失败') &&
                                                <li>
                                                    <p style={{ color: '#ccc', fontSize: '12px', marginBottom: '8px' }}>结算例数</p>
                                                    <div className={'item'}>{createBasicInfo.currentSettleNums}</div>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className={'create_content'}>
                            <Row className={'selectQueryGroup'} align="middle">
                                <Col span={12}>
                                    <Form
                                        style={{ marginTop: 14, marginBottom: 12 }}
                                        size="default"
                                        layout={'inline'}
                                        initialValues={{ layout: 'inline' }}
                                    >
                                        <Form.Item>
                                            <Input
                                                value={creatSettlementMentDetailParams.userCode}
                                                onChange={this.inputChange}
                                                allowClear={true}
                                                compact="true"
                                                placeholder="请输入姓名"
                                                style={{ width: 150 }}>
                                            </Input>
                                        </Form.Item>
                                        <Form.Item
                                            name='settleId'
                                            label="账单状态"
                                        >
                                            <Select
                                                style={{ width: 140 }}
                                                placeholder="请选择账单状态"
                                                allowClear
                                                onSelect={this.onSelectHandler}
                                                onClear={this.onSelectClear}
                                            >
                                                <Option value={null}>全部</Option>
                                                {billStatusList && billStatusList.map((item) => (
                                                    <Option key={item.code} value={item.code}>{item.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item><Button htmlType="submit" type="primary" onClick={this.billQueryHandler}>查 询</Button></Form.Item>
                                    </Form>
                                </Col>
                                <Col offset={8} span={4} style={{ display: 'flex', justifyContent: 'end' }}>
                                    {createBasicInfo.status === '已同步' ? (
                                        <Button type="primary" onClick={() => { _this.downloadEvidence(createBasicInfo) }}>
                                            下载证据单
                                        </Button>
                                    ) : (
                                        <Button type="primary" onClick={_this.downloadAgreement}>
                                            下载证据单
                                        </Button>
                                    )}
                                    {/* <Button type='primary' onClick={_this.downloadAgreement}>下载全部协议</Button> */}
                                </Col>
                            </Row>
                            <div className={'item_list'}>
                                <Table
                                    size={'small'}
                                    pagination={false}
                                    dataSource={_this.state.createDataSource}
                                    columns={signTableColumns} />
                                <div className={'create_pagination'}>
                                    <Pagination
                                        {...createpPaginationConfig}
                                        onChange={_this.createPageSizeChange}
                                    />
                                    {/* <Flex wrap="wrap" gap="small"> */}
                                    <div className={'create_pagination_right'}>
                                        {!(createBasicInfo.status === '结算成功' || createBasicInfo.status === '结算中' || createBasicInfo.status === '结算失败') &&
                                            <>
                                                {createBasicInfo.status === '失效' ? <Button danger disabled={true}>全部作废</Button> :
                                                    <Popconfirm
                                                        title="你确定要全部作废?"
                                                        onConfirm={_this.cancellation}
                                                        okText="确定"
                                                        cancelText="取消"
                                                    >
                                                        <Button danger>全部作废</Button>
                                                    </Popconfirm>}

                                                <Button type="primary" disabled={!(createBasicInfo.status == '已签署' || createBasicInfo.status == '同步失败' || createBasicInfo.status == '结算异常' || createBasicInfo.status == '待结算')} onClick={_this.syncThird}>同步至第三方</Button>
                                            </>
                                        }
                                        <Button type="primary" onClick={() => { _this.setState({ signAgreementModalVisible: false }, () => { this.getMainBillList() }) }}>确认</Button>
                                    </div>
                                    {/* </Flex> */}
                                </div>
                            </div>
                        </div>
                    </Spin>
                </Modal>
                <Modal
                    visible={_this.state.signServiceVisible}
                    title={null}
                    onCancel={() => {
                        this.setState({
                            signServiceVisible: false
                        });
                    }}
                    width={'50%'}
                    centered
                    closable={true}
                    destroyOnClose
                    maskClosable={false}
                    footer={_this.state.signState == '已签署' ? [
                        <Button key="submit" type='primary' danger onClick={_this.rejectSign}>拒绝</Button>
                    ] : null}
                >
                    <div dangerouslySetInnerHTML={{ __html: this.state.signContent }}></div>
                </Modal>
                <Modal
                    title="上传结算单"
                    visible={_this.state.isCreateVisible}
                    onOk={this.onSubmit}
                    okText='确认上传'
                    cancelText='关闭'
                    onCancel={this.handleCancel}
                    width='30%'
                    destroyOnClose
                >
                    <Spin spinning={_this.state.uploadLoading}>
                        <Form
                            {...layout}
                            name="nest-messages"
                            requiredMark={true}
                            ref={this.formRef}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="请选择结算单"
                                name='file'
                                rules={[{ required: true, message: '请上传结算单!' }]}
                            >
                                <div className={'upFile'} >
                                    <Upload {...props} >
                                        <Button
                                            icon={<UploadOutlined />}
                                            disabled={this.state.uploadBtnFlag}
                                        >
                                            上传结算单
                                        </Button>
                                    </Upload>
                                    <div
                                        className={'tips'}
                                        style={{ display: `${this.state.noFileTips ? 'block' : 'none'}` }}
                                    >
                                        {this.formatIcon('icon-guanbi')}
                                        <span style={{ marginLeft: 4 }}>请先选择文件</span>
                                    </div>
                                    <div className={'tip'} style={{ marginTop: 4 }}>支持扩展名：.xlsx,.xls</div>

                                </div>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        );
    }
}

export default ProjectSettlement