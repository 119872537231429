import React, { MouseEvent, useEffect, useState } from "react";
import { UploadFile } from "antd/lib/upload/interface";
import { Button } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import thumbStyl from './Thumb.module.scss'
import { makeThumb } from "../crf_form/components/ocr-autofill/utils";

type ThumbProps = {
  urls: string[];
  closable?: boolean;
  onRemove?: (index: number)=> void;
  onPreview: (file: UploadFile) => void;
};

const Thumb: React.FC<ThumbProps> = (props) => {
  const [fileSync, setFileSync] = useState<UploadFile[]>([]);

  useEffect(()=> {
    if(props.urls.length > 0) {
      setFileSync(props.urls.map((url)=> makeThumb(url)))
    } else {
      setFileSync([])
    }
  }, [props.urls])

  const removeControl = async (file: UploadFile) => {
    let current: UploadFile[] = [];

    const files: UploadFile[] = [...fileSync];
    for (const i in files) {
      if (files[i]["uid"] === file.uid) {
        current = files.splice(Number(i), 1) as UploadFile[];
        setFileSync(files);
        props.onRemove && props.onRemove(Number(i))
        break;
      }
    }
  };

  const closable = props.closable ?? true

  return (
    <div className={thumbStyl.wrapper}>
      <div className={thumbStyl.thumbs}>
        {fileSync.map((item) => (
          <div onClick={() => props.onPreview(item)} key={item.uid} className={thumbStyl.item}>
            <img src={item.thumbUrl} alt="" />
            {closable && <Button
              danger
              size="small"
              type="link"
              className={thumbStyl.remove}
              onClick={(event: MouseEvent)=> {
                event.stopPropagation()
                removeControl(item)
              }}
              icon={<CloseCircleFilled />}
            />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Thumb;
