import React, { Component } from 'react';
import { Modal, Button, Select, message, TreeSelect } from 'antd';
import { subjectCenterTransfer, subjectTransferQuery, GetCenterByProjectId } from '../../../../../services/project/ProjectService';
import './dataTransfer.less';

const { TreeNode } = TreeSelect;

class ModalDataTransfer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            receiveCenterId: '',
            totalSubjects: 0, // 受试者数量
            userCode: '',
            userName: '',
            centerId: null,
            centerName: '',
            centerTreeData: [], //筛选前的treeData
            filteredTreeData: [],//筛选后的treeData
        }
    }

    componentDidMount() {
        // sessionStorage.setItem('customerData_download', 'true')
        this.getSubjectTransferQueryData();
        this.getCenterData();
    }

    /**
     * 关闭
     */
    handleCancel = () => {
        this.props.closeModal();
        this.props.getDatas();
    };

    /**
     * 研究中心转移
     */
    getSubjectTransferQueryData = () => {
        let { projectId, transferInfo } = this.props;
        let { centerIds, code } = transferInfo;
        subjectTransferQuery(code, centerIds[0], projectId).then(res => {
            if (res && res.code == 0) {
                this.setState({
                    userCode: res.data.userCode,
                    userName: res.data.userName,
                    centerId: res.data.centerId,
                    centerName: res.data.centerName,
                    totalSubjects: res.data.totalSubjects
                })

            }
        })
    }



    /**
     * 
     * @param {*} 查找账号/名称
     */
    getCenterData = () => {
        const params = {
            projectId: this.props.projectId
        }
        GetCenterByProjectId(params).then(res => {
            if (res) {
                this.filterTreeData(res);
            }
        })
    }



    //筛选treeData
    filterTreeData = (treeData) => {
        let filterFun = (data) => {
            for (let item of data) {
                if (item.children && item.children.length > 0) {
                    if (item.type === "center") {
                        item.value = item.id + '_' + item.title
                        item.title = `${item.title}(中心)`
                    }
                    else if (item.type === "area") {
                        item.value = item.id + '_' + item.title
                        item.title = `${item.title}(区域)`
                    }
                    filterFun(item.children);
                }
                else {
                    if (item.type === "center") {
                        item.value = item.id + '_' + item.title
                        item.title = `${item.title}(中心)`
                    }
                    else if (item.type === "area") {
                        item.value = item.id + '_' + item.title
                        item.title = `${item.title}(区域)`
                    }
                }
            }
        }
        filterFun(treeData);
        this.setState({
            centerTreeData: treeData
        }, () => {
            this.setTreeDataSelectable()
        })
    }



    //根据选择的角色设置可选的区域、中心
    setTreeDataSelectable = () => {
        let filterData = JSON.parse(JSON.stringify(this.state.centerTreeData));
        let role = "EDC_CRC"
        let filterFun = (data) => {
            for (let item of data) {
                if (item.children && item.children.length > 0) {
                    if (item.type === 'area') {
                        if (role === "EDC_CRC") {
                            item.selectable = false
                        }
                        else if (role === "EDC_AM") {
                            item.selectable = true
                            item.title = <span style={{ color: '#6287EC' }}>{item.title}</span>
                        }
                        else {
                            item.selectable = false
                        }
                    }
                    else if (item.type === 'center') {
                        if (role === "EDC_AM") {
                            item.selectable = false
                        }
                        else if (role === "EDC_CRC") {
                            item.selectable = true
                            item.title = <span style={{ color: '#6287EC' }}>{item.title}</span>
                        }
                        else {
                            item.selectable = false
                        }
                    }
                    filterFun(item.children);
                }
                else {
                    if (item.type === 'area') {
                        if (role === "EDC_CRC") {
                            item.selectable = false
                        }
                        else if (role === "EDC_AM") {
                            item.selectable = true
                            item.title = <span style={{ color: '#6287EC' }}>{item.title}</span>
                        }
                        else {
                            item.selectable = false
                        }
                    }
                    else if (item.type === 'center') {
                        if (role === "EDC_AM") {
                            item.selectable = false
                        }
                        else if (role === "EDC_CRC") {
                            item.selectable = true
                            item.title = <span style={{ color: '#6287EC' }}>{item.title}</span>
                        }
                        else {
                            item.selectable = false
                        }
                    }
                }
            }
        }
        this.setState({
            filteredTreeData: filterData
        })
    }


    onTreeSelectChange = (value, item) => {
        this.setState({
            receiveCenterId: item.id
        })
    }


    // 设置父节点不可选
    renderTreeNodes = (data) => {
        if (data && data.length > 0) {
            data.map((item) => {
                if (item.children) {
                    item.disabled = true;
                    return (
                        <TreeNode key={item.id} title={item.title} value={item.value} disabled={item.disabled}>
                            {this.renderTreeNodes(item.children)}
                        </TreeNode>
                    )
                }
                return <TreeNode {...item} key={item.id} title={item.title} value={item.value}></TreeNode>
            })
        }
    }


    /**
     * 
     * @returns 转移
     */
    handleOk = () => {
        let { receiveCenterId, centerId, totalSubjects, userCode } = this.state;
        let { projectId } = this.props;
        if (centerId == receiveCenterId) {
            message.error('转移前后中心不可相同', 2);
            return false
        }
        let params = {
            transferCenterIds: [centerId],
            projectId: projectId,
            receiveCenterId: receiveCenterId,
            totalSubjects: totalSubjects,
            transferCode: userCode,
        }

        subjectCenterTransfer(params).then(res => {
            if (res && res.code == 0) {
                sessionStorage.removeItem('customerData_download');
                this.props.closeModal();
                message.success(res.message);
                this.props.getDatas();
            }
        })
    }


    render() {
        const { loading, totalSubjects, userCode, userName, centerName } = this.state;
        const { visible, } = this.props;

        return (
            <>
                <Modal
                    className={'TransferModals'}
                    visible={visible}
                    title="研究中心转移"
                    onCancel={this.handleCancel}
                    width={'50%'}
                    centered
                    destroyOnClose
                    footer={[
                        <Button
                            key="back"
                            onClick={this.handleCancel}
                            style={{
                                height: 36
                            }}
                            className={'cancelBtn'}
                        >
                            取消
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={this.handleOk}
                            style={{
                                height: 36
                            }}
                        >
                            转移
                        </Button>
                    ]}
                >
                    <div className={'content'}>
                        <div className={'left'}>
                            <p className={'name'}>{userName}{userCode ? `（${userCode}）` : ''}</p>
                            <p className={'msg'}>将在{centerName ? `“ ${centerName} ”` : '“ 未绑定医院 ”'}名下管理的
                                <span className={'color'}>{totalSubjects}个</span>{sessionStorage.getItem('targetName') || '受试者'}
                            </p>
                        </div>
                        <div className={'arrows'}>
                            <div className={'square'}></div>
                            <div className={'triangle'}></div>
                        </div>

                        <div>
                            <TreeSelect
                                style={{ width: '310px', height: 36 }}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                showSearch
                                treeData={this.state.filteredTreeData}
                                placeholder="请选择中心"
                                // treeDefaultExpandAll // 展开树节点
                                allowClear
                                onSelect={this.onTreeSelectChange}
                                filterTreeNode={true}
                                treeNodeFilterProp="title"
                            >
                                {this.renderTreeNodes(this.state.filteredTreeData)}
                            </TreeSelect>


                            <p className={'hintMsg'}>仅能转移给同项目下的其他中心</p>
                        </div>

                    </div>
                </Modal>
            </>
        );
    }
}

export default ModalDataTransfer;