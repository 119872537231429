import { requestGetRes } from "../EdcService";

// 石药多恩达自定义表单 - 新增模板
export type CustomFormTemplatesResult = {
  code: string
  createTime: string
  id: number
  inputState: number
  name: string
  serial: number
  type: number
  version: string
  visitId: number
}
export const getCustomFormTemplates = (visitId: number)=> {
  return requestGetRes(`data/visit/customize/${visitId}`)
}