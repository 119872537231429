import mExpr from "math-expression-evaluator";
import { Parser } from 'expr-eval'
import moment from 'moment';
import formAction from './formAction';
import { saveFormData, saveChangedFormData, updateFormData, updateChangedFormData } from '../../../services/data/DataService';
export default {

    // 处理逻辑运算
    handleLogicOperations(logicOperation, _this) {
        // 所有值都没有，没计算必要
        if ((!_this.savedValues || _this.savedValues.length === 0) && !_this?.propsData?.globalValues?.length )  {
            return "null";
        }
        switch (logicOperation.type) {
            case "expr":
                return this.handleExpr(logicOperation.rule, logicOperation.target, _this);
            case "rangeCalc":
                return this.handleRangeCalc(logicOperation.standard, logicOperation.target, _this);
            case "toAge":
                return this.handleToAge(logicOperation.target, _this);
            case "rangStandards":
                return this.handleRangStandards(logicOperation.table, logicOperation.target, _this);
            case "dynamicValue":
                return this.handleDynamicValue(logicOperation.target, _this);
            case "dateExpr":
                return this.handleDateExpr(logicOperation.rule, logicOperation.target, _this)
            default:
                return "null"
                break;
        }
    },

    /**
     * 范围计算
     * @param rule
     * @param target
     * @param _this
     */
    handleRangeCalc(standard, target, _this) {
        if (standard === null || standard.length === 0) {
            return "null";
        }

        let currValue = null;
        let targetValue = null;
        for (let data of _this.savedValues) {
            if (data.dataCode == target) {
                targetValue = data.value * 1;
            }
            if (data.dataCode == _this.data.dataCode) {
                currValue = data.value;
            }
        }
        if (!targetValue) {
            return "null";
        }

        for (let item of standard) {
            if (item["gt"] && item["gt"] >= targetValue) {
                continue;
            }
            if (item["gte"] && item["gte"] > targetValue) {
                continue;
            }
            if (item["lt"] && item["lt"] <= targetValue) {
                continue;
            }
            if (item["lte"] && item["lte"] < targetValue) {
                continue;
            }
            if (item["eq"] && item["eq"] !== targetValue) {
                continue;
            }
            if (item["ne"] && item["ne"] === targetValue) {
                continue;
            }
            if (currValue === item["result"]) {
                return "null";
            }
            return item["result"];
        }

        return "null";
    },
    // handleExpr(rule, target, _this) { //this包含 savedValues, data
    //     if (_this.data.label === 'ASDAS-CRP评估结果') {
    //         // debugger
    //     }
    //     console.log('rule, target, _this', rule, target, _this);

    //     let currValue = null;
    //     for (let key of target) {
    //         let value = null;
    //         for (let item of _this.savedValues) {
    //             if (item.dataCode == key) {
    //                 if (!Array.isArray(item.value)) {
    //                     value = item.value;
    //                 } else {
    //                     // input-select ['', ''] // 前者input输入框内容，后者分数，没有就是没选分数为0
    //                     value = item.value[1] || '';
    //                 }
    //             }
    //             if (item.dataCode == _this.data.dataCode) {
    //                 // currValue = item.value;
    //                 if (!Array.isArray(item.value)) {
    //                     currValue = item.value;
    //                 } else {
    //                     // input-select ['', ''] // 前者input输入框内容，后者分数，没有就是没选分数为0
    //                     currValue = item.value[1] || '';
    //                 }
    //             }
    //         }
    //         if (value == null || value === "" || isNaN(value)) {
    //             return "null";
    //         }
    //         rule = rule.replaceAll(key, value);
    //     }
    //     let maxPrecision = 2;
    //     if (_this.data.validationRules) {
    //         for (let rule of _this.data.validationRules) {
    //             if (rule.condition == "maxPrecision" && rule.type == "range") {
    //                 maxPrecision = rule.rule * 1;
    //             }
    //         }
    //     }
    //     try {
    //         let result = mExpr.eval(rule) + "";
    //         if (result % 1 !== 0) { // 如果是小数截取整数位前两位
    //             let length = result.indexOf(".") + maxPrecision + 1;
    //             length = length > result.length ? result.length : length;
    //             result = result.substr(0, length);
    //         }
    //         if (currValue == result) {
    //             return "null";
    //         } else {
    //             let reCorrectValues = []; // 自动计算的，但是手动修改后，仍然要保持自动计算的值的
    //             for (let item of _this.savedValues) {
    //                 if (item.dataCode == _this.data.dataCode) {
    //                     let needReCorrect = (item.value != result); // 是否需要更新
    //                     item.value = result;
    //                     needReCorrect && reCorrectValues.push({
    //                         ...item,
    //                         value: result
    //                     });
    //                 }
    //             }
    //             if (reCorrectValues.length > 0) {
    //                 let params = {
    //                     formId: _this.formId,
    //                     submit: false,
    //                     values: reCorrectValues
    //                 }
    //                 // formAction.saveData(params);
    //                 // _this.formId && saveChangedFormData(params);
    //                 if (_this.formId) {
    //                     reCorrectValues.forEach((item) => {
    //                         updateChangedFormData({
    //                             formId: _this.formId,
    //                             dataCode: item.dataCode,
    //                             deCode: item.deCode,
    //                             value: item.value
    //                         });
    //                     })
    //                 }
    //             }
    //             return result;
    //         }
    //     } catch (error) {

    //     }
    // },
    /**
     * 公式计算
     * @param rule
     * @param target
     * @param _this
     * @returns {string}
     */
    handleExpr(rule, target, _this) { //this包含 savedValues, data
        if (_this.data.label === 'ASDAS-CRP评估结果') {
            // debugger
        }
        console.log('rule, target, _this', rule, target, _this);
        let currValue = null;
        for (let key of target) {
            let value = null;
            // 针对带#的和不带#的，然后优先从savedValues,再从全局里面取
            if(key.indexOf('#') != -1){
                if(_this.data.dataCode.indexOf('#') != -1){
                    let Index = _this.data.dataCode.split('#')[1];
                    const newKey = `${key.split('#')[0]}#${Index}`;
                    const findIndex =  _this.savedValues.findIndex(item=>item.dataCode == newKey);
                    if(findIndex > -1){
                        const item = _this.savedValues[findIndex]
                       
                        if (item.dataCode == newKey) {
                            if(item.value !== null && typeof item.value === 'object'){
                                value = item.value.radio
                               
                            }else{
                                value = item.value;
                            }
                        }
                    }else{
                        // const arr = (_this.propsData?.globalValues || []).filter(s => s.dataCode === newKey)
                        // if(arr?.length){
                        //     value = arr[arr.length-1].value
                          
                        //     if(value !== null && typeof value === 'object'){
                        //         value = value.radio
                               
                        //     }
                        // }
                    }
                }
                
            }else{
                const findIndex =  _this.savedValues.findIndex(item=>item.dataCode == key);
                if(findIndex > -1){
                    const item = _this.savedValues[findIndex]
                    if (item.dataCode == key) {
                         //兼容 input-select ['', ''] 改为 {other: '', radio: '', min: '', max: ''}
                        if(item.value !== null && typeof item.value === 'object'){
                            value = item.value.radio
                           
                        }else{
                            value = item.value;
                        }
                    }

                }else{
                    // 在saveValued找不到的情况，从全局里面找
                    // const arr =  (_this.propsData?.globalValues || []).filter(s => s.dataCode === key)
                    // if(arr?.length){
                    //     value = arr[arr.length-1].value
                    //     if(value !== null && typeof value === 'object'){
                    //         value = value.radio
                    //     }
                    // }
                }
            }

            for (let item of _this.savedValues) {
               
                if (item.dataCode == _this.data.dataCode) {
                    currValue = item.value;
                    if(currValue == null || typeof currValue !== 'object'){
                        currValue = item.value;
                    }else{
                        //兼容 input-select {other: '', radio: '', min: '', max: ''}
                        currValue = item.value.radio
                    }
                }
            } 
            if ((value == null || value === "" || isNaN(value))) {
                // 计算值的参数值为空，那么currentValue应该也为空；如果有值则手动置空
                if(!currValue){
                    return "null";
                } else{
                    return ""
                }
            }
     
            rule = rule.replaceAll(key, value);
        }
        let maxPrecision = 2;
        if (_this.data.validationRules) {
            for (let rule of _this.data.validationRules) {
                if (rule.condition == "maxPrecision" && rule.type == "range") {
                    maxPrecision = rule.rule * 1;
                }
            }
        }
        try {
            let result = mExpr.eval(rule) + "";
            // if(isNaN(result)){
            //     if(!currValue){
            //         return "null";
            //     } else{
            //         return ""
            //     }
            // }
            if (result % 1 !== 0) { // 如果是小数截取整数位前两位
                let length = result.indexOf(".") + maxPrecision + 1;
                length = length > result.length ? result.length : length;
                result = result.substr(0, length);
            }
            if (currValue == result) {
                return "null";
            } else {
                let reCorrectValues = []; // 自动计算的，但是手动修改后，仍然要保持自动计算的值的
                for (let item of _this.savedValues) {
                    if (item.dataCode == _this.data.dataCode) {
                        let needReCorrect = (item.value != result); // 是否需要更新
                        item.value = result;
                        needReCorrect && reCorrectValues.push({
                            ...item,
                            value: result
                        });
                    }
                }
                if (reCorrectValues.length > 0) {
                    let params = {
                        formId: _this.formId,
                        submit: false,
                        values: reCorrectValues
                    }
                    // formAction.saveData(params);
                    // _this.formId && saveChangedFormData(params);
                    if (_this.formId) {
                        reCorrectValues.forEach((item) => {
                            updateChangedFormData({
                                formId: _this.formId,
                                dataCode: item.dataCode,
                                deCode: item.deCode,
                                value: item.value
                            });
                        })
                    }
                }
                return result;
            }
        } catch (error) {
            // 对于一些不规则计算，跳过，比如参数11.
            return "null";
        }
    },
    /**
     * 年龄计算
     * @param target
     * @param _this
     */
    handleToAge(target, _this) {
        let targetValue = null;
        let currValue = null;
        for (let data of _this.savedValues) {
            if (data.dataCode == target) {
                targetValue = data.value;
            }
            if (data.dataCode == _this.data.dataCode) {
                currValue = data.value;
            }
        }
        if (!targetValue || new Date(targetValue) == 'Invalid Date') {
            if(!currValue){
                return "null";
            } else{
                return ""
            }
        }
        let result = this.getAge(moment(new Date(targetValue)).format('YYYY-MM-DD'))

        if (currValue == result) {
            return "null";
        } else {
            return result;
        }
    },
    /**
     * 界值表计算   
     * 通过指定数据的值，自动进行范围计算，并展示出对应结果   
     * @param target
     * @param _this
     */
    handleRangStandards(table, target, _this) {
        if (table === null || table.length === 0 || !_this.data) {
            return "null";
        }
        let currValue = null;
        let targetValue = null;
        for (let data of _this.savedValues) {
            if (data.dataCode == target) {
                targetValue = data.value;
            }
            if (data.dataCode == _this.data.dataCode) {
                currValue = data.value;
            }
        }
        if (!targetValue) {
            return "null";
        }

        let result = ''
        for (let index = 0; index < table.length; index++) {
            const item = table[index];
            if (item.condition == 'eq' && (targetValue == Number(item.rule))) {
                result = item.result
                break
            } else if (item.condition == 'lt' && (targetValue < Number(item.rule))) {
                result = item.result
                break
            } else if (item.condition == 'lte' && (targetValue <= Number(item.rule))) {
                result = item.result
                break
            } else if (item.condition == 'gt' && (targetValue > Number(item.rule))) {
                result = item.result
                break
            } else if (item.condition == 'gte' && (targetValue >= Number(item.rule))) {
                result = item.result
                break
            }
        }
        if (currValue == result) {
            return "null";
        } else {
            return result;
        }
    },

    /**
      * 初始化后面的访视节点需要默认设置(前面填过)的值
      * @param target
      * @param _this
      */
    singleHandleGlobalValue(target, _this) {
        const globalValues = _this.propsData?.globalValues;
        const filledBeforeItem = (globalValues || []).find((v) => {
            if ((v.dataCode == target.dataCode) && (v.visitName == target.visit) && (v.formCode == target.form)) {
                return v;
            }
        })
        if (filledBeforeItem && filledBeforeItem.value) {
            return filledBeforeItem.value;
        }
        return null;
    },

    /**
      * 通过指定目标字段，将目标字段的值自动复制给当前表单
      * 如果当前字段值为空时，接受动态值，只要这个字段有值，就不接受动态值
      * @param target
      * @param _this
      */
    handleDynamicValue(target, _this) {
        if (!_this.data) {
            return "null";
        }
        let currValue = null;
        let targetValue = null;
        for (let data of _this.savedValues) {
            if (_this.data.dataCode.indexOf('#') != -1) {
                let Index = _this.data.dataCode.split('#')[1]
                if (target.indexOf('#{index}') != -1) {
                    target = `${target.split('#')[0]}#${Index}`
                }
            }

            if (data.dataCode == target) {
                targetValue = data.value;
            }
            if (data.dataCode == _this.data.dataCode) {
                currValue = data.value;
            }
        }

        if (!targetValue || currValue) {
            return "null";
        }
        let result = ''
        if (targetValue instanceof Array) {
            if (_this.data.optional) {
                for (const key in _this.data.optional) {
                    let Index = targetValue.findIndex((item) => item == key)
                    if (Index > -1) {
                        result = key
                    }
                }
            }
        } else {
            result = targetValue
        }

        if (currValue == result) {
            return "null";
        } else {
            return result;
        }
    },

    /**
     * 根据出生日期获取年龄
     * @param {*} strBirthday 出生日期  2010-01-01
     * @returns 
     */
    getAge(strBirthday) {
        let returnAge;

        let strBirthdayArr = strBirthday.split("-");
        let birthYear = strBirthdayArr[0];
        let birthMonth = strBirthdayArr[1];
        let birthDay = strBirthdayArr[2];

        let d = new Date();
        let nowYear = d.getFullYear();
        let nowMonth = d.getMonth() + 1;
        let nowDay = d.getDate();

        if (nowYear == birthYear) {
            returnAge = '0';//同年 则为0岁
        }
        else {
            var ageDiff = nowYear - birthYear; //年之差
            if (ageDiff > 0) {
                if (nowMonth == birthMonth) {
                    var dayDiff = nowDay - birthDay;//日之差
                    if (dayDiff < 0) {
                        returnAge = ageDiff - 1;
                    } else {
                        returnAge = ageDiff;
                    }
                } else {
                    var monthDiff = nowMonth - birthMonth;//月之差
                    if (monthDiff < 0) {
                        returnAge = ageDiff - 1;
                    } else {
                        returnAge = ageDiff;
                    }
                }
            }
            else {
                returnAge = '0';//出生日期不能大于今天
            }
        }
        return returnAge;
    },

    /**
     * 采血时间差值计算
     */
    handleDateExpr(rule, target, _this) {
        console.log('rule, target, _this', rule, target, _this);
        // 正则表达式确保格式为YYYY-MM-DD，同时排除月份和日期为非数字的情况
        const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
        
        // let currValue = (_this.savedValues ?? []).find(s => s.dataCode === _this.data.dataCode)?.value;
        // for (let key of target) {
        //     const findTargetValue = (_this.savedValues ?? []).find(s => s.dataCode === key)?.value
     
        //     if (findTargetValue) {
        //         const formatTimestamp = Math.floor(new Date(findTargetValue).getTime() / 1000) * 1000
        //         rule = rule.replaceAll(key, formatTimestamp);
        //     } else {
        //         return 'null'
        //     }
        // }

         // 先判断#，然后savedValues寻找，找不到就到全局里面找数据来计算（取最新的）
        let findTargetValue = null;
        let currValue = (_this.savedValues ?? []).find(s => s.dataCode === _this.data.dataCode)?.value;
        for (let [index, key] of target.entries()) {
            if (key.indexOf('#') != -1) {
                // 带有索引的一一对应
                if(_this.data.dataCode.indexOf('#') != -1){
                    let Index = _this.data.dataCode.split('#')[1];
    
                    const allValue = target[index] = `${key.split('#')[0]}#${Index}`;
                    findTargetValue = (_this.savedValues ?? []).find(s => s.dataCode === allValue)?.value;
                }
            }else{
               findTargetValue = (_this.savedValues ?? []).find(s => s.dataCode === key)?.value;
               
            //    if(!findTargetValue){
            //       const arr =  (_this.propsData?.globalValues || []).filter(s => s.dataCode === key)
            //       if(arr?.length){
            //         findTargetValue = arr[arr.length-1].value
            //      }       
            //    }
            }
         
            if ((findTargetValue == null || findTargetValue === "" )) {
                // 计算值的参数值为空，那么currentValue应该也为空；如果有值则手动置空
                if(!currValue ){
                    return "null";
                } else{
                    return ""
                }
            }
         
            const formatTimestamp = Math.floor(new Date(findTargetValue).getTime() / 1000) * 1000
            rule = rule.replaceAll(key, formatTimestamp);
        }
       
        let maxPrecision = 2;

        if (_this.data.validationRules?.length) {
            const rule = _this.data.validationRules.find(rule => rule.condition == "maxPrecision" && rule.type == "range")
            maxPrecision = +rule.rule
        }

        try {
            let result;
            if(rule.indexOf("NaN")>-1 ){
                if(currValue == "未知"){
                    return 'null'
                }
                return  "未知"
            }else{
                result = mExpr.eval(rule);
            }
            if (!Number.isInteger(result)) {
                result = result.toFixed(maxPrecision)
            }
            if (isNaN(result) || currValue == result) {
                return "null";
            }
            if (typeof result === 'number') {
                result = result.toString()
            }
         
            return result;
        } catch (error) {

        }
    },

    /**
     * @description 满足任意条件时,返回对应的值
     * @param {*} conditions 条件表达式
     * @param {*} ctx 当前表单项的上下文
     * @returns 
     */
    setConditionalValue(conditions, ctx) {
        if (!ctx.savedValues || ctx.savedValues.length === 0 || !ctx?.data) {
            return null;
        }
        const targetValue = (ctx.savedValues ?? []).find(s => s.dataCode === ctx.data.dataCode)?.value
        let result = null
        for (const c of (conditions ?? [])) {
            let emptyCount = 0
            const tokenObj = (c?.targets || []).reduce((acc, cur) => {
                let curValue = ctx.savedValues.find(s => s.dataCode === cur)?.value
                if (curValue === undefined || curValue === null) {
                    emptyCount += 1
                }
                if (c.type === 'number') {
                    curValue = curValue ? Number(curValue) : null
                }
                acc[cur] = curValue
                return acc
            }, {})

            if (emptyCount) {
                return null
            }
            const passed = Parser.evaluate(c.condition, tokenObj)
            if (passed) {
                result = c.value
                break;
            }
        }
        if (result === targetValue) {
            return null
        }
        return result
    },
}