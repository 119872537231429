/**
 * 项目报表
 */
import React from 'react';
import './index.less';
import { Tabs, Select, DatePicker } from "antd";
import ReportList from "./list";
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';
import { GetProjectEnume } from '../../../../services/dct/DctService';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const { TabPane } = Tabs;
const { Option } = Select;
let _this;

const reportTypeList = [
    { key: 1, text: "日报", remark: "DAY" },
    { key: 2, text: "周报", remark: "WEEK" },
    { key: 3, text: "月报", remark: "MONTH" },
]

class DctReport extends React.Component {

    constructor(props) {
        super(props);
        //  创建ref容器
        this.childRef = React.createRef();

        _this = this;
        _this.state = {
            selectTabPane: '1', // 报表类型 1DAY,2WEEK,3MONTH
            startDate: moment(new Date()).format('YYYY-MM-DD'), // 开始时间
            endDate: moment(new Date()).format('YYYY-MM-DD'), // 结束时间
            projectCode: '',
            projects: [], // 项目枚举
        }
    }

    componentDidMount() {
        this.getProjectEnume()
    }

    // 获取项目枚举
    getProjectEnume = () => {
        GetProjectEnume().then(res => {
            if (res) {
                this.setState({
                    projects: res
                })
            }
        })
    }

    /**
     * 选择报表
     */
    onTabClick = (val) => {
        this.setState({
            selectTabPane: Number(val)
        })
    }

    // 选择项目
    handleSelectChange = (value) => {
        this.setState({
            projectCode: value
        })
        this.childRef.current.getPageData(null, null, null, null,value)
    }

    // 选择日期
    // onChangeDate = (v, value) => {
    //     this.setState({
    //         startDate: value[0],
    //         endDate: value[1],
    //     })
    //     this.childRef.current.getPageData(null, null, value[0], value[1])
    // }

    render() {
        let { selectTabPane, startDate, endDate, projectCode, projects } = this.state;

        return (
            <div className={'dctReport'}>
                <div className='tabsTitle'>
                    <div className='search'>
                        所属项目：
                        <Select defaultValue="所有项目" onChange={this.handleSelectChange} size='middle'>
                            <Option value={''}>所有项目</Option>
                            {projects.length > 0 && projects.map(project => (
                                <Option key={project.code} value={project.code}>{project.name}</Option>
                            ))}
                        </Select>&nbsp;&nbsp;&nbsp;
                        {/* 选择日期：<RangePicker
                            locale={locale}
                            size='middle'
                            format={dateFormat}
                            onChange={this.onChangeDate}
                            value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                        /> */}
                    </div>
                    <Tabs defaultActiveKey="0" tabPosition='top' onTabClick={this.onTabClick}>
                        {reportTypeList.map(i => (
                            <TabPane tab={i.text} key={i.key} >
                                {
                                    selectTabPane == i.key && <ReportList
                                        ref={this.childRef}
                                        {...this.props}
                                        type={selectTabPane}
                                        startDate={startDate}
                                        endDate={endDate}
                                        projectCode={projectCode}
                                        selectTabPane={selectTabPane}
                                    />
                                }
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default DctReport;