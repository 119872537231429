/**
 * 报名审核
 */
import React from 'react';
import './list.less';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
    Form, Input, Select, Button, message as Message, InputNumber, Table, message, Tabs, Switch, Modal, DatePicker, Image, ConfigProvider
} from "antd";
import { createFromIconfontCN, ExclamationCircleOutlined, FormOutlined, PaperClipOutlined, SaveOutlined, DownloadOutlined } from '@ant-design/icons';
import 'moment/locale/zh-cn';
import {
    GetNewContractApplyList, GetNewContractAuditOperate, GetAgreementDetailByCode
} from "../../../services/apply/ApplyService";
import utils from '../utils';

const { TabPane } = Tabs;

let env = process.env.REACT_APP_ENV;
let _this;
const { Search } = Input;

let searchName = ''

class NewContractsList extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            dataLists: [], // 列表集合
            columns: [
                {
                    title: '客户名称',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.tenantName}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '报名项目',
                    width: '',
                    render: (text, record, index) => {

                        let projectCodes = '';
                        if (env === 'development' || env === 'test') {
                            projectCodes = record.projectCodes ? utils.matchCodesTEST(record.projectCodes) : ''
                        } else if (env === 'production') {
                            projectCodes = record.projectCodes ? utils.matchCodesPROD(record.projectCodes) : ''
                        }

                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.projectName}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '身份信息',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.name}
                                </div>
                                <div className={'sub-title'}>
                                    {record.phone}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '所属医院/科室',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.hospitalName}</div>
                                <div className={'sub-title'}>{record.dept}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '签署时间',
                    width: '',
                    render: (text, record, index) => {
                        if (record.signTime) {
                            return (
                                <div className={'con_div'}>
                                    <div className={'sub-title'}>{record.signTime ? record.signTime.replace('T', ' ') : ''}</div>
                                </div>
                            )
                        }
                    }
                },
                {
                    title: '审核时间',
                    width: '',
                    render: (text, record, index) => {
                        if (record.handleTime) {
                            return (
                                <div className={'con_div'}>
                                    <div className={'sub-title'}>{record.handleTime ? record.handleTime.replace('T', ' ') : ''}</div>
                                </div>
                            )
                        }
                    }
                },
                {
                    title: '审核状态',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div apply_state'}>
                                <Button type={record.status === 'RUNNING' ? 'primary' : 'default'} >
                                    {
                                        record.status === 'RUNNING' ? '待审核' :
                                            record.status === 'AGREE' ? '通过' : '未通过'
                                    }
                                </Button>
                            </div>
                        )
                    }
                },
                {
                    title: '合同',
                    width: '',
                    render: (text, record) =>
                        <div
                            onClick={eve => {
                                eve.stopPropagation();
                            }}
                            className={'partPop'}
                        >
                            <Button type="link" onClick={() => this.onContract(record)} >
                                查看合同
                            </Button>
                        </div>
                },
                {
                    title: '操作',
                    width: '',
                    render: (text, record) =>
                        <div
                            onClick={eve => {
                                eve.stopPropagation();
                            }}
                            className={'partPop newContractOprationBtn'}
                        >
                            <Button 
                                type="link"
                                key="back" 
                                onClick={() => this.onOperate(record.agreementCode, 'AGREE', record.status)} >
                                通过
                            </Button>
                            <Button 
                                danger
                                type="link"
                                key="back" 
                                onClick={() => this.onOperate(record.agreementCode, 'REJECT', record.status)} >
                                拒绝
                            </Button>
                        </div>
                },
            ],// 表格 行 配置
            page: 1,//当前页码数
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo, searchName);
                },
                current: 1,
                pageSize: 10,
                total: 100,
                showSizeChanger: false,
            },
            contractContent: '', // 合同内容
            isContractVisible: false,
        }
        _this.activeKey = 0
        this.timer = null
    }

    formRef = React.createRef();
    inputRef = React.createRef();

    componentDidMount() {
        _this.getPageData(); // 默认展示月报详情
    }

    /**
     * 查看合同
     */
    onContract = async (record) => {
        const res = await GetAgreementDetailByCode(record.agreementCode);
        this.setState({
            contractContent: res.content ? res.content.replace('${sign}', '') : '',
            isContractVisible: true
        })
    }

    /**
     * 操作
     */
    onOperate = async (agreementCode, operation, status) => {
        GetNewContractAuditOperate({
            'agreementCode': agreementCode,
            'status': operation
        }).then(res => {
            if (res.code == 0) {
                message.success('操作成功！', 1);
                this.getPageData();
            } else {
                message.info(res.message || '操作失败！', 1);
            }
        })
    }

    /**
     * 获取列表数据 
     * 通过：AGREE 
     * 未通过：REJECT 
     * 待人工审核 ：RUNNING
     */
    getPageData = async (curPage, name) => {
        let { page } = this.state;
        const params = {
            page: curPage ? curPage : page,
            pageSize: this.state.pagination.pageSize,
            name: name ? name : searchName
        }

        if (this.activeKey == 1) {
            params['status'] = 'RUNNING';
        } else if (this.activeKey == 2) {
            params['status'] = 'REJECT';
        }

        const res = await GetNewContractApplyList(params);
        if (res && res.code == 0) {
            _this.setState({
                dataLists: [...res.data.rows],
                page: res.data.page,
                pageSize: res.data.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })
        }
    }

    // 搜索
    onSearch = (v) => {
        searchName = v
        this.getPageData(1, v)
    }

    // 清除
    onSearchChange = (v) => {
        searchName = ''
        this.getPageData()
    }

    // 切换tab 待审核1，未通过2
    handleTabs = (value) => {
        this.activeKey = value
        this.getPageData()
    }

    // form过滤空格
    getValueFromEvent = (event) => {
        return event.target.value.replace(/\s+/g, "")
    }

    // 二次确认
    oprationComfirm = (agreementCode, operation, status) => {
        Modal.confirm({
            title: operation === 'AGREE' ? '确定通过吗？' : '确定拒绝吗？',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk() {
            },
            onCancel() {
            },
        });
    }

    render() {
        let { columns, dataLists, pagination, isContractVisible, contractContent } = this.state;

        dataLists.map((item, index) => {
            item['key'] = index + 1
        })

        const tabsData = [
            { title: '全部新合同' },
            { title: '待审核合同' },
            { title: '未通过合同' },
        ]
        
        return (
            <div className='applyList'>
                <div className={'apply_wrap'}>
                    <div className={'search_wrap'}>
                        <Search
                            allowClear
                            placeholder="请输入姓名或医院名称"
                            onSearch={(v) => this.onSearch(v)}
                            onChange={(v) => this.onSearchChange(v)}
                            style={{ width: 260 }}
                        />
                    </div>
                    <Tabs defaultActiveKey={this.activeKey} onChange={this.handleTabs}>
                        {tabsData.map((item, index) => (
                            <TabPane tab={item.title} key={index}>
                                <Table
                                    locale={{ emptyText: "暂无数据" }}
                                    columns={columns}
                                    dataSource={dataLists}
                                    defaultExpandAllRows={true}
                                    expandIconAsCell={false}
                                    pagination={dataLists.length !== 0 && pagination}
                                    rowKey={'id'}
                                />
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
                {/* 查看合同 */}
                <Modal
                    title="合同"
                    visible={isContractVisible}
                    onOk={() => { this.setState({ isContractVisible: false }) }}
                    okText='确认'
                    cancelText='关闭'
                    onCancel={() => { this.setState({ isContractVisible: false }) }}
                    width='60%'
                >
                    <div dangerouslySetInnerHTML={{ __html: contractContent || '暂无数据' }}></div>
                </Modal>
            </div>
        );
    }
}
export default withRouter(NewContractsList);