import { GET_TASKS_DATA, GET_SUBJECTDETAIL_DATA, GET_PAGE_STATE } from './actionTypes';
import { fromJS } from 'immutable';

const defaultState = fromJS({
    tasksData: {},// tasks接口数据
    pageState: {}
})

export default (preState = defaultState, action) => {
    switch (action.type) {
        case GET_TASKS_DATA:
            return preState.set('tasksData', action.tasksData);
        case GET_SUBJECTDETAIL_DATA:
            return preState.set('subjectDetail', action.subjectDetail);
        case GET_PAGE_STATE:
            return preState.set('pageState', action.pageState);
        default:
            return preState
    }

}