import { GET_PROJECT_MONITOR } from './actionTypes';
import { fromJS } from 'immutable';

const defaultState = fromJS({
  projectMonitor: {},// 项目监控接口数据
})

export default (preState = defaultState, action) => {
  switch (action.type) {
    case GET_PROJECT_MONITOR:
      return preState.set('projectMonitor', action.projectMonitor);
    default:
      return preState
  }

}