/**
 * 活动报名
 */
import { requestPostFile, requestPost, requestGet, requestPostRes, requestGetRes, requestPatchRes, requestPostFileRes } from '../EdcService';
import axios from 'axios'
const urlPrefix = "/plat/apply";

// 列表
export const GetApplyList = (params) => {
    const url = `${urlPrefix}/list`;
    return requestPostRes(url, params)
};

// 代表报名列表
export const GetRepresentativeList = (params) => {
    const url = `${urlPrefix}/representative/list`;
    return requestPostRes(url, params)
};

// 新合同审核列表
export const GetNewContractApplyList = (params) => {
    const url = `/plat/user/agreement/audit/page`;
    return requestPostRes(url, params)
};
// 新合同审核通过或者拒绝
export const GetNewContractAuditOperate = (params) => {
    const url = `/plat/user/agreement/audit/operate`;
    return requestPostRes(url, params)
};
// 查看详情
export const GetApplyDetail = (params) => {
    const url = `${urlPrefix}/detail`;
    return requestPostRes(url, params)
};
// 审核操作
export const GetApplyOperate = (params) => {
    const url = `${urlPrefix}/operate`;
    return requestPostRes(url, params)
};

// 同意申请 -人工审核
export const GetApplyIdentification = (params) => {
    const url = `${urlPrefix}/operate/identification`;
    return requestPostRes(url, params)
};

// 保存
export const GetEditId = (params) => {
    const url = `${urlPrefix}/edit`;
    return requestPostRes(url, params)
};

// 获取用户服务协议
export const GetAgreementDetail = (registerId, params) => {
    const url = `${urlPrefix}/agreement/detail?registerId=${registerId}`;
    return requestGet(url, params)
};

// 获取用户服务协议新
export const GetAgreementDetailByCode = (agreementCode) => {
    const url = `/plat/user/agreement/detail/${agreementCode}`;
    return requestGet(url);
};

// 审核身份回滚操作（撤消）
export const revokeRollback = (params) => {
    const url = `${urlPrefix}/operate/rollback`;
    return requestPostRes(url, params)
};

// 非医生 拒绝原因枚举
export const GetNotDoctorReasons = (params) => {
    const url = `${urlPrefix}/reject/reasons`;
    return requestGet(url, params)
};

// 签名不合格 拒绝原因枚举 
export const GetSignFailReasons = (params) => {
    const url = `${urlPrefix}/signFail/reasons`;
    return requestGet(url, params)
};

//医生身份认证任务列表
export const GetApplyCheckTaskList = (params) => {
    const url = `${urlPrefix}/batchCheckTaskList`;
    return requestPostRes(url, params)
};

//批量医生身份卫健委校验
export const batchCheckDoctor = (params) => {
    const url = `${urlPrefix}/batchCheck`;
    return requestPostFileRes(url, params)
};

//获取下载链接
export const GetApplyTaskUrl = (params) => {
    const url = `${urlPrefix}/taskUrl`;
    return requestPostRes(url, params)
};

//报名记录逻辑删除
export const deleteApply = (params) => {
    const url = `${urlPrefix}/delete/${params.id}`;
    return requestPostRes(url, params)
};


//获取导入模板地址
export const GetApplyModuleUrl = (params) => {
    const url = `${urlPrefix}/module`;
    return requestGetRes(url, params)
};



/**
 * 批量拒绝文件上传 
*/
export const batchRejectFile = (params) => {
    const url = `${urlPrefix}/batchReject`;
    return requestPostFile(url, params)
};

/**
 * 
 *批量四要素认证
 */
export const batchFourElements = (params) => {
    const url = `${urlPrefix}/check/fourOrTwo/excel`;

    return requestPostFile(url, params)
};
// 下载模板
export const batchFourTemplate = () => {
    const url = `plat/checkExcel.xlsx`;
    return new Promise((resolve, reject) => {
        axios.request({
            url: url,
            method: "get",
            responseType: 'blob'
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err)
            })
    });

}


//获取批量拒绝任务进度
export const GetBatchRejectProcess = (params) => {
    const url = `${urlPrefix}/batchRejectProcess`;
    return requestGetRes(url, params)
};


//可选项目列表
export const GetApplyProjectList = (params) => {
    const url = `${urlPrefix}/projectList`;
    return requestGetRes(url, params)
};

/**
 * 会员同步
 */
export const applyUserSync = (params) => {
    const url = `${urlPrefix}/userSync/${params.id}`;
    return requestPostRes(url)
};

/**
 * 报名列表是否展示财税
 */
export const getApplysettlementShow = () => {
    const url = `${urlPrefix}/settlementShow`;
    return requestPostRes(url)
};

/**
 * 用户租户是否有财税权限
 */
export const getApplysettlementPermission = () => {
    const url = `${urlPrefix}/settlementPermission`;
    return requestPostRes(url)
};



/**
 * 可选租户列表
 */
export const GetApplyTenantList = (params) => {
    const url = `${urlPrefix}/tenantList`;
    return requestGetRes(url, params)
};



//财税平台批量同步会员任务列表
export const GetMemberSyncTaskList = (params) => {
    const url = `/plat/memberSync/taskList`;
    return requestPostRes(url, params)
};


//获取同步会员下载链接
export const GetMemberSyncTaskUrl = (params) => {
    const url = `/plat/memberSync/taskUrl`;
    return requestPostRes(url, params)
};


//批量同步财税平台会员
export const batchMemberSync = (params) => {
    const url = `/plat/memberSync/batchSync`;
    return requestPostFileRes(url, params)
};

//获取身份证图片信息
export const getIdcardInfo = (params) => {
    const url = `/plat/apply/idcard/image/info`;
    return requestPostRes(url, params)
};

//banner管理

export const getBannerList = (params) => {
    const url = '/knowledge/banner/list';
    return requestPostRes(url, params)
}

export const deleteBanner = (params) => {
    const url = '/knowledge/banner/delete';
    return requestPostRes(url, params)
}

export const createBanner = (params) => {
    const url = '/knowledge/banner/create';
    return requestPostRes(url, params)
}

export const editBanner = (params) => {
    const url = '/knowledge/banner/edit';
    return requestPostRes(url, params)
}
export const bannerState = (params) => {
    const url = '/knowledge/banner/state';
    return requestPostRes(url, params)
}

export const getLocalList = (params) => {
    const url = '/knowledge/page_local/list';
    return requestGetRes(url, params)
}

export const getContentList = (params) => {
    const url = '/knowledge/content/list';
    return requestPostRes(url, params)
}
export const createContent = (params) => {
    const url = '/knowledge/content/create';
    return requestPostRes(url, params)
}

export const editContent = (params) => {
    const url = '/knowledge/content/edit';
    return requestPostRes(url, params)
}


export const getContentDetail = (id) => {
    const url = `/knowledge/content/detail/${id}`;
    return requestGetRes(url)
}

export const deleteContent = (id) => {
    const url = `/knowledge/content/delete/${id}`;
    return requestPostRes(url)
}
// 上下线 草稿才能上线 上线才能下线
export const contentOnOff = (params) => {
    const url = `/knowledge/content/on-off`;
    return requestPostRes(url,params)
}

//选 择标签列表
export const getTagList = (labelType) => {
    const url = `/knowledge/label/select/${labelType}`;
    return requestGetRes(url)
}

