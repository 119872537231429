import { requestPost, requestGet } from '../EdcService';

const urlPrefix = "/plat";

/**
 * 获取枚举类型
 * @param {} params
 */
export const GetTypeList = (params) => {
    const url = urlPrefix + "/sensitiveLog/list/type";
    return requestGet(url, params)
};



/**
 * 敏感操作记录列表
 * @param {} params
 */
export const getSensitiveLogList = (params) => {
    const url = urlPrefix + "/sensitiveLog/operation/list";
    return requestPost(url, params)
};

/**
 * 登录记录列表
 * @param {} params
 */
export const getLoginLogList = (params) => {
    const url = urlPrefix + "/sensitiveLog/login/list";
    return requestPost(url, params)
};