import React from 'react';
import {Tree} from 'antd';
import './detail.less';
import {createFromIconfontCN} from '@ant-design/icons';
import {GetFormTreeList} from "../../../../services/design/DesignService";
import localStorageUtil from "../../../../comm/localStorage";
import PubSub from "pubsub-js";
import {clearThreeTitle} from '../../../../util/menuModuleUtil';


let _this;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_1788044_0dwu4guekcwr.js', // icon-javascript, icon-java, icon-shoppingcart (overrided)
        '//at.alicdn.com/t/font_1788592_a5xf2bdic3u.js', // icon-shoppingcart, icon-python
        '//at.alicdn.com/t/font_542587_l6tst4dbwjq.js', // icon-jingdiananli_wujiaoxing_shoucanghou icon-dingwei
    ],
});
const {DirectoryTree} = Tree;
let treeExpandKey = '';

class Detail extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            treeNodeData: {
                expandedKey: '',
                selectedKeys: '暂未选节点',
                info: '暂无信息'
            },
            treeData: [],
        }
    }

    componentDidMount() {
        _this.getFormTreeList(); // 查询 树 info
    }

    componentWillUnmount() {
        clearThreeTitle()
    }

    /**
     * 树 节点 选中
     */
    handleTreeSelect = (selectedKeys, info) => {
        _this.setState({
            treeNodeData: {
                selectedKeys: JSON.stringify(selectedKeys),
                info: JSON.stringify(info)
            },
        })
    };

    /**
     * 查询 树 info
     */
    getFormTreeList = () => {
        GetFormTreeList(_this.props.location.state.id).then((res) => {
            let c = _this.treeMapList(res);
            _this.setState({
                expandedKey: treeExpandKey,
                treeData: c,
            }, () => {
                /*console.log(_this.state.treeData)*/
            })
        })
    }

    /**
     * 树 映射
     * @param values
     */
    treeMapList = (data) => {
        let b = [];
        data.forEach(i => {
            let c = {};
            if (i.parentId == '0') {
                treeExpandKey = i.key;
            }
            c.id = i.id;
            c.key = i.key;
            c.title = i.name
            c.isLeaf = i.type == 'FOLDER' ? false : true
            if (i.children && i.children.length != 0) {
                c.children = _this.treeMapList(i.children)
            } else {
                c.children = []
            }
            b.push(c)
        })
        return b
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName}/>) : undefined;
    }

    render() {
        return (
            <div className={'crfDetail'}>
                <div className={'detail_top'}>
                    <h2 className={'d_title'}>测试表单集合</h2>
                    <div className={'d_con'}>
                        <ul>
                            <li>
                                <span>表单级名称：</span>
                                <span>测试草案</span>
                            </li>
                            <li>
                                <span>草案类型：</span>
                                <span>44</span>
                            </li>
                            <li>
                                <span>应用项目数：</span>
                                <span>44</span>
                            </li>
                            <li>
                                <span>应用患者数：</span>
                                <span>44</span>
                            </li>
                            <li>
                                <span>表单集介绍：</span>
                                <span>44</span>
                            </li>
                            <li>
                                <span>状态：</span>
                                <span>44</span>
                            </li>

                            <li>
                                <span>创建时间：</span>
                                <span>44</span>
                            </li>
                            <li>
                                <span>创建人：</span>
                                <span>44</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={'detail_con'}>
                    <div className={'detail_left'}>
                        {
                            _this.state.treeData && _this.state.treeData.length != 0 ?
                                <DirectoryTree
                                    multiple
                                    defaultExpandedKeys={[this.state.expandedKey]}
                                    onSelect={_this.handleTreeSelect}
                                    treeData={_this.state.treeData}
                                /> : <></>
                        }
                    </div>
                    <div className={'detail_right'}>
                        <div>
                            <p>选中节点key：</p>
                            <p>{_this.state.treeNodeData.selectedKeys}</p>
                        </div>
                        <div>
                            <p>选中节点info：</p>
                            <p>{_this.state.treeNodeData.info}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Detail