/**
 * 特殊字段触发审核
 */
import React, { Component } from 'react';
import { Modal, Button, Form, Input, Space } from 'antd';
import { createFromIconfontCN, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './modal.less'

const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

class SpecialFields extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        
    }

    /**
     * 关闭
     */
     handleTriggerCancel = () => {
        this.props.closeModal(false);
    };

    // 确认
    onTriggerSubmit = (data) => {
        this.props.closeModal(true);
        this.props.getValue(data);
    }

    render() {
        const { visible } = this.props;

        return (
            <Modal
                    title="指定字段"
                    visible={visible}
                    onCancel={this.handleTriggerCancel}
                    width='40%'
                    footer={null}
            >
                <Form
                    name="nest-messages"
                    onFinish={this.onTriggerSubmit}
                    requiredMark={true}
                    ref={this.formRef}
                >
                    <Form.List name="pointFieldDtoList">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                        key={key}
                                        style={{
                                            display: 'flex',
                                            marginBottom: 8,
                                        }}
                                        align="baseline"
                                    >
                                        <Form.Item
                                            label="访视"
                                            {...restField}
                                            name={[name, 'visitNum']}
                                            rules={[{ required: false, message: '请输入访视!' }]}
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item
                                            label="表单"
                                            {...restField}
                                            name={[name, 'formNum']}
                                            rules={[{ required: false, message: '请输入表单!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="字段"
                                            {...restField}
                                            name={[name, 'ruleContent']}
                                            rules={[{ required: false, message: '请输入字段!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="规则"
                                            {...restField}
                                            name={[name, 'ruleType']}
                                            rules={[{ required: false, message: '请输入规则!' }]}
                                        >
                                            <Input />
                                        </Form.Item>

                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        添加
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>

                    
                    <Form.Item
                        style={{
                            marginBottom: 0, display: 'inline-block', float: 'right'
                        }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ height: 36, marginLeft: 20 }}
                        >
                            确认
                        </Button>
                    </Form.Item>
                    <Form.Item
                        style={{
                            marginBottom: 0, display: 'inline-block', float: 'right'
                        }}
                    >
                        <Button
                            onClick={this.handleTriggerCancel}
                            style={{ height: 36 }}
                        >
                            取消
                        </Button>
                    </Form.Item>
                    <div style={{ clear: 'both' }}></div>
                </Form>
            </Modal>
        );
    }
}

export default SpecialFields;