
import { BASE_URL } from '../../src/comm/config';
import $ from 'jquery';
import axios from 'axios';
import JsZip from 'jszip';

export default {
    // get 导出
    exportFun(downLoadUrl) {
        let tokenData = sessionStorage.getItem('token')
        tokenData = JSON.parse(tokenData);
        const url = BASE_URL + downLoadUrl;

        let xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = "blob";// 返回类型blob
        xhr.setRequestHeader("x-auth-token", tokenData.token);
        xhr.onload = function () {
            if (this.status === 200) {
                let blob = this.response;
                let reader = new FileReader();
                reader.readAsDataURL(blob);    // 转换为base64，可以直接放入a表情href
                reader.onload = function (e) {
                    // 转换完成，创建一个a标签用于下载
                    var a = document.createElement('a');
                    // selectTabPane
                    a.download = `下载.xls`
                    a.href = e.target.result;
                    $("body").append(a);// 修复firefox中无法触发click
                    a.click();
                    $(a).remove();
                }
            }
        };
        xhr.send();
    },
    // psot 导出
    exportPostFun(downLoadUrl, params, name, done) {

        const url = BASE_URL + downLoadUrl;
        axios({
            method: 'post',
            url: url,
            data: params,
            responseType: 'blob',
            timeout: 300000,
        }).then((res) => {
            const content = res.data;
            const blob = new Blob([content]);
            const fileName = name ? name : "导出数据.xls";
            const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL'];
            let elink = document.createElement('a');
            if ('download' in elink) {
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = selfURL['createObjectURL'](blob);
                document.body.appendChild(elink);

                // 触发链接
                elink.click();
                selfURL.revokeObjectURL(elink.href);
                document.body.removeChild(elink)
            } else {
                navigator.msSaveBlob(blob, fileName);
            }
            done && done();
        }).catch(()=> {
            done && done();
        })
    },

    // 毫秒转化成天时分秒的时间格式
    formatDuring(time) {
        let currTime = new Date(time).getTime();
        let nowTime = new Date().getTime();
        let mss = nowTime - currTime;
        let days = parseInt(mss / (1000 * 60 * 60 * 24));
        let hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));

        // var seconds = (mss % (1000 * 60)) / 1000;
        // return days + " 天 " + hours + " 小时 " + minutes + " 分钟 " + seconds + " 秒 ";

        days = days > 0 ? `${days}天` : '';
        hours = hours > 0 ? `${hours}小时` : '';
        minutes = minutes > 0 ? `${minutes}分钟` : '';

        return days + hours + minutes;
    },

    // 下载文件
    downloadFile(url, name) {
        let a = document.createElement("a");
        a.href = url;
        a.target = '_blank';
        a.download = name;
        a.click();
    },

    // 将服务协议添加至于zip
    async downloadZip(imgs = [], name = '') {
        // 图片链接
        let zip = new JsZip();
        let i = 1;
        for (let url of imgs) {
            let fileName = '';
            if (url.indexOf('/') > 0) {
                fileName = url.split("/").reverse()[0];
            }
            fileName = i + '_' + fileName;
            console.log('fileName', fileName);
            console.log('url', url);
            let { data } = await axios({
                method: "get",
                url,
                responseType: "blob",
            });
            zip.file(fileName, data
                //     , {
                //     binary: true
                // }
            ); // 逐个添加文件
            i++;
        }
        zip.generateAsync({ type: "blob" }).then((content) => {
            let url = window.URL.createObjectURL(content);
            this.downloadFile(url, name);
        });
    },

    // 图片转换成base64
    getBase64Image(img) {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, img.width, img.height);
        const dataURL = canvas.toDataURL("image/png");
        return dataURL;
    },

    //下载网络图片（而不是网页浏览）
    downloadHttpUrl(url = '', name = '') {
        const img = document.createElement('img');
        img.src = url + '?v=' + Math.random();
        img.crossOrigin = 'anonymous';
        img.onload = () => {
            let _img = this.getBase64Image(img);
            this.downloadFile(_img, name);
        }
    },

    // 通过出生日期计算出年龄YYYY(-、/)MM(-、/)DD
    getAgeByBirth(birth) {
        if (!birth) {
            return '';
        }
        if (birth.indexOf('-') > 0) {
            birth = birth.replace(/-/g, '');
        }
        if (birth.indexOf('/') > 0) {
            birth = birth.replace(/\//g, '');
        }
        let age;
        let myDate = new Date();
        let month = myDate.getMonth() + 1;
        let day = myDate.getDate();
        age = myDate.getFullYear() - birth.substring(0, 4) - 1;
        let birthMonth = birth.substring(4, 6);
        let birthDay = birth.substring(6, 8);
        if (birthMonth < month || (birthMonth == month && birthDay <= day)) {
            age++;
        }
        return age;
    },

    // 生成唯一id
    guid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },

    // 数字加逗号
    numberAddComma(num) {
        let str = num.toString();
        let reg = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
        return str.replace(reg, "$1,");
    },

    // 获取url参数
    getUrlParams(urlStr) {
        let url = urlStr;
        if (typeof url == 'undefined') {
            url = decodeURI(window.location.href); //获取url中'?'符后的字符串
        }
        const theRequest = new Object();
        if (url.indexOf('?') != -1) {
            let arr = url.split('?');
            let str = arr[arr.length - 1]; // 因为#号，可能有多个?
            let strs = str.split('&');
            for (let i = 0; i < strs.length; i++) {
                if (theRequest[strs[i].split('=')[0]]) {
                    // 有重复的key时
                    if (Array.isArray(theRequest[strs[i].split('=')[0]])) {
                        theRequest[strs[i].split('=')[0]] = [...theRequest[strs[i].split('=')[0]], decodeURI(strs[i].split('=')[1])];
                    } else {
                        // 有重复的key时
                        theRequest[strs[i].split('=')[0]] = [theRequest[strs[i].split('=')[0]], decodeURI(strs[i].split('=')[1])];
                    }
                } else {
                    // 没有重复的key时
                    theRequest[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1]);
                }
            }
        }
        return theRequest;
    }

}