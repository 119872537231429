import React, { useEffect, useState } from 'react';
import ActionView from '../../com/actionView/actionView';
import './text.less';

function TextCom(props: any) {
    const [value, setValue] = useState<any>();
    const [data, setData] = useState<any>();
    const [formId, setFormId] = useState<any>();
    const [dataActions, setDataActions] = useState<any>([]);
    const [formIsRead, setFormIsRead] = useState<boolean>(true);
    const [timeObject, setTimeObject] = useState<any>();
    const [savedValues, setSavedValues] = useState<any>([]);

    useEffect(() => {
        if (props.data) {
            //设置dataActions
            setDataActions(props.dataActions);
            //表单是否只读
            setFormIsRead(props.formIsRead);
            //获取当前表单的id
            setFormId(props.formId);
            //该条表单元素的数据
            setData(props.data);
            //已经保存的表单数据
            setSavedValues(props.savedValues);
            //设置当前表单已存的value
            let currentItem = props.savedValues.find((i: any) => {
                return i.dataCode == props.data.dataCode
            });
            if (currentItem) {
                setValue(currentItem.value)
            }
        }
    }, [props])

    return (
        <div className="textCom">

        </div>
    )
}

export default TextCom;
