import { Descriptions, Modal } from "antd";
import React, { useEffect, useState } from "react"
import { DataType } from "..";
import { createFromIconfontCN } from "@ant-design/icons";

type Props = {
  data: DataType
  types: Record<string, string>
  dataSource: DataType[]
  onClose(): void;
}

const IconFont = createFromIconfontCN({
  scriptUrl: [
    '//at.alicdn.com/t/c/font_2343612_2ojyi94p9ix.js',
  ],
});

const ViewModal: React.FC<Props> = ({ data, types, dataSource, onClose })=> {

  const [menuParent, setMenuParent] = useState<DataType>()

  useEffect(()=> {
    if (!data.parentCode) {
      return
    }

    if (data.parentCode === '000') {
      setMenuParent(undefined)
      return
    }

    let found = false

    const find = (target: DataType[])=> {
      if (found) {
        return
      }
      for(const item of target) {
        if (item.code === data.parentCode) {
          found = true
          setMenuParent(item)
          return
        }
        if (item.children) {
          find(item.children)
        }
      }
    }

    find(dataSource)

  }, [data.parentCode])

  return (
    <Modal onCancel={onClose}  title="查看菜单" open={true} footer={null} destroyOnClose closable>
      <Descriptions column={1}>
        <Descriptions.Item label="所属菜单">{menuParent ? menuParent.name : '顶级'}</Descriptions.Item>
        <Descriptions.Item label="菜单名称">{data.name}</Descriptions.Item>
        <Descriptions.Item label="菜单编码">{data.code}</Descriptions.Item>
        <Descriptions.Item label="菜单类型">{types[data.type]}</Descriptions.Item>
        <Descriptions.Item label="菜单地址">{data.url || '-'}</Descriptions.Item>
        <Descriptions.Item label="菜单图标">{data.icon ? <IconFont type={data.icon} style={{width: 20}}/> : '-'}</Descriptions.Item>
        <Descriptions.Item label="应用编号">{data.appCode || '-'}</Descriptions.Item>
        <Descriptions.Item label="菜单配置">{data.config || '-'}</Descriptions.Item>
      </Descriptions>
    </Modal>
  )
}

export default ViewModal