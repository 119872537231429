/**
 * 菜单数据模型
 * lechen
 */

class Menu {

    key: string;

    title: string;

    type: string;

    config: string;

    iconName: string;

    url: string;

    children: any;

    constructor(data: any) {
        this.key = data.code;
        this.title = data.name;
        this.iconName = data.icon;
        this.type = data.type;
        this.config = data.config;
        this.url = data.url;
        this.children = this.deepChildren(data.children);
    }

    deepChildren = (children: any[]) => {
        if (!children) {
            return null;
        }
        let menus: Menu[] = [];
        children.forEach(menu => {
            menus.push(new Menu(menu));
        })
        return menus;
    }
}

export default Menu;
