/**
 * CSO模块路由配置
 */
import UploadAudit from '../components/cso/uploadAudit/index';
import OrderRecord from '../components/cso/orderRecord';
import InfoRecord from '../components/cso/infoRecord';
import ProjectManage from '../components/cso/projectManage/index';
import DoctorAudit from '../components/cso/doctorAudit';
import MedicineManage from '../components/cso/medicineManage';

const routes = [
    { 'path': '/csoManage/uploadAudit/index', 'component': UploadAudit },
    { 'path': '/csoManage/operation/orderRecord', 'component': OrderRecord },
    { 'path': '/csoManage/info/record', 'component': InfoRecord },
    { 'path': '/csoManage/project/manage', 'component': ProjectManage},
    { 'path': '/csoManage/doctorAudit', 'component': DoctorAudit},
    { 'path': '/csoManage/medicineManage', 'component': MedicineManage}
];

 export default routes
 
