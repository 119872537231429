/**
 * 图片不完整
 */
import React, { Component } from 'react';
import { Modal, Button, Form, Checkbox, message } from 'antd';
import './index.less'

class InCompleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            optionsValues: [],
            formCode: null
        }
    }
    formRef = React.createRef();

    componentDidMount = () => {
        let { optionsData } = this.props
        this.setState({
            optionsValues: optionsData
        })
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps) {
            let { optionsData } = this.props
            this.setState({
                optionsValues: optionsData
            })
        }
    }

    /**
     * 确认
     */
    handleOk = (values) => {
        
        let newValues = []
        let { optionsValues } = this.state
        values.reason.map((vItem, vIndex)=>{
            optionsValues.length > 0 && optionsValues.map((item,index)=>{
                if (vItem == item.dataCode) {
                    newValues.push({
                        "deCode": item.deCode + "",
                        "dataCode": item.dataCode + ""
                    })
                }
            })
        })
        
        // this.props.getValue(values.reason)
        
        this.props.getValue(newValues)
        this.props.closeModal('ok');
    };

    /**
     * 取消
     */
    handleCancel = () => {
        this.props.closeModal('cancel');
    };

    render() {
        const { loading, optionsValues } = this.state;
        const { visible } = this.props;

        let optionsData = []
        optionsValues.length > 0 && optionsValues.map((item,index)=>{
            let obj = {
                label: item.name,
                value: item.dataCode
            } 
            optionsData.push(obj)
        })

        return (
            <>
                <Modal
                    className={'InCompleteModals'}
                    visible={visible}
                    title="图片不完整"
                    onCancel={this.handleCancel}
                    width={'30%'}
                    centered
                    destroyOnClose={true}
                    footer={[
                        <Button
                            key="back"
                            onClick={this.handleCancel}
                            style={{ marginRight: 80 }}
                        >取消</Button>
                    ]}
                >
                    <div className='refuseContent'>
                        <Form
                            name="batch-messages"
                            onFinish={this.handleOk}
                            requiredMark={true}
                            ref={this.formRef}
                            initialValues={{
                                'reason': ''
                            }}
                        >
                            <Form.Item
                                label="请选择图片缺失的内容（可多选）"
                                name='reason'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择图片缺失的内容',
                                    }
                                ]}
                            >
                                <Checkbox.Group
                                    options={optionsData}
                                ></Checkbox.Group>
                            </Form.Item>

                            <Form.Item wrapperCol={24}>
                                <Button
                                    key="submit"
                                    type="primary"
                                    htmlType="submit"
                                > 确认</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
            </>
        );
    }
}

export default InCompleteModal;