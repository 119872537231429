import React from 'react';
import { loadMicroApp } from 'qiankun';
import actions from '../../../actions';
import config from './config.json';

let env = process.env.REACT_APP_ENV;

let microApp: any = null;
class CRFComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            params: {},
            saveData: null, // 保存数据
        };
    }

    UNSAFE_componentWillMount = () => {
        // 注册一个观察者函数
        actions.onGlobalStateChange((state, prev) => {
            // 接收子应用保存状态信息
            if (state.data.callback == 'submitSuccess') {
                this.props.onSaveSuccess(state.data.params)
            }
        });
    }

    init = (v?: any) => {
        let { params, readonly } = this.props;
        sessionStorage.setItem('channel', config.channel)

        if (params) {
            let data = {
                data: { // 数据初始化
                    plugnType: "crf",
                    event: 'onInit',
                    params: {
                        channel: config.channel,
                        crfCode: params.crfCode,
                        crfVersion: params.crfVersion,
                        recordCode: params.recordCode,
                        tenantCode: params.tenantCode
                    },
                    readonly: v ?? readonly,
                    userCode: params.userCode
                }
            }
            actions.setGlobalState(data);
        }
    }

    // 数据保存
    handleSubmit = () => {
        this.handleSaveAndSubmit('onSubmit')
    }

    // 数据暂存
    handleSave = () => {
        this.handleSaveAndSubmit('onSave')
    }

    // 数据保存 或 暂存 时传递参数
    handleSaveAndSubmit = (event: any) => {
        let { params } = this.props;
        let data = {
            data: {
                plugnType: "crf",
                event: event,
                params: {
                    caseCode: params.caseCode,
                    channel: config.channel,
                    crfCode: params.crfCode,
                    crfVersion: params.crfVersion,
                    eventCode: params.eventCode,
                    headers: params.headers,
                    projectCode: params.projectCode,
                    recordCode: params.recordCode,
                    tenantCode: params.tenantCode,
                    userCode: params.userCode
                }
            },
        }
        actions.setGlobalState(data);
    }

    componentDidMount = () => {
        let pathname = window.location.pathname
        sessionStorage.setItem('QianKunUrlPrefix', pathname)
        this.loadApp()
        this.init();
    }

    UNSAFE_componentWillUnmount = () => {
        this.unloadApp()
    }

    /**
    * 手动加载微应用
    */
    loadApp = () => {
        microApp = loadMicroApp({
            name: 'crf-plugn', //微应用的名称，微应用之间必须确保唯一(微应用中package.json中的name)
            entry: config.entry[`${env}`], //微应用的entry地址
            container: '#crf',//微应用的容器节点的选择器
        });
    };

    /**
     * 卸载微应用
     */
    unloadApp = () => {
        if (microApp) {
            microApp.unmount();
        }
    };

    render() {
        return (
            <div id='crf'></div>
        );
    }
}
export default CRFComponent;
