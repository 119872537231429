import React, {Component} from 'react';
import {connect} from 'react-redux';
import './dictionaryAdd.less'
import {Button, Form, Input, Select, message} from "antd";
import {createFromIconfontCN} from "@ant-design/icons";
import {DictionariesAdd} from '../../../../services/config/ConfigService'
import {clearThreeTitle} from '../../../../util/menuModuleUtil';

const layout = {
    labelCol: {
        span: 7,
    },
    wrapperCol: {
        span: 17,
    },
};
const {Option} = Select
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

class DictionaryAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            state: 'USE' // 状态 默认是使用中
        }
    }

    componentWillUnmount() {
        clearThreeTitle()
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName}/>) : undefined;
    }

    /**
     * 添加 提交表单
     */
    handleFinish = (values) => {
        values.name = values.name.trim();
        DictionariesAdd(values).then((res) => {
            if (res.code == 0) {
                message.success('添加成功！')
                this.props.history.push('/config/dictionary')
            }
        })
    }

    render() {
        return (
            <div className={'dictionaryAdd'}>
                <div className={'dictionaryAddCon'}>
                    <div className={'dictionaryAdd_title'}>添加平台字典</div>
                    <div className={'dictionaryAdd_con'}>
                        <Form
                            {...layout}
                            name="nest-messages"
                            onFinish={this.handleFinish}
                            requiredMark={true}
                        >
                            <Form.Item
                                label="字典名称"
                                name='name'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入字典名称',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input
                                    style={{width: 468, marginLeft: 6}}
                                    placeholder="请输入字典名称"
                                />
                            </Form.Item>

                            <Form.Item
                                label="字典标识"
                                name='identification'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入字典标识',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input
                                    style={{width: 468, marginLeft: 6}}
                                    placeholder="请输入字典标识"
                                />
                            </Form.Item>

                            <Form.Item
                                label="分类"
                                name='type'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择分类',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='请选择分类'
                                    style={{width: 468, marginLeft: 6}}
                                >
                                    {
                                        this.props.typeSelectList.map(v => (
                                            <Option value={v.get('code')}
                                                    key={v.get('code')}>{v.get('message')}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="状态"
                                name='state'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择状态',
                                    },
                                ]}
                                initialValue={this.state.state}
                            >
                                <Select
                                    onChange={this.handleChange}
                                    placeholder='请选择状态'
                                    style={{width: 468, marginLeft: 6}}
                                >
                                    {
                                        this.props.stateSelectList.map(v => (
                                            <Option value={v.get('code')}
                                                    key={v.get('code')}>{v.get('message')}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="发布机构"
                                name='releasePro'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入发布机构',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input
                                    style={{width: 468, marginLeft: 6}}
                                    placeholder="请输入发布机构"
                                />
                            </Form.Item>

                            <Form.Item
                                label="字典介绍"
                                name='remark'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入字典介绍',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input.TextArea
                                    placeholder="多行输入"
                                    style={{width: 468, marginLeft: 6, height: 138}}
                                />
                            </Form.Item>

                            <Form.Item
                                wrapperCol={{span: 17, offset: 7}}
                                style={{marginBottom: 0}}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{marginLeft: 410}}
                                >
                                    保存
                                </Button>
                            </Form.Item>

                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    stateSelectList: state.getIn(['config', 'stateSelectList']),
    typeSelectList: state.getIn(['config', 'typeSelectList']),
})

const mapDispatchToProps = (dispatch) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(DictionaryAdd);