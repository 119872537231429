/**
 * dct模块路由配置
 */
import dctComponent from '../components/dct/data'; // pm 数据采集
import craImagesComponent from '../components/dct/images';  // cra 图片审核 
import crcDataComponent from '../components/dct/crc/data';  // crc录入
import crcDataEnterComponent from '../components/dct/crc/data/enter';  // crc任务派发
import crcDataTaskComponen1t from '../components/dct/crc/data/task';  // crc表单录入
import dctProjectCraComponent from '../components/dct/project/cra';  // cra 质疑管理
import dctProjectReportComponent from '../components/dct/project/report';  // 项目报表
import dctProjectReportDetailsComponent from '../components/dct/project/report/details';  // 项目报表 详情
import dctCaseAuditComponent from '../components/dct/project/caseAudit/index';  // 病例审核
import dctCaseAccountComponent from '../components/dct/project/account/index';  // 结算审核
import changePwdComponent from '../components/change/pwd'; // 重置密码
import caseStatisticComponent from '../components/dct/case/statistic/index';  // 病例统计
import approveStatisticComponent from '../components/dct/approve/statistic/index';  // 报名统计
import batchAuditComponent from '../components/dct/batch/audit/index';  // 批量审核
//  import dctProjectCrcComponent from '../components/dct/project/crc';  // crc 项目管理
//  import dctProjectPmComponent from '../components/dct/project/pm';  // pm 项目管理
import SettlementMangeComponent from '../components/dct/settlementMange/index';  // 结算申请
import SettlementViewComponent from '../components/dct/settlementMange/settlementView/index';  // 结算申请查询
import dctSignAudit from '../components/dct/signAudit/index';  // 病例签名审核
import SettlementReview from '../components/dct/settlementReview/index';  // 病例签名审核
import ImageCheck from '../components/dct/imageCheck';
import DataCheck from '../components/dct/crc/dataCheck';
import VisitTask from '../components/dct/visit';

const routes = [
    { 'path': '/dct/visitlist', 'component': VisitTask },
    { 'path': '/dct/data', 'component': dctComponent },
    { 'path': '/dct/images', 'component': craImagesComponent },
    { 'path': '/dct/imageslist', 'component': ImageCheck },
    { 'path': '/dct/crc/datalist', 'component': DataCheck },
    { 'path': '/dct/crc/data', 'component': crcDataComponent },
    { 'path': '/dct/crc/data/enter', 'component': crcDataEnterComponent },
    { 'path': '/dct/crc/data/task', 'component': crcDataTaskComponen1t },
    { 'path': '/dct/project/cra', 'component': dctProjectCraComponent },
    { 'path': '/dct/project/report', 'component': dctProjectReportComponent },
    { 'path': '/dct/project/report/details/:title/:id/:name', 'component': dctProjectReportDetailsComponent },
    { 'path': '/dct/case/audit', 'component': dctCaseAuditComponent },
    { 'path': '/dct/case/account', 'component': dctCaseAccountComponent },
    { 'path': '/dct/case/statistic', 'component': caseStatisticComponent },
    { 'path': '/dct/approve/statistic', 'component': approveStatisticComponent },
    { 'path': '/dct/batch/audit', 'component': batchAuditComponent },
    //  {'path': '/dct/project/crc', 'component': dctProjectCrcComponent},
    //  {'path': '/dct/project/pm', 'component': dctProjectPmComponent},
    { 'path': '/change/pwd', 'component': changePwdComponent },
    { 'path': '/dct/settlementMange', 'component': SettlementMangeComponent },
    { 'path': '/settlementView', 'component': SettlementViewComponent },
    { 'path': '/dct/sign/audit', 'component': dctSignAudit },
    { 'path': '/manager/project/settlement/audit', 'component': SettlementReview },
];

export default routes