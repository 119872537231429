import React, { useState } from "react";
import modStyl from './detail-modal.module.scss'
import storageX, { Engine } from "../../../../../util/storageX";
import { message, Button, Input } from "antd";
import { ReplyImageQuestion } from "../../../../../services/dct/DctService";

const { userName } = storageX(Engine.Session).user()

type Props = {
  id: string
  onCancel(refresh: boolean): void
}

const Form: React.FC<Props> = ({ id, onCancel }) => {
  const [rejectReason, setRejectReason] = useState('')

  const onSubmit = async () => {
    await ReplyImageQuestion({ id, rejectReason })
    setRejectReason('')
    message.success('回复成功')
    onCancel(true)
  }

  return (
    <div className={modStyl.block}>
      <div className={modStyl.inner}>
        <div className={modStyl.wt} style={{background: '#4E88F6'}}>{userName}</div>
        <div className={modStyl.md}>
          <div className={modStyl.subtitle}>
            <Input.TextArea placeholder="请输入质疑内容" onChange={({ target })=> setRejectReason(target.value)} rows={2}></Input.TextArea>
          </div>
          <div className={modStyl.action}>
            <Button onClick={()=> onCancel(false)} size="small" type="text">取消回复</Button>
            <Button onClick={onSubmit} size="small" type="primary">回复</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Form;