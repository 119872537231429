import React, { useEffect, useState } from "react";
import { DownloadOutlined, QrcodeOutlined } from "@ant-design/icons";
import { QRCodeCanvas } from "qrcode.react";

import { BASE_URL } from "../../../../../../comm/config";
import storageX from "../../../../../../util/storageX";

import modStyl from './signAgreementQR.module.scss'
import { Button } from "antd";
import SignModal from "../signModal/SignModal";

// 签名状态
enum SignState {
  // 0未签名
  None,
  // 1是医生
  Doctor,
  // 2是患者
  Examinee,
  // 3是都签名
  All
}

type SignAgreementQRProps = {
  data: {
    code: string;
    agreementUrl: string;
    signatureState: number;
  }
}
const SignAgreementQR: React.FC<SignAgreementQRProps> = ({data}) => {

  const [text] = useState(()=> {
    const { origin } = new URL(BASE_URL)

    const tenantCode = storageX().user().tenantCode

    const params = new URLSearchParams()

    params.set('fromUrlFromH5', '/epro/patient/signAgreement')
    params.set('toBeforeH5Url', '1')
    params.set('subjectCode', data.code)
    params.set('from', 'EPRO_PT')
    params.set('tenantCode', tenantCode)

    return `${origin}/wechat-applet-epro/epro?${decodeURIComponent(params.toString())}`
  })

  const [visible, setVisible] = useState(false)
  const [downloadUrl, setDownloadUrl] = useState('')
  const [signatureState, setSignatureState] = useState(SignState.None)

  useEffect(()=> {
    setDownloadUrl(data.agreementUrl)
    setSignatureState(data.signatureState)
  }, [data.signatureState, data.agreementUrl])
  const targetName = sessionStorage.getItem('targetName') || '受试者';
  const textContentAll = (<><DownloadOutlined/>{targetName}知情协议</>)
  const textContent = (<><QrcodeOutlined/>{targetName}知情并绑定</>)

  return (
    <>
      <div className={modStyl.wrapper}>
        <div className={modStyl.header}>
          
          <div className={modStyl.label}>
          {
            SignState.All === signatureState ? textContentAll : textContent
          }
          </div>
          <div className={modStyl.btn}>
          {
            SignState.Examinee === signatureState && (
              <Button onClick={()=> setVisible(true)} type="primary" size="small">医生签署</Button>
            )
          }
          {
            SignState.All === signatureState && (
              <Button target="_blank" type="link" size="small" href={downloadUrl}>下载协议</Button>
            )
          }
          </div>
        </div>
        {
          [0, 1].includes(signatureState) && (
            <div className={modStyl.body}>
              {<QRCodeCanvas value={text} size={128} />}
              <p>{targetName}使用微信扫一扫进行协议签署</p>
            </div>
          )
        }
      </div>
      {visible && (
          <SignModal
            visible={visible}
            subjectCode={data.code}
            onComplete={(url)=> {
              setDownloadUrl(url)
              setSignatureState(signatureState+1)
            }}
            onClose={()=> setVisible(false)}/>
        )
      }
    </>
    );
};

export default SignAgreementQR;
