import React, { forwardRef, useEffect, useState } from "react";
import { message, message as Message } from "antd";
import {
  CloseCircleFilled,
  UploadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { GetCaseSts } from "../../../services/data/DataService";
import "./videoUpload.less";
import OSS from "ali-oss";

let client: any = null;

function UploadCom(props: any, ref: any) {
  const [value, setValue] = useState<any>([]);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [ossData, setOssData] = useState<any>({});
  const [isShow, setIsShow] = useState<any>(false);
  const [url, setUrl] = useState<any>("");
  const [laborName, setLaborName] = useState<any>("");

  useEffect(() => {
    if (props.videoUrl) {
      setUrl(props.videoUrl);
    }
  }, [props.videoUrl]);

  useEffect(() => {
    getOssData();
  }, []);

  /**
   * 提取上传类型
   * @param json
   * @returns
   */
  const filterPathPre = (json: any) => {
    let newJson = json;
    let data: any = [];
    data = newJson.pathPre.split(",");
    data.map((i: any) => {
      if (i.includes("file")) {
        newJson.pathPre = i;
      }
    });

    return newJson;
  };

  /**
   * 获取OSS访问令牌
   */
  const getOssData = () => {
    GetCaseSts().then((res) => {
      if (res.code == 0) {
        if (!res.data) {
          return;
        }
        setOssData(filterPathPre(res.data));
        client = new OSS({ ...filterPathPre(res.data) });
      }
    });
  };

  /**
   * 删除
   */
  const deleteImg = (url: any) => {
    let val = value.filter((value: any, index: any, array: any) => {
      return value !== url;
    });
    setValue(val);
    setUrl("");
    props.getValue("", "");
  };

  /**
   * 上传
   * @param {*} e
   */
  const uploadFile = (e: any) => {
    setLoading(true);
    let userCode = sessionStorage.getItem("userName");
    let tenantCode = localStorage.getItem("tenantCode");
    let event = e;
    console.log(e);
    if (!e.target.files[0]) {
      return;
    }

    setLaborName(e.target.files[0].name);

    const file = e.target.files[0];
    const maxSize = 1024 * 1024 * 1024;

    const [_, ext] = file.type.split("/");
    if (![".mp4"].includes(`.${ext}`)) {
      message.error("上传格式不允许，请上传mp4格式");
      return false;
    } else if (file.size > maxSize) {
      return message.error("文件大小不能超过2G，请重新上传");
    }
    //处理FileReader
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      const suffix = file.name.slice(file.name.lastIndexOf("."));
      let time = new Date().getTime();
      const path = `${ossData.pathPre}${tenantCode}/${userCode}/${time}${suffix}`;

      // oss上传
      client
        .put(path, file)
        .then((res: any) => {
          let list = value;
          list.push(res.url);
          setValue(list);
          setUrl(res.url);
          setLoading(false);
          setIsShow(false);

          props.getValue(event.target.files[0].name, res.url);
        })
        .catch((err: any) => {
          setLoading(false);
          message.error("上传失败！");
        });
    };
  };

  return (
    <div className={"upload_wrap"}>
      <div className="crf_upload">
        视频上传：
        {value &&
          value.length > 0 &&
          value.map((item: any, index: any) => (
            <div key={index} className="upload_img">
              <a href={item.url} className="file_name">
                &nbsp;{laborName}
              </a>
              <CloseCircleFilled
                className="icon_del"
                onClick={() => deleteImg(item)}
              />
            </div>
          ))}
        {isShow && (
          <input className="fileinp" type="file" accept="application/*" />
        )}
        {!isShow && (!value || value.length == 0) && (
          <div className="upload_wrap">
            <input
              type="file"
              accept=".mp4"
              onChange={(e) => uploadFile(e)}
              className="upload_file"
            />
            {loading ? (
              <LoadingOutlined />
            ) : (
              <>
                <UploadOutlined />
                &nbsp; 点击上传
              </>
            )}
          </div>
        )}
        <div className="upload_tip">
          格式支持mp4，为保证视频加载与播放的流畅性，建议上传大小不超过1G
        </div>
        <a
          href={url}
          className="upload_url"
          target="_blank"
          rel="noopener noreferrer"
        >
          {url}
        </a>
      </div>
    </div>
  );
}

export default forwardRef(UploadCom);
