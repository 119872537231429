/**
 * cra 首页
 */
import React, { Component } from 'react';
import acceptImg from "../../static/images/accept.png";
import todayAcceptImg from "../../static/images/todayAccept.png";
import reachImg from "../../static/images/reach.png";
import failImg from "../../static/images/fail.png";
import settleTaskImg from "../../static/images/settleTask.png";
import { GetDctInputStatistics } from '../../../../services/dct/DctService';
import DataComponent from "../../components/data";
import EnterComponent from "./enter";
import EnterTaskComponent from './task';
import './index.less';

let _this;

class CrcDataComponent extends Component {

    constructor(props) {
        super(props);

        _this = this;
        _this.childRef = React.createRef()
        this.state = {
            statisticsData: null,
            enterData: [],
        };
    }

    componentDidMount() {
        this.getStatistics()

        if (
            this.props.location.state &&
            Object.keys(this.props.location.state).length > 0 &&
            Object.keys(this.props.location.state).includes('enterStatus')
        ) {
            let { enterStatus, statusText } = this.props.location.state
            this.getValue(enterStatus, statusText)
        }
    }

    // 统计
    getStatistics = () => {
        GetDctInputStatistics().then(res => {
            if (res && res.code == 0) {
                if (!res.data || res.data.length == 0) {
                    return
                }
                this.setState({
                    statisticsData: res.data
                })
            }
        })
    }

    // 获取接单状态
    getStatus = (data) => {
        this.setState({
            enterData: data
        })
    }

    // 获取接单状态
    getValue = (v, t) => {
        if (!v) {
            if (t == '休息') {
                this.getStatistics()
                return
            }

            this.getStatistics()
            this.childRef.current.handleCheck('开始接单')
        }
    }

    render() {
        let { statisticsData, enterData } = _this.state;
        let statisticsList = [
            { key: 0, icon: acceptImg, name: '可接任务', number: statisticsData ? statisticsData.pendingReviewTotal : 0, color: '#276ec9' },
            { key: 1, icon: todayAcceptImg, name: '今日接受任务', number: statisticsData ? statisticsData.reviewTotal : 0, color: '#f39c00' },
            { key: 2, icon: reachImg, name: '今日合格任务', number: statisticsData ? statisticsData.adoptTotal : 0, color: '#23aea8' },
            { key: 3, icon: failImg, name: '今日失败任务', number: statisticsData ? statisticsData.rejectTotal : 0, color: '#f83b24' },
            { key: 4, icon: settleTaskImg, name: '可结算任务', number: statisticsData ? statisticsData.settleAccountsTotal : 0, color: '#58278f' }
        ]
        return (
            <div className={'dctCrc_content'}>
                <DataComponent data={statisticsList} />
                <EnterTaskComponent
                    getStatus={(v) => this.getStatus(v)}
                    ref={this.childRef}
                    {...this.props}
                />
            </div>
        )
    }
}
export default CrcDataComponent