/**
 * 活动报名
 */
import { requestPostRes, requestGetRes } from "../EdcService";

export const addActivity = (params) => {
  const url = `/plat/apply/creat/activity`;
  return requestPostRes(url, params);
};

// 更新活动
export const updateActivity = (params) => {
  const url = `/plat/apply/update/activity`;
  return requestPostRes(url, params);
};
// 删除活动
export const deleteActivity = (id) => {
  const url = `/plat/apply/update/state/${id}`;
  return requestPostRes(url);
};
// 查询活动
export const getActivity = (params) => {
  const url = `/plat/apply/select/activity`;
  return requestPostRes(url, params);
};

// 创建活动审核人信息
export const createAuditActivity = (params) => {
  const url = `/plat/apply/create/auditActivity `;
  return requestPostRes(url, params);
};

// 获取活动地址
export const getActivityUrl = (id) => {
  const url = `/plat/apply/getUrl/${id} `;
  return requestGetRes(url);
};

// 租户查项目
export const listByTenantCodes = (params) => {
  const url = `/plat/apply/tenant/projects/list`;
  return requestGetRes(url, params);
};
