import React, {Component, Fragment, useEffect, useState} from 'react';
import './planAdd.less'
import {connect} from 'react-redux';
import {Button, Form, Input, Select, message} from "antd";
import {GetPlanSave} from '../../../../services/design/DesignService'
import {clearThreeTitle} from '../../../../util/menuModuleUtil';

const layout = {
    labelCol: {
        span: 7,
    },
    wrapperCol: {
        span: 17,
    },
};
const {Option} = Select

function PlanAdd(props: any, ref: any) {

    const [state, setState] = useState<any>('USE') // 状态 默认是使用中

    useEffect(() => {
        return componentWillUnmount
    }, [])

    /**
     * 组件销毁 处理事务
     */
    const componentWillUnmount = () => {
        clearThreeTitle()
    }

    /**
     * 添加 提交表单
     */
    const handleFinish = (values: any) => {
        values.name = values.name.trim();
        /*values.code = values.code.trim();*/
        values.applyPatientCount = values.applyPatientCount.trim();
        values.applyProjectCount = values.applyProjectCount.trim();
        values.version = values.version.trim();
        GetPlanSave(values).then((res: any) => {
            if (res) {
                message.success('添加成功！')
                props.history.push('/design/plan')
            }
        })
    }

    return (
        <div className={'planAdd'}>
            <div className={'planAddCon'}>
                <div className={'planAdd_title'}>添加方案</div>
                <div className={'planAdd_con'}>
                    <Form
                        {...layout}
                        name="nest-messages"
                        onFinish={handleFinish}
                        requiredMark={true}
                    >
                        <Form.Item
                            label="方案名称"
                            name='name'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入方案名称',
                                },
                                {
                                    whitespace: true,
                                    message: '不能输入空格',
                                }
                            ]}
                        >
                            <Input
                                style={{width: 468, marginLeft: 6}}
                                placeholder="请输入方案名称"
                            />
                        </Form.Item>

                        {/*<Form.Item
                            label="方案编码"
                            name='code'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入方案编码',
                                },
                                {
                                    whitespace: true,
                                    message: '不能输入空格',
                                }
                            ]}
                        >
                            <Input
                                style={{width: 468, marginLeft: 6}}
                                placeholder="请输入方案编码"
                            />
                        </Form.Item>*/}

                        <Form.Item
                            label="应用患者数"
                            name='applyPatientCount'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入应用患者数',
                                },
                                {
                                    whitespace: true,
                                    message: '不能输入空格',
                                }
                            ]}
                        >
                            <Input
                                style={{width: 468, marginLeft: 6}}
                                placeholder="请输入应用患者数"
                            />
                        </Form.Item>

                        <Form.Item
                            label="应用项目数"
                            name='applyProjectCount'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入应用项目数',
                                }
                            ]}
                        >
                            <Input
                                style={{width: 468, marginLeft: 6}}
                                placeholder="请输入应用项目数"
                            />
                        </Form.Item>

                        <Form.Item
                            label="类型"
                            name='planType'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择类型',
                                },
                            ]}
                        >
                            <Select
                                placeholder='请选择类型'
                                style={{width: 468, marginLeft: 6}}
                            >
                                {
                                    props.typeList && props.typeList.length != 0 ?
                                        props.typeList.map((v: any) => (
                                            <Option value={v.get('code')}
                                                    key={v.get('code')}>{v.get('name')}</Option>
                                        )) : ''
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="状态"
                            name='planState'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择状态',
                                },
                            ]}
                            initialValue={state}
                        >
                            <Select
                                placeholder='请选择状态'
                                style={{width: 468, marginLeft: 6}}
                            >
                                {
                                    props.statusList && props.statusList.length != 0 ?
                                        props.statusList.map((v: any) => (
                                            <Option value={v.get('code')}
                                                    key={v.get('code')}>{v.get('name')}</Option>
                                        )) : ''
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="版本号"
                            name='version'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入版本号',
                                },
                                {
                                    whitespace: true,
                                    message: '不能输入空格',
                                }
                            ]}
                        >
                            <Input
                                style={{width: 468, marginLeft: 6}}
                                placeholder="请输入版本号"
                            />
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{span: 17, offset: 7}}
                            style={{marginBottom: 0}}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{marginLeft: 410}}
                            >
                                保存
                            </Button>
                        </Form.Item>

                    </Form>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    statusList: state.getIn(['design', 'statusList']),
    typeList: state.getIn(['design', 'typeList']),
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PlanAdd);