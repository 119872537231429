import React from 'react';
import {Form, Input, Button, Select, DatePicker, InputNumber, Radio } from 'antd';
import './addNewProject.less';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import {addNewProject, GetGraftList, GetConfigTypeList, GetConfigProjectTypeList} from "../../../services/project/ProjectService";
import PubSub from "pubsub-js";
import localStorageUtil from "../../../comm/localStorage";

const layout = {
    labelCol: {
        span: 7,
    },
    wrapperCol: {
        span: 17,
    },
};
const validateMessages = {
    required: '请输入${label}!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

let _this;
const {Option} = Select;

class AddNewProject extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            planCodeData: [],
            typeData: [],
            planVersion: '',
            loading: false
        }
    }

    componentDidMount() {
        _this.getGraftList() // 方案下拉
        _this.getConfigTypeList() // 类型下拉
    }

    /**
     * 方案下拉
     * @param values
     */
    getGraftList = () => {
        GetGraftList().then(res => {
            let drafts = [];
            if (res) {
                res.forEach(x => {
                    drafts.push({code: x.code, version: x.version, name: x.name});
                })
            }
            _this.setState({
                planCodeData: drafts
            });
        });
    }

    /**
     * 类型下拉
     * @param values
     */
    getConfigTypeList = () => {
        // GetConfigTypeList().then(res => {
        GetConfigProjectTypeList().then(res => {
            let types = [];
            if (res.typeEnumList.length != 0) {
                res.typeEnumList.forEach(x => {
                    types.push({code: x.code, name: x.name});
                })
            }
            _this.setState({
                typeData: types
            });
        });
    }

    /**
     * 提交表单
     * @param values
     */
    handleFinish = (values) => {
        this.setState({
            loading: true
        })

        values['endTimeVal'] = values['endTimeVal'].format('YYYY-MM-DD');
        values.planVersion = _this.state.planVersion;
        addNewProject(values).then(res => {
            if (res && res.code == 0) {
                const menus = localStorageUtil.get('menus', true);
                const menuModule = localStorageUtil.get('menuModule', true);
                const param = {
                    isInit: false,
                    menus: menus[menuModule.levelOneIndex].children
                }
                PubSub.publish("onLeftNavChange", param);
            }
            this.setState({
                loading: false
            })
        })
    };

    /**
     * 下拉框
     * @param values
     */
    handleChange = (value, option) => {
        if (option.version) {
            _this.setState({
                planVersion: option.version
            })
        }
    };

    render() {
        const targetName = sessionStorage.getItem('targetName') || '受试者';
        return (
            <div className={'addNewProject'}>
                <div className={'addNewProject_title'}>添加新项目</div>
                <div className={'addNewProject_con'}>
                    <Form
                        {...layout} name="nest-messages"
                        onFinish={_this.handleFinish}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <Form.Item
                            label="项目名称"
                            name='name'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入项目名称'
                                },
                                {
                                    whitespace: true,
                                    message: '不能输入空格',
                                }
                            ]}
                        >
                            <Input
                                placeholder="请输入项目名称"
                                style={{width: 468, marginLeft: 14}}
                            />
                        </Form.Item>

                        <Form.Item
                            label="项目Logo"
                            name='icon'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入项目Logo'
                                },
                            ]}
                        >
                            <Input
                                placeholder="请输入项目Logo"
                                style={{width: 468, marginLeft: 14}}
                            />
                        </Form.Item>

                        <Form.Item
                            label="项目方案"
                            name='planCode'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择项目方案!',
                                },
                            ]}
                        >
                            <Select
                                onChange={_this.handleChange}
                                placeholder='请选择项目方案'
                                style={{width: 468, marginLeft: 14}}
                            >
                                {
                                    _this.state.planCodeData.map(v => (
                                        <Option value={v.code}
                                                version={v.version}
                                                code={v.code}
                                                key={v.code}>{v.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="入组目标"
                            name='groupGoal'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入入组目标'
                                }, {
                                    pattern: /^[1-9]+[0-9]*]*$/,
                                    message: '请输入大于0的数值',
                                }
                            ]}
                        >
                            <Input
                                placeholder="1000"
                                style={{width: 468, marginLeft: 14}}
                            />
                        </Form.Item>

                        <Form.Item
                            label="截止日期"
                            name="endTimeVal"
                            rules={[
                                {
                                    type: 'object',
                                    required: true,
                                    message: '请选择日期!',
                                },
                            ]}
                        >
                            <DatePicker
                                locale={locale}
                                placeholder='请选择日期'
                                inputReadOnly
                                style={{width: 468, marginLeft: 14}}
                            />
                        </Form.Item>

                        <Form.Item
                            label="项目类别"
                            name='type'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择项目类别!',
                                },
                            ]}
                        >
                            <Select
                                onChange={_this.handleChange}
                                placeholder='请选择项目类别'
                                style={{width: 468, marginLeft: 14}}
                            >
                                {
                                    _this.state.typeData.map(v => (
                                        <Option value={v.code}
                                                code={v.code}
                                                key={v.code}>{v.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="是否允许超过目标数"
                            name='allowPass'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择容许值'
                                }
                            ]}
                            initialValue={1}
                        >
                            <Radio.Group style={{width: 468, marginLeft: 14}}>
                                <Radio value={1}>不容许</Radio>
                                <Radio value={2}>容许</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="是否开启结算申请"
                            name='isSettlement'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择是否开启结算申请'
                                }
                            ]}
                            initialValue={0}
                        >
                            <Radio.Group style={{width: 468, marginLeft: 14}}>
                                <Radio value={0}>不开启</Radio>
                                <Radio value={1}>开启</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="项目冗余度"
                            name='redundancy'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入项目冗余度'
                                },
                                {
                                    pattern: /^[\d.%]+$/,
                                    message: '请输入正确的数值'
                                }
                            ]}
                            initialValue={_this.props.redundancy}
                        >
                            <InputNumber
                                min={0}
                                formatter={value => `${value}%`}
                                parser={value => value.replace(/^(-1+)|[^\d]+/g, '')}
                                placeholder="30%"
                                style={{width: 468, marginLeft: 14}}
                            />
                        </Form.Item>
                        <Form.Item
                            label={`${targetName}编码前缀`}
                            name='subjectCodePrefix'
                            rules={[
                                {
                                    required: true,
                                    message:`请输入${targetName}编码前缀`
                                },
                                {
                                    pattern: /^[a-zA-Z0-9]{2,6}$/,
                                    message: '请输入2-6个字母或数字'
                                }
                            ]}
                        >
                            <Input
                                placeholder={`请输入${targetName}编码前缀`}
                                style={{width: 468, marginLeft: 14}}
                            />
                        </Form.Item>

                        <Form.Item
                            label="项目说明"
                            name='description'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input.TextArea
                                placeholder="多行输入"
                                style={{width: 468, marginLeft: 14, height: 138}}
                            />
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{span: 24}}
                            style={{textAlign: 'center'}}
                        >
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>
                                保 存
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }
}

export default AddNewProject