import React from 'react'

import { Empty } from 'antd';
import { BulbOutlined } from '@ant-design/icons';
import modStyl from './index.module.scss'

type Item = {
  code: string;
  isWarning: boolean;
  remark: string;
}

type Props = {
  items: Item[];
}

const Remarks: React.FC<Props> = ({items = []}) => {
  return (
    <div className={modStyl.wrapper}>
      <div className={modStyl.header}>
        <div className={modStyl.label}><BulbOutlined/>备注</div>
      </div>
      <div className={modStyl.content}>
        <ul>
        {
          items.length > 0 && items.map((t) => <li key={t.code} className={`${t.isWarning ? modStyl.warn : ''}`}>{t.remark}</li>)
        }
        </ul>
        {
          items.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无备注信息"/>
        }
      </div>
    </div>
  )
}

export default Remarks