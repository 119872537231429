import React from 'react';
import { Table, Input, DatePicker, Button, Select, message } from 'antd';
import './index.less'
import { getLoginLogList } from "../../../services/log/logService";
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
const { RangePicker } = DatePicker;
const { Option } = Select;


class loginLog extends React.Component {
    state = {
        user: '',
        role: '',
        startDate: '',
        endDate: '',
        loginType: '',
        data: [],
        page: 1,
        pageSize: 10,
        totalRow: 0,
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        let params = {
            page: this.state.page,
            pageSize: this.state.pageSize,
            role: this.state.role,
            user: this.state.user,
            loginType: this.state.loginType,
            ed: this.state.endDate,
            sd: this.state.startDate,
        }
        getLoginLogList(params).then(res => {
            this.setState({
                data: res.rows,
                totalRow: res.total,
            })
        }).catch(err => {
            message.error(err.message);
        })

    }


    /**
     * 改变页数
     * @param {*} page 
     */
    changePage = (page) => {
        this.setState({ page }, () => {
            this.getData()
        })
    }

    onChange = (value, dateString) => {
        this.setState({
            startDate: dateString[0],
            endDate: dateString[1]
        })
    }


    handleChange = (val) => {
        this.setState({
            loginType: val
        })
    }


    /**
     * 搜索
     */
    handleSearch = () => {
        this.setState({
            page: 1,
            data: []
        }, () => {
            this.getData()
        })
    }


    render() {
        const columns = [
            {
                title: '登录账号',
                dataIndex: 'createUser'
            },
            {
                title: '登录人',
                dataIndex: 'createName'
            },
            {
                title: '登录时间',
                dataIndex: 'createTime',
                width: 220,
                render: (text, record, index) => {
                    return (
                        <div >
                            {moment(new Date(text)).format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                    )
                }
            },
            {
                title: '登录角色',
                dataIndex: 'roleName'
            },
            {
                title: '登录方式',
                dataIndex: 'loginType',
                render: (text) => {
                    return (
                        <div >
                            {text == 'WEB' ? 'Web端' : text == 'APP' ? '小程序' : ""}
                        </div>
                    )
                }
            }
        ];


        const pagination = {
            current: this.state.page,
            pageSize: this.state.pageSize,
            total: this.state.totalRow,
            showSizeChanger: false,
            onChange: this.changePage
        }


        return (
            <div className='patient_container'>
                <div className='search'>
                    <div className='searchItem'>账号：<Input placeholder="请输入账号" onChange={e => this.setState({ user: e.target.value })} style={{ width: 200 }} /></div>
                    <div className='searchItem'>角色：<Input placeholder="请输入角色" onChange={e => this.setState({ role: e.target.value })} style={{ width: 200 }} /></div>
                    <div className='searchItem'>方式：
                        <Select onChange={this.handleChange} style={{ width: 200 }} placeholder='请选择登录方式'>
                            <Option value="WEB">Web端</Option>
                            <Option value="APP">小程序</Option>
                        </Select></div>
                    <div className='searchItem'>时间：<RangePicker locale={locale} onChange={this.onChange} /></div>
                    <Button type="primary" onClick={this.handleSearch}>搜索</Button>
                </div>

                <Table rowKey={(record) => record.id} bordered columns={columns} dataSource={this.state.data} pagination={pagination} />

            </div>
        )
    }

}

export default loginLog;