import React from 'react';
import { Button, Select, DatePicker, Space, Table, Modal, Form, Input, Spin, message, Radio, Image, ConfigProvider, Checkbox, InputNumber } from 'antd';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { getCsoDoctorList, getDoctorApproveList, getDoctorApproveListDetail, doctorApproveListOperation, doctorApproveStatistics,
    getCsoDrugs, getCsoDrugList, getCaseSts, getCsoDrugsList, getCsoDrugsSave, getCsoDrugsDetail, updateCsoDrugState, getCsoDrugProperty } from '../../../services/cso/CsoService'
import './index.less'
import { PlusCircleOutlined } from '@ant-design/icons';
import OSS from "ali-oss";
import ImagePicker from '../../plat/settlementEvidence/formComponent/image-picker/index';
import RemarksModal from './modal/modal';

import { debounce } from 'lodash';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;


let auditOptions = [{
    label: '已通过',
    value: '1'
}, 
// {
//     label: '审核中',
//     value: '2'
// }, 
{
    label: '未通过',
    value: '3'
}, {
    label: '待审核',
    value: '4'
}]

let pickOptions = [{
    label: '是',
    value: true
}, {
    label: '否',
    value: false
}]

let stateOptions = [{
    label: '上架中',
    value: 1
}, {
    label: '已下架',
    value: 0
}]

let typeOptions = [{
    label: '处方药',
    value: 1
}, {
    label: '非处方药',
    value: 2
}, {
    label: '医疗器械',
    value: 3
}, {
    label: '特医食品',
    value: 4
}]


let medicineTypeOptions = [{
    label: '处方药',
    value: '1'
},{
    label: '非处方药',
    value: '0'
}]

const identityMap = {
    1: '医生',
    2: '药师',
    3: '护士',
    4: '非医疗专业人士'
}

const stateMap = {
    1: '上架中',
    0: '已下架'
};

const typeMap = {
    1: '处方药',
    2: '非处方药',
    3: '医疗器械',
    4: '特医食品' 
};

class Research extends React.Component {

    constructor(props) {
        super(props);
        this.lastFetchId = 0;
        this.fetchDoctorUser = debounce(this.fetchDoctorUser, 800);
    }

    state = {
        auditOptions: auditOptions,
        typeOptions: typeOptions,
        medicalPropertiesOptions: [],
        endDate: "",
        identityType: "",
        state: "",
        startDate: "",
        source: '',
        isPicked: null, // 是否甄选
        medicineType: null, // 产品类型
        approveUserName: '',
        page: 1,
        pageSize: 10,
        data: [],
        totalRow: 0,

        fetching: false,

        visible: false,
        detailVisible: false,
        importVisible: false,

        showDoctorList: true,
        doctorList: [],
        doctorCodeList: [],
        doctorCode: '',
        doctorName: undefined,
        loading: false,
        searchLoading: false,
        saveLoading: false,
        reasonLoading: false,

        diseases: [''],
        departments: [''],

        medicineName: null,
        medicalManufacturerName: null,
        medicalType: null,
        medicalProperties: null,
        ossData: {},
        photos: [],

        state: '',
        name: '',
        aliasName: '',
        manufacturerName: '',
        useType: '', // 用法
        type: '', // 药品类型
        disease: '', // 所属疾病
        adaptDisease: '', // 适用症状
        adaptMember: '', // 适用人群
        // isPick: '',
        pickType: '',
        channelType: '',
        maxCanBy: 0,
        size: '', // 规模
        taboo: '', // 禁忌
        price: '', // 价格
        // sourceType: '', // 渠道

        arrow1: true, //true是展开 false是折叠
        arrow2: true,
        arrow3: true,
        arrow4: true,
        textArea: '',
        nowRecord: {}, // 当前点击的条目
        detailRecord: {}, // 详情页条目
        personalInfo: {}, // 当前点击的条目
        checkBoxArr: [],
    }

    formRef = React.createRef();

    componentDidMount() {
        this.getPropety();
        this.getData();
        this.getOssData();
    }

    getPropety = () => {
        getCsoDrugProperty('department').then((res) => {
            if (res.code == 0) {
                let data = res.data || [];
                let mData = data.map((val) => ({
                    label: val.name,
                    value: val.code
                }))
                this.setState({
                    medicalPropertiesOptions: mData,
                })
            }

        })
    }

    getData = () => {
        this.setState({
            searchLoading: true,
            loading: true
        });
        console.log('medicalProperties', this.state.medicalProperties);
        let params = {
            page: this.state.page,
            pageSize: this.state.pageSize,
            name: this.state.medicineName ? this.state.medicineName : null,
            manufacturerName: this.state.medicalManufacturerName ? this.state.medicalManufacturerName : null,
            type: this.state.medicalType ? this.state.medicalType : null,
            properties: (this.state.medicalProperties && this.state.medicalProperties.length > 0) ? this.state.medicalProperties : null,
        }
        getCsoDrugsList(params).then((res) => {
            if (res.code == 0) {
                const data = res.data.rows || [];
                this.setState({
                    data: data,
                    totalRow: res.data.total,
                })
                setTimeout(() => {
                    this.setState({
                        searchLoading: false,
                        loading: false
                    });
                }, 300)
            }

        })
    }

    changeDate = (date, dateString) => {
        this.setState({
            startDate: dateString[0],
            endDate: dateString[1]
        })
    }

    //邀请人查询
    handleSourceChange = event => {
        this.setState({
            source: event.target.value
        })
    };

    //药品名
    drugNameChange = event => {
        this.setState({
            medicineName: event.target.value
        })
    };

    //生产商
    manufacturerNameChange = event => {
        this.setState({
            medicalManufacturerName: event.target.value
        })
    };

    // 批量导入
    batchImport = () => {
        this.setState({ importVisible: true })
    }

    handleSearch = () => {
        this.setState({
            page: 1
        }, () => {
            this.getData()
        })
    }


    changePage = (page) => {
        this.setState({ page }, () => {
            this.getData()
        })
    }
    changePageSize = (current, size) => {
        this.setState({ page: 1, pageSize: size }, () => {
            this.getData()
        })
    }

    //医生下拉框改变
    handleDoctorChange = data => {
        this.setState({
            doctorCode: data ? data.value : null,
            doctorList: [],
            fetching: false,
        });
    };

    fetchDoctorUser = value => {
        if (!value) {
            this.setState({ doctorList: [], fetching: false });
            return;
        }
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ doctorList: [], fetching: true });

        let params = {
            page: 1,
            pageSize: 100,
            content: value
        }

        getCsoDoctorList(params).then(res => {
            if (fetchId !== this.lastFetchId) {
                // for fetch callback order
                return;
            }
            const data = res.rows.map(user => ({
                text: user.name,
                value: user.code
            }));
            const listData = res.rows.map(user => ({
                text: user.code,
                value: user.code
            }));
            this.setState({ doctorList: data, doctorCodeList: listData, fetching: false });
        });
    }

    addDisease = () => {
        this.setState({
            diseases: [...this.state.diseases, '']
        });
    }

    diseasesChange = (index, val) => {
        let _diseases = this.state.diseases.map(v => v);
        _diseases[index] = val;
        this.setState({
            diseases: _diseases
        });
    }

    addDepartment = () => {
        this.setState({
            departments: [...this.state.departments, '']
        });
    }

    departmentsChange = (index, val) => {
        let departments = this.state.departments.map(v => v);
        departments[index] = val;
        this.setState({
            departments: departments
        });
    }

    edit = (data) => {
        let _photo = this.state.photos
        // 切换过后照片清空
        if (data.code != this.state.nowRecord.code) {
            _photo = [];
        }
        // 查看详情
        getCsoDrugsDetail(data.code).then((res) => {
            if (res.code == 0) {
                let detailData = res.data || {};
                this.setState({
                    detailRecord: res.data || {}
                }, () => {
                    // 默认选中
                    if (res.data?.icon) {
                        _photo = [{ url: res.data.icon }]
                    }
                    let _diseases = [''];
                    if (data.disease) {
                        if (data.disease.indexOf(',') > 0) {
                            _diseases = data.disease.split(',');
                        } else {
                            _diseases = [data.disease];
                        }
                    }
                    let departments = [''];
                    if (data.departments) {
                        if (data.departments.indexOf(',') > 0) {
                            departments = data.departments.split(',');
                        } else {
                            departments = [data.departments];
                        }
                    }
                    this.setState({ 
                        nowRecord: data,
                        state: data.state,
                        name: data.name || null,
                        aliasName: data.aliasName || null,
                        manufacturerName: data.manufacturerName || null,
                        type: data.type || null,
                        departments: data.departments || null,
                        disease: data.disease || null,
                        icon: data.icon || null,
                        useType: detailData.useType || null,
                        taboo: detailData.taboo || null,
                        price: detailData.price || null,
                        adaptDisease: detailData.adaptDisease || null,
                        adaptMember: detailData.adaptMember || null,
                        // isPick: detailData.isPick ?? null,
                        pickType: detailData.pickType ?? null,
                        channelType: detailData.channelType ?? '',
                        maxCanBy: detailData.maxCanBy ?? 0,
                        size: detailData.size || null,
                        // sourceType: detailData.sourceType || null,
                        photos: _photo,
                        diseases: _diseases,
                        departments: departments
                    }, () => {
                        this.setState({
                            visible: true
                        })
                    })
                })
            }
        })

    }

    // 查看药品详情
    detail = (data) => {
        getCsoDrugsDetail(data.code).then((res) => {
            if (res.code == 0) {
                this.setState({
                    nowRecord: data,
                    detailRecord: res.data || {}
                }, () => {
                    this.setState({
                        detailVisible: true
                    })
                })
            }
        })
    }

    /**
     * 获取OSS访问令牌
     */
    getOssData = () => {
        getCaseSts().then((res) => {
            if (res.code == 0) {
                if (!res.data) {
                    return
                }
                this.setState({
                    ossData: res.data
                });
            }
        })
    };

    /**
     * 提取上传类型
     * @param {*} json 
     * @returns 
     */
    filterPathPre = (json, type) => {
        let newJson = json;
        let data = [];
        data = newJson.pathPre.split(',')
        data.map((i) => {
            if (i.includes(type)) {
                newJson.pathPre = i
            }
        })

        return newJson
    }
    
    //当图片选择改变时
    onPhotoChange = (files) => {
        this.setState({
            photos: files
        });
    }

    // 实时上传方法
    onUpload = (item) => {
        const uploadToOss = 'upload_to_oss_web_component'; // 区分来源
        let ossData = this.state.ossData;
        let client = new OSS({ ...this.filterPathPre(ossData, 'image')});
        return new Promise((resolve, reject) => {
            const file = item.file;
            let _businessName = 'cso/manage' // 业务名称
            let _imgName =  ''; // 图片名称
            let imgName = '';
            //处理FileReader
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (e) => {
                const suffix = file.name.slice(file.name.lastIndexOf('.'));
                const fileName = file.name.slice(0, file.name.lastIndexOf('.'));
                _imgName = (imgName || fileName);
                const path = `${ossData.pathPre}${uploadToOss}/${_businessName}/${_imgName}${suffix}`;
                // oss上传
                client.put(
                    path,
                    file,
                ).then((res) => {
                    let url = res.url;
                    resolve({ url: url });
                })
            }
        });
    };

    // 提交
    handleSubmit = () => {
        this.saveConfirm();
    }

    // 提交
    saveConfirm = async() => {
        let url = null;
        if (this.state.photos.length > 0) {
            if (this.state.photos[0]?.url.indexOf('data:image') == 0) {
                let uploadUrl =  await this.onUpload(this.state.photos[0]);
                if (uploadUrl && uploadUrl.url) {
                    url = uploadUrl.url;
                }
            }
        }
        let params = {
            code: this.state.nowRecord.code,
            name: this.state.name,
            aliasName: this.state.aliasName,
            useType: this.state.useType,
            manufacturer: this.state.manufacturerName,
            type: this.state.type,
            adaptDisease: this.state.adaptDisease,
            adaptMember: this.state.adaptMember,
            // isPick: this.state.isPick,
            pickType: this.state.pickType,
            channelType: this.state.channelType,
            maxCanBy: this.state.maxCanBy,
            size: this.state.size,
            taboo: this.state.taboo,
            price: this.state.price,
            // sourceType: this.state.sourceType,
            department: this.state.departments.filter(val => !!val).join(',') || '',
            disease: this.state.diseases.filter(val => !!val).join(',') || ''
        }
        if (url) {
            params.icon = url;
        }
        this.setState({
            saveLoading: true
        })
        if (this.state.state != this.state.nowRecord.state) {
            // 更新状态
            await updateCsoDrugState({
                code: this.state.nowRecord.code,
                state: this.state.state
            })
        }
        getCsoDrugsSave(params).then((res) => {
            if (res.code == 0) {
                message.success('操作成功');
                this.getData();
                setTimeout(() => {
                    this.setState({
                        visible: false,
                        saveLoading: false
                    })
                }, 300)
            }
            this.setState({
                saveLoading: false
            })
        })
    }

    /**
     * 设置关闭隐藏状态和要传递的参数
     */
    closeModal = () => {
        this.setState({
            importVisible: false,
        });
    }

    render() {
        let { data, typeOptions, auditOptions, visible, doctorName, fetching, doctorList, showDoctorList, doctorCodeList, statistics, loading,
            arrow1, arrow2, arrow3, arrow4, source, approveUserName, searchLoading, reasonLoading, saveLoading,
            visible2, personalInfo } = this.state;
        const columns = [
            {
                title: '药品编号',
                dataIndex: 'code',
                width: 130
            },
            {
                title: '药品名',
                dataIndex: 'name'
            },
            {
                title: '通用名',
                dataIndex: 'aliasName'
            },
            {
                title: '状态',
                width: 100,
                render: (text, record) =>
                <div className={`role_verify ${record.state == 1 ? 'in' : (record.state == 0) ? 'out' : ''  }`}>
                    {
                        record.state == 1 ? '上架中' : 
                        (record.state == 0) ? '已下架' : ''
                    }
                </div>
            },
            {
                title: '生产商',
                dataIndex: 'manufacturerName'
            },
            {
                title: '产品类型',
                render: (text, record) =>
                <div className={''}>
                    {
                        record.type == 1 ? '处方药' : 
                        (record.type == 2) ? '非处方药' : 
                        (record.type == 3) ? '医疗器械' : 
                        (record.type == 4) ? '特医食品' : ''
                    }
                </div>
            },
            {
                title: '所属领域',
                dataIndex: 'departments'
            },
            {
                title: '治疗疾病',
                dataIndex: 'disease'
            },
            // {
            //     title: '是否甄选',
            //     dataIndex: ''
            // },
            {
                title: '操作',
                fixed: 'right',
                // width: 180,
                render: (text, record) =>
                    <div >
                        <Button style={{ padding: 0, marginRight: '10px' }} type="link" onClick={() => this.edit(record)} >
                            编辑
                        </Button>
                        <Button style={{ padding: 0, marginRight: '10px' }} type="link" onClick={() => this.detail(record)} >
                            详情
                        </Button>
                        {/* <Button className='btn_disabled' style={{ padding: 0, marginRight: '10px' }} type="link" onClick={() => this.edit(record)} >
                            上架
                        </Button>
                        <Button danger className='btn_disabled'  style={{ padding: 0, marginRight: '10px' }} type="link" onClick={() => this.edit(record)} >
                            下架
                        </Button> */}
                    </div>
            }
        ];


        const pagination = {
            current: this.state.page,
            pageSize: this.state.pageSize,
            total: this.state.totalRow,
            showSizeChanger: true,
            onChange: this.changePage,
            onShowSizeChange: this.changePageSize
        }


        return (
            <>
                <div className='cso_doctor_medicine_manage_container'>
                    <div className='search'>
                        <div className='search_con'>
                            {/* <div className='search_item'>
                                <span className='label'>药品搜索</span>
                                <Select
                                    showSearch
                                    placeholder="编号/商品名/通用名/厂商"
                                    style={{ width: 280 }}
                                    options={auditOptions}
                                    allowClear
                                    onChange={value => this.setState({ state: value })}
                                >
                                </Select>
                            </div>
                            <div className='search_item'>
                                <span className='label'>当前状态</span>
                                <Select
                                    style={{ width: 280 }}
                                    options={typeOptions}
                                    placeholder="请选择身份类型"
                                    allowClear
                                    onChange={value => this.setState({ identityType: value })}
                                />
                            </div> */}
                            {/* <div className='search_item'>
                                <span className='label'>类型</span>
                                <Select
                                    allowClear
                                    style={{ width: 280 }}
                                    placeholder=""
                                    options={medicineTypeOptions}
                                    onChange={value => this.setState({ medicineType: value})}
                                >
                                </Select>
                            </div> */}
                            {/* <div className='search_item'>
                                <span className='label'>是否甄选</span>
                                <Select
                                    placeholder=""
                                    style={{ width: 280 }}
                                    options={pickOptions}
                                    allowClear
                                    onChange={value => this.setState({ isPicked: value })}
                                >
                                </Select>
                            </div> */}
                            {/* <div className='search_item'>
                                <span className='label'>是否甄选</span>
                                <Select
                                    allowClear
                                    showSearch
                                    style={{ width: 280 }}
                                    labelInValue
                                    value={doctorName}
                                    placeholder="请输入医生姓名搜索"
                                    notFoundContent={fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onSearch={this.fetchDoctorUser}
                                    onChange={this.handleDoctorChange}
                                >
                                    {(doctorList || []).map(d => (
                                        <Option key={d.value} value={d.value}>{d.text}</Option>
                                    ))}
                                </Select>
                            </div> */}
                            {/* <div className='search_item'>
                                <span className='label'>审核时间</span>
                                <RangePicker onChange={this.changeDate} locale={locale} style={{ width: 280 }} />
                            </div> */}
                            <div className='search_item'>
                                <span className='label'>药品名</span>
                                <Input
                                    allowClear
                                    style={{ width: 280 }}
                                    value={this.state.medicineName}
                                    placeholder="请输入药品名或商品名"
                                    onChange={this.drugNameChange}
                                >
                                </Input>
                            </div>
                            <div className='search_item'>
                                <span className='label'>生产商</span>
                                <Input
                                    allowClear
                                    style={{ width: 280 }}
                                    value={this.state.medicalManufacturerName}
                                    placeholder="请输入生产商名"
                                    onChange={this.manufacturerNameChange}
                                >
                                </Input>
                            </div>
                            <div className='search_item'>
                                <span className='label'>产品类型</span>
                                <Select
                                     allowClear
                                     style={{ width: 280 }}
                                     options={typeOptions}
                                     placeholder="请选择产品类型"
                                     onChange={value => this.setState({ medicalType: value })}
                                >
                                </Select>
                            </div>
                            <div className='search_item'>
                                <span className='label'>所属领域</span>
                                <Select
                                     allowClear
                                     mode='multiple'
                                     style={{ width: 280 }}
                                     options={this.state.medicalPropertiesOptions}
                                     placeholder="请选择所属领域"
                                     onChange={value => this.setState({ medicalProperties: value })}
                                >
                                </Select>
                            </div>
                        </div>
                        <div className='search_btn' style={{ marginRight: 0 }}>
                            <Button type="primary" style={{ width: 88 }} onClick={this.batchImport}>批量导入</Button>
                        </div>
                        <div className='search_btn'>
                            <Button type="primary" style={{ width: 88 }} loading={searchLoading} onClick={this.handleSearch}>查询</Button>
                        </div>
                    </div>

                    <div className='cpds_content'>
                        <Spin tip="数据加载中..." spinning={loading}>
                            <Table rowKey={(record) => record.id} dataSource={data} columns={columns} pagination={pagination} scroll={{ x: 'calc(700px + 50%)' }} />
                        </Spin>
                    </div>

                </div>

                <Modal
                    visible={visible}
                    title=''
                    closable={true}
                    onCancel={() => { this.setState({ visible: false, saveLoading: false }); }}
                    destroyOnClose
                    width={650}
                    className='cso_medical_manage_edit_modal'
                    onOk={this.handleSubmit}
                    footer={
                        <div className='modal_footer'>
                            <Button type='primary' loading={saveLoading} onClick={this.handleSubmit}>更改</Button>
                        </div>
                    }
                    okText="通过"
                    cancelText="拒绝"
                >
                    <div className='modal_content'>
                        <div className='search_item'>
                            <span className='label'>药品名</span>
                            <Input
                                allowClear
                                style={{ width: 280 }}
                                value={this.state.name}
                                onChange={e => this.setState({ name: e.target.value })}
                            >
                            </Input>
                        </div>
                        <div className='search_item'>
                            <span className='label'>通用名</span>
                            <Input
                                allowClear
                                style={{ width: 280 }}
                                value={this.state.aliasName}
                                onChange={e => this.setState({ aliasName: e.target.value })}
                            >
                            </Input>
                        </div>
                        <div className='search_item search_item_upload'>
                            <span className='label'>缩略图</span>
                            <div className='label_upload'>
                                <ImagePicker
                                    style={{
                                        with: '280px'
                                    }}
                                    filesList={this.state.photos}
                                    onChange={this.onPhotoChange}
                                    multiple={false}
                                    max={1}
                                    mode="cover"
                                    width="22%"
                                    config={['defaultDashed']}
                                    disabled={false}
                                />
                            </div>
                        </div>
                        <div className='search_item'>
                            <span className='label'>价格</span>
                            <Input
                                allowClear
                                type='number'
                                style={{ width: 280 }}
                                value={this.state.price}
                                onChange={e => this.setState({ price: e.target.value })}
                            >
                            </Input>
                        </div>
                        <div className='search_item'>
                            <span className='label'>状态</span>
                            <Select
                                className='select_item'
                                placeholder=""
                                style={{ width: 280 }}
                                options={stateOptions}
                                allowClear={false}
                                value={this.state.state}
                                onChange={value => this.setState({ state: value })}
                            >
                            </Select>
                        </div>
                        <div className='search_item'>
                            <span className='label'>适应症</span>
                            <Input
                                allowClear
                                style={{ width: 280 }}
                                value={this.state.adaptDisease}
                                onChange={e => this.setState({ adaptDisease: e.target.value })}
                            >
                            </Input>
                        </div>
                        <div className='search_item'>
                            <span className='label'>适应人群</span>
                            <Input
                                allowClear
                                style={{ width: 280 }}
                                value={this.state.adaptMember}
                                onChange={e => this.setState({ adaptMember: e.target.value })}
                            >
                            </Input>
                        </div>
                        <div className='search_item'>
                            <span className='label'>用法</span>
                            <Input
                                allowClear
                                style={{ width: 280 }}
                                value={this.state.useType}
                                onChange={e => this.setState({ useType: e.target.value })}
                            >
                            </Input>
                        </div>
                        <div className='search_item'>
                            <span className='label'>生产商</span>
                            <Input
                                allowClear
                                style={{ width: 280 }}
                                value={this.state.manufacturerName}
                                onChange={e => this.setState({ manufacturerName: e.target.value })}
                            >
                            </Input>
                        </div>
                        <div className='search_item'>
                            <span className='label'>规模</span>
                            <Input
                                allowClear
                                style={{ width: 280 }}
                                value={this.state.size}
                                onChange={e => this.setState({ size: e.target.value })}
                            >
                            </Input>
                        </div>
                        <div className='search_item'>
                            <span className='label'>禁忌</span>
                            <TextArea
                                allowClear
                                style={{ width: 280 }}
                                value={this.state.taboo}
                                onChange={e => this.setState({ taboo: e.target.value })}
                            >
                            </TextArea>
                        </div>
                        <div className='search_item'>
                            <span className='label'>产品类型</span>
                            <Select
                                className='select_item'
                                placeholder=""
                                style={{ width: 280 }}
                                options={typeOptions}
                                allowClear={false}
                                value={this.state.type}
                                onChange={value => this.setState({ type: value })}
                            >
                            </Select>
                        </div>
                        <div className='search_item'>
                            <span className='label'>甄选/优选</span>
                            {/* <Select
                                className='select_item'
                                placeholder=""
                                style={{ width: 280 }}
                                options={pickOptions}
                                allowClear={false}
                                value={this.state.isPick}
                                onChange={value => this.setState({ isPick: value })}
                            >
                            </Select> */}
                            <Radio.Group onChange={e => this.setState({ pickType: e.target.value })} value={this.state.pickType}>
                                <Radio value={1}>甄选</Radio>
                                <Radio value={2}>优选</Radio>
                                <Radio value={0}>普通产品</Radio>
                            </Radio.Group>
                        </div>
                        {
                            this.state.departments.map((val, index) => (
                            <div className='search_item'>
                                <span style={{ visibility: index !== 0 ? 'hidden' : ''}} className='label'>所属领域</span>
                                <Input
                                    allowClear
                                    style={{ width: 280 }}
                                    value={val}
                                    onChange={e => { this.departmentsChange(index, e.target.value) }}
                                >
                                </Input>
                                {
                                    index == (this.state.departments.length - 1) &&
                                    <PlusCircleOutlined className='icon_plus' onClick={this.addDepartment}></PlusCircleOutlined>
                                }
                            </div>
                            ))
                        }
                        {
                            this.state.diseases.map((val, index) => (
                                <div className='search_item'>
                                    <span style={{ visibility: index !== 0 ? 'hidden' : ''}} className='label'>治疗疾病</span>
                                    <Input
                                        allowClear
                                        style={{ width: 280 }}
                                        value={val}
                                        onChange={e => { this.diseasesChange(index, e.target.value) }}
                                    >
                                    </Input>
                                    {
                                        index == (this.state.diseases.length - 1) &&
                                        <PlusCircleOutlined className='icon_plus' onClick={this.addDisease}></PlusCircleOutlined>
                                    }
                                </div>
                            ))
                        }
                        <div className='search_item'>
                            <span className='label'>境外药品</span>
                            <Radio.Group onChange={e => this.setState({ channelType: e.target.value })} value={this.state.channelType}>
                                <Radio value={1}>是</Radio>
                                <Radio value={2}>否</Radio>
                            </Radio.Group>
                        </div>
                        <div className='search_item'>
                            <span className='label'>最大购买量</span>
                            <InputNumber onChange={val => this.setState({ maxCanBy: val })} min={1} value={this.state.maxCanBy} defaultValue={this.state.maxCanBy} controls={true} />
                        </div>
                    </div>
                </Modal>

                <Modal
                    visible={this.state.detailVisible}
                    title=''
                    closable={true}
                    onCancel={() => { this.setState({ detailVisible: false }); }}
                    destroyOnClose
                    centered
                    width={690}
                    className='cso_medical_manage_detail_modal'
                    footer={null}
                >
                    <div className='modal_content'>
                        <div className='search_item'>
                            <span className='label'>药品名：</span>
                            <div className='text'>{this.state.detailRecord.name}</div>
                        </div>
                        <div className='search_item'>
                            <span className='label'>商品名：</span>
                            <div className='text'>{this.state.detailRecord.aliasName}</div>
                        </div>
                        <div className='search_item'>
                            <span className='label'>状态：</span>
                            <div className='text'>{stateMap[this.state.detailRecord.state]}</div>
                        </div>
                        <div className='search_item'>
                            <span className='label'>缩略图：</span>
                            <div className='text'>
                                {
                                    this.state.detailRecord.icon && 
                                    <img style={{ width: '100px' }} src={this.state.detailRecord.icon} />
                                }
                            </div>
                        </div>
                        <div className='search_item'>
                            <span className='label'>适用症状：</span>
                            <div className='text'>{this.state.detailRecord.adaptDisease}</div>
                        </div>
                        <div className='search_item'>
                            <span className='label'>适用人群：</span>
                            <div className='text'>{this.state.detailRecord.adaptMember}</div>
                        </div>
                        <div className='search_item'>
                            <span className='label'>价格：</span>
                            <div className='text'>{this.state.detailRecord.price}</div>
                        </div>
                        <div className='search_item'>
                            <span className='label'>用法：</span>
                            <div className='text'>{this.state.detailRecord.useType}</div>
                        </div>
                        <div className='search_item'>
                            <span className='label'>生产商：</span>
                            <div className='text'>{this.state.detailRecord.manufacturerName || this.state.nowRecord.manufacturerName}</div>
                        </div>
                        <div className='search_item'>
                            <span className='label'>规模：</span>
                            <div className='text'>{this.state.detailRecord.size}</div>
                        </div>
                        <div className='search_item'>
                            <span className='label'>禁忌：</span>
                            <div className='text'>{this.state.detailRecord.taboo}</div>
                        </div>
                        <div className='search_item'>
                            <span className='label'>产品类型：</span>
                            <div className='text'>{typeMap[this.state.detailRecord.type]}</div>
                        </div>
                        <div className='search_item'>
                            {/* <span className='label'>是否甄选：</span> */}
                            {/* <div className='text'>{this.state.detailRecord.isPick ? '是' : '否'}</div> */}
                            <span className='label'>甄选/优选：</span>
                            <div className='text'>{this.state.detailRecord.pickType == 1 ? '甄选' : this.state.detailRecord.pickType == 2 ? '优选' : '普通产品' }</div>
                        </div>
                        <div className='search_item'>
                            <span className='label'>所属领域：</span>
                            <div className='text'>{this.state.detailRecord.departments}</div>
                        </div>
                        <div className='search_item'>
                            <span className='label'>治疗疾病：</span>
                            <div className='text'>{this.state.detailRecord.disease}</div>
                        </div>
                        <div className='search_item'>
                            <span className='label'>境外药品：</span>
                            <div className='text'>{this.state.detailRecord.channelType == 1 ? '是' : this.state.detailRecord.channelType == 2 ? '否' : '' }</div>
                        </div>
                    </div>
                </Modal>
                {
                <div className={'modal'}>
                    {
                        this.state.importVisible 
                        &&
                        <RemarksModal
                            visible={this.state.importVisible}
                            closeModal={this.closeModal}
                        />
                    }

                </div>
                }
            </>
        )

    }

}

export default Research