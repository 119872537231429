/**
 * 
 * 2-受试者暂停 模态框
 * 
 */
import React, { Component } from 'react';
import { Button, Form, Input, Select, Radio, message } from 'antd';
import { createFromIconfontCN } from '@ant-design/icons';
import axios from 'axios';
import '../modalOperate.less';
import { stopTypeList, stopTypeList2, layout } from '../select/select';
import {
    subjectStopOk, subjectSuspendOk, subjectCancelOk, getStopSubjectEnume, getSuspendSubjectEnume, getCancelSubjectEnume,
} from '../../../../../../../services/data/DataService';

const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

const cancelStyle = { marginBottom: 0 }

class StopSubject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stopType: 0, // 默认暂停类型 停止受试
            stopSubjectList: [], // 停止受试原因列表
            suspendSubjectList: [], // 暂停受试原因列表
            cancelSubjectList: [], // 废弃受试原因列表
            subjectReasonTxt: '停止受试原因',
        }
    }
    formRef = React.createRef();

    /**
     * 停止受试
     */
    subjectStopOk = (params) => {
        subjectStopOk(params).then(res => {
            if (res && res.code == 0) {
                message.success('停止受试成功！');
                this.props.handleCancel(1);
            }
        })
    }

    /**
     * 临时暂停
     */
    subjectSuspendOk = (params) => {
        subjectSuspendOk(params).then(res => {
            if (res && res.code == 0) {
                message.success('临时暂停成功！');
                this.props.handleCancel(2);
            }
        })
    }

    /**
     * 永久废弃
     */
    subjectCancelOk = (params) => {
        subjectCancelOk(params).then(res => {
            if (res && res.code == 0) {
                message.success('永久废弃成功！');
                this.props.handleCancel(3);
            }
        })
    }

    /**
     * 停止受试原因枚举
     */
    getStopSubjectEnume = () => {
        getStopSubjectEnume().then(res => {
            if (res && res.code == 0) {
                this.setState({
                    stopSubjectList: res.data
                })
            }
        })
    }

    /**
     * 暂停受试原因枚举
     */
    getSuspendSubjectEnume = () => {
        getSuspendSubjectEnume().then(res => {
            if (res && res.code == 0) {
                this.setState({
                    suspendSubjectList: res.data
                })
            }
        })
    }

    /**
     * 废弃受试原因枚举
     */
    getCancelSubjectEnume = () => {
        getCancelSubjectEnume().then(res => {
            if (res && res.code == 0) {
                this.setState({
                    cancelSubjectList: res.data
                })
            }
        })
    }

    componentWillMount() {
        this.getStopSubjectEnume();
    }

    /**
     *  确认
     */
    onSubmit = (values) => {
        let { stopType } = this.state;
        let { subjectCode } = this.props;
        let params = {
            subjectCode: subjectCode,
            reasonType: values['reasonType'],
            remark: values['remark']
        }
        if (stopType == 0) {
            // 停止受试
            this.subjectStopOk(params);
        } else if (stopType == 1) {
            // 临时暂停
            this.subjectSuspendOk(params);
        } else {
            // 永久废弃
            this.subjectCancelOk(params);
        }
    }

    /**
     * 类型切换
     */
    onChangeType = (e) => {
        this.formRef.current.setFieldsValue({
            'reasonType': 1,
            'remark': ''
        })

        this.setState({
            stopType: e.target.value,
        })

        if (e.target.value == 0) {
            this.getStopSubjectEnume();
            this.setState({
                subjectReasonTxt: '停止受试原因',
            })

        } else if (e.target.value == 1) {
            this.getSuspendSubjectEnume();
            this.setState({
                subjectReasonTxt: '暂停受试原因'
            })
        } else if (e.target.value == 2) {
            this.getCancelSubjectEnume();
            this.setState({
                subjectReasonTxt: '废弃数据原因'
            })
        }
    }

    render() {
        const targetName = sessionStorage.getItem('targetName') || '受试者';
        let { stopType, stopSubjectList, suspendSubjectList, cancelSubjectList, subjectReasonTxt } = this.state;

        // 受试者暂停-（原因的Select.Option）
        let optionLists = [];
        if (stopType == 0) {
            optionLists = [...stopSubjectList]
        } else if (stopType == 1) {
            optionLists = [...suspendSubjectList]
        } else {
            optionLists = [...cancelSubjectList]
        }

        return (
            <div className={'operateContent'}>
                <Form
                    {...layout}
                    name="nest-messages"
                    onFinish={this.onSubmit}
                    requiredMark={true}
                    ref={this.formRef}
                    initialValues={{
                        'radioGroup-type': 0,
                        'reasonType': 1,
                        'remark': '',
                    }}
                >
                    {/* 类型 */}
                    <Form.Item
                        label={'暂停类型'}
                        name='radioGroup-type'
                        rules={[
                            {
                                required: true,
                                message: '必填项未填',
                            }
                        ]}
                    >
                        <Radio.Group
                            value={stopType}
                            onChange={this.onChangeType}
                            buttonStyle="solid"
                        >
                            {
                                (this.props.enableCancel == 0 ? stopTypeList : stopTypeList2).map(item => {
                                    return (
                                        <Radio.Button value={item.value} key={item.value}>{item.name}</Radio.Button>
                                    )
                                })
                            }


                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={subjectReasonTxt}
                        name='reasonType'
                        rules={[
                            {
                                required: true,
                                message: '必填项未填',
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            style={{ width: 180 }}
                            placeholder=""
                        >
                            {
                                optionLists.map((item, index) => {
                                    return (
                                        <Select.Option value={item.key} key={index}>{item.text}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item label="原因描述" name='remark' style={cancelStyle}>
                        <Input.TextArea placeholder="多行输入" />
                    </Form.Item>
                    <Form.Item wrapperCol={18}>
                        <p className={'txtStyle'}>
                            {stopType == 0 ? '停止后不可再次操作，请谨慎操作！' :
                                stopType == 2 ? `废弃后数据将从${targetName}列表消失，请谨慎操作！` : ''}
                        </p>
                    </Form.Item>

                    <Form.Item wrapperCol={24} className={'submit'}>
                        <Button
                            key="submit"
                            type="primary"
                            htmlType="submit"
                        >
                            确认
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default StopSubject;