import { Button, Empty, Form, Input, Modal, Select, message as messager } from "antd"
import React, { useEffect, useState } from "react"
import { CustomFormTemplatesResult, getCustomFormTemplates } from "../../../../../../services/data/visit";
import { addCustomForm } from "../../../../../../services/data/form";
import modStyl from './index.module.scss'

type Props = {
  visitId: number;
  onClose: ()=> void
}
const  CreateFormModal: React.FC<Props> = ({visitId, onClose})=> {

  const [loading, setLoading] = useState(false)
  const [templateId, setTemplateId] = useState(8)
  const [form] = Form.useForm()

  const [templates, setTemplates] = useState<{
    value: number;
    label: string;
  }[]>([])

  const handleFinish = async ({name}: {templateId: number, name: string})=> {
    setLoading(true)
    const {code, message} = await addCustomForm({
      visitId,
      name,
      templateId: templateId,
    })
    setLoading(false)
    if(code === 0) {
      onClose()
      messager.success('添加成功')
    }
  }

  const fetchTemplates = async ()=> {
    const { code, data } : {  code: number, data: CustomFormTemplatesResult[]} = await getCustomFormTemplates(visitId)
    if(code === 0) {
      if(data.length === 1) {
        setTemplateId(data[0].id)
      }
      setTemplates(data.map(({id, name})=> ({value: id, label: name})))
    }
  }

  useEffect(()=> {
    fetchTemplates()
  }, [])

  return (
    <Modal onCancel={onClose} title="新增表单" footer={null} destroyOnClose visible>
      {
        templates.length === 0 ? (
          <Empty description="暂无表单模板" />
        ) : (
          <Form onFinish={handleFinish} form={form} colon>
            {/* <Form.Item label="表单模板" name="templateId" initialValue={templateId} rules={[{required: true, message: '请选择表单模板'}]}>
              <Select onSelect={(value)=> setTemplateId(Number(value))} options={templates}></Select>
            </Form.Item> */}
            <Form.Item label="表单名称" name="name" rules={[{required: true, message: '请输入表单名称'}]}>
              <Input placeholder="请输入表单名称"/>
            </Form.Item>
            <div className={modStyl.footer}>
              <Button onClick={onClose}>取消</Button>
              <Button loading={loading} htmlType="submit" type="primary">确认</Button>
            </div>
          </Form>
        )
      }
    </Modal>
  )
}

export default CreateFormModal
