export const formatSeconds = (seconds: number)=> {
  const values = [3600, 60, 0]
  const pieces = []
  for(const value of values) {
    if(seconds < 60) {
      pieces.push(seconds < 10 ? `0${seconds}` : seconds)
      break;
    }
    if(seconds >= value) {
      const n = Math.floor(seconds / value)
      pieces.push(n < 10 ? `0${n}` : n)
      seconds = seconds % value
    }
  }
  if(pieces.length == 1) {
    pieces.unshift('00')
  }
  return pieces.join(':')
}

export function formatDateTime(value: string | null) {
  if(value) {
    const now = new Date(value)
    return new Intl.DateTimeFormat('nu', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: 'Asia/Shanghai',
    }).format(now)
  }
  return '--'
}

export function maskPhoneNumber(value: string) {
  if(value) {
    return value.replace(/^(1\d{2})(\d{4})(\d{4})$/g, '$1****$3')
  }
  return '--'
}