import React, { forwardRef, useEffect, useRef, useState } from 'react';
import './formField.less';
import { Toast, Badge } from 'antd-mobile';
// import { ImageViewer } from 'antd-mobile-v5';
import moment from 'moment';
import { PhotoSlider } from 'react-photo-view';
import 'react-photo-view/dist/index.css';
import verify from '../../../../../utils/verify';
import ImagePicker from '../image-picker/index';
import Video from '../video/index';
import tool from '../../../../../utils/tool';



let photoId = 0;



function FormField(props: any, ref: any) {

    //当前表单的id
    const [formId, setFormId] = useState<any>();
    //设置表单域
    const [fieldList, setFieldList] = useState<any>([]);

    //当前表单的值
    const [currentValue, setCurrentValue] = useState<any>();

    //当前表单已存的value
    const [savedValue, setSavedValue] = useState<any>([]);

    //整个表单的formAction
    const [formActions, setFormActions] = useState<any>([]);

    //表单是否可读状态
    const [formIsRead, setFormIsRead] = useState(true);

    //图片
    const [photos, setPhotos] = useState<any>([]);

    //图片预览是否显示
    const [photoVisible, setPhotoVisible] = useState(false);
    //当前预览图片的index
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState<any>(0);

    // 设置当前显示的图片示例
    const [currPhotoRemark, setCurrPhotoRemark] = useState<string>('');
    // 设置图片是否可以脱敏
    const [infoPhotos, setInfoPhotos] = useState<any>([]);

    const mPhotoRef = useRef();
    const mVideoRef = useRef();



    useEffect(() => {
        if (props.formId) {
            setFormId(props.formId);
        }
        //初始化数据
        initData(props.fieldList);
    }, [props])



    //初始数据处理
    const initData = (data: any) => {
        // return;
        if (data) {
            tool.dealFieldList(data);
            tool.dealFieldListByValues(data);
            let fieldList = data.body;
            let savedValue = data.values;
            let dataAction = data.dataActions;
            let formAction = data.formActions;


            //判断表单是否处于可读状态
            if (formAction && formAction.length > 0) {
                if (formAction.includes('FormActionRead')) {
                    setFormIsRead(false);
                }
            }

            if (dataAction && dataAction.length == 0) {
                setFormIsRead(false);
            }

            setFieldList(fieldList);
            setSavedValue(savedValue);
            setFormActions(formAction);

            logicOperation(fieldList, savedValue);
        }
    }

    const logicOperation = (fieldList: any, savedValue: any) => {
        if (!fieldList || fieldList.length == 0 || !savedValue || savedValue.length == 0) {
            return;
        }
        // for (let field of fieldList) {
        //     if (field.logicOperations && field.logicOperations.length > 0) {
        //         for (let logicOperation of field.logicOperations) {
        //             mExper.handleLogicOperations(logicOperation, savedValue, field);
        //         }
        //     }
        //     if (field["children"]) {
        //         logicOperation(field["children"], savedValue);
        //     }
        // }
    }



    //验证是否为空
    const validationIsNull = (item: any) => {
        //检查是否必须选项
        if (item.validationRules && item.validationRules.length > 0 && props.readonly != true) {
            let isNotNull = false;
            for (let rule of item.validationRules) {
                if (rule.condition === 'ne' && rule.rule === 'null') {
                    return (
                        <span className="necessary">*</span>
                    )
                }
                else {
                    return null
                }
            }
        }
    }

    //动态显示已经保存的值
    const showSavedValue = (item: any) => {
        let currentItem = savedValue.find((i: any) => {
            return i.dataCode === item.dataCode
        });
        let fieldListItem = fieldList.find((f: any) => {
            return f.dataCode === item.dataCode
        });


        if (currentItem) {
            if (item.type === 'select') {
                if (currentItem.value) {
                    if (item.optional[currentItem.value]) {
                        return item.optional[currentItem.value]
                    }
                    else if (item.other) {
                        if (item.other.type === 'input') {
                            return currentItem.value;
                        }
                        else if (item.other.type === 'date') {
                            return moment(currentItem.value).format('YYYY-MM-DD')
                        }
                    }
                    else {
                        return null;
                    }
                }
            }
            else if (item.type === 'date') {
                if (currentItem.value) {
                    return moment(currentItem.value).format('YYYY-MM-DD')
                }
            }
            else if (item.type === 'checkbox') {

                if (currentItem.value) {
                    let filterArr = [];
                    for (let i of currentItem.value) {
                        if (i) {
                            if (item.other) {
                                if (i !== item.other.key) {
                                    if (item.optional[i]) {
                                        filterArr.push(item.optional[i]);
                                    }
                                }
                            }
                            else {
                                if (item.optional[i]) {
                                    filterArr.push(item.optional[i]);
                                }
                            }
                        }
                    }
                    if (item.other) {
                        if (currentItem.value.indexOf(item.other.key) !== -1) {
                            filterArr.push(currentItem.value[currentItem.value.length - 1])
                        }
                    }
                    return filterArr.join(',')
                }
            }
            else if (item.type === 'photo') {
                if (currentItem.value && currentItem.value.length > 0) {
                    return props.readonly ? '' : '图片已上传，点击查看'
                }
                else {
                    return ''
                }
            }
            else if (item.type === 'video') {
                if (currentItem.value && currentItem.value.length > 0) {
                    return props.readonly ? '' : '视频已上传，点击查看'
                }
                else {
                    return ''
                }
            }
            else if (item.type === 'file') {
                if (currentItem.value && currentItem.value.length > 0) {
                    return props.readonly ? '文件已上传' : '文件已上传，点击查看'
                }
                else {
                    return ''
                }
            }
            else if (item.type === 'input-select') {
                if (currentItem.value) {
                    let value;
                    if (item.other) {
                        // 范围
                        if (item.inputType && item.inputType == 'inputrange') {
                            if (item.optional[currentItem.value[2]]) {
                                value = `${currentItem.value[0]} ~ ${currentItem.value[1]} ${item.optional[currentItem.value[2]]}`;
                            }
                            else {
                                if (item.other.type === 'input') {
                                    value = `${currentItem.value[0]} ~ ${currentItem.value[1]} ${currentItem.value[2]}`;
                                }
                            }
                            return value
                        }
                        else {
                            if (item.optional[currentItem.value[1]]) {
                                value = `${currentItem.value[0]}${item.optional[currentItem.value[1]]}`;
                            }
                            else {
                                if (item.other.type === 'input') {
                                    value = `${currentItem.value[0]}${currentItem.value[1]}`;
                                }
                                else if (item.other.type === 'date') {
                                    value = `${currentItem.value[0]}${moment(currentItem.value[1]).format('YYYY-MM-DD')}`;
                                }
                            }
                            return value
                        }
                    } else {
                        value = `${currentItem.value[0]}${item.optional[currentItem.value[1]]}`;
                        return value
                    }
                }
            }
            else {
                if (currentItem.value) {
                    return item.unit ? `${currentItem.value}${item.unit}` : currentItem.value
                }
            }
        }
        else {
            if (item.type === 'select') {
                if (item.defaultValue) {
                    return item.optional[item.defaultValue]
                }
            }
            else {
                return null;
            }
        }
    }

    /**
     * 递归查找下一题的配置信息
     * @param config   配置信息
     * @param dataCode 当前字段的数据编码
     * @param isNextNode 是否下一个目标节点
     * @returns nextFieldConfig 下一个字段胡配置信息
     */
    const deepNextField: any = (config: any, dataCode: string, isNextNode: boolean) => {
        let result = {
            item: undefined,
            isNextNode: isNextNode
        };
        if (!config || config.length == 0) {
            return result;
        }
        for (let item of config) {
            // 显隐逻辑处理
            if (item.display) {
                let display = false;

                if (item.display.type === 'ValueCascade') {
                    if (item.display.condition === 'eq') {
                        //当前选中表单已存的value
                        let currentItem = savedValue.find((i: any) => {
                            return i.dataCode == item.display.target
                        });
                        if (currentItem) {
                            if (!((currentItem.value && currentItem.value.indexOf(item.display.rule) !== -1) || (currentItem.value && currentItem.value === item.display.rule))) {
                                display = true;
                            }
                        }
                    }
                    else if (item.display.condition === 'oreq') {
                        let currentItem = savedValue.find((i: any) => {
                            return i.dataCode == item.display.target
                        });
                        if (currentItem && currentItem.value) {
                            if (currentItem.value instanceof Array) {
                                currentItem.value.length > 0 && currentItem.value.map((i: any) => {
                                    if (item.display.rule.includes(i)) {
                                        display = true;
                                    }
                                })
                            } else {
                                if (item.display.rule.includes(currentItem.value)) {
                                    display = true;
                                }
                            }
                        }
                    }
                    else if (item.display.condition === 'andEq') {
                        let list = savedValue.filter((i: any) => {
                            return item.display.target.includes(i.dataCode)
                        });

                        if (list && list.length > 0) {
                            for (let i = 0; i < list.length - 1; i++) {
                                if (list[i].value instanceof Array) {
                                    if (
                                        (list[i].dataCode == item.display.target[i] && list[i].value.includes(item.display.rule[i])) &&
                                        (list[i + 1].dataCode == item.display.target[i + 1] && list[i + 1].value.includes(item.display.rule[i + 1]))
                                    ) {
                                        display = true;
                                    }
                                } else {
                                    if (
                                        (list[i].dataCode == item.display.target[i] && list[i].value == item.display.rule[i]) &&
                                        (list[i + 1].dataCode == item.display.target[i + 1] && list[i + 1].value == item.display.rule[i + 1])
                                    ) {
                                        display = true;
                                    }
                                }
                            }
                        }
                    }
                }
                else if (currentValue instanceof Array) {
                    if (item.display.target instanceof Array) {
                        let i = item.display.target.indexOf(dataCode);
                        if (item.display.target[i] == dataCode) {
                            if (item.display.rule[i] instanceof Array) {
                                currentValue.map(rItem => {
                                    if (!item.display.rule[i].includes(rItem)) {
                                        display = true;
                                    }
                                })
                            } else {
                                if (!currentValue.includes(item.display.rule[i])) {
                                    display = true;
                                }
                            }
                        }
                    } else {
                        if (!currentValue.includes(item.display.rule) && item.display.target == dataCode) {
                            display = true;
                        }
                    }

                } else {
                    if (item.display.target instanceof Array) {
                        let i = item.display.target.indexOf(dataCode);
                        if (item.display.target[i] == dataCode && item.display.rule[i] != currentValue) {
                            display = true;
                        }
                    } else {
                        if (item.display.rule != currentValue && item.display.target == dataCode) {
                            display = true;
                        }
                    }
                }
            

                if (display) {
                    continue;
                }
            }
            // 递归子节点
            if (item.children) {
                let nextConfig = deepNextField(item.children, dataCode, result.isNextNode);
                if (nextConfig.item) {
                    return nextConfig;
                } else {
                    result.isNextNode = nextConfig.isNextNode;
                    continue;
                }
            }
            // 如果当前节点是下一个节点，直接返回配置
            if (item.dataCode && result.isNextNode) {
                result.item = item;
                return result;
            }
            // 处理当前节点
            if (item.dataCode == dataCode) {
                result.isNextNode = true;
                continue;
            }

        }
        return result;
    }

    // 只读查看大图方法
    const onReadOnlyPreview = (index: number, item: any): Promise<string> => {
        return new Promise((resolve, reject) => {
            setCurrentPhotoIndex(index)
            // 设置显示预览
            setPhotos(item);
            setPhotoVisible(true);
        });
    };

    //当点击了添加list按钮
    const onClickAddList = (item: any) => {
        return () => {
            setFieldList(tool.addList(JSON.parse(JSON.stringify(fieldList)), item.id));
        }
    }

    //判断是否加载更多
    const isAddMore = (item: any) => {
        if (formActions && formActions.length > 0) {
            if (formActions.indexOf('FormActionSubmit') !== -1) {
                return (
                    <div className="addNextList" onClick={onClickAddList(item)}>
                        点击加载更多
                    </div>
                )
            }
        }
    }


    const displayPhoto = (item: any) => {
        const mPhoto = savedValue.filter((val: any) => {
            if (item.dataCode === val.dataCode) {
                return true;
            }
            return false;
        }).map((item: any) => {
            return item.value;
        });
        let diaplayPhotos = (mPhoto[0] || []).map((itemVal: any) => {
            return {
                url: itemVal
            }
        })
        let suffixPhotos = changedPhoto(diaplayPhotos);
        return (suffixPhotos && suffixPhotos.length > 0) ?
            <ImagePicker
                ref={mPhotoRef}
                filesList={suffixPhotos}
                multiple={false}
                max={8}
                mode="cover"
                width="22%"
                onGetPreviewUrl={(index) => { onReadOnlyPreview(index, suffixPhotos) }}
                config={['defaultDashed']}
                disabled={true}
            />
            :
            null
    }

    const displayVideo = (item: any) => {
        const mVideos = savedValue.filter((val: any) => {
            if (item.dataCode === val.dataCode) {
                return true;
            }
            return false;
        }).map((item: any) => {
            return item.value;
        });
        let diaplayVideos = mVideos.map((itemVal: any) => {
            return {
                url: itemVal
            }
        })
        let suffixVideos = changedPhoto(diaplayVideos);
        return (suffixVideos && suffixVideos.length > 0) ?
            <Video
                ref={mVideoRef}
                filesList={suffixVideos}
                multiple={false}
                max={1}
                mode="cover"
                width="22%"
                config={['defaultDashed']}
                disabled={true}
            />
            :
            null
    }

    //选择表单
    const switchField = (item: any, index?: any) => {
        if (item.display) {
            // ValueCascade
            if (item.display.type === 'ValueCascade') {
                // eq
                if (item.display.condition === 'eq') {
                    //当前选中表单已存的value
                    let currentItem = savedValue.find((i: any) => {
                        return i.dataCode == item.display.target
                    });
                    // console.log('switchField-item:',item,',switchField-currentItem:',currentItem)
                    if (currentItem) {
                        if ((currentItem.value && currentItem.value.indexOf(item.display.rule) !== -1) || (currentItem.value && currentItem.value === item.display.rule)) {
                            return chooseForm(item, index)
                        }
                    }
                }
                else if (item.display.condition === 'andEq') {
                    let judgeArr = [];
                    for (let i = 0; i < item.display.target.length; i++) {
                        let findedItem = savedValue.find((valueItem: any) => {
                            return valueItem.dataCode === item.display.target[i]
                        });
                        if (findedItem) {
                            if (findedItem.value instanceof Array) {
                                if (findedItem.value.indexOf(item.display.rule[i]) === -1) {
                                    judgeArr.push(false);
                                }
                                else {
                                    judgeArr.push(true);
                                }
                            }
                            else {
                                if (findedItem.value !== item.display.rule[i]) {
                                    judgeArr.push(false);
                                }
                                else {
                                    judgeArr.push(true);
                                }
                            }
                        }
                        else {
                            judgeArr.push(false)
                        }
                    }
                    if (judgeArr.every(item => {
                        if (item === true) {
                            return true
                        }
                    })) {
                        return chooseForm(item, index)
                    }
                }
                else if (item.display.condition === 'orEq') {
                    let judgeArr = [];
                    for (let i = 0; i < item.display.target.length; i++) {
                        let findedItem = savedValue.find((valueItem: any) => {
                            return valueItem.dataCode === item.display.target[i]
                        });
                        if (findedItem) {
                            if (findedItem.value instanceof Array) {
                                if (findedItem.value.indexOf(item.display.rule[i]) === -1) {
                                    judgeArr.push(false);
                                }
                                else {
                                    judgeArr.push(true);
                                }
                            }
                            else {
                                if (findedItem.value !== item.display.rule[i]) {
                                    judgeArr.push(false);
                                }
                                else {
                                    judgeArr.push(true);
                                }
                            }
                        }
                        else {
                            judgeArr.push(false)
                        }
                    }
                    if (judgeArr.indexOf(true) !== -1) {
                        return chooseForm(item, index)
                    }
                }
            }
        }
        else {
            return chooseForm(item, index)
        }
    }

    // 异常提醒
    const formAnnotation = (item: any) => {
        let res: any = null;
        let currentItem = savedValue.find((i: any) => {
            return i.dataCode === item.dataCode
        });
        if (currentItem && currentItem.value) {
            res = currentItem.value
        }
        return res
    }

    //根据表单类型选择相应表单
    const chooseForm = (item: any, index?: any) => {
        let r = verify.checkValueAnnotation(formAnnotation(item), item);
        const type = item.type;

        switch (type) {
            //选择
            case 'select':
                return (
                    <>
                        <div className="field_content" >
                            <div className="field_label">
                                {validationIsNull(item)}
                                {item.label}
                            </div>
                            <>{renderAnswer(item)}</>

                            <div className="field_right">
                                <span className="defaultValue">
                                    {showSavedValue(item)}
                                </span>
                            </div>

                        </div>
                    </>
                )
            //只读文本
            case 'text':
                return (
                    <>
                        <div className="field_content">
                            <div className="field_label text_field_label">
                                {validationIsNull(item)}
                                {item.label}
                            </div>
                            <>{renderAnswer(item)}</>
                         
                        </div>
                    </>
                )
            // 拍照备注
            case 'photo-remark':
                return (
                    formIsRead ? (
                        <>
                            <div className="field_content" >
                                <div className="field_label field_label_remark">
                                    {validationIsNull(item)}
                                    {item.label}
                                </div>
                                <>{renderAnswer(item)}</>
                           
                            </div>
                            {/* <ImageViewer
                                image={item["link"]}
                                visible={item["link"] === currPhotoRemark}
                                onClose={() => {
                                    setCurrPhotoRemark("")
                                }}
                            /> */}
                        </>
                    ) : <></>
                )
            //输入框
            case 'input':
                return (
                    <>
                        <div className="field_content">
                            {
                                r && r.status &&
                                <span className="field_annotation" onClick={(e: any) => onRangeAnnotation(e, r.message)}>!</span>
                            }
                            <div className="field_label">
                                {validationIsNull(item)}
                                <span style={{ color: r && r.status ? 'red' : '#2E2F33' }}>{item.label}</span>
                            </div>
                            <>{renderAnswer(item)}</>

                            <div className="field_right">
                                <div className="defaultValue">
                                    <span style={{ color: r && r.status ? 'red' : '#2E2F33' }}>{showSavedValue(item)}</span>
                                </div>

                            </div>

                        </div>
                    </>
                )
            //输入框
            case 'number':
                return (
                    <>
                        <div className="field_content">
                            {
                                r && r.status &&
                                <span className="field_annotation" onClick={(e: any) => onRangeAnnotation(e, r.message)}>!</span>
                            }
                            <div className="field_label">
                                {validationIsNull(item)}
                                <span style={{ color: r && r.status ? 'red' : '#2E2F33' }}>{item.label}</span>
                            </div>
                            <>{renderAnswer(item)}</>
                            <div className="field_right">
                                <div className="defaultValue">
                                    <span style={{ color: r && r.status ? 'red' : '#2E2F33' }}>{showSavedValue(item)}</span>
                                </div>

                            </div>
                        </div>
                    </>
                )
            //时间表单
            case 'date':
                return (
                    <>
                        <div className="field_content">
                            {
                                r && r.status &&
                                <span className="field_annotation" onClick={(e: any) => onRangeAnnotation(e, r.message)}>!</span>
                            }
                            <div className="field_label">
                                {validationIsNull(item)}
                                <span style={{ color: r && r.status ? 'red' : '#2E2F33' }}>{item.label}</span>
                            </div>
                            <>{renderAnswer(item)}</>
                            <div className="field_right">
                                <div className="defaultValue">
                                    <span style={{ color: r && r.status ? 'red' : '#2E2F33' }}>{showSavedValue(item)}</span>
                                </div>

                            </div>
                        </div>
                    </>
                )
            //文本域
            case 'textarea':
                return (
                    <>
                        <div className="field_content">
                            <div className="field_label">
                                {validationIsNull(item)}
                                {item.label}
                            </div>
                            <>{renderAnswer(item)}</>
                            <div className="field_right">
                                <div className="defaultValue">
                                    {showSavedValue(item)}
                                </div>

                            </div>
                        </div>
                    </>
                )
            //photo
            case 'photo':
                let renderPhoto = null;
                if (props.readonly) {
                    renderPhoto = displayPhoto(item);
                }
                return (
                    <>
                        <div className={`field_content ${renderPhoto ? 'content_has_photo' : ''}`} >
                            <div className="field_label">
                                {validationIsNull(item)}
                                {item.label}
                            </div>
                            <>{renderAnswer(item)}</>
                            <div className="field_right">
                                <div className="defaultValue">
                                    {showSavedValue(item)}
                                </div>

                            </div>
                        </div>
                        {
                            props.readonly && renderPhoto &&
                            <div style={{ marginTop: '5px', borderBottom: '1px solid #D8D8D8' }}>
                                {renderPhoto}
                            </div>
                        }
                    </>
                )
            // video
            case 'video':
                let renderVideo = null;
                if (props.readonly) {
                    renderVideo = displayVideo(item);
                }
                return (
                    <>
                        <div className={`field_content ${renderVideo ? 'content_has_video' : ''}`} >
                            <div className="field_label">
                                {validationIsNull(item)}
                                {item.label}
                            </div>
                            <>{renderAnswer(item)}</>
                            <div className="field_right">
                                <div className="defaultValue">
                                    {showSavedValue(item)}
                                </div>

                            </div>
                        </div>
                        {
                            props.readonly && renderVideo &&
                            <div style={{ marginTop: '5px' }}>
                                {renderVideo}
                            </div>
                        }
                    </>
                )
            // file
            case 'file':
                return (
                    <>
                        <div className="field_content" >
                            <div className="field_label">
                                {validationIsNull(item)}
                                {item.label}
                            </div>
                            <>{renderAnswer(item)}</>
                            <div className="field_right">
                                <div className="defaultValue">
                                    {showSavedValue(item)}
                                </div>

                            </div>
                        </div>
                    </>
                )
            //checkbox
            case 'checkbox':
                return (
                    <>
                        <div className="field_content" >
                            <div className="field_label">
                                {validationIsNull(item)}
                                {item.label}
                            </div>
                            <>{renderAnswer(item)}</>
                            <div className="field_right">
                                <div className="defaultValue">
                                    {showSavedValue(item)}
                                </div>

                            </div>
                        </div>
                    </>
                )
            //input-select
            case 'input-select':
                return (
                    <>
                        <div className="field_content" >
                            <div className="field_label">
                                {validationIsNull(item)}
                                {item.label}
                            </div>
                            <>{renderAnswer(item)}</>
                            <div className="field_right">
                                <div className="defaultValue">
                                    {showSavedValue(item)}
                                </div>

                            </div>
                        </div>
                    </>
                )
            //decimal
            case 'decimal':
                return (
                    <>
                        <div className="field_content" >
                            {
                                r && r.status &&
                                <span className="field_annotation" onClick={(e: any) => onRangeAnnotation(e, r.message)}>!</span>
                            }
                            <div className="field_label">
                                {validationIsNull(item)}
                                <span style={{ color: r && r.status ? 'red' : '#2E2F33' }}>{item.label}</span>
                            </div>
                            <>{renderAnswer(item)}</>
                            <div className="field_right">
                                <div className="defaultValue">
                                    <span style={{ color: r && r.status ? 'red' : '#2E2F33' }}>{showSavedValue(item)}</span>
                                </div>

                            </div>
                        </div>
                    </>
                )
            //多个表单组
            case 'group':
                return (
                    <React.Fragment>
                        {item.label ? <div className="title">
                            &ensp;{item.label}
                        </div> : null}
                        {
                            item.children.map((field: any, i: any) => {
                                let renderDom = switchField(field, i);
                                return (
                                    <>
                                        {
                                            renderDom ?
                                                <div className="field" key={i}>
                                                    {renderDom}
                                                </div>
                                                :
                                                null
                                        }
                                    </>

                                )
                            })
                        }
                    </React.Fragment>
                )
            //list类型
            case 'list':
                return (
                    <React.Fragment>
                        {item.label ? <div className="title">
                            &ensp;{item.label}
                        </div> : null}
                        <div className={`list#${index}`}>
                            {
                                item.children.map((field: any, i: any) => {
                                    return (
                                        <div style={{ width: '100%' }} key={i}>
                                            {
                                                item.children.length >= 1 ? <Badge style={{ backgroundColor: '#6287EC', color: '#ffffff' }} text={i + 1} overflowCount={item.maxNumber} /> : null
                                            }
                                            {switchField(field)}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            isAddMore(item)
                        }
                    </React.Fragment>
                )
            default:
                break;
        }
    }


    // 范围异常提醒框
    const onRangeAnnotation = (e: any, message: any) => {
        e.stopPropagation();
        Toast.info(message, 2);
    }

    // 渲染质疑
    const renderAnswer = (item: any) => {
        let currentItem = savedValue.find((i: any) => {
            return i.dataCode === item.dataCode
        });

        if (currentItem && currentItem.question) {
            let dom;
            // currentItem.questions && currentItem.questions.length > 0 && currentItem.questions.map((qItem: any, qIndex: any) => {
            //     if (qItem.state == 'Answer') {
            //         dom = <img src={IconAnswerReply} alt='' className='IconAnswer' />
            //     } else {
            //         dom = <img src={IconAnswer} alt='' className='IconAnswer' />
            //     }
            // })

            return dom
        }
        else {
            return <></>
        }
    }

    // 图片有缓存，需要加后缀更新
    const changedPhoto = (photos: any) => {
        photoId++;
        const urlChangePhotos = photos.map((val: Object) => {
            let _url = '';
            // @ts-ignore
            if (val.url.indexOf('?') > -1) {
                // @ts-ignore
                _url = val.url + photoId
            } else {
                // @ts-ignore
                _url = val.url + '?id=' + photoId
            }
            return {
                url: _url
            };
        });
        return urlChangePhotos;
    }


    return (
        <div className='mobile_filed'>
            {
                fieldList && fieldList.length > 0 && fieldList.map((item: any, index: any) => {
                    return (
                        <div className="formItem" key={index}>
                            {switchField(item)}
                        </div>
                    )
                })
            }

            {
                props.readonly &&
                <PhotoSlider
                    photoClosable
                    bannerVisible={true}
                    images={photos && photos.map((item: any) => ({ src: item.url }))}
                    visible={photoVisible}
                    onClose={() => { setPhotoVisible(false); setTimeout(() => { document.body.style.overflow = 'auto'; }, 500); }}
                    index={currentPhotoIndex}
                    introVisible={true}
                    onIndexChange={(i: any) => { setCurrentPhotoIndex(i) }}
                />
            }

        </div>
    )
}

export default forwardRef(FormField);