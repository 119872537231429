/**
 * dct数据采集/所有项目
 */
import React, { Component } from 'react';
import { message, Row, Col } from 'antd';
import DctDataComponent from "./dataStatistics";
import DctMonitorComponent from "../data/monitor";
import DctTasksComponent from "../data/tasks";
import DctStatisticsComponent from "./assistantStatistics";
import {GetDctProjectUser} from '../../../services/dct/DctService'
import './index.less';

let _this;

class DctComponent extends Component {

    constructor(props) {
        super(props);
        _this = this;
        this.state = {
            projectData: [],
            dataAutoRefresh: false, // 刷新数据总览
            isAuto: true
        };
        this.inputEl = React.createRef();
    }

    componentDidMount() {
        this.getDctProject()
    }

    // 获取项目
    getDctProject = () => {
        GetDctProjectUser().then(res => {
            if (res.code == 0) {
                if (!res.data) {
                    return;
                }

                this.setState({
                    projectData: res.data
                })
                // sessionStorage.setItem('projectCodes',res.data)
                // sessionStorage.removeItem('projectCodes')
            }
        })
    }

    // 监听自动刷新 
    onRefresh = (v) => {
        if (v) {
            this.setState({
                dataAutoRefresh: true,
                isAuto: false
            })
        }
    }

    render() {

        let { dataAutoRefresh, isAuto } = this.state
        return (
            <div className={'dct_content'}>
                <Row gutter={16}>
                    <Col className="gutter-row" span={16}>
                        {isAuto && <DctDataComponent />}
                        {dataAutoRefresh && <DctDataComponent />}
                        
                        <DctMonitorComponent onRefresh={(v)=>this.onRefresh(v)}/>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <DctTasksComponent/>
                        <DctStatisticsComponent/>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default DctComponent