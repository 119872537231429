import { DatePicker, Form, Input, message, Modal, Select } from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";
import React, { useEffect, useState } from "react";
import { approveProjectEnume, createBatchDownloadTask } from "../../../services/downloadApprove/DownloadApproveService";

type Props = {
  onClose(refresh?: boolean): void
}

const CreateBatchModal: React.FC<Props> = ({ onClose })=> {

  const [projects, setProjects] = useState<any[]>([])

  const [form] = Form.useForm();

  // 选择项目时加载数据
  const fetchProjectList = async ()=> {
    const data = await approveProjectEnume({})
    setProjects(data.map(({projectCode, projectName}: any)=> ({label: projectName, value: projectCode})))
  }

  // 选择用户时加载数据
  const onSubmit = async ()=> {
    try {
      const data = await form.validateFields()

      const keys = ['startDate', 'endDate']
      keys.forEach((key)=> {
        if (data[key]) {
          data[key] = data[key].format('YYYY-MM-DD')
        }
      })

      const { code } = await createBatchDownloadTask(data);
      if (code == 0) {
          message.success('创建成功！');
          onClose(true)
      }
    } catch (error) {
      
    }
  }

  useEffect(()=> {
    fetchProjectList()
    sessionStorage.setItem('dataSourceDownload', 'true');
    return ()=> {
      sessionStorage.removeItem('dataSourceDownload');
    }
  }, [])

  return (
    <Modal
      title="创建数据批量下载任务"
      open={true}
      onOk={onSubmit}
      okText='确认创建'
      cancelText='关闭'
      maskClosable={false}
      destroyOnClose
      onCancel={()=> onClose(false)}
      width={680}>
      <Form
        form={form}
        labelCol={{span: 8}}
        wrapperCol={{span: 12}}
        name="nest-messages"
        onFinish={onSubmit}
        requiredMark={true}
        autoComplete="off">
        <Form.Item
          label="请选择项目"
          name='projectCodes'
          rules={[
            {required: true, message: '请选择项目' },
            {type: 'array', max: 10, message: '最多选择10个项目'}
          ]}
        >
          <Select
            placeholder='请选择项目'
            options={projects}
            mode="multiple"
            filterOption={(input, option) => option.label.includes(input)}
            allowClear/>
        </Form.Item>
        <Form.Item label="超级密码" name="pwd" rules={[{ required: true, message: '请输入超级密码!' }]}>
          <Input type="password" placeholder="请输入超级密码" allowClear />
        </Form.Item>
        <Form.Item label="审核记录起始时间" name="startDate" rules={[{ required: false, message: '请输入审核记录起始时间!' }]}>
          <DatePicker locale={locale} style={{ width: '100%' }}/>
        </Form.Item>
        <Form.Item label="审核记录截至时间" name="endDate" rules={[{ required: false, message: '请输入审核记录截至时间!' }]}>
          <DatePicker locale={locale} style={{ width: '100%' }}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateBatchModal