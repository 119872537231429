export default {
    //表单暂存机制
    saveData(data: any, removeCodes: string[] = []) {
        // debugger
        let localStore_formData = sessionStorage.getItem('localStore_formData');
        if (localStore_formData) {
            //对各表单缓存集合
            let formDataArr = JSON.parse(localStore_formData);
            //当前操作表单的缓存
            let formData = formDataArr.find((item: any) => {
                return item.formId === data.formId
            });
            if (formData) {
                const oldMap: {[key: string]: any} = {}
                // 原数据建议唯一映射关系
                formData.values.forEach((item: any)=> {
                    if(!removeCodes.includes(item.dataCode)) {
                        // 捕获item.deCode 缺失异常
                        if(!item.deCode) {
                            item.deCode = item.dataCode.split('#').shift();
                        }
                        oldMap[`${item.dataCode}`] = item;

                    }
                })
                // 将新参的值合并到原数据中
                data.values.forEach((item: any)=> {
                    if(!item.deCode) {
                        item.deCode = item.dataCode.split('#').shift();
                    }
                    const key = `${item.dataCode}`;
                    
                    if(typeof oldMap[key] === 'object') {
                        oldMap[key]['value'] = item.value;
                        if(typeof item.extendAttribute !== 'undefined') {
                            oldMap[key]['extendAttribute'] = item.extendAttribute
                        }
                        // 带有hide属性补充上，方便后端计算
                        if(typeof item.type !== 'undefined') {
                            oldMap[key]['type'] = item.type
                        }
                    } else {
                        oldMap[key] = item
                    }
                })
                formData.values = Object.values(oldMap)
                formData.init = false;
            }
            else {
                data.init = false;
                formDataArr.push(data);
            }
           
            sessionStorage.setItem('localStore_formData', JSON.stringify(formDataArr));
        }
        else {
            data.init = false;
            let formDataArr: any[] = [];
            formDataArr.push(data);
            sessionStorage.setItem('localStore_formData', JSON.stringify(formDataArr));
        }
    },
    //表单value删除某一项值
    deleteData(data: any) {
        // debugger
        let localStore_formData = sessionStorage.getItem('localStore_formData');
        if (localStore_formData) {
            let formDataArr = JSON.parse(localStore_formData);
            //当前操作表单的缓存
            let formData = formDataArr.find((item: any) => {
                return item.formId === data.formId
            });
            if (formData) {
                // console.log(formData.values);
                // console.log(data.targets);
                data.targets.forEach((v: any) => {
                    formData.values.find((item: any, index: any) => {
                        if (item && item.dataCode == v) {
                            formData.values.splice(index, 1)
                        }
                    });
                })

                formData.init = false;
            }
            sessionStorage.setItem('localStore_formData', JSON.stringify(formDataArr));
        }
    }
}