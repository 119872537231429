import React from "react";
import {Button, Steps, Form, Select, Typography, message, Input} from 'antd';
import {
    LoadingOutlined, UnorderedListOutlined,
    CloudSyncOutlined, CloudDownloadOutlined, SyncOutlined, CheckCircleOutlined, DownloadOutlined
} from '@ant-design/icons';
import {withRouter} from "react-router-dom";
import './down.less'
import {DownProjectReport, GetProject, GetUrlProjectReport} from "../../../services/data/DataService";

const { Title } = Typography;
const { Option } = Select;
const { Step } = Steps;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};

/**
 * 项目报表下载
 */
class ProjectReportDownComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            projects: [],
            downUrl: undefined,
            selectProject: undefined,
            pwd: undefined
        }
    }

    componentDidMount() {
        this.loadProject();
    }

    /**
     * 加载项目列表
     */
    loadProject = () => {
        GetProject().then(res => {
            this.setState({
                projects: res.data
            });
        })
    }

    handleSelect = (value) => {
        const {selectProject} = this.state;
        if (selectProject === value) {
            return;
        }
        this.setState({
            selectProject: value,
        });
    }

    handlePassword = (e) => {
        const {pwd} = this.state;
        if (pwd === e.target.value) {
            return;
        }
        this.setState({
            pwd: e.target.value
        })
    }

    checkGenerateStatus = (url) => {
        this.next();
        this.setState({
            downUrl: url
        })
    }

    startDown = () => {
        this.next();
        const {
            selectProject, pwd
        } = this.state;
        if (selectProject && pwd && pwd.trim().length > 0) {
            DownProjectReport(selectProject, pwd).then(res => {
                if (res && res.code === 0) {
                    this.checkGenerateStatus(res.data);
                } else if (res.message){
                    this.last();
                } else {
                    this.last();
                    message.error("系统错误，请联系开发人员！")
                }
            })
        } else if (selectProject) {
            message.warn("请选输入下载密码！")
        } else {
            message.warn("请选择要下载的项目！")
        }
    }

    next = () => {
        let current = this.state.current;
        current = current < 3 ? current + 1 : 1;
        this.setState({
            current: current,
        });
    }

    last = () => {
        let current = this.state.current;
        current = current > 1 ? current - 1 : 1;
        this.setState({
            current: current,
        });
    }

    getStepState = (step) => {
        const {current} = this.state;
        if (current < step) {
            return "wait";
        } else if (current === step) {
            return "process";
        } else {
            return "finish";
        }
    }

    selectProjectUI = () => {
        const {
            current,
            projects
        } = this.state;
        return current !== 1 ? (<div></div>) : (
            <div className="select-project-box">
                <Form
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    {...layout}
                >
                    <Form.Item
                        label="请选择项目"
                        name="projectName"
                        rules={[{ required: true, message: '请选择项目!' }]}
                    >
                        <Select onSelect={this.handleSelect}>
                            {projects.map(project => (
                                <Option key={project.code} value={project.code}>{project.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="请输入下载密码"
                        name="pwd"
                        rules={[{ required: true, message: '请输入下载密码!' }]}
                    >
                        <Input onChange={this.handlePassword} type="password"/>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 17, span: 0 }}>
                        <Button type="primary" htmlType="button" onClick={this.startDown}>
                             下 一 步
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }

    generatingUI = () => {
        const {
            current
        } = this.state;
        return current !== 2 ? (<div></div>) : (
            <div className="generating-box">
                <SyncOutlined spin />
                <title className="generating-note">
                    正在生成数据...
                </title>
            </div>
        );
    }

    finishUI = () => {
        const {
            current,
            downUrl
        } = this.state;
        return current !== 3 ? (<div></div>) : (
            <div className="finish-box">
                <div>
                    <CheckCircleOutlined twoToneColor="#52c41a"/>
                </div>
                <div>
                    <title className="finish-note">
                        生成成功
                    </title>
                </div>
                <div>
                    <Button type="primary" shape="round" icon={<DownloadOutlined />} size={20} href={downUrl}>
                        点击下载
                    </Button>
                </div>
                <div className="back">
                    <Button type="primary" htmlType="button" onClick={this.next}>
                        返回
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const {
            current
        } = this.state;
        return (
            <div className="down">
                <div className="down-box">
                    <Title>项目报告下载</Title>
                    <Steps>
                        <Step status={this.getStepState(1)} title="选择项目" icon={<UnorderedListOutlined /> }/>
                        <Step status={this.getStepState(2)} title="生成统计数据" icon={current===2?<LoadingOutlined />:<CloudSyncOutlined />} />
                        <Step status={this.getStepState(3)} title="下载数据" icon={<CloudDownloadOutlined />} />
                    </Steps>
                    {this.selectProjectUI()}
                    {this.generatingUI()}
                    {this.finishUI()}
                </div>
            </div>
        )
    }
}


export default withRouter(ProjectReportDownComponent);