/**
 * 源数据下载
 */
import { requestGet, requestPostRes, requestGetRes, requestPatchRes } from '../EdcService';

const urlPrefix = "/cdms/dataSource";

// 源数据任务列表 
export const GetDataSourceList = (params) => {
    const url = `${urlPrefix}/dataSourceProjectList `;
    return requestPostRes(url, params)
};

// 获取下载链接
export const GetDownLoadUrl = (params) => {
    const url = `${urlPrefix}/getProjectDownLoadUrl`;
    return requestPostRes(url, params)
};

// 创建下载任务 
export const createDataSource = (params) => {
    const url = `${urlPrefix}/createProjectTask`;
    return requestPostRes(url, params)
};




