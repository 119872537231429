/**
 * 数据管理模块路由配置
 */
import ProjectDataDownload from '../components/project/data/download';     // 源数据下载
import ProjectDataClear from '../components/project/data/clear';     // 数据清理后台

const routes = [
    { 'path': '/project/data/download', 'component': ProjectDataDownload },
    { 'path': '/project/data/waited/clear', 'component': ProjectDataClear },
];

export default routes
