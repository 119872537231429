/**
 * 项目报表
 */
import React from 'react';
import './index.less';
import { Tabs, Select, DatePicker, Button, Input } from "antd";
import CaseAuditList from "./list";
import 'moment/locale/zh-cn';
import moment from 'moment';
import { GetProjectEnume, GetTenantSelects, GetProjectCenter } from '../../../../services/dct/DctService';
import { DownloadOutlined } from '@ant-design/icons';
import utils from "../../../../util/utils";

const { TabPane } = Tabs;
const { Option } = Select;
let _this;

const reportTypeList = [
    { key: 1, text: "全部病例", remark: "" },
]
class DctCaseAudit extends React.Component {

    constructor(props) {
        super(props);
        //  创建ref容器
        this.childRef = React.createRef();

        _this = this;
        _this.tenantProjectMap = {};
        _this.state = {
            selectTabPane: '1', // 报表类型 1DAY,2WEEK,3MONTH
            startDate: moment(new Date()).format('YYYY-MM-DD'), // 开始时间
            endDate: moment(new Date()).format('YYYY-MM-DD'), // 结束时间
            projects: [], // 项目枚举
            centerList: [], // 项目下面的中心枚举
            centerCode: undefined,
            subjectCode:"",
            tenants: [],
            auditState: '',
            tenantCode: '',
            projectCode: '',
            selectCont: 0, // 勾选数量
            tenantProjects: [], // 租户对应的项目
            showTenanProject: false,
        }
    }

    componentDidMount() {
        // this.getProjectEnume()
        this.getTenantAndAudit();
    }

    // 获取项目枚举
    getProjectEnume = () => {
        GetProjectEnume().then(res => {
            if (res) {
                this.setState({
                    projects: res
                })
            }
        })
    }

    getTenantAndAudit = () => {
        GetTenantSelects().then(res => {
            if (res.code == 0 && res.data) {
                let allProject = [];
                (res.data || []).forEach(item => {
                    _this.tenantProjectMap[item.code] = item;
                    allProject = [...allProject, ...item.selects];
                });
                this.setState({
                    tenants: res.data || [],
                    projects: allProject
                })
            }
        })
    }
    /**
     * 切换tab
     */
    onTabClick = (val) => {
        this.setState({
            selectTabPane: Number(val)
        })
    }

    // 选择审核状态
    handleSelectChangeAudit = (value) => {
        this.setState({
            auditState: value
        }, () => {
            this.childRef.current.getPageData(1, null, null, null, {
                auditState: value,
                createUser: this.state.createUser,
                tenantCode: this.state.tenantCode,
                projectCode: this.state.projectCode,
                centerCode: this.state.centerCode,
                subjectCode: this.state.subjectCode,
                isFilter: true
            })
        })

    }

    // 选择租户
    handleSelectChangeTenant = (value) => {
        this.setState({
            tenantCode: value
        })
        let projectCode = this.state.projectCode;
        if (value) {
            // 选择租户选项
            let selectItem = _this.tenantProjectMap[value];
            projectCode = ''; // 选择租户时，项目选为所有。租户和项目有联动效果
            this.setState({
                projectCode: projectCode,
                tenantProjects: selectItem.selects,
                showTenanProject: true,
            })
        } else {
            // 选择全部
            this.setState({
                tenantProjects: [],
                showTenanProject: false
            })
        }
        this.childRef.current.getPageData(1, null, null, null, {
            tenantCode: value,
            createUser: this.state.createUser,
            auditState: this.state.auditState,
            projectCode: projectCode,
            centerCode: this.state.centerCode,
            subjectCode: this.state.subjectCode,
            isFilter: true
        })
    }

    // 选择项目
    handleSelectChange = (value) => {
        this.setState({
            projectCode: value,
            centerCode: undefined
        }, () => {
            this.getProjectCenter(value)
            this.childRef.current.getPageData(1, null, null, null, {
                projectCode: value,
                createUser: this.state.createUser,
                auditState: this.state.auditState,
                tenantCode: this.state.tenantCode,
                centerCode: this.state.centerCode,
                subjectCode: this.state.subjectCode,
                isFilter: true
            })
        })

    }

    getProjectCenter = (projectCode) => {

        if (projectCode) {
            let params = {
                projectCode
            }
            GetProjectCenter(params).then(res => {
                if (res.code == 0) {
                    this.setState({
                        centerList: res.data
                    })
                }
            })
        } else {
            this.setState({
                centerList: []
            })
        }
    }


    handleCenterChange = (value) => {
        this.setState({
            centerCode: value
        }, () => {
            this.childRef.current.getPageData(1, null, null, null, {
                center: value,
                createUser: this.state.createUser,
                projectCode: this.state.projectCode,
                auditState: this.state.auditState,
                tenantCode: this.state.tenantCode,
                centerCode: this.state.centerCode,
                subjectCode: this.state.subjectCode,
                isFilter: true
            })
        })

    }
    handleSubjectCodeChange = (e) => {
        let value = e.currentTarget.value;
       
        this.setState({
            subjectCode: value
        }, () => {
            this.childRef.current.getPageData(1, null, null, null, {
                subjectCode: value,
                createUser: this.state.createUser,
                projectCode: this.state.projectCode,
                auditState: this.state.auditState,
                tenantCode: this.state.tenantCode,
                centerCode: this.state.centerCode,
                isFilter: true
            })
        })

    }
    handleCreateUserChange = (e) => {
        let value = e.currentTarget.value;
        // if (value.length !== 11 && value.length !== 0) {
        //     return;
        // }
        this.setState({
            createUser: value
        }, () => {
            this.childRef.current.getPageData(1, null, null, null, {
                createUser: value,
                projectCode: this.state.projectCode,
                auditState: this.state.auditState,
                tenantCode: this.state.tenantCode,
                centerCode: this.state.centerCode,
                subjectCode: this.state.subjectCode,
                isFilter: true
            })
        })

    }


    // 更新勾选项目
    updateCount = (selectCont) => {
        this.setState({ selectCont });
    }

    // 勾选通过
    selectAllPass = () => {
        this.childRef.current.selectAllPass();
    }

    // 勾选拒绝
    selectAllReject = () => {
        this.childRef.current.selectAllReject();
    }

    // 导出
    downloadRecord = () => {
        let { auditState, createUser, tenantCode, projectCode } = this.state;
        let projectList = [];
        if (projectCode != '') {
            projectList.push(projectCode)
        }
        let url = `/data/subject/audit/downLoadAuditList`
        let params = {
            auditState,
            createUser,
            projectList,
            tenantCode
        }
        utils.exportPostFun(url, params)
    }

    render() {
        let { selectTabPane, startDate, endDate, projectCode, projects, tenants, selectCont, showTenanProject, tenantProjects, centerList, centerCode, subjectCode} = this.state;
        let displayProjectList = showTenanProject ? tenantProjects : projects;

        console.log(centerList);
        return (
            <div className={'dctReport'}>
                <div className='tabsTitle'>
                    <div className='multiple_select'>
                        <Button className='all_pass' type='primary' onClick={this.selectAllPass}>全部通过</Button>
                        <Button className='all_reject' type='primary' onClick={this.selectAllReject} danger>全部拒绝</Button>
                        {/* <Button className='all_reject' type="primary" icon={<DownloadOutlined />} size={'middle'} onClick={() => this.downloadRecord()}>
                            导出
                        </Button> */}
                        {
                            selectCont > 0 &&
                            <span className='select_count'>已选择{selectCont}个</span>
                        }
                    </div>
                    <div className='search'>

                        <Input
                            placeholder="请输入任务编码"
                            onChange={this.handleSubjectCodeChange}
                            size='middle'
                            style={{ width: '130px' }}
                        />
                        <Input
                            placeholder="请输入上传人账号"
                            onChange={this.handleCreateUserChange}
                            size='middle'
                            style={{ width: '130px' }}
                        />
                        <Select defaultValue="全部状态" onChange={this.handleSelectChangeAudit} size='middle' style={{ width: '100px' }}>
                            <Option value={''}>全部状态</Option>
                            <Option key={1} value={1}>待审核</Option>
                            <Option key={2} value={2}>通过</Option>
                            <Option key={3} value={3}>未通过</Option>
                        </Select>&nbsp;&nbsp;&nbsp;
                        <Select defaultValue="所有租户" onChange={this.handleSelectChangeTenant} size='middle' style={{ width: '130px' }}>
                            <Option value={''}>所有租户</Option>
                            {tenants.length > 0 && tenants.map(project => (
                                <Option key={project.code} value={project.code}>{project.name}</Option>
                            ))}
                        </Select>&nbsp;&nbsp;&nbsp;
                        <Select defaultValue="所有项目" value={projectCode} onChange={this.handleSelectChange} size='middle' style={{ width: '220px' }}>
                            <Option value={''}>所有项目</Option>
                            {(displayProjectList || []).map(project => (
                                <Option key={project.code} value={project.code}>{project.name}</Option>
                            ))}
                        </Select>&nbsp;&nbsp;&nbsp;
                        <Select placeholder="请选择所属中心" value={centerCode} disabled={!projectCode} onChange={this.handleCenterChange} size='middle' style={{ width: '200px' }}>
                            {(centerList || []).map(project => (
                                <Option key={project.code} value={project.code}>{project.name}</Option>
                            ))}
                        </Select>&nbsp;&nbsp;&nbsp;
                    </div>
                    <Tabs defaultActiveKey="0" tabPosition='top' onTabClick={this.onTabClick}>
                        {reportTypeList.map(i => (
                            <TabPane tab={i.text} key={i.key} >
                                {
                                    selectTabPane == i.key && <CaseAuditList
                                        ref={this.childRef}
                                        {...this.props}
                                        type={selectTabPane}
                                        startDate={startDate}
                                        endDate={endDate}
                                        projectCode={projectCode}
                                        selectTabPane={selectTabPane}
                                        item={i}
                                        updateCount={this.updateCount}
                                    />
                                }
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default DctCaseAudit;