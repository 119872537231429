// 
import { requestGetRes, requestPostRes } from "../EdcService";

type Result<T> = {
  code: number;
  message: string;
  data: T
}

export type DataType = {
  id: number
  // 医助姓名
  assistantName: string
  // 绑定关爱专员
  careOfficerPhone: string
  careOfficerName: string
      // 创建时间
  createTime: string
  // 创建人
  createUser: string
  // 邀请医生链接
  doctorUrl: null | string
  // 邀请医生
  inviteDoctorNumber: null | number
  // 邀请患者
  invitePatientNumber: null | number
  isValid: number
  // 邀请患者二维码链接
  patientUrl: null | string
  // 医助手机
  phone: string
  projectCode: string
  projectName: string
  tenantCode: null | string
  updateTime: null | string
  updateUser: null | string
}

export type AssistantListParams = {
  assistantName: string;
  phone: string;
}

export type AssistantListResult = {
  total: number;
  rows: DataType[]
}

// 查询医助列表
export const getAssistantList = (params: AssistantListParams, page: number, pageSize: number)=> {
  return requestPostRes('patient/assistant/list', {
    page,
    pageSize,
    ...params
  })  as Promise<Result<AssistantListResult>>
}

export type CreateAssistantPayload = {
  id?: number // 修改时必传
  projectName: string
  projectCode: string
  assistantName: string
  phone: string
  careOfficerPhone: string
}
// 新增医助
export const createAssistant = (data: CreateAssistantPayload)=> {
  return requestPostRes('patient/assistant/add', data)
}
// 编辑医助
export const updateAssistant = (data: CreateAssistantPayload)=> {
  return requestPostRes('patient/assistant/update', data)
}

///////////////// 关爱专员管理 ////////////////////////////////////////
export type CareOfficerDataType = {
  id: number
  careOfficerName: string
  createTime: string | null
  createUser: string | null
  inviteAssistantNumber: number
  inviteDoctorNumber: number | null
  invitePatientNumber: number | null
  isValid: number
  phone: string
  projectCode: string
  projectName: string
  tenantCode: string | null
  updateTime: string | null
  updateUser: string | null
  wechatCode: string
  wechatId: string | null
}

export type CareOfficerListParams = {
  careOfficerName: string;
  phone: string;
}

export type CareOfficerListResult = {
  total: number;
  rows: CareOfficerDataType[]
}

// 查询关爱专员列表
export const getCareOfficerList = (params: CareOfficerListParams, page: number, pageSize: number)=> {
  return requestPostRes('patient/careOfficer/list', {
    page,
    pageSize,
    ...params
  })  as Promise<Result<CareOfficerListResult>>
}

// 编辑关爱专员
export type updateCareOfficerPayload = {
  id: number
  projectName: string
  projectCode: string
  wechatCode: string
}
export const updateCareOfficer = (data: updateCareOfficerPayload)=> {
  return requestPostRes('patient/careOfficer/update', data)
}