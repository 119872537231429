/**
 * 视频审计
 */
 import { requestPostFile, requestPost, requestGet, requestPostRes, requestGetRes, requestPatchRes } from '../EdcService';

 const urlPrefix = "/data";

// 视频审计列表 
export const GetAuditDataList = (params) => {
    const url = `${urlPrefix}/audit/auditDataList`;
    return requestPostRes(url, params)
};

// 视频列表
export const GetAuditVideoList = (params) => {
    const url = `${urlPrefix}/audit/videoList`;
    return requestGet(url, params)
};

// 视频审计项目枚举
export const GetProjectsEnume = (params) => {
    const url = `${urlPrefix}/audit/tenant/projectList`;
    return requestGet(url, params)
};



// 项目审计列表 
export const GetProjectDataList = (params) => {
    const url = `${urlPrefix}/audit/auditDataListByRole`;
    return requestPostRes(url, params)
};

// 视频列表
export const GetAuditVideoList1 = (params) => {
    const url = `${urlPrefix}/audit/videoList`;
    return requestGet(url, params)
};

// 项目审计项目枚举
export const GetRoleProjectsEnume = (params) => {
    const url = `${urlPrefix}/audit/role/projectList`;
    return requestGet(url, params)
};

// 获取病例签名
export const GetAuditSign = (params) => {
    const url = `${urlPrefix}/singleAudit/getSign`;
    return requestGetRes(url, params)
};

// 项目审计签名状态枚举
export const GetSignStateEnume = (params) => {
    const url = `${urlPrefix}/audit/auditSignStateDataList`;
    return requestGet(url, params)
};