import { Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import previewStyl from './Preview.module.scss';

type PreviewProps = {
  url: string;
  visible: boolean;
  close: () => void;
};

const Preview: React.FC<PreviewProps> = (props) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  const cancelControl = () => {
    setVisible(false);
    props.close();
  };

  return (
    <Modal
      width="960px"
      visible={visible}
      title="预览图片"
      footer={null}
      onCancel={cancelControl}
    >
      {loading && <div className={previewStyl.spinWrapper}><Spin /></div>}
      <img onLoad={ ()=> setLoading(false)} alt={props.url} style={{ width: "100%", display: loading ? 'none' : 'block' }} src={props.url} />
    </Modal>
  );
};

export default Preview;
