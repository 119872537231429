/**
 * 头部组件
 * lechen
 */

import React from 'react';
import HeaderNav from '../nav/HeaderNav';
import './header.less'
import PubSub from "pubsub-js";
import { DoLogout } from "../../../services/plat/UserService";
import { createFromIconfontCN } from '@ant-design/icons';
import iconAvatar from '../../../static/images/icon_avatar.png';
import { actionCreator } from '../../data/store';
import store from '../../../store';
let _this;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_1788044_0dwu4guekcwr.js', // icon-javascript, icon-java, icon-shoppingcart (overrided)
    ],
});

class AppHeader extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            header: 1,
            userName: sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token')).userName : '王医生',
            signOutFlag: false
        }
    }

    componentDidMount() {
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    /**
     * 用户名点击事件
     */
    userInfoEvents = () => {
        if (_this.state.signOutFlag) {
            _this.setState({
                signOutFlag: false
            })
        } else {
            _this.setState({
                signOutFlag: true
            })
        }
    }

    /**
     * 退出登录点击事件
     */
    signOutEvents = (e) => {
        /*e.stopPropagation();*/
        DoLogout().then(res => {
            let userName = sessionStorage.getItem('userName');
            let checkAgreement = localStorage.getItem('checkAgreement');
            const LoginPathName = sessionStorage.getItem('LoginPathName');  // /login , /safeLogin

            sessionStorage.clear();
            // localStorage.clear();
            sessionStorage.setItem('userName', userName);
            sessionStorage.setItem('LoginPathName', LoginPathName); // 防止退出登录返回至错误的登录页
            localStorage.setItem('checkAgreement', checkAgreement);
            sessionStorage.removeItem('menuWidth')
            PubSub.publish("onLogout");
            const action = actionCreator.PageStateAction({});
            console.log(action)
            console.log(store)
            store.dispatch(action)

        })
    }

    render() {
        return (
            <div className={"header"}>
                {/* <div className={'header_icon'}> */}
                {/* {_this.formatIcon('icon-java')}*/}
                {/* </div> */}
                <HeaderNav></HeaderNav>
                <div className={"userName"} onClick={_this.userInfoEvents}>
                    <img src={iconAvatar} alt='' className={'user_photo'} />
                    <p className={'user_name'} onClick={_this.handleClick}>{_this.state.userName}</p>
                    <div className={'signOut'} onClick={_this.signOutEvents}
                        style={{ display: (_this.state.signOutFlag) ? "block" : "none" }}>
                        <img src={iconAvatar} alt='' className={'user_photo'} />
                        <span>退出登录</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default AppHeader;


