import React from "react";
import Condition from "./components/condition";
import Category from "./components/category";
import { DataBoardContext, defaultValue } from "./index.context";
import { QueryParams } from "../../../../services/data/dashboard";

type Props = {
  projectId: number
  projectCode: string
}

const DataBoard:React.FC<Props> = ({ projectId, projectCode })=> {
  
  const [tabIndex, setTabIndex] = React.useState<string>(defaultValue.tabIndex)

  const [queryParams, setQueryParams] = React.useState<QueryParams>({})
  
  const onSearch = (q: QueryParams)=> {
    setQueryParams({
      ...q
    })
  }

  const onChange = (index: string)=> {
    setTabIndex(index)
  }

  return (
    <DataBoardContext.Provider value={{ tabIndex, projectId, projectCode, queryParams }}>
      <Condition activeKey={tabIndex} onSearch={onSearch} />
      <Category onChange={onChange}/>
    </DataBoardContext.Provider>
  )
}
export default DataBoard