/**
 * 随访服务接口
 */
import { requestPost, requestGet, requestGetRes, requestPostRes } from '../EdcService';

const urlPrefix = "/visit";

/**
 * 报名信息列表
 * @param {} params
 */
export const getRegisterlist = (params) => {
    const url = urlPrefix + "/register/list";
    return requestPostRes(url, params);
};


/**
 * 报名信息详情
 * @param {} params
 */
export const getRegisterDetail = (params) => {
    const url = urlPrefix + `/register/detail/${params.id}`;
    return requestGetRes(url);
};


/**
 * 报名审核处理
 * @param {} params
 */
export const handleRegister = (params) => {
    const url = urlPrefix + `/register/handle/${params.id}`;
    return requestPostRes(url, params);
};