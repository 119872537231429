/**
 * 结算申请记录
 */
import React from 'react';
import './index.less';
import { withRouter } from 'react-router-dom';
import { Button, message as Message, Table, message } from "antd";
import 'moment/locale/zh-cn';
import { getSettlementView } from '../../../../services/data/DataService';
import { createFromIconfontCN } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/zh_CN';
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',
    ],
});

let _this;

class SettlementView extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            dataLists: [], // 列表集合
            page: 1,//当前页码数
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo)
                },
                current: 1,
                pageSize: 10,
                total: 100,
                showSizeChanger: false,
            },
        }
    }


    componentDidMount = () => {
        this.getPageData();
    }

    /**
     * 获取待处理列表数据 
     */
    getPageData = async (curPage) => {
        let { page } = this.state;

        const params = {
            page: curPage ? curPage : page,
            pageSize: this.state.pagination.pageSize
        }

        const res = await getSettlementView(params);
        if (res && res.code == 0) {
            _this.setState({
                dataLists: [...res.data.rows],
                page: res.data.page,
                pageSize: res.data.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })
        }
    }



    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    /**
     * 下载数据
     */
    downLoadData = (record, type) => {
        if (type == 'formFileUrl') {
            if (!record.formFileUrl) {
                message.info('暂无申请单下载链接', 2)
                return false
            }
            window.open(record.formFileUrl);
        } else {
            if (!record.approveFileUrl) {
                message.info('暂无审核记录下载链接', 2)
                return false
            }
            window.open(record.approveFileUrl);
        }
    }

    render() {
        let { dataLists, pagination } = this.state;
        let searchWrapHeight = document.getElementById('search_wrap');
        let sh = searchWrapHeight ? searchWrapHeight.offsetHeight : 0;
        let wh = document.documentElement.clientHeight;
        let th = wh - sh - 400;


        let columns = [
            {
                title: '项目名称',
                width: 380,
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div className={'title'}>{record.projectName}</div>
                    </div>
                )
            },
            {
                title: '待处理结算单',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div className={'title'}>{record.waitFormNum}</div>
                    </div>
                )
            },
            {
                title: '已处理结算单',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div className={'title'}>{record.dealFormNum}</div>
                    </div>
                )
            },
            {
                title: '项目目标数',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div className={'title'}>{record.targetNum}</div>
                    </div>
                )
            },
            {
                title: '已审核病例量',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div className={'title'}>{record.auditNum}</div>
                    </div>
                )
            },
            {
                title: '已提交结算量',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div className={'title'}>{record.commitNum}</div>
                    </div>
                )
            },
            {
                title: '已审批结算量',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div className={'title'}>{record.approveNum}</div>
                    </div>
                )
            },
            {
                title: '操作',
                width: '',
                render: (text, record) =>
                    <div className={'partPop'} >
                        <Button type="link" onClick={() => this.downLoadData(record, 'formFileUrl')} >
                            申请单下载
                        </Button>
                        <Button type="link" onClick={() => this.downLoadData(record, 'approveFileUrl')}>
                            审核记录下载
                        </Button>
                    </div>
            }
        ]



        return (
            <div className='SettlementView'>
                <div className={'content_wrap'}>
                    <div className={'search_wrap'}>
                        结算申请记录
                    </div>
                    <div className={'content_table'}>
                        <Table
                            locale={{ emptyText: "暂无数据" }}
                            columns={columns}
                            dataSource={dataLists}
                            pagination={dataLists.length !== 0 && pagination}
                            rowKey={'id'}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(SettlementView);