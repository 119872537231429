/**
 * 
 * 1-病史备注
 * 
 */
import React, { Component } from 'react';
import { Button, Form, Input, Select, Radio, message, DatePicker } from 'antd';
import { createFromIconfontCN } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/zh_CN';
import axios from 'axios';
import '../modalOperate.less';
import { diseaseTypeList, relativeList, layout, isWarnList, isHealthList, severeLeavelList } from '../select/select';

const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

class Disease extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stopType: 0, // 默认暂停类型
        }
    }
    formRef = React.createRef();

    componentWillMount() {
    }

    /**
     * 
     * @returns 插入
     */
    onSubmit = (values) => {
        let { stopType } = this.state;
        const form = this.formRef.current;
        const formValues = form.getFieldsValue(
            ['disease-name', 'radioroup-severe', 'radioroup-health',
                'affirm-date', 'selectOption-relative', 'radioroup-warn', 'remark']);

        if (stopType == 0) { // 个人病史

        } else if (stopType == 1) { // 家庭病史

        } else { // 过敏史

        }
    }

    onChange = (value) => {
        // debugger;
    }
    onSearch = () => {

    }

    /**
     * 类型切换
     */
    onChangeType = (e) => {
        this.setState({
            stopType: e.target.value,
        })
    }

    /**
     * 确诊时间
     */
    onChangeDate = () => {

    }

    render() {
        let { stopType } = this.state;

        return (
            <div className={'operateContent'}>
                <Form
                    {...layout}
                    name="nest-messages"
                    onFinish={this.onSubmit}
                    requiredMark={true}
                    ref={this.formRef}
                    initialValues={{
                        'radioGroup-type': 0,
                        'disease-name': '',
                        'radioroup-severe': '0',
                        'radioroup-health': '0',
                        'affirm-date': '',
                        'selectOption-relative': 0,
                        'radioroup-warn': 0,
                        'remark': ''
                    }}
                >

                    {/* 类型 */}
                    <Form.Item
                        label={'病史类型'}
                        name='radioGroup-type'
                        rules={[
                            {
                                required: true,
                                message: '必填项未填',
                            }
                        ]}
                    >
                        <Radio.Group
                            value={stopType}
                            onChange={this.onChangeType}
                            buttonStyle="solid"
                        >
                            {
                                diseaseTypeList.map(item => {
                                    return (
                                        <Radio.Button value={item.value} key={item.value}>{item.name}</Radio.Button>
                                    )
                                })
                            }

                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={stopType == 2 ? '过敏名称' : '疾病名称'}
                        name='disease-name'
                        rules={[
                            {
                                required: true,
                                message: '必填项未填',
                            }
                        ]}
                    >
                        <Input placeholder='请输入曾患疾病名称' />
                    </Form.Item>

                    {stopType == 2 &&
                        <Form.Item name="radioroup-severe" label="严重程度">
                            <Radio.Group >
                                {
                                    severeLeavelList.map(item => {
                                        return (
                                            <Radio value={item.value} key={item.value}>{item.name}</Radio>
                                        )
                                    })
                                }
                            </Radio.Group>
                        </Form.Item>
                    }

                    {stopType == 0 &&
                        <>
                            <Form.Item name="radioroup-health" label="是否治愈">
                                <Radio.Group >
                                    {
                                        isHealthList.map(item => {
                                            return (
                                                <Radio value={item.value} key={item.value}>{item.name}</Radio>
                                            )
                                        })
                                    }
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item label="确诊时间" name='affirm-date'>
                                <DatePicker onChange={this.onChangeDate} locale={locale} />
                            </Form.Item>
                        </>
                    }

                    {stopType == 1 &&
                        <Form.Item
                            label={'与患者关系'}
                            name='selectOption-relative'
                        >
                            <Select
                                showSearch
                                style={{ width: 180 }}
                                placeholder=""
                                onChange={this.onRelativeChange}
                                onSearch={this.onRelativeSearch}
                            >
                                {
                                    relativeList.map((item, index) => {
                                        return (
                                            <Select.Option value={item.value} key={index}>{item.name}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    }

                    {(stopType == 1 || stopType == 2) &&
                        <Form.Item name="radioroup-warn" label="是否警示">
                            <Radio.Group>
                                {
                                    isWarnList.map(item => {
                                        return (
                                            <Radio value={item.value} key={item.value}>{item.name}</Radio>
                                        )
                                    })
                                }
                            </Radio.Group>
                        </Form.Item>
                    }

                    <Form.Item label={stopType == 2 ? '过敏备注' : "病史备注"} name='remark'>
                        <Input.TextArea />
                    </Form.Item>
                </Form>
                <div className={'submit'}>
                    <Button
                        key="submit"
                        type="primary"
                        onClick={this.onSubmit}
                    >
                        插入
                        </Button>
                </div>
            </div>
        );
    }
}

export default Disease;