/**
 * 审计
 */
import videoAuditComponent from '../components/videoAudit';
import projectAuditComponent from '../components/projectAudit';
import AuditDownloadComponent from '../components/auditDownload';
const routes = [
    { 'path': '/videoAudit', 'component': videoAuditComponent }, // 视频审计
    { 'path': '/projectAudit', 'component': projectAuditComponent }, // 项目审计
    { 'path': '/audit/download', component: AuditDownloadComponent } // 审计数据源下载

];

export default routes
