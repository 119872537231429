import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { GetDctImageStatistics, GetDctInputStatistics } from "../../../../../services/dct/DctService"

import acceptPNG from "../../../static/images/accept.png";
import todayAcceptPNG from "../../../static/images/todayAccept.png";

import modStyl from './Statistics.module.scss'

export type StatisticsActions = {
  refresh: ()=> Promise<void>
}
export type StatisticsProps = {

}
const Statistics = forwardRef<StatisticsActions, StatisticsProps>((props, ref)=> {

  const [items, setItems] = useState([
    {
      id: "pendingReviewTotal",
      icon: acceptPNG,
      name: "待录入任务",
      value: 0,
      color: "#276ec9",
    },
    {
      id: "reviewTotal",
      icon: todayAcceptPNG,
      name: "今日录入任务",
      value: 0,
      color: "#f39c00",
    },
  ]);

  const refresh = async()=> {
    const {code, data} = await GetDctInputStatistics()
    if(code === 0) {
      setItems(
        items.map((item)=> {
          return {
            ...item,
            value: data[item.id]  ?? 0,
          }
        })
      )
    }
  }

  useImperativeHandle(ref, ()=> {
    return {
      refresh
    }
  }, [])

  useEffect(()=> {
    refresh()
  }, [])

  return (
    <div className={modStyl.wrapper}>
      <ul>
        {items.map((item)=> (
          <li key={item.id}>
            <dl style={{backgroundImage: 'url('+ item.icon +')'}}>
              <dt style={{color: item.color}}>{item.value}</dt>
              <dd>{item.name}</dd>
            </dl>
          </li>
        ))}
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  )
})

export default Statistics