import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react';
import {
    message,
    message as Message,
    Button,
    Modal
} from "antd";
import { CloseCircleFilled, PlusOutlined, LoadingOutlined, StopOutlined } from '@ant-design/icons';
import { GetCaseSts } from '../../../../services/data/DataService';
import './video.less';
import formAction from '../../utils/formAction';
import OSS from "ali-oss";
import none from "../../../../static/images/none_video.png";
import { Player, ControlBar, PlaybackRateMenuButton, BigPlayButton } from 'video-react';
// @import "~video-react/styles/scss/video-react";

let client: any = null;
let timerId;


function VideoCom(props: any, ref: any) {
    const [fileList, setFileList] = useState<any>([]);
    const [value, setValue] = useState<any>([]);
    const [data, setData] = useState<any>();
    const [formId, setFormId] = useState<any>();
    const [dataActions, setDataActions] = useState<any>([]);
    const [formIsRead, setFormIsRead] = useState<boolean>(true);
    const [savedValues, setSavedValues] = useState<any>([]);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [updatedValue, setUpdatedValue] = useState<any>([]);
    const [loading, setLoading] = useState<any>(false);
    const [ossData, setOssData] = useState<any>({});
    const [isShow, setIsShow] = useState<any>(false);
    const [url, setUrl] = useState<any>('');
    const [fullVideo, setFullVideo] = useState<boolean>(false);
    const [videoUrl, setVideoUrl] = useState<any>(''); //全屏播放视频链接

    const playerRef: any = useRef()

    useEffect(() => {
        if (props.data) {
            //设置dataActions
            setDataActions(props.dataActions);
            //表单是否只读
            setFormIsRead(props.formIsRead);
            //获取当前表单的id
            setFormId(props.formId);
            //该条表单元素的数据
            setData(props.data);
            //已经保存的表单数据
            setSavedValues(props.savedValues);
            //如果是修改时
            if (props.isUpdate) {
                setIsUpdate(props.isUpdate)
            }
            else {
                setIsUpdate(false)
            }
            //设置当前表单已存的value
            let currentItem = props.savedValues.find((i: any) => {
                return i.dataCode == props.data.dataCode
            });
            if (currentItem) {
                let fieldList = [];
                if (currentItem.value) {
                    for (let item of currentItem.value) {
                        fieldList.push({
                            url: item,
                            uid: item
                        })
                    }
                }
                setValue(fieldList);
                setFileList(fieldList);
                setUpdatedValue(fieldList);
            }
            else {
                setValue([])
                setFileList([]);
                setUpdatedValue([]);
            }
        }

        // getOssData()

    }, [props.data, props.savedValues])

    /**
     * 提取上传类型
     * @param json 
     * @returns 
     */
    const filterPathPre = (json: any) => {
        let newJson = json;
        let data: any = [];
        data = newJson.pathPre.split(',')
        data.map((i: any) => {
            if (i.includes('video')) {
                newJson.pathPre = i
            }
        })

        return newJson
    }

    /**
     * 获取OSS访问令牌
    */
    const getOssData = () => {
        // 当前缓存中已经存储了oss token
        let oss = sessionStorage.getItem("ossToken");
        if (oss) {
            let json = JSON.parse(oss);
            client = new OSS({ ...filterPathPre(json) });
            setOssData(filterPathPre(json))
            return;
        }

        let loading = sessionStorage.getItem("loadingOssToken");
        if (loading == "true") {
            setTimeout(getOssData, 500);
            return;
        } else {
            sessionStorage.setItem("loadingOssToken", "true");
        }

        GetCaseSts().then(res => {
            if (res.code == 0) {

                sessionStorage.setItem("loadingOssToken", "false");
                if (!res.data) {
                    return
                }
                setOssData(filterPathPre(res.data))

                sessionStorage.setItem("ossToken", JSON.stringify(res.data));
                timerId = setTimeout(() => {
                    sessionStorage.removeItem("ossToken");
                }, 3600 * 1000)

                client = new OSS({ ...filterPathPre(res.data) });
            }
        })
    };

    //暂存数据
    const saveData = (value: any) => {
        let filterArr = [];
        for (let item of value) {
            if (item.url) {
                filterArr.push(item.url);
            }
        }
        let values = [
            {
                dataCode: data.dataCode,
                deCode: data.deCode,
                value: filterArr
            }
        ];
        let params = {
            formId: formId,
            submit: false,
            values: values
        }
        formAction.saveData(params);
        // props.startTimerFun();
        props.updateFun()
        // saveFormData(params).then((res) => {
        //     props.updateFun()
        // })
    }

    useImperativeHandle(ref, () => ({
        // changeVal 就是暴露给父组件的方法
        getModifyValue: () => {
            let filterArr = [];
            for (let item of updatedValue) {
                if (item.url) {
                    filterArr.push(item.url);
                }
            }
            let params = {
                dataCode: data.dataCode,
                deCode: data.deCode,
                formId: formId,
                value: filterArr,
            }
            return params;
        },
    }));

    /**
     * 删除
     */
    const deleteImg = (url: any) => {
        let val = value.filter((value: any, index: any, array: any) => {
            return value !== url;
        })
        setValue(val)
        if (isUpdate === true) {
            setUpdatedValue(val);
        }
        else {
            saveData(val);
        }

        setFileList([...value]);
    }

    /**
     * 上传
     * @param {*} e 
     */
    const uploadFile = (e: any) => {
        setLoading(true)
        let userCode = sessionStorage.getItem('userName')
        let tenantCode = localStorage.getItem('tenantCode')

        if (!e.target.files[0]) return;

        const file = e.target.files[0];
        //处理FileReader
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
            const suffix = file.name.slice(file.name.lastIndexOf('.'));
            let time = new Date().getTime();
            const path = `${ossData.pathPre}${tenantCode}/${userCode}/${time}${suffix}`;
            // oss上传
            client.put(
                path,
                file,
            ).then((res: any) => {
                let list = value;
                list.push(res.url)
                setValue(list)
                setUrl(res.url)
                setLoading(false)
                setIsShow(false)

                if (isUpdate === true) {
                    setUpdatedValue([...list, { url: res.url, uid: res.url }]);
                }
                else {
                    saveData([...list, { url: res.url, uid: res.url }]);
                }

                setFileList([...list]);

            }).catch((err: any) => {
                setLoading(false)
                message.error('上传失败！')
            })
        }
    }


    /**切换播放器全屏 */
    const toggleFullScreen = (videoUrl: any) => {
        setFullVideo(true)
        setVideoUrl(videoUrl)
    }

    return (
        <div className={'videoCom'}>
            <div className="crf_upload">
                {
                    value && value.length > 0 && value.map((item: any, index: any) => (
                        <div key={index} className="upload_img">
                            <video
                                height="100%"
                                controls
                                // autoPlay
                                src={item.url}
                                className="curr_video"
                            >
                            </video>

                            <CloseCircleFilled className="icon_del" onClick={() => deleteImg(item)} hidden={!formIsRead} />
                            <div className='downLoad'>
                                <span className="opertion" onClick={() => toggleFullScreen(item.url)}>全屏</span>
                                <a href={item.url} className="opertion" target="_bank" download>下载</a>
                            </div>

                        </div>
                    ))
                }

                {
                    isShow &&
                    <video
                        height="100%"
                        controls
                        autoPlay
                        src={url}
                        className="curr_video"
                    >
                    </video>
                }

                {
                    !isShow && formIsRead &&
                    <div className="upload_wrap">
                        {/* <input
                            type="file"
                            accept="video/*"
                            onChange={(e) => uploadFile(e)}
                            disabled={!formIsRead}
                            className="upload_file"
                        />
                        {loading ? <LoadingOutlined /> : <PlusOutlined />} */}

                        <StopOutlined />
                    </div>
                }

                {!formIsRead && value.length == 0 && <img src={none} alt='' className={'none_img'} />}
            </div>

            <Modal
                visible={fullVideo}
                destroyOnClose
                footer={false}
                maskClosable={false}
                onCancel={() => setFullVideo(false)}
                width='60vw'
                className='video_fullContent'
            >
                <div className='fullContent'>
                    <Player
                        ref={playerRef}
                        playsInline
                        src={videoUrl}
                    >
                        <BigPlayButton position="center" />
                        <ControlBar>
                            <PlaybackRateMenuButton rates={[10, 5, 3, 2, 1.5]} />
                        </ControlBar>
                    </Player>
                </div>

            </Modal>
        </div>
    )
}

export default forwardRef(VideoCom);


