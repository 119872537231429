/**
 * 批量审核
 */
import React from 'react';
import './index.less';
import { withRouter } from 'react-router-dom';
import { Form, Input, Select, Button, message as Message, Table, message, Modal, Tabs, Upload, DatePicker } from "antd";
import 'moment/locale/zh-cn';
import { getWaitApproveRecord, getSettlementProjects, getDealApproveRecord, postBackSettlement, getSettlementTenantList } from '../../../services/data/DataService';
import { createFromIconfontCN, UploadOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/zh_CN';
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',
    ],
});
const { Option } = Select;
const { TabPane } = Tabs;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};
const { RangePicker } = DatePicker;
let _this;

class DoctorApprove extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            activeKey: '1',
            dataLists: [], // 列表集合
            page: 1,//当前页码数
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    if (this.state.activeKey == '1') {
                        this.getPageData(pageNo)
                    } else {
                        this.getDealPageData(pageNo)
                    }
                },
                current: 1,
                pageSize: 10,
                total: 100,
                showSizeChanger: false,
            },
            projects: [], // 项目枚举
            projectCode: '', // 项目code
            tenantList: [], // 租户枚举
            tenantCode: '', // 租户code
            userCode: '', // 审批人code
            startDate: '',
            endDate: '',

            isCreateVisible: false,
            recordId: '',
            fileList: [],
            uploadBtnFlag: false,
            noFileTips: false,//未选择文件提示
            uploadFlag: true,

        }
    }

    formRef = React.createRef();

    componentDidMount = () => {
        // 列表
        this.getPageData();
        this.getProjectEnume();
        this.getTenantEnume();
    }

    // 获取项目枚举
    getProjectEnume = () => {
        getSettlementProjects().then(res => {
            if (res) {
                this.setState({
                    projects: res.data
                })
            }
        })
    }

    // 获取租户枚举
    getTenantEnume = () => {
        getSettlementTenantList().then(res => {
            if (res) {
                this.setState({
                    tenantList: res.data
                })
            }
        })
    }


    /**
     * 获取待处理列表数据 
     */
    getPageData = async (curPage) => {
        let { page, projectCode, tenantCode } = this.state;

        const params = {
            page: curPage ? curPage : page,
            pageSize: this.state.pagination.pageSize,
            projectCode: projectCode ? projectCode : undefined,
            tenantCode: tenantCode ? tenantCode : undefined,
        }

        const res = await getWaitApproveRecord(params);
        if (res && res.code == 0) {
            _this.setState({
                dataLists: [...res.data.rows],
                page: res.data.page,
                pageSize: res.data.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })
        }
    }

    /**
     * 获取已处理列表数据 
     */
    getDealPageData = async (curPage) => {
        let { page, projectCode, tenantCode, userCode, startDate, endDate } = this.state;

        const params = {
            page: curPage ? curPage : page,
            pageSize: this.state.pagination.pageSize,
            projectCode: projectCode ? projectCode : undefined,
            tenantCode: tenantCode ? tenantCode : undefined,
            userCode: userCode ? userCode : undefined,
            startDate: startDate ? startDate : undefined,
            endDate: endDate ? endDate : undefined,
        }

        const res = await getDealApproveRecord(params);
        if (res && res.code == 0) {
            _this.setState({
                dataLists: [...res.data.rows],
                page: res.data.page,
                pageSize: res.data.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })
        }
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    // 选择项目
    handleSelectChange = (value) => {
        this.setState({
            projectCode: value
        })
    }
    // 选择租户
    handleTenantChange = (value) => {
        this.setState({
            tenantCode: value
        })
    }

    // 选择审批时间
    changeDate = (date, dateString) => {
        this.setState({
            startDate: dateString[0],
            endDate: dateString[1]
        })
    }




    handleSearch = () => {
        this.setState({
            page: 1
        }, () => {
            if (this.state.activeKey == '1') {
                this.getPageData()
            } else {
                this.getDealPageData()
            }
        })
    }


    changeTab = (activeKey) => {
        this.setState({
            activeKey,
            page: 1
        }, () => {
            if (activeKey == '1') {
                this.getPageData()
            } else {
                this.getDealPageData()
            }
        })
    }

    /**
     * 下载数据
     */
    downLoadData = (record) => {
        if (this.state.activeKey == '1') {
            window.open(record.formFileUrl);
        } else {
            window.open(record.postBackUrl);
        }
    }


    //回传弹窗
    postBack = (record) => {
        this.setState({
            isCreateVisible: true,
            recordId: record.id
        })
    }


    // 上传文件
    onSubmit = async () => {
        if (!this.state.uploadFlag) {
            return false
        }
        const { fileList } = this.state;
        if (fileList.length === 0) {
            this.setState({
                noFileTips: true
            })
            return;
        }
        const uploadFormData = new FormData();
        fileList.forEach(file => {
            uploadFormData.append('file', file);
        });

        uploadFormData.append('recordId', this.state.recordId);
        this.setState({ uploadFlag: false })

        const res = await postBackSettlement(uploadFormData);
        if (res && res.code == 0) {
            message.success('回传成功！');
            _this.setState({
                isCreateVisible: false,
                uploadBtnFlag: false,
                noFileTips: false,
                fileList: [],
                uploadFlag: true,
                page: 1
            }, () => {
                this.getPageData()
            })
        } else {
            _this.setState({
                uploadFlag: true
            })
        }
    }

    // 取消
    handleCancel = () => {
        this.setState({
            isCreateVisible: false,
            uploadBtnFlag: false,
            noFileTips: false,
            fileList: []
        })
    }


    render() {
        let { activeKey, dataLists, pagination, isCreateVisible, projects, projectCode, tenantList, tenantCode, userCode } = this.state;

        let searchWrapHeight = document.getElementById('search_wrap');
        let sh = searchWrapHeight ? searchWrapHeight.offsetHeight : 0;
        let wh = document.documentElement.clientHeight;
        let th = wh - sh - 400;

        const props = {
            name: 'file',
            accept: ".xlsx,.xls",
            headers: {
                authorization: 'authorization-text',
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }), () => {
                    if (this.state.fileList.length === 1) {
                        this.setState({
                            uploadBtnFlag: true,
                            noFileTips: false
                        })
                    }
                });
                return false
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
                this.setState({
                    uploadBtnFlag: false
                })
            },
        }


        let columns = [
            {
                title: '项目名称',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div className={'title'}>{record.projectName}</div>
                    </div>
                )
            },
            {
                title: '所属租户',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div className={'title'}>{record.tenantName}</div>
                    </div>
                )
            },
            {
                title: '待处理结算单',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div className={'title'}>{record.waitFormNum}</div>
                    </div>
                )
            },
            {
                title: '已处理结算单',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div className={'title'}>{record.dealFormNum}</div>
                    </div>
                )
            },
            {
                title: '待处理结算量',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div className={'title'}>{record.waitDataNum}</div>
                    </div>
                )
            },
            {
                title: '已处理结算量',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div className={'title'}>{record.dealDataNum}</div>
                    </div>
                )
            },
            {
                title: '操作',
                width: '',
                render: (text, record) =>
                    <div
                        onClick={eve => {
                            eve.stopPropagation();
                        }}
                        className={'partPop'}
                    >
                        <Button type="link" onClick={() => this.downLoadData(record)} >
                            申请单下载
                        </Button>
                        <Button type="link" onClick={() => this.postBack(record)}>
                            回传
                        </Button>
                    </div>
            }
        ]

        let columns1 = [
            {
                title: '项目名称',
                width: '',
                render: (text, record) => {
                    return (
                        <div className={'con_div'}>
                            <div className={'title'}>{record.projectName}</div>
                        </div>
                    )
                }
            },
            {
                title: '所属租户',
                width: '',
                render: (text, record) => {
                    return (
                        <div className={'con_div'}>
                            <div className={'title'}>{record.tenantName}</div>
                        </div>
                    )
                }
            },
            {
                title: '结算单数量',
                width: '',
                render: (text, record) => {
                    return (
                        <div className={'con_div'}>
                            <div className={'title'}>{record.formNum}</div>
                        </div>
                    )
                }
            },
            {
                title: '申请结算例数',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div className={'title'}>{record.dataNum}</div>
                    </div>
                )
            },
            {
                title: '审批通过例数',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div className={'title'}>{record.passNum}</div>
                    </div>
                )
            },
            {
                title: '审批未通过例数',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div className={'title'}>{record.rejectNum}</div>
                    </div>
                )
            },
            {
                title: '审批人',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div className={'title'}>{record.createUser}</div>
                    </div>
                )
            },
            {
                title: '审批时间',
                width: '',
                render: (text, record) => {
                    if (record.createTime) {
                        return (
                            <div className={'con_div'}>
                                <div className={'sub-title'}>{record.createTime ? record.createTime.replace('T', ' ') : ''}</div>
                            </div>
                        )
                    }
                }
            },
            {
                title: '操作',
                width: '',
                render: (text, record) =>
                    <div
                        onClick={eve => {
                            eve.stopPropagation();
                        }}
                        className={'partPop'}
                    >
                        <Button type="link" onClick={() => this.downLoadData(record)} >
                            审批明细下载
                        </Button>
                    </div>
            }
        ]


        return (
            <div className='settlementMange'>
                <div className={'content_wrap'}>
                    <div className={'search_wrap'} id="search_wrap">
                        <div className='search_con'>
                            <div className='search_item'>
                                <span className='label'>所属项目</span>
                                <Select
                                    showSearch
                                    defaultValue="所有项目"
                                    value={projectCode}
                                    placeholder="请选择所属项目"
                                    style={{ width: 280 }}
                                    allowClear
                                    onChange={this.handleSelectChange}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value={''}>所有项目</Option>
                                    {projects && projects.length > 0 && projects.map(project => (
                                        <Option key={project.projectCode} value={project.projectCode}>{project.projectName}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='search_item'>
                                <span className='label'>选择租户</span>
                                <Select
                                    showSearch
                                    defaultValue="所有租户"
                                    value={tenantCode}
                                    onChange={this.handleTenantChange}
                                    size='middle'
                                    style={{ width: 280 }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    allowClear
                                >
                                    <Option value={''}>所有租户</Option>
                                    {tenantList && tenantList.length > 0 && tenantList.map(item => (
                                        <Option key={item.code} value={item.code}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='search_item' hidden={activeKey == '1'}>
                                <span className='label'>审批人</span>
                                <Input placeholder="请输入审批人" value={userCode} onChange={(e) => this.setState({ userCode: e.target.value })} style={{ width: 220 }} />
                            </div>
                            <div className='search_item' hidden={activeKey == '1'}>
                                <span className='label'>审批时间</span>
                                <RangePicker onChange={this.changeDate} locale={locale} style={{ width: 300 }} />
                            </div>
                        </div>

                        <div className='searchBtn'>
                            <Button type="primary" size={'middle'} onClick={this.handleSearch}>
                                筛选
                            </Button>
                        </div>
                    </div>

                    <div className={'content_table'}>
                        <Tabs activeKey={activeKey} onChange={this.changeTab}>
                            <TabPane tab="待处理结算单" key="1">
                                <Table
                                    locale={{ emptyText: "暂无数据" }}
                                    columns={columns}
                                    dataSource={dataLists}
                                    pagination={dataLists.length !== 0 && pagination}
                                    rowKey={'id'}
                                    scroll={{ y: `${th}px` }}
                                />
                            </TabPane>
                            <TabPane tab="已处理记录" key="2">
                                <Table
                                    locale={{ emptyText: "暂无数据" }}
                                    columns={columns1}
                                    dataSource={dataLists}
                                    pagination={dataLists.length !== 0 && pagination}
                                    rowKey={'id'}
                                    scroll={{ y: `${th}px` }}
                                />
                            </TabPane>
                        </Tabs>

                    </div>
                </div>

                {/* 创建回传文件任务 */}
                <Modal
                    title="回传文件"
                    visible={isCreateVisible}
                    onOk={this.onSubmit}
                    okText='确认回传'
                    cancelText='关闭'
                    onCancel={this.handleCancel}
                    width='30%'
                    destroyOnClose
                >
                    <Form
                        {...layout}
                        name="nest-messages"
                        requiredMark={true}
                        ref={this.formRef}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="请选择文件"
                            name='file'
                            rules={[{ required: true, message: '请上传文件!' }]}
                        >
                            <div className={'upFile'} >
                                <Upload {...props} >
                                    <Button
                                        icon={<UploadOutlined />}
                                        disabled={this.state.uploadBtnFlag}
                                    >
                                        上传文件
                                    </Button>
                                </Upload>
                                <div
                                    className={'tips'}
                                    style={{ display: `${this.state.noFileTips ? 'block' : 'none'}` }}
                                >
                                    {this.formatIcon('icon-guanbi')}
                                    <span>请先选择文件</span>
                                </div>
                                <div className={'tip'} style={{ marginTop: 4 }}>支持扩展名：.xlsx</div>
                            </div>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}
export default withRouter(DoctorApprove);