import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { message } from "antd";
import QRCode from "qrcode.react";
import PosterUtil from "./poster-util";

type Props = {
  width?: number;
  height?: number;
  QRWidth?: number;
  id: string;
  name: string;
  title: string;
  description: string;
  data: string;
}
export type PosterRef = {
  savePoster: ()=> void;
}

const Poster = forwardRef<PosterRef, Props>(({id, title, description, name, data, width=360, height=470, QRWidth=150}, ref)=> {

  const canvasRef = useRef<HTMLCanvasElement>(null)

  const [QRCodeStyle, setQRCodeStyle] = useState({
    top: 0,
    left: 0,
  })

  const start = async (ctx: CanvasRenderingContext2D)=> {

    const util = new PosterUtil(ctx, width, height, QRWidth)

    setQRCodeStyle({
      top: util.QRTop,
      left: util.QRLeft,
    })

    // 绘制背景
    util.drawBackground()

    // 绘制标题
    util.drawIntro(title, description)    

    // 邀请人
    await util.drawInviter(name)

    const qr = document.getElementById(id) as HTMLCanvasElement
    if (!qr) {
      message.error('邀请二维码生成失败')
      return
    }

    // 绘制二维码
    await util.drawQrCode(qr.toDataURL('image/png'))
  }

  useImperativeHandle(ref, ()=> ({
    async savePoster() {
      try {
        if (!canvasRef.current) {
          return
        }

        const dataUrl = canvasRef.current.toDataURL('image/png')
        const a = document.createElement('a')
        a.href = dataUrl
        a.download = `${title}.png`
        a.click()
      } catch (error) {
        message.error('下载失败')
      }
    }
  }))

  useEffect(()=> {
    if (canvasRef.current) {
      canvasRef.current.width = width
      canvasRef.current.height = height
      start(canvasRef.current.getContext('2d') as CanvasRenderingContext2D)
    }
  }, [])

  return (
    <>
      <canvas ref={canvasRef} style={{width, height}}></canvas>
      <QRCode id={id} renderAs="canvas" style={{
        position: 'absolute',
        ...QRCodeStyle
      }} size={QRWidth} value={data} includeMargin/>
    </>
  )
})

export default Poster