/**
 * 首页
 * lechen
 */

import React from 'react';
import './index.less';

class AppIndex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className={'index'}>Hey,Girl...欢迎进入惠每电子数据采集平台</div>
        )
    }

}

export default AppIndex;


