import { Button, Popover } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import "./Quick.less"
import { messageFill, MessageFillResult } from "../../services/data/chat";

type QuickProps = {
  onConfirm: (value: string)=> void
}
const Quick: React.FC<QuickProps> = (props)=> {

  const [visible, setVisible] = useState(false)

  const [list, setList] = useState<MessageFillResult[]>([])

  const content = useMemo(()=> {
    return ()=> {
      return <div className="feat-message--quick customized-scrollbar">
        <ul>
          {list.map((t)=> <li onClick={ ()=> {
            props.onConfirm(t.content)
            setVisible(false)
            }} key={t.id}>{t.content}</li>)}
        </ul>
      </div>
    }
  }, [list])

  useEffect(()=> {
    if(visible && list.length === 0) {
      messageFill().then(({code, data})=> {
        if(code === 0) {
          setList(data as MessageFillResult[])
        }
      })
    }
  }, [visible])

  return (
    <Popover
      visible={visible}
      onVisibleChange={(state: boolean)=> setVisible(state)}
      overlayClassName="feat-message--quick-overlay"
      content={content}
      trigger="click"
      title="常用语">
      <Button danger>常用语</Button>
    </Popover>
  )
}

export default Quick