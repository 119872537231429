/**
 * 批量编辑
 */
import React, { Component } from "react";
import { Modal, Button } from "antd";
import "./modalLabor.less";
import ReactWEditor from "wangeditor-for-react";
import WangEditor from "../wangEditor";

class modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorContent: this.props?.content,
    };
  }
  /**
   * 关闭
   */
  handleCancel = (value) => {
    this.setState(
      {
        loading: false,
      },
      () => {
        this.props.closeModal();
      }
    );

    // 确认
    if (value) {
      this.props.getValue(this.state.editorContent);
    }
  };

  render() {
    const { visible } = this.props;

    return (
      <>
        <Modal
          className={"modalLabor"}
          open={visible}
          title="服务协议"
          onCancel={() => this.handleCancel(false)}
          width={"60%"}
          centered
          destroyOnClose
          footer={[
            <Button
              key="back"
              onClick={() => this.handleCancel(false)}
              className={"cancelBtn"}
            >
              关闭
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => this.handleCancel(true)}
            >
              确认
            </Button>,
          ]}
        >
          <WangEditor
            defaultValue={this.props?.content}
            changeHtml={(editor) => {
              this.setState({
                editorContent: editor.getHtml(),
              });
            }}
          />
        </Modal>
      </>
    );
  }
}

export default modal;
