/**
 * 报名审核模块路由配置
 */
import ApplyList from '../components/apply/list/list';
import Representative from '../components/apply/representative/list'
import NewContractsList from '../components/apply/newContractsList/list';
import DoctorApprove from '../components/apply/doctorApprove';
import DoctorAudit from '../components/apply/doctorAudit';
import MemberSync from '../components/apply/memberSync';
import AssistantDoctor from '../components/apply/assistant/doctor';
import CareOfficer from '../components/apply/care/officer';
//  import ReprotDetails from '../components/project/detail/projectReport/details/details';     
import tagList from '../components/apply/tag';
import ContentList from '../components/apply/content/list';
import ContentBanner from '../components/apply/content/banner';
const routes = [
    { 'path': '/care/officer', 'component': CareOfficer },
    { 'path': '/assistant/doctor', 'component': AssistantDoctor },
    { 'path': '/apply/list', 'component': ApplyList },
    { 'path': '/apply/representative', 'component': Representative },
    { 'path': '/newContract/list', 'component': NewContractsList },
    { 'path': '/doctorApprove', 'component': DoctorApprove },
    { 'path': '/doctorAudit', 'component': DoctorAudit },
    { 'path': '/memberSync', 'component': MemberSync },
    { 'path': '/apply/tag', 'component': tagList },
    { 'path': '/content/lists', 'component': ContentList },
    { 'path': '/banner/lists', 'component': ContentBanner },
  
    //  {'path': '/project/detail/projectReport/details', 'component': ReprotDetails},
];

export default routes
