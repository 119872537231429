import { requestPostRes } from "../EdcService";

// 签署模板信息
type SignAgreementParams = {
  subjectCode: string;
  agreement: string;
  // 是否是医生签名
  judgment?: number;
}
export const signAgreement = (params: SignAgreementParams, fromDoctor=false)=> {
  return requestPostRes('plat/apply/subjectSign', {
    // 非必填做默认值
    judgment: fromDoctor ? 1 : 2,
    ...params,
  })
}
