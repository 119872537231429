import React from 'react';
import { Button, Select, DatePicker, Space, Table, Modal, Form, Input, Spin, message } from 'antd';
import { GetDesignPlanList, GetTypeList, GetDiseaseList, GetTenantList, CreateDesignPlan } from '../../../services/design/DesignService'
import './index.less'
import locale from 'antd/es/date-picker/locale/zh_CN';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    }
};

class Research extends React.Component {

    state = {
        loading: false,
        disease: "",
        planName: "",
        endDate: "",
        planType: "",
        startDate: "",
        tenantCode: "",
        page: 1,
        pageSize: 10,
        data: [],
        totalRow: 0,
        typeOptions: [],
        diseaseOptions: [],
        tenantOptions: [],
        isModalOpen: false,
        newDisease: ''
    }

    formRef = React.createRef();

    componentDidMount() {
        this.getTypeList()
        this.getDiseaseList()
        this.getTenantList()
        this.getData()
    }


    //获取类型
    getTypeList = () => {
        GetTypeList().then(res => {
            let typeOptions = []
            if (res.code == 0) {
                for (const key in res.data) {
                    typeOptions.push({
                        label: key,
                        value: res.data[key]
                    })
                }
                this.setState({
                    typeOptions
                })
            }
        })
    }

    //获取病种
    getDiseaseList = () => {

        GetDiseaseList().then(res => {
            if (res.code == 0) {
                let diseaseOptions = []
                res.data && res.data.forEach(v => {
                    diseaseOptions.push({
                        label: v,
                        value: v
                    })
                });
                console.log(diseaseOptions);
                this.setState({
                    diseaseOptions
                })
            }
        })
    }

    //获取租户列表
    getTenantList = () => {
        GetTenantList().then(res => {
            let tenantOptions = []
            if (res.code == 0) {
                res.data.forEach(v => {
                    tenantOptions.push({
                        label: v.name,
                        value: v.code
                    })
                });
                this.setState({
                    tenantOptions
                })
            }
        })
    }

    getData = () => {
        let params = {
            page: this.state.page,
            pageSize: this.state.pageSize,
            disease: this.state.disease ? this.state.disease : undefined,
            planType: this.state.planType ? this.state.planType : undefined,
            planName: this.state.planName ? this.state.planName : undefined,
            startDate: this.state.startDate ? this.state.startDate : undefined,
            endDate: this.state.endDate ? this.state.endDate : undefined,
            tenantCode: this.state.tenantCode ? this.state.tenantCode : undefined
        }

        this.setState({ loading: true }, () => {
            GetDesignPlanList(params).then(res => {
                if (res.code == 0) {
                    this.setState({
                        data: res.data.rows,
                        totalRow: res.data.total,
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                }
            })
        })
    }

    changeDate = (date, dateString) => {
        this.setState({
            startDate: dateString[0],
            endDate: dateString[1]
        })
    }

    handleSearch = () => {
        this.getData()
    }


    changePage = (page) => {
        this.setState({ page }, () => {
            this.getData()
        })
    }
    changePageSize = (current, size) => {
        this.setState({ page: 1, pageSize: size }, () => {
            this.getData()
        })
    }

    showModal = () => {
        this.setState({
            isModalOpen: true
        })
    };


    handleOk = () => {
        this.formRef.current.validateFields().then(values => {
            if (!values.disease && !this.state.newDisease) {
                message.error('请完善涉及病种', 2)
                return false
            }

            let disease = values.disease
            if (this.state.newDisease == 'add') {
                disease = values.newDisease
            }

            let params = {
                planName: values.planName,
                planCode: values.planCode,
                planType: values.planType,
                disease: disease,
                tenantCode: values.tenantCode,
                introduction: values.introduction
            }

            CreateDesignPlan(params).then(res => {
                if (res.code == 0) {
                    this.setState({
                        isModalOpen: false,
                        newDisease: ''
                    })
                    this.getData()
                    this.getDiseaseList()
                }
            })
        })
    };
    handleCancel = () => {
        this.setState({
            isModalOpen: false,
            newDisease: ''
        })
    };

    jumpDetail = (data) => {
        this.props.history.push('/system/designPlan/detail/' + data.id)
    }


    render() {
        let { data, diseaseOptions, typeOptions, tenantOptions, isModalOpen, loading } = this.state;
        const columns = [
            {
                title: '方案名称',
                dataIndex: 'name'
            },
            {
                title: '方案编号',
                dataIndex: 'code'
            },
            {
                title: '状态',
                dataIndex: 'planStatus',
                width: 100,
                render: (text, record) => (
                    <div className='planStatus'>
                        <span className={record.planStatus == '设计中' ? 'design' : record.planStatus == '已发布' ? 'release' : ''}>{record.planStatus}</span>
                    </div>
                )
            },
            {
                title: '当前版本',
                dataIndex: 'version'
            },
            {
                title: '方案类型',
                dataIndex: 'planType'
            },
            {
                title: '涉及病种',
                dataIndex: 'disease'
            },
            {
                title: '所属租户',
                dataIndex: 'tenantName'
            },
            {
                title: '上传时间',
                dataIndex: 'createTime'
            },
            {
                title: '创建人',
                dataIndex: 'createUser'
            },
            {
                title: '操作',
                render: (text, record) =>
                    <div >
                        <Button type="link" onClick={() => this.jumpDetail(record)} >
                            查看
                        </Button>
                    </div>
            },
        ];


        const pagination = {
            current: this.state.page,
            pageSize: this.state.pageSize,
            total: this.state.totalRow,
            showSizeChanger: true,
            onChange: this.changePage,
            onShowSizeChange: this.changePageSize
        }


        return (
            <>
                <div className='research_container'>
                    <div className='search'>
                        <div className='search_con'>
                            <div className='search_item'>
                                <span className='label'>租户</span>
                                <Select
                                    showSearch
                                    placeholder="请选择租户"
                                    style={{ width: 260 }}
                                    allowClear
                                    onChange={value => this.setState({ tenantCode: value })}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {tenantOptions.length > 0 && tenantOptions.map(item => (
                                        <Option key={item.value} value={item.value}>{item.label}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='search_item'>
                                <span className='label'>类型</span>
                                <Select
                                    style={{ width: 260 }}
                                    options={typeOptions}
                                    placeholder="请选择类型"
                                    allowClear
                                    onChange={value => this.setState({ planType: value })}
                                />
                            </div>
                            <div className='search_item'>
                                <span className='label'>病种</span>
                                <Select
                                    style={{ width: 260 }}
                                    placeholder="请选择病种"
                                    allowClear
                                    onChange={value => this.setState({ disease: value })}
                                >
                                    {diseaseOptions.length > 0 && diseaseOptions.map(item => (
                                        <Option key={item.value} value={item.value}>{item.label}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='search_item'>
                                <span className='label'>方案名称</span>
                                <Input placeholder="请输入方案名称" allowClear onChange={e => this.setState({ planName: e.target.value })} style={{ width: 260 }} />
                            </div>
                            <div className='search_item'>
                                <span className='label'>创建时间</span>
                                <RangePicker onChange={this.changeDate} locale={locale} style={{ width: 300 }} />
                            </div>
                        </div>
                        <div className='search_btn'>
                            <Button type="primary" style={{ width: 88 }} onClick={this.handleSearch}>查询</Button>
                            <Button type="primary" style={{ width: 88, marginLeft: 20 }} onClick={this.showModal}>新增</Button>
                        </div>
                    </div>

                    <div className='cpds_content'>
                        <Spin tip="数据加载中..." spinning={loading}>
                            <Table rowKey={(record) => record.id} dataSource={data} columns={columns} pagination={pagination} />
                        </Spin>
                    </div>

                </div>
                <Modal
                    title="创建新方案"
                    visible={isModalOpen}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText='确定'
                    cancelText='取消'
                    destroyOnClose
                    width={987}
                >
                    <Form
                        className="creatform"
                        ref={this.formRef}
                        {...formItemLayout}
                    >
                        <Form.Item
                            label="方案名称"
                            name="planName"
                            rules={[{ required: true, message: '请输入方案名称' }]}
                        >
                            <Input placeholder="请输入方案名称" />
                        </Form.Item>
                        <Form.Item
                            label="方案编号"
                            name="planCode"
                            rules={[{ required: true, message: '请输入方案编号' }]}
                        >
                            <Input placeholder="请输入方案编号" suffix={<span style={{ fontSize: 12, color: '#FF0202' }}>编号必须唯一</span>} />
                        </Form.Item>
                        <Form.Item
                            label="方案类型"
                            name="planType"
                            rules={[{ required: true, message: '请选择方案类型' }]}
                        >
                            <Select
                                placeholder='请选择方案类型'
                            >
                                {typeOptions.length > 0 && typeOptions.map(item => (
                                    <Option key={item.value} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label='涉及病种'
                            name="disease"
                            rules={[{ required: true, message: '请选择涉及病种' }]}
                        >
                            <Select placeholder='请选择涉及病种' onChange={(v) => this.setState({ newDisease: v })}  >
                                <Option value='add'>新增病种</Option>
                                {diseaseOptions.length > 0 && diseaseOptions.map(item => (
                                    <Option key={item.value} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {this.state.newDisease == 'add' && <Form.Item
                            label="新病种名称"
                            name="newDisease"
                            rules={[{ required: true, message: '请输入新病种名称' }]}
                        >
                            <Input placeholder="请输入新病种名称" />
                        </Form.Item>}
                        <Form.Item
                            label="所属租户"
                            name="tenantCode"
                            rules={[{ required: true, message: '请选择所属租户' }]}
                        >
                            <Select
                                showSearch
                                placeholder='请选择所属租户'
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {tenantOptions.length > 0 && tenantOptions.map(item => (
                                    <Option key={item.value} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="方案介绍"
                            name="introduction"
                            rules={[
                                { required: true, message: '请输入方案介绍' }]}
                        >
                            <TextArea placeholder="请输入方案介绍" rows={5} />
                        </Form.Item>

                    </Form>
                </Modal>
            </>
        )

    }

}

export default Research