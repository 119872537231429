/**
 * 系统用户服务接口
 */
 import { requestPost, requestGet, requestPostRes } from '../EdcService';

 const urlPrefix = "/plat";
 
/**
 * 获取租户列表
 * @param {} params
 */
 export const getTenantList = (params) => {
     const url = urlPrefix + "/tenant/list";
     return requestPost(url, params);
 };

/**
 * 租户添加
 * @param {} params
 */
export const tenantAdd = (params) => {
    const url = urlPrefix + "/tenant/add";
    return requestPost(url, params);
};

/**
 * 租户修改
 * @param {} params
 */
 export const tenantUpdate = (params) => {
    const url = urlPrefix + "/tenant/update";
    return requestPostRes(url, params);
};
// /tenant/update

/**
 * 租户详情
 * @param {} params
 */
 export const tenantDetail = (params) => {
    const url = urlPrefix + `/tenant/detail?code=${params.code}`;
    return requestPost(url, params);
};

/**
 * 获取方案选择数据
 * @param {} params
 */
 export const getDesignPlanSelects = (params) => {
    const url = "/design/plan/selects/data";
    return requestGet(url, params);
};

/**
 * 分组分页查询
 * @param {} params
 */
 export const getVisitGroupPage = (params) => {
    const url = "/visit/group/page";
    return requestPost(url, params);
};

/**
 * 获取分组详情
 * @param {} params
 */
 export const getVisitGroupDetail = (code) => {
    const url = `/visit/group/detail/${code}`;
    return requestPost(url);
};

/**
 * 适用范围枚举
 * @param {} params
 */
 export const getVisitGroupRangeEnums = (params) => {
    // const url = "/visit/group/range/enums";
    const url = "/visit/group/data/scope/enums";
    return requestPost(url, params);
};

/**
 * 首页模板选择项数据
 * @param {} params
 */
 export const getDesignMainSelects = (params) => {
    const url = "/design/main/selects";
    return requestGet(url, params);
};

/**
 * 分组保存
 * @param {} params
 */
 export const toVisitGroupSave = (params) => {
    const url = "/visit/group/save";
    return requestPost(url, params);
};