/**
 * 重置密码
 */

import React from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { GetChangePassWord } from '../../../services/dct/DctService';
import { Encrypt, Decrypt } from "../../../util/crypto";
import './index.less';

class ChangePwdComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    onFinish = (values) => {
        
        let params = {
            newPass: Encrypt(values.newpassword),
            resetType: 'SUPER',
            userCode: values.userCode,
            verifyPass: Encrypt(values.superpassword)
        }
        
        GetChangePassWord(params).then(res => {
            
            if (res && res.code == 0) {
                message.success('重置成功！')
            } else {
                message.error(res.message)
            }
        })
    };

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    render() {
        return (
            <div className={'ChangePwd_wrap'}>
                <Form
                    name="basic"
                    labelCol={{
                        span: 5,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{width: '50%'}}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    // autoComplete="off"
                    autoComplete={'new-password'}
                >
                    <Form.Item
                        label="用户名"
                        name="userCode"
                        rules={[
                            {
                                required: true,
                                message: '请输入用户名!',
                            },
                        ]}
                    >
                        <Input allowClear />
                    </Form.Item>

                    <Form.Item
                        label="新密码"
                        name="newpassword"
                        rules={[
                            {
                                required: true,
                                message: '请输入新密码!',
                            },
                        ]}
                    >
                        <Input allowClear />
                    </Form.Item>

                    <Form.Item
                        label="超级密码"
                        name="superpassword"
                        rules={[
                            {
                                required: true,
                                message: '请输入超级密码!',
                            },
                        ]}
                    >
                        <Input visibilityToggle={false} allowClear />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 5,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            重置
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }

}

export default ChangePwdComponent;


