import React from 'react';
import { Modal, Button, Upload, message, Tooltip, Table } from 'antd';
import { createFromIconfontCN, UploadOutlined } from '@ant-design/icons';
import { batchRejectFile, GetBatchRejectProcess, GetApplyModuleUrl, batchFourElements, batchFourTemplate } from '../../../services/apply/ApplyService';
import uploading from '../../../static/images/uploading.png'
import './uploadModal.less';
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',
    ],
});

let timeInterval;

class UploadModal extends React.Component {
    state = {
        visible: false,
        noFileTips: false,
        fileList: [],
        uploadStatus: 'before', //上传状态 before pedding success fail
        allCount: 0,
        completeCount: 0,
        percent: '0%',
        errorVoList: [],
        errorCount: 0,
        errorFile: '',
        dataLists: []

    };

    componentDidMount() {
        this.setState({
            visible: this.props.uploadModal
        })
    }

    // 四要素认证图片

    handleFourUpload = async () => {
        const { fileList } = this.state;
        if (fileList.length === 0) {
            this.setState({
                noFileTips: true
            })
            return;
        }
        this.setState({ loading: true })
        const uploadFormData = new FormData();
        fileList.forEach(file => {
            uploadFormData.append('excelFile', file);
        });

        let res = await batchFourElements(uploadFormData);

        if (res) {
            message.success(`上传成功`);
            this.setState({
                fileList: [],
                uploadStatus: 'success',
                dataLists: res || []
            });
        }

    }
    handleUpload = async () => {
        const { fileList } = this.state;
        if (fileList.length === 0) {
            this.setState({
                noFileTips: true
            })
            return;
        }
        this.setState({ loading: true })
        const uploadFormData = new FormData();
        fileList.forEach(file => {
            uploadFormData.append('file', file);
        });


        let res = await batchRejectFile(uploadFormData);

        if (res) {
            message.success(`上传成功`);
            this.setState({
                fileList: [],
                uploadStatus: 'pedding'
            });

            let params = {
                key: res
            }
            timeInterval = setInterval(async () => {
                let resTwo = await GetBatchRejectProcess(params);
                if (resTwo) {
                    if (resTwo.data.completeNum >= resTwo.data.total) {
                        this.setState({
                            uploadStatus: resTwo.data.failNum == 0 ? 'success' : 'fail',
                            errorFile: resTwo.data.errorFile
                        });
                        clearInterval(timeInterval)
                    }
                } else {
                    clearInterval(timeInterval)
                }
            }, 1000)

        }
    };


    closeModal = () => {
        this.setState({ visible: false })
        this.props.closeModal()
    }


    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    downloadErrorFile = () => {
        window.open(this.state.errorFile);
    }



    //下载modal
    downloadModuleUrl = () => {
        if (this.state.fileType == 1) {
            GetApplyModuleUrl().then(res => {
                if (res && res.code == 0) {
                    window.open(res.data);
                }
            })
        } else {
            batchFourTemplate().then(res => {
                console.log(res)
                // 创建一个Blob URL  
                const url = window.URL.createObjectURL(new Blob([res]));

                // 创建一个<a>标签并设置其href属性为Blob URL  
                const a = document.createElement('a');
                a.href = url;
                a.download = '四要素认证模板.xlsx'; // 设置你想要的文件名（包括扩展名）  

                // 触发下载  
                document.body.appendChild(a); // 必须添加到DOM中才能触发点击  
                a.click();

                // 清理（从DOM中移除并撤销Blob URL）  
                setTimeout(() => {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }, 100); // 延迟一点以确保下载开始  
            })
        }

    }


    render() {
        let columns = [
            {
                title: '姓名',
                width: 170,
                render: (text, record) => {
                    return (
                        <div className={'con_div'}>
                            <div className='projectName'>
                                <Tooltip title={record.name}>
                                    {record.name}
                                </Tooltip>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '手机号',
                width: 170,
                render: (text, record) => {
                    return (
                        <div className={'con_div'}>
                            <div className='projectName'>
                                <Tooltip title={record.phone}>
                                    {record.phone}
                                </Tooltip>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '身份证',
                width: 170,
                render: (text, record) => {
                    return (
                        <div className={'con_div'}>
                            <div className='projectName'>
                                <Tooltip title={record.idCard}>
                                    {record.idCard}
                                </Tooltip>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '银行卡号',
                width: 170,
                render: (text, record) => {
                    return (
                        <div className={'con_div'}>
                            <div className='projectName'>
                                <Tooltip title={record.bankNumber}>
                                    {record.bankNumber}
                                </Tooltip>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '四要素认证',
                width: 170,
                render: (text, record) => {
                    return (
                        <div className={'con_div'}>
                            {
                                record.fourResult === 'success' ?
                                    <div className='three_success' >
                                        <span className='icon'></span>
                                        <span>已通过</span>
                                    </div> :
                                    <div className='three_fail'>
                                        <span className='icon'></span>
                                        <span>{
                                            record.elementsFailCode == '1' ? '姓名和手机号不匹配' :
                                                record.elementsFailCode == '2' ? '身份证错误' :
                                                    record.elementsFailCode == '3' ? '银行卡错误' : '未通过'}</span>
                                    </div>
                            }
                        </div>
                    )
                }
            },

            // {
            //     title: '姓名和手机号',
            //     width: 170,
            //     render: (text, record) => {
            //         return (
            //             <div className={'con_div'}>
            //                 {
            //                     record.nameAndPhoneResult === 'fail' ?

            //                         <div className='three_fail'>
            //                             <span>错误</span>
            //                         </div> :
            //                         <div className='three_success' >
            //                             <span className='icon'></span>
            //                             <span>正确</span>
            //                         </div>
            //                 }
            //             </div>
            //         )
            //     }
            // },

            // {
            //     title: '身份证信息',
            //     width: 170,
            //     render: (text, record) => {
            //         return (
            //             <div className={'con_div'}>
            //                 {
            //                     record.nameAndIdCardResult === 'fail' ?

            //                         <div className='three_fail'>
            //                             <span>错误</span>
            //                         </div> :

            //                         <div className='three_success' >
            //                             <span className='icon'></span>
            //                             <span>正确</span>
            //                         </div>
            //                 }
            //             </div>
            //         )
            //     }
            // }
        ]


        let { visible, uploadStatus, dataLists } = this.state

        const props = {
            name: 'file',
            accept: ".xlsx,.xls",
            headers: {
                authorization: 'authorization-text',
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }), () => {

                    if (this.state.fileList.length === 1) {
                        if (this.props.fileType == 1) {
                            this.handleUpload()
                        } else {
                            this.handleFourUpload()
                        }

                    }
                });
                return false
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
        };


        return (
            <div>
                <Modal
                    title={this.props.fileType == 1 ? "批量审核不通过" : '批量四要素认证'}
                    visible={visible}
                    onCancel={this.closeModal}
                    className='uploadModal'
                    footer={null}
                    width={this.state.dataLists.length ? '50%' : '30%'}
                >
                    {uploadStatus == 'before' && <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <div>
                            <Button type="primary" onClick={this.downloadModuleUrl}>
                                下载导入模板
                            </Button>
                        </div>
                        <div>
                            <div className='selectProject'>
                                <Upload {...props}>
                                    <Button type="primary" disabled={this.state.fileList.length > 0}>
                                        导入数据
                                    </Button>
                                </Upload>
                            </div>
                        </div>
                    </div>}

                    {uploadStatus == 'pedding' && <div className='uploading_content'>
                        <div className='uploading_tip'>请耐心等待上传完成</div>
                        <img src={uploading} alt="" />
                    </div>}

                    {
                        (this.props.fileType == 2 && dataLists.length) ?
                            <Table
                                locale={{ emptyText: "暂无数据" }}
                                columns={columns}
                                dataSource={dataLists}
                                pagination={false}
                                expandIconAsCell={false}
                                rowKey={'id'}
                            /> : ''
                    }

                    {uploadStatus == 'success' && <div className='upload_success_content'>
                        <div>
                            <div className='success_tip' style={{ textAlign: 'center' }}>导入完成</div>
                            <Button type="primary" onClick={this.closeModal}>
                                确认
                            </Button>
                        </div>
                    </div>}


                    {uploadStatus == 'fail' && <div className='upload_success_content'>
                        <div>
                            <div className='success_tip' style={{ textAlign: 'center' }}>导入完成</div>
                            <Button type="primary" onClick={this.downloadErrorFile}>
                                下载导入失败数据
                            </Button>
                        </div>
                    </div>}

                </Modal>
            </div>
        );
    }
}

export default UploadModal