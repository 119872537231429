import React from 'react';
import {Tabs} from 'antd';
import './allTenant.less';
import PubSub from "pubsub-js";
import {createFromIconfontCN} from '@ant-design/icons';
import {withRouter} from 'react-router-dom';
import { getTenantList, getDesignPlanSelects, getVisitGroupDetail, getVisitGroupPage } from "../../../services/tenant/Tenant";

// import {GetProjectDetailInfo} from "../../../services/project/ProjectService";

import TenantSetting from "./tenantSetting/index";
import FollowupPatientSetting from "./followupPatientSetting/index";
let _this;
const {TabPane} = Tabs;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_1788044_0dwu4guekcwr.js', // icon-javascript, icon-java, icon-shoppingcart (overrided)
        '//at.alicdn.com/t/font_1788592_a5xf2bdic3u.js', // icon-shoppingcart, icon-python
        '//at.alicdn.com/t/font_542587_l6tst4dbwjq.js', // icon-jingdiananli_wujiaoxing_shoucanghou icon-dingwei
    ],
});
class ProjectDetail extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        // console.log('props.location.state.id', props.location.state.id);
        // sessionStorage.setItem('projectId',props.location.state.id);
        // let currentRole = sessionStorage.getItem('roleCode');

        _this.state = {
            tenantCode: props.location.state.id ? props.location.state.id : '',
            // userRole: '',
            // key: "",
            // projectId: props.location.state.id ? props.location.state.id : '',
            // judgeKeyFlag: true,
            // detailTitle: '项目测试一',
            // detailDate: '2021-12-31',
            // detailTarget: '2000',
            // detailIntroduceCon: '项目介绍',
            // detailProjectStatus: '进行中',
            // detailProjectType: '上市后研究',
            // detailApplicationDraft: '测试草案',
            // detailData: {},
            activeKey: '1', // tabs 切换的key
            groupActiveKey: '1',
            groupData: []
            // shadowFlag: false, // 监听滚动的高度 显隐 阴影
        }
    }

    divRef = React.createRef();

    componentDidMount() {
        // if (_this.props.location.state.id !== '') {
        //     GetProjectDetailInfo(_this.state.projectId).then(res => {
        //         let statusList = '';
        //         if (res.status == "PROCESS") {
        //             statusList = '进行中'
        //         } else if (res.status == "STOP") {
        //             statusList = '已暂停'
        //         } else if (res.status == "END") {
        //             statusList = '已完成'
        //         }
        //         _this.setState({
        //             detailTitle: res.name,
        //             detailDate: res.endTimeVal,
        //             detailTarget: res.groupGoal,
        //             detailIntroduceCon: res.description,
        //             detailProjectStatus: statusList,
        //             detailProjectType: res.typeName,
        //             detailApplicationDraft: res.planName,
        //             detailData: res
        //         })

        //         sessionStorage.setItem('projectCode',res.code);
        //     })
        // } else {
        //     _this.props.history.push("/noConfigPage");
        // }

        // PubSub.subscribe("onProjectSetSave", (subscribeName, projectId) => {
        //     GetProjectDetailInfo(projectId).then(res => {
        //         let statusList = '';
        //         if (res.status == "PROCESS") {
        //             statusList = '进行中'
        //         } else if (res.status == "STOP") {
        //             statusList = '已暂停'
        //         } else if (res.status == "END") {
        //             statusList = '已完成'
        //         }
        //         _this.setState({
        //             activeKey: '1',
        //             judgeKeyFlag: true,
        //             detailTitle: res.name,
        //             detailDate: res.endTimeVal,
        //             detailTarget: res.groupGoal,
        //             detailIntroduceCon: res.description,
        //             detailProjectStatus: statusList,
        //             detailProjectType: res.typeName,
        //             detailApplicationDraft: res.planName,
        //             detailData: res,
        //         })
        //     })
        // });

        // // 定位到项目报表tab页
        // if(_this.props.location.state.activeKey){
        //     this.setState({
        //         activeKey: _this.props.location.state.activeKey
        //     })
        // }
        this.initData();
    }

    initData = () => {
        const param = {
            // "index": 1,
            "page": 1,
            "pageSize": 1000,
            "tenantCode": this.state.tenantCode
          }
        getVisitGroupPage(param).then(res => {
            const _newData = [{}].concat((res.rows || []).reverse())
            this.setState({
                groupData: _newData,
                groupActiveKey: '1',
            });
        })
    }

    updateGroupTabs = () => {
        this.initData();
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName}/>) : undefined;
    }

    /**
     * 选择
     */
    handleChange = (key) => {
        this.setState({
            activeKey: key,
            shadowFlag: false
        })
        if (key == '1') {
            _this.setState({
                judgeKeyFlag: true
            })
        } else {
            _this.setState({
                judgeKeyFlag: false
            })
        }
    }

    grouHandleChange = (key) => {
        this.setState({
            groupActiveKey: key
        })
    }

    /**
     * 监听滚动
     */
    handleOnScroll = () => {
        if (this.divRef.current.scrollTop > 0) {
            this.setState({
                shadowFlag: true
            })
        } else {
            this.setState({
                shadowFlag: false
            })
        }
    }

    onSave = (value) => {
        this.setState({
            activeKey: value
        })
    }

    render() {
        let { activeKey, userRole, groupData, groupActiveKey, tenantCode } = _this.state;
        return (
            <div className={'detail'}>
                {/* 租户一 */}
                <Tabs
                        tabBarStyle={{
                            'color': '#101010',
                        }}
                        tabBarGutter={58}
                        tabPosition={'top'}
                        activeKey={_this.state.activeKey}
                        onChange={_this.handleChange}
                    >
                        <TabPane tab="租户设置" key="1">
                            {_this.state.activeKey == '1' ? <TenantSetting tenantCode={tenantCode} /> : null}
                        </TabPane>
                        <TabPane tab="随访患者分组设置" key="2">
                            {_this.state.activeKey === '2' 
                                ?
                                <Tabs
                                    activeKey={_this.state.groupActiveKey}
                                    onChange={_this.grouHandleChange}
                                >
                                    {
                                        groupData && groupData.map((val, index) => (
                                            <TabPane tab={val.name || '新增'} key={index + 1}>
                                                {
                                                    _this.state.groupActiveKey == (index + 1) &&
                                                    <FollowupPatientSetting {...val} tenantCode={tenantCode} updateGroupTabs={this.updateGroupTabs} />
                                                }
                                            </TabPane>
                                        ))
                                    }
                                    {/* <TabPane tab="分组2" key="2">
                                        123
                                    </TabPane> */}
                                </Tabs>
                                :
                                null
                            }
                        </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default withRouter(ProjectDetail)