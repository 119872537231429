import { GET_TASKS_DATA, GET_SUBJECTDETAIL_DATA, GET_PAGE_STATE } from './actionTypes'

export const TasksDataAction = (tasksData) => ({
    type: GET_TASKS_DATA,
    tasksData,
})

export const SubjectDetailAction = (subjectDetail) => ({
    type: GET_SUBJECTDETAIL_DATA,
    subjectDetail,
})

export const PageStateAction = (pageState) => ({
    type: GET_PAGE_STATE,
    pageState,
})

