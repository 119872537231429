import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {actionCreator} from '../../store';
import './metaManagementEdit.less'
import {Button, Form, Input, Select, message} from "antd";
import {createFromIconfontCN} from "@ant-design/icons";
import {GetDataThemeInfo, GetDataThemeModify} from '../../../../services/config/ConfigService'
import {clearThreeTitle} from '../../../../util/menuModuleUtil';

const layout = {
    labelCol: {
        span: 7,
    },
    wrapperCol: {
        span: 17,
    },
};
const {Option} = Select
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

class metaManagementEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            infoData: {} // 详情 数据集合
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        this.getDataThemeInfo()
    }

    componentWillUnmount() {
        clearThreeTitle()
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName}/>) : undefined;
    }

    /**
     * 详情
     */
    getDataThemeInfo = () => {
        GetDataThemeInfo(this.props.location.state.id).then(res => {
            if (this.formRef && this.formRef.current) {
                this.formRef.current.setFieldsValue({
                    name: res.name,
                    mark: res.mark,
                    category: res.category,
                    scope: res.scope,
                    type: res.type,
                    state: res.state,
                    /*version: res.version*/
                })
            }
        })
    }

    /**
     * 添加 提交表单
     */
    handleFinish = (values) => {
        values.id = this.props.location.state.id;
        values.name = values.name.trim();
        values.mark = values.mark.trim()
        values.category = values.category.trim();
        values.scope = values.scope.trim()
        /*values.version = values.version.trim();*/
        GetDataThemeModify(values).then((res) => {
            if (res.code == 0) {
                message.success('修改成功！')
                this.props.history.push('/config/metaManagement')
            }
        })
    }

    render() {
        return (
            <div className={'metaManagementEdit'}>
                <div className={'metaManagementEditCon'}>
                    <div className={'metaManagementEdit_title'}>修改数据主题</div>
                    <div className={'metaManagementEdit_con'}>
                        <Form
                            {...layout}
                            name="nest-messages"
                            onFinish={this.handleFinish}
                            requiredMark={true}
                            ref={this.formRef}
                        >
                            <Form.Item
                                label="主题名称"
                                name='name'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入主题名称',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input
                                    style={{width: 468, marginLeft: 6}}
                                    placeholder="请输入主题名称"
                                />
                            </Form.Item>

                            <Form.Item
                                label="主题标识"
                                name='mark'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入主题标识',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input
                                    style={{width: 468, marginLeft: 6}}
                                    placeholder="请输入主题标识"
                                />
                            </Form.Item>

                            <Form.Item
                                label="主题类别"
                                name='category'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入主题类别',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input
                                    style={{width: 468, marginLeft: 6}}
                                    placeholder="请输入主题类别"
                                />
                            </Form.Item>

                            <Form.Item
                                label="适用范围"
                                name='scope'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入适用范围',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input
                                    style={{width: 468, marginLeft: 6}}
                                    placeholder="请输入适用范围"
                                />
                            </Form.Item>

                            <Form.Item
                                label="主题类型"
                                name='type'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择主题类型',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='请选择主题类型'
                                    style={{width: 468, marginLeft: 6}}
                                >
                                    {
                                        this.props.typeSelectList.map(v => (
                                            <Option value={v.get('code')}
                                                    key={v.get('code')}>{v.get('name')}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="状态"
                                name='state'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择状态',
                                    },
                                ]}
                            >
                                <Select
                                    onChange={this.handleChange}
                                    placeholder='请选择状态'
                                    style={{width: 468, marginLeft: 6}}
                                >
                                    {
                                        this.props.stateSelectList.map(v => (
                                            <Option value={v.get('code')}
                                                    key={v.get('code')}>{v.get('name')}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                            {/*<Form.Item
                                label="版本号"
                                name='version'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入版本号',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input
                                    style={{width: 468, marginLeft: 6}}
                                    placeholder="请输入版本号"
                                />
                            </Form.Item>*/}

                            <Form.Item
                                wrapperCol={{span: 17, offset: 7}}
                                style={{marginBottom: 0}}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{marginLeft: 410}}
                                >
                                    保存
                                </Button>
                            </Form.Item>

                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    stateSelectList: state.getIn(['config', 'dataThemeStateSelectList']),
    typeSelectList: state.getIn(['config', 'dataThemeTypeSelectList']),
})

const mapDispatchToProps = (dispatch) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(metaManagementEdit);