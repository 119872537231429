import React from "react"
import { Button, Col, Form, Input, Row } from "antd"
import { useEffect, useState } from "react"

import modStyl from './condition.module.scss'
import { LabelListParams } from "../../../../services/knowledge/label"

type ConditionProps = {
  onSearch: (params: LabelListParams)=> void
}

const Condition: React.FC<ConditionProps> = ({ onSearch })=> {
  const [name, setName] = useState<string>('')

  const submitControl = ()=> {
    onSearch({
      name
    })
  }

  const resetControl = ()=> {
    setName('')
    onSearch({
      name: '',
    })
  }

  const colCols = {
    span: 6
  }
  const fieldCols = {
    labelCol: {
      span: 6
    },
    wrapperCol: {
      span: 18
    }
  }

  return (
    <div className={modStyl.wrapper}>
      <Form>
        <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="标签名称">
              <Input onChange={({target})=> setName(target.value)} value={name} placeholder="请输入医助姓名" allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Button onClick={submitControl} type="primary">搜索</Button>
            <Button onClick={resetControl} style={{marginLeft: 10}}>重置</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Condition
