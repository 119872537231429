import { Form, Input, message, Modal, Select } from "antd";
import React, { useEffect, useState } from "react"
import { approveProjectEnume, createAuditDownloadTask } from "../../../services/downloadApprove/DownloadApproveService";

type Props = {
  onClose(refresh: boolean): void;
}

const CreateModal: React.FC<Props> = ({ onClose })=> {

  const [projects, setProjects] = useState<any[]>([])

  const [form] = Form.useForm();

  const fetchProject = async ()=> {
    const data = await approveProjectEnume()
    setProjects(data.map((t: any)=> ({
      label: t.projectName,
      value: t.projectCode,
    })))
  }

  const createTask = async ()=> {
    try {
      const {pwd, projectCode} = await form.validateFields()

      const { code } = await createAuditDownloadTask({
        projectCode,
        pwd,
      })

      if (code == 0) {
        message.success('创建下载任务成功')
        onClose(true)
      }
      
    } catch (error) {
      
    }
  }

  useEffect(()=> {
    fetchProject()
    form.setFieldsValue({
      pwd: '',
      projectCode: '',
    })
  }, [])

  return (
    <Modal onOk={createTask} onCancel={()=> onClose(false)} okText="确认创建" cancelText="取消" title="创建数据下载任务" open={true} destroyOnClose closable>
      <Form form={form}>
        <Form.Item labelCol={{span: 5}} name="projectCode" label="请选择项目" rules={[{required: true, message: '请选择项目'}]}>
          <Select options={projects} placeholder="请选择" allowClear showSearch filterOption={(keyword, { label }: any) => label.includes(keyword)}></Select>
        </Form.Item>
        <Form.Item labelCol={{span: 5}} name="pwd" label="超级密码"rules={[{required: true, message: '请输入超级密码'}]}>
          <Input.Password placeholder="请输入超级密码" allowClear></Input.Password>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateModal