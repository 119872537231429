/**
 * 系统角色服务接口
 */
import { requestPost, requestGet } from '../EdcService';

const urlPrefix = "/plat";


/**
 * 获取所有项目角色
 */
export const getAllRoleList = (params) => {
    const url = urlPrefix + "/role/all/list";
    return requestGet(url, params)
};


/**
 * 角色添加
 * @param {} params
 */
export const addRole = (params) => {
    const url = urlPrefix + "/role/add";
    return requestPost(url, params)
};


/**
 * 角色启用禁用
 * @param {} params
 */
export const editRoleStatus = (params) => {
    const url = urlPrefix + "/role/change/roleStatus?code=" + params.code;
    return requestPost(url, params)
};



/**
 * 角色软删除
 * @param {} params
 */
export const deleteRole = (params) => {
    const url = urlPrefix + "/role/change/status?code=" + params.code;
    return requestPost(url, params)
};


/**
 * 获取角色权限
 * @param {} params
 */
export const getRoleResources = (params) => {
    const url = urlPrefix + "/role/resource/detail?code=" + params.code;
    return requestPost(url, params)
};

/**
 * 角色添加权限
 * @param {} params
 */
export const addRoleResource = (params) => {
    const url = urlPrefix + "/role/resource/add";
    return requestPost(url, params)
};