// 
import { Params } from "../../components/dct/visit/components/Condition";
import { requestGetRes, requestPostRes } from "../EdcService";

type Result<T> = {
  code: number;
  message: string;
  data: T
}
/**
 * 留言列表
 * @param params 
 * @returns 
 */
export const getVisitList = (params: Params, page: number, pageSize: number)=> {
  return requestPostRes('data/visitTask/list', {
    page,
    pageSize,
    ...params
  })
}

// 发送短信
export const sendVisitSMS = (id: number)=> {
  return requestPostRes(`data/visitTask/sendMsg/${id}`)
}

// 完成随访
type FinishTaskParams = {
  visitTaskId: number;
  subjectState: number;
  remark: string;
}
export const finishTask = (params: FinishTaskParams)=> {
  return requestPostRes('data/visitTask/finishTask', params)
}

// 获取当前登录者坐席
export const getAgentUserInfo = <T>(): Promise<Result<T>>=> {
  return requestGetRes('data/visitTask/getSeats') as Promise<Result<T>>
}

// 获取随访记录
export type VisitRecord = {
  createTime: string;
  createUser: null | string;
  id: number;
  isDeleted: number;
  msgContent: null | string;
  recordPath: null | string;
  sinToken: null | string;
  totalDuration: null | number;
  type: number;
  startDate: string;
  endDate: string;
  updateTime: null | string;
  updateUser: null | string;
  visitStatus: number;
  visitTaskId: number;
  visitTime: string;
  visitUser: string;
  visitUserName: string;
}
export const getVisitRecord = (id: number): Promise<Result<VisitRecord[]>>=> {
  return requestGetRes(`/data/visitTask/getVisitRecord/${id}`) as Promise<Result<VisitRecord[]>>
}

// 保存随访记录接口
export const saveVisitRecord = (visitTaskId: number, sinToken: string): Promise<Result<any>>=> {
  return requestPostRes('/data/visitTask/saveVisitRecord', {
    visitTaskId,
    sinToken,
    type: 0,
  })
}
export const finishCall = (sessionId: string): Promise<Result<any>>=> {
  return requestPostRes(`/data/visitTask/finishCall/${sessionId}`)
}