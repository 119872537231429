import './Home.less'

import React from "react";

import Sider from "./components/Sider";
import Chat from '../../chat/Chat';

const Home = () => {
  return (
    <div className='feat-message--layout'>
      <Sider/>
      <Chat subjectCode=''/>
    </div>
  );
};

export default Home;
