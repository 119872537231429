import { requestPostFile, requestPost, requestGet, requestPostRes, requestGetRes, requestPatchRes } from '../EdcService';

/**
* 查询可选项目列表
*/
export const GetCdmsprojectList = (params) => {
    const url = `/cdms/tenantCase/tenant/project/list`;
    return requestGet(url, params)
};

/**
* 查询可选创建人
*/
export const GetCdmsUserList = (params) => {
    const url = `/cdms/tenantCase/tenant/user/list`;
    return requestGet(url, params)
};

/**
* 查询租户病例任务列表 
*/
export const GetCdmsTaskList = (params) => {
    const url = `/cdms/tenantCase/taskList`;
    return requestPost(url, params)
};

/**
* 手动创建病例源文件任务 
*/
export const createCdmsTask = (params) => {
    const url = `/cdms/tenantCase/createTask`;
    return requestPostRes(url, params)
};

/**
* 获取下载病例源链接 
*/
export const downloadCdmsTask = (params) => {
    const url = `/cdms/tenantCase/getUrl`;
    return requestGetRes(url, params)
};

/**
* 回传审核结果 
*/
export const cdmsPostBack = (params) => {
    const url = `/cdms/tenantCase/postBack`;
    return requestPostFile(url, params)
};

/**
* 回传审核结果 
*/
export const getPostBackProcess = (params) => {
    const url = `/cdms/tenantCase/postBackProcess`;
    return requestGet(url, params)
};


/**
* 获取回传文件下载链接 
*/
export const getCdmsPostBackUrl = (params) => {
    const url = `/cdms/tenantCase/getPostBackUrl`;
    return requestGetRes(url, params)
};