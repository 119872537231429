import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {actionCreator} from '../store';
import './metaManagement.less';
import {Form, Row, Col, Input, Button, Select, Table, Popconfirm} from 'antd';
import {
    GetDataThemeDelete,
    DictionariesDelete,
    GetDataThemeSelect,
    GetDataThemeList,
} from '../../../services/config/ConfigService'
import localStorageUtil from "../../../comm/localStorage";

import PubSub from "pubsub-js";
import {createFromIconfontCN} from "@ant-design/icons";

const FormItem = Form.Item;
const {Option} = Select;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2343612_7unhssk7d2o.js',
    ],
});
const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

class metaManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stateSelectList: [], // 数据元 状态下拉集合
            typeSelectList: [], // 数据元 所属类型下拉集合
            state: 'USE',// 数据元 默认状态
            page: 1,// 当前页码数
            /** 接口列表元素*/
            dataThemeList: [], // 数据元 列表
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getList(pageNo);
                },
                current: 1,
                pageSize: 10,
                total: 1,
                showSizeChanger: false,
            },
            columns: [
                {
                    align: 'center',
                    title: '主题名称',
                    key: 'name',
                    width: 120,
                    dataIndex: 'name'
                },
                {
                    align: 'center',
                    title: '主题标识',
                    key: 'mark',
                    width: 120,
                    dataIndex: 'mark'
                },
                {
                    align: 'center',
                    title: '主题类别',
                    key: 'category',
                    width: 120,
                    dataIndex: 'category'
                },
                {
                    align: 'center',
                    title: '适用范围',
                    key: 'scope',
                    width: 120,
                    dataIndex: 'scope'
                },
                {
                    align: 'center',
                    title: '所属类型',
                    key: 'typeName',
                    width: 120,
                    dataIndex: 'typeName'
                },
                {
                    align: 'center',
                    title: '状态',
                    key: 'stateName',
                    width: 120,
                    dataIndex: 'stateName'
                },
                {
                    align: 'center',
                    title: '创建时间',
                    key: 'createTime',
                    width: 180,
                    dataIndex: 'createTime'
                },
                {
                    align: 'center',
                    title: '创建人',
                    key: 'createUser',
                    width: 220,
                    dataIndex: 'createUser',
                    render: (text, item) => {
                        return <div>
                            ({item.createUser}) {item.createUserName}
                        </div>
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    width: 148,
                    fixed: 'right',
                    render: (text, item) => {
                        return (
                            <div className={'tableBtns'}>
                                <div onClick={() => {
                                    this.handleItemDetail(item)
                                }}>
                                    {this.formatIcon('iconchakan')}
                                    查看
                                </div>
                                <div onClick={() => {
                                    this.handleItemEdit(item)
                                }}>
                                    {this.formatIcon('iconbianji')}
                                    编辑
                                </div>
                                <Popconfirm
                                    title="确认删除该条数据?"
                                    okText='确认'
                                    cancelText='取消'
                                    onConfirm={() => {
                                        this.handleItemDelete(item)
                                    }}
                                >
                                    <div className={'del'}>
                                        {this.formatIcon('iconshanchu')}
                                        删除
                                    </div>
                                </Popconfirm>
                            </div>
                        )
                    }
                }
            ], // 表格配置
            tableLoading: false, // 表格 loading
        };
    }

    formRef = React.createRef();

    componentDidMount() {
        this.getList();
        this.getDataThemeSelect()
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName}/>) : undefined;
    }

    /**
     * 获取平台编码集查询集合
     */
    getList = (currentPage) => {
        this.setState({
            tableLoading: true
        })
        let {
            name,
            state,
            type,
            category,
            mark
        } = this.formRef.current.getFieldsValue(['name', 'state', 'type', 'category', 'mark'])
        const params = {
            name: name ? name : null,
            state: state ? state : null,
            type: type ? type : null,
            category: category ? category : null,
            mark: mark ? mark : null,
            page: currentPage ? currentPage : this.state.page,
            pageSize: this.state.pagination.pageSize,
        }
        GetDataThemeList(params).then(res => {
            if (res) {
                this.setState({
                    tableLoading: false,
                    dataThemeList: res.rows,
                    page: res.page,
                    pagination: {
                        ...this.state.pagination,
                        current: res.page,
                        total: res.total,
                    }
                })
            } else {
                this.setState({
                    tableLoading: false
                })
            }
        })
    }

    /**
     * 获取 状态 所属类型 下拉集合
     */
    getDataThemeSelect = () => {
        GetDataThemeSelect().then(res => {
            this.setState({
                stateSelectList: res.stateEnumList,
                typeSelectList: res.typeEnumList,
            }, () => {
                this.props.handleInitSelectList(res.stateEnumList, res.typeEnumList);
            })
        })
    }

    /**
     * 查询提交方法
     * @param fieldsValue
     */
    handleSearch = () => {
        this.getList()
    }

    /**
     * 查看详情方法
     */
    handleItemDetail = (item) => {
        let menuModule = localStorageUtil.get('menuModule', true);
        menuModule.levelThreeTitle = '数据主题详情';
        menuModule.selectMenuUrl = '/config/metaManagement';
        localStorageUtil.set('menuModule', menuModule, true);
        PubSub.publish('onMenuModuleChange', menuModule);
        this.props.history.push({pathname: "/config/metaManagement/detail", state: {id: item.id}});
    }

    /**
     * 编辑方法
     */
    handleItemEdit = (item) => {
        let menuModule = localStorageUtil.get('menuModule', true);
        menuModule.levelThreeTitle = '数据主题编辑';
        menuModule.selectMenuUrl = '/config/metaManagement';
        localStorageUtil.set('menuModule', menuModule, true);
        PubSub.publish('onMenuModuleChange', menuModule);
        this.props.history.push({pathname: "/config/metaManagement/edit", state: {id: item.id}});
    }

    /**
     * 删除方法
     */
    handleItemDelete = (item) => {
        GetDataThemeDelete(item.id).then((res) => {
            if (this.state.dataThemeList.length == 1 && this.state.page != 1) {
                this.getList(this.state.page - 1);
            } else {
                this.getList(this.state.page);
            }
        })
    }

    /**
     * 添加 方法
     */
    handleAddForm = () => {
        let menuModule = localStorageUtil.get('menuModule', true);
        menuModule.levelThreeTitle = '数据主题添加';
        localStorageUtil.set('menuModule', menuModule, true);
        PubSub.publish('onMenuModuleChange', menuModule);
        this.props.history.push('/config/metaManagement/add');
    }

    render() {
        return (
            <div className={'metaManagement'}>
                <div className={'dataThemeForm'}>
                    <Form
                        layout="inline"
                        className={'codeSetForm'}
                        autoComplete="off"
                        ref={this.formRef}
                        onFinish={this.handleSearch}
                    >
                        <FormItem
                            name="name"
                            label="主题名称："
                            /* initialValue={this.state.name}*/
                            rules={[
                                {
                                    whitespace: true,
                                    message: '不能输入空格',
                                }
                            ]}
                        >
                            <Input
                                style={{borderColor: '#EEEFF7'}}
                                placeholder="请输主题名称"
                            />
                        </FormItem>
                        <FormItem
                            name="mark"
                            label="标识："
                            /*initialValue={this.state.mark}*/
                            rules={[
                                {
                                    whitespace: true,
                                    message: '不能输入空格',
                                }
                            ]}
                        >
                            <Input
                                style={{borderColor: '#EEEFF7'}}
                                placeholder="请输入标识"
                            />
                        </FormItem>
                        <FormItem
                            name="category"
                            label="类别："
                            rules={[
                                {
                                    whitespace: true,
                                    message: '不能输入空格',
                                }
                            ]}
                            /*initialValue={this.state.category}*/
                        >
                            <Input
                                style={{borderColor: '#EEEFF7'}}
                                placeholder="请输入类别"
                            />
                        </FormItem>
                        <FormItem
                            name="state"
                            label="状态："
                            style={{paddingRight: 0}}
                            initialValue={this.state.state}
                        >
                            <Select
                                allowClear
                                placeholder="请选择状态"
                                /*style={{width: '100%'}}*/
                            >
                                {
                                    this.state.stateSelectList && this.state.stateSelectList.length != 0 ?
                                        this.state.stateSelectList.map(v => (
                                            <Option
                                                value={v.code}
                                                key={v.code}
                                            >{v.name}</Option>
                                        )) : ''
                                }
                            </Select>
                        </FormItem>
                        <FormItem
                            name="type"
                            label="所属类型："
                            /*initialValue={this.state.type}*/
                            style={{marginBottom: 0}}
                        >
                            <Select
                                allowClear
                                placeholder="请选择所属类型"
                            >
                                {
                                    this.state.typeSelectList && this.state.typeSelectList.length != 0 ?
                                        this.state.typeSelectList.map(v => (
                                            <Option
                                                value={v.code}
                                                key={v.code}
                                            >{v.name}</Option>
                                        )) : ''
                                }
                            </Select>
                        </FormItem>
                        <FormItem
                            style={{width: '75%', textAlign: 'right', marginBottom: 0, paddingRight: 0}}
                            wrapperCol={{span: 24}}
                        >
                            <Button
                                className={'searchBtn'}
                                htmlType="submit"
                                style={{borderRadius: '2px', height: 36, marginRight: 16}}
                            >
                                查询
                            </Button>
                            <Button
                                type="primary"
                                onClick={this.handleAddForm}
                                style={{borderRadius: '2px', height: 36}}
                            >
                                添加
                            </Button>
                        </FormItem>
                    </Form>
                    {/*<div className={'btns'}>
                        <Button
                            className={'searchBtn'}
                            onClick={this.handleSearch}
                            style={{borderRadius: '2px', height: 36, marginRight: 16}}
                        >
                            查询
                        </Button>
                        <Button
                            type="primary"
                            onClick={this.handleAddForm}
                            style={{borderRadius: '2px', height: 36}}
                        >
                            添加
                        </Button>
                    </div>*/}
                </div>
                <Table
                    locale={{emptyText: "暂无数据"}}
                    className={'tableList'}
                    bordered
                    columns={this.state.columns}
                    dataSource={this.state.dataThemeList}
                    pagination={this.state.pagination}
                    rowKey={'id'}
                    scroll={{x: 1550}}
                    loading={this.state.tableLoading}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
    handleInitSelectList(stateSelect, typeSelect) {
        const action = actionCreator.dataThemeSelectListAction(stateSelect, typeSelect);
        dispatch(action)
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(metaManagement);
