import React from "react";
import {
  Tooltip,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Radio,
  message,
  Row,
  Col,
  Image,
  ConfigProvider,
  Spin,
} from "antd";
import {
  getActivity,
  addActivity,
  updateActivity,
  deleteActivity,
  createAuditActivity,
  getActivityUrl,
  listByTenantCodes,
} from "../../../services/activity/ActivityService";
import { GetTenantList } from "../../../services/design/DesignService";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ModalLabor from "./modalLabor/modalLabor";
import "@wangeditor/editor/dist/css/style.css";
import "./list.less";
import locale from "antd/es/date-picker/locale/zh_CN";
import zh_CN from "antd/lib/locale-provider/zh_CN";
import moment from "moment";
import copy from "copy-to-clipboard";
import "moment/locale/zh-cn";
import Photo from "./Photo";
import WangEditor from "./wangEditor";
import TextArea from "antd/lib/input/TextArea";

// 自然人默认协议
const templete2 =
  '<h2 style="text-align: center;">服务协议</h2><p><br></p><p>&nbsp;&nbsp;&nbsp;&nbsp;您好！感谢您参与“&nbsp;${activityName}”，感谢您为本次项目提供的相关服务，本单位将向您支付本次项目的服务费用，相关服务费用将委托第三方公司进行支付，感谢您的辛勤付出！</p><p>&nbsp;&nbsp;&nbsp;&nbsp;为了更好给予您相关支付，请您仔细核对本协议中的相关信息是否正确，确认无误后完成签名。</p><p>&nbsp;&nbsp;&nbsp;&nbsp;感谢您对本次活动的支持。</p><p><br></p><p>开户名：${account}&nbsp;</p><p>开户行名称：${openBank}</p><p>银行账号：${bankNo}</p><p>身份证号码：${cardNo}&nbsp;</p><p><br></p><p>&nbsp;&nbsp;&nbsp;&nbsp;谨请您签名并提交</p><p><br></p><p>此致&nbsp;敬礼</p><p><br></p><p>&nbsp;&nbsp;&nbsp;&nbsp;兹确认同意并接受本协议条款。</p><p><br></p><p>个人签名：${sign}</p><p>手机号：${phone}</p><p><br></p>';
// EDC默认协议
const templete =
  '<h2 style="text-align: center;">专家服务协议</h2><p><br/></p><p>尊敬的专家：</p><p>&nbsp;&nbsp;&nbsp;&nbsp;您好！感谢您参与“&nbsp;${activityName}”，感谢您为本次项目提供的相关服务，本单位将向您支付本次项目的服务报酬，感谢您的辛勤付出！</p><p>&nbsp;&nbsp;&nbsp;&nbsp;为了更好给予您报酬的支付，请您仔细核对本协议中的相关信息是否正确，确认无误后完成签名。</p><p>&nbsp;&nbsp;&nbsp;&nbsp;感谢您对本次活动的支持。</p><p><br/></p><p>开户名：${account}&nbsp;</p><p>开户行名称：${openBank}</p><p>银行账号：${bankNo}</p><p>身份证号码：${cardNo}&nbsp;</p><p><br/></p><p>&nbsp;&nbsp;&nbsp;&nbsp;谨请您签名并提交</p><p><br/></p><p>此致&nbsp;敬礼</p><p><br/></p><p>&nbsp;&nbsp;&nbsp;&nbsp;兹确认同意并接受本协议条款。</p><p><br/></p><p>个人签名：${sign}</p><p>手机号：${phone}</p><p><br/></p>';

class ActivityComponents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editor: null,
      request: null,
      tableLoading: false,
      logo: "",
      laborAgreementContent: "",
      originLaborAgreementContent: "", // 编辑时原本协议内容
      visibleLabor: false,
      tenantOptions: [],
      statusList: [
        { label: "报名中", value: 0 },
        { label: "未开始", value: 1 },
        { label: "已截止", value: 2 },
        { label: "已下架", value: 3 },
      ],
      isUrlModal: false, //获取活动url弹窗
      activityUrl: "",
      isAuditModal: false, //审核账号
      projectList: [],
      formData: {},
      searchName: null,
      tenantCode: "",
      isModalVisible: false,
      dataLists: [], // 列表集合
      page: 1, //当前页码数
      pagination: {
        // 分页组件元素对象信息
        onChange: (pageNo) => {
          this.setState(
            {
              page: pageNo,
            },
            () => {
              this.getPageData();
            }
          );
        },
        current: 1,
        pageSize: 10,
        total: 100,
        showSizeChanger: false,
      },
      isCreated: true,
    };
  }
  formRef = React.createRef();

  componentDidMount() {
    this.getTenantList();
    this.getPageData();
  }

  //
  changeHtml = (editor, key) => {
    // this.setState({
    //   formData: {
    //     ...this.state.formData,
    //     [key]: editor.getHtml(),
    //   },
    // });
    this.formRef.current.setFieldsValue({ [key]: editor.getHtml() });
  };

  //当图片选择改变时
  onPhotoChange = (fileUrl) => {
    this.setState({
      logo: fileUrl || "",
    });
  };

  // 租户选择改变
  handleSearchTenant = (value) => {
    this.setState({
      tenantCode: value,
    });
  };

  handleTenantChange = (value) => {
    if (value) {
      // 获取项目枚举
      listByTenantCodes({ tenantCode: value }).then((res) => {
        this.setState({
          projectList: res.data || [],
        });
        // 切换项目置空
        this.formRef.current.setFieldsValue({
          projectCode: undefined,
        });
      });
    } else {
      this.setState({
        projectList: [],
      });
      // 切换项目置空
      this.formRef.current.setFieldsValue({
        projectCode: undefined,
      });
    }
  };
  closeLaborModal = () => {
    this.setState({
      visibleLabor: false,
    });
  };

  getLaborValue = (v) => {
    this.setState({
      laborAgreementContent: v,
    });
  };

  // 修改服务协议
  modifyLabor = () => {
    console.log(this.state.laborAgreementContent);
    this.setState({
      visibleLabor: true,
    });
  };

  //获取租户列表
  getTenantList = async () => {
    const res = await GetTenantList();
    if (res.code == 0) {
      const tenantOptions = res.data.map((v) => ({
        label: v.name,
        value: v.code,
      }));

      this.setState({
        tenantOptions: tenantOptions,
      });
    }
  };

  onSearch = () => {
    this.setState(
      {
        page: 1,
      },
      () => {
        this.getPageData();
      }
    );
  };
  // 创建活动审核账号
  handleAuditCreate = (params) => {
    // params.tenantCode = this.state.tenantCode;
    if (params.projectCodes) {
      params.projectCodes = params.projectCode.join(",");
    }
    createAuditActivity(params).then((res) => {
      if (res.code === 0) {
        message.success("操作成功");
        this.setState({
          isAuditModal: false,
        });
      }
    });
  };
  handleFieldsChange = (changedFields, allFields) => {
    if (changedFields.length) {
      this.setState({
        formData: {
          ...this.state.formData,
          [changedFields[0].name[0]]: changedFields[0].value,
        },
      });
    }
  };

  // 提交表单
  handleFinish = () => {
    const { laborAgreementContent } = this.state;
    this.formRef.current.validateFields().then((values) => {
      if (values) {
        const { startDate, endDate, activityType } =
          this.formRef.current.getFieldsValue();
        // 合并原本数据的值
        const params = {
          ...this.state.formData,
          ...this.formRef.current.getFieldsValue(),
        };
        // 时间数据预处理
        params.startDate = moment(startDate).format("YYYY-MM-DD");
        params.endDate = moment(endDate).format("YYYY-MM-DD");
        if (params.projectCode) {
          params.projectCode = params.projectCode.join(",");
        }
        // 是否预签服务协议
        if (!params.isPreSign) {
          delete params.laborAgreementContent;
        } else {
          params.laborAgreementContent = laborAgreementContent;
          // 协议内容为空
          if (!laborAgreementContent) {
            params.agreementCode = "";
            params.laborAgreementContent = "";
            //判断协议模板是否为默认模板
          } else if (activityType == 1 && laborAgreementContent == templete) {
            params.agreementCode = "activity4";
          } else if (activityType == 2 && laborAgreementContent == templete2) {
            params.agreementCode = "activity8";
          } else {
            // 当协议不为默认模板时， 创建和更新不为原始值的情况
            if (
              this.state.isCreated ||
              laborAgreementContent !== this.state.originLaborAgreementContent
            ) {
              params.agreementCode = "";
            }
          }
        }

        //图片
        params.logo = this.state.logo;

        (this.state.isCreated
          ? addActivity(params)
          : updateActivity(params)
        ).then((res) => {
          if (res.code === 0) {
            message.success("操作成功");
            this.setState({
              isModalVisible: false,
            });
            this.getPageData();
          }
        });
      }
    });
  };

  // 查询列表
  getPageData = () => {
    this.setState(
      {
        tableLoading: true,
      },
      () => {
        getActivity({
          tenantCode: this.state.tenantCode,
          searchName: this.state.searchName || "",
          page: this.state.page,
          pageSize: this.state.pagination.pageSize,
        }).then((res) => {
          const { code, data } = res;
          if (code === 0) {
            this.setState({
              tableLoading: false,
              dataLists: data.rows,
              page: data.page,
              pageSize: data.pageSize,
              pagination: {
                ...this.state.pagination,
                current: data.page,
                pageSize: data.pageSize,
                total: data.total,
              },
            });
          }
        });
      }
    );
  };
  // 查看活动地址
  getActivityUrl = (row) => {
    getActivityUrl(row.id).then((res) => {
      if (res.code === 0) {
        this.setState({
          activityUrl: res.data,
          isUrlModal: true,
        });
      }
    });
  };
  // 修改活动
  updateData = async (row) => {
    // 获取项目枚举
    const res = await listByTenantCodes({ tenantCode: row.tenantCode });

    let projectCode = [];
    if (row.projectCode) {
      projectCode = row.projectCode.split(",");
    }

    this.setState(
      {
        formData: {
          ...row,
          startDate: row.startDate ? moment(row.startDate, "YYYY-MM-DD") : null,
          endDate: row.endDate ? moment(row.endDate, "YYYY-MM-DD") : null,
          projectCode: projectCode,
        },
        laborAgreementContent: row.laborAgreementContent,
        originLaborAgreementContent: row.laborAgreementContent,
        logo: row.logo,
        isModalVisible: true,
        isCreated: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            projectList: res.data || [],
          });
          this.formRef.current.setFieldsValue({ ...this.state.formData });
        }, 500);
      }
    );
  };

  // 删除活动
  deleteActivity = (row) => {
    Modal.confirm({
      title: "确定删除该活动吗？删除后该活动的数据将会一并被清理",
      icon: <ExclamationCircleOutlined />,
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        deleteActivity(row.id)
          .then((res) => {
            this.setState(
              {
                page: 1,
              },
              () => {
                this.getPageData();
              }
            );
          })
          .catch((err) => {
            message.error(err.message);
          });
      },
    });
  };
  //  下架
  handleShelf = (row) => {
    let params = row;
    params.status = 3;
    updateActivity(params).then((res) => {
      if (res.code === 0) {
        message.success("操作成功");
        this.getPageData();
      }
    });
  };
  render() {
    const {
      dataLists,
      pagination,
      isModalVisible,
      projectList,
      isCreated,
      isAuditModal,
      isUrlModal,
      activityUrl,
      tableLoading,
      tenantOptions,
      laborAgreementContent,
    } = this.state;

    let columns = [
      {
        title: "活动名称",
        width: 170,
        render: (text, record) => {
          return (
            <div>
              <Tooltip title={record.name}>{record.name}</Tooltip>
            </div>
          );
        },
      },
      {
        title: "活动编码",
        width: 170,
        render: (text, record) => {
          return (
            <div>
              <Tooltip title={record.code}>{record.code}</Tooltip>
            </div>
          );
        },
      },
      {
        title: "所属项目",
        width: 170,
        render: (text, record) => {
          return (
            <div>
              <Tooltip title={record.projectName}>{record.projectName}</Tooltip>
            </div>
          );
        },
      },
      {
        title: "引导图",
        width: 100,
        render: (text, record) => {
          return (
            <div>
              {record.logo && (
                <ConfigProvider locale={zh_CN}>
                  <Image width={80} height={46} src={record.logo} />
                </ConfigProvider>
              )}
            </div>
          );
        },
      },
      {
        title: "活动状态",
        width: 100,
        render: (text, record) => {
          return (
            <div className={`status${record.status}`}>
              {record.status == 0
                ? "报名中"
                : record.status == 1
                ? "未开始"
                : record.status == 2
                ? "已截止"
                : "已下架"}
            </div>
          );
        },
      },
      {
        title: "报名开始时间",
        width: 120,
        render: (text, record) => {
          return <div>{record.startDate}</div>;
        },
      },

      {
        title: "报名截止时间",
        width: 120,
        render: (text, record) => {
          return <div>{record.endDate}</div>;
        },
      },

      {
        title: "姓名自动匹配",
        width: 120,
        render: (text, record) => {
          return (
            <div>
              {record.accountNameSyncType === 1
                ? "同步且修改"
                : record.accountNameSyncType === 2
                ? "同步且不修改"
                : "不同步"}
            </div>
          );
        },
      },

      {
        title: "是否人工审核",
        width: 120,
        render: (text, record) => {
          return <div>{record.isBadgeApprove ? "是" : "否"}</div>;
        },
      },
      {
        title: "是否四要素验证",
        width: 120,
        render: (text, record) => {
          return <div>{record.threeElementsApprove ? "是" : "否"}</div>;
        },
      },
      {
        title: "是否自动身份验证",
        width: 120,
        render: (text, record) => {
          return <div>{record.isIdentityApprove ? "是" : "否"}</div>;
        },
      },
      {
        title: "是否开启身份证上传审核",
        width: 120,
        render: (text, record) => {
          return <div>{record.isUploadIdcard ? "是" : "否"}</div>;
        },
      },
      {
        title: "是否预签服务协议",
        width: 120,
        render: (text, record) => {
          return <div>{record.isPreSign ? "是" : "否"}</div>;
        },
      },

      {
        title: "操作",
        width: 300,
        fixed: "right",
        render: (text, record) => (
          <div
            onClick={(eve) => {
              eve.stopPropagation();
            }}
          >
            <Button type="link" onClick={() => this.updateData(record)}>
              查看
            </Button>
            <Button type="link" onClick={() => this.getActivityUrl(record)}>
              活动地址
            </Button>
            <Button type="link" onClick={() => this.deleteActivity(record)}>
              删除
            </Button>
            {record.status !== 3 ? (
              <Button type="link" onClick={() => this.handleShelf(record)}>
                下架
              </Button>
            ) : (
              ""
            )}
          </div>
        ),
      },
    ];
    return (
      <div className="activityList">
        <div className={"activity_wrap"}>
          <div className="top_content">
            <div>
              <Input
                className={"search_wrap"}
                allowClear
                placeholder="请输入活动名称/活动编码搜索"
                onChange={(e) => this.setState({ searchName: e.target.value })}
                style={{ width: 280 }}
              />
              <span>租户：</span>
              <Select
                showSearch
                placeholder="请选择租户"
                style={{ width: 260 }}
                allowClear
                onChange={(value) => this.handleSearchTenant(value)}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {tenantOptions.length > 0 &&
                  tenantOptions.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
              </Select>
              <Button
                className={"btn_search"}
                type="primary"
                onClick={() => this.onSearch()}
              >
                搜索
              </Button>
            </div>

            <div className="right_action">
              <Button
                type="primary"
                onClick={() => {
                  this.setState(
                    {
                      isCreated: true,
                      formData: {
                        isPreSign: true,
                        status: 0,
                        request: null,
                        introduction: null,
                        accountNameSyncType: 2,
                        isBadgeApprove: true,
                        threeElementsApprove: true,
                        isIdentityApprove: true,
                        isUploadIdcard: 0,
                        activityType: 1,
                        isCertificate: 1,
                        isTitleList: true,
                      },
                      laborAgreementContent: templete,
                      isModalVisible: true,
                      projectList: [],
                    },

                    () => {
                    
                      setTimeout(() => {
                        console.log(this.formRef)
                        this.formRef.current.setFieldsValue({
                          ...this.state.formData,
                        });
                      }, 500);
                    }
                  );
                }}
              >
                + 创建活动
              </Button>
            </div>
          </div>
          <br />
          <Spin tip="数据加载中..." spinning={tableLoading}>
            <Table
              locale={{ emptyText: "暂无数据" }}
              columns={columns}
              dataSource={dataLists}
              expandIconAsCell={false}
              pagination={dataLists.length !== 0 && pagination}
              rowKey={"id"}
              scroll={{ x: "calc(700px + 50%)" }}
            />
          </Spin>
        </div>
        <Modal
          title="活动报名地址"
          open={isUrlModal}
          onCancel={() => {
            this.setState({
              isUrlModal: false,
            });
          }}
          onOk={() => {
            copy(activityUrl);
            message.success("复制成功");
            this.setState({
              isUrlModal: false,
            });
          }}
          okText="复制"
          cancelText="关闭"
          width={600}
          destroyOnClose
        >
          <div>{activityUrl}</div>
        </Modal>
        {/* 创建审核账号 */}
        <Modal
          title="创建审核账号"
          open={isAuditModal}
          onCancel={() => {
            this.setState({
              isAuditModal: false,
            });
          }}
          width={600}
          destroyOnClose
          footer={null}
        >
          <Form
            name="basic"
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 16,
            }}
            autoComplete="off"
            onFinish={this.handleAuditCreate}
          >
            <Form.Item
              label="名称"
              name="name"
              rules={[{ required: true, message: "名称不能为空", max: 20 }]}
            >
              <Input placeholder="请输入名称" maxLength={20} />
            </Form.Item>
            <Form.Item
              label="登录账号"
              name="code"
              rules={[{ required: true, message: "登录账号不能为空", max: 20 }]}
            >
              <Input placeholder="请输入登录账号" maxLength={20} />
            </Form.Item>

            <Form.Item
              label="登录密码"
              name="pass"
              rules={[{ required: true, message: "登录密码不能为空", max: 20 }]}
            >
              <Input placeholder="请输入登录密码" maxLength={20} />
            </Form.Item>
            <Form.Item label="关联项目" name="projectCode">
              <Select
                placeholder="请选择所属项目"
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
              >
                {projectList.length > 0 &&
                  projectList.map((project) => (
                    <Select.Option
                      key={project.projectCode}
                      value={project.projectCode}
                    >
                      {project.projectName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="primary"
                ghost
                style={{ marginRight: 10 }}
                onClick={() => this.setState({ isAuditModal: false })}
              >
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                保存
              </Button>
            </div>
          </Form>
        </Modal>
        <Modal
          title={isCreated ? "创建活动" : "修改活动"}
          open={isModalVisible}
          onCancel={() => {
            this.setState({
              isModalVisible: false,
            });
          }}
          width={1300}
          destroyOnClose
          footer={[
            <div>
              <Button
                onClick={() => {
                  this.setState({
                    isModalVisible: false,
                  });
                }}
              >
                取消
              </Button>

              <Button type="primary" onClick={this.handleFinish}>
                {isCreated ? "确认创建" : "保存修改"}
              </Button>
            </div>,
          ]}
        >
          <>
            <Form
              name="basic"
              // labelCol={{
              //   span: 6,
              // }}
              wrapperCol={{
                span: 18,
              }}
              onFieldsChange={this.handleFieldsChange}
              autoComplete="off"
              ref={this.formRef}
            >
              <Row>
                <Col span={12}>
                  <Form.Item
                    label="活动名称"
                    name="name"
                    rules={[{ required: true, message: "活动名称不能为空" }]}
                  >
                    <Input placeholder="请输入活动名称" maxLength={50} />
                  </Form.Item>

                  <Form.Item
                    label="开始日期"
                    name="startDate"
                    rules={[{ required: true, message: "请输入开始日期!" }]}
                  >
                    <DatePicker
                      locale={locale}
                      style={{ width: "100%" }}
                      format={"YYYY-MM-DD"}
                      placeholder="请选择开始日期"
                    />
                  </Form.Item>
                  <Form.Item
                    label="截止日期"
                    name="endDate"
                    rules={[{ required: true, message: "请输入截止日期" }]}
                  >
                    <DatePicker
                      locale={locale}
                      style={{ width: "100%" }}
                      format={"YYYY-MM-DD"}
                      placeholder="请选择截止日期"
                    />
                  </Form.Item>
                  <Form.Item
                    label="租户"
                    name="tenantCode"
                    rules={[{ required: true, message: "请选择租户" }]}
                  >
                    <Select
                      showSearch
                      placeholder="请选择租户"
                      allowClear
                      onChange={(value) => this.handleTenantChange(value)}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {tenantOptions.length > 0 &&
                        tenantOptions.map((item) => (
                          <Select.Option key={item.value} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="所属项目" name="projectCode">
                    <Select
                      placeholder="请选择所属项目"
                      mode="multiple"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                    >
                      {projectList.length > 0 &&
                        projectList.map((project) => (
                          <Select.Option
                            key={project.projectCode}
                            value={project.projectCode}
                          >
                            {project.projectName}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="活动类型" name="activityType">
                    <Radio.Group
                      style={{ width: 468, marginLeft: 14 }}
                      onChange={(e) => {
                        this.setState({
                          laborAgreementContent:
                            e.target.value == 2 ? templete2 : templete,
                        });
                      }}
                    >
                      <Radio value={1}>EDC</Radio>
                      <Radio value={2}>自然人</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label="是否预签服务协议" name="isPreSign">
                    <Radio.Group style={{ width: 468, marginLeft: 14 }}>
                      <Radio value={true}>是</Radio>
                      <Radio value={false}>否</Radio>
                    </Radio.Group>
                  </Form.Item>

                  {this.state.formData.isPreSign && (
                    <Form.Item label="服务协议" name="laborAgreementContent">
                      <>
                        <b>服务协议模板</b>
                        <Button type="link" onClick={() => this.modifyLabor()}>
                          修改
                        </Button>
                      </>
                    </Form.Item>
                  )}
                  <Form.Item label="引导图">
                    <div className="label_upload">
                      <Photo
                        width="50%"
                        logo={this.state.formData.logo}
                        onChange={this.onPhotoChange}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="姓名自动匹配" name="accountNameSyncType">
                    <Radio.Group style={{ width: 468, marginLeft: 14 }}>
                      <Radio value={0}>不同步</Radio>
                      <Radio value={1}>同步且修改</Radio>
                      <Radio value={2}>同步且不修改</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label="是否人工审核" name="isBadgeApprove">
                    <Radio.Group style={{ width: 468, marginLeft: 14 }}>
                      <Radio value={true}>是</Radio>
                      <Radio value={false}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label="是否四要素验证" name="threeElementsApprove">
                    <Radio.Group style={{ width: 468, marginLeft: 14 }}>
                      <Radio value={true}>是</Radio>
                      <Radio value={false}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label="是否自动身份验证" name="isIdentityApprove">
                    <Radio.Group style={{ width: 468, marginLeft: 14 }}>
                      <Radio value={true}>是</Radio>
                      <Radio value={false}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label="是否开启身份证上传审核"
                    name="isUploadIdcard"
                  >
                    <Radio.Group style={{ width: 468, marginLeft: 14 }}>
                      <Radio value={1}>是</Radio>
                      <Radio value={0}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label="是否展示医生资质" name="isCertificate">
                    <Radio.Group style={{ width: 468, marginLeft: 14 }}>
                      <Radio value={1}>是</Radio>
                      <Radio value={0}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label="职称是否展示" name="isTitleList">
                    <Radio.Group style={{ width: 468, marginLeft: 14 }}>
                      <Radio value={true}>是</Radio>
                      <Radio value={false}>否</Radio>
                    </Radio.Group>
                  </Form.Item>

                  {this.state.formData.isTitleList && (
                    <Form.Item label="职称配置" name="titleList">
                      <TextArea
                        placeholder="请输入职称配置，用逗号隔开"
                        maxLength={500}
                        rows={3}
                      />
                    </Form.Item>
                  )}
                  <Form.Item label="科室配置" name="departmentList">
                    <TextArea
                      placeholder="请输入科室配置，用逗号隔开"
                      maxLength={500}
                      rows={3}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label="活动介绍" name="introduction">
                    <WangEditor
                      changeHtml={(editor) =>
                        this.changeHtml(editor, "introduction")
                      }
                      defaultValue={this.state.formData.introduction}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label="活动要求" name="request">
                    <WangEditor
                      changeHtml={(editor) =>
                        this.changeHtml(editor, "request")
                      }
                      defaultValue={this.state.formData.request}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </>
          {/*  服务协议 */}

          <ModalLabor
            visible={this.state.visibleLabor}
            closeModal={this.closeLaborModal}
            content={laborAgreementContent}
            getValue={(v) => this.getLaborValue(v)}
          />
        </Modal>
      </div>
    );
  }
}

export default ActivityComponents;
