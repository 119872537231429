import React from 'react';
import { Button, Select, DatePicker, Space, Table, Modal, Form, Input, Spin, message, Radio, Image, ConfigProvider, Checkbox } from 'antd';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { getCsoDoctorList, getDoctorApproveList, getDoctorApproveListDetail, doctorApproveListOperation, doctorApproveStatistics } from '../../../services/cso/CsoService'
import './index.less'
import locale from 'antd/es/date-picker/locale/zh_CN';
import utils from '../../../util/utils';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import BottomArrow from '../../../static/csoImages/bottom_arrow.png';
import TopArrow from '../../../static/csoImages/bottom_top.png';
import doctor_audit_personal from '../../../static/csoImages/doctor_audit_personal.png';
import doctor_audit_practice from '../../../static/csoImages/doctor_audit_practice.png';
import doctor_certify from '../../../static/csoImages/doctor_certify.png';
import doctor_audit_audit from '../../../static/csoImages/doctor_audit_audit.png';
import certify_no_picture from '../../../static/csoImages/certify_no_picture.png';
import doctor_audit_link from '../../../static/csoImages/doctor_audit_link.png';

import { debounce } from 'lodash';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

let typeOptions = [{
    label: '医生',
    value: '1'
}, {
    label: '药师',
    value: '2'
}, {
    label: '护士',
    value: '3'
}, {
    label: '非医疗专业人士',
    value: '4'
}]

let auditOptions = [{
    label: '已通过',
    value: '1'
}, 
// {
//     label: '审核中',
//     value: '2'
// }, 
{
    label: '未通过',
    value: '3'
}, {
    label: '待审核',
    value: '4'
}]

const identityMap = {
    1: '医生',
    2: '药师',
    3: '护士',
    4: '非医疗专业人士'
}

const checkBoxOptions = [
    { label: '卫建委可查，上传证件姓名与卫建委不一致', value: '卫建委可查，上传证件姓名与卫建委不一致' },
    { label: '卫建委可查，上传证件医院与卫建委不一致', value: '卫建委可查，上传证件医院与卫建委不一致' },
    { label: '卫建委可查，请上传个人证件原件', value: '卫建委可查，请上传个人证件原件' },
    { label: '卫建委不可查，请上传执业证书+个人证件原件上传证件与填写信息不一致', value: '卫建委不可查，请上传执业证书+个人证件原件上传证件与填写信息不一致' },
    { label: '药监局可查，上传证件姓名与药监局不一致', value: '药监局可查，上传证件姓名与药监局不一致' },
    { label: '药监局可查，上传证件执业单位与药监局不一致', value: '药监局可查，上传证件执业单位与药监局不一致' },
    { label: '药监局可查，请上传个人证件原件', value: '药监局可查，请上传个人证件原件' },
    { label: '药监局不可查，请上传执业证书+个人证件原件', value: '药监局不可查，请上传执业证书+个人证件原件' },
    { label: '其他', value: '其他' }
];

class Research extends React.Component {

    constructor(props) {
        super(props);
        this.lastFetchId = 0;
        this.fetchDoctorUser = debounce(this.fetchDoctorUser, 800);
    }

    state = {
        auditOptions: auditOptions,
        typeOptions: typeOptions,
        endDate: "",
        identityType: "",
        state: "",
        startDate: "",
        source: '',
        approveUserName: '',
        page: 1,
        pageSize: 10,
        data: [],
        totalRow: 0,

        fetching: false,

        visible: false,
        visible2: false,
        showDoctorList: true,
        doctorList: [],
        doctorCodeList: [],
        doctorCode: '',
        doctorName: undefined,
        loading: false,
        searchLoading: false,
        auditLoading: false,
        reasonLoading: false,

        arrow1: true, //true是展开 false是折叠
        arrow2: true,
        arrow3: true,
        arrow4: true,
        textArea: '',
        nowRecord: {}, // 当前点击的条目
        personalInfo: {}, // 当前点击的条目
        checkBoxArr: [],
        statistics: {} // 统计数据
    }

    formRef = React.createRef();

    componentDidMount() {
        this.getAuditStastics();
        this.getData();
    }

    getAuditStastics = () => {
        doctorApproveStatistics().then((res) => {
            if (res.code == 0) {
                this.setState({
                    statistics: res.data || {}
                })
            }
        })
    }

    getData = () => {
        this.setState({
            searchLoading: true,
            loading: true
        });
        let params = {
            page: this.state.page,
            pageSize: this.state.pageSize,
            doctorContent: this.state.doctorCode ? this.state.doctorCode : null,
            identityType: this.state.identityType ? this.state.identityType : null,
            state: this.state.state ? this.state.state : null,
            source: this.state.source ? this.state.source : null,
            approveContent: this.state.approveUserName ? this.state.approveUserName : null,
            approveStartTime: this.state.startDate ? this.state.startDate + ' 00:00:00' : null,
            approveEndTime: this.state.endDate ? this.state.endDate + ' 23:59:59' : null,
        }
        getDoctorApproveList(params).then((res) => {
            if (res.code == 0) {
                const data = res.data.rows || [];
                this.setState({
                    data: data,
                    totalRow: res.data.total,
                })
                setTimeout(() => {
                    this.setState({
                        searchLoading: false,
                        loading: false
                    });
                }, 300)
            }

        })
    }

    changeDate = (date, dateString) => {
        this.setState({
            startDate: dateString[0],
            endDate: dateString[1]
        })
    }

    //邀请人查询
    handleSourceChange = event => {
        this.setState({
            source: event.target.value
        })
    };

    //审核人查询
    handleAuditChange = event => {
        this.setState({
            approveUserName: event.target.value
        })
    };

    handleSearch = () => {
        this.setState({
            page: 1
        }, () => {
            this.getData()
        })
    }


    changePage = (page) => {
        this.setState({ page }, () => {
            this.getData()
        })
    }
    changePageSize = (current, size) => {
        this.setState({ page: 1, pageSize: size }, () => {
            this.getData()
        })
    }

    //医生下拉框改变
    handleDoctorChange = data => {
        this.setState({
            doctorCode: data ? data.value : null,
            doctorList: [],
            fetching: false,
        });
    };

    fetchDoctorUser = value => {
        if (!value) {
            this.setState({ doctorList: [], fetching: false });
            return;
        }
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ doctorList: [], fetching: true });

        let params = {
            page: 1,
            pageSize: 100,
            content: value
        }

        getCsoDoctorList(params).then(res => {
            if (fetchId !== this.lastFetchId) {
                // for fetch callback order
                return;
            }
            const data = res.rows.map(user => ({
                text: user.name,
                value: user.code
            }));
            const listData = res.rows.map(user => ({
                text: user.code,
                value: user.code
            }));
            this.setState({ doctorList: data, doctorCodeList: listData, fetching: false });
        });
    }

    jumpDetail = (data) => {
        this.setState({ nowRecord: data})
        getDoctorApproveListDetail({
            id: data.id
        }).then(res => {
            if (res.code == 0) {
                this.setState({
                    personalInfo: res.data
                })
            }
        })
        this.setState({
            visible: true
        })
    }

    checkBoxChange = (checkedValues) => {
        this.setState({
            checkBoxArr: checkedValues
        });
    };

    textAreaOnChange = (e) => {
        const targetValue = [];
        targetValue['textArea'] = e.target.value;
        this.setState(targetValue)
    };

    handleSubmit = () => {
        this.auditConfirm();
    }

    handerReject = () => {
        this.setState({
            visible: false,
            visible2: true
        });
    }

    cancelReasonConfirm = () => {
        let { nowRecord, checkBoxArr, textArea } = this.state;
        if (checkBoxArr.length == 0) {
            message.info('请选择拒绝原因');
            return;
        }
        if (checkBoxArr[checkBoxArr.length - 1] == '其他') {
            if (!textArea) {
                message.info('请输入其他对应的拒绝原因');
                return;
            } else {
                checkBoxArr[checkBoxArr.length - 1] = textArea;
            }
        }
        this.setState({
            reasonLoading: true
        })
        doctorApproveListOperation({
            approveId: nowRecord.id,
            approveRemark: checkBoxArr.join('、'),
            state: 3
        }).then((res) => {
            if (res.code == 0) {
                message.success('操作成功');
                this.getData();
                this.getAuditStastics();
                setTimeout(() => {
                    this.setState({
                        visible2: false,
                        reasonLoading: false
                    })
                }, 300)
            }
        })
    }

    auditConfirm = () => {
        let { nowRecord } = this.state;
        this.setState({
            auditLoading: true
        })
        doctorApproveListOperation({
            approveId: nowRecord.id,
            state: 1
        }).then((res) => {
            if (res.code == 0) {
                message.success('操作成功');
                this.getData();
                this.getAuditStastics();
                setTimeout(() => {
                    this.setState({
                        visible: false,
                        auditLoading: false
                    })
                }, 300)
            }
        })
    }

    render() {
        let { data, typeOptions, auditOptions, visible, doctorName, fetching, doctorList, showDoctorList, doctorCodeList, statistics, loading,
            arrow1, arrow2, arrow3, arrow4, source, approveUserName, searchLoading, reasonLoading, auditLoading,
            visible2, personalInfo } = this.state;
        const columns = [
            {
                title: '姓名 / 手机号',
                width: 130,
                render: (text, record) =>
                <div>
                    <div>
                        {record.doctorName}
                    </div>
                    <div className='gray'>
                        {record.doctorCode ? record.doctorCode.replace( /(\d{3})(\d{4})(\d{4})/, "$1 $2 $3") : ''}
                    </div>
                </div>
            },
            {
                title: '身份',
                width: 120,
                dataIndex: 'identityName',
                render: (text, record) =>
                <div>
                    {identityMap[record.identityType]}
                </div>
            },
            {
                title: '医院 / 科室 / 职称',
                render: (text, record) =>
                <div>
                    <div>
                        {record.hospital}
                    </div>
                    <div>
                    {record.institution}{record.dept ? ' / ' + record.dept : ''}{record.title ? ' / ' + record.title : ''}
                    </div>
                </div>
            },
            {
                title: '实名校验',
                width: 88,
                render: (text, record) =>
                <div className='name_verify'>
                    {
                        record.phoneApprove ?
                        <CheckCircleFilled style={{'color': '#00B011', 'fontSize': '20px'}} />
                        :
                        <CloseCircleFilled style={{'color': 'red', 'fontSize': '20px'}} />
                    }
                </div>
            },
            {
                title: '身份认证',
                width: 100,
                render: (text, record) =>
                <div className={`role_verify ${record.state == 1 ? 'pass' : (record.state == 2 || record.state == 3) ? 'no_pass' : record.state == 4 ? 'to_audit' : 'pass'  }`}>
                    {
                        record.state == 1 ? '已通过' : 
                        (record.state == 2) ? '审核中' :
                        (record.state == 3) ? '未通过' :
                        (record.state == 4) ? '待审核' : ''
                    }
                </div>
            },
            {
                title: '提交时间',
                width: 150,
                render: (text, record) =>
                <div>
                    { record.submitTime ? record.submitTime.replace('T', ' ') : '' }
                </div>
            },
            {
                title: '拒绝原因',
                dataIndex: 'approveRemark'
            },
            {
                title: '邀请人',
                dataIndex: 'source'
            },
            {
                title: '审核人',
                dataIndex: 'approveUserName'
            },
            {
                title: '审核时间',
                width: 150,
                render: (text, record) =>
                <div>
                    { record.approveTime ? record.approveTime.replace('T', ' ') : '' }
                </div>
            },
            {
                title: '操作',
                fixed: 'right',
                width: 80,
                render: (text, record) =>
                    <div >
                        <Button style={{ padding: 0, marginRight: '10px' }} type="link" onClick={() => this.jumpDetail(record)} >
                            查看详情
                        </Button>
                    </div>
            },
        ];


        const pagination = {
            current: this.state.page,
            pageSize: this.state.pageSize,
            total: this.state.totalRow,
            showSizeChanger: true,
            onChange: this.changePage,
            onShowSizeChange: this.changePageSize
        }


        return (
            <>
                <div className='cso_doctor_audit_research_container'>
                    <div className='introduce'>
                        <div>
                            <div>审核总数</div>
                            <div>{ statistics.allNum ? utils.numberAddComma(statistics.allNum) : 0 }</div>
                        </div>
                        <div>
                            <div>已通过</div>
                            <div>{ statistics.allPassNum ?  utils.numberAddComma(statistics.allPassNum) : 0 }</div>
                        </div>
                        <div>
                            <div>已拒绝</div>
                            <div>{ statistics.allFailNum  ?  utils.numberAddComma(statistics.allFailNum) : 0 }</div>
                        </div>
                        <div>
                            <div>待审核</div>
                            <div>{ statistics.allWaitNum ?  utils.numberAddComma(statistics.allWaitNum) : 0 }</div>
                        </div>
                        <div>
                            <div>今日通过</div>
                            <div>{ statistics.todayPassNum ? utils.numberAddComma(statistics.todayPassNum) : 0 }</div>
                        </div>
                        <div>
                            <div>今日拒绝</div>
                            <div>{ statistics.todayFailNum ? utils.numberAddComma(statistics.todayFailNum) : 0 }</div>
                        </div>
                    </div>
                    <div className='gap'></div>
                    <div className='search'>
                        <div className='search_con'>
                            <div className='search_item'>
                                <span className='label'>审核状态</span>
                                <Select
                                    showSearch
                                    placeholder="请选择状态"
                                    style={{ width: 280 }}
                                    options={auditOptions}
                                    allowClear
                                    onChange={value => this.setState({ state: value })}
                                >
                                </Select>
                            </div>
                            <div className='search_item'>
                                <span className='label'>身份</span>
                                <Select
                                    style={{ width: 280 }}
                                    options={typeOptions}
                                    placeholder="请选择身份类型"
                                    allowClear
                                    onChange={value => this.setState({ identityType: value })}
                                />
                            </div>
                            <div className='search_item'>
                                <span className='label'>邀请人</span>
                                <Input
                                    allowClear
                                    style={{ width: 280 }}
                                    value={source}
                                    placeholder="请输入编号"
                                    onChange={this.handleSourceChange}
                                >
                                </Input>
                            </div>
                            <div className='search_item'>
                                <span className='label'>搜索医生</span>
                                <Select
                                    allowClear
                                    showSearch
                                    style={{ width: 280 }}
                                    labelInValue
                                    value={doctorName}
                                    placeholder="请输入医生姓名搜索"
                                    notFoundContent={fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onSearch={this.fetchDoctorUser}
                                    onChange={this.handleDoctorChange}
                                >
                                    {(doctorList || []).map(d => (
                                        <Option key={d.value} value={d.value}>{d.text}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='search_item'>
                                <span className='label'>审核时间</span>
                                <RangePicker onChange={this.changeDate} locale={locale} style={{ width: 280 }} />
                            </div>
                            <div className='search_item'>
                                <span className='label'>审核人搜索</span>
                                <Input
                                    allowClear
                                    style={{ width: 280 }}
                                    value={approveUserName}
                                    placeholder="请输入姓名/账号搜索"
                                    onChange={this.handleAuditChange}
                                >
                                </Input>
                            </div>

                        </div>
                        <div className='search_btn'>
                            <Button type="primary" style={{ width: 88 }} loading={searchLoading} onClick={this.handleSearch}>查询</Button>
                        </div>
                    </div>

                    <div className='cpds_content'>
                        <Spin tip="数据加载中..." spinning={loading}>
                            <Table rowKey={(record) => record.id} dataSource={data} columns={columns} pagination={pagination} scroll={{ x: 'calc(700px + 50%)' }} />
                        </Spin>
                    </div>

                </div>



                <Modal
                    visible={visible}
                    title=''
                    closable={true}
                    onCancel={() => { this.setState({ visible: false }); this.detailTimeOut && clearTimeout(this.detailTimeOut); }}
                    destroyOnClose
                    width={950}
                    className='cso_doctor_audit_resource_modal'
                    onOk={this.handleSubmit}
                    footer={
                        <div className='modal_footer'>
                            {
                                personalInfo.identityType != 4 ?
                                <div className='footer_left'> 
                                    <img src={doctor_audit_link} alt='' />
                                    {
                                        (personalInfo.identityType == 1 || personalInfo.identityType == 3) &&
                                        <a style={{color: '#3391F0'}} href='http://www.nhc.gov.cn' target='_blank' rel='noreferrer'>前往卫健委网站验证信息</a>
                                    }
                                    {
                                        personalInfo.identityType == 2 &&
                                        <a style={{color: '#3391F0'}} href='http://zyys.nmpa.gov.cn/commonweb.action?method=previewsysportal&unid=J8f4e08183bc4b82af128e57a0a33da0&appunid=J5b9d21d6e744c3fa925985bb6178015' target='_blank' rel='noreferrer' >执业药师信息服务门户</a>
                                    }
                                </div>
                                :
                                <div className='footer_left'> 
                                </div>
                            }
                            {
                                personalInfo.state !== 3 &&
                                <Button danger onClick={this.handerReject} >拒绝</Button>
                            }
                            {
                                personalInfo.state !== 1 &&
                                <Button type='primary' loading={auditLoading} onClick={this.handleSubmit}>通过</Button>
                            }
                        </div>
                    }
                    okText="通过"
                    cancelText="拒绝"
                >
                    <div className='modal_content'>
                        <div className='menu'>
                            <div className='title'>
                                <div className='title_left'>
                                    <img src={doctor_audit_personal} alt="" />
                                    <span>个人信息</span>
                                </div>
                                <img
                                    className='pointer'
                                    src={arrow1 ? TopArrow : BottomArrow}
                                    onClick={() => this.setState({ arrow1: !this.state.arrow1 })}
                                    alt=""
                                />
                            </div>
                            <div className='menu_content' hidden={!arrow1}>
                                <div className='info_item'>
                                    <div className='info_label text_right required'>姓名</div>
                                    <div className='info'>{personalInfo.name ? personalInfo.name : <span className='no-data'>暂无信息</span>}</div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label text_right'>手机号</div>
                                    <div className='info'>{personalInfo.code ? personalInfo.code : <span className='no-data'>暂无信息</span>}</div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label text_right required'>省/市/区</div>
                                    <div className='info'>{personalInfo.province ? (personalInfo.province + '' + (personalInfo.city || '')) : <span className='no-data'>暂无信息</span>}</div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label text_right required'>提交时间</div>
                                    <div className='info'>{personalInfo.submitTime ? (personalInfo?.submitTime.replace('T', ' ')) : <span className='no-data'>暂无信息</span>}</div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label text_right'>实名认证</div>
                                    <div className='info'>{personalInfo.phoneApprove ? '已认证' : '未认证'}</div>
                                </div>
                            </div>
                        </div>
                        <div className='menu'>
                            <div className='title'>
                                <div className='title_left'>
                                    <img src={doctor_audit_practice} alt="" />
                                    <span>执业信息</span>
                                </div>
                                <img
                                    className='pointer'
                                    src={arrow2 ? TopArrow : BottomArrow}
                                    onClick={() => this.setState({ arrow2: !this.state.arrow2 })}
                                    alt=""
                                />
                            </div>
                            <div className='menu_content'>
                                {
                                    (personalInfo.identityType == 1 || personalInfo.identityType == 3 || !personalInfo.identityType)
                                    &&
                                    <>
                                        <div className='info_item'>
                                            <div className='info_label text_right required'>医院</div>
                                            <div className='info'>{personalInfo.institution ? personalInfo.institution : <span className='no-data'>暂无信息</span>}</div>
                                        </div>
                                        <div className='info_item' style={{ visibility: 'hidden' }}>
                                            <div className='info_label text_right'></div>
                                            <div className='info'></div>
                                        </div>
                                        <div className='info_item'>
                                            <div className='info_label text_right'>科室</div>
                                            <div className='info'>{personalInfo.dept ? personalInfo.dept : <span className='no-data'>暂无信息</span>}</div>
                                        </div>
                                        <div className='info_item'>
                                            <div className='info_label text_right'>职称</div>
                                            <div className='info'>{personalInfo.title ? personalInfo.title : <span className='no-data'>暂无信息</span>}</div>
                                        </div>
                                    </>
                                }
                                {
                                    personalInfo.identityType == 2
                                    &&
                                    <>
                                        <div className='info_item'>
                                            <div className='info_label text_right required'>执业单位</div>
                                            <div className='info'>{personalInfo.practiceUnit ? personalInfo.practiceUnit : <span className='no-data'>暂无信息</span>}</div>
                                        </div>
                                        <div className='info_item'>
                                            <div className='info_label text_right'>执业类别</div>
                                            <div className='info'>{personalInfo.practiceType ? personalInfo.practiceType : <span className='no-data'>暂无信息</span>}</div>
                                        </div>
                                        <div className='info_item'>
                                            <div className='info_label text_right'>执业范围</div>
                                            <div className='info'>{personalInfo.practiceScope ? personalInfo.practiceScope : <span className='no-data'>暂无信息</span>}</div>
                                        </div>
                                        <div className='info_item'>
                                            <div className='info_label text_right'>药师职称</div>
                                            <div className='info'>{personalInfo.title ? personalInfo.title : <span className='no-data'>暂无信息</span>}</div>
                                        </div>
                                    </>
                                }
                                {
                                    personalInfo.identityType == 4
                                    &&
                                    <>
                                        <div className='info_item'>
                                            <div className='info_label text_right required'>身份证</div>
                                            <div className='info'>{personalInfo.idCard ? personalInfo.idCard.replace(/\d{14}(\d{4})/, "**************$1") : <span className='no-data'>暂无信息</span>}</div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className='menu'>
                            <div className='title'>
                                <div className='title_left'>
                                    <img src={doctor_certify} alt="" />
                                    <span>证件信息</span>
                                </div>
                                <img
                                    className='pointer'
                                    src={arrow3 ? TopArrow : BottomArrow}
                                    onClick={() => this.setState({ arrow3: !this.state.arrow3 })}
                                    alt=""
                                />
                            </div>
                            <div className='menu_content practice' hidden={!arrow3}>
                                <div className='info_item'>
                                    <div className='info' style={{ marginLeft: '42px'}}>
                                        {
                                            !personalInfo.certificate &&
                                            <div style={{height: '100px'}}>
                                                <img height={'100%'}  src={certify_no_picture} alt=''  />
                                            </div>
                                        }
                                        {
                                            personalInfo.certificate && (personalInfo.certificate.split(';') || []).map((val, index) => 
                                            (<div key={index} style={{height: '100px', display: 'inline-block', marginRight: '20px', marginTop: '16px'}}>
                                                {
                                                    val ?
                                                    <ConfigProvider locale={zh_CN}>
                                                        <Image src={val} height={'100%'} />
                                                    </ConfigProvider>
                                                    :
                                                    ''
                                                }
                                            </div>))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='menu'>
                            <div className='title'>
                                <div className='title_left'>
                                    <img src={doctor_audit_audit} alt="" />
                                    <span>审核详情</span>
                                </div>
                                <img
                                    className='pointer'
                                    src={arrow4 ? TopArrow : BottomArrow}
                                    onClick={() => this.setState({ arrow4: !this.state.arrow4 })}
                                    alt=""
                                />
                            </div>
                            <div className='menu_content practice' hidden={!arrow4}>
                                <div className='info_item'>
                                    <div className='info_label text_right'>审核结果</div>
                                    <div className={`info ${personalInfo?.state == 1 ? 'pass' : (personalInfo?.state == 2 || personalInfo?.state == 3) ? 'no_pass' : personalInfo?.state == 4 ? 'to_audit' : ''}`}>
                                        {
                                            personalInfo.state == 1 ? '已通过' : 
                                            (personalInfo.state == 2) ? '审核中' :
                                            (personalInfo.state == 3) ? '未通过' :
                                            personalInfo.state == 4 ? '待审核' : <span className='no-data'>暂无信息</span>
                                        }
                                    
                                    </div>
                                </div>
                                <div className='info_item '>
                                    <div className='info_label text_right'>审核人</div>
                                    <div className='info'>{personalInfo.approveUserName ? personalInfo.approveUserName : <span className='no-data'>暂无信息</span>}</div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label text_right'>审核时间</div>
                                    <div className='info'>{personalInfo.approveTime ? personalInfo.approveTime.replace('T', ' ') : <span className='no-data'>暂无信息</span>}</div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label text_right'>拒绝原因</div>
                                    <div className='info'>{personalInfo.approveRemark ? personalInfo.approveRemark : <span className='no-data'>暂无信息</span>}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>

                <Modal
                    visible={visible2}
                    title=''
                    closable={true}
                    onCancel={() => this.setState({ visible2: false })}
                    destroyOnClose
                    centered
                    width={950}
                    className='cso_doctor_audit_order_record_modal_wrap'
                    footer={
                    <div className='modal_footer'>
                        <Button type='primary' ghost onClick={() => { this.setState({ visible: true, visible2: false }); }}>取消</Button>
                        <Button type='primary' loading={reasonLoading} onClick={() => {this.cancelReasonConfirm() }}>确认</Button>
                    </div>}
                    okText="确定"
                    cancelText="取消"
                >
                    <div className='modal_content'>
                        <div className='title'>
                            <span className='title_text'>请选择审核不通过理由</span>
                        </div>
                        <div className='item'>
                            <Checkbox.Group className='check_group' options={checkBoxOptions} onChange={this.checkBoxChange} />
                        </div>
                        {
                            this.state.checkBoxArr.find((val) => val == '其他') &&
                            <div className='item'>
                                <TextArea className='item_right' onChange={(e) => { this.textAreaOnChange(e) }}  style={{ resize: 'none' }} placeholder="请输入拒绝理由" maxLength={500} ></TextArea>
                            </div>
                        }

                    </div>
                </Modal>
            </>
        )

    }

}

export default Research