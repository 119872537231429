import { requestPostRes } from "../EdcService";

// 石药多恩达自定义表单 - 添加表单
type Payload = {
  visitId: number
  templateId: number
  name: string
}
export const addCustomForm = ({visitId, ...data}: Payload)=> {
  return requestPostRes(`data/form/add/${visitId}`, data)
}