/**
 * 报名审核
 */
import React from 'react';
import './index.less';
import { withRouter } from 'react-router-dom';
import {
    Input, Button, message as Message, Table, message, Modal, Image, ConfigProvider
} from "antd";
import { PaperClipOutlined } from '@ant-design/icons';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import {
    getRegisterlist, getRegisterDetail, handleRegister
} from "../../../services/visit/Visit";

import ReasonModal from "./modal/modal";



let _this;
const { Search } = Input;


let searchName = ''

class ApplylistsComponent extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            dataLists: [], // 列表集合
            columns: [
                {
                    title: '姓名',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.name}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '报名项目',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.groupName}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '申请时间',
                    width: '',
                    render: (text, record, index) => {
                        if (record.createTime) {
                            return (
                                <div className={'con_div'}>
                                    {record.createTime ? record.createTime.replace('T', ' ') : ''}
                                </div>
                            )
                        }
                    }
                },
                {
                    title: '用户类型',
                    width: '',
                    render: (text, record, index) => (
                        <div className={'con_div'}>
                            {record.userType == 'doctorUser' ? '医生' : '医助'}
                        </div>
                    )
                },
                {
                    title: '状态',
                    width: '',
                    render: (text, record, index) => (
                        <div className={'con_div'}>
                            {record.state == '1' ? '未审核' : record.state == '2' ? '已通过' : record.state == '3' ? '未通过' : ''}
                        </div>
                    )
                },
                {
                    title: '操作',
                    width: '',
                    render: (text, record) =>
                        <div
                            onClick={eve => {
                                eve.stopPropagation();
                            }}
                            className={'partPop'}
                        >
                            <Button type="link" onClick={() => this.onDetails(record.id, record.approveStatus, record.identificationStatus)} >
                                查看详情
                            </Button>
                        </div>
                },
            ],// 表格 行 配置
            page: 1,//当前页码数
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo, searchName);
                },
                current: 1,
                pageSize: 10,
                total: 100,
                showSizeChanger: false,
            },

            isModalVisible: false, // 信息详情
            detailsData: {}, // 详情数据
            showFooter: false, // 是否显示footer
            applyState: '', // 报名状态
            idStatus: '', // 医生身份认证状态
            applyId: '', // 审核id
            reasonVisible: false, // 拒绝原因模态框
            reasonType: '', // 拒绝原因类型
        }
        _this.activeKey = 0
        this.timer = null
    }

    componentDidMount() {
        _this.getPageData(); // 默认展示月报详情
    }



    /**
     * 查看详情
     */
    onDetails = async (id) => {
        const params = {
            id
        }
        const res = await getRegisterDetail(params);
        if (res && res.code == 0) {
            this.setState({
                detailsData: res.data ? res.data : {},
                showFooter: res.data.state == '1' ? true : false,
                applyId: id,
                isModalVisible: true
            })
        }
    }

    /**
     * 获取列表数据 
     */
    getPageData = async (curPage, name) => {
        let { page } = this.state;
        const params = {
            page: curPage ? curPage : page,
            pageSize: this.state.pagination.pageSize
        }

        const res = await getRegisterlist(params);
        if (res && res.code == 0) {
            _this.setState({
                dataLists: [...res.data.rows],
                page: res.data.page,
                pageSize: res.data.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })
        }
    }


    // 同意申请
    handleOk = () => {
        let params = {
            id: this.state.applyId,
            state: 'Pass'
        }

        handleRegister(params).then(res => {
            if (res.code == 0) {
                this.setState({
                    isModalVisible: false,
                    reasonVisible: false
                })
                this.getPageData()
            }
        })

    }
    // 取消
    handleCancel = async () => {
        this.setState({
            reasonVisible: true,
            reasonType: 'isdoctor'
        })

    }

    // 签名不合格提交
    handleSignCancel = () => {
        this.setState({
            reasonVisible: true,
            reasonType: 'sign'
        });
    }

    // 搜索
    onSearch = (v) => {
        searchName = v
        this.getPageData(1, v)
    }

    // 清除
    onSearchChange = (v) => {
        searchName = ''
        this.getPageData()
    }

    // 前往卫健委网站验证
    onLink = (v) => {
        let url = 'http://zgcx.nhc.gov.cn:9090/doctor'
        window.open(url, '_blank')
    }

    // 签名不合格/非医生
    closeReasonModal = () => {
        this.setState({
            reasonVisible: false
        });
    }

    // 原因
    getRejectReason = (value) => {
        let params = {
            id: this.state.applyId,
            state: 'Fail',
            failReasons: value
        }

        handleRegister(params).then(res => {
            if (res.code == 0) {
                this.setState({
                    isModalVisible: false,
                    reasonVisible: false
                })
                this.getPageData()
            }
        })
    }

    render() {
        let { columns, dataLists, pagination, isModalVisible, detailsData, reasonVisible, reasonType, showFooter } = this.state;
        // 模态框内容 
        const detailsInfo = [{
            title: '个人信息',
            content: [
                { type: 'text', label: '姓名', defaultValue: detailsData.name ? detailsData.name : '' },
                { type: 'text', label: '手机号', defaultValue: detailsData.createUser ? detailsData.createUser : '' },
                { type: 'DatePicker', label: '申请时间', defaultValue: detailsData.createTime ? detailsData.createTime.substring(0, 10) : '' },
            ]
        }, {
            title: '医院信息',
            content: [
                { type: 'select', label: '所在省份', defaultValue: detailsData.province ? detailsData.province : '', dispaly: detailsData.userType == 'doctorUser' ? 'none' : '' },
                { type: 'text', label: '所在城市', defaultValue: detailsData.city ? detailsData.city : '', dispaly: detailsData.userType == 'doctorUser' ? 'none' : '' },
                { type: 'text', label: '医疗机构', defaultValue: detailsData.institution ? detailsData.institution : '', dispaly: detailsData.userType == 'doctorUser' ? '' : 'none' },
                { type: 'text', label: '所在科室', defaultValue: detailsData.dept ? detailsData.dept : '', dispaly: detailsData.userType == 'doctorUser' ? '' : 'none' },
                { type: 'text', label: '职称', defaultValue: detailsData.title ? detailsData.title : '', dispaly: detailsData.userType == 'doctorUser' ? '' : 'none' }
            ]
        }, {
            title: '证件信息',
            content: [
                { type: 'Image', label: '', defaultValue: detailsData.certificate ? detailsData.certificate.split(',') : [] }
            ]
        }]

        return (
            <div className='applyList'>
                <div className={'apply_wrap'}>
                    {/* <div className={'search_wrap1'}>
                        <Search
                            allowClear
                            placeholder="请输入姓名或医院名称"
                            onSearch={(v) => this.onSearch(v)}
                            onChange={(v) => this.onSearchChange(v)}
                            style={{ width: 260, marginRight: 30 }}
                        />
                    </div> */}
                    <Table
                        locale={{ emptyText: "暂无数据" }}
                        columns={columns}
                        dataSource={dataLists}
                        defaultExpandAllRows={true}
                        expandIconAsCell={false}
                        pagination={dataLists.length !== 0 && pagination}
                        rowKey={'id'}
                    />
                </div>

                {/* 查看详情 */}
                <Modal
                    wrapClassName={'applyList_modal'}
                    title="申请人信息详情"
                    visible={isModalVisible}
                    onCancel={() => { this.setState({ isModalVisible: false }) }}
                    footer={[
                        <>
                            {
                                showFooter &&
                                <>
                                    <Button type="link" onClick={this.onLink} className={'linkBtn'}><PaperClipOutlined /> 前往卫健委网站验证</Button>
                                    <div style={{ width: '100%' }}>
                                        <Button danger type="primary" key="back" onClick={this.handleCancel}>
                                            拒绝
                                        </Button>
                                        <Button key="submit" type="primary" onClick={this.handleOk}>
                                            同意申请
                                        </Button>
                                    </div>

                                </>
                            }
                        </>
                    ]}
                >

                    {
                        detailsInfo.map((item, index) => (
                            <div className={'model_detail_Info'} key={index}>
                                <span className={'title'}>{detailsData.certificate ? item.title : ''}</span>
                                {
                                    item.content.map((x, y) => (
                                        <div className={x.type == 'Image' ? 'wraps_Image' : 'wraps'} key={y} style={{ display: x.dispaly }}>
                                            <span className={'label'}>{x.label}</span>
                                            <div className={'Image_item'}>
                                                {
                                                    x.type == 'Image' ?
                                                        <>
                                                            {x.defaultValue.length > 0 && x.defaultValue.map((i, dIndex) => (
                                                                <ConfigProvider locale={zh_CN}>
                                                                    <Image
                                                                        height={'100%'}
                                                                        src={i}
                                                                        alt=''
                                                                        key={dIndex}
                                                                    />
                                                                </ConfigProvider>
                                                            ))}
                                                        </>
                                                        : x.defaultValue
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }

                </Modal>

                {/* 签名不合格/非医生 */}
                {
                    reasonVisible &&
                    <ReasonModal
                        visible={reasonVisible}
                        closeModal={this.closeReasonModal}
                        type={reasonType}
                        getRejectReason={this.getRejectReason}
                    />
                }

            </div>
        );
    }
}
export default withRouter(ApplylistsComponent);