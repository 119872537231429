import { Form, Input, message as messager, Modal, Select, TreeSelect } from "antd";
import React, { useEffect, useState } from "react"
import { createMenu, getMenuEnum, getMenuNodes } from "../../../../services/system/menu";
import { DataType } from "..";

type Props = {
  appCode?: string
  parentCode: string
  onClose(refresh: boolean): void;
}

export const takeCodeString = (value: string)=> {
  const pieces: number[] = []
  for(let i = 0; i < 3; i++) {
    pieces.push(Math.floor(Math.random() * 10))
  }
  return value + '_' + pieces.join('')
}

const CreateModal: React.FC<Props> = ({ parentCode, onClose, appCode = 'APP001' })=> {

  const [menus, setMenus] = useState<any[]>([])
  const [types, setTypes] = useState<any[]>([])
  const [currentCode, setCurrentCode] = useState('')

  const [form] = Form.useForm();

  const initialValues = {
    appCode,
    parentCode,
    type: parentCode === '000' ? 'MENU' : null
  }

  const fetchTypeList = async ()=> {
    const data = await getMenuEnum()
    setTypes(data.map(({key, text}: any)=> ({
      label: text,
      value: key,
    })))
  }

  const fetchNodeList = async ()=> {
    const { allResource } = await getMenuNodes()

    const tran = (list: any[])=> {
      for(const item of list) {
        item.label = item.name
        item.value = item.code
        if (item.children) {
          tran(item.children)
        }
      }
    }

    tran(allResource)

    allResource.unshift({label: '一级菜单', value: '000'})

    setMenus(allResource)
  }

  const createData = async ()=> {
    try {
      const data = await form.validateFields()

      const { code, message } = await createMenu(data)

      if (code === 0) {
        messager.success('创建成功')
        onClose(true)
      } else {
        messager.error(message)
      }
    } catch (error) {
      console.log('?er', error)
    }
  }

  const onSelect = (value: string, item: DataType)=> {
    setCurrentCode(value)
    form.setFieldValue('type', value === '000' ? 'MENU' : null)
    form.setFieldValue('code', takeCodeString(item.code))
  }

  useEffect(()=> {
    setCurrentCode(parentCode)
    fetchTypeList()
    fetchNodeList()
    form.setFieldValue('code', takeCodeString(parentCode))
  }, [])

  return (
    <Modal onOk={createData} onCancel={()=> onClose(false)} okText="创建" cancelText="取消" title="新增菜单" open={true} destroyOnClose closable>
      <Form form={form} initialValues={initialValues}>
        <Form.Item labelCol={{span: 5}} name="parentCode" label="上级菜单" rules={[{required: true, message: '请选择上级菜单'}]}>
          <TreeSelect onSelect={onSelect} treeData={menus}></TreeSelect>
        </Form.Item>
        <Form.Item labelCol={{span: 5}} name="type" label="菜单类型" rules={[{required: true, message: '请选择菜单类型'}]}>
          <Select disabled={currentCode === '000'} options={types} placeholder="请选择" allowClear showSearch filterOption={(keyword, { label }: any) => label.includes(keyword)}></Select>
        </Form.Item>
        <Form.Item labelCol={{span: 5}} name="name" label="菜单名称" rules={[{required: true, message: '请输入菜单名称'}]}>
          <Input placeholder="请输入菜单名称" allowClear/>
        </Form.Item>
        <Form.Item labelCol={{span: 5}} name="code" label="菜单编码" rules={[{required: true, message: '请输入菜单编码'}]}>
          <Input placeholder="请输入菜单编码" allowClear/>
        </Form.Item>
        <Form.Item labelCol={{span: 5}} name="url" label="菜单地址" rules={[{required: true, message: '请输入菜单地址'}]}>
          <Input placeholder="请输入菜单地址" allowClear/>
        </Form.Item>
        <Form.Item labelCol={{span: 5}} name="icon" label="菜单图标">
          <Input placeholder="请输入菜单图标" allowClear/>
        </Form.Item>
        <Form.Item labelCol={{span: 5}} name="appCode" label="应用编号">
          <Input placeholder="请输入应用编号" allowClear/>
        </Form.Item>
        <Form.Item labelCol={{span: 5}} name="config" label="菜单配置">
          <Input.TextArea placeholder="请输入菜单配置" allowClear/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateModal