/**
 * 图片列表
 */
import React, { Component } from 'react';
import './index.less';
import { Button, message, Row, Col, Switch, List, Modal, Checkbox, Spin } from 'antd';
import {
    ExclamationCircleOutlined, QuestionCircleTwoTone, CheckCircleTwoTone,
    CoffeeOutlined, FundProjectionScreenOutlined
} from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { GetDctApprovalOperation, CancelDctApprovalOperation } from "../../../../../services/dct/DctService";
import SubmitCom from '../submit'
import IconMark from '../../../static/images/iconMark.png';
import RefuseModal from "../refuseModal";

const CheckboxGroup = Checkbox.Group;

let _this;
const itemChecked = {
    border: '1px solid rgb(10, 88, 255)',
    borderRadius: '3px',
    background: 'rgba(77, 136, 245, .2)'
}
const work = {
    background: '#1890ff',
    color: '#fff',
}
const noWork = {
    background: '#fff',
    color: '#1890ff',
}

class CheckListComponent extends Component {

    constructor(props) {
        super(props);
        _this = this;
        this.state = {
            checkedList: [], // 选中列表
            isBatch: false, // 批量选择
            currId: null,
            currValues: null,
            ImgList: null,
            switchChecked: true, // 默认工作状态
            isSpin: false,
            passStatus: null, // 通过or取消通过
            modalVisible: false,// modal显隐
        };
        this.childRef = React.createRef();
        this.rejectReason = ''
    }

    componentDidMount() {

        if (this.props.data) {
            this.setState({
                ImgList: this.props.data,
                currId: this.props.data[0].id,
                currValues: this.props.data[0],
            })
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {

        if (nextProps) {
            let { data, listItem } = nextProps
            this.setState({
                ImgList: data,
                currId: listItem ? listItem.id : null,
                passStatus: listItem ? listItem.approveStatus : data[0].approveStatus,
                currValues: listItem || data[0],
            })
        }
    }

    componentWillUnmount = () => {
        
    }

    // 一键通过
    aKeyBy = () => {
        let { currId, checkedList, ImgList } = this.state;

        if (checkedList.length == 0) {
            message.error('请先选择数据！')
            this.setState({
                isSpin: false,
            })
            return
        }

        let params = {
            approveStatus: 'AGREE',
            id: checkedList.length > 0 ? checkedList.join(',') : ''
        }
        this.passOperation(params)
        this.toTuoMin(ImgList)
    }

    // 通过/质疑
    passOperation = async (params) => {
        const res = await GetDctApprovalOperation(params);
        if (res && res.code == 0) {
            message.success(res.message)
            _this.setState({
                isSpin: false,
                passStatus: params.approveStatus,
                isBatch: false
            })
            _this.props.getValue(true)
        }
    }

    // 切换图片
    changeImg = (values) => {
        this.setState({
            currId: values.id,
            currValues: values,
            passStatus: values.approveStatus,
            isBatch: false
        })

        this.props.getListData(values)
    }

    // 全选/批量选择
    onCheckAllChange = (value) => {
        let { ImgList } = this.state
        let waitPassIds = []
        ImgList && ImgList.length > 0 && ImgList.map((item, index) => {
            if (item.approveStatus == 'RUNNING') {
                waitPassIds.push(item.id)
            }
        })

        if (value) {
            waitPassIds = []
        }

        this.setState({
            checkedList: waitPassIds,
            isBatch: !value
        })
    };

    // 工作/休息
    onSwitch = (checked) => {

        if (!checked) {
            this.setState({
                switchChecked: false
            })

            Modal.confirm({
                title: '退出工作模式',
                icon: <ExclamationCircleOutlined />,
                content: '退出工作模式后，当前未完成的任务将会自动放弃，是否确认切换？',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    this.setState({
                        switchChecked: false
                    }, () => {
                        this.props.getValue(false, '休息')
                    })
                    this.toTuoMin()
                },
                onCancel: () => {
                    this.setState({
                        switchChecked: true
                    })
                }
            });
        }
    }

    // 选中
    onChangeCheckbox = (e, id) => {
        let { checkedList } = this.state;
        let list = checkedList

        if (e.target.checked) {
            list.push(id)
            this.setState({
                checkedList: list
            })

        } else {
            if (checkedList.includes(id)) {

                this.setState({
                    checkedList: list.filter(i => i !== id),
                    isBatch: !(list.filter(i => i !== id).length == 0)
                })
            }
        }
    }

    onChecked = async (val) => {
        let { currId, currValues, ImgList } = this.state
        let params = {
            id: currId || ImgList[0].id
        }
        if (val == 'AGREE') { // 通过
            params['approveStatus'] = 'AGREE'
            this.passOperation(params)
            this.toTuoMin()
        } else if (val == 'REJECT') { // 质疑

            if (currValues && currValues.approveStatus == 'REJECT') {
                message.warn('该图片已质疑，请勿重复质疑！')
                return
            }

            this.setState({
                modalVisible: true
            })

        } else { // 取消通过
            this.CancelOperation(params)
            this.toTuoMin()
        }
    }

    // 取消通过 
    CancelOperation = async (params) => {
        const res = await CancelDctApprovalOperation(params);
        if (res && res.code == 0) {
            message.success(res.message)
            _this.setState({
                passStatus: 'AGREE'
            })
            _this.props.getValue(true)
        }
    }

    // 关闭拒绝模态框
    closeModal = () => {
        this.setState({
            modalVisible: false,
        });
    }

    // 获取拒绝信息
    getModalValue = (v) => {
        let {currId, ImgList} = this.state
        let params = {
            id: currId || ImgList[0].id,
            approveStatus: 'REJECT',
            rejectReason: v
        }
        this.rejectReason = v
        this.passOperation(params)
        // 质疑通过同时脱敏
        this.toTuoMin()
    }

    // （休息、质疑、取消、通过），一键通过都脱敏， 前者单个，后者一起
    toTuoMin(imgList = []) {
        let { currValues } = this.state;
        this.childRef.current && this.childRef.current.tuoMin && this.childRef.current.tuoMin(currValues.url, imgList);
    }

    render() {
        let { ImgList, checkedList,
            isBatch, currId, switchChecked, isSpin, passStatus, modalVisible } = this.state;
            
        return (
            <div className="CheckList_wrap">
                <div className="operate_wrap">
                    <div className="title">待审核图片</div>
                    <div className="switch">
                        <div className="left" onClick={() => this.onSwitch(true)} style={switchChecked ? work : noWork}>
                            <FundProjectionScreenOutlined />&nbsp;工作
                        </div>
                        <div className="right" onClick={() => this.onSwitch(false)} style={!switchChecked ? work : noWork}>
                            <CoffeeOutlined />&nbsp;休息
                        </div>
                    </div>
                </div>
                <Row gutter={16} style={{ marginLeft: 0, marginRight: 0, overflow: 'auto' }}>
                    {
                        ImgList && ImgList.map((item, index) => (
                            <Col span={8} key={index}
                                style={{
                                    height: '50%',
                                    padding: '0px',
                                }}>
                                <div className="item"
                                    style={item.id == (currId || ImgList[0].id) ? itemChecked : {}}
                                    onClick={() => this.changeImg(item)}
                                >
                                    <div className="col_content" >
                                        <div className="mask" hidden={item.approveStatus == 'RUNNING'}>
                                            {item.approveStatus == 'AGREE' && <><CheckCircleTwoTone twoToneColor="#52c41a" />&nbsp;&nbsp;已通过</>}
                                            {item.approveStatus == 'REJECT' && <><QuestionCircleTwoTone twoToneColor="#ff0000" />&nbsp;&nbsp;已拒绝</>}
                                        </div>
                                        <img src={item.url + '?id=' + Math.random()} alt='' className={'ImgStyle'} />
                                        {
                                            item.approveStatus == 'RUNNING' && <div className="multiple" hidden={!isBatch}>
                                                <Checkbox
                                                    defaultChecked
                                                    checked={checkedList.includes(item.id)}
                                                    onChange={(e, id) => this.onChangeCheckbox(e, item.id)}
                                                />
                                            </div>
                                        }
                                        {item.sensitiveInfo == '1' && <img src={IconMark} alt="" className="icon-mark" />}
                                    </div>
                                    <div className="img_tit">
                                        <span className="small-sign" hidden={!(item.desensitization == '1')}>需脱敏</span>
                                        <span className="txt">{item.label}</span>
                                        {/* {item.label} */}
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
                <div className="operate_bottom">
                    <SubmitCom
                        onCheckAll={(v) => this.onCheckAllChange(v)}
                        currId={currId}
                        onChecked={(v) => this.onChecked(v)}
                        ref={this.childRef}
                        isSpin={isSpin}
                        passStatus={passStatus}
                        aKeyBy={() => this.aKeyBy()}
                        isBatch={isBatch}
                    />
                    <RefuseModal
                        visible={modalVisible}
                        closeModal={_this.closeModal}
                        getValue={(v) => this.getModalValue(v)}
                    />
                </div>
            </div>
        )
    }
}
export default CheckListComponent