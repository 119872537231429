import React from "react";
import "./index.less";

import {
  ConfigProvider,
  Input,
  Select,
  Button,
  Table,
  message,
  Modal,
  Col,
  Row,
  Form,
  Image,
  Spin,
} from "antd";
import {
  getBannerList,
  deleteBanner,
  createBanner,
  editBanner,
  getLocalList,
  bannerState,
} from "../../../../services/apply/ApplyService";
import { ExclamationCircleFilled } from "@ant-design/icons";
import zh_CN from "antd/lib/locale-provider/zh_CN";
import AliUpload from "../imageUpload";
const { Option } = Select;
const { confirm } = Modal;

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      title: null,
      state: null,
      page: 1,
      totalRow: 0,
      dataList: [],
      stateOptions: [
        {
          value: 1,
          label: "上线",
        },
        {
          value: 2,
          label: "下线",
        },
      ],
      dialogVisible: false,
      isEdit: 0,
      localOptions: [],
      sortOptions: [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
      ],
    };
  }

  formRef = React.createRef();
  componentDidMount() {
    this.getLocalOptions();
    this.getData();
  }

  // 获取位置列表
  getLocalOptions = () => {
    getLocalList({ pageType: 2 }).then((res) => {
      if (res.code == 0) {
        this.setState({
          localOptions: res.data,
        });
      }
    });
  };

  //删除报名
  deleteItem = (id) => {
    let params = {
      id,
    };
    let that = this;
    confirm({
      title: "确定要删除该条banner内容吗?",
      icon: <ExclamationCircleFilled />,
      okText: "确定",
      cancelText: "取消",
      onOk() {
        deleteBanner(params).then((res) => {
          if (res.code == 0) {
            message.success("删除成功", 1);
            that.getData();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  getData = (pageNo) => {
    let params = {
      title: this.state.title,
      state: this.state.state,
      page: pageNo ? pageNo : this.state.page,
      pageSize: 10,
    };
    this.setState(
      {
        loading: true,
      },
      () => {
        getBannerList(params).then((res) => {
          if (res.code == 0 && res.data) {
            this.setState({
              dataList: res.data.rows,
              page: res.data.page,
              totalRow: res.data.total,
              loading: false,
            });
          }
        });
      }
    );
  };

  handleSearch = () => {
    this.getData();
  };

  /**
   * 重置搜索
   */
  resetSearch = () => {
    this.setState(
      {
        title: null,
        state: null,
        page: 1,
        totalRow: 0,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  /**
   *创建与编辑banner
   */
  createOrEdit = (val, record) => {
    this.setState(
      { dialogVisible: true, isEdit: val, imageUrl: record?.imageUrl },
      () => {
        if (val) {
          this.formRef.current.setFieldsValue({ ...record });
        }
      }
    );
  };
  // 上线或者下线
  handleOperate = async (id, state) => {
    const res = await bannerState({
      id,
      state,
    });
    if (res.code == 0) {
      message.success("操作成功！");
      this.getData();
    }
  };

  handleCancel = () => {
    this.setState({ dialogVisible: false });
    this.formRef.current.resetFields();
  };

  // 图片传递
  handleFileSelect = (url) => {
    this.setState({ imageUrl: url });
    this.formRef.current.setFieldsValue({ imageUrl: url });
  };

  // 表单提交或者修改
  handleFinish = async () => {
    this.formRef.current.validateFields().then(async (values) => {
      // const values = this.formRef.current.getFieldsValue(['id', 'imageUrl', 'pageLocalId', 'outsideChain', 'sortNum', 'title'])
      if (this.state.isEdit) {
        const res = await editBanner(values);
        if (res.code == 0) {
          message.success("编辑成功", 1);
          this.setState({
            dialogVisible: false,
          });
          this.formRef.current.resetFields();
          this.getData();
        }
      } else {
        const res = await createBanner(values);
        if (res.code == 0) {
          message.success("添加成功", 1);

          this.setState({
            dialogVisible: false,
          });
          this.formRef.current.resetFields();
          this.getData();
        }
      }
    });
  };

  render() {
    let {
      loading,
      stateOptions,
      dialogVisible,
      dialogLoading,
      page,
      totalRow,
      dataList,
      localOptions,
      sortOptions,
      isEdit,
    } = this.state;

    let columns = [
      {
        title: "位置",
        dataIndex: "pageLocalName",
        width: 200,
      },
      {
        title: "排序",
        dataIndex: "sortNum",
        width: 80,
      },
      {
        title: "图片",
        dataIndex: "imageUrl",
        width: 145,
        render: (text, record) => {
          return (
            <ConfigProvider locale={zh_CN}>
              <Image src={record.imageUrl} className="img_url" />
            </ConfigProvider>
          );
        },
      },
      {
        title: "标题",
        dataIndex: "title",
        width: 200,
      },
      {
        title: "状态",
        dataIndex: "state",
        width: 145,
        render: (text, record) => (
          <div>
            <div>{record.state == 1 ? "上线" : "下线"}</div>
          </div>
        ),
      },
      {
        title: "其他",
        width: "",
        render: (text, record) => {
          return (
            <div>
              创建人：{record.createUserName}
              <br />
              最后修改时间：{record.modifyTime}
            </div>
          );
        },
      },

      {
        title: "操作",
        width: 200,
        fixed: "right",
        align: "center",
        render: (text, record) => (
          <div className="btn-operate">
            <Button
              type="primary"
              size="small"
              onClick={() => this.createOrEdit(1, record)}
            >
              编辑
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                this.handleOperate(record.id, record.state == 2 ? 1 : 2);
              }}
            >
              {record.state == 2 ? "上线" : "下线"}
            </Button>
            <Button
              type="primary"
              size="small"
              danger
              onClick={() => {
                this.deleteItem(record.id);
              }}
            >
              删除
            </Button>
          </div>
        ),
      },
    ];

    let pagination = {
      onChange: (pageNo) => {
        this.getData(pageNo);
      },
      current: page,
      pageSize: 10,
      total: totalRow,
      showSizeChanger: false,
      showTotal: (total) => `共${total} 条记录`,
    };

    return (
      <div className="banner_content">
        <div className={"apply_wrap"}>
          <div className={"search_wrap_content"}>
            <div className="search_left">
              <Row className="search_row">
                <Col span={8}>
                  <span className="search_label">banner标题</span>

                  <Input
                    allowClear
                    placeholder="请输入标题"
                    value={this.state.title}
                    onChange={(e) => this.setState({ title: e.target.value })}
                    style={{ width: "70%" }}
                  />
                </Col>
                <Col span={8}>
                  <span className="search_label">状态</span>
                  <Select
                    showSearch
                    placeholder="请选择状态"
                    style={{ width: "70%" }}
                    value={this.state.state}
                    allowClear
                    onChange={(value) => this.setState({ state: value })}
                    optionFilterProp="children"
                  >
                    {stateOptions.map((item) => (
                      <Option key={item.value} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col span={8} className="right_action">
                  <Button
                    type="primary"
                    size={"middle"}
                    onClick={this.handleSearch}
                  >
                    查询
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    size={"middle"}
                    onClick={this.resetSearch}
                  >
                    重置
                  </Button>
                </Col>
              </Row>
            </div>
          </div>

          <div className={"btns_wrap"}>
            <Button type="primary" onClick={() => this.createOrEdit(0)}>
              新建
            </Button>
          </div>
          <Spin tip="数据加载中..." spinning={loading}>
            <Table
              locale={{ emptyText: "暂无数据" }}
              columns={columns}
              dataSource={dataList}
              pagination={pagination}
              rowKey={"id"}
              scroll={{ x: "calc(700px + 50%)" }}
            />
          </Spin>
        </div>

        <Modal
          className="case-audit-checkbox-wrapper"
          visible={dialogVisible}
          title={isEdit ? "编辑" : "新建"}
          onCancel={this.handleCancel}
          width={"700px"}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              取消
            </Button>,
            <Button
              htmlType="submit"
              type="primary"
              loading={dialogLoading}
              onClick={this.handleFinish}
            >
              确认
            </Button>,
          ]}
        >
          <Form
            ref={this.formRef}
            onFinish={this.handleFinish}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item name="id" hidden></Form.Item>
            <Form.Item
              label="位置"
              name="pageLocalId"
              rules={[{ required: true, message: "请选择位置!" }]}
            >
              <Select placeholder="请选择" style={{ width: 300 }} allowClear>
                {localOptions.map((e) => {
                  return <Option value={e.id}>{e.title}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="排序"
              name="sortNum"
              rules={[{ required: true, message: "请输入排序!" }]}
            >
              <Select placeholder="请选择" style={{ width: 300 }}>
                {sortOptions.map((e) => {
                  return <Option value={e.value}>{e.value}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="标题"
              name="title"
              rules={[{ required: true, message: "请输入标题!" }]}
            >
              <Input
                style={{ width: 300 }}
                placeholder="请输入标题"
                autoComplete="off"
                maxLength={50}
              />
            </Form.Item>
            <Form.Item
              label="banner图片"
              name="imageUrl"
              rules={[{ required: true, message: "请上传图片" }]}
            >
              <AliUpload
                onFileChange={this.handleFileSelect}
                imageUrl={this.state.imageUrl}
              />
            </Form.Item>
            <Form.Item label="跳转外链" name="outsideChain">
              <Input
                style={{ width: 300 }}
                placeholder="请输入跳转外链"
                autoComplete="off"
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default Banner;
