/**
 * 左部菜单导航
 * lechen
 */

import React, { useState, useEffect, useRef } from 'react';
import {Tree, Menu} from 'antd';
import {Scrollbars} from 'react-custom-scrollbars';
import "./nav.less";
import PubSub from 'pubsub-js';
import {createFromIconfontCN,MenuUnfoldOutlined,MenuFoldOutlined, ControlOutlined,} from '@ant-design/icons';
import {Redirect, withRouter} from 'react-router-dom';
import localStorageUtil from "../../../comm/localStorage";
import {GetProjectMenuService} from "../../../services/plat/MenuService";
// import Logo from './logo.png'
import iconLogo from './icon_logo.png'
import myicon from "../../project/detail/researchCenter/photo_icon.png";

const {SubMenu} = Menu;

const IconFont = createFromIconfontCN({
    scriptUrl: [
       '//at.alicdn.com/t/c/font_2343612_uey1i1zogf.js',
       '//at.alicdn.com/t/c/font_2343612_c7gxd0ggx57.js',
    ],
});
let _this;
let sesioonMenuWidth = sessionStorage.getItem('menuWidth');
// console.log(sesioonMenuWidth,'---11')
class MenuNav extends React.Component {
    
    constructor(props) {
        super(props);
        _this = this;

        _this.navRef = React.createRef();
        _this.handleMouseDown = this.handleMouseDown.bind(this); // 绑定this
        _this.handleMouseMove = this.handleMouseMove.bind(this);
        _this.handleMouseUp = this.handleMouseUp.bind(this);
        
        _this.state = {
            // width: 200, // 初始宽度
            width: !sesioonMenuWidth ? 200 : sesioonMenuWidth,
            isDragging: false, // 是否正在拖拽
            startX: null, // 鼠标按下时的X坐标
            startWidth: null, // 拖拽前导航栏的宽度

            menus: [],
            collapsed: false,
            selectMenuKey: '',
            menuIsOpen: '',
            dynamicUrl: {},
            levelTwoKey: '',
            levelTwoUrl: '',
            levelTwoTitle: '',
            tenantName: sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token')).tenantName : 'xxx租户名称',
            menuMinWidth: '0px', // 菜单栏最小宽度
            menuMaxWidth: '200px', // 菜单栏最大宽度
            currentMenus: null,
        }
    }

    /**
     * 导航拖拽
     */
    handleMouseDown = (e) => {
        e.stopPropagation() 
        if (e.button !== 0) return;// 只处理鼠标左键事件
        if (e.clientX >= this.navRef.current.getBoundingClientRect().right - 10) { // 限定拖拽区域为导航栏右侧边缘10像素范围内
            this.setState({
                isDragging: true, // 标记为正在拖拽
                startX: e.clientX,
                startWidth: parseInt(window.getComputedStyle(this.navRef.current).width, 10), // 获取导航栏的当前宽度
            });
            document.addEventListener('mousemove', this.handleMouseMove); // 监听鼠标移动和松开事件
            document.addEventListener('mouseup', this.handleMouseUp);
        }
    }
    handleMouseMove(e) { 
        if (!this.state.isDragging) return; // 如果不是正在拖拽状态，直接返回
    
        const offset = e.clientX - this.state.startX; // 计算鼠标移动的距离
        const newWidth = this.state.startWidth + offset; // 新的导航栏宽度
        if (newWidth < 200 || newWidth > 500) return; // 防止导航栏过小或过大
        this.setState({ width: newWidth }); // 更新state中的宽度
        this.props.getMouseUpVal({menuWidth: this.state.width})
    }
    handleMouseUp() {
        if (!this.state.isDragging) return; // 如果不是正在拖拽状态，直接返回
    
        this.setState({ isDragging: false }); // 标记为停止拖拽
        document.removeEventListener('mousemove', this.handleMouseMove); // 移除事件监听
        document.removeEventListener('mouseup', this.handleMouseUp);

        if(sessionStorage.getItem('menuWidth')>0){
            sessionStorage.removeItem('menuWidth')
            sessionStorage.setItem('menuWidth', this.state.width)
        } else {
            sessionStorage.setItem('menuWidth', this.state.width)
        }

        this.props.getMouseUpVal({menuWidth: this.state.width})
    }

    componentDidMount() {
        _this.onOneLevelStatusChange(); // 左边导航
    }

    componentWillUnmount() {
        PubSub.unsubscribe("onLeftNavChange");
    }

    /**
     * 左边导航栏
     * type : menu => 菜单, nav => 导航, interf => 接口,opt =>操作,dynav => 动态导航
     */
    onOneLevelStatusChange = async () => {
        PubSub.subscribe('onLeftNavChange', async (key, param) => {
            let menuModule = localStorageUtil.get('menuModule', true);

            // 二级模块缓存
            let dynamicUrl = {};//所有动态菜单跳转url对象信息
            let levelTwoKey = '';
            let levelTwoTitle = '';
            let levelTwoUrl = '';
            // 三级模块缓存
            let levelThreeKey = '';
            let levelThreeTitle = '';

            let selectOpenMenuKey = '';// 默认展开二级的key
            let selectMenuKey = '';// 默认选中三级的key
            let selectMenuUrl = '';// 默认选中跳转菜单的url
            let dynamicMenuUrlParams = {};// 默认选中跳转动态菜单的参数

            let existDynamicMenu = false;// 是否存在动态菜单项
            let chooseDynamicMenu = false;// 默认三级选中的动态菜单开关
            let defaultOpenMenu = false;// 默认二级展开的菜单开关
            let defaultSelectMenu = false;// 默认二级选中的菜单开关

            let newMenus = [];// 构建新的菜单列表

            // 判断是否有二级菜单数据
            const menus = param.menus;
            if (menus && menus.length > 0) {
                for (let twoMenu of menus) {
                    switch (twoMenu.type) {
                        case 'MENU':// 当前目录是菜单类型
                            let threeMenus = twoMenu.children;
                            if (threeMenus && threeMenus.length > 0) {
                                // 默认展开的菜单目录项
                                if (!defaultOpenMenu) {
                                    selectOpenMenuKey = twoMenu.key;
                                    defaultOpenMenu = true;
                                }
                                // 默认选中的菜单目录项
                                if (!defaultSelectMenu) {
                                    selectMenuKey = threeMenus[0].key;
                                    selectMenuUrl = threeMenus[0].url;
                                    defaultSelectMenu = true;

                                    // 缓存
                                    levelTwoKey = twoMenu.key;
                                    levelTwoUrl = twoMenu.url;
                                    levelTwoTitle = twoMenu.title;
                                    levelThreeKey = threeMenus[0].key;
                                    levelThreeTitle = threeMenus[0].title;
                                }
                            }
                            break;
                        case 'NAV': // 当前目录是导航类型
                            // 默认选中的菜单目录项
                            if (!defaultSelectMenu) {
                                selectMenuKey = twoMenu.key;
                                selectMenuUrl = twoMenu.url;
                                defaultSelectMenu = true

                                // 缓存
                                levelTwoKey = twoMenu.key;
                                levelTwoUrl = twoMenu.url;
                                levelTwoTitle = twoMenu.title;
                            }
                            break;
                        case 'DYNAV': // 当前目录是动态导航类型
                            existDynamicMenu = true;
                            dynamicUrl[twoMenu.key] = twoMenu.url;// 存动态菜单跳转url

                            let config = twoMenu.config; // 取出当前动态菜单配置属性值
                            if (config) { // 处理不同状态的项目菜单接口数据
                                const {url, params} = JSON.parse(config);
                                const projectMenus = await GetProjectMenuService(url, {...params});
                                if (projectMenus && projectMenus.length > 0) {// 存在动态子菜单列表
                                    // 默认展开的菜单目录项
                                    if (!defaultOpenMenu && !chooseDynamicMenu) {
                                        selectOpenMenuKey = twoMenu.key;
                                        defaultOpenMenu = true;
                                    }

                                    //映射三级菜单数据
                                    let myMenuList = [];
                                    projectMenus.forEach(one => {
                                        let myMenu = {};
                                        myMenu.key = one.id || one.code; //兼容租户
                                        myMenu.code = one.code;
                                        myMenu.title = one.name;
                                        myMenu.iconName = one.icon;
                                        myMenuList.push(myMenu);
                                    })

                                    // 设置动态菜单默认选中项
                                    if (!chooseDynamicMenu) {
                                        selectMenuUrl = twoMenu.url;
                                        dynamicMenuUrlParams = {
                                            id: myMenuList[0].key,
                                            code: myMenuList[0].code
                                        }
                                        selectMenuKey = myMenuList[0].key;
                                        chooseDynamicMenu = true;
                                        defaultSelectMenu = true

                                        //缓存
                                        levelTwoKey = twoMenu.key;
                                        levelTwoUrl = twoMenu.url;
                                        levelTwoTitle = twoMenu.title;
                                        levelThreeKey = myMenuList[0].key;
                                        levelThreeTitle = myMenuList[0].title;
                                    }

                                    // 挂载动态菜单的所有子菜单列表
                                    twoMenu.children = myMenuList;
                                } else {
                                    // 第一次查询动态菜单项为空，跳过动态菜单选中逻辑，选中第一个导航菜单
                                    chooseDynamicMenu = true;
                                    twoMenu.url = "noprojectpage";
                                }
                            }
                            break;
                    }
                    newMenus.push(twoMenu);
                }
            }

            this.setState({
                currentMenus: newMenus
            })

            // 判断是否为页面刷新，路由到当前缓存的页面信息
            if (param.isInit) {
                levelTwoKey = menuModule.levelTwoKey;
                levelTwoUrl = menuModule.levelTwoUrl;
                levelTwoTitle = menuModule.levelTwoTitle;
                levelThreeKey = menuModule.levelThreeKey;
                levelThreeTitle = menuModule.levelThreeTitle;

                selectOpenMenuKey = menuModule.selectOpenMenuKey;
                selectMenuKey = menuModule.selectMenuKey;
                selectMenuUrl = menuModule.selectMenuUrl;
                dynamicMenuUrlParams = menuModule.dynamicMenuUrlParams;
            } else {
                // if (!selectMenuUrl) {
                //     // 默认设置选中key和选中菜单项
                //     if (!existDynamicMenu) {// 项目中心默认选中添加项目，其他中心，默认选中第一个菜单
                //         selectOpenMenuKey = newMenus[0].key;
                //         selectMenuKey = newMenus[0].key;
                //         levelTwoKey = newMenus[0].key;
                //         levelTwoTitle = newMenus[0].title;
                //         levelTwoUrl = newMenus[0].url;
                //     }
                // }

                selectOpenMenuKey = newMenus[0].key;
                selectMenuKey = newMenus[0].key;
                levelTwoKey = newMenus[0].key;
                levelTwoTitle = newMenus[0].title;
                levelTwoUrl = newMenus[0].url;

                //存储菜单模块信息
                menuModule.levelTwoKey = levelTwoKey;
                menuModule.levelTwoUrl = levelTwoUrl;
                menuModule.levelTwoTitle = levelTwoTitle;
                menuModule.levelThreeKey = levelThreeKey;
                menuModule.levelThreeTitle = levelThreeTitle;

                menuModule.selectOpenMenuKey = selectOpenMenuKey;
                menuModule.selectMenuKey = selectMenuKey;
                menuModule.selectMenuUrl = selectMenuUrl;
                menuModule.dynamicMenuUrlParams = dynamicMenuUrlParams;
                localStorageUtil.set('menuModule', menuModule, true);
            }

            //发布 面包屑
            PubSub.publish('onMenuModuleChange');
            _this.setState({
                menuIsOpen: selectOpenMenuKey,// 展开二级菜单开关
                selectMenuKey: selectMenuKey,// 选中菜单的key
                menus: newMenus,
                dynamicUrl,
                levelTwoKey,
                levelTwoUrl,
                levelTwoTitle
            }, () => {
                // 路由跳转
                if (!selectMenuUrl) {
                    // 跳转默认组建(未配置菜单项)
                    _this.props.history.push("/noConfigPage");
                } else {
                    if (_this.props.history.location.pathname == selectMenuUrl) {
                        _this.props.history.push({
                            pathname: "/emptyPage",
                            state: {path: selectMenuUrl, ...dynamicMenuUrlParams}
                        });
                    } else {
                        _this.props.history.push({pathname: selectMenuUrl, state: {...dynamicMenuUrlParams}});
                    }
                }
            })


        })
    }

    /**
     * 二级菜单点击标题,折叠菜单事件
     */
    handleSelectMenuTitle = (menu) => {
        const menuModule = localStorageUtil.get('menuModule', true);
        // 缓存菜单模块信息
        menuModule.selectOpenMenuKey = menu.key;
        localStorageUtil.set('menuModule', menuModule, true);

        _this.setState({
            menuIsOpen: _this.state.menuIsOpen === menu.key ? null : menu.key,
            levelTwoKey: menu.key,
            levelTwoTitle: menu.domEvent.target.textContent
        })
    };

    /**
     * 二级MenuItem点击事件
     */
    handleMenuItemClick = (menu) => {
        const menuModule = localStorageUtil.get('menuModule', true);
        _this.setState({
            selectMenuKey: menu.key
        }, () => {
            let t;
            let m = this.state.currentMenus;
            if (m && m.length>0) {
                m.map(i=>{
                    if (i.key == menu.key) {
                        t = i.title
                    }
                })
            }

            // 缓存菜单模块信息
            menuModule.levelTwoKey = menu.key;
            menuModule.levelTwoTitle = menu.item.node ? menu.item.node.textContent : t;
            menuModule.levelTwoUrl = menu.item.props.url || '';
            menuModule.levelThreeKey = "";
            menuModule.levelThreeTitle = "";

            menuModule.selectOpenMenuKey = menu.key;
            menuModule.selectMenuKey = menu.key;
            menuModule.selectMenuUrl = menu.item.props.url || '';
            localStorageUtil.set('menuModule', menuModule, true);

            // 发布
            PubSub.publish('onMenuModuleChange');

            if (menu.item.props.url && menu.item.props.type != 'DYNAV') {
                _this.props.history.push(menu.item.props.url);
            } else {
                this.props.history.push("/noprojectpage");
            }
        })
    };

    /**
     * 三级菜单ThreeMenu点击事件
     */
    handleThreeMenuClick = (selectedKeys, obj) => {
        _this.setState({
            selectMenuKey: obj.node.key
        }, () => {
            if (_this.state.levelTwoUrl) {
                const menuModule = localStorageUtil.get('menuModule', true);
                //存储菜单模块信息
                menuModule.levelTwoKey = _this.state.levelTwoKey;
                menuModule.levelTwoUrl = _this.state.levelTwoUrl;
                menuModule.levelTwoTitle = _this.state.levelTwoTitle;
                menuModule.levelThreeKey = obj.node.key;
                menuModule.levelThreeTitle = obj.node.title;

                menuModule.dynamicMenuUrlParams = {id: obj.node.key};
                menuModule.selectMenuKey = obj.node.key;
                menuModule.selectMenuUrl = _this.state.dynamicUrl[_this.state.levelTwoKey] || '';
                localStorageUtil.set('menuModule', menuModule, true);

                // 发布
                PubSub.publish('onMenuModuleChange');

                if (_this.props.history.location.pathname == _this.state.dynamicUrl[_this.state.levelTwoKey]) {
                    _this.props.history.push({
                        pathname: "/emptyPage",
                        state: {path: _this.state.dynamicUrl[_this.state.levelTwoKey], id: obj.node.key, code: obj.node.code}
                    });
                } else {
                    _this.props.history.push({
                        pathname: _this.state.dynamicUrl[_this.state.levelTwoKey],
                        state: {id: obj.node.key, code: obj.node.code}
                    });
                }

            } else {
                const menuModule = localStorageUtil.get('menuModule', true);
                //存储菜单模块信息
                menuModule.levelTwoKey = _this.state.levelTwoKey;
                menuModule.levelTwoUrl = _this.state.levelTwoUrl;
                menuModule.levelTwoTitle = _this.state.levelTwoTitle;
                menuModule.levelThreeKey = obj.node.key;
                menuModule.levelThreeTitle = obj.node.title;
                menuModule.selectMenuKey = obj.node.key;
                menuModule.selectMenuUrl = obj.node.url || '';
                localStorageUtil.set('menuModule', menuModule, true);
                _this.props.history.push(obj.node.url);
            }
        })

    };

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName}/>) : undefined;
    }

    // 收起菜单栏
    toggleCollapsed = () => {
        let { collapsed, menuMinWidth, menuMaxWidth } = this.state;
        this.setState({
            collapsed: !this.state.collapsed,
        });
        // console.log('是否伸缩:'+collapsed, '外边取到:'+sesioonMenuWidth,menuMaxWidth, menuMinWidth,'现场去：'+sessionStorage.getItem('menuWidth'),'---toggle') 
        if(sessionStorage.getItem('menuWidth') === null ){
            // console.log('为null')
            this.props.getValue({menuWidth: collapsed ? menuMaxWidth : menuMinWidth})
        } else {
            // console.log('非null')
            this.props.getValue({menuWidth: collapsed ? sessionStorage.getItem('menuWidth')+'px' : menuMinWidth})
        }
    }

    render() {
        let { collapsed } = this.state;
        return (
            <div className={'menuNav'}>
                <div className={'menu_top'}>
                    <span className={'menu_photo'}>
                        <img src={iconLogo} alt=""/>
                    </span>
                    <span className={'menu_name'}>科研数据小助手</span>
                    
                    <span onClick={this.toggleCollapsed} className={'collapsed-icon'}>
                        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
                    </span>
                </div>

                <div className={'menu_bottom'} ref={this.navRef} style={{ width: `${this.state.width}px` }} onMouseDown={this.handleMouseDown}>
                    <Scrollbars>
                        <div>
                            <Menu
                                openKeys={[_this.state.menuIsOpen]}
                                mode="inline"
                                theme="light"
                                inlineCollapsed={collapsed}
                                selectedKeys={[this.state.selectMenuKey]}
                            >
                                {!collapsed &&
                                    this.state.menus.map((item, index) => {
                                        if (item.children && item.children.length > 0) {
                                            return (
                                                <SubMenu
                                                    key={item.key}
                                                    icon={_this.formatIcon(item.iconName)}
                                                    title={item.title}
                                                    onTitleClick={_this.handleSelectMenuTitle}
                                                >
                                                    <div>
                                                        <Tree
                                                            showLine={{showLeafIcon: false}}
                                                            onSelect={this.handleThreeMenuClick}
                                                            treeData={item.children}
                                                            selectedKeys={[this.state.selectMenuKey]}
                                                            icon={this.formatIcon(item.icon)}
                                                        />
                                                    </div>
                                                </SubMenu>)
                                        } else {
                                            return (
                                                <Menu.Item
                                                    key={item.key}
                                                    icon={this.formatIcon(item.iconName)}
                                                    url={item.url}
                                                    type={item.type}
                                                    selectedKeys={[this.state.selectMenuKey]}
                                                    onClick={this.handleMenuItemClick}
                                                >
                                                    {item.title}
                                                </Menu.Item>)
                                        }
                                    })
                                }
                            </Menu>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}

export default withRouter(MenuNav);


