/**
 * 配置中心服务接口
 * meixin
 */

import {requestPost, requestGet, requestPostRes} from '../EdcService';

const urlPrefix = "/config";

/**
 * 获取平台字典查询下拉枚举集合
 * @param {} params
 */
export const DictionarySearchSelect = (params) => {
    const url = urlPrefix + "/dictionaries/selects";
    return requestGet(url, params)
};

/**
 * 获取平台字典分页列表
 * @param {} params
 */
export const DictionaryPageList = (params) => {
    const url = urlPrefix + "/dictionaries/list";
    return requestPost(url, params)
};

/**
 * 获取平台字典分页列表
 * @param {} params
 */
export const DictionaryInfo = (id, params) => {
    const url = urlPrefix + `/dictionaries/${id}`;
    return requestGet(url, params)
};

/**
 * 删除
 * @param {} params
 */
export const DictionariesDelete = (id, params) => {
    const url = urlPrefix + `/delete/${id}`;
    return requestPost(url, params)
};

/**
 * 添加 新增
 * @param {} params
 */
export const DictionariesAdd = (params) => {
    const url = urlPrefix + `/dictionaries/save`;
    return requestPostRes(url, params)
};

/**
 * 修改
 * @param {} params
 */
export const DictionariesEdit = (params) => {
    const url = urlPrefix + `/modify`;
    return requestPostRes(url, params)
};

/**
 * 添加字典项目
 * @param {} params
 */
export const DictionariesProjectAdd = (id, params) => {
    const url = urlPrefix + `/dictionariesProject/save/${id}`;
    return requestPostRes(url, params)
};


//获取规则设置查询列表
export const GetRuleConfigSelects = () => {
    const url = urlPrefix + `/ruleConfiguration/selects`;
    return requestGet(url)
}

//获取规则设置分页列表
export const GetRuleConfigList = (params) => {
    const url = urlPrefix + `/ruleConfiguration/list`;
    return requestPost(url, params)
}
//根据id获取相应脱敏规则
export const GetRuleById = (params) => {
    const url = urlPrefix + `/ruleElementDirectory/${params.configurationId}/directorys`;
    return requestGet(url)
}
//添加目录 || 脱敏数据
export const AddDirectory = (params) => {
    const url = urlPrefix + `/ruleElementDirectory/save`;
    return requestPostRes(url, params)
}
//修改目录 || 脱敏数据
export const ModifyDirectory = (params) => {
    const url = urlPrefix + `/ruleElementDirectory/modify`;
    return requestPostRes(url, params)
}
//删除目录 || 脱敏数据
export const DeleteDirectory = (params) => {
    const url = urlPrefix + `/ruleElementDirectory/delete/${params.id}`;
    return requestPostRes(url, params)
}
//删除目录 || 脱敏数据
export const GetDeCodeList = (params) => {
    const url = urlPrefix + `/dataElement/list`;
    return requestPostRes(url, params)
}
//获取编辑状态下的rule规则
export const GetEditRuleInfo = (params) => {
    const url = urlPrefix + `/ruleConfiguration/${params.id}`;
    return requestGet(url)
}
//获取只读状态下的rule规则
export const GetReadRuleInfo = (params) => {
    const url = urlPrefix + `/ruleConfiguration/${params.id}/${params.version}`;
    return requestGet(url)
}
//保存规则
export const SaveRule = (params) => {
    const url = urlPrefix + `/ruleElement/save`;
    return requestPostRes(url, params)
}
//修改规则
export const ModifyRule = (params) => {
    const url = urlPrefix + `/ruleElement/modify`;
    return requestPostRes(url, params)
}
//发布版本
export const SubmitRule = (params) => {
    const url = urlPrefix + `/ruleConfiguration/saveRuleAndVersion`;
    return requestPostRes(url, params)
}
//修改推荐版本
export const ModifyRecomVersion = (params) => {
    const url = urlPrefix + `/ruleConfiguration/modify/${params.id}/${params.versionRecom}`;
    return requestGet(url)
}
//根据下拉查询规则
export const SearchRule = (params) => {
    const url = urlPrefix + `/ruleElement/getRule/${params.usability}/${params.security}`;
    return requestPostRes(url, params)
}
//启用停用
export const ModifyRuleConfig = (params) => {
    const url = urlPrefix + `/ruleConfiguration/update/${params.id}/${params.state}`;
    return requestPostRes(url, params)
}
/**
 * 获取数据元管理 状态 下拉枚举集合
 * @param {} params
 */
export const GetDataThemeSelect = () => {
    const url = urlPrefix + `/dataTheme/selects`;
    return requestGet(url)
};

/**
 * 获取数据元管理 表格查询
 * @param {} params
 */
export const GetDataThemeList = (params) => {
    const url = urlPrefix + `/dataTheme/list`;
    return requestPost(url, params)
};

/**
 * 获取数据元管理 表格增加
 * @param {} params
 */
export const GetDataThemeSave = (params) => {
    const url = urlPrefix + `/dataTheme/save`;
    return requestPostRes(url, params)
};

/**
 * 获取数据元管理 详情
 * @param {} params
 */
export const GetDataThemeInfo = (id) => {
    const url = urlPrefix + `/dataTheme/${id}/info`;
    return requestGet(url)
};

/**
 * 获取数据元管理 详情更新
 * @param {} params
 */
export const GetDataThemeModify = (params) => {
    const url = urlPrefix + `/dataTheme/modify`;
    return requestPostRes(url, params)
};

/**
 * 获取数据元管理 删除
 * @param {} params
 */
export const GetDataThemeDelete = (id) => {
    const url = urlPrefix + `/dataTheme/delete/${id}`;
    return requestPostRes(url)
};

/**
 * 获取数据元管理 主题目录集合 树
 * @param {} params
 */
export const GetDataThemeDirectoryTreeList = (id) => {
    const url = urlPrefix + `/dataThemeDirectory/${id}/list`;
    return requestPostRes(url)
};

/**
 * 获取数据元管理 主题目录集合 树 添加目录
 * @param {} params
 */
export const GetDataThemeDirectorySave = (param) => {
    const url = urlPrefix + `/dataThemeDirectory/save`;
    return requestPostRes(url, param)
};

/**
 * 根据 树id 获取目录详情
 * @param {} params
 */
export const GetDataThemeDirectoryInfo = (id) => {
    const url = urlPrefix + `/dataThemeDirectory/${id}/info`;
    return requestGet(url)
};

/**
 * 获取数据元管理 主题目录集合 树 目录 更新
 * @param {} params
 */
export const GetDataThemeDirectoryModify = (param) => {
    const url = urlPrefix + `/dataThemeDirectory/modify`;
    return requestPostRes(url, param)
};

/**
 * 获取数据元管理 主题目录集合 树 删除目录
 * @param {} params
 */
export const GetDataThemeDirectoryDel = (id) => {
    const url = urlPrefix + `/dataThemeDirectory/delete/${id}`;
    return requestPostRes(url)
};

/**
 * 获取数据元管理 数据元 树 删除数据元
 * @param {} params
 */
export const GetDataElementDel = (id) => {
    const url = urlPrefix + `/dataElement/delete/${id}`;
    return requestPostRes(url)
};

/**
 * 获取数据元管理 主题目录集合 树 添加数据元
 * @param {} params
 */
export const GetDataElementSave = (param) => {
    const url = urlPrefix + `/dataElement/save`;
    return requestPostRes(url, param)
};

/**
 * 根据 树id 获取数据元 详情
 * @param {} params
 */
export const GetDataElementInfo = (id) => {
    const url = urlPrefix + `/dataElement/${id}/info`;
    return requestGet(url)
};

/**
 * 获取数据元管理 主题目录集合 树 目录 更新
 * @param {} params
 */
export const GetDataElementModify = (param) => {
    const url = urlPrefix + `/dataElement/modify`;
    return requestPostRes(url, param)
};

/**
 * 数据元 类型 下拉框
 * @param {} params
 */
export const GetDataElementSelects = (param) => {
    const url = urlPrefix + `/dataElement/selects`;
    return requestGet(url, param)
};


/**
 * 数据编码集 下拉框查询
 * @param {} params
 */
export const GetDataCodesetSelects = () => {
    const url = urlPrefix + `/dataCodeset/selects`;
    return requestGet(url)
};

/**
 * 数据编码集 分页列表
 * @param {} params
 */
export const DataCodesetPageList = (params) => {
    const url = urlPrefix + "/dataCodeset/list";
    return requestPost(url, params)
};

/**
 * 数据编码集 删除列表
 * @param {} params
 */
export const GetDataCodesetDel = (id) => {
    const url = urlPrefix + `/dataCodeset/delete/${id}`;
    return requestPostRes(url)
};


/**
 * 数据编码集 列表 添加
 * @param {} params
 */
export const GetDataCodesetSave = (param) => {
    const url = urlPrefix + `/dataCodeset/save`;
    return requestPostRes(url, param)
};

/**
 * 数据编码集 列表 修改
 * @param {} params
 */
export const GetDataCodesetModify = (param) => {
    const url = urlPrefix + `/dataCodeset/modify`;
    return requestPostRes(url, param)
};

/**
 * 根据 数据编码集 详情
 * @param {} params
 */
export const GetDataCodesetInfo = (id) => {
    const url = urlPrefix + `/dataCodeset/${id}/info`;
    return requestGet(url)
};


/**
 * 获取数据编码集 主题目录集合 树
 * @param {} params
 */
export const GetDataCodesetDirectoryTreeList = (id) => {
    const url = urlPrefix + `/dataCodesetDirectory/${id}/list`;
    return requestPostRes(url)
};

/**
 * 编码集 主题目录集合 树 添加目录
 * @param {} params
 */
export const GetDataCodesetDirectorySave = (param) => {
    const url = urlPrefix + `/dataCodesetDirectory/save`;
    return requestPostRes(url, param)
};

/**
 * 编码集 根据 树id 获取目录详情
 * @param {} params
 */
export const GetdataCodesetDirectoryInfo = (id) => {
    const url = urlPrefix + `/dataCodesetDirectory/${id}/info`;
    return requestGet(url)
};

/**
 * 编码集 主题目录集合 树 目录 更新
 * @param {} params
 */
export const GetDataCodesetDirectoryModify = (param) => {
    const url = urlPrefix + `/dataCodesetDirectory/modify`;
    return requestPostRes(url, param)
};

/**
 * 编码集 主题目录集合 树 删除目录
 * @param {} params
 */
export const GetDataCodesetDirectoryDel = (id) => {
    const url = urlPrefix + `/dataCodesetDirectory/delete/${id}`;
    return requestPostRes(url)
};

/**
 * 编码集  删除 编码集数据
 * @param {} params
 */
export const GetDataCodeDel = (id) => {
    const url = urlPrefix + `/dataCode/delete/${id}`;
    return requestPostRes(url)
};

/**
 * 编码集 数据元 添加
 * @param {} params
 */
export const GetDataCodeSave = (param) => {
    const url = urlPrefix + `/dataCode/save`;
    return requestPostRes(url, param)
};

/**
 * 编码集 数据元 详情
 * @param {} params
 */
export const GetDataCodeInfo = (id) => {
    const url = urlPrefix + `/dataCode/${id}/info`;
    return requestGet(url)
};

/**
 * 编码集 数据元 更新
 * @param {} params
 */
export const GetDataCodeModify = (param) => {
    const url = urlPrefix + `/dataCode/modify`;
    return requestPostRes(url, param)
};

/**
 * 编码集 数据元 下拉框 下拉枚举集合
 * @param {} params
 */
export const GetDataCodeSelects = (param) => {
    const url = urlPrefix + `/dataCode/selects`;
    return requestGet(url, param)
};

/**
 * 数据元 下拉集合
 * @param {} params
 */
export const GetDataElementList = (param) => {
    const url = urlPrefix + `/dataElement/list`;
    return requestPostRes(url, param)
};

/**
 * 数据主题 所有数据主题下拉集合
 * @param {} params
 */
export const GetDataElementListSelect = (param) => {
    const url = urlPrefix + `/dataTheme/list/select`;
    return requestPostRes(url, param)
};








