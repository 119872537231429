import React, { Component } from 'react'
import './dctOcrView.less'
import { Button, message, Modal, Tabs } from 'antd';
import { createFromIconfontCN, ScanOutlined } from '@ant-design/icons';
import Zmage from 'react-zmage'
import { updateOcrList } from '../../../../services/data/DataService';
import ocrgif from '../../../../static/images/ocrgif.gif';
import Item from 'antd/lib/list/Item';
import PubSub from 'pubsub-js';

const { confirm } = Modal;
const { TabPane } = Tabs;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2343612_ls9uemqdl2.js'
    ],
});

export default class DctOcrView extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: {},
            ocrLists: [], // 划词列表
        }
    }

    componentDidMount = () => {

    }

    componentWillUnmount = () => {
        PubSub.unsubscribe('ocrData')
    }

    UNSAFE_componentWillReceiveProps = (nextProps: any) => {
        
        if (nextProps.ocrLists) {
            let list = nextProps.ocrLists;
            list.length > 0 && list.map((i: any) => {
                if (nextProps.formItem) {
                    let formItem = nextProps.formItem
                    if (i.dataCode == formItem.dataCode) {
                        if (!(Object.keys(formItem).includes('isDelimitation'))) {
                            formItem['isDelimitation'] = i.isDelimitation
                        }

                        formItem['sort'] = i.sort
                    }
                }

            });
            this.setState({
                ocrLists: list
            })
        }

        PubSub.subscribe('ocrData', (topic:any, subdata:any) => {

            if (subdata.code == nextProps.formItem.dataCode) {
                nextProps.formItem['isShowGif'] = subdata.checked
            } else {
                nextProps.formItem['isShowGif'] = false
            }
            this.setState({
                data: nextProps.formItem
            })
        })

        this.setState({
            data: nextProps.formItem
        })
    }

    // 开始划词
    onOcrInfo = (data: any) => {

        this.props.getOcrIcon({code: data.dataCode, checked: true})
        
        if (this.props.startFun) {
            let startFun = this.props.startFun
            let currentData = {
                sort: data.sort,
                dataCode: data.dataCode,
                deCode: data.deCode
            }
            startFun(currentData).then((res: String) => {
                console.log('划词', res);
                this.props.getOcrValue({ text: res, code: data.dataCode })
                this.props.getOcrIcon({code: data.dataCode, checked: false})
            });
        }
    }

    // 退出划词
    exitOcr = (data: any) => {

        this.props.getOcrIcon({code: data.dataCode, checked: false})

        if (this.props.cancleFun) {
            let cancleFun = this.props.cancleFun
            let currentData = {
                sort: data.sort,
                dataCode: data.dataCode,
                deCode: data.deCode
            }
            cancleFun(currentData).then((res: String) => {
                console.log('退出划词', res);
            });
        }
    }


    render() {
        let { data, ocrLists } = this.state;
        let { value } = this.props;
        // isDelimitation: true 手动划词
        return (
            <div className="container">&nbsp;&nbsp;
                {
                    ocrLists && ocrLists.length > 0 &&
                    <>
                        {
                            data.sort && (value && value != '') && 
                            <span className="ocr-num" style={{ backgroundColor: data.isDelimitation ? "#6c96f7" : "#4ad03f" }}>
                                {data.sort}
                            </span>
                        }

                        <ScanOutlined className="ocr-icon" onClick={() => this.onOcrInfo(data)} hidden={data.isShowGif} />
                        <img hidden={!data.isShowGif} src={ocrgif} className="ocr-gif" alt="" onClick={() => this.exitOcr(data)} />
                    </>
                }
            </div>
        )
    }
}


