import React from "react";
import {Button, Steps, Form, Input, Typography} from 'antd';
import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import {withRouter} from "react-router-dom";

const { Title } = Typography;

const { Step } = Steps;

/**
 * 项目报表下载
 */
class ProjectDataDownComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            checkAgreement: false,
            passWordType: 'password'
        }
    }

    loadProject = () => {

    }

    onFinish = () => {

    }

    render() {
        return (
            <></>
        )
    }
}


export default withRouter(ProjectDataDownComponent);