/**
 * 数据中心服务接口
 * meixin
 */
import { requestPostRes, requestGetRes, requestPatchRes, requestPostFileRes, requestPostFile } from '../EdcService';

const urlPrefix = "/data";

/**
 * 获取待处理任务列表
 * @param {} params
 */
export const GetTasks = (params) => {
    const url = urlPrefix + '/tasks';
    return requestGetRes(url, params)
};

/**
 * 获取用户当前的项目
 * @param {} params
 */
export const GetProject = () => {
    const url = '/project/projects';
    return requestGetRes(url)
};

/**
 * 开启下载报告任务
 * @param {} params
 */
export const DownProjectReport = (projectCode, pwd) => {
    const url = `/cdms/report/${projectCode}?pwd=${pwd}`;
    return requestGetRes(url)
};

/**
 * 查询报告是否生成完毕
 * @param {} params
 */
export const GetUrlProjectReport = (fileName) => {
    let env = process.env.REACT_APP_ENV;
    let prePath = env === 'test' ? 'cdms-test' : 'cdms';
    const url = `https://huimei-edc.oss-cn-shanghai.aliyuncs.com/edc/${prePath}/report/${fileName}.xlsx`;
    return requestGetRes(url)
};

/**
 * 获取任务类型
 * @param {} params
 */
export const GetTaskType = () => {
    const url = urlPrefix + '/task/type';
    return requestGetRes(url)
};

/**
 * 根据id获取受试者首页数据
 * @param {} params
 */
export const GetSubjectDetail = (id, param) => {
    const url = urlPrefix + `/subject/${id}`;
    return requestGetRes(url, param)
};

/**
 * 根据访视id获取表单列表
 * @param {} params
 */
export const GetFormsById = (id) => {
    const url = urlPrefix + `/visit/${id}`;
    return requestGetRes(url)
};

/**
 * 待处理事件 点击根据id获取表单列表
 * @param {} params
 */
export const GetSubjectVisitsInput = (id, type) => {
    const url = urlPrefix + `/subject/${id}/visits/${type}`;
    return requestGetRes(url)
};

/**
 * 根据任务类型获取访视详情
 * @param {} params
 */
export const GetVisitsByType = (params) => {
    const url = urlPrefix + `/subject/${params.subjectCode}/visits/${params.type}`;
    return requestGetRes(url)
};

/**
 * 根据表单id获取具体表单模板
 * @param {} params
 */
export const GetFormTemplateById = (id) => {
    const url = urlPrefix + `/form/${id}`;
    return requestGetRes(url)
};

/**
 * 根据项目id获取所属中心列表
 * @param {} params
 */
export const GetCenterByProjectId = (id) => {
    const url = `/project/center/${id}/centers`;
    return requestPostRes(url)
};

/**
 * 根据项目id获取项目分组配置
 * @param {} params
 */
export const getGroupByProjectId = (params) => {
    const url = `/project/groupConfig`;
    return requestGetRes(url, params)
};

/**
 * 添加受试者
 * @param {} params
 */
export const AddSubject = (params) => {
    const url = urlPrefix + `/subject`;
    return requestPostRes(url, params)
};

/**
 * 受试者入组
 * @param {} params
 */
export const SubjectJoin = (id) => {
    const url = urlPrefix + `/subject/join/${id}`;
    return requestPatchRes(url)
};

/**
 * 受试者排除
 * @param {} params
 */
export const SubjectPass = (id) => {
    const url = urlPrefix + `/subject/pass/${id}`;
    return requestPatchRes(url)
};

/**
 * 保存表单
 * @param {} params
 */
export const SaveForm = (id, params) => {
    const url = urlPrefix + `/data`;
    return requestPostRes(url, params)
};

/**
 * 修改数据
 * @param {} params
 */
export const UpdateData = (id, params) => {
    const url = urlPrefix + `/data/update`;
    return requestPostRes(url, params)
};

/**
 * 获取注解类型
 * @param {} params
 */
export const GetAnnotationType = () => {
    const url = urlPrefix + `/data/annotation/types`;
    return requestGetRes(url)
};

/**
 * 数据注解
 * @param {} params
 */
export const AnnotationData = (id, params) => {
    const url = urlPrefix + `/data/annotation`;
    return requestPostRes(url, params)
};

/**
 * 数据备注
 * @param {} params
 */
export const GemarkData = (id, params) => {
    const url = urlPrefix + `/data/remark`;
    return requestPostRes(url, params)
};

/**
 * 更新表单状态
 * @param {} params
 */
export const UpdateFormState = (id, params) => {
    const url = urlPrefix + `/form/state`;
    return requestPostRes(url, params)
};

/**
 * 获取质疑类型
 * @param {} params
 */
export const GetQuestionTypes = () => {
    const url = urlPrefix + `/question/types`;
    return requestGetRes(url)
};

/**
 * 添加质疑
 * @param {} params
 */
export const AddQuestion = (params) => {
    const url = urlPrefix + `/question`;
    return requestPostRes(url, params)
};

/**
 * 回复质疑
 * @param {} params
 */
export const AnswerQuestion = (params) => {
    const url = urlPrefix + `/question/answer`;
    return requestPostRes(url, params)
};

/**
 * 关闭质疑
 * @param {} params
 */
export const CloseQuestion = (params) => {
    const url = urlPrefix + `/question/close`;
    return requestPostRes(url, params)
};

/**
 * 重新打开质疑
 * @param {} params
 */
export const ReopenQuestion = (params) => {
    const url = urlPrefix + `/question/reopen`;
    return requestPostRes(url, params)
};

/**
 * 修改实际访视日期
 * @param {} params
 */
export const ModifyVisitDate = (params) => {
    const url = urlPrefix + `/visit/date`;
    return requestPostRes(url, params)
};

/**
 * 修改预约访视日期
 * @param {} params
 */
export const ModifyAppointmentDate = (params) => {
    const url = urlPrefix + `/visit/appointment`;
    return requestPostRes(url, params)
};

/**
 * 计划访视日期修改
 * @param {} params
 */
export const ModifyPlanDate = (params) => {
    const url = urlPrefix + `/visit/planDate`;
    return requestPostRes(url, params)
};

/**
 * 获取受试者备注type
 * @param {} params
 */
export const GetRemarkType = (params) => {
    const url = `/config/dictionaries/getTypeByCode/SubjectRemarkType`;
    return requestGetRes(url, params)
};

/**
 * 添加受试者备注
 * @param {} params
 */
export const AddRemark = (params) => {
    const url = urlPrefix + `/subject/addRemark`;
    return requestPostRes(url, params)
};

/**
 * 数据稽查
 * @param {} params
 */
export const GetTrance = (params) => {
    const url = urlPrefix + `/form/${params.formId}/data/${params.dataCode}/trace`;
    return requestGetRes(url, params)
};

/**
 * 进度 弃用
 * @param {} params
 */
export const GetProjectSubject = (id, params) => {
    const url = `/project/project/subject/${id}`;
    return requestGetRes(url, params)
};

export const GetProjectChartStat = (subjectCode) => requestGetRes(`/data/staticDate/${subjectCode}`)

/**
 * 受试者工作台统计（v2.4弃用）
 * @param {} params
 */
export const GetProjectStatistic = () => {
    const url = `/project/user/projects/statistic`;
    return requestGetRes(url)
};

/**
 * 根据form的id获取具体表单数据
 * @param {} params
 */
export const GetFormDataById = (params) => {
    const url = `/data/form/${params.id}`;
    return requestGetRes(url)
};

/**
 * 根据form的id和租户获取具体表单数据
 * @param {} params
 */
export const GetFormDataByIdInTenant = (params) => {
    const url = `/data/form/${params.id}?dataTenantCode=${params.tenantCode}`;
    return requestGetRes(url)
};

/**
 * 暂存、提交表单填写的数据
 * @param {} params
 */
export const saveChangedFormData = (params) => {
    const url = `/data/data`;
    return requestPostRes(url, params, {}, { showErrorMsg: false })
};

/**
 * 暂存、提交表单填写的数据
 * @param {} params
 */
export const saveFormData = (params) => {
    const url = `/data/data`;
    return requestPostRes(url, params)
};
//更新表单状态
export const updateFormState = (params) => {
    const url = `/data/form/state`;
    return requestPostRes(url, params)
}
//判断是否能 删除表单数据，关于结算
export const isDisableDelete = (params) => {
    const url = `/data/subject/form/delete`;
    return requestPostRes(url, params)
}
//删除表单数据
export const deleteFormData = (params) => {
    const url = `/data/invalid`;
    return requestPostRes(url, params)
}

export const updateOcrList = (params) => {
    const url = `/data/ocr/update`;
    return requestPostRes(url, params)
}
export const updateChangedFormData = (params) => {
    const url = `/data/data/update`;
    return requestPostRes(url, params, {}, { showErrorMsg: false })
}
export const updateFormData = (params) => {
    const url = `/data/data/update`;
    return requestPostRes(url, params)
}
export const remarkForm = (params) => {
    const url = '/data/data/remark';
    return requestPostRes(url, params)
}

// 获取注释类型
export const getAnnotationType = () => {
    const url = '/data/data/annotation/types';
    return requestGetRes(url);
}
//注释操作
export const annotationForm = (params) => {
    const url = '/data/data/annotation';
    return requestPostRes(url, params);
}
//质疑类型
export const getQuestionType = () => {
    const url = '/data/question/types';
    return requestGetRes(url);
}
//创建质疑
export const questionForm = (params) => {
    const url = '/data/question';
    return requestPostRes(url, params)
}
//创建质疑-管理端
export const dataQuestionings = (params) => {
    const url = '/dct/imageTask/dataQuestionings';
    return requestPostRes(url, params)
}

//关闭质疑
export const closeQuestion = (params) => {
    const url = '/data/question/close';
    return requestPostRes(url, params)
}
//回复质疑
export const answerQuestion = (params) => {
    const url = '/data/question/answer';
    return requestPostRes(url, params)
}
//重新打开质疑
export const reOpenQuestionRes = (params) => {
    const url = '/data/question/reopen';
    return requestPostRes(url, params)
}
//获取痕迹稽查
export const getTrance = (params) => {
    const url = `/data/form/${params.formId}/data/insTime/trace`;
    return requestGetRes(url)

}

// v2.4
/**
 * 受试者工作台统计
 * @param {} params
 */
export const getDataCRCStatistic = (projectCode) => {
    const url = `/balance/task/crc/statistic/${projectCode}`;
    return requestGetRes(url)
};

export const getVisitStatistic = (params) => {
    const url = `/data/api/project/visit/statistic`;
    return requestGetRes(url, params)
}



/**
 * 停止受试原因枚举
 * @param {} params 
 */
export const getStopSubjectEnume = () => {
    const url = `/data/subject/stop/reasons`;
    return requestGetRes(url)
};

/**
 * 暂停受试原因枚举
 * @param {} params 
 */
export const getSuspendSubjectEnume = () => {
    const url = `/data/subject/suspend/reasons`;
    return requestGetRes(url)
};

/**
 * 废弃受试原因枚举
 * @param {} params 
 */
export const getCancelSubjectEnume = () => {
    const url = `/data/subject/cancel/reasons`;
    return requestGetRes(url)
};

/**
 * 暂停受试
 * @param {} params
 */
export const subjectStopOk = (params) => {
    const url = `/data/subject/${params.subjectCode}/stop`;
    return requestPostRes(url, params)
};

/**
 * 临时暂停
 * @param {} params
 */
export const subjectSuspendOk = (params) => {
    const url = `/data/subject/${params.subjectCode}/suspend`;
    return requestPostRes(url, params)
};

/**
 * 永久废弃
 * @param {} params
 */
export const subjectCancelOk = (params) => {
    const url = `/data/subject/${params.subjectCode}/cancel`;
    return requestPostRes(url, params)
};

/**
 * 继续访试
 * @param {} params
 */
export const subjectContinue = (subjectCode) => {
    const url = `/data/subject/${subjectCode}/resume`;
    return requestPostRes(url)
};

/**
 * 获取OSS访问令牌
 * @param {*} params 
 */
export const GetCaseSts = () => {
    let url = `/file/sts/getStsToken`;
    return requestGetRes(url)
};

/**
 * 保存表单数据(crf)
 * @param {*} params 
 */
export const saveCrfForm = (params) => {
    const url = `/data/crfData`;
    return requestPostRes(url, params)
};

/**
 * 数据修改(crf)
 * @param {*} params 
 */
export const updateCrfData = (params) => {
    const url = `/data/data/crfUpdate`;
    return requestPostRes(url, params)
};

/**
 * 查看协议
 * @param {*} params 
 */
export const GetAgreement = (params) => {
    let url = `/data/subjectAgreement/getAgreement?subjectCode=${params.subjectCode}`;
    return requestGetRes(url)
}

// 获取审核操作记录表单详情
export const GetApproveRecordForm = (params) => {
    const url = `/data/approveRecordForm/${params.id}?dataTenantCode=${params.tenantCode}`
    return requestGetRes(url)
};



/**
 * 结算申请项目列表
 * @param {*} params 
 */
export const getSettlementProjects = () => {
    const url = `/project/settlement/projects`;
    return requestGetRes(url)
};

/**
 * 结算申请租户列表
 * @param {*} params 
 */
export const getSettlementTenantList = () => {
    const url = `/project/settlement/tenantList`;
    return requestGetRes(url)
};

/**
 * 待处理结算单
 * @param {*} params 
 */
export const getWaitApproveRecord = (params) => {
    const url = `/data/settlement/waitApproveRecord`;
    return requestPostRes(url, params)
};

/**
 * 已处理结算单
 * @param {*} params 
 */
export const getDealApproveRecord = (params) => {
    const url = `/data/settlement/dealApproveRecord`;
    return requestPostRes(url, params)
};


/**
 * 回传结算文件
 * @param {*} params 
 */
export const postBackSettlement = (params) => {
    const url = `/data/settlement/postBack`;
    return requestPostFileRes(url, params)
};


/**
 * 结算申请查询
 * @param {*} params 
 */
export const getSettlementView = (params) => {
    const url = `/data/settlement/view`;
    return requestPostRes(url, params)
};


/**
 * 单病例签名审核列表
 * @param {*} params 
 */
export const getSignAuditList = (params) => {
    const url = `/data/singleAudit/signAuditList`;
    return requestPostRes(url, params)
};

/**
 * 单病例签名审核
 * @param {*} params 
 */
export const signAudit = (params) => {
    const url = `/data/singleAudit/audit`;
    return requestPostRes(url, params)
};

/**
 * 撤销单病例签名审核
 * @param {*} params 
 */
export const auditRollback = (params) => {
    const url = `/data/singleAudit/auditRollback/${params.id}`;
    return requestPostRes(url)
};

/**
 * 单病例签名可选项目列表
 * @param {*} params 
 */
export const getSignAuditProjectList = () => {
    const url = `/data/singleAudit/projectList`;
    return requestPostRes(url)
};

/**
 * 单病例签名可选租户列表
 * @param {*} params 
 */
export const getSignAuditTenantList = () => {
    const url = `/data/singleAudit/tenantList`;
    return requestPostRes(url)
};

/**
 * 获取签名pdf
 * @param {*} params 
 */
export const getSignPdf = (params) => {
    const url = `/data/singleAudit/getSignPdf`;
    return requestGetRes(url, params)
};

/**
 * 下载病例单
 * @params {*} params
 */
export const downloadCases = (params) => {
    const url = urlPrefix + `/downloadCases`
    return requestGetRes(url, params)
}

/**
 * 签名回传
 * @param {*} params
 */
export const singleAudit = (params) => {
    const url = `/data/singleAudit/sign`;
    return requestPostRes(url, params)
}

/**
 * 病例签名审核状态下拉
 * @param {*} params 
 */
export const getSignAuditStateList = (params) => {
    const url = `/data/singleAudit/signAllStateList`;
    return requestGetRes(url, params)
};

/**
 * 结算审核结算
 * @param {*} params 
 */
export const manualSettlement = (params) => {
    const url = `/data/singleAudit/manual/settlement`;
    return requestPostRes(url, params)
};

/**
 * 结算审核结算撤销
 * @param {*} params 
 */
export const manualSettlementRollback = (params) => {
    const url = `/data/singleAudit/manual/settlement/rollback`;
    return requestPostRes(url, params)
};

/**
 * 结算同步
 * @param {*} params 
 */
export const manualSettlementExcel = (params) => {
    const url = `/data/singleAudit/manual/settlement/excel`;
    return requestPostFile(url, params)
};

/**
 * 项目分组下拉列表
 * @param {} params
 */
export const getGroupPageSelect = (params) => {
    const url = `/project/group/list/selects`;
    return requestGetRes(url, params)
};
