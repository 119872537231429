/**
 * 平台日志模块路由配置
 */
import Operation from '../components/log/operation';     // 操作日志
import LoginLog from '../components/log/loginLog';     // 登录日志

const routes = [
    { 'path': '/log/operation', 'component': Operation },
    { 'path': '/log/login', 'component': LoginLog }
];

export default routes