import React from "react";
import { Upload, message as messager, Modal } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import './imageUpload.less'
import alioss from "ali-oss";
import {
  makeUploadHeaders,
  makeUploadName,
  makeUploadOptions,
} from "../../crf_form/components/ocr-autofill/utils";

class AliUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      fileList: props.imageUrl ? [this.makeThumb(props.imageUrl)] : [],
    };
  }

  componentDidUpdate(prevProps) {
    // 检查 imageUrl 是否发生了变化
    if (prevProps.imageUrl !== this.props.imageUrl) {
      this.setState({
        fileList: this.props.imageUrl
          ? [this.makeThumb(this.props.imageUrl)]
          : [],
      });
    }
  }
  makeThumb = (url, width = 100) => {
    const parsed = new URL(url.split("?").shift());
    const sourceUrl = parsed.toString();
    const match = /\/([a-f0-9]{32})\.(jpg|jpeg|png)$/g.exec(parsed.pathname);
    let uuid = "";
    if (Array.isArray(match)) {
      uuid = match[1];
    }

    return {
      name: parsed.pathname,
      url: sourceUrl,
      uid: uuid,
      thumbUrl: `${sourceUrl}?x-oss-process=image/resize,w_${width},limit_0,m_fill`,
    };
  };
  // 第1步：上传图片
  startUpload = async (file) => {
    const pieces = await makeUploadOptions(messager.error);

    if (!pieces) {
      messager.error("初始化上传参数失败");
      return;
    }

    const [prefix, options] = pieces;

    const client = new alioss(options);

    const pathname = makeUploadName(prefix, file);

    if (!pathname) {
      messager.error("上传缺少必要参数");
      return;
    }

    // 上传图片到OSS
    const result = await client.put(pathname, file, {
      timeout: 600 * 1000,
      headers: makeUploadHeaders(0),
    });

    if (result.res.status !== 200) {
      messager.error("上传失败，请重试！");
      return;
    }
    console.log(result.url);
    // props.onUpload(result.url)
    this.setState(
      {
        fileList: [this.makeThumb(result.url)],
      },
      () => {
        console.log(this);
        this.props.onFileChange(result.url);
      }
    );
  };

  customRequest = async (options) => {
    const key = "aliyun-loading";
    try {
      messager.loading({
        content: "正在上传...",
        duration: 0,
        key,
      });
      await this.startUpload(options.file);
    } catch (error) {
      console.log("?aliupload", error);
    } finally {
      setTimeout(() => messager.destroy(key), 100);
    }
  };

  beforeUploadControl = (file) => {
    console.log(file.size);
    const maxSize = 10 * 1024 * 1024;
    const [_, ext] = file.type.split("/");
    if (![".png", ".jpg", ".jpeg"].includes(`.${ext}`)) {
      messager.error("上传格式不允许");
      return false;
    } else if (file.size > maxSize) {
      messager.error("文件大小不能超过10MB");
    }
  };
  removeFile = (file) => {
    this.setState({
      fileList: [],
    });
  };

  render() {
    const uploadButton = (
      <div>
        <PlusOutlined type="plus" />
        <div>上传图片</div>
      </div>
    );

    return (
      <>
        <Upload
          accept=".png, .jpg, .jpeg"
          listType="picture-card"
          maxCount={1}
          customRequest={this.customRequest}
          beforeUpload={this.beforeUploadControl}
          onPreview={() => {
            this.setState({ previewVisible: true });
          }}
          onRemove={this.removeFile}
          fileList={this.state.fileList}
        >
          {this.state.fileList.length >= `1 ` ? null : uploadButton}
        </Upload>
        <div className="upload_tip">
          图片支持png，jpg，jpeg三种格式，建议比例 16*9，大小不超过10M
        </div>
        <Modal
          width="960px"
          visible={this.state.previewVisible}
          title="预览图片"
          footer={null}
          onCancel={() => {
            this.setState({
              previewVisible: false,
            });
          }}
        >
          <img
            alt={this.props.imageUrl}
            style={{ width: "100%" }}
            src={this.props.imageUrl}
          />
        </Modal>
      </>
    );
  }
}

export default AliUpload;
