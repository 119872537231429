import React, {Component, useEffect, useState} from 'react';
import './planDetail.less';
import {Form, Space, Input, Button, Select, Divider, Steps, Modal} from 'antd';
import {GetPlanDetail} from "../../../../services/design/DesignService";
import {Tabs} from 'antd';
import {clearThreeTitle} from '../../../../util/menuModuleUtil';
import {createFromIconfontCN} from "@ant-design/icons";

const FormItem = Form.Item;
const {TabPane} = Tabs;
const {Option} = Select;
const {Step} = Steps;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2343612_lgp6tttonc9.js',
    ],
});

function PlanDetail(props: any, ref: any) {

    // 详情 总数据
    const [planDetailData, setPlanDetailData] = useState<any>();
    // 详情 头部 数据
    const [planDetailHeader, setPlanDetailHeader] = useState<any>();
    // 详情 body 数据
    const [planDetailBody, setPlanDetailBody] = useState<any>();
    // 历史版本 下拉框集合
    const [versionList, setVersionList] = useState<any>([]);
    // modal 显 隐
    const [visible, setVisible] = useState<any>(false);
    //访视节点 form 表单列表
    const [visibleFormList, setVisibleFormList] = useState<any>([
        {
            title: '入排标准',
            describe: '入排节点',
            list: [
                {
                    name: '入排标准内容'
                },
                {
                    name: '知情同意书'
                }
            ]
        },
        {
            title: '基线访视',
            describe: '基线节点',
            list: [
                {
                    name: '人口统计学'
                },
                {
                    name: '个人病史'
                },
                {
                    name: '家庭病史'
                },
                {
                    name: '历史治疗'
                },
                {
                    name: '并发症'
                },
                {
                    name: '合并用药'
                },
            ]
        },
        {
            title: '初诊',
            describe: '计划节点(0-1天)',
            list: [
                {
                    name: '检查记录'
                },
                {
                    name: '用药记录'
                },
                {
                    name: '并发症'
                },
                {
                    name: '复诊计划'
                }
            ]
        },
        {
            title: '复诊',
            describe: '计划节点(0-1天)',
            list: [
                {
                    name: '检查记录'
                },
                {
                    name: '用药记录'
                },
                {
                    name: '并发症'
                },
            ]
        },
        {
            title: '复诊1',
            describe: '计划节点(0-1天)',
            list: [
                {
                    name: '检查记录'
                },
                {
                    name: '用药记录'
                },
                {
                    name: '并发症'
                },
            ]
        }
    ])

    useEffect(() => {
        getPlanDetail()
        return componentWillUnmount
    }, [])

    /**
     * 组件销毁 处理事务
     */
    const componentWillUnmount = () => {
        clearThreeTitle()
    }

    /**
     * 详情 接口
     */
    const getPlanDetail = () => {
        GetPlanDetail(props.location.state.id).then((res) => {
            if (res) {
                setPlanDetailData(res)
                setPlanDetailHeader(res.header)
                setPlanDetailBody(res.body)
                setVersionList(versionListMap(res.versionList))
            }
        })
    }

    /**
     * 历史版本 下拉框
     */
    const versionListMap = (data: any) => {
        let b: any = [];
        data.forEach((i: string) => {
            b.push({
                code: i,
                name: "v" + i
            })
        })
        return b;
    }

    /**
     * 方案 回掉
     */
    const callback = () => {

    }

    /**
     * 访视节点 click modal
     */
    const handleVisibleModal = () => {
        setVisible(true)
    }

    return (
        <div className={'planDetail'}>
            <div className={'planDetailCon'}>
                <div className={'detail_title'}>
                    <h4>方案详情</h4>
                    <div className={'info'}>
                        <div className={'div1'}>
                            <div>
                                <span>方案名称：</span>
                                {planDetailHeader ? planDetailHeader.name : ''}
                            </div>
                            <div>
                                <span>最新版本：</span>
                                {planDetailHeader ? planDetailHeader.version : ''}
                            </div>
                            <div>
                                <span>应用项目数：</span>
                                {planDetailHeader ? planDetailHeader.applyProjectCount : ''}
                            </div>
                            <div>
                                <span>创建时间：</span>
                                {planDetailHeader ? planDetailHeader.createTime : ''}
                            </div>
                            <div>
                                <span>创建人：</span>
                                {planDetailHeader ? planDetailHeader.createUser : ''}
                            </div>
                        </div>
                        <div className={'div2'}>
                            <div>
                                <span>应用患者数：</span>
                                {planDetailHeader ? planDetailHeader.applyPatientCount : ''}
                            </div>
                            <div>
                                <span>方案类型：</span>
                                {planDetailHeader ? planDetailHeader.planType : ''}
                            </div>
                            <div>
                                <span>状态：</span>
                                {planDetailHeader ? planDetailHeader.planState : ''}
                            </div>
                        </div>
                        <div className={'div3'}>
                            <p>方案介绍：</p>
                            <p>{planDetailHeader ? planDetailHeader.description : '暂无介绍'}</p>
                        </div>
                    </div>
                </div>
                <div className={'detail_statistics'}>
                    <ul>
                        <li>
                            <Space split={<Divider type="vertical"/>}>
                                <div>访视节点</div>
                                <div className={'num'}>12</div>
                            </Space>
                        </li>
                        <li>
                            <Space split={<Divider type="vertical"/>}>
                                <div>表单总数</div>
                                <div className={'num'}>83</div>
                            </Space>
                        </li>
                        <li>
                            <Space split={<Divider type="vertical"/>}>
                                <div>字段总数</div>
                                <div className={'num'}>1663</div>
                            </Space>
                        </li>
                        <li>
                            <Space split={<Divider type="vertical"/>}>
                                <div>逻辑核查</div>
                                <div className={'num'}>218</div>
                            </Space>
                        </li>
                        <li>
                            <Space split={<Divider type="vertical"/>}>
                                <div>逻辑控制</div>
                                <div className={'num'}>112</div>
                            </Space>
                        </li>
                    </ul>
                </div>
                <div className={'detail_con'}>
                    {/*<Tabs defaultActiveKey="1" onChange={callback}>
                        <TabPane tab="方案内容" key="1">
                            <div style={{marginBottom: 20}}>
                                历史版本：
                                {

                                    <Select
                                        allowClear
                                        style={{width: 200}}
                                        defaultValue={planDetailHeader ? planDetailHeader.version : ''}
                                    >
                                        {
                                            versionList && versionList.length != 0 ?
                                                versionList.map((i: any) => (
                                                    <Option
                                                        value={i.code}
                                                        key={i.code}
                                                    >{i.name}</Option>
                                                )) : ''
                                        }
                                    </Select>
                                }
                            </div>
                            <div style={{marginTop: 20}}>
                                <ul>
                                    {
                                        planDetailBody && planDetailBody.nodes ?
                                            planDetailBody.nodes.map((node: any) => (
                                                <li key={node.name}>
                                                    <Button
                                                        style={{width: '120px', border: 'none'}}
                                                    >
                                                        {node.name}
                                                    </Button>
                                                    <span>
                                            {node.forms.map((form: any) => form.name).join("、")}
                                        </span>
                                                </li>
                                            )) : <li></li>
                                    }
                                </ul>
                            </div>
                        </TabPane>
                    </Tabs>*/}
                    <div className={'title'}>
                        <h4>方案内容</h4>
                        <div className={'historySelect'}>
                            {
                                planDetailHeader ?
                                    <Select
                                        allowClear
                                        style={{width: 200}}
                                        defaultValue={planDetailHeader ? planDetailHeader.version : ''}
                                    >
                                        {
                                            versionList && versionList.length != 0 ?
                                                versionList.map((i: any) => (
                                                    <Option
                                                        value={i.code}
                                                        key={i.code}
                                                    >{i.name}</Option>
                                                )) : ''
                                        }
                                    </Select> : ''
                            }
                        </div>
                    </div>
                    <div className={'con'}>
                        <ul className={'scrollCon'}>
                            {
                                visibleFormList && visibleFormList.length != 0 ?
                                    visibleFormList.map((i: any) => (
                                        <li
                                            key={i.title}
                                        >
                                            <div className={'visibleTitle'}>
                                                <div className={'t_left'}>
                                                    <IconFont type={'icondanxuankuang'}/>
                                                </div>
                                                <div className={'t_right'}>
                                                    <p>{i.title}</p>
                                                    <p>{i.describe}</p>
                                                </div>
                                                <div className={'rightIcon'}>
                                                    <IconFont type={'iconxiangyou-copy'}/>
                                                </div>
                                            </div>
                                            <div className="conList" onClick={handleVisibleModal}>
                                                <ul>
                                                    {
                                                        i.list && i.list.length != 0 ?
                                                            i.list.map((u: any) => (
                                                                <li
                                                                    key={u.name}
                                                                >
                                                                    {u.name}
                                                                </li>
                                                            )) : ''
                                                    }
                                                </ul>
                                            </div>
                                        </li>
                                    )) : ''
                            }
                        </ul>
                    </div>
                </div>
            </div>
            {/*访视节点 点击弹框*/}
            <Modal
                className={'designPlanModal'}
                visible={visible}
                onCancel={() => {
                    setVisible(false)
                }}
                width={1102}
                centered
                footer={[]}
            >
                <div style={{height: 300}}>高飞表单</div>
            </Modal>
        </div>
    )
}

export default PlanDetail;