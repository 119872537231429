import { Button, Modal } from 'antd'
import modStyl from './UserInfo.module.scss'

import React, { useContext } from 'react'
import { ArrowRightOutlined } from '@ant-design/icons'
import DictTransform from './DictTransform';
import { visitTaskContext } from '..';

//#region 字典值转换
type Name = 'subjectName' | 'jzcs';

type TransformProps = {
  value: string | number | null;
  name: Name;
}

const Transform: React.FC<TransformProps> = ({name, value})=> {
  // 居住城市
  if(name === 'jzcs') {
    if(value) {
      return <span>{value}</span>
    }
  }

  // 处理患者姓名
  if(name === 'subjectName') {
    if(value) {
      return <span>{(value as string).split('').map((a, i)=> i> 0 ? '*' : a).join('')}</span>
    }
  }
  return null
}
//#endregion

type Props = {
  data: {
    // 本人还是家属
    txr: null | number;
    // 性别
    xb: null | number;
    // 受教育程度
    jycb: null | number;
    // 居住城市
    jzcs: null | string;
    //
    subjectCode: string;
    // 姓名
    subjectName: null | string;
  };
  // 是否换行
  nowrap?: boolean
  // 是否精简
  simple?: boolean
}

const UserInfo:React.FC<Props> = ({data, nowrap, simple})=> {

  const ctx = useContext(visitTaskContext)

  const openSubjectDetail = ()=> {
    ctx.openSubjectModal(data.subjectCode)
  }

  return (
    <div className={modStyl.userInfo} style={nowrap ? {height: 'auto', display: 'inline-flex'} : {}}>
      <div className={modStyl.r}>
        <Transform value={data.subjectName} name="subjectName"/>
        <DictTransform value={data.xb} name="gender"/>
        <DictTransform value={data.txr} name="whois"/>
        {
          !simple && (
            <>
              <DictTransform value={data.jycb} name="qual"/>
              <Transform value={data.jzcs ?? '--'} name="jzcs"/>
            </>
          )
        }
      </div>
      <div className={modStyl.r}>
        <span>({data.subjectCode})</span>
        <Button onClick={openSubjectDetail} style={{color: '#39f'}} size='small' type="link">查看<ArrowRightOutlined/></Button>
      </div>
    </div>
  )
}

UserInfo.defaultProps = {
  nowrap: false,
  simple: false,
}

export default UserInfo