/**
 *
 * 3-事件登记
 * 
 */
import React, { Component } from 'react';
import { Modal, Button, Form, Input, Select, Radio, Upload, message, Progress, DatePicker } from 'antd';
import { createFromIconfontCN } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/zh_CN';
import axios from 'axios';
import '../modalOperate.less';
import { eventTypeList, layout, untowardEffectList, eventDegreeList, evaluateList, AEeventClassList, AEeventLevelList } from '../select/select';

const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

const rules = [
    {
        required: true,
        message: '必填项未填',
    }
]
const w180 = { width: 180 };

/**药物不良反应 */
const stopType_1_formItemList = [
    { key: 0, label: '不良反应分类', name: 'untoward-effect', list: untowardEffectList, required: true },
    { key: 1, label: '不良反应程度', name: 'event-degree', list: eventDegreeList, required: true },
    { key: 2, label: '相关性评定', name: 'evaluate', list: evaluateList, required: true },
]

/**不良事件 */
const stopType_2_formItemList = [
    { key: 0, label: 'AE事件分类', name: 'AE-eventClass', list: AEeventClassList, required: true },
    { key: 1, label: 'AE事件程度', name: 'AE-event-degree', list: eventDegreeList, required: true },
    { key: 2, label: 'AE事件分级', name: 'AE-evaluate', list: AEeventLevelList, required: false },
]


class EventRegister extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stopType: 0, // 默认暂停类型
            mergeMedicine: 0, // 有无合并用药
        }
    }
    formRef = React.createRef();

    componentWillMount() {

    }

    /**
     * 
     * @returns 插入
     */
    onSubmit = (values) => {
        let { stopType } = this.state;

        const form = this.formRef.current;
        const valuesNew = form.getFieldsValue(['radioGroup-type']);

        if (stopType == 0) { // 不良事件

        } else if (stopType == 1) { // 药物不良反应

        }
    }

    onChange = (item) => {
    }
    onSearch = (item) => {
    }

    /**
     * 类型切换
     */
    onTypeChange = (e) => {
        this.setState({
            stopType: e.target.value,
        })
    }

    /**合并用药 */
    onMedicineChange = (e) => {
        this.setState({
            mergeMedicine: e.target.value
        })
    }


    render() {
        let { stopType, mergeMedicine } = this.state;

        return (
            <div className={'operateContent'}>
                <Form
                    {...layout}
                    name="nest-messages"
                    onFinish={this.onSubmit}
                    requiredMark={true}
                    ref={this.formRef}
                    initialValues={{
                        'radioGroup-type': 0,
                        'untoward-effect': 0,
                        'event-degree': 0,
                        'evaluate': 0,
                        'AE-eventClass': 0,
                        'AE-event-degree': 0,
                        'AE-evaluate': 0,
                        'merge-medicine': 0,
                        'medicine-name': '',
                        'medicine-use': '',
                        'AE-relevance': 0,
                        'medicine-dosage': '',
                        'start-date': '',
                        'end-date': ''
                    }}
                >

                    {/* 类型 */}
                    <Form.Item
                        label={'病史类型'}
                        name='radioGroup-type'
                        rules={rules}
                    >
                        <Radio.Group
                            value={stopType}
                            onChange={this.onTypeChange}
                            buttonStyle="solid"
                        >
                            {
                                eventTypeList.map(item => {
                                    return (
                                        <Radio.Button value={item.value} key={item.value}>{item.name}</Radio.Button>
                                    )
                                })
                            }

                        </Radio.Group>
                    </Form.Item>

                    {
                        stopType == 1 ?
                            <>
                                {
                                    stopType_1_formItemList.map((item) => {

                                        return (
                                            <Form.Item
                                                label={item.label}
                                                name={item.name}
                                                rules={item.required && rules}
                                                key={item.key}
                                            >
                                                <Select
                                                    showSearch
                                                    style={w180}
                                                    placeholder=""
                                                    onChange={() => this.onChange(item)}
                                                    onSearch={() => this.onSearch(item)}
                                                >
                                                    {
                                                        item.list.map((i, index) => {
                                                            return (
                                                                <Select.Option value={i.value} key={index}>{i.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        )
                                    })
                                }
                            </> :
                            <>
                                {
                                    stopType_2_formItemList.map((item) => {

                                        return (
                                            <Form.Item
                                                label={item.label}
                                                name={item.name}
                                                rules={item.required && rules}
                                                key={item.key}
                                            >
                                                <Select
                                                    showSearch
                                                    style={w180}
                                                    placeholder=""
                                                    onChange={() => this.onChange(item)}
                                                    onSearch={() => this.onSearch(item)}
                                                >
                                                    {
                                                        item.list.map((i, index) => {
                                                            return (
                                                                <Select.Option value={i.value} key={index}>{i.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        )
                                    })
                                }

                                <Form.Item
                                    name="merge-medicine"
                                    label="合并用药"
                                    rules={rules}
                                    onChange={this.onMedicineChange}
                                >
                                    <Radio.Group value={mergeMedicine}>
                                        <Radio value={0} >无合并用药</Radio>
                                        <Radio value={1} >有合并用药</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                {
                                    mergeMedicine == 1 &&
                                    <>
                                        <Form.Item
                                            label={'药物名称'}
                                            name='medicine-name'
                                            rules={rules}
                                        >
                                            <Input placeholder='请输入药物名称' />
                                        </Form.Item>
                                        <Form.Item
                                            label={'药物使用症'}
                                            name='medicine-use'
                                            rules={rules}
                                        >
                                            <Input placeholder='请输入药物使用症' />
                                        </Form.Item>

                                        <Form.Item
                                            name="AE-relevance"
                                            label="AE相关性"
                                            rules={rules}
                                        >
                                            <Radio.Group >
                                                <Radio value={0} >相关</Radio>
                                                <Radio value={1} >不相关</Radio>
                                            </Radio.Group>
                                        </Form.Item>

                                        <Form.Item
                                            label={'用药剂量'}
                                            name='medicine-dosage'
                                            rules={rules}
                                        >
                                            <Input placeholder='请输入用药剂量' />
                                        </Form.Item>

                                        <Form.Item label="开始用药时间" name='start-date' rules={rules}>
                                            <DatePicker onChange={this.onStartDate} locale={locale} />
                                            
                                        </Form.Item>
                                        <Form.Item label="结束用药时间" name='end-date'>
                                            <DatePicker onChange={this.onEndDate} locale={locale} />
                                        </Form.Item>
                                    </>
                                }

                            </>
                    }

                    <Form.Item label={stopType == 1 ? '不良反应描述' : "AE事件描述"} name='remark'>
                        <Input.TextArea />
                    </Form.Item>
                </Form>
                <div className={'submit'}>
                    <Button
                        key="submit"
                        type="primary"
                        onClick={this.onSubmit}
                    >
                        插入
                        </Button>
                </div>
            </div>
        );
    }
}

export default EventRegister;