/**
 * 项目中心服务接口
 * meixin
 */
import { requestPostFile, requestPost, requestGet, requestPostRes, requestGetRes, requestPatchRes, requestPostFileRes } from '../EdcService';

const urlPrefix = "/project";



/**
 * 全部禁用、启用用户
 * @param {*} params 
 * @returns 
 */
export const updateAll = (params) =>{
    const url = urlPrefix + "/user/updateALL";
    return requestPostRes(url, params)
}
/**
 * 批量禁用、启用用户
 * @param {} params 
 * @returns 
 */
export const batchStopUser = (params) =>{
    const url = urlPrefix + "/user/update";
    return requestPostRes(url, params)
}

/**
 * 添加项目
 * @param {} params
 */
export const addNewProject = (params) => {
    const url = urlPrefix + "/save";
    return requestPostRes(url, params)
};

/**
 * 更新项目设置
 * @param {} params
 */
export const ModifyNewProject = (params) => {
    const url = urlPrefix + "/modify";
    return requestPostRes(url, params)
};


/**
 * 获取项目下拉状态列表
 * @param {} params
 */
export const GetProjectStatusList = (params) => {
    const url = urlPrefix + "/status/list";
    return requestGet(url, params)
};

/**
 * 获取项目方案列表
 * @param {} params
 */
export const GetGraftList = (params) => {
    const url = "/design/plan/listBytenant";
    return requestPost(url, params)
};

/**
 * 获取项目类别列表 (方案类型)
 * @param {} params
 */
export const GetConfigTypeList = (params) => {
    const url = "/design/plan/selects";
    return requestGet(url, params)
};

/**
 * 获取项目类别列表
 * @param {} params
 */
export const GetConfigProjectTypeList = (params) => {
    const url = "/project/selects";
    return requestGet(url, params)
};

/**
 * 获取项目的详细信息
 * @param {} params
 */
export const GetProjectDetailInfo = (id) => {
    const url = `/project/detail/${id}/info`;
    return requestGet(url)
};

/**
 * 获取项目的监控信息
 * @param {} params
 */
export const GetProjectMonitor = (id, params) => {
    const url = urlPrefix + `/project/${id}/monitor`;
    return requestPost(url, params)
};

/**
 * 获取项目研究中心列表
 * @param {} params
 */
export const GetProjectCenterList = (params) => {
    const url = `/project/center/list`;
    return requestPost(url, params)
};

/**
 * 添加项目的研究中心
 * @param {} params
 */
export const AddProjectCenter = (params) => {
    const url = `/project/center/add`;
    return requestPost(url, params)
};


/**
 * 修改项目的研究中心
 * @param {} params
 */
export const ModifyProjectCenter = (params) => {
    const url = `/project/center/modify`;
    return requestPost(url, params)
};

/**
 * 更新研究中心状态
 * @param {} params
 */
export const UpdateProjectCenterStatus = (id, params) => {
    const url = `/project/center/update/${id}`;
    return requestPost(url, params)
};

/**
 * 删除项目的研究中心
 * @param {} params
 */
export const DeleteProjectCenter = (id, params) => {
    const url = `/project/center/delete/${id}`;
    return requestPost(url, params)
};

/**
 * 获取项目的研究中心列表分页数据
 * @param {} params
 */
export const GetProjectCenterPageList = (params) => {
    const url = `/project/center/list/pages`;
    return requestPost(url, params)
};

/**
 * 获取项目参与者列表(下拉集合数据)
 * @param {} params
 */
export const GetProjectAllocationList = (params) => {
    const url = `/project/user/list`;
    return requestPost(url, params)
};

/**
 * 获取项目参与者列表分页数据
 * @param {} params
 */
export const GetProjectUserPageList = (params) => {
    const url = `/project/user/list/pages`;
    return requestPost(url, params)
};

/**
 * 添加项目的参与者
 * @param {} params
 */
export const AddProjectAllocation = (params) => {
    const url = `/project/user/add`;
    return requestPostRes(url, params)
};

/**
 * 修改项目的参与者
 * @param {} params
 */
export const ModifyProjectAllocation = (params) => {
    const url = `/project/user/modify`;
    return requestPostRes(url, params)
};


/**
 * 更新参与者状态
 * @param {} params
 */
export const UpdateProjectAllocationStatus = (id, params) => {
    const url = `/project/user/update/${id}`;
    return requestPost(url, params)
};


/**
 * 删除项目的参与者
 * @param {} params
 */
export const DeleteProjectAllocation = (id, params) => {
    const url = `/project/user/delete/${id}`;
    return requestPostRes(url, params)
};

/**
 * 项目参与者 上传文件
 * @param {} params
 */
export const GetProjectUploadUser = (id, params) => {
    const url = `/project/user/uploadUser/${id}`;
    return requestPostFile(url, params)
};

/**
 * 项目参与者 上传文件进度
 * @param {} params
 */
export const GetProjectUploadProgress = (params) => {
    const url = `/project/user/uploadUser/${params}`;
    return requestGet(url)
};


/**
 * 获取项目区域列表
 * @param {} params
 */
export const GetProjectAreaList = (params) => {
    const url = `/project/area/list`;
    return requestPost(url, params)
};

/**
 * 添加项目的区域
 * @param {} params
 */
export const AddProjectArea = (params) => {
    const url = `/project/area/add`;
    return requestPost(url, params)
};

/**
 * 修改项目的区域
 * @param {} params
 */
export const ModifyProjectArea = (params) => {
    const url = `/project/area/modify`;
    return requestPost(url, params)
};


/**
 * 更新区域状态
 * @param {} params
 */
export const UpdateProjectAreaStatus = (id, params) => {
    const url = `/project/area/update/${id}`;
    return requestPost(url, params)
};


/**
 * 删除项目的区域
 * @param {} params
 */
export const DeleteProjectArea = (id, params) => {
    const url = `/project/area/delete/${id}`;
    return requestPost(url, params)
};

/**
 * 获取租户信息
 * @param {} params
 */
export const GetTenantUsers = (id, params) => {
    const url = `/plat/tenant/users`;
    return requestPost(url, params)
};

/**
 * 获取租户 下的参与者用户列表
 * @param {} params
 */
export const GetTenantUserSelects = (params) => {
    const url = `/plat/tenant/userSelects`;
    return requestGet(url, params)
};

/**
 * 获取项目区域的中心下拉集合接口
 * @param {} params
 */
export const GetAreaCenterSelect = (projectId, areaCode, params) => {
    const url = urlPrefix + `/project/${projectId}/center/${areaCode}/selects`;
    return requestPost(url, params)
};

/**
 * 获取项目中心的参与者下拉集合接口
 * @param {} params
 */
export const GetCenterUserSelect = (projectId, centerCode, params) => {
    const url = urlPrefix + `/project/${projectId}/user/${centerCode}/selects`;
    return requestPost(url, params)
};

/**
 * 获取参与者的中心下拉接口
 * @param {} params
 */
export const GetCenterByProjectId = (params) => {
    const url = urlPrefix + `/center/center/select`;
    return requestPost(url, params)
};

/**
 * 项目月报-报表类型
 * @param {} params
 */
export const getReportTypes = (params) => {
    const url = `/project/report/types`;
    return requestGet(url, params)
};

/**
 * 项目月报-列表页
 * @param {} params
 */
export const getReportData = (params) => {
    const url = `/project/report`;
    return requestPostRes(url, params)
};

/**
 * 项目月报-重新生成
 * @param {} params
 */
export const refreshReport = (id) => {
    const url = `/project/report/regenerate/${id}`;
    return requestPostRes(url)
};

/**
 * 项目月报-月报概要
 * @param {} params
 */
export const getReportSummary = (id) => {
    const url = `/project/report/${id}`;
    return requestGetRes(url)
};

/**
 * 项目月报-月报明细
 * @param {} params
 */
export const getReportDetailData = (params, currentRole) => {
    let url = `/project/report/detail`;
    if (currentRole == 'EDC_AM') {
        url += `?role=${currentRole}`;
    }
    return requestPostRes(url, params)
};

/**
 * 项目月报-月报下载
 * @param {} params
 */
export const getReportDownload = (id, role) => {
    let url = `/project/report/${id}`;
    if (role == 'EDC_AM') {
        url += `?role=${role}`;
    }
    return requestPatchRes(url)
};

// /**
//  * 结算管理-账单查询
//  * @param {} params
//  */
export const getMainBillList = (params) => {
    const url = `/settlement/billmain/main/bills`;
    return requestPostRes(url, params)
};

export const editBillStatus = (params) => {
    const url = `/settlement/billmain/edit/status?mainId=${params.mainId}&status=${params.status}`;
    return requestPostRes(url)
};

/**
 * 结算管理-结算密码
 * @param {} params
 */
export const verifyPwd = (params) => {
    const url = `/settlement/settlementpwd/verify/pwd?settlePwd=${params.settlePwd}&projectCode=${params.projectCode}`;
    return requestPostRes(url, params)
};

/**
 * 结算管理-项目进度统计表
 * @param {} params
 */
export const billDetailList = (params) => {
    const url = `/settlement/billdetail/all/bills`;
    return requestPostRes(url, params)
};

/**
 * 结算管理-获取账单基本信息
 * @param {*} params 
 */
export const getBasicInfo = (params) => {
    const url = `/settlement/billmain/main/${params.mainId}`
    return requestPostRes(url)
}


/* * 结算管理-账单状态枚举
 * @param {} params
 */
export const getStatusList = () => {
    const url = `/settlement/settlementSubjectStatus/status`;
    return requestGet(url)
};

/* * 结算管理-批量作废账单
 * @param {} params
 */
export const billRemove = (params) => {
    const url = `/settlement/billdetail/remove?ids=${params}`;
    return requestPostRes(url)
};

/* * 结算管理-批量清零账单
 * @param {} params
 */
export const confirmClearZero = (params) => {
    const url = `/settlement/billdetail/clear/zero?mainId=${params}`;
    return requestPostRes(url, params);
};

/* * 结算管理-获取批量清零账单数
 * @param {} params
 */
export const confirmClearZeroNum = (params) => {
    const url = `/settlement/billdetail/get/number?mainId=${params}`;
    return requestPostRes(url, params);
};

/* * 结算管理-修改单条本次结算
 * @param {} params
 */
export const singleBillUpdate = (params) => {
    const url = `/settlement/billdetail/update`;
    return requestPostRes(url, params)
};

/* * 结算管理-协议作废全部
 * @param {} params
 */
export const signAllCancel = (params) => {
    const url = `/settlement/billmain/allcancel?mainId=${params}`;
    return requestPostRes(url)
};

/**
 * 结算管理-签署协议下载全部协议
 */
export const downloadAllAgreement = (params) => {
    const url = `/settlement/settlementAgreement/download`;
    return requestGetRes(url, params);
};

/* * 结算管理-单条查看用户协议详情
 * @param {} params
 */
export const settlementAgreementList = (params) => {
    const url = `/settlement/settlementAgreement/detail/${params}`;
    return requestGetRes(url)
};

export const uploadSettlement = (params) => {
    const url = `/settlement/billdetail/upload`
    return requestPostFileRes(url, params)
}

/* * 结算管理-作废单条协议
 * @param {} params
 */
export const signSingleCancel = (params) => {
    const url = `/settlement/billdetail/cancel/bill?id=${params}`;
    return requestPostRes(url)
};

/**结算管理-立即生成账单
 * @param {} params 
 */
export const immediatelyCreate = (params) => {
    const url = `/settlement/billmain/create`
    return requestPostRes(url, params)
}

/**结算管理-本次不结算
 * @param {*} params 
 */
export const cancelSettlement = (params) => {
    const url = `/settlement/billmain/allremove?mainId=${params}`
    return requestPostRes(url)
}

/**结算管理-单条不勾选或勾选
 * @params {} params
 */
export const singleCheck = (params) => {
    const url = `/settlement/billdetail/signalremove`
    return requestPostRes(url, params)
}

/**结算管理-同步至第三方
 * @param {} params 
 */
export const syncThird = (params) => {
    const url = `/settlement/settleapi/settlementApply/${params}`
    return requestPostRes(url)
}

/**结算管理-服务协议拒绝
 * @param {} params 
 */
export const rejectSign = (params) => {
    const url = `/settlement/settlementAgreement/reject`
    return requestPostRes(url, params)
}





/**
 * 积分管理-账单月份查询
 * @param {} params
 */
export const getBillMonthQuery = (projectCode) => {
    const url = `/balance/bill/month/query/${projectCode}`;
    return requestGet(url)
};

/**
 * 积分管理-账单查询
 * @param {} params
 */
export const getBillQuery = (params) => {
    const url = `/balance/bill/query`;
    return requestPostRes(url, params)
};

/**
 * 积分管理-服务协议列表
 */
export const getLaborAgreementQuery = (params) => {
    const url = `/balance/laborAgreement/query`;
    return requestPostRes(url, params)
};

/**
 * 积分管理-服务协议详情
 */
export const getLaborAgreementDetail = (id) => {
    const url = `/balance/laborAgreement/detail/${id}`;
    return requestGet(url)
};

/**
 * 积分管理-账单明细锁定
 * @param {} params
 */
export const getBillRecordLock = (detailId) => {
    const url = `/balance/bill/record/lock/${detailId}`;
    return requestPostRes(url)
};

/**
 * 积分管理-账单明细解锁
 * @param {} params
 */
export const getBillRecordUnlock = (detailId) => {
    const url = `/balance/bill/record/unLock/${detailId}`;
    return requestPostRes(url)
};

/**
 * 积分管理-生成积分兑换单
 * @param {} params
 */
export const getBillGenerate = (billId) => {
    const url = `/balance/bill/generate/${billId}`;
    return requestPostRes(url)
};

/**
 * 积分管理-撤消兑换单
 * @param {} params
 */
export const getBillCancel = (billId) => {
    const url = `/balance/bill/cancel/${billId}`;
    return requestPostRes(url)
};

/**
 * 积分管理-兑换确认
 * @param {} params
 */
export const getBillSure = (billId) => {
    const url = `/balance/bill/sure/${billId}`;
    return requestPostRes(url)
};

/**
 * 积分管理-批量编辑
 * @param {} params
 */
export const getBillBatchSettle = (params) => {
    const url = `/balance/bill/record/batch/settle`;
    return requestPostRes(url, params)
};

/**
 * 积分管理-区域信息(展示所有子节点)
 * @param {} params
 */
export const getAreaData = (projectId) => {
    const url = `/project/project/${projectId}/area`;
    return requestPostRes(url)
};
/**
 * 积分管理-区域信息(加载子节点)
 * @param {} params
 */
export const getAreaNodeData = (projectId, areaId) => {
    const url = `/project/project/${projectId}/area/${areaId}`;
    return requestPostRes(url)
};

/**
 * 积分管理-中心信息
 * @param {} params
 */
export const getCenterData = (projectId) => {
    const url = `/project/project/${projectId}/center/selects`;
    return requestPostRes(url)
};

/**
 * 积分管理-中心下拉集合
 * @param {} params
 */
export const getCenterSelectData = (projectId, areaCode) => {
    const url = `/project/project/${projectId}/center/${areaCode}/selects`;
    return requestPostRes(url)
};

/**
 * 积分管理-日期修改确认
 * @param {} params
 */
export const getBillManual = (params) => {
    const url = `/balance/bill/manual`;
    return requestPostRes(url, params)
};

/**
 * 积分管理-结算修改
 * @param {} params
 */
export const getBillRecordSettle = (detailId, planSettleNum) => {
    const url = `/balance/bill/record/settle/${detailId}/${planSettleNum}`;
    return requestPostRes(url)
};

/**
 * 积分管理-导出积分兑换单
 * @param {} params
 */
export const balanceBillExport = (id) => {
    const url = `/balance/bill/record/${id}/export`;
    return requestGetRes(url)
};

/**
 * /查找参与者
 */
export const getUsersTop = (projectId, params) => {
    const url = `project/${projectId}/users/top`;
    return requestGetRes(url, params)
};

/**
 * /受试者转移数据查询
 */
export const subjectTransferQuery = (userCode, centerId, projectId) => {
    const url = `project/user/query/${userCode}/${centerId}/${projectId}`;
    return requestGetRes(url)
};

/**
 * /受试者转移
 */
export const subjectTransfer = (params) => {
    const url = `project/user/subject/transfer`;
    return requestPostRes(url, params)
};

/**
 * /参与者中心转移
 */
export const subjectCenterTransfer = (params) => {
    const url = `project/user/subject/centerTransfer`;
    return requestPostRes(url, params)
};

/**
 * 更新参与者状态
 */
export const updateuserState = (id, params) => {
    const url = `project/user/update/${id}`;
    return requestPostRes(url, params)
};


/**
 * 撤销服务协议
 */
export const laborAgreementCancel = (param) => {
    const url = `/balance/laborAgreement/cancel/${param.billId}`;
    return requestPostRes(url, param)
};

/**
 * 生成付款申请单
 */
export const createPayment = (id) => {
    const url = `/balance/laborAgreement/createPayment/${id}`;
    return requestPostRes(url)
};

/**
 * 查询付款申请单
 */
export const paymentList = (params) => {
    const url = `/balance/laborAgreement/paymentList`;
    return requestPostRes(url, params)
};

/**
 * 确认付款 
 */
export const confirmLaborPayment = (id) => {
    const url = `/balance/laborAgreement/pay/${id}`;
    return requestPostRes(url)
};

/**
 * 批量确认（积分超过可兑换数） 
 */
export const confirmBatchSettleConfirm = (params) => {
    const url = `/balance/bill/api/batch/settle/confirm`;
    return requestPostRes(url, params);
};

/**
 * 批量上传结算 
 */
export const confirmBatchSettleUpload = (params) => {
    const url = `/balance/bill/api/batch/settle/upload`;
    return requestPostFile(url, params);
};

/**
 * 下载服务协议(只传agreementId是单个，只传billId是批量)
 */
export const getlaborAgreementDownload = (params) => {
    const url = `/balance/laborAgreement/download/agreement`;
    return requestGetRes(url, params);
};

/**
 * 查看生成服务协议的进度条
 */
export const getLaborAgreementTaskProcess = (billId) => {
    const url = `/balance/laborAgreement/task/process/${billId}`;
    return requestPostRes(url);
};

/**
 * 服务协议审核
 */
export const getLaborAgreementApprove = (params) => {
    const url = `/balance/laborAgreement/approve`;
    return requestPostRes(url, params);
};

/**
 * 获取参与者资料
 */
export const getUserMaterial = (params) => {
    const url = `/project/user/getUserMaterial`;
    return requestGetRes(url, params);
};

/**
 * 编辑参与者资料
 */
export const editUserMaterial = (params) => {
    const url = `/project/user/editUserMaterial`;
    return requestPostRes(url, params);
};


/**
 * 项目类型列表
 */
export const getProjectTypeList = (params) => {
    const url = `/project/typeList`;
    return requestGetRes(url, params);
};

/**
 * 财税平台结算申请列表
 */
export const getSettlementApplyList = (params) => {
    const url = `/balance/laborAgreement/settlementApplyList`;
    return requestPostRes(url, params);
};

/**
 * 财税平台同步结算
 */
export const settlementApply = (params) => {
    const url = `/balance/laborAgreement/settlementApply/${params.billId}`;
    return requestPostRes(url, params);
};


/**
 * 项目是否有财税同步权限
 */
export const getSettlementPermission = (params) => {
    const url = `/project/settlementPermission/${params.projectCode}`;
    return requestPostRes(url)
};



/**
 * 单个服务协议撤回
 */
export const singleCancel = (params) => {
    const url = `/balance/laborAgreement/singleCancel/${params.billDetailId}`;
    return requestPostRes(url);
};


/**
 * 获取lg结算证据列表
 */
export const getEvidenceSubjectList = (params) => {
    const url = `/settlement/settleapi/lg/getEvidenceSubjects`;
    return requestPostRes(url, params);
};

/**
 * lg受试者首页详情
 */
export const getLgSubjectDetail = (params) => {
    const url = `/data/subject/lg/${params.subjectCode}`;
    return requestGetRes(url);
};

/**
 * lg访视详情
 */
export const getLgVisit = (params) => {
    const sourceType = params.sourceType
    const url = (sourceType == 'lg') ? `/data/visit/lg/${params.id}` : `/data/visit/${params.id}`;
    return requestGetRes(url);
};

/**
 * lg获取表单详情
 */
export const getLgForm = (params) => {
    const sourceType = params.sourceType
    delete params.sourceType
    const url = (sourceType == 'lg') ? `/data/form/lg/${params.id}` : `/data/form/${params.id}`;
    return requestGetRes(url, params);
};



/**
 * 获取结算证据列表
 */
export const getEvidenceList = (params) => {
    const url = `/balance/settlement/evidenceList`;
    return requestPostRes(url, params);
};


/**
 * 获取医生编码
 * @param {*} params 
 */
export const getYsbmApi = (id) => {
    const url = urlPrefix + `/user/getYsbm?projectId=${id}`;
    return requestPostRes(url)
};

/**
 * 获取分组列表(下拉集合数据)
 * @param {} params
 */
// export const GetProjectAllocationList = (params) => {
//     const url = `/project/user/list`;
//     return requestPost(url, params)
// };

/**
 * 获取分组列表分页数据
 * @param {} params
 */
export const GetGroupPageList = (params) => {
    const url = `/project/group/page`;
    return requestPost(url, params)
};

/**
 * 添加项目分组列表
 * @param {} params
 */
export const AddGroupPage = (params) => {
    const url = `/project/group/save`;
    return requestPostRes(url, params)
};

/**
 * 删除项目分组列表
 * @param {} params
 */
export const DeleteGroupPage = (id) => {
    const url = `/project/group/delete/${id}`;
    return requestGetRes(url)
};