import React, { useState, useEffect, useCallback, ChangeEvent, useMemo } from "react";
import { Button, Col, Empty, Input, List, message as messager, Modal, Radio, Row, Typography } from "antd";
import storageX from "../../../../../../util/storageX";
import styl from "./TransoutModal.module.scss";
import { transoutSubject } from "../../../../../../services/project/project";
import {
  GetCenterByProjectId,
  subjectTransferQuery,
} from "../../../../../../services/project/ProjectService";
import debounce from "lodash/debounce";

type TransoutModalProps = {
  visible: boolean;
  projectId: number;
  subjectCode: string;
  onClose: () => void;
  onTransSuccess: () => void;
};

type CenterItem = {
  id: number;
  code: string;
  title: string;
  type: string;
  value: string;
};

type DoctorItem = {
  code: string;
  name: string;
}

type DoctorData = {
  userCode: string;
  userName: string;
  centerId: number;
  centerName: string;
  totalSubjects: number;
  tenantUserDtos: DoctorItem[]
}

type QueryResult<T> = {
  code: number;
  message: string;
  data: T
}

const TransoutModal = (props: TransoutModalProps) => {
  const [visible, setVisible] = useState(false);
  const [centerId, setCenterId] = useState(0);
  const [projectId, setProjectId] = useState(0);
  const [centerSource, setCenterSource] = useState<CenterItem[]>([]);
  const [doctorSource, setDoctorSource] = useState<DoctorItem[]>([]);
  const [centerSourceOrigin, setCenterSourceOrigin] = useState<CenterItem[]>([]);
  const [doctorSourceOrigin, setDoctorSourceOrigin] = useState<DoctorItem[]>([]);
  const [doctorCode, setDoctorCode] = useState('')
  const [user] = useState(()=> storageX().user())
  const [loading, setLoading] = useState(false)
  const [doctorKeyword, setDoctorKeyword] = useState('')
  const [centerKeyword, setCenterKeyword] = useState('')

  const targetName = sessionStorage.getItem('targetName') || '受试者';
  const initState = ()=> {
    setCenterId(0);
    setDoctorCode('');
    setDoctorSource([]);
    setDoctorSourceOrigin([]);
  }

  useEffect(() => {
    if (props.visible && props.projectId) {
      // 重置
      initState()
      setProjectId(props.projectId);
      GetCenterByProjectId({ projectId: props.projectId })
        .then((data: CenterItem[]) => {
          setCenterSource(data);
          setCenterSourceOrigin(data);
        })
        .catch(() => {});
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [props.visible, props.projectId]);

  const fetchUser = async (item: CenterItem) => {
    if (item.id === centerId) {
      return;
    }

    setLoading(true)
    
    const {code, message, data}: QueryResult<DoctorData> = await subjectTransferQuery(
      user.userCode,
      item.id,
      projectId
    );

    setLoading(false)
    
    if(code !== 0) {
      messager.error(message)
      return
    }
    
    setCenterId(item.id);
    setDoctorSource(data.tenantUserDtos)
    setDoctorSourceOrigin(data.tenantUserDtos)
  };

  const checkDoctor = (item: DoctorItem)=> {
    setDoctorCode(item.code)
  }

  const onConfirm = async () => {
    const {code, message}: QueryResult<any> = await transoutSubject({
      projectId,
      centerId,
      transferCode: user.userCode,
      recipientCode: doctorCode,
      totalSubjects: 1,
      subjectCodes: [props.subjectCode]
    })

    if(code === 0) {
      onCancel()
      props.onTransSuccess()
      messager.success(`${targetName}已转移`, 3, ()=> {
        
      })
    } else {
      messager.error(message)
    }
  };

  const onCancel = () => {
    props.onClose();
    setVisible(false);
  };

  // 
  const onSearchCenter = (value: string)=> {
    initState()
    setCenterSource(()=> centerSourceOrigin.filter(({title})=> title.indexOf(value) > -1))
  }

  const onSearchDoctor = (value: string)=> {
    setDoctorCode('')
    setDoctorSource(()=> doctorSourceOrigin.filter(({name})=> name.indexOf(value) > -1))
  }

  //
  const centerDebounce = useMemo(()=> debounce((value)=> onSearchCenter(value), 500), [centerSourceOrigin])
  // 
  const doctorDebounce = useMemo(()=> debounce((value)=> onSearchDoctor(value), 500), [doctorSourceOrigin])
  // 用户输入搜索时
  const onChange = (event: ChangeEvent)=> {
    const el = event.target as HTMLInputElement
    if(el.name === 'center') {
      setCenterKeyword(el.value)
      centerDebounce(el.value)
    } else {
      setDoctorKeyword(el.value)
      doctorDebounce(el.value)
    }
  }

  return (
    <Modal
      bodyStyle={{ background: "#F6F7FC" }}
      footer={null}
      width="640px"
      title={`转移${targetName}`}
      visible={visible}
      onCancel={onCancel}
      keyboard={false}
      maskClosable={false}
    >
      <Row gutter={12}>
        <Col span={11}>
          <Typography.Title style={{fontSize: 14}} level={5}>中心列表</Typography.Title>
          <Input.Search 
          name="center"
          autoComplete="off"
          value={centerKeyword}
          disabled={centerKeyword.length === 0 && centerSource.length === 0}
          onChange={onChange}
          onSearch={onSearchCenter}
          size="middle"
          placeholder="搜索中心名称"
          allowClear/>
          {centerSource.length > 0 ? (
            <List
              size="small"
              dataSource={centerSource}
              className={styl.list}
              renderItem={(item) => (
                <List.Item
                  className={centerId === item.id ? styl.active : ""}
                  onClick={() => fetchUser(item)}
                >
                  {item.title}
                </List.Item>
              )}
            />
          ) : (
            <Empty
              className={styl.empty}
              description="暂无数据"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </Col>
        <Col span={2}></Col>
        <Col span={11}>
          <Typography.Title style={{fontSize: 14}} level={5}>医生列表</Typography.Title>
          <Input.Search
            name="doctor"
            value={doctorKeyword}
            autoComplete="off"
            disabled={doctorKeyword.length === 0 && doctorSource.length === 0}
            onChange={onChange}
            onSearch={onSearchDoctor}
            size="middle"
            placeholder="搜索医生"
            allowClear/>
          {doctorSource.length > 0 ? (
            <List
              size="small"
              className={styl.list}
              dataSource={doctorSource}
              renderItem={(item) => (<List.Item onClick={()=> checkDoctor(item) }><Radio checked={item.code === doctorCode} value={item.code}>{item.name}</Radio></List.Item>)}
            ></List>
          ) : (
            <Empty
              className={styl.empty}
              description={doctorSource.length && centerId === 0 ? "请选择中心名称" : "暂无数据"}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: 16, justifyContent: "flex-end" }}>
        <Col>
          <Button onClick={onConfirm} disabled={doctorCode.length===0} loading={loading} type="primary">确定</Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default TransoutModal;
