/**
 * 数据采集总体情况
 */
import React from 'react';
import { connect } from 'react-redux';
import './echartsOverallSituation.less';
import ReactEcharts from '../../../../base/echarts/ReactEcharts';

let _this;

const optionsData = [
    {color: '#73A0FA', colorGroup: '#EAF1FF, #73A0FA', name: '目标数', value: 100, num: 100, id: 'id1'},
    {color: '#73A0FA', colorGroup: '#B7EB8F, #EBFCDE', name: '已完成', value: 90, num: 90, id: 'id2'},
    {color: '#73A0FA', colorGroup: '#B2D2DB, #F0F7FF', name: '采集中', value: 80, num: 80, id: 'id3'},
    {color: '#73A0FA', colorGroup: '#ECD48E, #FFF8E5', name: '待入排', value: 70, num: 70, id: 'id4'},
    {color: '#73A0FA', colorGroup: '#FFC9CB, #FFF1F7', name: '已脱落', value: 60, num: 60, id: 'id5'},
    {color: '#73A0FA', colorGroup: '#FFBB96, #FFF6F1', name: '已排除', value: 50, num: 50, id: 'id6'},
]

class overallSituationEcharts extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
        }
    }

    componentDidMount() {
    }
    
    getOptions = (item) => {
        let color = item.colorGroup.split(',');
   
        let echartData = [
            { value: 100 - item.value },
            { value: item.value }
        ];
   
        let formatNumber = function(num) {
          let reg = /(?=(\B)(\d{3})+$)/g;
          return num.toString().replace(reg, ',');
        }
        let total = echartData.reduce((a, b) => {
          return b.value + '%'
        }, 0);
   
        let option = {
   
          color: color,
          title: [{
            text: '{val|' + formatNumber(total) + '}',
            top: 'center',
            left: 'center',
            textStyle: {
              rich: {
                val: {
                  fontSize: 16,
                  color: item.color,
                }
              }
            }
          }],
          series: [{
            type: 'pie',
            radius: ['60%', '90%'],
            center: ['50%', '50%'],
            data: echartData,
            hoverAnimation: false,
            itemStyle: {
                normal: {
                    borderWidth: 2,
                    borderRadius: 2,
                }
            },
            labelLine: {
              normal: {
                length: 0,
                length2: 0,
                lineStyle: {
                  color: item.color
                }
              }
            }
          }]
   
        };
        return option;
    }

  render() {
    let data = _this.props.projectMonitor;
    if (JSON.stringify(data) !== '{}') {
      return (<div className={'overall'}>
        <ul className={'ul'}>
          {
            optionsData.map((item, index) => {
              return (
                <li key={index} className={'li'}>
                  <ReactEcharts
                    id={item.id}
                    option={_this.getOptions(item)}
                    style={{ width: 75, height: 75, margin: '0 auto' }}
                  />
                  <div className={'echarts_title'}>
                    <p className={'num'}>{item.num}</p>
                    <span className={'txt'}>{item.name}</span>
                  </div>
                </li>
              )

            })
          }
        </ul>
      </div>);
    } else {
      return <div></div>
    }
  }
}

const mapStateToProps = (state) => ({
    projectMonitor: state.getIn(['project', 'projectMonitor'])
})

const mapDispatchToProps = (dispatch) => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(overallSituationEcharts);