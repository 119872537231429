import React from "react";
import {
  ConfigProvider,
  Modal,
  message,
  Button,
  Form,
  Select,
  Radio,
  Input,
  DatePicker,
  Row,
  Col,
  TreeSelect,
} from "antd";
import {
  editContent,
  createContent,
  getContentDetail,
} from "../../../../services/apply/ApplyService";
import AliUpload from "../imageUpload";
import ReactWEditor from "wangeditor-for-react";
import zh_CN from "antd/lib/locale-provider/zh_CN";
import VideoUpload from "../videoUpload";
import moment from "moment";
const { Option } = Select;

class ContentModal extends React.Component {
  constructor(props) {
    super(props);
  }
  formRef = React.createRef();
  state = {
    dialogVisible: this.props.dialogVisible,
    isEdit: false,
    dialogLoading: false,
    localOptions: this.props.localOptions,
    mediaType: null,
    onlineTime: null,
    offlineTime: null,
    videoUrl: null,
    imageUrl: null,
  };
  // 视频资源回调
  getValue = (name, url) => {
    console.log(url);
    this.setState({
      videoUrl: url,
    });
    this.formRef.current.setFieldsValue({ videoUrl: url });
  };

  // 图片传递
  handleFileSelect = (url) => {
    this.setState({ imageUrl: url });
    this.formRef.current.setFieldsValue({ imageUrl: url });
  };
  // 提交或者编辑
  saveData = (state) => {
    this.formRef.current.validateFields().then(async (values) => {
      console.log(values);
      // state 1保存 2保存并发布
      values.publish = state;
      values.onlineTime = values.onlineTime
        ? moment(values.onlineTime).format("YYYY-MM-DD HH:mm:ss")
        : null;
      values.offlineTime = values.offlineTime
        ? moment(values.offlineTime).format("YYYY-MM-DD HH:mm:ss")
        : null;

      // const values = this.formRefDialog.current.getFieldsValue(['id', 'imageUrl', 'pageLocalId', 'outsideChain', 'sortNum', 'title'])
      if (this.state.isEdit) {
        const res = await editContent(values);
        if (res.code == 0) {
          message.success("编辑成功", 1);

          this.resetFields();
          this.props.refleshData();
        }
      } else {
        const res = await createContent(values);
        if (res.code == 0) {
          message.success("添加成功", 1);

          this.resetFields();
          this.props.refleshData();
        }
      }
    });
  };

  // 清除表单数据
  resetFields = () => {
    this.formRef.current.resetFields();
  };
  // 编辑表单赋值
  setEditData = async (id) => {
    this.setState({
      isEdit: 1,
    });
    if (id) {
      const res = await getContentDetail(id);
      if (res.code == 0 && res.data) {
        let formData = res.data;
        // 特殊数据处理
        if (formData.onlineTime) {
          formData.onlineTime = moment(
            formData.onlineTime,
            "YYYY-MM-DD HH:mm:ss"
          );
        }
        if (formData.offlineTime) {
          formData.offlineTime = moment(
            formData.offlineTime,
            "YYYY-MM-DD HH:mm:ss"
          );
        }
        this.setState({
          imageUrl: formData?.imageUrl,
          mediaType: formData.mediaType,
          videoUrl: formData?.videoUrl,
        });
        this.formRef.current.setFieldsValue({ ...formData });
      }
    }
  };
  render() {
    const { localOptions, mediaType, isEdit, imageUrl, videoUrl } = this.state;
    return (
      <ConfigProvider locale={zh_CN}>
        <Form
          ref={this.formRef}
          onFinish={this.handleFinish}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item name="id" hidden></Form.Item>
          <Form.Item
            label="内容标题"
            name="title"
            rules={[{ required: true, message: "请输入内容标题!" }]}
          >
            <Input
              placeholder="请输入内容标题"
              autoComplete="off"
              maxLength={50}
            />
          </Form.Item>
          <Form.Item
            label="所属页面"
            name="pageLocalId"
            rules={[{ required: true, message: "请选择所属页面!" }]}
          >
            <Select placeholder="请选择">
              {localOptions.map((e) => {
                return <Option value={e.id}>{e.title}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="内容标签"
            name="labels"
            rules={[{ required: true, message: "请选择内容标签!" }]}
          >
            <TreeSelect
              multiple
              showSearch
              treeNodeFilterProp="label"
              style={{
                width: "100%",
              }}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
              placeholder="请选择"
              allowClear
              treeData={this.props.labelOptions}
            />
          </Form.Item>
          <Form.Item
            label="缩略图"
            name="imageUrl"
            rules={[{ required: true, message: "请上传图片！" }]}
          >
            <AliUpload
              onFileChange={this.handleFileSelect}
              imageUrl={imageUrl}
            />
          </Form.Item>
          <Form.Item
            label="内容类型"
            name="mediaType"
            rules={[{ required: true, message: "请选择内容类型!" }]}
          >
            <Radio.Group
              onChange={(e) => {
                this.setState({ mediaType: e.target.value });
              }}
              value={mediaType}
            >
              <Radio value={1}>资讯</Radio>
              <Radio value={2}>外链</Radio>
            </Radio.Group>
          </Form.Item>
          {mediaType === 2 && (
            <Form.Item
              label="外链地址"
              name="outsideChain"
              rules={[{ required: true, message: "请输入外链地址！！" }]}
            >
              <Input placeholder="请输入外链地址" autoComplete="off" />
            </Form.Item>
          )}
          {mediaType == 1 && (
            <>
              <Row>
                <Col span={24} offset={4}>
                  <Form.Item name="content">
                    <ReactWEditor />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} offset={4}>
                  <Form.Item name="videoUrl">
                    <VideoUpload
                      getValue={this.getValue}
                      videoUrl={videoUrl}
                    ></VideoUpload>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Form.Item
            label="上线时间"
            name="onlineTime"
            rules={[{ required: true, message: "请选择上线时间!" }]}
          >
            <DatePicker placeholder="请选择" showTime />
          </Form.Item>
          <Form.Item label="下线时间" name="offlineTime">
            <DatePicker placeholder="请选择" showTime />
          </Form.Item>
        </Form>
      </ConfigProvider>
    );
  }
}

export default ContentModal;
