import React, { forwardRef, useEffect, useImperativeHandle, useState, useMemo, useRef } from 'react';
import { Input, Tooltip , message} from 'antd';
import verify, { replaceFieldRemind } from '../../utils/verify';
import mexp from 'math-expression-evaluator';

import { saveFormData, updateFormData} from "../../../../services/data/DataService";
import './number.less';
import ActionView from '../../com/actionView/actionView';
import formAction from '../../utils/formAction';
import mExper from "../../utils/operation.ts";
import DctOcrView from '../../com/dctOcrView/dctOcrView';
import { InfoCircleOutlined } from '@ant-design/icons';
function NumberCom(props: any, ref: any) {
    const [formInit, setFormInit] = useState<boolean>(true);
    const previousValuesRef = useRef<any>([]);
    const isFirstRenderRef = useRef(true);
    const [value, setValue] = useState<any>();
    const [data, setData] = useState<any>();
    const [formId, setFormId] = useState<any>();
    const [dataActions, setDataActions] = useState<any>([]);
    const [formIsRead, setFormIsRead] = useState<boolean>(true);
    const [timeObject, setTimeObject] = useState<any>();
    const [savedValues, setSavedValues] = useState<any>([]);
    const [updatedValue, setUpdatedValue] = useState<any>();
    const [isUpdate, setIsUpdate] = useState<boolean>(false);

    const [ocrLists, setOcrLists] = useState<any>(null);
    // 手动划词内容
    const [ocrValue, setOcrValue] = useState<any>(null);
    // 超范围异常提醒
    const [annotation, setAnnotation] = useState<any>(null);

    const memoizedValues = useMemo(() => savedValues, [savedValues]);

    
    useEffect(() => {
       
        if (props.data) {
            console.log(props)
            //设置dataActions
            setDataActions(props.dataActions);
            //表单是否只读
            setFormIsRead(props.formIsRead);
            //获取当前表单的id
            setFormId(props.formId);
            //该条表单元素的数据
            setData(props.data);
            //已经保存的表单数据
            setSavedValues(props.savedValues);
            //如果是修改时
            if (props.isUpdate) {
                setIsUpdate(props.isUpdate)
            }
            else {
                setIsUpdate(false)
            }

            if (props.ocrLists) {
                setOcrLists(props.ocrLists)
            }

            //设置当前表单已存的value
            let currentItem = props.savedValues.find((i: any) => {
                return i.dataCode == props.data.dataCode
            });
            if (currentItem) {
                setValue(currentItem.value);
                setUpdatedValue(currentItem.value);
            } else {
                setValue(null);
                setUpdatedValue(null);
            }
 
            // 超范围异常提醒 
            if (props.data.remind || props.data.reminds) {
                // feature: 使用列表中的其他字段进行比较
                if(props.data.remind) {
                    replaceFieldRemind(props.data.remind, props.savedValues)
                }

                //
                setAnnotation(
                    verify.checkValueAnnotation(
                        currentItem ? currentItem.value : value,
                        props.data,
                        props.formCenterCode
                    )
                );
            }
        }
    }, [props])


  
    useEffect(()=>{
        // 第一次不计算，只获取后端值。第二次并且每次值发生改变时，则发生计算
        if(isFirstRenderRef.current){
            isFirstRenderRef.current = false; 
            return
        }
        if ( !isFirstRenderRef.current || (previousValuesRef.current.length !== savedValues.length ||
            !previousValuesRef.current.every((value:any, index:any) => value === savedValues[index])) ) {
            previousValuesRef.current = [...savedValues];
            
            if (  props.data.logicOperations && props.data.logicOperations.length > 0  ) {
                for (let logicOperation of props.data.logicOperations) {
                    // 年龄的首次渲染获取后端的值，二次修改才自动计算，因为当时访视的年龄为填写时的年龄，不进行更新；特殊处理
                    // 可以拓展为表单初始化，第一次取不计算，从后端取值
                    
                     if(formInit){
                        isFirstRenderRef.current = false; 
                        setFormInit(false)
                        continue
                    }
                    console.log(logicOperation)
                    let result = mExper.handleLogicOperations(logicOperation, {
                        savedValues: savedValues,
                        data: data,
                        formId: props.formId || formId
                    });
                    if (result == "null") {
                        continue;
                    }
                    setValue(result);
                    let values = [
                        {
                            dataCode: data.dataCode,
                            deCode: data.deCode,
                            value: result
                        }
                    ]
                    saveData(formId, values);
                    const params =  {
                        dataCode: data.dataCode,
                        deCode: data.deCode,
                        value: result,
                        formId
                    }
                    if (params && !formIsRead) {
                        updateFormData(params).then(async (res) => {
                            if (res.code === 0) {
                            }
                            else {
                                // 该受试者信息已存在，重复受试者编码为：[QLXH200015]
                                if(res.code === 50031411) {
                                    return
                                }
                                if (res.message) {
                                    message.error(`${res.message}`, 1)
                                }
                                else {
                                    message.error(`修改失败`, 1)
                                }
                            }
                        })
                    }
                }
            }
        }

       
            
    },[savedValues])

    // 当提交后显示的value
    const showSavedValue = () => {
        if (value) {
            return value;
        }
        else {
            return '未录入'
        }
    }

    const onChange = (e: any) => {
        let filterValue = verify.checkValue(e.target.value, data);

        // setAnnotation(verify.checkValueAnnotation(filterValue, data, props.formCenterCode));
        saveItemData(filterValue)

        if ((props.ocrLists && props.ocrLists.length > 0 && ocrValue) || filterValue == '') {
            deleteFunc()
        }
    }

    // 清除划词
    const deleteFunc = () => {
        let currentData = {
            sort: data.sort,
            dataCode: data.dataCode,
            deCode: data.deCode
        }

        props.deleteDrawAreaFunc && props.deleteDrawAreaFunc(currentData).then((res: String) => {
            console.log('删除划词', res);
        });
    }

    const saveItemData = (filterValue: any) => {
        setValue(filterValue)

        let extendAttribute = ''
        if (props.data.isAdd) {
            extendAttribute = 'isAdd=true'
        }

        let values = [
            {
                dataCode: data.dataCode,
                deCode: data.deCode,
                value: filterValue,
                extendAttribute: extendAttribute ? extendAttribute : undefined
            }
        ]
        if (isUpdate) {
            setUpdatedValue(filterValue);
        }
        if (timeObject) {
            clearTimeout(timeObject);
        }
        let time = setTimeout(() => {
            if (!isUpdate) {
                saveData(formId, values)
            }
        }, 200);
        setTimeObject(time)
    }

    //暂存数据
    const saveData = (formId: any, values: any) => {
        let params = {
            formId: formId,
            submit: false,
            values: values
        }
        formAction.saveData(params);
        // props.startTimerFun();
        props.updateFun(getPure(values[0].value))
        // saveFormData(params).then((res) => {
        //     props.updateFun()
        // })
    
       
    }
    
    const getPure = (currentValue: any)=> ({
        dataCode: data.dataCode,
        deCode: data.deCode,
        formId: formId,
        value: currentValue ?? updatedValue,
    })

    useImperativeHandle(ref, () => ({
        // changeVal 就是暴露给父组件的方法
        getModifyValue: getPure,
    }));

    // 获取划词信息
    const getOcrValue = (values: any) => {
        setOcrValue(values)
        data['isDelimitation'] = true
        saveItemData(values && values.text);
    }

    // 获取划词icon信息
    const getOcrIcon = (values: any) => {
        props.getOcrIcon(values)
    }

    return (
        <div className="numberCom">
            {/* {
                formIsRead ? <Input allowClear={true} disabled={!formIsRead} value={value} style={{ width: '100%' }} min={1} placeholder={data ? `请输入${data.label}` : ''} onChange={onChange} suffix={data && data.unit ? data.unit : ''} /> : <div>{showSavedValue()}</div>
            } */}
            <Input
                allowClear={true}
                disabled={!formIsRead}
                value={value}
                style={{ width: '100%', color: annotation && annotation.status ? 'red' : 'rgba(0, 0, 0, 0.85)' }}
                min={0}
                placeholder={data ? `请输入${data.label}` : ''}
                onChange={onChange}
                prefix={
                    annotation && annotation.status &&
                    <Tooltip title={annotation.message}>
                        <InfoCircleOutlined style={{ color: 'red', fontSize: 16 }} />
                    </Tooltip>
                }
                suffix={<>
                    {data && data.unit ? data.unit : ''}
                    <DctOcrView formItem={data} ocrLists={ocrLists}
                        startFun={props.triggerDrawAreaFunc}
                        cancleFun={props.cancelDrawAreaFunc}
                        getOcrValue={getOcrValue}
                        getOcrIcon={getOcrIcon}
                        value={value}
                    />
                </>}
            />
        </div>
    )
}

export default forwardRef(NumberCom);
