import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import axios from 'axios';
import utils from '../../utils/utils';
import { Modal, Upload, Image, ConfigProvider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { BASE_URL } from '../../../../comm/config';
import { saveFormData } from '../../../../services/data/DataService';
import './photo.less';
import ActionView from '../../com/actionView/actionView';
import formAction from '../../utils/formAction';
import {
    message,
    message as Message,
} from "antd";
import zh_CN from 'antd/lib/locale-provider/zh_CN';

function PhotoCom(props: any, ref: any) {
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [previewTitle, setPreviewTitle] = useState<string>('');
    const [header, setHeader] = useState<any>();
    const [fileList, setFileList] = useState<any>([]);
    const [value, setValue] = useState<any>([]);
    const [data, setData] = useState<any>();
    const [formId, setFormId] = useState<any>();
    const [dataActions, setDataActions] = useState<any>([]);
    const [formIsRead, setFormIsRead] = useState<boolean>(true);
    const [bool, setBool] = useState<boolean>(true);
    const [savedValues, setSavedValues] = useState<any>([]);
    const [previewImage, setPreviewImage] = useState<any>();
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [updatedValue, setUpdatedValue] = useState<any>([]);

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (props.data) {
            //设置dataActions
            setDataActions(props.dataActions);
            //表单是否只读
            setFormIsRead(props.formIsRead);
            //获取当前表单的id
            setFormId(props.formId);
            //该条表单元素的数据
            setData(props.data);
            //已经保存的表单数据
            setSavedValues(props.savedValues);
            //如果是修改时
            if (props.isUpdate) {
                setIsUpdate(props.isUpdate)
            }
            else {
                setIsUpdate(false)
            }
            //设置当前表单已存的value
            let currentItem = props.savedValues.find((i: any) => {
                return i.dataCode == props.data.dataCode
            });
            if (currentItem) {
                let fieldList = [];
                if  (currentItem.value) {
                    for (let item of currentItem.value) {
                        fieldList.push({
                            url: item,
                            uid: item
                        })
                    }
                }
                setValue(fieldList);
                setFileList(fieldList);
                setUpdatedValue(fieldList);
            }
            else {
                setValue([])
                setFileList([]);
                setUpdatedValue([]);
            }
        }
    }, [props.data, props.savedValues])


    //图片预览
    const handlePreview = (e: any) => {
        console.log(e);
        if(e.url){
            setPreviewImage(e.url);
        }else if(e.response){
            setPreviewImage(e.response.data.url);
        }
        setPreviewVisible(true);
        setPreviewTitle('图片预览');
    }

    const _itemData = {
        projectCode: '',
        subjectCode: ''
    };
    let itemData = sessionStorage.getItem('photoEditItem');
    itemData = itemData ? JSON.parse(itemData) : _itemData;
    const extraHeader = {
        "x-formId-code": formId,
        // @ts-ignore 首页进来时点击条目的projectCode
        "x-projectCode-code": itemData.projectCode,
        // @ts-ignore 首页进来时点击条目的subjectCode
        "x-subjectCode-code": encodeURIComponent(itemData.subjectCode)
    }

    const tuoMin = (url: String) => {
        const postData = {
          "areaList": [],
          "fileUrl": url,
          "formId": formId,
          "platform": 0, // 1为h5, 0为pc
          // @ts-ignore
          "subjectCode": itemData.subjectCode
        };
        axios.post("/file/image/desensitizate", postData);
    }

    const handleChange = ({ file, fileList }: any) => {
        let url;
        //添加图片
        if (file.status === 'done') {
            if (file['response'].code === 0) {
                // url = file['response'].data; // 之前的图片上传接口
                url = file['response'].data.url; // 脱敏后的新上传接口
                if (url) {
                    setValue([...value, { url: url, uid: url }]);
                    if (isUpdate === true) {
                        setUpdatedValue([...value, { url: url, uid: url }]);
                    }
                    else {
                        saveData([...value, { url: url, uid: url }]);
                    }
                    // 因为要进行记录，需要更新脱敏接口
                    tuoMin(url);
                }
            }
        }
        //删除图片
        else if (file.status === 'removed') {
            setValue(fileList)
            if (isUpdate === true) {
                setUpdatedValue(fileList);
            }
            else {
                saveData(fileList);
            }
        }
        else if (file.status === 'error') {
            message.error("上传失败！", 1);
        }
        setFileList([...fileList]);
    }

    //暂存数据
    const saveData = (value: any) => {
        let filterArr = [];
        for (let item of value) {
            if (item.url) {
                filterArr.push(item.url);
            }
        }
        let values = [
            {
                dataCode: data.dataCode,
                deCode: data.deCode,
                value: filterArr
            }
        ];
        let params = {
            formId: formId,
            submit: false,
            values: values
        }
        formAction.saveData(params);
        // props.startTimerFun();
        props.updateFun()
        // saveFormData(params).then((res) => {
        //     props.updateFun()
        // })
    }

    const handleCancel = () => {
        setPreviewVisible(false);
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>点击上传图片</div>
        </div>
    );

    useImperativeHandle(ref, () => ({
        // changeVal 就是暴露给父组件的方法
        getModifyValue: () => {
            let filterArr = [];
            for (let item of updatedValue) {
                if (item.url) {
                    filterArr.push(item.url);
                }
            }
            let params = {
                dataCode: data.dataCode,
                deCode: data.deCode,
                formId: formId,
                value: filterArr,
            }
            return params;
        },
    }));
    
    return (
        <div className="photoCom">
            {/* {
                value && value.length > 0 ? <div>
                    <Upload
                        action={`${BASE_URL}data/data/image`}
                        method="POST"
                        accept="image/*"
                        headers={utils.getHeaders()}
                        listType="picture-card"
                        fileList={value}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        disabled={!formIsRead}
                    >
                        {value.length >= 30 || !formIsRead ? null : uploadButton}
                    </Upload>
                    <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        onCancel={handleCancel}
                    >
                        <img alt="img" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                </div> : <div>未录入</div>
            } */}
            {
                <div>
                    <Upload
                        // action={`${BASE_URL}data/data/image`} // 之前的上传接口
                        action={`${BASE_URL}file/image/upload`} // 脱敏图片上传接口
                        method="POST"
                        accept="image/*"
                        // @ts-ignore
                        headers={utils.getHeaders(extraHeader)}
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        disabled={!formIsRead}
                    >
                        {value.length >= 30 ? null : uploadButton}
                    </Upload>
                    <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        // zIndex={99999}
                        onCancel={handleCancel}
                    >
                        {/* <img alt="img" style={{ width: '100%' }} src={previewImage} /> */}

                        <ConfigProvider locale={zh_CN}>
                            {/* <Image
                                width={'100%'}
                                src={previewImage}
                            /> */}

                            <Image
                                width={'100%'}
                                src={previewImage}
                                preview={{ visible: false }}
                                onClick={() => setVisible(true)}
                            />

                            <div style={{ display: 'none' }}>
                                <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
                                    <Image src={previewImage} />
                                    {
                                        fileList && fileList.length > 0 && fileList.map((file: any) => (
                                            previewImage != file.url && <Image src={file.url} />
                                        ))
                                    }
                                </Image.PreviewGroup>
                            </div>
                        </ConfigProvider>
                    </Modal>
                </div>
            }
        </div>
    )
}

export default forwardRef(PhotoCom);
