/**
 * 项目统计（区域、中心、参与者）
 */
import React from 'react';
import { connect } from 'react-redux';
import { Select, Button, DatePicker, Form, TreeSelect, Input, Row, Col, Tabs, Table } from 'antd';
import './projectStatistics.less';
import { CaretDownOutlined, createFromIconfontCN, SearchOutlined, PictureOutlined, TableOutlined } from '@ant-design/icons';

import { GetProjectMonitor, GetAreaCenterSelect, GetCenterUserSelect } from "../../../../../services/project/ProjectService";
import locale from "antd/es/date-picker/locale/zh_CN";

const { TabPane } = Tabs;
const { Search } = Input;

let _this;
const { Option } = Select;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

const data = [];
for (let i = 0; i < 100; i++) {
    data.push({
        key: i,
        name: 'John Brown',
        companyAddress: 'Lake Street 42',
        companyName: 'SoftLake Co',
    });
}

class ProjectStatistics extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            tabIndex: '1', // 1-区域统计，2-中心统计，3-参与者统计
        }
    }

    componentDidMount() {

    }

    onChange = (value) => {
        this.setState({
            tabIndex: value
        })
    }

    TabPaneData = () => {
        let { tabIndex } = this.state;

        let columns = [
            {
                title: '区域',
                dataIndex: 'name',
                key: 'name',
                width: 100,
                className: tabIndex === '1' ? '' : 'none',
            },
            {
                title: '中心数量',
                dataIndex: 'name',
                key: 'name',
                width: 100,
                className: tabIndex === '1' ? '' : 'none',
            },
            {
                title: '中心名称',
                dataIndex: 'name',
                key: 'name',
                width: 100,
                className: tabIndex === '2' ? '' : 'none',
            },
            {
                title: '参与者数量',
                dataIndex: 'name',
                key: 'name',
                width: 100,
                className: tabIndex === '1' || tabIndex === '2' ? '' : 'none',
            },

            {
                title: '参与者名称',
                dataIndex: 'name',
                key: 'name',
                width: 100,
                className: tabIndex === '3' ? '' : 'none',
            },
            {
                title: '参与者账号',
                dataIndex: 'name',
                key: 'name',
                width: 100,
                className: tabIndex === '3' ? '' : 'none',
            },
            {
                title: '所在中心',
                dataIndex: 'name',
                key: 'name',
                width: 100,
                className: tabIndex === '3' ? '' : 'none',
            },

            {
                title: sessionStorage.getItem('targetName') || '受试者',
                children: [
                    {
                        title: '目标数',
                        dataIndex: 'companyAddress',
                        key: 'companyAddress',
                    },
                    {
                        title: '已完成',
                        dataIndex: 'companyName',
                        key: 'companyName',
                    },
                    {
                        title: '已入组',
                        dataIndex: 'companyName',
                        key: 'companyName',
                    },
                    {
                        title: '完成率',
                        dataIndex: 'companyName',
                        key: 'companyName',
                    },
                ],
            },
            {
                title: '积分',
                children: [
                    {
                        title: '累计积分',
                        dataIndex: 'companyAddress',
                        key: 'companyAddress',
                    },
                    {
                        title: '已兑换',
                        dataIndex: 'companyName',
                        key: 'companyName',
                    },
                    {
                        title: '未兑换',
                        dataIndex: 'companyAddress',
                        key: 'companyAddress',
                    },
                    {
                        title: '兑换率',
                        dataIndex: 'companyName',
                        key: 'companyName',
                    },
                ],
            }
        ]
        
        let TabPaneList = [
            {tab: '区域统计', key: 1, columns: columns, dataSource: data},
            {tab: '中心统计', key: 2, columns: columns, dataSource: data},
            {tab: '参与者统计', key: 3, columns: columns, dataSource: data},
        ]

        return TabPaneList;
    }

    /**
     * 搜索参与者
     */
    onSearch = () => {

    }

    render() {
        let { colorState } = this.state;
        return (
            <div className={'projectStatistics'}>
                <Search placeholder="请输入参与者名称" onSearch={this.onSearch} enterButton className={'search'} />
                <Tabs defaultActiveKey="1" onChange={this.onChange}>
                    {
                        this.TabPaneData().map((item) => {
                            return (
                                <TabPane tab={item.tab} key={item.key}>
                                    <Table
                                        columns={item.columns}
                                        dataSource={item.data}
                                        bordered
                                        size="middle"
                                        scroll={{ x: 'calc(700px + 50%)' }}
                                    />
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
            </div>
        );
    }
}

export default ProjectStatistics;