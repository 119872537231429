import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { messageSession } from '../../../../services/data/chat';
import storageX from '../../../../util/storageX';

type Props = {
  onSelect?: ()=> void;
}

const Sider: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<any[]>([]);

  const [selectId, setSelectId] = useState('')

  const [page, setPage] = useState(1)
  const [sender] = useState(()=> storageX().user().userCode)

  const fetchList = async ()=> {
    const {code, message, data} = await messageSession({page, sender})
    console.log(code)
  }

  useEffect(() => {
    fetchList()
  }, []);

  const scrollableTarget = 'scrollable-session'

  type SessionItem = any
  const onChange = (item: SessionItem)=> {
    setSelectId(item.email)
  }

  return (
    <div className="feat-message--west">
      <div className="feat-message--finder">
        <Input.Search allowClear/>
      </div>
      <div >
        <ul className="feat-message--sessoins customized-scrollbar" id={scrollableTarget}>
          {data.length > 0 && data.map((item, i)=> (
            <li key={i} onClick={ ()=> onChange(item) } className={item.id === selectId ? 'active' : ''}>
              <div className="wt">
                <UserOutlined style={{fontSize: 20}}/>
              </div>
              <div className="md">
                <h4>JSNS100583</h4>
                <p>2024-12-12 12:12:12</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sider;