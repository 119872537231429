import React from 'react';
import { Button, Select, Tabs, Space, Table, Modal, Form, Input, message } from 'antd';
import {
    GetDesignPlanDetail, GetDesignPlanVersions, GetDesignUpdateRecords, GetDesignPublishRecords,
    SavePlanVersion, CheckContent, GetFormContentDetail, GetPlanContentDetail, SavePlanForm,
    PublishContent, PublishOrRollback, setCurrentVersion
} from '../../../../services/design/DesignService'
import { CloseOutlined } from '@ant-design/icons';

import './detail.less'
const { TextArea } = Input;
const { TabPane } = Tabs;

const defaultTabs = [
    { title: '研究方案', data: null },
    { title: '新问卷', data: null },
];

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
    }
};


class ResearchDetail extends React.Component {

    state = {
        planData: null,
        currentTab: 0,
        versionsData: [],
        updateData: [],
        publishData: [],
        totalRow: 0,
        page: 1,
        pageSize: 10,


        operationType: "add", //新增add或者编辑edit或者复制copy
        addVisible: false,
        currentData: null,
        activeKey: '0',
        submitBtnStatus: 'staging', //staging 暂存 check 校验 release 发布
        verisonTabData: defaultTabs,
        contentModalVisible: false,
        contentTitle: '',
        modalContent: '',

        showModalTitle: ''
    }

    formRef = React.createRef();

    componentDidMount() {
        this.getDataDetail()
        this.getPlanVerisons()
    }

    getDataDetail = () => {
        let params = {
            id: this.props.match.params.id
        }

        GetDesignPlanDetail(params).then(res => {
            if (res.code == 0) {
                this.setState({
                    planData: res.data
                })
            }
        })
    }


    getPlanVerisons = () => {
        let params = {
            page: this.state.page,
            pageSize: this.state.pageSize,
            planId: this.props.match.params.id
        }
        GetDesignPlanVersions(params).then(res => {
            if (res.code == 0) {
                this.setState({
                    versionsData: res.data.rows,
                    totalRow: res.data.total,
                })
            }
        })
    }

    getPlanUpdateRecords = () => {
        let params = {
            page: this.state.page,
            pageSize: this.state.pageSize,
            planId: this.props.match.params.id
        }
        GetDesignUpdateRecords(params).then(res => {
            if (res.code == 0) {
                this.setState({
                    updateData: res.data.rows,
                    totalRow: res.data.total,
                })
            }
        })
    }

    getPlanPublishRecords = () => {
        let params = {
            page: this.state.page,
            pageSize: this.state.pageSize,
            planId: this.props.match.params.id
        }
        GetDesignPublishRecords(params).then(res => {
            if (res.code == 0) {
                this.setState({
                    publishData: res.data.rows,
                    totalRow: res.data.total,
                })
            }
        })
    }

    //切换记录tab
    changeTab = (activeKey) => {
        this.setState({
            currentTab: activeKey,
            page: 1,
            pageSize: 10
        }, () => {
            if (this.state.currentTab == 0) {
                this.getPlanVerisons()
            } else if (this.state.currentTab == 1) {
                this.getPlanUpdateRecords()
            } else {
                this.getPlanPublishRecords()
            }
        })
    }


    changePage = (page) => {
        this.setState({ page }, () => {
            if (this.state.currentTab == 0) {
                this.getPlanVerisons()
            } else if (this.state.currentTab == 1) {
                this.getPlanUpdateRecords()
            } else {
                this.getPlanPublishRecords()
            }
        })
    }
    changePageSize = (current, size) => {
        this.setState({ page: 1, pageSize: size }, () => {
            if (this.state.currentTab == 0) {
                this.getPlanVerisons()
            } else if (this.state.currentTab == 1) {
                this.getPlanUpdateRecords()
            } else {
                this.getPlanPublishRecords()
            }
        })
    }

    //切换问卷
    onChange = activeKey => {
        let { verisonTabData } = this.state
        let _currentData = verisonTabData[activeKey]
        if (!_currentData.data) {
            this.formRef.current.resetFields()
            this.setState({
                activeKey,
                submitBtnStatus: 'staging',
                operationType: 'add'
            });
            return;
        }

        if (activeKey == 0) {
            let params = {
                id: verisonTabData[0].data.planVersionId
            }
            GetPlanContentDetail(params).then(res => {
                if (res.code == 0) {
                    this.setState({
                        activeKey,
                        submitBtnStatus: 'staging',
                        operationType: 'edit'
                    }, () => {
                        setTimeout(() => {
                            this.formRef.current.setFieldsValue({
                                version: res.data.version,
                                content: res.data.planContent,
                            })
                        }, 200);
                    });
                }
            })

        } else {
            let params = {
                formCode: _currentData.data.formCode,
                version: _currentData.data.version,
            }
            GetFormContentDetail(params).then(res => {
                if (res.code == 0) {
                    let data = {
                        planId: this.props.match.params.id,
                        formVersionId: res.data.id,
                        formName: res.data.name,
                        version: res.data.version,
                        formCode: res.data.code,
                        content: res.data.content,
                    }
                    this.setState({
                        activeKey,
                        submitBtnStatus: res.data.formStatus == 1 ? 'publish' : 'staging',
                        currentData: data,
                        operationType: 'edit'
                    }, () => {
                        setTimeout(() => {
                            if (_currentData.data.formVersionId) {
                                this.formRef.current.setFieldsValue({
                                    formName: res.data.name,
                                    formCode: res.data.code,
                                    version: res.data.version,
                                    content: res.data.content
                                })
                            } else {
                                this.formRef.current.setFieldsValue({
                                    formName: _currentData.data.formName,
                                    formCode: _currentData.data.formCode,
                                    version: _currentData.data.version,
                                    content: undefined
                                })
                            }
                        }, 200);
                    });
                }
            })
        }
    };

    //新增问卷
    addForm = () => {
        const { verisonTabData } = this.state;
        verisonTabData.push({ title: '新问卷', data: null });
        this.setState({
            verisonTabData,
            activeKey: `${verisonTabData.length - 1}`,
            operationType: 'add'
        });
        this.formRef.current.resetFields()
    };


    handleCancel = () => {
        this.setState({
            operationType: 'add',
            addVisible: false,
            activeKey: '0',
            submitBtnStatus: 'staging',
            verisonTabData: [
                { title: '研究方案', data: null },
                { title: '新问卷', data: null },
            ]
        })
        if (this.state.currentTab == 0) {
            this.getPlanVerisons()
        } else if (this.state.currentTab == 1) {
            this.getPlanUpdateRecords()
        } else {
            this.getPlanPublishRecords()
        }
    }


    //暂存版本内容
    handleSave = () => {
        let { verisonTabData, activeKey } = this.state
        //tab第一项是版本
        if (activeKey == 0) {
            this.formRef.current.validateFields().then(values => {
                let params = {
                    planId: this.props.match.params.id,
                    version: values.version,
                    content: values.content,
                }

                //新增版本不加planVersionId，编辑需要加planVersionId
                if (this.state.operationType == 'edit' && verisonTabData[activeKey] && verisonTabData[activeKey].data) {
                    params.planVersionId = verisonTabData[activeKey].data.planVersionId
                }

                SavePlanVersion(params).then(res => {
                    if (res.code == 0) {
                        let data = {
                            planVersionId: res.data.id,
                            version: values.version,
                            content: values.content
                        }
                        verisonTabData[activeKey].data = data
                        this.setState({
                            verisonTabData,
                            submitBtnStatus: 'check',
                            currentData: params,
                            operationType: 'edit'
                        })
                    }
                })
            })
        } else {
            this.formRef.current.validateFields().then(values => {
                let params = {
                    planId: this.props.match.params.id,
                    formName: values.formName,
                    version: values.version,
                    formCode: values.formCode,
                    content: values.content,
                }

                //新增版本不加planVersionId，编辑需要加planVersionId
                if (this.state.operationType == 'edit' && verisonTabData[activeKey] && verisonTabData[activeKey].data) {
                    params.formVersionId = verisonTabData[activeKey].data.formVersionId
                }

                SavePlanForm(params).then(res => {
                    if (res.code == 0) {
                        let data = {
                            planId: this.props.match.params.id,
                            formVersionId: res.data.id,
                            formName: values.formName,
                            version: values.version,
                            formCode: values.formCode,
                            content: values.content
                        }
                        verisonTabData[activeKey].data = data
                        this.setState({
                            verisonTabData,
                            submitBtnStatus: 'check',
                            currentData: params,
                            operationType: 'edit'
                        })
                    }
                })
            })
        }
    }

    //校验版本内容
    handleCheck = () => {
        let { currentData } = this.state
        let params = {
            planId: currentData.planId,
            version: currentData.version,
            type: '1'
        }

        if (currentData.formCode) {
            params.type = '2'
            params.formCode = currentData.formCode
        }

        CheckContent(params).then(res => {
            if (res.code == 0 && res.data) {
                message.success('校验通过', 2)
                this.setState({
                    submitBtnStatus: 'release'
                })
            } else {
                message.error('校验不通过', 2)
            }
        })
    }


    //发布版本/表单内容
    handlePublish = () => {
        let { currentData } = this.state;
        let params = {
            planId: currentData.planId,
            version: currentData.version,
            type: '1'
        }

        if (currentData.formCode) {
            params.type = '2'
            params.formCode = currentData.formCode
            params.formName = currentData.formName
        }

        PublishContent(params).then(res => {
            if (res.code == 0) {
                this.handleCancel()
                message.success('发布成功', 2)
            }
        })
    }

    showEditOrCopy = (record, type) => {
        let params = {
            id: record.id
        }
        GetPlanContentDetail(params).then(res => {
            if (res.code == 0) {
                let currentData = {
                    id: res.data.id,
                    version: res.data.version,
                    content: res.data.planContent
                }
                let tabsData = [{
                    title: '研究方案',
                    data: {
                        planVersionId: res.data.id,
                        version: res.data.version,
                        content: res.data.planContent,
                    }
                }]
                res.data.formsDtoList && res.data.formsDtoList.forEach((item) => {
                    tabsData.push({
                        title: item.name,
                        data: {
                            formCode: item.code,
                            formName: item.name,
                            version: item.version,
                            formVersionId: item.formVersionId,
                        }
                    })
                })

                this.setState({
                    operationType: type,
                    verisonTabData: tabsData,
                    activeKey: '0',
                    addVisible: true,
                    currentData
                }, () => {
                    setTimeout(() => {
                        this.formRef.current.setFieldsValue({
                            version: res.data.version,
                            content: res.data.planContent,
                        })
                    }, 200);
                })
            }
        })
    }


    /**
     * 显示变更前/后内容
     */
    showContent = (modalContent, data) => {
        let showModalTitle = `${this.state.planData.name} / ${data.version}`
        if (data.type == 2) {
            showModalTitle = this.state.planData.name + ' / ' + data.formName + ' / ' + data.version
        }
        this.setState({
            showModalTitle: showModalTitle,
            modalContent: modalContent,
            contentModalVisible: true
        })
    }


    publish = (data, type) => {
        let params = {
            optType: type,
            planVersionId: data.id
        }
        PublishOrRollback(params).then(res => {
            if (res.code == 0) {
                message.success('操作成功', 2)
                this.getPlanVerisons()
            }
        })
    }


    setCurrent = (data) => {
        let params = {
            id: data.id
        }

        setCurrentVersion(params).then(res => {
            if (res.code == 0) {
                message.success('操作成功', 2)
                this.getPlanVerisons()
            }
        })
    }

    render() {

        let { planData, addVisible, currentTab, versionsData, activeKey, publishData, updateData, verisonTabData, submitBtnStatus } = this.state
        const versionColumns = [
            {
                title: '版本号',
                dataIndex: 'version'
            },
            {
                title: '当前版本',
                dataIndex: 'isCurrent',
                render: (text, record) => (
                    <div>
                        {
                            record.isCurrent == 1 ? <div className='isCurrent'>
                                当前版本
                            </div> : null
                        }
                    </div>
                )
            },
            {
                title: '状态',
                dataIndex: 'planStatus',
                render: (text, record) => (
                    <div className='planStatus'>
                        {
                            record.planStatus == 0 ? <span className='design'>设计中</span> : <span className='release'>已发布</span>
                        }
                    </div>
                )
            },
            {
                title: '校验状态',
                dataIndex: 'isCheck',
                render: (text, record) => (
                    <div className='isCheck'>
                        {
                            record.isCheck == 0 ?
                                <span className='failed'>
                                    <span className='icon'></span>
                                    <span>未通过</span>
                                </span> : <span className='passed'>
                                    <span className='icon'></span>
                                    <span>已通过</span>
                                </span>
                        }

                    </div>
                )
            },
            {
                title: '重写记录',
                dataIndex: 'editNum',
                render: (text) => (
                    <div>
                        【{text}次重写】
                    </div>
                )
            },
            {
                title: '最后发布日期',
                dataIndex: 'publishTime'
            },
            {
                title: '发布人',
                dataIndex: 'publishUserName'
            },
            {
                title: '创建日期',
                dataIndex: 'createTime'
            },
            {
                title: '创建人',
                dataIndex: 'createUser'
            },
            {
                title: '操作',
                width: 300,
                render: (text, record) =>
                    <div >
                        {record.planStatus == 0 && <Button type="link" onClick={() => this.showEditOrCopy(record, 'edit')} >
                            编辑
                        </Button>}
                        {record.isCurrent != 1 && <>
                            {
                                record.planStatus == 0 ? <Button type="link" onClick={() => this.publish(record, '1')} disabled={record.isCheck == 0} >
                                    <span className='design'>发布</span>
                                </Button> : <Button type="link" onClick={() => this.publish(record, '2')} >
                                    <span style={{ color: '#F03333' }}>撤销</span>
                                </Button>}
                        </>}
                        <Button type="link" onClick={() => this.showEditOrCopy(record, 'copy')} >
                            复制
                        </Button>
                        {record.planStatus == 1 && record.isCurrent != 1 && <Button type="link" onClick={() => this.setCurrent(record, '2')} >
                            设为当前
                        </Button>}
                    </div>
            },
        ];
        const updateColumns = [
            {
                title: '变更版本',
                dataIndex: 'version'
            },
            {
                title: '变更操作',
                dataIndex: 'optionType',
                render: (text, record) =>
                    <div>
                        {text == 1 ? `新增${record.type == 1 ? '方案' : '问卷'}` : `修改${record.type == 1 ? '方案' : '问卷'}`}
                    </div>
            },
            {
                title: '变更时间',
                dataIndex: 'createTime'
            },
            {
                title: '变更人',
                dataIndex: 'createUserName'
            },
            {
                title: '变更前内容',
                dataIndex: 'beforeContent',
                render: (text, record) => (
                    <div>
                        {record.optionType == 1 ? '-' : <Button type="link" onClick={() => this.showContent(text, record)}>
                            查看
                        </Button>}
                    </div>
                )
            },
            {
                title: '变更后内容',
                dataIndex: 'afterContent',
                render: (text, record) => (
                    <Button type="link" onClick={() => this.showContent(text, record)}>
                        查看
                    </Button>
                )
            }
        ];
        const publishColumns = [
            {
                title: '变更版本',
                dataIndex: 'version'
            },
            {
                title: '发布状态',
                dataIndex: 'optionType',
                render: (text, record) =>
                    <div>
                        {text == 1 ? `发布${record.status == 1 ? '成功' : '失败'}` : `撤销${record.type == 1 ? '成功' : '失败'}`}
                    </div>
            },
            {
                title: '发布时间',
                dataIndex: 'createTime'
            },
            {
                title: '发布人',
                dataIndex: 'createUserName'
            },
            {
                title: '发布内容',
                dataIndex: 'content',
                render: (text, record) => (
                    <Button type="link" onClick={() => this.showContent(text, record)}>
                        查看
                    </Button>
                )
            },
        ];


        const operations = (
            <div className='right_opertions'>
                <Button onClick={this.addForm}>+ 增加问卷</Button>
                <CloseOutlined onClick={this.handleCancel} />
            </div>
        )

        const pagination = {
            current: this.state.page,
            pageSize: this.state.pageSize,
            total: this.state.totalRow,
            onChange: this.changePage,
            onShowSizeChange: this.changePageSize
        }

        return (
            <>
                <div className='detail_container'>
                    <div className='plan_header'>
                        <div className='planName'>
                            {planData && planData.name}
                            <span className={(planData && planData.planStatus == '已发布') ? 'label' : 'design'}>{planData && planData.planStatus}</span>
                        </div>
                        <div className='planInfo'>
                            <div className='info_item'>
                                <div className='label'>方案类型</div>
                                <div>{planData && planData.planType}</div>
                            </div>
                            <div className='info_item'>
                                <div className='label'>所属租户</div>
                                <div>{planData && planData.tenantName}</div>
                            </div>
                            <div className='info_item'>
                                <div className='label'>相关病种</div>
                                <div>{planData && planData.disease}</div>
                            </div>
                            <div className='info_item'>
                                <div className='label'>创建人</div>
                                <div className='userInfo'> <span className='avatar'></span> {planData && planData.createUserName}</div>
                            </div>
                            <div className='info_item'>
                                <div className='label'>创建时间</div>
                                <div>{planData && planData.createTime}</div>
                            </div>
                        </div>
                        <div className='introduce'>
                            <div className='label'>方案介绍</div>
                            <div>{planData && planData.introduction}</div>
                        </div>
                    </div>

                    <div className='plan_record'>
                        <div className='record_tabs'>
                            <div className={currentTab == 0 ? 'record_tab current' : 'record_tab'} onClick={() => this.changeTab(0)}>方案版本</div>
                            <div className={currentTab == 1 ? 'record_tab current' : 'record_tab'} onClick={() => this.changeTab(1)}>变更记录</div>
                            <div className={currentTab == 2 ? 'record_tab current' : 'record_tab'} onClick={() => this.changeTab(2)}>发布记录</div>
                            <div className='extraContent'>
                                <Button onClick={() => this.setState({ addVisible: true, operationType: 'add' })}>新增版本</Button>
                            </div>
                        </div>

                        <div className='record_con'>
                            {currentTab == 0 && <Table dataSource={versionsData} columns={versionColumns} pagination={pagination} />}
                            {currentTab == 1 && <Table dataSource={updateData} columns={updateColumns} pagination={pagination} />}
                            {currentTab == 2 && <Table dataSource={publishData} columns={publishColumns} pagination={pagination} />}
                        </div>
                    </div>
                </div>

                <Modal
                    title=''
                    visible={addVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={987}
                    closable={false}
                    footer={null}
                    className='plan_modal'
                    destroyOnClose
                >
                    <Tabs activeKey={activeKey} onChange={this.onChange} tabBarExtraContent={operations}>
                        {verisonTabData.map((item, index) => (
                            <TabPane tab={item.title} key={index}> </TabPane>
                        ))}
                    </Tabs>

                    <div>
                        {
                            activeKey == 0 ? (
                                <Form className="creatform" ref={this.formRef} {...formItemLayout}>
                                    <Form.Item
                                        label="版本号"
                                        name="version"
                                        extra={<span style={{ fontSize: 12, color: '#666666', paddingTop: 8, display: 'block' }}>新建需要填写版本号，编辑不可修改，版本号全局唯一，不可重复</span>}
                                        rules={[{ required: true, message: '请输入版本号' }]}
                                    >
                                        {/* <Input placeholder="请输入方案名称" disabled={verisonTabData[activeKey].data} /> */}
                                        <Input placeholder="请输入方案名称" disabled={this.state.operationType == 'edit'} />
                                    </Form.Item>
                                    <Form.Item
                                        label="版本内容"
                                        name="content"
                                        extra={<span style={{ fontSize: 12, color: '#666666', paddingTop: 8, display: 'block' }}>先校验格式通过才能发布上线，修改后需要重新校验</span>}
                                        rules={[{ required: true, message: '请输入版本内容' }]}
                                    >
                                        <TextArea placeholder="请输入版本内容" rows={8} onChange={() => this.setState({ submitBtnStatus: 'staging' })} />
                                    </Form.Item>
                                </Form>
                            ) : (
                                <Form className="creatform" ref={this.formRef} {...formItemLayout}>
                                    <Form.Item
                                        label="问卷名称"
                                        name="formName"
                                        rules={[{ required: true, message: '请输入问卷名称' }]}
                                    >
                                        <Input placeholder="请输入问卷名称" onChange={() => this.setState({ submitBtnStatus: 'staging' })} />
                                    </Form.Item>
                                    <Form.Item
                                        label="版本号"
                                        name="version"
                                        extra={<span style={{ fontSize: 12, color: '#666666', paddingTop: 8, display: 'block' }}>新建需要填写版本号，编辑不可修改，版本号全局唯一，不可重复</span>}
                                        rules={[{ required: true, message: '请输入版本号' }]}
                                    >
                                        {/* <Input placeholder="请输入版本号" disabled={verisonTabData[activeKey].data} /> */}
                                        <Input placeholder="请输入版本号" disabled={this.state.operationType == 'edit'} />
                                    </Form.Item>
                                    <Form.Item
                                        label="问卷编码"
                                        name="formCode"
                                        rules={[{ required: true, message: '请输入问卷编码' }]}
                                    >
                                        <Input placeholder="请输入问卷编码" onChange={() => this.setState({ submitBtnStatus: 'staging' })} />
                                    </Form.Item>
                                    <Form.Item
                                        label="版本内容"
                                        name="content"
                                        extra={<span style={{ fontSize: 12, color: '#666666', paddingTop: 8, display: 'block' }}>先校验格式通过才能发布上线，修改后需要重新校验</span>}
                                        rules={[{ required: true, message: '请输入版本内容' }]}
                                    >
                                        <TextArea placeholder="请输入版本内容" rows={8} onChange={() => this.setState({ submitBtnStatus: 'staging' })} />
                                    </Form.Item>
                                </Form>

                            )
                        }
                    </div>


                    <div className='footerBtns'>
                        <Space>
                            <Button type='primary' onClick={this.handleSave} disabled={submitBtnStatus == 'staging' ? false : true}>
                                暂存{activeKey == '0' ? '版本' : '问卷'}内容
                            </Button>
                            <Button type='primary' onClick={this.handleCheck} disabled={submitBtnStatus == 'check' ? false : true}>
                                {activeKey == '0' ? '版本' : '问卷'}格式校验
                            </Button>
                            <Button type='primary' onClick={this.handlePublish} disabled={submitBtnStatus == 'release' ? false : true}>
                                {activeKey == '0' ? '版本' : '问卷'}发布上线
                            </Button>
                        </Space>
                    </div>
                </Modal>

                <Modal
                    title={this.state.showModalTitle}
                    visible={this.state.contentModalVisible}
                    footer={null}
                    onCancel={() => this.setState({ contentModalVisible: false })}
                    width={1000}
                >
                    <TextArea rows={18} readOnly value={this.state.modalContent} />
                </Modal>
            </>
        )
    }
}

export default ResearchDetail