/**
 * cra 图片审核
 */
import React, { Component } from 'react';
import './index.less';
import { Row, Col } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import PreviewComponent from "./preview";
import CheckListComponent from "./list";
import { GetDctAssignTask } from "../../../../services/dct/DctService";

let _this;
class CheckComponent extends Component {

    constructor(props) {
        super(props);
        _this = this;
        this.state = {
            ImgList: null, 
            listItemData: null, // // 待审核图片
            taskId: null, // 任务id
        };
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState({
                ImgList: this.props.data
            })
        }
    }

    // 指派任务
    getAssignTask = async () => {
        const res = await GetDctAssignTask();
        if (res && res.code == 0) {
            if (!res.data || res.data.length == 0) {
                this.props.getValue(false)
                return
            }

            res.data.map((item)=>{
                if (item.id == this.state.taskId) {
                    this.setState({
                        listItemData: item,
                    })
                }
            })

            this.setState({
                ImgList: res.data,
                listItemData: res.data[0]
            })
        }
    }

    // 获取当前点击的图片信息
    getListData = (values) => {
        let { listItemData } = this.state
        if (JSON.stringify(values) !== JSON.stringify(listItemData)) {
            this.setState({
                listItemData: values,
                taskId: values.id
            })
        }
    }

    // 获取预览的值
    getPreviewValue = (v) => {
        this.props.getValue(v)
        if (v) {
            this.getAssignTask()
        }
    }

    // 获取图片列表的值
    getListValue = (v,t) => {
        this.props.getValue(v,t)
        if (v) {
            this.getAssignTask()
        }
    }

    render() {
        let { ImgList, listItemData } = this.state;

        return (
            <div className={'ImgCheck_content'}>
                <div className="Imglist_wrap" >
                    <Row gutter={16} className="row" style={{ margin: 0, height: '100%' }}>
                        <Col span={8} className="col" style={{ background: '#e9eaec', padding: 0, height: '100%' }}>
                            <PreviewComponent
                                data={ImgList}
                                listItem={listItemData}
                                getValue={(v) => this.getPreviewValue(v)}
                            />

                        </Col>
                        <Col span={16} style={{ paddingLeft: '16px', height: '100%' }}>
                            <CheckListComponent
                                data={ImgList}
                                listItem={listItemData}
                                getListData={(v) => this.getListData(v)}
                                getValue={(v,t) => this.getListValue(v,t)}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
export default CheckComponent