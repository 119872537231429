/**
 * 批量病例审核
 */
import React from 'react';
import { Table, Select, message, Button, Modal } from "antd";
import { GetCdmsprojectList, GetCdmsTaskList, createCdmsTask, downloadCdmsTask, GetCdmsUserList, getCdmsPostBackUrl } from '../../../services/cdms/cdmsService';
import './index.less';
import uploadTask from '../../../static/images/uploadTask.svg'
import UploadModal from './uploadModal'
const { Option } = Select;

let options = [
    {
        value: '',
        label: '全部状态'
    },
    {
        value: 'Wait',
        label: '待审核'
    },
    {
        value: 'Approve',
        label: '已审核'
    },
    {
        value: 'Create',
        label: '生成中'
    },
    {
        value: 'Postback',
        label: '回传中',
    },
    {
        value: 'Error',
        label: '回传文件格式错误'
    },
    {
        value: 'Valid',
        label: '已失效'
    }
]


let timer;

class BatchCaseRewiew extends React.Component {

    state = {
        projects: [],
        currentProject: '',
        state: '',
        userList: [],
        createUser: '',
        dataList: [], // 列表集合
        page: 1,//当前页码数
        total: 0,
        isModalOpen: false,
        selectProject: null,
        uploadModal: false,
        postBackId: ''
    }




    componentDidMount() {
        sessionStorage.setItem('dataSourceDownload', 'true');
        sessionStorage.setItem('customerData_download', 'true');
        this.getProjects()
        this.getUsers()
        this.getTaskList()

        timer = setInterval(() => {
            this.getTaskList()
        }, 5000);
    }


    componentWillUnmount() {
        clearInterval(timer)
    }

    getProjects = () => {
        GetCdmsprojectList().then(res => {
            if (res) {
                let projects = []
                projects = res.map(v => ({
                    value: v.code,
                    label: v.name
                }))
                this.setState({
                    projects: projects
                })
            }
        })
    }


    getUsers = () => {
        const token = sessionStorage.getItem('token');
        let loginResult = { token: "" };
        if (token) {
            loginResult = JSON.parse(token);
        }
        let params = {
            tenantCode: loginResult.tenantCode
        }
        GetCdmsUserList(params).then(res => {
            if (res) {
                let userList = []
                userList = res.map(v => ({
                    value: v.code,
                    label: v.name
                }))
                userList.unshift({ value: '', label: '全部创建人' })
                this.setState({
                    userList: userList
                })
            }
        })
    }

    getTaskList = () => {
        let { createUser, state, currentProject, page } = this.state;
        let params = {
            createUser: createUser,
            page: page,
            pageSize: 10,
            projectCode: currentProject,
            state: state ? state : undefined,
        }
        GetCdmsTaskList(params).then(res => {
            if (res) {
                this.setState({
                    dataList: res.rows,
                    page: res.page,
                    total: res.total

                })
            }
        })
    }

    getPageData = (page) => {
        this.setState({
            page
        }, () => {
            this.getTaskList()
        })
    }

    createTask = () => {
        if (!this.state.selectProject) {
            message.error(`请先选择所属项目`, 1);
            return false
        }
        const token = sessionStorage.getItem('token');
        let loginResult = { token: "" };
        if (token) {
            loginResult = JSON.parse(token);
        }
        let params = {
            projectCode: this.state.selectProject,
            tenantCode: loginResult.tenantCode
        }

        createCdmsTask(params).then(res => {
            if (res.code === 0) {
                this.setState({
                    isModalOpen: false,
                    selectProject: null,
                }, () => {
                    setTimeout(() => {
                        this.getTaskList()
                    }, 500);
                })
            }
        })
    }

    handleChange = (value) => {
        this.setState({
            state: value,
            page: 1
        }, () => {
            this.getTaskList()
        })
    };


    changeUser = (value) => {
        let Index = this.state.userList.findIndex(v => v.value == value)
        if (Index > -1) {
            this.setState({
                createUser: value ? this.state.userList[Index].label : '',
                page: 1
            }, () => {
                this.getTaskList()
            })
        }
    }

    handleChangeProject = (value) => {
        this.setState({
            currentProject: value,
            page: 1
        }, () => {
            this.getTaskList()
        })
    };


    // 下载
    download = (id) => {

        const token = sessionStorage.getItem('token');
        let loginResult = { token: "" };
        if (token) {
            loginResult = JSON.parse(token);
        }

        let params = {
            id: id,
            'x-auth-token': loginResult.token
        }

        downloadCdmsTask(params).then(res => {
            if (res && res.code == 0 && res.data) {
                window.open(res.data);
            } else {
                message.warning('暂无待审核病例文件', 2)
            }
        })
    }


    // 下载
    downloadPostBack = (id) => {
        const token = sessionStorage.getItem('token');
        let loginResult = { token: "" };
        if (token) {
            loginResult = JSON.parse(token);
        }

        let params = {
            id: id,
            'x-auth-token': loginResult.token
        }

        getCdmsPostBackUrl(params).then(res => {
            if (res && res.code == 0 && res.data) {
                window.open(res.data);
            } else {
                message.warning('暂无回传文件', 2)
            }
        })
    }

    handleSelectProject = (value) => {
        this.setState({
            selectProject: value
        })
    };



    postBack = (id) => {
        this.setState({
            postBackId: id,
            uploadModal: true
        })
    }

    closeModal = () => {
        this.setState({ uploadModal: false })
        this.getTaskList()
    }

    render() {
        const  targetName = sessionStorage.getItem('targetName') || '受试者'
        
        let { userList, createUser, state, dataList, isModalOpen, uploadModal, projects, currentProject, selectProject, postBackId } = this.state;

        let columns = [
            {
                title: '项目名称',
                dataIndex: 'projectName'
            },
            {
                title: '任务名称',
                width: '',
                dataIndex: 'taskName'
            },
            {
                title: '状态',
                width: 160,
                render: (text, record) => (
                    <div >
                        {record.state == 'Wait' ? <div className='state_wait'> <span className='dot'></span> 待审核</div>
                            : record.state == 'Create' ? <div className='state_wait state_create'> <span className='dot'></span> 生成中</div>
                                : record.state == 'Approve' ? <div className='state_wait state_approve'> <span className='dot'></span> 已审核</div>
                                    : record.state == 'Postback' ? <div className='state_wait state_postback'> <span className='dot'></span> 回传中</div>
                                        : record.state == 'Error' ? <div className='state_wait state_error'> <span className='dot'></span> 格式错误</div>
                                            : record.state == 'Valid' ? <div className='state_wait state_valid'> <span className='dot'></span> 已失效</div> : ''}
                    </div>
                )
            },
            {
                title: '待审核数',
                width: 100,
                dataIndex: 'count'
            },
            {
                title: '创建人',
                width: 120,
                dataIndex: 'createUser'
            },
            {
                title: '生成时间',
                width: 160,
                render: (text, record, index) => {
                    if (record.createTime) {
                        return (
                            <div className={'con_div'}>
                                <div className={'sub-title'}>{record.createTime ? record.createTime.replace('T', ' ') : ''}</div>
                            </div>
                        )
                    }
                }
            },
            {
                title: '操作',
                fixed: 'right',
                width: 400,
                render: (text, record) =>
                    <div>
                        <Button type="link" onClick={() => this.download(record.id)}>下载待审核病例</Button>
                        <Button type="link" onClick={() => this.postBack(record.id)} disabled={!(record.state == 'Wait' || record.state == 'Error')}>回传审核结果</Button>
                        <Button type="link" onClick={() => this.downloadPostBack(record.id)} disabled={!(record.state == 'Approve' || record.state == 'Error')}>下载回传文件</Button>
                    </div>
            },
        ]

        return (
            <>
                <div className='batchAudit_container'>
                    <div className='batchAudit_content'>
                        <div className='audit_header'>
                            <div className='title'>批量病例审核</div>
                            <div className='search'>
                                <Select
                                    value={state}
                                    style={{ width: 160 }}
                                    onChange={this.handleChange}
                                    options={options}
                                />
                                <Select
                                    value={createUser}
                                    style={{ width: 160 }}
                                    onChange={this.changeUser}
                                    options={userList}
                                />
                                <Select
                                    value={currentProject}
                                    style={{ width: 280 }}
                                    onChange={this.handleChangeProject}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value=''>全部项目</Option>
                                    {
                                        projects.map((v, i) => (
                                            <Option value={v.value} key={i}>{v.label}</Option>
                                        ))
                                    }
                                </Select>
                                <Button type="primary" className='createBtn' onClick={() => this.setState({ isModalOpen: true })}>
                                    <img src={uploadTask} alt="" />创建病例源文件
                                </Button>
                            </div>

                        </div>

                        <div className='table_content'>
                            <Table
                                locale={{ emptyText: "暂无数据" }}
                                columns={columns}
                                dataSource={dataList}
                                pagination={{ onChange: (pageNo) => { this.getPageData(pageNo); }, current: this.state.page, pageSize: 10, total: this.state.total, showSizeChanger: false, }}
                                rowKey={'id'}
                                scroll={{ x: 1500 }}
                            />
                        </div>
                    </div>
                </div>

                <Modal
                    title="创建最新审核文件"
                    visible={isModalOpen}
                    onOk={this.createTask}
                    onCancel={() => this.setState({ selectProject: null, isModalOpen: false })}
                    className='creatModal'
                    okText='确认创建'
                    cancelText='取消'
                >
                    <div className='title'>手动创建病例源文件将生成截止至当前时间的所有新提交未审核{targetName}和审核不通过后未修改的{targetName}。</div>
                    <div className='selectProject'>
                        <div>所属项目：</div>
                        <Select
                            placeholder='请选择项目'
                            value={selectProject}
                            style={{ width: 280 }}
                            onChange={this.handleSelectProject}
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                projects.map((v, i) => (
                                    <Option value={v.value} key={i}>{v.label}</Option>
                                ))
                            }
                        </Select>
                    </div>
                </Modal>

                <div>
                    {uploadModal && <UploadModal postBackId={postBackId} uploadModal={uploadModal} closeModal={this.closeModal} />}
                </div>

            </>
        );
    }
}

export default BatchCaseRewiew;