/**
 * dct 数据总览
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './index.less';
import { Button, Input, Select, Tabs, message, Row, Col, Card, Switch, List, Avatar, Tag, Table } from 'antd';
import { GetDctDataOverview } from "../../../../services/dct/DctService";
import { createFromIconfontCN } from "@ant-design/icons";

const { TabPane } = Tabs;
let _this;
const { Option } = Select;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2343612_ixe83tdh89g.js',
    ],
});

class DataStatistics extends Component {

    constructor(props) {
        super(props);
        _this = this;
        this.state = {
            statcisicData: {}, // 受试者工作台统计数据
        };
        this.inputEl = React.createRef();
        this.timer = null
    }

    componentDidMount() {
        this.getData();
    }

    /**
     * 获取 当前用户的项目
     * @param key
     */
     getData = () => {
        // project001 project002
         let params = {
            projectCode: '',
            userId: ''
         }
        GetDctDataOverview(params).then(res => {
            if (res.code == 0) {
                if (!res.data) {
                    return;
                }
                this.setState({
                    statcisicData: res.data
                })
            }
        })
    }

    render() {

        const { stayInputTaskTotal, processingTaskTotal, qualifiedTaskTotal, failTaskTotal, settlementTaskTotal } = this.state.statcisicData;
        const subjectStatcisicList = [
            { key: 0, name: '待录入任务', num: stayInputTaskTotal, color: '#276ec9' },
            { key: 1, name: '处理中任务', num: processingTaskTotal, color: '#23aea8' },
            { key: 2, name: '今日合格任务', num: qualifiedTaskTotal, color: '#f39c00' },
            { key: 3, name: '今日失败任务', num: failTaskTotal, color: '#f83b24' },
            { key: 4, name: '待结算任务', num: settlementTaskTotal, color: '#58278f' }
        ]

        return (
            <div className={'dctData_content'}>
                <div className={'dct_top_tit'}>数据总览</div>
                <div className={'dct_top'}>
                    {
                        subjectStatcisicList.map((i, index) => {
                            return (
                                <div className={'cols-con'} key={index}>
                                    <div className={'subjects-right'}>
                                        <div className={'number'} style={{ color: i.color }}>{i.num ? i.num : 0}</div>
                                        <div className={'name'}>{i.name}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        )
    }
}
export default DataStatistics