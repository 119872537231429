/**
 * 数据管理-待审核数据清理
 */
import React from 'react';
import './index.less';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
    Form, Input, Select, Button, message as Message, Table, message, Modal, DatePicker, Radio
} from "antd";
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { GetDataClearTaskList, createClearTask, GetListEnume } from "../../../../services/project/DownLoadService";



let _this;
const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

class DataClearComponent extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            dataLists: [], // 列表集合
            columns: [
                {
                    title: '任务名称',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.taskName}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '创建人',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div current_state'}>
                                {record.createUser}
                            </div>
                        )
                    }
                },
                {
                    title: '开始时间',
                    width: '',
                    render: (text, record, index) => {
                        if (record.startTime) {
                            return (
                                <div className={'con_div'}>
                                    <div className={'sub-title'}>
                                        {record.startTime == 'null' ? '' : record.startTime ? record.startTime.replace('T', ' ') : ''}
                                    </div>
                                </div>
                            )
                        }
                    }
                },
                {
                    title: '完成时间',
                    width: '',
                    render: (text, record, index) => {
                        if (record.finishTime) {
                            return (
                                <div className={'con_div'}>
                                    <div className={'sub-title'}>{record.finishTime == 'null' ? '' : record.finishTime ? record.finishTime.replace('T', ' ') : ''}</div>
                                </div>
                            )
                        }
                    }
                },
                {
                    title: '状态',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div current_state'}>
                                {
                                    record.createState == 0 ? '处理中' : '已完成'
                                }
                            </div>
                        )
                    }
                },
            ],// 表格 行 配置
            page: 1,//当前页码数
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo);
                },
                current: 1,
                pageSize: 10,
                total: 100,
                showSizeChanger: false,
            },
            isCreateVisible: false, // 创建数据下载任务模态框
            projectList: [], // 所有项目
            projectCodes: [], // 项目code
            currentDate: null, // 检索日期
            disableDownLoad: false,
            caseStartDate: null, // 起始时间
            caseEndDate: null, // 截至时间
        }
    }

    formRef = React.createRef();

    componentDidMount = () => {
        sessionStorage.setItem('customerData_download', 'true');
        // 列表
        this.getPageData();
        // 获取项目枚举
        this.getProjectEnume();
    }

    componentWillUnmount = () => {
        sessionStorage.removeItem('customerData_download');
    }

    // 获取项目枚举
    getProjectEnume = () => {
        GetListEnume().then(res => {
            this.setState({
                projectList: res || []
            })
        })
    }


    /**
     * 获取列表数据 
     */
    getPageData = async (curPage) => {
        let { page, currentDate } = this.state;

        let tenantCode = ''; // 租户code
        let token = sessionStorage.getItem('token');

        let t = null;
        if (token) {
            t = JSON.parse(token);
            tenantCode = t.tenantCode
        }

        const params = {
            page: curPage ? curPage : page,
            pageSize: this.state.pagination.pageSize,
            date: currentDate,
            tenantCode
        }

        const res = await GetDataClearTaskList(params);
        if (res && res.code == 0) {
            _this.setState({
                dataLists: [...res.data.rows],
                page: res.data.page,
                pageSize: res.data.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })
        }
    }

    // 取消
    handleCancel = async () => {
        this.setState({
            isCreateVisible: false
        })
    }

    // form过滤空格
    getValueFromEvent = (event) => {
        return event.target.value.replace(/\s+/g, "")
    }

    // 创建下载任务
    createTasks = () => {
        if (this.formRef.current) {
            this.formRef.current.resetFields()
        }

        this.setState({
            isCreateVisible: true
        })
    }

    // 选择日期
    onDateChange = (date, dateString) => {
        this.setState({
            currentDate: dateString
        }, () => {
            this.getPageData()
        })
    };

    // 创建/提交
    onSubmit = async (values) => {
        const form = this.formRef.current;
        const formValues = form.getFieldsValue(
            ['projectCode', 'caseStartDate', 'caseEndDate']);

        if (!formValues.projectCode) {
            message.error('请选择项目！')
            return
        }
        if (!formValues.caseStartDate || formValues.caseStartDate == '') {
            message.error('病例完成起始时间！')
            return
        }
        if (!formValues.caseEndDate || formValues.caseEndDate == '') {
            message.error('病例完成截至时间！')
            return
        }

        let tenantCode = ''; // 租户code
        let token = sessionStorage.getItem('token');
        let t = null;
        if (token) {
            t = JSON.parse(token);

            tenantCode = t.tenantCode
        }

        const userCode = sessionStorage.getItem('userName')

        let params = {
            projectCode: formValues.projectCode,
            tenantCode,
            userCode
        }

        if (formValues.caseStartDate) {
            params['startDate'] = moment(formValues.caseStartDate).format('YYYY-MM-DD');
        }
        if (formValues.caseEndDate) {
            params['endDate'] = moment(formValues.caseEndDate).format('YYYY-MM-DD');
        }

        const res = await createClearTask(params);
        if (res && res.code == 0) {
            message.success('创建成功！');

            _this.setState({
                isCreateVisible: false
            }, () => {
                this.getPageData()
            })
        }
    }

    render() {
        let { columns, dataLists, pagination, isCreateVisible, currentDate, projectList } = this.state;

        dataLists.length > 0 && dataLists.map((item, index) => {
            item['key'] = index + 1
        })

        return (
            <div className='data_sourceData'>
                <div className={'download_wrap'}>
                    <div className={'title_wrap'}>
                        <div>待审核数据清理</div>
                        <div className={'search_wrap'}>
                            <DatePicker
                                onChange={this.onDateChange}
                                locale={locale}
                                value={currentDate ? moment(currentDate, 'YYYY-MM-DD') : null}
                            />
                            <Button type="primary" size={'middle'} onClick={() => this.createTasks()} style={{ marginLeft: 12 }}>
                                创建数据清理任务
                            </Button>
                        </div>
                    </div>

                    <Table
                        locale={{ emptyText: "暂无数据" }}
                        columns={columns}
                        dataSource={dataLists}
                        pagination={dataLists.length !== 0 && pagination}
                        rowKey={'id'}
                    />
                </div>

                {/* 创建数据清理任务 */}
                <Modal
                    title="创建数据清理任务"
                    visible={isCreateVisible}
                    onOk={this.onSubmit}
                    okText='确认创建'
                    cancelText='关闭'
                    onCancel={this.handleCancel}
                    width={700}
                >
                    <Form
                        {...layout}
                        name="nest-messages"
                        onFinish={this.onSubmit}
                        requiredMark={true}
                        ref={this.formRef}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="请选择项目"
                            name='projectCode'
                            rules={[{ required: true, message: '请选择项目!' }]}
                        >
                            <Select
                                onChange={this.handleChange}
                                placeholder='请选择项目'
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                allowClear
                            >
                                {projectList.length > 0 && projectList.map(project => (
                                    <Option key={project.code} value={project.code}>{project.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="病例完成起始时间"
                            name="caseStartDate"
                            rules={[{ required: true, message: '请输入病例完成起始时间!' }]}
                        >
                            <DatePicker
                                locale={locale}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="病例完成截至时间"
                            name="caseEndDate"
                            rules={[{ required: true, message: '请输入病例完成截至时间!' }]}
                        >
                            <DatePicker
                                locale={locale}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}
export default withRouter(DataClearComponent);