/**
 * 平台管理模块路由配置
 */
import AllTenant from '../components/tenant/allTenant/allTenant';
import NewTenant from '../components/tenant/newTenant/newTenant';

const routes = [
    {'path': '/tenant/all', 'component': AllTenant},
    {'path': '/tenant/add', 'component': NewTenant},
];

export default routes
