import React from 'react';
import './index.less';
import { Input, Select, Button, Table, message, Modal, Col, Row, Spin, Checkbox, Form, Upload } from "antd";
import {
    getSignAuditList, getSignAuditProjectList, getSignAuditTenantList, getSignAuditStateList, manualSettlement,
    manualSettlementRollback
} from "../../../services/data/DataService";
import { createFromIconfontCN, ExclamationCircleOutlined } from '@ant-design/icons';
import UploadModal from './uploadModal'
const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',
    ],
});

class SettlementReview extends React.Component {

    state = {
        tenantOptions: [],
        projectOptions: [],
        patientName: '',
        projectCode: null,
        manualSettlementState: null,
        subjectCode: '',
        submitUserCode: '',
        submitUserName: '',
        tenantCode: null,
        page: 1,
        totalRow: 0,
        dataList: [],
        subjectData: '',
        loading: false,


        uploadModal: false,
        postBackId: '',
        selectIds: []

    }


    componentDidMount() {
        this.getEmunList()
        this.getData()
    }


    /**
     * 获取项目和租户下拉框数据
     */
    getEmunList = () => {
        getSignAuditProjectList().then(res => {
            if (res.code == 0 && res.data) {
                this.setState({
                    projectOptions: res.data
                })
            }
        })
        getSignAuditTenantList().then(res => {
            if (res.code == 0 && res.data) {
                this.setState({
                    tenantOptions: res.data
                })
            }
        })
    }


    getData = (pageNo) => {
        this.setState({ loading: true })
        let params = {
            patientName: this.state.patientName,
            projectCode: this.state.projectCode,
            signStates: ["2-0"],
            manualSettlementState: this.state.manualSettlementState,
            subjectCode: this.state.subjectCode,
            submitUserCode: this.state.submitUserCode,
            submitUserName: this.state.submitUserName,
            tenantCode: this.state.tenantCode,
            page: pageNo ? pageNo : this.state.page,
            pageSize: 10
        }
        getSignAuditList(params).then(res => {
            if (res.code == 0 && res.data) {
                this.setState({
                    dataList: res.data.rows,
                    page: res.data.page,
                    totalRow: res.data.total,
                    loading: false
                })
            }
        })
    }


    handleSearch = () => {
        this.getData('1')
    }

    /**
     * 重置搜索
     */
    resetSearch = () => {
        this.setState({
            tenantCode: null,
            projectCode: null,
            signStates: ["2-0"],
            manualSettlementState: null,
            patientName: '',
            subjectCode: '',
            submitUserCode: '',
            submitUserName: '',
            page: 1
        }, () => {
            this.getData()
        })
    }

    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    //结算
    handleSettlement = (data) => {
        // 0-撤销，1-结算
        let params = {
            ids: [data.id],
            manualSettlementState: "1"
        }
        manualSettlement(params).then(res => {
            if (res.code == 0) {
                message.success('结算成功')
                this.getData()
            }
        })
    }

    //批量结算
    handleBatchSettlement = () => {
        if (this.state.selectIds.length == 0) {
            message.info('不能为空')
            return false
        }
        let params = {
            ids: this.state.selectIds,
            manualSettlementState: "1"
        }
        manualSettlement(params).then(res => {
            if (res.code == 0) {
                message.success('批量结算成功')
                this.setState({ selectIds: [] })
                this.getData()
            }
        })
    }

    //撤销结算
    handleSettlementRollback = (data) => {
        Modal.confirm({
            title: '是否确定撤销该条数据结算？',
            icon: <ExclamationCircleOutlined />,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                let params = {
                    ids: [data.id],
                    manualSettlementState: "0"
                }

                manualSettlementRollback(params).then(res => {
                    if (res.code == 0) {
                        console.log(res);
                        message.success('撤销成功')
                        this.getData()
                    }
                })
            }
        });
    }


    handleSettlementSync = () => {
        this.setState({
            uploadModal: true
        })
    }

    closeModal = () => {
        this.setState({ uploadModal: false })
    }

    render() {
        let { dataList, tenantOptions, projectOptions, totalRow, page, uploadModal, loading, selectIds, postBackId } = this.state;


        let columns = [
            {
                title: '病例编码',
                dataIndex: 'subjectCode',
                width: '',
            },
            {
                title: '患者',
                dataIndex: 'patientName',
                width: 140,
            },
            {
                title: '结算状态',
                dataIndex: 'manualSettlementState',
                width: 120,
                render: (text, record) => {
                    return (
                        <div className='apply_state'>
                            {record.manualSettlementState == '1' ? <span style={{ color: '#3391F0' }}>已结算</span> : <span style={{ color: '#52C41A' }}>待结算</span>}
                        </div>
                    )
                }
            },
            {
                title: '提交人',
                dataIndex: 'submitUserName',
                width: '',
                render: (text, record) => (
                    <div className={'con_div'}>
                        <div>{record.submitUserName}</div>
                        <div>{record.submitUserCode}</div>
                    </div>
                )
            },
            {
                title: '签名审核时间',
                dataIndex: 'approveTime',
                width: 140,
                render: (text, record) => {
                    if (record.submitTime) {
                        return (
                            <div className={'con_div'}>{record.approveTime ? record.approveTime.replace('T', ' ') : ''}
                            </div>
                        )
                    }
                }
            },
            {
                title: '项目',
                dataIndex: 'projectName',
                width: '',
            },
            {
                title: '租户',
                dataIndex: 'tenantName',
                width: '',
            },
            {
                title: '审核人',
                dataIndex: 'manualSettlementAuditUser',
                width: '',
            },
            {
                title: '结算时间',
                dataIndex: 'manualSettlementAuditTime',
                width: 120,
                render: (text, record) => {
                    if (record.approveTime) {
                        return (
                            <div className={'con_div'}>{record.manualSettlementAuditTime ? record.manualSettlementAuditTime.replace('T', ' ') : ''}
                            </div>
                        )
                    }
                }
            },
            {
                title: '操作',
                width: 100,
                fixed: 'right',
                align: 'center',
                render: (text, record) =>
                    <div>
                        {record.manualSettlementState == '1' ? <Button type="link" onClick={() => this.handleSettlementRollback(record)} >
                            撤销结算
                        </Button> : <Button type="link" onClick={() => this.handleSettlement(record)} >
                            结算
                        </Button>}
                    </div>
            },
        ]

        const rowSelection = {
            selectedRowKeys: selectIds,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectIds: selectedRowKeys
                })
            },
            getCheckboxProps: (record) => ({
                disabled: record.manualSettlementState == '1'
            }),
        };

        let pagination = {
            onChange: (pageNo) => {
                this.getData(pageNo);
            },
            current: page,
            pageSize: 10,
            total: totalRow,
            showSizeChanger: false,
            showTotal: total => `共${total} 条记录`,
        }


        return (
            <div className='settlementReview_content'>
                <div className={'apply_wrap'}>
                    <div className={'search_wrap_content'}>
                        <div className='search_left'>
                            <Row className='search_row'>
                                <Col span={6}>
                                    <span className='search_label'>选择租户</span>
                                    <Select
                                        showSearch
                                        placeholder="请选择租户"
                                        style={{ width: '70%' }}
                                        value={this.state.tenantCode}
                                        allowClear
                                        onChange={value => this.setState({ tenantCode: value })}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {tenantOptions.map(item => (
                                            <Option key={item.code} value={item.code}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={6}>
                                    <span className='search_label'>选择项目</span>
                                    <Select
                                        showSearch
                                        placeholder="请选择项目"
                                        style={{ width: '70%' }}
                                        value={this.state.projectCode}
                                        allowClear
                                        onChange={value => this.setState({ projectCode: value })}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {projectOptions.map(item => (
                                            <Option key={item.projectCode} value={item.projectCode}>{item.projectName}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={6}>
                                    <span className='search_label'>病例编码</span>
                                    <Input
                                        allowClear
                                        placeholder="请输入病例编码"
                                        value={this.state.subjectCode}
                                        onChange={(e) => this.setState({ subjectCode: e.target.value })}
                                        style={{ width: '70%' }}
                                    />
                                </Col>
                                <Col span={6}>
                                    <span className='search_label'>患者名称</span>
                                    <Input
                                        allowClear
                                        placeholder="请输入患者名称"
                                        value={this.state.patientName}
                                        onChange={(e) => this.setState({ patientName: e.target.value })}
                                        style={{ width: '70%' }}
                                    />
                                </Col>
                                <Col span={6} >
                                    <span className='search_label'>结算状态</span>
                                    <Select
                                        placeholder="请选择结算状态"
                                        style={{ width: '70%' }}
                                        value={this.state.manualSettlementState}
                                        allowClear
                                        onChange={value => this.setState({ manualSettlementState: value })}
                                    >
                                        <Option value='0'>待结算</Option>
                                        <Option value='1'>已结算</Option>

                                    </Select>
                                </Col>
                                <Col span={6}>
                                    <span className='search_label'>提交账号</span>
                                    <Input
                                        allowClear
                                        placeholder="请输入提交账号"
                                        value={this.state.submitUserCode}
                                        onChange={(e) => this.setState({ submitUserCode: e.target.value })}
                                        style={{ width: '70%' }}
                                    />
                                </Col>
                                <Col span={6}>
                                    <span className='search_label'>提交姓名</span>
                                    <Input
                                        allowClear
                                        placeholder="请输入提交姓名"
                                        value={this.state.submitUserName}
                                        onChange={(e) => this.setState({ submitUserName: e.target.value })}
                                        style={{ width: '70%' }}
                                    />
                                </Col>
                            </Row>

                        </div>
                        <div className='right_action'>
                            <Button type="primary" size={'middle'} onClick={this.handleSearch} >
                                查询
                            </Button>
                            <Button type="primary" ghost size={'middle'} onClick={this.resetSearch} >
                                重置
                            </Button>
                        </div>
                    </div>
                    <div className='btns_wrap'>
                        <Button type='primary' onClick={this.handleBatchSettlement}>批量结算</Button>
                        <Button type='primary' ghost onClick={this.handleSettlementSync}>结算同步</Button>
                    </div>
                    <Spin spinning={loading}>
                        <Table
                            locale={{ emptyText: "暂无数据" }}
                            columns={columns}
                            dataSource={dataList}
                            pagination={pagination}
                            rowKey={'id'}
                            scroll={{ x: 'calc(700px + 50%)' }}
                            rowSelection={rowSelection}
                        />
                    </Spin>
                </div>

                <div>
                    {uploadModal && <UploadModal postBackId={postBackId} uploadModal={uploadModal} closeModal={this.closeModal} />}
                </div>
            </div >
        )
    }
}

export default SettlementReview