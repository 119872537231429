import { Empty, Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { getPagePaginate, QueryParams, SubjectResult } from "../../../../../../services/data/dashboard";
import { DataBoardContext } from "../../index.context";

type Props = {

}

let i = 1

const PageTable: React.FC<Props> = ()=> {

  const ctx = useContext(DataBoardContext)
  
  const [pageSize] = useState(20)
  const [pageNum, setPageNum] = useState(1)
  
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const [dataSource, setDataSource] = useState<SubjectResult[]>([])

  //#region 表格列
  const columns: ColumnsType<SubjectResult> = [
    {
      width: 120,
      title: '区域',
      ellipsis: true,
      dataIndex: 'areaName',
      render: (value: string)=> value || '-'
    },
    {
      width: 120,
      title: '研究中心',
      dataIndex: 'centerName',
    },
    {
      width: 120,
      title: '研究者',
      dataIndex: 'doctorName',
    },
    {
      width: 120,
      title: '患者编号',
      dataIndex: 'subjectCode',
    },
    {
      width: 80,
      title: '访视名称',
      dataIndex: 'visitName',
    },
    {
      width: 120,
      title: '页面名称',
      dataIndex: 'formName',
    },
    {
      width: 80,
      title: '页面编号',
      dataIndex: 'num',
    },
    {
      width: 80,
      title: '页面是否已提交',
      dataIndex: 'isSubmit',
    },
    {
      width: 80,
      title: '页面是否已补充录入',
      dataIndex: 'isReplenish',
    },
    {
      width: 80,
      title: '页面是否存在未关闭质疑',
      dataIndex: 'isQuestion',
    },
    {
      width: 80,
      title: '页面是否已签名',
      dataIndex: 'isSignature',
    },
    {
      width: 80,
      title: '页面是否已锁定',
      dataIndex: 'isLock',
    },
  ];
  //#endregion

  const fetchPaginate = async (page = 1)=> {
    setLoading(true)
    const { data } = await getPagePaginate(page, pageSize, ctx.projectCode, ctx.queryParams)
    setLoading(false)
    setTotal(data.total)
    setDataSource(data.rows)
    setPageNum(page)
  }

  useEffect(()=> {
    fetchPaginate()
  }, [ctx.queryParams])

  return (
    <Table
      loading={loading}
      size="middle"
      rowKey={()=> `${i++}`}
      scroll={{y: window.innerHeight - 563}}
      columns={columns}
      dataSource={dataSource}
      locale={{emptyText: <Empty description="暂无数据"/>}}
      pagination={{
        onChange: (page: number)=> fetchPaginate(page),
        showTotal: total=> `共${total}条，每页${pageSize}条`,
        style: {marginBottom: 0},
        total: total,
        current: pageNum,
        pageSize: pageSize,
        hideOnSinglePage: true,
        showSizeChanger: false,
        showQuickJumper: false,
        position: ["bottomRight"]}}/>
  )
}

export default PageTable