/**
  * 
  * @param {审核操作记录} params 
  * @returns 
  */

 import { requestPostFile, requestPost, requestGet, requestPostRes, requestGetRes, requestPatchRes } from '../EdcService';

 /**
 * 查询受试者审核列表
 */
export const GetApproveRecordList = (params) => {
    const url = `/data/approveRecordList`;
    return requestPostRes(url,params)
};

/**
 * 项目枚举 
 */ 
export const GetProjectEnume = (params) => {
    const url = `/project/process/list`;
    return requestGet(url, params)
};