import React, { useEffect, useState, useRef } from 'react';
import './ocrView.less'
import { Button, message, Modal, Tabs } from 'antd';
import { createFromIconfontCN, ExclamationCircleOutlined } from '@ant-design/icons';
import Zmage from 'react-zmage'
import { updateOcrList } from '../../../../services/data/DataService';
const { confirm } = Modal;
const { TabPane } = Tabs;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2343612_ls9uemqdl2.js'
        //iconInput_Running iconInput_Complete iconInput_Wait
        //iconCheck_Running iconCheck_Question iconCheck_Wait iconCheck_Complete
        //iconQuestion_Running iconQuestion_Question iconQuestion_Wait iconQuestion_Complete iconQuestion_Answer
        //iconExamine_Running
        //iconFreeze_Running
        //iconAutograph_Complete
        //iconLock_Complete
    ],
});
const OcrView = (props: any) => {
    // 缩放比例
    const [scaleRate, setScaleRate] = useState<number>(1);
    // 旋转角度
    const [rotateVal, setRotateVal] = useState<number>(0);
    //大图片组件dom操作
    const [formId, setFormId] = useState<any>();
    const imgViewRef = useRef<any>(null);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [imgs, setImgs] = useState<any>([]);

    useEffect(() => {
        if (props.data) {
            setImgs(props.data);
            setFormId(props.formId);
        }
    }, [props])

    /**
     * 切换图片选择事件
     */
    const handleChangeImg = (index: number) => {
        setScaleRate(1);
        imgViewRef.current.style.transform = `scale(1)`;
        setRotateVal(0);
        setCurrentIndex(index);
    }

    /**
     * 图片放大事件
     */
    const handleBigImg = () => {
        if (imgViewRef.current) {
            const rate = scaleRate + 0.1;
            if (rate <= 2.0) {
                setScaleRate(rate);
            }
            imgViewRef.current.style.transform = `rotate(${rotateVal}deg) scale(${rate})`;
        }
    }

    /**
     * 图片缩小事件
     */
    const handleSmallImg = () => {
        if (imgViewRef.current) {
            const rate = scaleRate - 0.1;
            if (rate >= 0.5) {
                setScaleRate(rate);
            }
            imgViewRef.current.style.transform = `rotate(${rotateVal}deg) scale(${rate})`;
        }
    }

    /**
     * 图片正向旋转事件
     */
    const handleRotateImg = () => {
        const deg = rotateVal + 90;
        setRotateVal(deg);
        imgViewRef.current.style.transform = `rotate(${deg}deg) scale(${scaleRate})`;
    }

    /**
     * 图片反向旋转事件
     */
    const handleRotateBackImg = () => {
        const deg = rotateVal - 90;
        setRotateVal(deg);
        imgViewRef.current.style.transform = `rotate(${deg}deg) scale(${scaleRate})`;
    }

    /**
     * 图片删除事件
     */
    const handleDeleteImg = () => {
        confirm({
            title: '确认删除该图片吗？',
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk() {
                let filterArr = [];
                for (let i = 0; i < imgs.length; i++) {
                    if (currentIndex !== i) {
                        filterArr.push(imgs[i])
                    }
                }
                let params = {
                    formId: formId,
                    images: filterArr
                }
                updateOcrList(params).then(res => {
                    if (res.code == '0') {
                        message.success('删除成功！', 1);
                        setImgs(res.data)
                        if (currentIndex - 1 >= 0) {
                            setCurrentIndex(currentIndex - 1);
                        }
                        else {
                            setCurrentIndex(currentIndex);
                        }
                    }
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    /**
     * 上一张图片事件
     */
    const handlePrevImg = () => {
        let prev = currentIndex - 1;
        if (prev < 0) {
            prev = 0;
        } else {
            setScaleRate(1);
            imgViewRef.current.style.transform = `scale(1)`;
            setRotateVal(0);
        }
        setCurrentIndex(prev);
    }

    /**
     * 下一张图片事件
     */
    const handleNextImg = () => {
        let next = currentIndex + 1;
        if (next > imgs.length - 1) {
            next = imgs.length - 1;
        } else {
            setScaleRate(1);
            imgViewRef.current.style.transform = `scale(1)`;
            setRotateVal(0);
        }
        setCurrentIndex(next);
    }

    //全屏
    const handleFullImg = () => {
        return Zmage.browsing({ src: imgs[currentIndex] })
    }

    /**
   * icon 图标
   */
    const formatIcon = (iconName: any) => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    return (
        <div className={'container'}>
            <div className={'imgWrapper'}>
                {
                    imgs.length > 0 && imgs.map((path: string, ind: number) => (
                        <img key={ind} src={path}
                            className={currentIndex == ind ? 'imgActive' : ''}
                            onClick={() => handleChangeImg(ind)}
                            alt="img"
                        ></img>
                    ))
                }
                {
                    imgs.length === 0 ? <span className="noOrc_img">{formatIcon('iconnoOrc')}</span> : null
                }
            </div>
            <div className={'bigPic'}>
                <div className={'center-pane'}>
                    {
                        imgs.length > 0 ? <img src={imgs[currentIndex]} ref={imgViewRef} alt="img"></img> : null
                    }
                    {
                        imgs.length === 0 ? <div className="noOrc_img">{formatIcon('iconnoOrc')}</div> : null
                    }
                </div>
            </div>
            {
                imgs.length > 0 ? <div className={'picOpt'}>
                    <p className={'left'}>
                        <span onClick={handleBigImg}>{formatIcon('iconAmplification_img')}</span>
                        <span onClick={handleSmallImg}>{formatIcon('iconNarrow_img')}</span>
                        <span onClick={handleRotateImg}>{formatIcon('iconToRight_img')}</span>
                        <span onClick={handleRotateBackImg}>{formatIcon('iconToLeft_img')}</span>
                        <span onClick={handleFullImg}>{formatIcon('iconFullScreen')}</span>
                        <span onClick={handleDeleteImg}>{formatIcon('iconDelete_img')}</span>
                    </p>
                    <p className={'right'}>
                        <span onClick={handlePrevImg}>{formatIcon('iconPrevious_img')}</span>
                        <span onClick={handleNextImg}>{formatIcon('iconNext_img')}</span>
                    </p>
                </div> : null
            }
        </div>
    )
}

export default OcrView;

