import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { saveFormData } from "../../../../services/data/DataService";
import TextArea from 'antd/lib/input/TextArea';
import './textarea.less';
import ActionView from '../../com/actionView/actionView';
import formAction from '../../utils/formAction';
import DctOcrView from '../../com/dctOcrView/dctOcrView';

function TextareaCom(props: any, ref: any) {
    const [value, setValue] = useState<any>();
    const [data, setData] = useState<any>();
    const [formId, setFormId] = useState<any>();
    const [dataActions, setDataActions] = useState<any>([]);
    const [formIsRead, setFormIsRead] = useState<boolean>(true);
    const [timeObject, setTimeObject] = useState<any>();
    const [savedValues, setSavedValues] = useState<any>([]);
    const [updatedValue, setUpdatedValue] = useState<any>();
    const [isUpdate, setIsUpdate] = useState<boolean>(false);

    const [ocrLists, setOcrLists] = useState<any>(null);
    // 手动划词内容
    const [ocrValue, setOcrValue] = useState<any>(null);

    useEffect(() => {
        if (props.data) {
            //设置dataActions
            setDataActions(props.dataActions);
            //表单是否只读
            setFormIsRead(props.formIsRead);
            //获取当前表单的id
            setFormId(props.formId);
            //该条表单元素的数据
            setData(props.data);
            //已经保存的表单数据
            setSavedValues(props.savedValues);
            //如果是修改时
            if (props.isUpdate) {
                setIsUpdate(props.isUpdate)
            }
            else {
                setIsUpdate(false)
            }

            if (props.ocrLists) {
                setOcrLists(props.ocrLists)
            }

            //设置当前表单已存的value
            let currentItem = props.savedValues.find((i: any) => {
                return i.dataCode == props.data.dataCode
            });
            if (currentItem) {
                setValue(currentItem.value);
                setUpdatedValue(currentItem.value);
            }
            else {
                setValue(null);
                setUpdatedValue(null);
            }
        }
    }, [props])

    //当提交后显示的value
    const showSavedValue = () => {
        if (value) {
            return value;
        }
        else {
            return '未录入'
        }
    }

    const onChange = (e: any) => {
        saveItemData(e.target.value);
        if ((props.ocrLists && props.ocrLists.length > 0 && ocrValue) || e.target.value == '') {
            deleteFunc()
        }
    }

    // 清除划词
    const deleteFunc = () => {
        let currentData = {
            sort: data.sort,
            dataCode: data.dataCode,
            deCode: data.deCode
        }
        
        props.deleteDrawAreaFunc && props.deleteDrawAreaFunc(currentData).then((res: String) => {
            console.log('删除划词', res);
        });
    }

    const saveItemData = (filterValue: any) => {
        setValue(filterValue)

        let extendAttribute = ''
        if (props.data.isAdd) {
            extendAttribute = 'isAdd=true'
        }


        let values = [
            {
                dataCode: data.dataCode,
                deCode: data.deCode,
                value: filterValue,
                extendAttribute: extendAttribute ? extendAttribute : undefined
            }
        ]
        
        if (isUpdate) {
            setUpdatedValue(value);
        }
        if (timeObject) {
            clearTimeout(timeObject);
        }
        let time = setTimeout(() => {
            if (!isUpdate) {
                saveData(formId, values)
            }
        }, 200);
        setTimeObject(time)
    }

    //暂存数据
    const saveData = (formId: any, values: any) => {
        let params = {
            formId: formId,
            submit: false,
            values: values
        }
        formAction.saveData(params);
        // props.startTimerFun();
        props.updateFun()
        // saveFormData(params).then((res) => {
        //     props.updateFun()
        // })
    }

    useImperativeHandle(ref, () => ({
        // changeVal 就是暴露给父组件的方法
        getModifyValue: () => {
            let params = {
                dataCode: data.dataCode,
                deCode: data.deCode,
                formId: formId,
                value: updatedValue,
            }
            return params;
        },
    }));

    // 获取划词信息
    const getOcrValue = (values: any) => {
        setOcrValue(values)
        data['isDelimitation'] = true
        saveItemData(values && values.text);
    }

    // 获取划词icon信息
    const getOcrIcon = (values: any) => {
        props.getOcrIcon(values)
    }

    return (
        <div className="textareaCom">
            {/* {
                formIsRead ? <TextArea showCount maxLength={100} allowClear={true} style={{ width: '100%' }} disabled={!formIsRead} value={value} placeholder={data ? `请输入${data.label}` : ''} onChange={onChange} /> : <div>{showSavedValue()}</div>
            } */}
            <TextArea 
                showCount maxLength={props.textAreaMaxLen ? props.textAreaMaxLen : 100} allowClear={true} 
                style={{ width: '100%' }} 
                disabled={!formIsRead} 
                value={value} 
                placeholder={data ? `请输入${data.label}` : ''} 
                onChange={onChange}
            />
            <div style={{display: 'flex'}}>
                <DctOcrView formItem={data} ocrLists={ocrLists} 
                    startFun={props.triggerDrawAreaFunc} 
                    cancleFun={props.cancelDrawAreaFunc} 
                    getOcrValue={getOcrValue}
                    getOcrIcon={getOcrIcon}
                    value={value}
                />
            </div>
            
        </div>
    )
}

export default forwardRef(TextareaCom);
