import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { DatePicker, message } from 'antd';
import moment from 'moment';
import './date.less';
import formAction from '../../utils/formAction';
import locale from 'antd/es/date-picker/locale/zh_CN';
import utils from '../../utils/utils';

function DateCom(props: any, ref: any) {
    const [value, setValue] = useState<any>();
    const [data, setData] = useState<any>();
    const [formId, setFormId] = useState<any>();
    const [dataActions, setDataActions] = useState<any>([]);
    const [formIsRead, setFormIsRead] = useState<boolean>(true);
    const [timeObject, setTimeObject] = useState<any>();
    const [savedValues, setSavedValues] = useState<any>([]);
    const [updatedValue, setUpdatedValue] = useState<any>();
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [minDate, setMinDate] = useState<Date>();
    const [maxDate, setMaxDate] = useState<Date>();

    const [minVisitDate, setMinVisitDate] = useState<any>('');

    useEffect(() => {
        if (props.data) {
            //设置dataActions
            setDataActions(props.dataActions);
            //表单是否只读
            setFormIsRead(props.formIsRead);
            //获取当前表单的id
            setFormId(props.formId);
            //该条表单元素的数据
            setData(props.data);
            //已经保存的表单数据
            setSavedValues(props.savedValues);
            //如果是修改时
            if (props.isUpdate) {
                setIsUpdate(props.isUpdate)
            }
            else {
                setIsUpdate(false)
            }
            //设置最大值最小值
            if (data && data.validationRules && data.validationRules.length > 0) {
                for (let item of data.validationRules) {
                    if (item.type === 'range') {
                        if (item.condition === 'lte') {
                            if (item.rule === '${currDate}') {
                                setMaxDate(new Date());
                            }
                        }
                        else if (item.condition === 'gte') {
                            if (item.rule) {
                                setMinDate(new Date(item.rule));
                            }
                        }
                    } else if (item.type === 'compare') {
                        if (props.savedValues && props.savedValues.length > 0) {
                            let compareData;
                            for (let value of props.savedValues) {
                                if (item.rule === value["dataCode"]) {
                                    compareData = value["value"];
                                }
                            }
                            if (compareData && item.condition === 'lte') {
                                setMaxDate(new Date(compareData));
                            } else if (compareData && item.condition === 'gte') {
                                setMinDate(new Date(compareData));
                            }
                        }
                    }
                }
            }
            //设置当前表单已存的value
            let currentItem = props.savedValues.find((i: any) => {
                return i.dataCode == props.data.dataCode
            });
            if (currentItem) {
                setValue(currentItem.value ? moment(currentItem.value) : null);
                setUpdatedValue(currentItem.value ? moment(currentItem.value) : null);
            } else {
                setValue(null);
                setUpdatedValue(null);
            }


            getVisitDate()
        }
    }, [props])

    const validationRulesFun = () => {
        //设置最大值最小值
        if (data && data.validationRules && data.validationRules.length > 0) {
            for (let item of data.validationRules) {
                if (item.type === 'range') {
                    if (item.condition === 'lte') {
                        if (item.rule === '${currDate}') {
                            setMaxDate(new Date());
                        }
                    }
                    else if (item.condition === 'gte') {
                        if (item.rule) {
                            setMinDate(new Date(item.rule));
                        }
                    }
                } else if (item.type === 'compare') {
                    if (props.savedValues && props.savedValues.length > 0) {
                        let compareData;
                        for (let value of props.savedValues) {
                            if (item.rule === value["dataCode"]) {
                                compareData = value["value"];
                            }
                        }
                        if (compareData && item.condition === 'lte') {
                            setMaxDate(new Date(compareData));
                        } else if (compareData && item.condition === 'gte') {
                            setMinDate(new Date(compareData));
                        }
                    }
                }
            }
        }
    }

    const getNextDate = (date: any, day: any) => {
        var dd = new Date(date);
        dd.setDate(dd.getDate() + day);
        var y = dd.getFullYear();
        var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
        var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
        return y + "-" + m + "-" + d;
    };


    const getVisitDate = () => {
        if (data && data.validationRules && data.validationRules.length > 0) {
            for (let item of data.validationRules) {
                if (item.type === 'range') {
                    if (item.condition === 'gte') {
                        if (item.rule === 'visitDate') {
                            if (item.visit) {
                                let _data = props.visitInfoList.find((v: any) => {
                                    return v.visitName == item.visit
                                })
                                if (_data && _data.visitDate) {
                                    if (item.space) {
                                        let newDate = utils.getNewDateFormDays(_data.visitDate, item.space, 'add')
                                        setMinVisitDate(newDate)
                                    }
                                }
                            }
                        }
                    }
                } else if(item.type === 'compare') {
                    if (item.condition === 'gte') {
                        if (item.rule === 'visitDate') {
                            if (item.visit) {
                                let _data = props.visitInfoList.find((v: any) => {
                                    return v.visitName == item.visit
                                })
                                if (_data && _data.visitDate) {
                                    if (item.space) {
                                        let newDate = utils.getNewDateFormDays(_data.visitDate, item.space, 'add')
                                        setMinVisitDate(newDate)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }



    //控制不可选的时间范围
    const disabledDate = (current?: any) => {
        // if (maxDate) {
        //     return current >= moment(maxDate).endOf('day');
        // }
        // else if (minDate) {
        //     return current <= moment(minDate).endOf('day');
        // }
        // else {
        //     return false;
        // }

        let max: any;
        let min: any;
        const dateRegExp = new RegExp(/^(\d{4})-(\d{2})-(\d{2})$/);
        const dateTimeRegExp = new RegExp(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/)
        if (data && data.validationRules && data.validationRules.length > 0) {
            for (let item of data.validationRules) {
                if (item.type === 'range') {
                    if (item.condition === 'lte') {
                        if (item.rule === '${currDate}') {
                            max = new Date();
                        } else if (item.rule === 'visitDate') {
                            if (item.visit) {
                                let _data = props.visitInfoList.find((v: any) => {
                                    return v.visitName == item.visit
                                })
                                if (_data && _data.visitDate) {
                                    if (item.space) {
                                        let newDate = utils.getNewDateFormDays(_data.visitDate, item.space, 'reduce')
                                        max = new Date(newDate);
                                    } else {
                                        max = new Date(_data.visitDate);
                                    }
                                }
                            }
                        }
                    }
                    else if (item.condition === 'gte') {
                        if (item.rule === 'visitDate') {
                            if (item.visit) {
                                let _data = props.visitInfoList.find((v: any) => {
                                    return v.visitName == item.visit
                                })
                                if (_data && _data.visitDate) {
                                    if (item.space) {
                                        let newDate = utils.getNewDateFormDays(_data.visitDate, item.space, 'add')
                                        min = new Date(newDate);
                                    } else {
                                        min = new Date(_data.visitDate);
                                    }
                                }
                            }

                        } else if (item.rule) {
                            // min = new Date(item.rule)
                            min = new Date(getNextDate(item.rule, -1))
                        }
                    }
                    else if(item.condition === 'between') {
                        if(Array.isArray(item.rule)) {
                            // 要填充的时间
                            const fill = ['00:00:00', '23:59:59']
                            // 只有日期时自动补充时间
                            const pieces = item.rule.map((now: string, i: number)=> {
                                if(dateRegExp.test(now)) {
                                    return `${now} ${fill[i]}`
                                }
                            })

                            for(const now of pieces) {
                                if(!dateTimeRegExp.test(now)) {
                                    message.error('日期格式配置错误')
                                    return false
                                }
                            }

                            [min, max] = pieces.map((t: string)=> new Date(t))

                            return !(current >= moment(min).startOf('day') && current <= moment(max).endOf('day'))
                        }
                    }
                }
                else if (item.type === 'compare') {
                    if(item.rule === 'visitDate') {
                        if(item.condition === 'gte') {
                            return current < moment(minVisitDate).endOf('day')
                        }
                    }
                    if (props.savedValues && props.savedValues.length > 0) {
                        let compareData;
                        let _index = -1; // 多组时当前下标
                        if (props.data && props.data["dataCode"] && props.data["dataCode"].indexOf('#') >= 0) {
                            _index = props.data["dataCode"].split('#')[1];
                        }
                        for (let value of props.savedValues) {
                            if ((item.rule == value["dataCode"]) || (item.rule == value["deCode"])) {
                                compareData = value["value"];
                            }
                            // 多组
                            if (_index != -1) {
                                // eg -> startTime12#{index}
                                if (item.rule && item.rule.indexOf('#') >= 0) {
                                    let rulePre = item.rule.split('#')[0];
                                    let nowRule = rulePre + '#' + _index;
                                    if (nowRule == value["dataCode"]) {
                                        compareData = value["value"];
                                    }
                                }
                            }
                        }
                        if (compareData && item.condition === 'lte') {
                            max = new Date(compareData)
                        } else if (compareData && item.condition === 'gte') {
                            min = new Date(compareData)
                        }
                    }
                }
            }
        }
        
        if (max && min) {
            return current > moment(max).endOf('day') || current < moment(min).startOf('day')
        }
        else if (max) {
            return current > moment(max).endOf('day')
        }
        else if (min) {
            return current < moment(min).startOf('day')
        } else {
            return false
        }
    }


    //当提交后显示的value
    const showSavedValue = () => {
        if (value) {
            return moment(value).format('YYYY-MM-DD')
        }
        else {
            return '未录入'
        }
    }


    const onChange = (value: any) => {
      
        // if(!value){
        //     // 对于日期格式，时间YYYY-MM-DD
        //     if(props.type=="date"){
        //         value = moment(value).format("YYYY-MM-DD")
        //     }       
        // }
        if(!value){
            value= ""   // 置空 
         }

        setValue(value)
        let extendAttribute = ''
        if (props.data.isAdd) {
            extendAttribute = 'isAdd=true'
        }
        let values = [
            {
                dataCode: data.dataCode,
                deCode: data.deCode,
                value: value , // null后端会自动填写当前时间
                extendAttribute: extendAttribute ? extendAttribute : undefined
            }
        ]
        if (isUpdate) {
            setUpdatedValue(value);
        }
        if (timeObject) {
            clearTimeout(timeObject);
        }
        let time = setTimeout(() => {
            if (!isUpdate) {
                saveData(formId, values)
            }
        }, 200);
        setTimeObject(time)
    }

    //暂存数据
    const saveData = (formId: any, values: any) => {
        let params = {
            formId: formId,
            submit: false,
            values: values
        }
        formAction.saveData(params);
        // props.startTimerFun();
        props.updateFun(getPure(values[0].value))
        // saveFormData(params).then((res) => {
        //     props.updateFun()
        // })
    }

    const getPure = (currentValue?: any)=> ({
        dataCode: data.dataCode,
        deCode: data.deCode,
        formId: formId,
        value: currentValue ?? updatedValue,
    })

    useImperativeHandle(ref, () => ({
        // changeVal 就是暴露给父组件的方法
        getModifyValue: getPure
    }));

    return (
        <div className="dateCom">
            {/* {
                formIsRead ? <DatePicker disabledDate={disabledDate} allowClear={false} disabled={!formIsRead} value={value} style={{ width:'100%' }} placeholder={data ? `请输入${data.label}` : ''} format={'YYYY/MM/DD'} onChange={onChange} /> : <div>{showSavedValue()}</div>
            } */}
           
            <DatePicker
                defaultPickerValue={value ? moment(value) : props.data.dateDefaultValue ? moment(props.data.dateDefaultValue) : minVisitDate ? moment(minVisitDate) : moment(new Date())}
                showTime={props.type == 'datetime'}
                disabledDate={disabledDate}
                allowClear={true}
                disabled={!formIsRead}
                value={value ? moment(value):value}
                style={{ width: '100%' }}
                placeholder={data ? `请输入${data.label}` : ''}
                format={props.type == 'datetime' ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'}
                onChange={onChange}
                locale={locale}
                
            />
        </div>
    )
}

export default forwardRef(DateCom);
