
import modStyl from './PhoneModal.module.scss'
import { Button, message as messager, Modal } from "antd"
import React, { useContext, useState } from "react"
import UserInfo from './UserInfo'
import { DataType, visitTaskContext } from '..'
import { sendVisitSMS } from '../../../../services/dct/visit'

type Props = {
  current: DataType;
  onClose: ()=> void;
}

const SendModal: React.FC<Props> = ({current, onClose})=> {
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  const ctx = useContext(visitTaskContext)

  const sendHandle = async ()=> {
    setLoading(true)
    const {code, message} = await sendVisitSMS(current.id)
    setLoading(false)
    setSent(true)
    if(code === 0) {
      onClose()
      ctx.reload()
      messager.success('发送成功')
    }
  }
  return (
    <Modal visible={true} title="短信随访" onCancel={onClose} footer={null} closable maskClosable={false} destroyOnClose>
      <div className={modStyl.wrapper}>
        <div className={modStyl.row}>
          <div className={modStyl.label}>患者</div>
          <div className={modStyl.content}>
            <UserInfo data={current}/>
          </div>
        </div>
        <div className={modStyl.row}>
          <div className={modStyl.label}>短信模板</div>
          <div className={modStyl.content}>
            <p className={modStyl.readonly}>{current.msgContent}</p>
          </div>
        </div>
        {
          false === sent && (
            <div className={modStyl.row}>
              <Button loading={loading} onClick={sendHandle} type='primary' className={modStyl.button}>发送短信</Button>
            </div>
          )
        }
      </div>
    </Modal>
  )
}

export default SendModal