export default {
    getHeaders(extraData: Object) {
        // 判断是否有token
        const token = sessionStorage.getItem('token');
        let headers;
        if (token) {
            let loginResult = JSON.parse(token);
            headers = {
                'x-auth-token': loginResult.token,
            };
            if (extraData) {
                headers = Object.assign({}, headers, extraData);
            }
        }
        return headers;
    },
    //计算时间差
    get_time_diff(time: any) {
        var diff = '';
        var time_diff = new Date().getTime() - new Date(time).getTime(); //时间差的毫秒数 

        //计算出相差天数 
        var days = Math.floor(time_diff / (24 * 3600 * 1000));
        if (days > 0) {
            diff += days + '天';
        }
        //计算出小时数 
        var leave1 = time_diff % (24 * 3600 * 1000);
        var hours = Math.floor(leave1 / (3600 * 1000));
        if (hours > 0) {
            diff += hours + '小时';
        } else {
            if (diff !== '') {
                diff += hours + '小时';
            }
        }
        //计算相差分钟数 
        var leave2 = leave1 % (3600 * 1000);
        var minutes = Math.floor(leave2 / (60 * 1000));
        if (minutes > 0) {
            diff += minutes + '分钟';
        } else {
            if (diff !== '') {
                diff += minutes + '分钟';
            }
            else {
                diff += "不到1分钟";
            }
        }

        //计算相差秒数 
        // var leave3 = leave2 % (60 * 1000);
        // var seconds = Math.round(leave3 / 1000);
        // if (seconds > 0) {
        //     diff += seconds + '秒';
        // } else {
        //     if (diff !== '') {
        //         diff += seconds + '秒';
        //     }
        // }

        return diff;
    },
    /**
     * [dateAddDays 从某个日期增加或者减少n天后的日期]
     * @param  {[string]} dateStr  [日期字符串]
     * @param  {[int]} dayCount [增加的天数]
     * @param  {[string]} type add增加  reduce减少
     * @return {[string]}[增加n天后的日期字符串]
     */
    getNewDateFormDays(dateStr: any, dayCount: any, type: any = 'add'): string {
        let tempDate: any = new Date(dateStr.replace(/-/g, "/"));//把日期字符串转换成日期格式
        let resultDate: any = ''
        if (type == 'add') {
            resultDate = new Date((tempDate / 1000 + (86400 * dayCount)) * 1000);//增加n天后的日期
        } else {
            resultDate = new Date((tempDate / 1000 - (86400 * dayCount)) * 1000);//增加n天后的日期
        }
        let resultDateStr = resultDate.getFullYear() + "-" + (resultDate.getMonth() + 1) + "-" + (resultDate.getDate());//将日期转化为字符串格式
        return resultDateStr;
    }

}