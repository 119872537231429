/**
 * 报名统计
 */
 import React from 'react';
 import './index.less';
 import { withRouter } from 'react-router-dom';
 import {
     Button, Table
 } from "antd";
 import MyModal from './modal/modal';
 import SelectArea from './select/selectArea';
 import { CsoProjectPage } from "../../../services/cso/CsoService";

 let _this;
 
 class ProjectManage extends React.Component {
 
     constructor(props) {
         super(props);
         _this = this;
         _this.state = {
             visible: false,
             projectCode: '',
             nowRecord: {},
             dataLists: [], // 列表集合
             columns: [
                 {
                     title: '编号',
                     width: 130,
                     render: (text, record, index) => {
                         return (
                             <div className={'con_div'}>
                                 <div className={'title'}>
                                    {record.code}
                                 </div>
                             </div>
                         )
                     }
                 },
                 {
                     title: '项目名称',
                     width: '',
                     render: (text, record, index) => {
                         return (
                             <div className={'con_div'}>
                                 <div className={'title'}>
                                     {record.name}
                                 </div>
                             </div>
                         )
                     }
                 },
                 {
                    title: '状态',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={`title ${record.state == 1 ? 'start' : record.state == 2 ? 'pause' : record.state == 3 ? 'closed' : ''}`}>
                                    {record.state == 1 ? '启用' : record.state == 2 ? '暂停' : record.state == 3 ? '关闭' : '' }
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '类型',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.type == 1 ? '公开项目' : record.type == 2 ? '封闭项目' : '' }
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '合作企业',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.companyCode}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '疾病领域',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.diseaseDomain}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '医生数',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.doctorCount}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '患者数',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.patientCount}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '坐席数',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                {record.seatsCount}
                                </div>
                            </div>
                        )
                    }
                },
                 {
                     title: <div style={{ textAlign: 'center' }}>操作</div>,
                     width: 180,
                     fixed: 'right',
                     render: (text, record, index) => {
                         return (
                             <div className={'con_div'} style={{display: 'flex'}} >
                                {/* <Button type='link' style={{ flex: 1, paddingLeft: 0, paddingRight: 0, textAlign: 'center' }} onClick={() => { this.operation(record) }} >管理</Button> */}
                                {/* <Button type='link' style={{ flex: 1, paddingLeft: 0, paddingRight: 0, textAlign: 'center' }} onClick={() => { this.operation(record) }} >坐席</Button> */}
                                <Button type='link' style={{ flex: 1, paddingLeft: 0, paddingRight: 0, textAlign: 'center' }} onClick={() => { this.operation(record) }} >医生</Button>
                                {/* <Button type='link' style={{ flex: 1, paddingLeft: 0, paddingRight: 0, textAlign: 'center' }} onClick={() => { this.operation(record) }} >处方</Button> */}
                             </div>
                         )
                     }
                 }
             ],// 表格 行 配置
             page: 1,//当前页码数
             pagination: { // 分页组件元素对象信息
                 onChange: (pageNo) => {
                     this.getPageData(pageNo);
                 },
                 current: 1,
                 pageSize: 10,
                 total: 0,
                 showSizeChanger: false,
             },
         }
     }
 
     componentDidMount() {
         _this.getPageData();
     }
 
     /**
      * 获取列表数据 
      */
     getPageData = async (curPage, name) => {
         let { page } = this.state;
         const params = {
             page: curPage ? curPage : page,
             pageSize: this.state.pagination.pageSize,
         }
 
         const res = await CsoProjectPage(params);
         if (res && res.code == 0) {
             _this.setState({
                 dataLists: [...res.data.rows],
                 page: res.data.page,
                 pageSize: res.data.pageSize,
                 pagination: {
                     ...this.state.pagination,
                     current: res.data.page,
                     pageSize: res.data.pageSize,
                     total: res.data.total,
                 }
             })
         }
     }

    /**
      * 操作 
      */
     operation (rParams) {
        this.setState({
            nowRecord: rParams,
            projectCode: rParams.code,
            visible: true
        })
     }

    /**
     * 设置关闭隐藏状态和要传递的参数
     */
    closeModal = () => {
        this.setState({
            visible: false,
        });
    }
 
     render() {
         let { columns, dataLists, pagination, projectCode, visible, nowRecord } = this.state;
 
         dataLists.map((item, index) => {
             item['key'] = index + 1
         })
 
         return (
             <div className='cso_project_manage_wrap'>
                 <div className={'queryList'}>
                     {/* <div className={'header_content'}>
                         <div className={'left_wrap'}>
                            <SelectArea></SelectArea>
                         </div>
                         <div className={'right_wrap'}>
                            <Button type='primary'onClick={() => { this.setState({ visible: true })} } >查询</Button>
                         </div>
                     </div> */}
                     <Table
                         locale={{ emptyText: "暂无数据" }}
                         columns={columns}
                         dataSource={dataLists}
                         defaultExpandAllRows={true}
                         expandIconAsCell={false}
                         pagination={dataLists.length !== 0 && pagination}
                         rowKey={'id'}
                         size='small'
                         scroll={{x: 1300 }}
                     />
                 </div>
                {/* 批量导入参与者 */}
                {
                    projectCode && 
                    <div className={'modal'}>
                        <MyModal
                            nowRecord={nowRecord}
                            projectCode={projectCode}
                            visible={visible}
                            closeModal={this.closeModal}
                        />
                    </div>
                }

             </div>
         );
     }
 }
 export default withRouter(ProjectManage);