/**
 * 菜单 服务
 * lechen
 */

import {get, post} from '../../comm/http';
import {message} from 'antd';
import Menu from "../../models/plat/Memu";
import ProjectMenu from "../../models/plat/ProjectMenu";

export async function GetMenuService(): Promise<any> {
    return new Promise((resolve, reject) => {
        /*get('/plat/menus')*/
        /*get('./mock/getMenus.json')*/
        get('/plat/menus?type=MENU')
            .then(res => {
                if (res.code == '0') {
                    let _data = res.data;
                    // _data[0].children.push(
                    //     {
                    //         children: [],
                    //         code: "CSO_MEDICINE_MANAGE",
                    //         config: "",
                    //         icon: "",
                    //         name: "药品管理",
                    //         type: "NAV",
                    //         url: "/csoManage/medicineManage"
                    //     }
                    // )
                    console.log(' _data _data ', _data);
                    resolve(new Menu({
                        children: _data
                    }).children);
                } else {
                    message.error(res.message);
                    reject(res.message);
                }
            }).catch(res => {
            message.error(res.message);
        });
    });
}

export async function GetProjectMenuService(url,params): Promise<any> {
    return new Promise((resolve, reject) => {
        post(url, params)
            .then(res => {
                if (res.code == '0') {
                    resolve(res.data);
                } else {
                    message.error(res.message);
                    reject(res.message);
                }
            }).catch(res => {
            message.error(res.message);
        });
    });
}
