import {combineReducers} from 'redux-immutable';
import {configReducer} from '../components/config/store';
import {designReducer} from '../components/design/store';
import {projectReducer} from '../components/project/store';
import {platReducer} from '../components/plat/store';
import {dataReducer} from '../components/data/store';

/**
 * 联合redux,模块化处理
 */
export default combineReducers({
    config: configReducer,
    design: designReducer,
    project: projectReducer,
    plat: platReducer,
    data: dataReducer,
})
