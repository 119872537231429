import React, {Component, Fragment} from 'react';
import './codeSetThemTreeDetail.less';
import {withRouter} from 'react-router-dom';
import {Form, Input, Select, Button, message, AutoComplete} from 'antd';
import debounce from 'lodash/debounce';
import {
    GetDataCodeSave,
    GetDataCodeSelects,
    GetDataCodeInfo,
    GetDataCodeModify,
    GetDataElementList,
    GetDataElementListSelect
} from '@/services/config/ConfigService'

const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 19,
    },
};
const {Option} = Select

class CodeSetThemTreeDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataThemSelectList: [], // 数据元类型下拉框集合
            themeIdSelectList: [], // 数据主题 下拉集合
            deCodeValue: '', // 数据元编码 值
            deCodeSelectList: [], // 数据元编码 下拉框集合
        };
        this.handleSearch = debounce(this.handleSearch, 1000);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.info && nextProps.operation == 'modify') {
            this.getDataElementInfo(nextProps.info.id)
        }
        if (nextProps.operation == 'add') {
            this.formRef.current.setFieldsValue({
                name: '',
                code: '',
                nameIndex: '',
                define: '',
                type: [],
                format: '',
                allowValue: '',
            })
        }
    }

    componentDidMount() {
        this.getDataElementSelects()
        this.getDataElementListSelect()
        if (this.props.operation == 'modify') {
            this.getDataElementInfo(this.props.info.id)
        }
        if (this.props.operation == 'add') {
            this.formRef.current.setFieldsValue({
                name: '',
                code: '',
                nameIndex: '',
                define: '',
                type: [],
                format: '',
                allowValue: '',
            })
        }
    }

    formRef = React.createRef()

    /**
     * 数据元 下拉框集合
     */
    getDataElementList = () => {
        let {themeId} = this.formRef.current.getFieldsValue(['themeId'])
        let param = {
            code: this.state.deCodeValue ? this.state.deCodeValue : null,
            themeId: themeId ? Array.isArray(themeId) ? null : themeId : null
        }
        GetDataElementList(param).then(res => {
            if (res) {
                this.setState({
                    deCodeSelectList: res.data,
                }, () => {
                    if (this.state.deCodeValue != '') {
                        let currentData = res.data.filter(item => (item.code == this.state.deCodeValue))
                        if (currentData.length != 0) {
                            let {name, type, nameIndex, define, format, allowValue, themeId} = currentData[0]
                            this.formRef.current.setFieldsValue({
                                name: name,
                                type: type ? type : [],
                                nameIndex: nameIndex,
                                define: define,
                                format: format,
                                allowValue: allowValue,
                                themeId: themeId,
                            })
                        }
                    }
                })
            }
        })
    }

    /**
     * 数据主题 下拉框数据集合
     */
    getDataElementListSelect = () => {
        GetDataElementListSelect().then(res => {
            if (res.code == 0) {
                this.setState({
                    themeIdSelectList: res.data
                })
            }
        })
    }

    /**
     * 数据元 下拉框数据集合
     */
    getDataElementSelects = () => {
        GetDataCodeSelects().then(res => {
            if (res.typeEnumList && res.typeEnumList.length != 0) {
                this.setState({
                    dataThemSelectList: res.typeEnumList
                })
            }
        })
    }

    /**
     * 数据元 详情
     */
    getDataElementInfo = (id = '') => {
        if (id) {
            GetDataCodeInfo(id).then(res => {
                if (res) {
                    this.formRef.current.setFieldsValue({
                        name: res.name,
                        code: res.code,
                        deCode: res.deCode ? res.deCode : '',
                        type: res.type ? res.type : [],
                        nameIndex: res.nameIndex,
                        define: res.define,
                        format: res.format,
                        allowValue: res.allowValue,
                        themeId: res.themeId ? res.themeId : [],
                    })
                } else {
                    this.formRef.current.setFieldsValue({
                        name: '',
                        code: '',
                        nameIndex: '',
                        define: '',
                        type: [],
                        format: '',
                        allowValue: '',
                        deCode: '',
                        themeId: [],
                    })
                }
            })
        }
    }

    /**
     * 数据元编码 change事件
     */
    handleChange = (v) => {
        this.formRef.current.setFieldsValue({
            deCode: v
        })
        let data = this.state.deCodeSelectList;
        if (data) {
            let currentData = data.filter(item => (item.code == v))
            if (currentData.length != 0) {
                let {name, type, nameIndex, define, format, allowValue, themeId} = currentData[0]
                this.formRef.current.setFieldsValue({
                    name: name,
                    type: type ? type : [],
                    nameIndex: nameIndex,
                    define: define,
                    format: format,
                    allowValue: allowValue,
                    themeId: themeId,
                })
            } else {
                this.formRef.current.setFieldsValue({
                    name: '',
                    type: [],
                    nameIndex: '',
                    define: '',
                    format: '',
                    allowValue: '',
                    /*themeId: [],*/
                })
            }
        }
    }

    /**
     * 数据元编码 Search事件
     */
    handleSearch = (val) => {
        if (val) {
            this.setState({
                deCodeValue: val,
            }, () => {
                this.getDataElementList()
            })
        }
    }

    /**
     * 数据主题 change事件
     */
    handleThemeIdChange = (val) => {
        this.formRef.current.setFieldsValue({
            name: '',
            code: '',
            nameIndex: '',
            define: '',
            type: [],
            format: '',
            allowValue: '',
            themeId: val,
            deCode: '',
        })
    }

    /**
     * 添加 修改 子 元
     */
    handleFinish = (e) => {
        if (this.props.operation == 'modify') {
            //修改
            let param = e;
            param.id = this.props.info.id;
            param.deCode = param.deCode.trim();
            GetDataCodeModify(param).then(res => {
                if (res.code == 0) {
                    message.success('修改成功！')
                    this.props.interFace()
                }
            })
        } else if (this.props.operation == 'add') {
            // 添加
            let param = e;
            param.directoryId = this.props.info.id;
            param.codesetId = this.props.codesetId;
            param.deCode = param.deCode.trim();
            GetDataCodeSave(param).then(res => {
                if (res.code == 0) {
                    message.success('添加成功！')
                    this.props.interFace()
                    this.formRef.current.setFieldsValue({
                        name: '',
                        code: '',
                        nameIndex: '',
                        define: '',
                        type: [],
                        format: '',
                        allowValue: '',
                        themeId: [],
                        deCode: '',
                    })
                }
            })
        }

    }

    render() {
        return (
            <Fragment>
                <Form
                    {...layout}
                    name="nest-messages"
                    onFinish={this.handleFinish}
                    requiredMark={true}
                    className={'codeSetThemForm'}
                    ref={this.formRef}
                >
                    <Form.Item
                        label="数据元编码"
                        name='deCode'
                        rules={[
                            {
                                required: true,
                                message: '请选输入数据元编码',
                            },
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ]}
                    >
                        <AutoComplete
                            onSearch={val => this.handleSearch(val)}
                            style={{marginLeft: 8}}
                            placeholder="未查询到数据元编码则自动新增保存"
                            onChange={val => this.handleChange(val)}
                        >
                            {
                                this.state.deCodeSelectList && this.state.deCodeSelectList.length != 0 ?
                                    this.state.deCodeSelectList.map(v => (
                                        <Option
                                            value={v.code}
                                            key={v.id}
                                        >{v.code}({v.name})</Option>
                                    )) : ''
                            }
                        </AutoComplete>
                    </Form.Item>

                    <Form.Item
                        label="所属数据主题"
                        name='themeId'
                        rules={[
                            {
                                required: true,
                                message: '请选择所属数据主题',
                            }
                        ]}
                    >
                        <Select
                            allowClear
                            placeholder="请选择所属数据主题"
                            style={{marginLeft: 8}}
                            onChange={this.handleThemeIdChange}
                        >
                            {
                                this.state.themeIdSelectList && this.state.themeIdSelectList.length != 0 ?
                                    this.state.themeIdSelectList.map(v => (
                                        <Option
                                            value={v.id}
                                            key={v.id}
                                        >{v.name}</Option>
                                    )) : ''
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="数据编码"
                        name='code'
                        rules={[
                            {
                                required: true,
                                message: '请输入数据编码',
                            },
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ]}
                    >
                        <Input
                            style={{marginLeft: 8}}
                            placeholder="请输入数据编码"
                        />
                    </Form.Item>

                    <Form.Item
                        label="数据元名称"
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: '请输入数据元名称',
                            },
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ]}
                    >
                        <Input
                            style={{marginLeft: 8}}
                            placeholder="请输入数据元名称"
                        />
                    </Form.Item>

                    <Form.Item
                        label="数据元索引"
                        name='nameIndex'
                        rules={[
                            {
                                required: true,
                                message: '请输入数据元索引',
                            },
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ]}
                    >
                        <Input
                            style={{marginLeft: 8}}
                            placeholder="请输入数据元索引"
                        />
                    </Form.Item>

                    <Form.Item
                        label="数据元定义"
                        name='define'
                        rules={[
                            {
                                required: true,
                                message: '请输入数据元定义',
                            },
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ]}
                    >
                        <Input.TextArea
                            style={{marginLeft: 8, height: 108}}
                            placeholder="请输入数据元定义"
                        />
                    </Form.Item>

                    <Form.Item
                        label="数据元类型"
                        name='type'
                        rules={[
                            {
                                required: true,
                                message: '请选择数据元类型',
                            }
                        ]}
                    >
                        <Select
                            allowClear
                            placeholder="请选择数据元类型"
                            style={{marginLeft: 8}}
                        >
                            {
                                this.state.dataThemSelectList && this.state.dataThemSelectList.length != 0 ?
                                    this.state.dataThemSelectList.map(v => (
                                        <Option
                                            value={v.code}
                                            key={v.code}
                                        >{v.name}</Option>
                                    )) : ''
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="表示格式"
                        name='format'
                        rules={[
                            {
                                required: true,
                                message: '请输入表示格式',
                            },
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ]}
                    >
                        <Input
                            style={{marginLeft: 8}}
                            placeholder="请输入表示格式"
                        />
                    </Form.Item>

                    <Form.Item
                        label="容许值"
                        name='allowValue'
                        rules={[
                            {
                                required: true,
                                message: '请输入容许值',
                            },
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ]}
                    >
                        <Input
                            style={{marginLeft: 8}}
                            placeholder="请输入容许值"
                        />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{span: 24}}
                        style={{textAlign: 'right', marginRight: '-8px'}}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{backgroundColor: '#6287ec', borderColor: '#6287ec'}}
                        >
                            {this.props.operation == 'modify' ? '修 改' : '保 存'}
                        </Button>
                    </Form.Item>

                </Form>
            </Fragment>
        )
    }
}

export default withRouter(CodeSetThemTreeDetail);