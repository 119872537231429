import { requestGet, requestPost, requestPostRes } from "../EdcService";


export const getMenuEnum = ()=> {
  return requestGet('plat/resource/reasons')
}

// 
export const getMenuNodes = () => {
  return requestGet('plat/resource/reasonsList')
}

export type MenuPayload = {
  code: string
  name: string
  icon: string
  url: string
  parentCode: string
  appCode: string
  type: string
}
export const createMenu = (data: MenuPayload)=> {
  return requestPostRes('plat/resource/add', data)
}

export const updateMenu = (data: MenuPayload)=> {
  return requestPostRes('plat/resource/update', data)
}