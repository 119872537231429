/**
 * 项目报表详情
 */
import React, { useState, useEffect } from 'react';
import './index.less';
import { Button } from 'antd';
import { GetDetailsummary, GetDetailUser } from "../../../../../services/dct/DctService";
import DetailComponent from './detail'
import SummarizeComponent from './summarize'

function Details(props) {

  // 汇总列表
  const [summarizeData, setSummarizeData] = useState([]);
  // 明细列表
  const [detailData, setDetailData] = useState();
  // 翻页 
  const [isTurn, setIsTurn] = useState(false);

  /**获取汇总数据*/
  const getDetailsummaryData = () => {
    let { id } = props.location.state || props.match.params.id;
    let params = { id }
    GetDetailsummary(params).then(res => {
      if (res && res.code == 0) {
        setSummarizeData(res.data)
        sessionStorage.setItem('summarizeData', JSON.stringify(res.data))
      }
    })
  }
  /**获取用户明细数据*/
  const getDetailUserData = (page, pageSize) => {
    
    let { id } = props.location.state || props.match.params.id;
    let params = { 
      id,
      page: page || 1,
      pageSize: pageSize || 10
     }
    GetDetailUser(params).then(res => {
      if (res && res.code == 0) {
        setDetailData(res.data)
        sessionStorage.setItem('userDetailData', JSON.stringify(res.data))
      }
    })
  }

  useEffect(() => {
    getDetailsummaryData()
    getDetailUserData()
  }, [])

  // 返回
  const goBack = () => {
    props.history.push({
      pathname: '/dct/project/report',
      state: {}
    })
  }

  return (
    <div className='dctReport_detail'>

      <div className={'title-con'}>
        <div className={'title'}>{`${props.location.state.name}${props.location.state.title}`}</div>
        <Button type='primary' onClick={goBack}>返回</Button>
      </div>

      <div className='detailContent'>
        <SummarizeComponent data={summarizeData} />
        <DetailComponent 
          data={detailData} 
          onPageChange={getDetailUserData}
        />
      </div>

    </div>
  )
}
export default Details;
