import React, { useEffect, useRef, useState } from "react"
import Statistics, { StatisticsActions } from "./components/Statistics"

import indexStyl from './index.module.scss'
import Condition, { ApproveStatus, Params, StatusEnum } from "./components/Condition"
import { Button, Empty, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import DetailModal, { fetchTaskDistributeImageList, makeLast, Options } from "./components/DetailModal"

export type DataResult = {
  code: number;
  message?: string;
  data: {
    page: number;
    pageSize: number;
    total: number;
    rows: DataType[];
  }
}

export type DataType = {
  id: string;
  label: string;
  projectName: string;
  centerName: string;
  subjectCode: string;
  subjectName: string;
  createUser: string;
  tenantCode: string;
  collector: string;
  // 列表中是数组详情是字符串
  url: string[] | string;
  approveStatus: ApproveStatus;
  visitName: string;
  version: string;

  rejectReason: string | null;

  desensitization: 1 | 2;
  desensitizationPass: 1 | 2;

  formId: number;
  formCode: string;
  formName: string;

  completeUserCode: string;
  completeTime: string;
}

const ImageCheck = ()=> {

  const [visible, setVisible] = useState(false)
  
  const [pageSize] = useState(20)
  const [pageNum, setPageNum] = useState(1)
  
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const [dataSource, setDataSource] = useState<DataType[]>([])

  const statRef = useRef<StatisticsActions>(null)

  const conditionRef = useRef<Params>({
    subjectCode: "",
    projectName: "",
    approveStatus: 'RUNNING', 
    centerName: "",
    createUser: "",
    startTime: "",
    endTime: ""
  })

  const [options, setOptions] = useState({} as Options)

  // 计算详情需要的翻面参数
  const makeOptions = (index: number)=> {
    const last = makeLast(index, total, pageNum, pageSize)
    return {
      index,
      last,
      condition: conditionRef.current,
      pageNum,
      pageSize,
      total,
      records: dataSource
    }
  }

  //#region 表格列
  const columns: ColumnsType<DataType> = [
    // {
    //   width: 80,
    //   title: '#',
    //   align: 'right',
    //   key: 'linenumber',
    //   render: (_: any, _1: DataType, index: number)=> {
    //     return ((pageNum - 1) * pageSize) + index + 1
    //   }
    // },
    {
      width: 120,
      title: '所属项目',
      ellipsis: true,
      dataIndex: 'projectName',
    },
    {
      width: 120,
      title: '所属中心',
      dataIndex: 'centerName',
    },
    {
      width: 120,
      title: '访视',
      dataIndex: 'visitName',
    },
    {
      width: 120,
      title: '表单',
      dataIndex: 'formName',
    },
    {
      width: 120,
      title: '采集人',
      dataIndex: 'collector',
    },
    {
      width: 160,
      title: sessionStorage.getItem('targetName') || '受试者',
      dataIndex: 'subjectName',
      render: (value: string, record: DataType)=> {
        return `${value}(${record['subjectCode']})`
      }
    },
    {
      width: 160,
      title: '提交时间',
      dataIndex: 'createTime',
      render: (value: string)=> {
        return value ? value.replace('T', ' ') : '-'
      }
    },
    {
      width: 100,
      title: '审核状态',
      dataIndex: 'approveStatus',
      render: (value: ApproveStatus)=> {
        return StatusEnum[value]
      }
    },
    {
      width: 160,
      title: '审核时间',
      dataIndex: 'completeTime',
      render: (value: string)=> {
        return value ? value.replace('T', ' ') : '-'
      }
    },
    {
      width: 120,
      title: '审核人员',
      dataIndex: 'completeUserCode',
      render: (value: string)=> {
        return value ? value : '-'
      }
    },
    {
      width: 100,
      title: '操作',
      key: 'action',
      render: (_: any, record: DataType, index: number) => (
        <Button onClick={ ()=> {
          setVisible(true)
          setOptions(makeOptions(index))
        }} type="primary" size="small">查看详情</Button>
      ),
    },
  ];
  //#endregion

  const fetchData = async (params: Params, reload=false)=> {
    setLoading(true)
    const [a, b] = await fetchTaskDistributeImageList(reload ? 1 : pageNum, pageSize, params)
    if(reload) {
      setPageNum(1)
    }
    setLoading(false)
    setTotal(a)
    setDataSource(b)
  }

  const onSearch = (params: Params)=> {
    conditionRef.current = params
    fetchData(params, true)
  }

  // 当图片审核状态变更后及时刷新列表数据
  const refreshControl = (needReload=false)=> {
    setVisible(false)
    if(needReload) {
      statRef.current?.refresh()
      fetchData(conditionRef.current, true)
    }
  }

  useEffect(()=> {
    fetchData(conditionRef.current, true)
  }, [])

  return (
    <>
    <div className={indexStyl.wrapper}>
      <Statistics ref={statRef}/>
      <Condition onSearch={onSearch}/>
      <div className={indexStyl.tableWrapper}>
        <Table
          loading={loading}
          size="middle"
          rowKey="id"
          scroll={{y: window.innerHeight - 420}}
          columns={columns}
          dataSource={dataSource}
          locale={{emptyText: <Empty description="暂无数据"/>}}
          pagination={{
            onChange: (page: number)=> {
              setPageNum(page)
              fetchData(conditionRef.current)
            },
            total: total,
            current: pageNum,
            pageSize: pageSize,
            hideOnSinglePage: true,
            showSizeChanger: false,
            showQuickJumper: false,
            position: ["bottomRight"]}}/>
      </div>
    </div>
    {
      visible && (
        <DetailModal onClose={refreshControl} options={options}/>
      )
    }
    </>
  )
}

export default ImageCheck