import React, {useEffect, useRef, useState} from 'react';
import './planEdit.less'
import {connect} from 'react-redux';
import {
    Button,
    Form,
    Input,
    Select,
    Tabs,
    Space,
    Divider,
    Radio,
    Modal,
    message,
    Tree,
    Popconfirm,
    InputNumber,
    Checkbox
} from "antd";
import {
    GetPlanDetail,
    GetPlanModify,
    GetPlanModifyDetail,
    GetPlanVersionSave,
    GetPlanNodeSave,
    GetPlanNodeModify,
    GetPlanNodeDel,
    GetPlanNodeFormSave,
    GetPlanNodeFormModify,
    GetPlanNodeFormDel
} from '../../../../services/design/DesignService'
import {clearThreeTitle} from '../../../../util/menuModuleUtil';
import {
    createFromIconfontCN,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined
} from "@ant-design/icons";
import {GetDataCodesetDirectoryDel} from "../../../../services/config/ConfigService";
import styles from "../../../config/maskingRule/maskingRule.module.scss";
/*import {useForm} from 'antd/lib/form/Form';*/

const layout = {
    labelCol: {
        span: 3,
    },
    wrapperCol: {
        span: 21,
    },
};
const addVisibleFormLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
}
const tailLayout = {
    wrapperCol: {span: 24},
};
const {Option} = Select;
const {TextArea} = Input;
const {TabPane} = Tabs;
const {DirectoryTree} = Tree;
const FormItem = Form.Item;
const {confirm} = Modal;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2343612_65z6fg0engf.js'
    ],
});

function PlanEdit(props: any, ref: any) {

    // form 表单
    const editFormRef = useRef<any>(null);
    // 发布方案弹框 form 表单
    const publishModalFormRef = useRef<any>(null);
    // crf表单模板库 存为模板 form 表单
    const crfSaveFormRef = useRef<any>(null);
    // 访视节点详情 添加入排条件 form 表单
    const [visibleInfoForm_rowIn] = Form.useForm<any>();
    // 访视节点详情 添加激活条件 form 表单
    const [visibleInfoForm_active] = Form.useForm<any>();
    // 添加访视 form 表单
    const addVisitFormRef = useRef<any>(null);
    // tabs 数据
    const [tabsData, setTabsData] = useState<any>([
        {
            title: '入排标准', key: '1', id: 1, closable: false,
            formList: [
                {
                    name: '入排标准内容'
                },
                {
                    name: '知情同意书'
                }
            ]
        },
        {
            title: '基线访视', key: '2', id: 2, closable: false,
            formList: [
                {
                    name: '人口统计学信息'
                },
                {
                    name: '家族病史'
                },
                {
                    name: '个人疾病史'
                },
                {
                    name: '历史用药'
                }
            ]
        },
        {
            title: '初诊随访', key: '3', id: 3, closable: false,
            formList: [
                {
                    name: '检验检查记录'
                },
                {
                    name: '诊疗记录'
                },
                {
                    name: '用药记录'
                },
                {
                    name: 'AE事件'
                }
            ]
        },
        {
            title: '复诊随访', key: '4', id: 4, closable: false,
            formList: [
                {
                    name: '检验检查记录'
                },
                {
                    name: '诊疗记录'
                },
                {
                    name: '用药记录'
                },
                {
                    name: 'AE事件'
                }
            ]
        },
    ]);
    // tabs activeKey
    const [activeKey, setActiveKey] = useState<any>(tabsData && tabsData.length != 0 ? tabsData[0].key : null);
    // tabs 当前节点 信息数据data
    const [currentTabsData, setCurrentTabsData] = useState<any>(tabsData && tabsData.length != 0 ? tabsData[0] : null)
    // 选择 访视 节点的名字
    const [visitSelectName, setVisitSelectName] = useState<any>(tabsData && tabsData.length != 0 ? tabsData[0].title : '添加访视');
    // 添加 访视 新节点flag
    const [addVisibleFlag, setAddVisibleFlag] = useState<any>(tabsData && tabsData.length != 0 ? false : true);
    // 添加 访视 节点类型
    const [planVisibleTypeList, setPlanVisibleTypeList] = useState<any>([
        {code: '1', name: '可选访视节点'},
        {code: '2', name: '计划访视节点'}
    ]);
    // 访视 计划类型
    const [visiblePlanTypeList, setVisiblePlanTypeList] = useState<any>([
        {code: '1', name: '基线时间'},
        {code: '2', name: '上次访视时间'}
    ]);

    // 初诊访视 窗口期
    const [visibleWindowVal, setVisibleWindowVal] = useState<number>(3);
    // 初诊访视 窗口期 + =>true
    const [visibleWindowFlag, setVisibleWindowFlag] = useState<any>('+')
    // 初诊访视 计划日期
    const [visiblePlanVal, setVisiblePlanVal] = useState<number>(3);


    // 根据类型 弹框显示不同的内容
    const [modalTyp, setModalTyp] = useState<any>();
    // modal 显 隐
    const [visible, setVisible] = useState<any>(false);
    // modal 插入模板 显 隐
    const [insertTemplateFlag, setInsertTemplateFlag] = useState<any>(false);
    // crf表单模板库 模版下拉list
    const [tempLibraryList, setTempLibraryList] = useState<any>([
        {
            code: 'all',
            name: '全部模板库'
        },
        {
            code: 'a',
            name: 'AAA模板库'
        },
        {
            code: 'b',
            name: 'BBB模板库'
        },
        {
            code: 'c',
            name: 'CCC模板库'
        }
    ]);
    // crf表单模板库 模版下拉框默认值
    const [tempLibraryVal, setTempLibraryVal] = useState<any>('all');
    const inputEl = useRef<any>(null);
    // crf表单模板库 tree 数据集合
    const [tempTreeData, setTempTreeData] = useState<any>([
        {
            "id": 71,
            "key": "Dir-71",
            "name": "正式数据集",
            "parentId": 0,
            "directoryFlag": true,
            "children": [{
                "id": 72,
                "key": "Dir-72",
                "name": "数据集",
                "parentId": 71,
                "directoryFlag": true,
                "children": [{
                    "id": 31,
                    "key": "Code-31",
                    "name": "家族病史",
                    "parentId": 72,
                    "directoryFlag": false,
                    "children": null
                }, {
                    "id": 32,
                    "key": "Code-32",
                    "name": "既往肿瘤史名称",
                    "parentId": 72,
                    "directoryFlag": false,
                    "children": null
                }, {
                    "id": 33,
                    "key": "Code-33",
                    "name": "既往肿瘤史",
                    "parentId": 72,
                    "directoryFlag": false,
                    "children": null
                }]
            }]
        }]);
    // tree 树展开
    const [tempExpandedKeys, setTempExpandedKeys] = useState<any>(null);
    // tree 选中节点
    const [tempTreeSelectedKeys, setTempTreeSelectedKeys] = useState<any>(null);
    // tree 选中节点 信息
    const [tempTreeSelectedInfo, setTempTreeSelectedInfo] = useState<any>(null);

    // 存为模版
    const [insertOrSaveTempFlag, setInsertOrSaveTempFlag] = useState<any>(false)

    const [dictionariesProjectsValues, setDictionariesProjectsValues] = useState<Array<any>>([]);

    useEffect(() => {
        getPlanDetail()
        return componentWillUnmount
    }, [])

    useEffect(() => {
        if (insertTemplateFlag && insertOrSaveTempFlag) {
            // crf弹框 且 插入模板 的情况下
            filterRuleData(tempTreeData)
        }
        return componentWillUnmount
    }, [insertTemplateFlag])

    /**
     * 组件销毁 处理事务
     */
    const componentWillUnmount = () => {
        clearThreeTitle()
    }

    /**
     * 方案 详情
     */
    const getPlanDetail = () => {
        /*GetPlanDetail(props.location.state.id).then(res => {
        })*/
        GetPlanModifyDetail(props.location.state.id).then((res: any) => {
            if (res) {
                if (editFormRef.current) {
                    let {name, code, applyPatientCount, applyProjectCount, version, planState, planType} = res.header
                    editFormRef.current.setFieldsValue({
                        name,
                        code,
                        applyPatientCount,
                        applyProjectCount,
                        version,
                        planState,
                        planType
                    })
                }
            }
        })
    }

    /**
     * 表单 修改
     */
    const handleEditFinish = () => {
        if (editFormRef.current) {
            let params = editFormRef.current.getFieldsValue(['name', 'code', 'applyPatientCount', 'applyProjectCount', 'version', 'planState', 'planType'])
            params.name = params.name.trim();
            /*params.code = params.code.trim();*/
            params.applyPatientCount = params.applyPatientCount.trim();
            params.applyProjectCount = params.applyProjectCount.trim();
            params.version = params.version.trim();
            params.id = props.location.state.id;
            GetPlanModify(params).then((res: any) => {
                if (res) {
                    message.success('修改成功！')
                    props.history.push('/design/plan')
                }
            })
        }
    }

    /**
     * 停用
     */
    const handleStopUsing = () => {
        handleBack()
        message.success('停用成功')
    }

    /**
     * tabs change
     */
    const handleTabsChange = (activeKey: any) => {
        setActiveKey(activeKey)
        setAddVisibleFlag(false)
    }

    useEffect(() => {
        if (tabsData && tabsData.length != 0) {
            let data = tabsData.filter((item: any) => {
                return item.key == activeKey
            })
            if (data.length != 0) {
                setVisitSelectName(data[0].title)
                setCurrentTabsData(data[0])
                console.log(activeKey)
                switch (activeKey) {
                    case '1':
                        // 入排标准 入排条件
                        console.log(visibleInfoForm_rowIn)
                        if (visibleInfoForm_rowIn) {
                            let dictionariesProjectDtos: any[] = [];
                            dictionariesProjectDtos.push({
                                name: "1",
                                age: '1',
                                age1: '1',
                                age2: '1',
                            })
                            visibleInfoForm_rowIn.setFieldsValue({'dictionariesProjects': dictionariesProjectDtos})
                        }

                        break
                    case '4':
                        // 复诊随访 激活条件
                        if (visibleInfoForm_active) {
                            let activeDtos: any[] = [];
                            activeDtos.push({
                                name: "1",
                                age: '1',
                                age1: '1',
                                age2: '1',
                                age3: '1',
                            })
                            visibleInfoForm_active.setFieldsValue({'active': activeDtos})
                        }
                        break
                    default:
                        break
                }
            }
        }
        console.log(activeKey)
    }, [activeKey])

    /**
     * tabs 标签页 编辑
     */
    const handleEditTabs = (targetKey: any, action: any) => {
        handleRemove(targetKey)
    }

    /**
     * tabs 标签页 删除
     */
    const handleRemove = (targetKey: any) => {
        confirm({
            title: '确定删除此访视节点吗？',
            icon: <ExclamationCircleOutlined/>,
            okText: '确认',
            cancelText: '取消',
            content: '',
            centered: true,
            onOk: () => {
                let delData = tabsData.filter((pane: any) => pane.key == targetKey)
                if (delData.length != 0) {
                    GetPlanNodeDel(delData[0].id).then((res: any) => {
                        if (res) {
                            if (tabsData.length >= 2) {
                                message.success('删除成功！')
                                let lastIndex: any = 0;
                                tabsData.forEach((pane: any, i: any) => {
                                    if (pane.key === targetKey) {
                                        lastIndex = i - 1;
                                    }
                                });
                                let panes = tabsData.filter((pane: any) => pane.key !== targetKey);
                                let filterActiveKey = activeKey;
                                if (panes.length && filterActiveKey === targetKey) {
                                    if (lastIndex >= 0) {
                                        filterActiveKey = panes[lastIndex].key;
                                    } else {
                                        filterActiveKey = panes[0].key;
                                    }
                                }
                                let selectData = panes.filter((pane: any) => pane.key == filterActiveKey)
                                setTabsData(panes)
                                setActiveKey(filterActiveKey)
                                setVisitSelectName(selectData[0].title)
                                setCurrentTabsData(selectData[0])
                            }

                        }
                    })
                }
            },
            onCancel: () => {
                closeModal();
            },
        });
    }

    /**
     * tabs 添加 访视
     */
    const handleTabsAdd = () => {
        /*setActiveKey(null)
        setnewTabIndex(newTabIndex++)
        let activeKey = `newTab${newTabIndex}`;
        tabsData.push({title: 'New Tab', content: 'New Tab Pane', key: activeKey});
        setTabsData(tabsData)
        console.log(newTabIndex)*/
        setVisitSelectName('添加访视')
        setActiveKey(null)
        setTabsData(tabsData)
        setAddVisibleFlag(true)
    };

    /**
     * tabs 访视节点 编辑信息
     */
    const handleVisibleInfoForm = () => {
        return (
            <React.Fragment>
                <div className={'visibleItem'}>
                    <div className={'label'}>
                        <div className={'sign'}>*</div>
                        节点类型：
                    </div>
                    <div className={'visibleItemForm'}>
                        {
                            activeKey && (activeKey == '1' || activeKey == '2') ? '入排节点' :
                                <Select
                                    allowClear
                                    placeholder="请选择节点类型"
                                    value={`${activeKey == 3 ? '2' : '1'}`}
                                    style={{width: '100%'}}
                                >
                                    {
                                        planVisibleTypeList && planVisibleTypeList.length != 0 ?
                                            planVisibleTypeList.map((i: any) => (
                                                <Option value={i.code}
                                                        key={i.code}>{i.name}</Option>
                                            )) : ''
                                    }
                                </Select>
                        }
                    </div>
                </div>
                <div
                    className={'visibleItem'}
                    style={{display: `${activeKey && activeKey == '1' ? 'flex' : 'none'}`}}
                >
                    <div className={'label'}>
                        <div className={'sign'}>*</div>
                        是否启用：
                    </div>
                    <div className={'visibleItemForm'}>
                        <Radio.Group
                            defaultValue={1}
                        >
                            <Radio value={1}>启用</Radio>
                            <Radio value={2}>禁用</Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className={'visibleItem'}>
                    <div className={'label'}>
                        <div className={'sign'}>*</div>
                        节点名称：
                    </div>
                    <div className={'visibleItemForm'}>
                        <Input
                            style={{borderColor: '#EEEFF7'}}
                            placeholder="请输入节点名称"
                            value={currentTabsData ? currentTabsData.title : null}
                        />
                    </div>
                </div>
                {
                    handleVisibleInfoFormAppend()
                }
                <div className={'visibleItemBtns'}>
                    <Button
                        className={'searchBtn'}
                        style={{
                            borderRadius: '2px',
                            display: `${activeKey && (activeKey == 1 || activeKey == 2) ? 'none' : 'inline'}`
                        }}
                        onClick={() => handleRemove(activeKey)}
                    >
                        删除
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={handleVisibleEditFinish}
                        style={{borderRadius: '2px'}}
                    >
                        修改
                    </Button>
                </div>
            </React.Fragment>
        )
    }

    /**
     * tabs 访视节点 编辑信息 根据选中的访视节点 展示不同信息
     */
    const handleVisibleInfoFormAppend = () => {
        console.log(activeKey)
        if (activeKey) {
            switch (activeKey) {
                case '1':
                    return (
                        <React.Fragment>
                            <div className={'visibleItem'}>
                                <div className={'label'}>
                                    <div className={'sign'}>*</div>
                                    入排标准：
                                </div>
                                <div className={'visibleItemForm'}>
                                    <Radio.Group
                                        defaultValue={1}
                                    >
                                        <Radio value={1}>全部满足</Radio>
                                        <Radio value={2}>单项满足</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            <div className={'visibleItem'}>
                                <div className={'label'}></div>
                                <div className={'visibleItemForm'}>
                                    <Form
                                        form={visibleInfoForm_rowIn}
                                    >
                                        <Form.List
                                            name="dictionariesProjects"
                                            /*initialValue={dictionariesProjectsValues}*/
                                        >
                                            {(fields, {add, remove}) => {
                                                return <>
                                                    {
                                                        fields.map((field: any) => (
                                                            <Space
                                                                key={field.key}
                                                                style={{
                                                                    display: 'flex',
                                                                }}
                                                                align="baseline"

                                                            >
                                                                {
                                                                    console.log(field)
                                                                }
                                                                {
                                                                    fields.length > 1 ? (
                                                                        <MinusCircleOutlined
                                                                            className="dynamic-delete-button"
                                                                            onClick={() => remove(field.name)}
                                                                        />
                                                                    ) : <div style={{width: 16}}></div>
                                                                }
                                                                <Form.Item
                                                                    name={[field.name, 'name']}
                                                                    fieldKey={[field.fieldKey, 'name']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: '请选择名称'
                                                                        }
                                                                    ]}
                                                                    initialValue={'1'}
                                                                >
                                                                    <Select
                                                                        style={{
                                                                            borderColor: '#EEEFF7',
                                                                            marginRight: 10
                                                                        }}
                                                                    >
                                                                        <Option value={'1'}>入排标准内容
                                                                        </Option>
                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[field.name, 'age']}
                                                                    fieldKey={[field.fieldKey, 'age']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: '请选择年龄'
                                                                        }
                                                                    ]}
                                                                    initialValue={'1'}
                                                                >
                                                                    <Select
                                                                        style={{
                                                                            borderColor: '#EEEFF7',
                                                                            marginRight: 10
                                                                        }}
                                                                    >
                                                                        <Option value={'1'}>年龄
                                                                        </Option>
                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[field.name, 'age1']}
                                                                    fieldKey={[field.fieldKey, 'age1']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: '请选择等于'
                                                                        }
                                                                    ]}
                                                                    initialValue={'1'}
                                                                >
                                                                    <Select
                                                                        style={{
                                                                            borderColor: '#EEEFF7',
                                                                            marginRight: 10
                                                                        }}
                                                                    >
                                                                        <Option value={'1'}>等于
                                                                        </Option>
                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[field.name, 'age2']}
                                                                    fieldKey={[field.fieldKey, 'age2']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: '请选择'
                                                                        }
                                                                    ]}
                                                                    initialValue={'1'}
                                                                >
                                                                    <Select
                                                                        style={{
                                                                            borderColor: '#EEEFF7',
                                                                            marginRight: 10
                                                                        }}
                                                                    >
                                                                        <Option value={'1'}>大于18
                                                                        </Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </Space>
                                                        ))
                                                    }
                                                    <Form.Item>
                                                        <PlusCircleOutlined
                                                            style={{marginRight: 8}}
                                                            onClick={() => add()}
                                                        />
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                        >
                                                            添加入排条件
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            }}
                                        </Form.List>
                                    </Form>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                    break;
                case '3':
                    return (
                        <React.Fragment>
                            <div className={'visibleItem'}>
                                <div className={'label'}>
                                    <div className={'sign'}>*</div>
                                    计划类型：
                                </div>
                                <div className={'visibleItemForm'}>
                                    {
                                        <Select
                                            allowClear
                                            placeholder="请选择计划类型"
                                            defaultValue={'1'}
                                            style={{width: '100%'}}
                                        >
                                            {
                                                visiblePlanTypeList && visiblePlanTypeList.length != 0 ?
                                                    visiblePlanTypeList.map((i: any) => (
                                                        <Option value={i.code}
                                                                key={i.code}>{i.name}</Option>
                                                    )) : ''
                                            }
                                        </Select>
                                    }
                                </div>
                            </div>
                            <div className={'visibleItem'}>
                                <div className={'label'}>
                                    <div className={'sign'}>*</div>
                                    计划日期：
                                </div>
                                <div className={'visibleItemForm'}>
                                    <div className={'add'}
                                         onClick={handleVisiblePlan}
                                    >+
                                    </div>
                                    {
                                        <Input type='number'
                                            /*addonBefore="+"*/
                                               addonAfter="天" min={1} max={10}
                                               value={visiblePlanVal}/>
                                        /*<InputNumber min={1} max={10} defaultValue={3}/>*/
                                    }
                                    {/*<div>天</div>*/}
                                </div>
                            </div>
                            <div className={'visibleItem'}>
                                <div className={'label'}>
                                    <div className={'sign'}>*</div>
                                    窗口期：
                                </div>
                                <div className={'visibleItemForm window'}>
                                    {
                                        <Radio.Group
                                            value={visibleWindowFlag}
                                            buttonStyle="solid"
                                            onChange={handleVisibleWindowFlag}
                                        >
                                            <Radio.Button value="+">+</Radio.Button>
                                            <Radio.Button value="-">-</Radio.Button>
                                        </Radio.Group>
                                    }
                                    <div
                                        className={'add'}
                                        onClick={handleVisibleWindow}
                                    >
                                        +/-
                                    </div>
                                    {
                                        <Input type='number'
                                            /*addonBefore="士"*/
                                               addonAfter="天" min={1} max={10}
                                               value={visibleWindowVal}/>
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                    )
                    break;
                case '4':
                    return (
                        <React.Fragment>
                            <div className={'visibleItem'}>
                                <div className={'label'}>
                                    <div className={'sign'}>*</div>
                                    激活方式：
                                </div>
                                <div className={'visibleItemForm'}>
                                    <Checkbox.Group
                                        options={
                                            [
                                                {label: '逻辑激活', value: 'l'},
                                                {label: '手动天加', value: 's'},
                                            ]
                                        }
                                        defaultValue={['l']}
                                    />
                                </div>
                            </div>
                            <div className={'visibleItem'}>
                                <div className={'label'}>
                                    <div className={'sign'}>*</div>
                                    激活条件：
                                </div>
                                <div className={'visibleItemForm'}>
                                    <Radio.Group
                                        defaultValue={1}
                                    >
                                        <Radio value={1}>全部满足</Radio>
                                        <Radio value={2}>单项满足</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            <div className={'visibleItem'}>
                                <div className={'label'}></div>
                                <div className={'visibleItemForm'}>
                                    <Form
                                        form={visibleInfoForm_active}
                                    >
                                        <Form.List
                                            name="active"
                                        >
                                            {(fields, {add, remove}) => (
                                                <>
                                                    {
                                                        fields.map((field: any, index) => (
                                                            <Space
                                                                key={field.key}
                                                                style={{
                                                                    display: 'flex',
                                                                }}
                                                                align="baseline"
                                                            >
                                                                {fields.length > 1 ? (
                                                                    <MinusCircleOutlined
                                                                        className="dynamic-delete-button"
                                                                        onClick={() => remove(field.name)}
                                                                    />
                                                                ) : <div style={{width: 16}}></div>}
                                                                <Form.Item
                                                                    name={[field.name, 'name']}
                                                                    fieldKey={[field.fieldKey, 'name']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: '请选择名称'
                                                                        }
                                                                    ]}
                                                                    initialValue={'1'}
                                                                >
                                                                    <Select
                                                                        style={{
                                                                            borderColor: '#EEEFF7',
                                                                            marginRight: 10
                                                                        }}
                                                                    >
                                                                        <Option value={'1'}>初诊随访
                                                                        </Option>
                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[field.name, 'age']}
                                                                    fieldKey={[field.fieldKey, 'age']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: '请选择年龄'
                                                                        }
                                                                    ]}
                                                                    initialValue={'1'}
                                                                >
                                                                    <Select
                                                                        style={{
                                                                            borderColor: '#EEEFF7',
                                                                            marginRight: 10
                                                                        }}
                                                                    >
                                                                        <Option value={'1'}>复诊计划
                                                                        </Option>
                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[field.name, 'age1']}
                                                                    fieldKey={[field.fieldKey, 'age1']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: '请选择等于'
                                                                        }
                                                                    ]}
                                                                    initialValue={'1'}
                                                                >
                                                                    <Select
                                                                        style={{
                                                                            borderColor: '#EEEFF7',
                                                                            marginRight: 10
                                                                        }}
                                                                    >
                                                                        <Option value={'1'}>是否复诊
                                                                        </Option>
                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[field.name, 'age2']}
                                                                    fieldKey={[field.fieldKey, 'age2']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: '请选择'
                                                                        }
                                                                    ]}
                                                                    initialValue={'1'}
                                                                >
                                                                    <Select
                                                                        style={{
                                                                            borderColor: '#EEEFF7',
                                                                            marginRight: 10
                                                                        }}
                                                                    >
                                                                        <Option value={'1'}>等于
                                                                        </Option>
                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[field.name, 'age3']}
                                                                    fieldKey={[field.fieldKey, 'age3']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: '请选择'
                                                                        }
                                                                    ]}
                                                                    initialValue={'1'}
                                                                >
                                                                    <Select
                                                                        style={{
                                                                            borderColor: '#EEEFF7',
                                                                            marginRight: 10
                                                                        }}
                                                                    >
                                                                        <Option value={'1'}>是
                                                                        </Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </Space>
                                                        ))
                                                    }
                                                    <Form.Item>
                                                        <PlusCircleOutlined
                                                            style={{marginRight: 8}}
                                                            onClick={() => add()}
                                                        />
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                        >
                                                            添加激活条件
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Form>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * tabs 初诊访视 计划日期 加 天数
     */
    const handleVisiblePlan = () => {
        let a: any = visiblePlanVal;
        a = Number(visiblePlanVal) + 1;
        setVisiblePlanVal(a)
    }

    /**
     * tabs 初诊访视 窗口期 加 减 天数
     */
    const handleVisibleWindow = () => {
        let a: any = visibleWindowVal;
        if (visibleWindowFlag == '+') {
            a = Number(visibleWindowVal) + 1;
        } else {
            if (a > 1) {
                a = Number(visibleWindowVal) - 1;
            }
        }
        setVisibleWindowVal(a)
    }

    /**
     * tabs 初诊访视 窗口期 加 减 flag
     */
    const handleVisibleWindowFlag = () => {
        if (visibleWindowFlag == '+') {
            setVisibleWindowFlag('-')
        } else {
            setVisibleWindowFlag('+')
        }
    }

    /**
     * tabs 访视节点 编辑btn
     */
    const handleVisibleEditFinish = () => {
        message.success('修改成功！')
    }

    /**
     * 发版
     */
    const handlePublish = () => {
        setVisible(true)
        setModalTyp('Publish')
    }

    /**
     * 发版 确认
     */
    const handlePublishFinish = (values: any) => {
        values.planId = props.location.state.id
        GetPlanVersionSave(values).then((res: any) => {
            if (res) {
                message.success('版本发布成功')
                setVisible(false)
            }
        })
    }

    /**
     * modal 关闭
     */
    const closeModal = () => {
        let modalEle = document.getElementById('modal');
        if (modalEle) {
            modalEle.style.display = 'none';
        }
    }

    /**
     * 插入模板 modal
     */
    const insertTemplate = () => {
        setVisible(false)
        setInsertTemplateFlag(true)
        setInsertOrSaveTempFlag(true)
    }

    /**
     * 插入模板 模板下拉框
     * @param key
     */
    const handleTempLibrarySelect = (c: any) => {
        setTempLibraryVal(c)
    }
    const selectAfter = (
        <Select
            defaultValue="all"
            className="select-after"
            onSelect={handleTempLibrarySelect}
        >
            {
                tempLibraryList && tempLibraryList.length != 0 ?
                    tempLibraryList.map((i: any) => (
                        <Option
                            value={i.code}
                            key={i.code}
                        >{i.name}</Option>
                    )) : ''
            }
        </Select>
    );

    /**
     * 插入模板 模板下拉框 查找
     * @param key
     */
    const handleTempLibrarySearch = () => {
        console.log('查找')
    }

    /**
     * 插入模板 tree 映射
     * @param data
     */
    const filterRuleData = (data: any) => {
        let expandedKeys: any[] = [];
        let filterTreeDataFun = (treeData: any) => {
            for (let item of treeData) {
                if (item.children && item.children.length !== 0) {
                    if (item.parentId === 0) {
                        expandedKeys.push(item.key)
                        item.icon = <IconFont type="icondatabase"></IconFont>
                    }
                    item.title = item.name;
                    item.isLeaf = item.directoryFlag ? false : true;
                    filterTreeDataFun(item.children)
                } else {
                    item.title = item.name;
                    item.isLeaf = item.directoryFlag ? false : true;
                }
            }
        }
        //设置treeData
        filterTreeDataFun(data);
        //设置默认点中的key
        setDefaultKeys(data);
    }

    /**
     * 插入模板 tree 设置默认展开&&默认选中的节点
     * @param data
     */
    const setDefaultKeys = (data: any) => {
        let expandedKeys: any[] = [];
        let selectedKeys: any[] = [];
        let selectedNode;
        if (data && data.length > 0) {
            expandedKeys.push(data[0].key);
            if (data[0].children && data[0].children.length > 0) {
                let judgeIsOut = false;
                let filterData = (dataItem: any) => {
                    for (let item of dataItem) {
                        if (item.children && item.children.length > 0) {
                            if (item.isLeaf) {
                                selectedKeys = [item.key];
                                selectedNode = item;
                                judgeIsOut = true;
                            } else {
                                expandedKeys.push(item.key);
                                filterData(item.children);
                            }
                        } else {
                            if (item.isLeaf) {
                                selectedKeys = [item.key];
                                selectedNode = item;
                                judgeIsOut = true;
                            }
                        }
                        if (judgeIsOut) {
                            return;
                        }
                    }
                }
                filterData(data[0].children);
                setTempExpandedKeys(expandedKeys);
                setTempTreeSelectedKeys(selectedKeys);
                setTempTreeSelectedInfo(selectedNode);
            } else {
                setTempExpandedKeys([data[0].key]);
                setTempTreeSelectedKeys([data[0].key]);
                setTempTreeSelectedInfo(data[0]);
            }
        }
    }

    /**
     * 插入模板 tree 展开节点
     */
    const handleTempTreeExpand = (keys: any) => {
        setTempExpandedKeys([...keys])
    };

    /**
     * 插入模板 tree click
     */
    const handleTempTreeSelect = (selectedKeys: any, info: any) => {
        setTempTreeSelectedKeys(selectedKeys)
        setTempTreeSelectedInfo(info.selectedNodes[0])
    }

    /**
     * 插入模板 插入 click
     */
    const handleCrfInsertModalFinish = () => {
        setInsertTemplateFlag(false)
        setVisible(true)
    }

    /**
     * 存为模板 click
     */
    const handleSaveTemplate = () => {
        setVisible(false)
        setInsertTemplateFlag(true)
        setInsertOrSaveTempFlag(false)
    }

    /**
     * 存为模板 确认
     */
    const handleSaveTempModalFinish = () => {
        setInsertTemplateFlag(false)
        setVisible(true)
    }

    /**
     * 访视节点 关联表单 添加表单 保存
     */
    const handleSaveForm = () => {
        setModalTyp(null);
        setVisible(false)
    }

    /**
     * 节点 关联表单 添加
     */
    const handlePlanNodeFormSave = (data: any) => {
        setModalTyp('FormAdd');
        setVisible(true)
        console.log('当前节点数据', data)
        /*let values = {
            nodeId: activeKey
        }
        GetPlanNodeFormSave(values).then((res: any) => {
            if (res.code == 0) {
                message.success('添加成功！')
            }
        })*/
    }

    /**
     * 表单 节点 添加
     */
    const handleAddVisibleFinish = (val: any) => {
        val.planId = props.location.state.id;
        GetPlanNodeSave(val).then((res: any) => {
            if (res) {
                message.success('添加成功！')
                setTabsData(tabsData)
                setActiveKey(tabsData[0].key)
                setAddVisibleFlag(false)
                setVisitSelectName(tabsData[0].title)
            }
        })
    }

    /**
     * 表单 节点 取消
     */
    const handleCancelVisible = () => {
        setTabsData(tabsData)
        setActiveKey(tabsData[0].key)
        setAddVisibleFlag(false)
        setVisitSelectName(tabsData[0].title)
    }

    /**
     * 返回首页
     */
    const handleBack = () => {
        props.history.push('/design/plan')
    }

    /**
     * icon 图标
     */
    const formatIcon = (iconName: string) => {
        return iconName ? (<IconFont type={iconName}/>) : undefined;
    }

    return (
        <div className={'planEdit'}>
            <div className={'planEditCon'}>
                {/*修改详情*/}
                <div className={'planEditConTop'}>
                    <div className={'planEdit_title'}>
                        修改方案
                    </div>
                    <div className={'planEdit_con'}>
                        <Form
                            layout={'inline'}
                            onFinish={handleEditFinish}
                            requiredMark={true}
                            ref={editFormRef}
                            className={'planForm'}
                        >
                            <Form.Item
                                label="方案名称"
                                name='name'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入方案名称',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input
                                    style={{borderColor: '#EEEFF7'}}
                                    placeholder="请输入方案名称"
                                />
                            </Form.Item>

                            <Form.Item
                                label="类型"
                                name='planType'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择类型',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='请选择类型'
                                    style={{borderColor: '#EEEFF7'}}
                                >
                                    {
                                        props.typeList && props.typeList.length != 0 ?
                                            props.typeList.map((v: any) => (
                                                <Option value={v.get('code')}
                                                        key={v.get('code')}>{v.get('name')}</Option>
                                            )) : ''
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="应用患者数"
                                name='applyPatientCount'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入应用患者数',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input
                                    style={{borderColor: '#EEEFF7'}}
                                    placeholder="请输入应用患者数"
                                />
                            </Form.Item>

                            <Form.Item
                                label="版本号"
                                name='version'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入版本号',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                                style={{paddingRight: 0}}
                            >
                                <Input
                                    style={{borderColor: '#EEEFF7'}}
                                    placeholder="请输入版本号"
                                />
                            </Form.Item>

                            <Form.Item
                                label="方案编码"
                                name='code'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入方案编码',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input
                                    style={{borderColor: '#EEEFF7'}}
                                    placeholder="请输入方案编码"
                                />
                            </Form.Item>

                            <Form.Item
                                label="状态"
                                name='planState'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择状态',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='请选择状态'
                                    style={{borderColor: '#EEEFF7'}}
                                >
                                    {
                                        props.statusList && props.statusList.length != 0 ?
                                            props.statusList.map((v: any) => (
                                                <Option value={v.get('code')}
                                                        key={v.get('code')}>{v.get('name')}</Option>
                                            )) : ''
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="应用项目数"
                                name='applyProjectCount'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入应用项目数',
                                    }
                                ]}
                            >
                                <Input
                                    style={{borderColor: '#EEEFF7'}}
                                    placeholder="请输入应用项目数"
                                />
                            </Form.Item>
                            <Form.Item
                                label="方案介绍"
                                name='applyProjectCount'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入方案介绍',
                                    }
                                ]}
                                style={{width: '100%'}}
                            >
                                <TextArea
                                    style={{
                                        borderColor: '#EEEFF7',
                                    }}
                                    rows={2}
                                    placeholder="请输入方案介绍"
                                >

                                </TextArea>
                            </Form.Item>

                            <Form.Item
                                style={{width: '100%', textAlign: 'right', marginBottom: 0, paddingRight: 0}}
                                wrapperCol={{span: 24}}
                            >
                                <Button
                                    className={'searchBtn'}
                                    onClick={handleBack}
                                >
                                    返回首页
                                </Button>
                                <Button
                                    className={'searchBtn'}
                                    style={{borderRadius: '2px'}}
                                    onClick={handleStopUsing}
                                >
                                    停用
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    /*onClick={handleFinish}*/
                                    style={{borderRadius: '2px'}}
                                >
                                    修改
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                {/*访视内容*/}
                <div className={'planEditConBottom'}>
                    <div className={'tabsGroup'}>
                        <div className={'btnGroup'}>
                            <Button onClick={handleTabsAdd}>添加访视</Button>
                            <Button
                                onClick={handlePublish}
                                type="primary"
                            >
                                发版
                            </Button>
                        </div>
                        <Tabs
                            hideAdd
                            onChange={handleTabsChange}
                            activeKey={activeKey}
                            /*type="editable-card"
                            onEdit={handleEditTabs}*/
                        >
                            {
                                tabsData && tabsData.length != 0 ?
                                    tabsData.map((pane: any) => (
                                        <TabPane
                                            tab={pane.title}
                                            key={pane.key}
                                            closable={pane.closable}
                                        >
                                            <div className={'tabPaneCon'}>
                                                <div className={'TabPaneLeft'}>
                                                    <div className={'left_title'}>
                                                        {visitSelectName}关联表单
                                                    </div>
                                                    <div className={'left_con'}>
                                                        <ul>
                                                            {
                                                                pane.formList && pane.formList.length != 0 ?
                                                                    pane.formList.map((u: any) => (
                                                                        <li>
                                                                            <span className={'title'}>{u.name}</span>
                                                                            <div className={'operation'}>
                                                                                <Space
                                                                                    split={<Divider type="vertical"/>}>
                                                                                    <div>{formatIcon('iconkongzhiqi')}逻辑控制</div>
                                                                                    <div>{formatIcon('iconDataActionUpdate')}编辑</div>
                                                                                    <div>{formatIcon('iconshanchu')}删除</div>
                                                                                </Space>
                                                                            </div>
                                                                        </li>
                                                                    )) : ''
                                                            }
                                                            <li onClick={() => {
                                                                handlePlanNodeFormSave(pane)
                                                            }}>
                                                                {formatIcon('icontianjia')}
                                                                添加表单
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className={'TabPaneRight'}>
                                                    <div className={'right_title'}>
                                                        {visitSelectName}信息
                                                    </div>
                                                    <div className={'right_con'}>
                                                        {
                                                            handleVisibleInfoForm()
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                    )) : ''
                            }
                        </Tabs>
                        {/*添加访视节点*/}
                        <div className={'addVisible'}>
                            {
                                addVisibleFlag ?
                                    <div className={'tabPaneCon'}>
                                        <div className={'TabPaneLeft'}>
                                            <div className={'left_title'}>
                                                {visitSelectName}关联表单
                                            </div>
                                            <div className={'left_con'}>
                                                <ul>
                                                    <li onClick={() => {
                                                        handlePlanNodeFormSave(null)
                                                    }}>
                                                        {formatIcon('icontianjia')}
                                                        添加表单
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={'TabPaneRight'}>
                                            <div className={'right_title'}>
                                                {visitSelectName}信息
                                            </div>
                                            <div className={'right_con'}>
                                                <Form
                                                    {...addVisibleFormLayout}
                                                    className={'planSearchItem'}
                                                    autoComplete="off"
                                                    onFinish={handleAddVisibleFinish}
                                                    ref={addVisitFormRef}
                                                    requiredMark
                                                >
                                                    <FormItem
                                                        name="planVisibleType"
                                                        label="节点类型"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '请输入节点名称',
                                                            }
                                                        ]}
                                                        initialValue={'1'}
                                                    >
                                                        <Select
                                                            allowClear
                                                            placeholder="请选择节点类型"
                                                            style={{marginLeft: 8}}
                                                        >
                                                            {
                                                                planVisibleTypeList && planVisibleTypeList.length != 0 ?
                                                                    planVisibleTypeList.map((i: any) => (
                                                                        <Option value={i.code}
                                                                                key={i.code}>{i.name}</Option>
                                                                    )) : ''
                                                            }
                                                        </Select>
                                                    </FormItem>

                                                    <FormItem
                                                        name="orderNum"
                                                        label="节点顺序"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '请输入节点顺序',
                                                            }
                                                        ]}
                                                    >
                                                        <Input
                                                            style={{marginLeft: 8, borderColor: '#EEEFF7'}}
                                                            placeholder="请输入节点顺序"
                                                        />
                                                    </FormItem>

                                                    <FormItem
                                                        name="name"
                                                        label="节点名称"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '请输入节点名称',
                                                            },
                                                            {
                                                                whitespace: true,
                                                                message: '不能输入空格',
                                                            }
                                                        ]}
                                                    >
                                                        <Input
                                                            style={{marginLeft: 8, borderColor: '#EEEFF7'}}
                                                            placeholder="请输入节点名称"
                                                        />
                                                    </FormItem>

                                                    <FormItem
                                                        style={{
                                                            textAlign: 'right',
                                                            marginBottom: 0,
                                                            paddingRight: 0,
                                                            marginRight: '-8px'
                                                        }}
                                                        wrapperCol={{span: 24}}
                                                    >
                                                        <Button
                                                            className={'searchBtn'}
                                                            onClick={handleCancelVisible}
                                                            style={{borderRadius: '2px', marginRight: 12}}
                                                        >
                                                            取消
                                                        </Button>
                                                        <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            style={{borderRadius: '2px'}}
                                                        >
                                                            保存
                                                        </Button>
                                                    </FormItem>
                                                </Form>
                                            </div>
                                        </div>
                                    </div> : ''
                            }
                        </div>
                    </div>
                </div>
                {/*弹框*/}
                <Modal
                    className={'designPlanModal'}
                    visible={visible}
                    title={`${modalTyp == 'Publish' ? '发布方案' : '人口统计学信息'}`}
                    onCancel={() => {
                        setVisible(false)
                    }}
                    width={840}
                    centered
                    /*destroyOnClose*/
                    footer={[]}
                >
                    {/*发布方案*/}
                    <div style={{display: `${modalTyp == 'Publish' ? 'block' : 'none'}`}}>
                        <Form
                            {...layout}
                            name="nest-messages"
                            requiredMark={true}
                            ref={publishModalFormRef}
                            onFinish={handlePublishFinish}
                        >
                            <Form.Item
                                label="版本号"
                                name='version'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入版本号',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input
                                    placeholder="请输入版本号"
                                    style={{width: 562, marginLeft: 6}}
                                />
                            </Form.Item>

                            <Form.Item
                                label="版本说明"
                                name='description'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入版本说明',
                                    },
                                    {
                                        type: 'string',
                                        max: 100,
                                        message: '请输入100字以内',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input.TextArea
                                    placeholder="请输入版本说明"
                                    style={{width: 562, marginLeft: 6, height: 169}}
                                />
                            </Form.Item>

                            <Form.Item
                                style={{
                                    textAlign: 'right',
                                    width: '100%',
                                    marginBottom: 0,
                                    marginTop: 40,
                                }}
                                {...tailLayout}
                            >
                                <Button
                                    style={{marginRight: 12, borderColor: '#6c97f7', color: '#6c97f7'}}
                                    onClick={() => {
                                        setVisible(false)
                                    }}
                                >
                                    取消
                                </Button>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                >
                                    确认
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                    {/*添加表单*/}
                    <div
                        style={{display: `${modalTyp == 'FormAdd' ? 'block' : 'none'}`}}
                        className={'addFormModal'}
                    >
                        <div style={{height: 300}}>高飞表单</div>
                        <div className={'btnS'}>
                            <Button
                                className={'searchBtn'}
                                onClick={insertTemplate}
                                style={{marginRight: 10}}
                            >
                                插入模板
                            </Button>
                            <Button
                                className={'searchBtn'}
                                onClick={handleSaveTemplate}
                            >
                                存为模板
                            </Button>
                            <Button
                                type="primary"
                                onClick={handleSaveForm}
                            >
                                保存
                            </Button>
                        </div>
                    </div>
                </Modal>
                {/*插入 弹框*/}
                <Modal
                    className={'designPlanModal'}
                    visible={insertTemplateFlag}
                    title="CRF表单模板库"
                    onCancel={() => {
                        setInsertTemplateFlag(false)
                    }}
                    width={1102}
                    centered
                    destroyOnClose
                    footer={[]}
                >
                    {/*crf表单模板库 插入模板*/}
                    <div
                        className={'crfFormModal'}
                        style={{display: `${insertOrSaveTempFlag ? 'flex' : 'none'}`}}
                    >
                        <div className={'m_left'}>
                            <div className={'top'}>
                                <div className={'all_search'}>
                                    <div>
                                        <Input
                                            name={'projectCode'}
                                            addonAfter={selectAfter}
                                            placeholder={`请输入${sessionStorage.getItem('targetName') || '受试者'}编号查找`}
                                            ref={inputEl}
                                        />
                                        <Button
                                            type="primary"
                                            style={{width: 64, height: 36}}
                                            onClick={handleTempLibrarySearch}
                                        >
                                            查找
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className={'bottom'}>
                                {
                                    tempTreeData && tempTreeData.length !== 0 ?
                                        <DirectoryTree
                                            showLine={{showLeafIcon: false}}
                                            onSelect={handleTempTreeSelect}
                                            onExpand={handleTempTreeExpand}
                                            treeData={tempTreeData}
                                            expandedKeys={tempExpandedKeys}
                                            selectedKeys={tempTreeSelectedKeys}
                                        /> : '暂无数据,请联系管理员！！！'
                                }
                            </div>
                        </div>
                        <div className={'m_right'}>
                            <div className={'title'}>
                                <h4>方案内容</h4>
                                <div className={'historySelect'}>
                                    {
                                        <Select
                                            allowClear
                                            style={{width: 200}}
                                            defaultValue={'1.0'}
                                        >
                                            <Option value='1.0' key='1.0'>v1.0</Option>
                                            <Option value='2.0' key='2.0'>v2.0</Option>
                                            <Option value='3.0' key='3.0'>v3.0</Option>
                                        </Select>
                                    }
                                </div>
                            </div>
                            <div className={'con'}>
                                {
                                    tempTreeSelectedInfo && tempTreeSelectedInfo.length != 0 ?
                                        JSON.stringify(tempTreeSelectedInfo) : '高飞表单'
                                }
                            </div>
                            <div className={'btns'}>
                                <Button
                                    className={'searchBtn'}
                                    onClick={() => {
                                        setInsertTemplateFlag(false)
                                        setVisible(true)
                                    }}
                                    style={{borderRadius: '2px', marginRight: 10}}
                                >
                                    取消
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{borderRadius: '2px',}}
                                    onClick={handleCrfInsertModalFinish}
                                >
                                    插入
                                </Button>
                            </div>
                        </div>
                    </div>
                    {/*crf表单模板库 存为模板*/}
                    <div
                        className={'crfSaveTempForm'}
                        style={{display: `${insertOrSaveTempFlag ? 'none' : 'block'}`}}
                    >
                        <Form
                            {...layout}
                            requiredMark={true}
                            ref={crfSaveFormRef}
                            onFinish={handleSaveTempModalFinish}
                        >
                            <Form.Item
                                label="选择模板库"
                                name='111'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择模板库',
                                    }
                                ]}
                                initialValue={'all'}
                            >
                                <Select
                                    style={{width: 562, marginLeft: 6}}
                                >
                                    {
                                        tempLibraryList && tempLibraryList.length != 0 ?
                                            tempLibraryList.map((i: any) => (
                                                <Option
                                                    value={i.code}
                                                    key={i.code}
                                                >{i.name}</Option>
                                            )) : ''
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="选择储存目录"
                                name='222'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择储存目录',
                                    }
                                ]}
                                initialValue={'a'}
                            >
                                <Select
                                    style={{width: 562, marginLeft: 6}}
                                >
                                    <Option value='a' key='a'>aaa目录</Option>
                                    <Option value='b' key='b'>bbb目录</Option>
                                    <Option value='c' key='c'>ccc目录</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="表单名称"
                                name='333'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入表单名称',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input
                                    placeholder="请输入表单名称"
                                    style={{width: 562, marginLeft: 6}}
                                />
                            </Form.Item>

                            <Form.Item
                                label="版本号"
                                name='444'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入版本号',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input
                                    placeholder="请输入版本号"
                                    style={{width: 562, marginLeft: 6}}
                                />
                            </Form.Item>

                            <Form.Item
                                label="版本说明"
                                name='description'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入版本说明',
                                    },
                                    {
                                        type: 'string',
                                        max: 100,
                                        message: '请输入100字以内',
                                    },
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                            >
                                <Input.TextArea
                                    placeholder="请输入版本说明"
                                    style={{width: 562, marginLeft: 6, height: 169}}
                                />
                            </Form.Item>

                            <Form.Item
                                style={{
                                    textAlign: 'right',
                                    width: '100%',
                                    marginBottom: 0,
                                    marginTop: 40,
                                }}
                                {...tailLayout}
                            >
                                <Button
                                    className={'searchBtn'}
                                    onClick={() => {
                                        setInsertTemplateFlag(false)
                                        setVisible(true)
                                    }}
                                    style={{borderRadius: '2px', marginRight: 10}}
                                >
                                    取消
                                </Button>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                >
                                    确认
                                </Button>
                            </Form.Item>
                        </Form>

                    </div>
                </Modal>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    statusList: state.getIn(['design', 'statusList']),
    typeList: state.getIn(['design', 'typeList']),
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PlanEdit);