import React from 'react';
import { Modal, Button, Upload, message } from 'antd';
import { createFromIconfontCN, UploadOutlined } from '@ant-design/icons';
import { cdmsPostBack, getPostBackProcess } from '../../../services/cdms/cdmsService';

import upload_fail from '../../../static/images/upload_fail.png'
import upload_success from '../../../static/images/upload_success.png'
import uploading from '../../../static/images/uploading.png'

const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',
    ],
});

let timeInterval;

class UploadModal extends React.Component {
    state = {
        visible: false,
        noFileTips: false,
        fileList: [],
        uploadStatus: 'before', //上传状态 before pedding success fail
        allCount: 0,
        completeCount: 0,
        percent: '0%',
        errorVoList: [],
        errorCount: 0
    };

    componentDidMount() {
        this.setState({
            visible: this.props.uploadModal
        })
    }



    handleUpload = async () => {
        const { fileList } = this.state;
        if (fileList.length === 0) {
            this.setState({
                noFileTips: true
            })
            return;
        }
        this.setState({ loading: true })
        const uploadFormData = new FormData();
        fileList.forEach(file => {
            uploadFormData.append('file', file);
        });
        const userCode = sessionStorage.getItem('userName')
        uploadFormData.append('recordId', this.props.postBackId)
        uploadFormData.append('x-user-code', userCode)


        let res = await cdmsPostBack(uploadFormData);

        if (res) {
            message.success(`上传成功`);
            this.setState({
                fileList: [],
                uploadStatus: 'pedding',
                allCount: res
            });

            let params = {
                recordId: this.props.postBackId
            }
            timeInterval = setInterval(async () => {
                let resTwo = await getPostBackProcess(params);
                if (resTwo) {
                    if (resTwo.completeCount < resTwo.allCount) {
                        this.setState({
                            allCount: resTwo.allCount,
                            completeCount: resTwo.completeCount,
                            percent: resTwo.percent,
                            noFileTips: false,
                        });
                    } else {
                        this.setState({
                            allCount: resTwo.allCount,
                            completeCount: resTwo.completeCount,
                            percent: resTwo.percent,
                            uploadStatus: resTwo.resultVo.errorCount == 0 ? 'success' : 'fail',
                            errorCount: resTwo.resultVo.errorCount,
                            errorVoList: resTwo.resultVo.errorVoList,
                            noFileTips: false,
                        });
                        clearInterval(timeInterval)
                    }
                } else {
                    clearInterval(timeInterval)
                }
            }, 1000)

        }
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            fileList: [],
            uploadStatus: 'before',
            allCount: 0,
            completeCount: 0,
            percent: '0%',
            noFileTips: false,
        });
    };


    restUpload = () => {
        this.setState({
            fileList: [],
            uploadStatus: 'before',
            allCount: 0,
            completeCount: 0,
            percent: '0%',
            noFileTips: false,
        });
    };

    closeModal = () => {
        this.setState({ visible: false })
        this.props.closeModal()
    }


    /**
 * icon 图标
 */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    render() {
        const  targetName = sessionStorage.getItem('targetName') || '受试者'
        let { visible, uploadStatus, allCount, completeCount, percent, errorVoList, errorCount } = this.state

        const props = {
            name: 'file',
            accept: ".xlsx,.xls",
            headers: {
                authorization: 'authorization-text',
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }), () => {
                    if (this.state.fileList.length === 1) {
                        this.setState({
                            noFileTips: false
                        })
                    }
                });
                return false
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
        };


        let footer = null

        if (uploadStatus == 'before') {
            footer = [
                (
                    <div>
                        <Button onClick={this.closeModal}> 取消</Button>
                        <Button type="primary" onClick={this.handleUpload}>
                            确认上传
                        </Button>
                    </div>
                )
            ]
        } else if (uploadStatus == 'pedding') {
            footer = [
                (
                    <div>
                        <Button onClick={this.closeModal}>后台上传</Button>
                    </div>
                )
            ]
        } else {
            footer = [
                (
                    <div className='footer_btn'>
                        <Button icon={<UploadOutlined />} onClick={this.restUpload}> 重新上传 </Button>
                        <Button type="primary" onClick={this.closeModal}>
                            确认
                        </Button>
                    </div>
                )
            ]
        }

        return (
            <div>
                <Modal
                    title="回传审核结果"
                    visible={visible}
                    onCancel={this.closeModal}
                    className='uploadModal'
                    footer={footer}
                >
                    {uploadStatus == 'before' && <div>
                        <div className='title'>请在点击下方按钮上传审核后的文件，请确保文件格式正确。</div>
                        <div className='selectProject'>
                            <div>上传文件：</div>
                            <Upload {...props}>
                                <Button icon={<UploadOutlined />} disabled={this.state.fileList.length > 0}>
                                    上传文件
                                </Button>
                            </Upload>
                            <div className='notips' style={{ display: `${this.state.noFileTips ? 'block' : 'none'}` }}>
                                {this.formatIcon('icon-guanbi')}
                                <span style={{ marginLeft: 4 }}>请先选择文件</span>
                            </div>
                        </div>
                        <div className='uploadtip'>支持扩展名：.xlsx</div>
                    </div>}

                    {uploadStatus == 'pedding' && <div className='uploading_content'>
                        <div className='uploading_title'>审核结果回传中</div>
                        <div className='uploading_tip'>请耐心等待数据上传完成</div>
                        <img src={uploading} alt="" />
                        <div className='uploading_progress'>{`进度：${completeCount}/${allCount} (${percent})`}</div>
                    </div>}

                    {uploadStatus == 'success' && <div className='upload_success_content'>
                        <img src={upload_success} alt="" />
                        <div>
                            <div className='success_tip'>审核结果上传完成</div>
                            <div className='success_num'>共上传{allCount}条</div>
                        </div>
                    </div>}

                    {uploadStatus == 'fail' && <div className='upload_fail_content'>
                        <div className='fail_title'>
                            <img src={upload_fail} alt="" />
                            <span>回传审核失败记录</span>
                        </div>
                        <div className='fail_tip'>请仔细查看下列数据，修改后可重新上传审核</div>
                        <div className='error_list'>
                            <div className='error_item'>共上传{allCount}条，错误{errorCount}条:</div>
                            {
                                errorVoList.map((v, i) => (
                                    <div className='error_item' key={i}>{i + 1}. 第{v.lineNum}行{v.subjectCode}{targetName}{v.reason}</div>
                                ))
                            }

                        </div>
                    </div>}

                </Modal>
            </div>
        );
    }
}

export default UploadModal