import React, {Component, Fragment} from 'react';
import './dataThemTreeDetail.less';
import {withRouter} from 'react-router-dom';
import {Form, Input, Select, Button, message} from 'antd';
import {
    GetDataElementSave,
    GetDataElementSelects,
    GetDataElementInfo,
    GetDataElementModify
} from '@/services/config/ConfigService'

const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 19,
    },
};
const {Option} = Select

class DataThemTreeDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataThemSelectList: [] // 数据元类型下拉框集合
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log(nextProps)
        if (nextProps.info && nextProps.operation == 'modify') {
            this.getDataElementInfo(nextProps.info.id)
        }
        if (nextProps.operation == 'add') {
            this.formRef.current.setFieldsValue({
                name: '',
                code: '',
                nameIndex: '',
                define: '',
                type: [],
                format: '',
                allowValue: '',
            })
        }
    }

    componentDidMount() {
        this.getDataElementSelects()
        if (this.props.operation == 'modify') {
            this.getDataElementInfo(this.props.info.id)
        }
        if (this.props.operation == 'add') {
            this.formRef.current.setFieldsValue({
                name: '',
                code: '',
                nameIndex: '',
                define: '',
                type: [],
                format: '',
                allowValue: '',
            })
        }
    }

    formRef = React.createRef()

    /**
     * 数据元 下拉框数据集合
     */
    getDataElementSelects = () => {
        GetDataElementSelects().then(res => {
            if (res.typeEnumList && res.typeEnumList.length != 0) {
                this.setState({
                    dataThemSelectList: res.typeEnumList
                })
            }
        })
    }

    /**
     * 数据元 详情
     */
    getDataElementInfo = (id = '') => {
        GetDataElementInfo(id).then(res => {
            if (res) {
                this.formRef.current.setFieldsValue({
                    name: res.name,
                    code: res.code,
                    nameIndex: res.nameIndex,
                    define: res.define,
                    type: res.type,
                    format: res.format,
                    allowValue: res.allowValue,
                })
            }
        })
    }

    /**
     * 添加 修改 子 元
     */
    handleFinish = (e) => {
        if (this.props.operation == 'modify') {
            //修改
            let param = e;
            param.id = this.props.info.id;
            GetDataElementModify(param).then(res => {
                if (res.code == 0) {
                    message.success('修改成功！')
                    this.props.interFace()
                }
            })
        } else if (this.props.operation == 'add') {
            // 添加
            let param = e;
            param.directoryId = this.props.info.id;
            param.themeId = this.props.themeId;
            param.type = Array.isArray(param.type) ? null : param.type
            GetDataElementSave(param).then(res => {
                if (res.code == 0) {
                    message.success('添加成功！')
                    this.props.interFace()
                    this.formRef.current.setFieldsValue({
                        name: '',
                        code: '',
                        nameIndex: '',
                        define: '',
                        type: [],
                        format: '',
                        allowValue: '',
                    })
                }
            })
        }

    }

    render() {
        return (
            <Fragment>
                <Form
                    {...layout}
                    name="nest-messages"
                    onFinish={this.handleFinish}
                    requiredMark={true}
                    ref={this.formRef}
                    className={'dataThemTreeFrom'}
                >
                    <Form.Item
                        label="数据元编码"
                        name='code'
                        rules={[
                            {
                                required: true,
                                message: '请输入数据元编码',
                            },
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ]}
                    >
                        <Input
                            style={{marginLeft: 8}}
                            placeholder="请输入数据元编码"
                        />
                    </Form.Item>

                    <Form.Item
                        label="数据元名称"
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: '请输入数据元名称',
                            },
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ]}
                    >
                        <Input
                            style={{marginLeft: 8}}
                            placeholder="请输入数据元名称"
                        />
                    </Form.Item>

                    <Form.Item
                        label="数据元索引"
                        name='nameIndex'
                        rules={[
                            {
                                required: true,
                                message: '请输入数据元索引',
                            }, {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ]}
                    >
                        <Input
                            style={{marginLeft: 8}}
                            placeholder="请输入数据元索引"
                        />
                    </Form.Item>

                    <Form.Item
                        label="数据元定义"
                        name='define'
                        rules={[
                            {
                                required: true,
                                message: '请输入数据元定义',
                            }, {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ]}
                    >
                        <Input.TextArea
                            style={{marginLeft: 8, height: 108}}
                            placeholder="请输入数据元定义"
                        />
                    </Form.Item>

                    <Form.Item
                        label="数据元类型"
                        name='type'
                        rules={[
                            {
                                required: true,
                                message: '请选择数据元类型',
                            }
                        ]}
                    >
                        <Select
                            allowClear
                            placeholder="请选择数据元类型"
                            style={{marginLeft: 8}}
                        >
                            {
                                this.state.dataThemSelectList && this.state.dataThemSelectList.length != 0 ?
                                    this.state.dataThemSelectList.map(v => (
                                        <Option
                                            value={v.code}
                                            key={v.code}
                                        >{v.name}</Option>
                                    )) : ''
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="表示格式"
                        name='format'
                        rules={[
                            {
                                required: true,
                                message: '请输入表示格式',
                            },
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ]}
                    >
                        <Input
                            style={{marginLeft: 8}}
                            placeholder="请输入表示格式"
                        />
                    </Form.Item>

                    <Form.Item
                        label="容许值"
                        name='allowValue'
                        rules={[
                            {
                                required: true,
                                message: '请输入容许值',
                            },
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            }
                        ]}
                    >
                        <Input
                            style={{marginLeft: 8}}
                            placeholder="请输入容许值"
                        />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{span: 24}}
                        style={{textAlign: 'right', marginRight: '-8px'}}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{backgroundColor: '#6287ec', borderColor: '#6287ec'}}
                        >
                            {this.props.operation == 'modify' ? '修 改' : '保 存'}
                        </Button>
                    </Form.Item>

                </Form>
            </Fragment>
        )
    }
}

export default withRouter(DataThemTreeDetail);