
import React from "react"

type DictItem<T> = {
  value: number,
  label: T
}

const transform = function <T>(elements: T[], ignoreEmpty=true) {
  const pieces: DictItem<T>[] = []
  elements.forEach((label, value)=> {
    if(ignoreEmpty && label === '') {
      return
    }
    pieces.push({
      label,
      value
    })
  })
  return pieces
}

const a2m = function<T>(elements: DictItem<T>[]) {
  const m = new Map<number, T>()
  elements.forEach(({label, value})=> {
    m.set(value, label)
  })
  return m
}

// 随访状态
export const visitStatusDict = transform(['待随访', '随访中', '已随访', '已失效']);
// 随访类型
export const visitTypeDict = transform(['', '用药提醒', '问卷提醒']);
// 受试者状态
export const examineeStatusDict = transform(['', '死亡', '失访', '撤回知情同意', '生存']);
// 性别
export const genderDict = transform(['', '男', '女']);
// 本人还是家属
export const whoisDict = transform(['', '本人', '家属']);
// 学历
export const qualDict = transform(['', '文盲', '小学', '初中', '高中', '大学', '研究生及以上']);

const dictMap = new Map([
  // 学历
  ['qual', a2m(qualDict)],
  // 本人还是家属
  ['whois', a2m(whoisDict)],
  // 性别
  ['gender', a2m(genderDict)],
  // 随访状态
  ['visitStatus', a2m(visitStatusDict)],
  // 随访类型
  ['visitType', a2m(visitTypeDict)],
  // 受试者状态
  ['examineeStatus', a2m(examineeStatusDict)],
])

type Props = {
  name: string;
  value: number | string | null;
}
const DictTransform: React.FC<Props> = ({name, value})=> {
  if('' === value || null === value || !dictMap.has(name)) {
    return null
  }
  return <span>{dictMap.get(name)?.get(Number(value))}</span>
}

export default DictTransform