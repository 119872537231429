import React, { Component } from 'react';
import { Modal, Button, Form, Input, message, AutoComplete, Row, Col } from 'antd';
import { createFromIconfontCN } from '@ant-design/icons';
import axios from 'axios';
import { getUserMaterial, editUserMaterial } from '../../../../../services/project/ProjectService';
import './modalEditor.less';

const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

class ModalEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEditor: true, // 是否编辑
            formData: null, // 表单数据
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        this.getUserMaterial();
    }

    /**
     * 关闭
     */
    handleCancel = () => {
        this.props.closeModal();
    };

    /**
     * 
     * @param {*} 获取参与者数据
     */
    getUserMaterial = () => {
        let { userId } = this.props;
        const params = {
            userId
        }
        getUserMaterial(params).then(res => {
            if (res && res.code == 0) {
                this.setState({
                    formData: res.data
                }, () => {
                    this.formRef.current.setFieldsValue(res.data)
                })
            }
        })
    }

    // 提交
    onFinish = (values) => {
        // 确认
        if (!this.state.isEditor) {
            let { userId } = this.props;
            let params = values;
            params['id'] = userId;
            editUserMaterial(params).then(res => {
                if (res && res.code == 0) {
                    message.success('修改成功！')
                    this.props.closeModal();
                    this.props.getDatas();
                } else {
                    message.success(res.data)
                }
            })
        }
        // 编辑
        else {
            this.setState({
                isEditor: false
            })
        }
    };

    render() {
        const { isEditor, formData } = this.state;
        const { visible } = this.props;

        return (
            <>
                <Modal
                    className={'modal_editor'}
                    visible={visible}
                    title="查看和修改医生（参与者）收款资料"
                    onCancel={this.handleCancel}
                    width={'40%'}
                    centered
                    destroyOnClose
                    maskClosable={false}
                    footer={null}
                >
                    <Form
                        name="basic"
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        onFinish={this.onFinish}
                        autoComplete="off"
                        initialValue={formData}
                        ref={this.formRef}
                    >
                        {/* 基本信息 */}
                        <Form.Item label="基本信息" style={{ fontWeight: 'bolder' }}></Form.Item>
                        <Form.Item
                            label="账户名"
                            name="account"
                            rules={[
                                {
                                    required: false,
                                    message: '请输入账户名！',
                                },
                            ]}
                        >
                            <Input disabled={isEditor} />
                        </Form.Item>
                        <Form.Item
                            label="身份证号"
                            name="cardNo"
                            rules={[
                                {
                                    required: false,
                                    message: '请输入身份证号！',
                                },
                            ]}
                        >
                            <Input disabled={isEditor} />
                        </Form.Item>
                        <Form.Item
                            label="科室"
                            name="department"
                            rules={[
                                {
                                    required: false,
                                    message: '请输入科室！',
                                },
                            ]}
                        >
                            <Input disabled={isEditor} />
                        </Form.Item>
                        <Form.Item
                            label="职称"
                            name="jobTitle"
                            rules={[
                                {
                                    required: false,
                                    message: '请输入职称！',
                                },
                            ]}
                        >
                            <Input disabled={isEditor} />
                        </Form.Item>

                        {/* 银行信息 */}
                        <Form.Item label="银行信息" style={{ fontWeight: 'bolder' }}></Form.Item>
                        <Form.Item
                            label="姓名"
                            name="name"
                            rules={[
                                {
                                    required: false,
                                    message: '请输入姓名！',
                                },
                            ]}
                        >
                            <Input disabled={isEditor} />
                        </Form.Item>
                        <Form.Item
                            label="银行卡"
                            name="bankNo"
                            rules={[
                                {
                                    required: false,
                                    message: '请输入银行卡！',
                                },
                            ]}
                        >
                            <Input disabled={isEditor} />
                        </Form.Item>
                        <Form.Item
                            label="开户行"
                            name="openBank"
                            rules={[
                                {
                                    required: false,
                                    message: '请输入开户行！',
                                },
                            ]}
                        >
                            <Input disabled={isEditor} />
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 6,
                                span: 16,
                            }}
                            style={{ padding: '10px 0 40px' }}
                        >
                            <Button type="primary" htmlType="submit">
                                {isEditor ? '编辑' : '确认'}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}

export default ModalEditor;