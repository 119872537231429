import React from "react";
import { Link } from 'react-router-dom';
import './nav.less';
import {Breadcrumb} from 'antd';
import {createFromIconfontCN} from '@ant-design/icons';
import localStorageUtil from "../../../comm/localStorage";
import PubSub from "pubsub-js";

let _this;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_1788044_0dwu4guekcwr.js', // icon-javascript, icon-java, icon-shoppingcart (overrided)
    ],
});

class AppCrumbs extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            levelOneTitle: "",
            levelTwoTitle: "",
            levelTwoUrl: "",
            levelThreeTitle: "",
        }
    }

    componentDidMount() {
        PubSub.subscribe('onMenuModuleChange', _this.initMenuModuleTitles);
    }

    initMenuModuleTitles = () => {
        let menuModule = localStorageUtil.get('menuModule', true);
        const levelOneTitle = menuModule.levelOneTitle || '';
        const levelTwoUrl = menuModule.levelTwoUrl || '';
        const levelTwoTitle = menuModule.levelTwoTitle || '';
        const levelThreeTitle = menuModule.levelThreeTitle || '';
        this.setState({
            levelOneTitle,
            levelTwoTitle,
            levelTwoUrl,
            levelThreeTitle,
        });
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName}/>) : undefined;
    }

    onRefresh = () => {
        window.location.reload() 
    }

    render() {
        return (
            <div className={'crumbs'}>
                <div className={'crumbs_icon'}>
                    {/*{_this.formatIcon('icon-java')}*/}
                </div>
                <div className={'crumbs_nav'}>
                    <Breadcrumb>
                        <Breadcrumb.Item className={'first_item'}>
                            {_this.state.levelOneTitle}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {/* <Link to={_this.state.levelTwoUrl}>{_this.state.levelTwoTitle}</Link> */}
                            <span className={'refresh'} onClick={this.onRefresh}>{_this.state.levelTwoTitle}</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {_this.state.levelThreeTitle}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        );
    }
}

export default AppCrumbs;