/**
 * 登录
 * lechen
 */

import React from 'react';
import './login.less';
import { withRouter } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Modal, message } from 'antd';
import PubSub from "pubsub-js";
import User from "../../../models/plat/User";
import { Account } from "../../../models/plat/Account";
import { DoLogin } from "../../../services/plat/UserService";
import { Encrypt, Decrypt } from "../../../util/crypto";

import { EyeInvisibleOutlined, EyeTwoTone, createFromIconfontCN } from '@ant-design/icons';
const layout = {
    wrapperCol: {
        span: 24,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 19,
        span: 9,
    },
};

const MyIcon = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_2343612_u4ool4i00dn.js', // 在 iconfont.cn 上生成
});

class AppLogin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            checkAgreement: false,
            passWordType: 'password'
        }
    }

    componentDidMount() {
        let checkAgreement = localStorage.getItem("checkAgreement")
        if (checkAgreement) {
            this.setState({
                checkAgreement: true
            })
        }
    }

    loginPage = () => {
        let loginPage = 'default';

        let lastTenant = undefined;
        let tenantCode = localStorage.getItem('tenantCode');
        if (tenantCode && tenantCode.trim().length > 0) {
            lastTenant = tenantCode;
        }

        if (!lastTenant) {
            return loginPage;
        }

        if (lastTenant === 'TENANT-013') {
            return 'hd';
        } else {
            return loginPage
        }
    };

    /**
     * 登录成功
     */
    onFinish = values => {
        if (!this.state.checkAgreement) {
            message.info('请先阅读并同意知情同意书')
            return false;
        }


        let account = new Account(values);
        let params = {
            userCode: account.userCode,
            passWord: Encrypt(account.passWord),
        }

        //测试加密
        console.log(Decrypt(Encrypt(account.userCode)))

        DoLogin(params).then(user => {
            if (user) {
                const userData = new User(user);
                sessionStorage.setItem('token', JSON.stringify(userData));
                sessionStorage.setItem('userName', account.userCode);
                sessionStorage.setItem('roleCode', userData.roleCode);
                sessionStorage.setItem('currentuserName', user.userName);
                localStorage.setItem('tenantCode', userData.tenantCode);
                localStorage.setItem('checkAgreement', 'true')
                PubSub.publish('onLoginSuccess');
                this.props.history.push("/index");
            }
        })

    };

    /**
     * 注册事件
     */
    registerEvent = () => {
        PubSub.publish('goRegister');
    }

    /**
     * 登录失败
     */
    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    handleSure = () => {
        this.setState({
            checkAgreement: true,
            isModalVisible: false
        })
    }


    //同意协议checkbox改变
    onCheckboxChange = (e) => {
        if (!this.state.checkAgreement) {
            this.setState({
                isModalVisible: true
            })
            return false;
        }
        this.setState({
            checkAgreement: e.target.checked
        })
    };

    //显示服务协议模态框
    showModal = (e) => {
        this.setState({ isModalVisible: true })
    };

    //改变密码框类型
    changePassWordType = () => {
        if (this.state.passWordType === 'password') {
            this.setState({ passWordType: 'text' })
        } else {
            this.setState({ passWordType: 'password' })
        }
    }

    getPassWordIcon = () => {
        return this.state.passWordType === 'password' ? <MyIcon className='fontIcon' type='iconpassword_disvisible' onClick={this.changePassWordType} /> :
            <MyIcon className='fontIcon' type='iconpassword_visible' onClick={this.changePassWordType} />
    }

    render() {
        const loginPage = this.loginPage();
        return (
            <div className={loginPage === 'default' ? 'login' : 'login_'+loginPage}>
                <div className={"login_con"}>
                    <div className={"title_box"}>
                        <h1 className={"title"}>管理后台</h1>
                        <div className={"title_tips"}>惠每临床科研与患者管理系统</div>
                    </div>
                    <div className={"login_main"}>
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{ remember: true }}
                            requiredMark={false}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <div className='user_label'>账号</div>
                            <Form.Item
                                label=""
                                name="userCode"
                                rules={[{ required: true, message: '请输入账号！' }]}
                                initialValue={sessionStorage.getItem('userName')}
                            >
                                <Input placeholder="请输入账号" style={{ height: 40 }} />
                            </Form.Item>
                            <div className='user_label'>密码</div>
                            <Form.Item
                                label=""
                                name="passWord"
                                rules={[{ required: true, message: '请输入密码!' }]}
                            >
                                <Input
                                    type={this.state.passWordType}
                                    placeholder="请输入密码"
                                    suffix={this.getPassWordIcon()}
                                />
                            </Form.Item>
                            <Form.Item >

                                <Checkbox checked={this.state.checkAgreement} onChange={this.onCheckboxChange}>
                                </Checkbox>
                                <span className={'agreement'}>
                                    已阅读并同意
                                    <span className='view_agreement' onClick={() => this.setState({ isModalVisible: true })}>《知情同意书》</span>
                                </span>
                            </Form.Item>
                            <Form.Item  className='action_submit'>
                                {/* <div className={'login_register'} onClick={this.registerEvent}>
                                    注册账号
                                </div> */}
                                <Button className={"submit"} type="primary" htmlType="submit">
                                    登录
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <Modal
                    title="知情同意书"
                    visible={this.state.isModalVisible}
                    cancelText='不同意'
                    okText='同意'
                    onOk={this.handleSure}
                    onCancel={() => this.setState({ isModalVisible: false, checkAgreement: false })}>
                    <p>本人所在的医院及科室并未对我参与此项目做出任何禁止性规定，且本人填写的信息内容已获取患者的同意，并已按照个人信息保护相关法律法规的要求向患者履行告知义务。</p>
                </Modal>
            </div>
        )
    }
}

export default withRouter(AppLogin);


