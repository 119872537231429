/**
 * 助理统计
 */
import React, { Component } from 'react';
import { Select, Tabs, message, Card, List, Table } from 'antd';
import { createFromIconfontCN } from "@ant-design/icons";
import { GetDctAssistantStatistics } from "../../../../services/dct/DctService";
import firstImg from "../../static/images/one.svg";
import secondImg from "../../static/images/two.svg";
import thirdImg from "../../static/images/three.svg";
import './index.less';

const { TabPane } = Tabs;
let _this;
const { Option } = Select;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2343612_ixe83tdh89g.js',
    ],
});

class AssistantStatistics extends Component {

    constructor(props) {
        super(props);
        _this = this;
        this.state = {
            statisticsData: [],
            totalNum: null, // 总人数
            onLineNum: null, // 今日在线
            tasksData: [],
            columns: [
                {
                    title: '姓名',
                    dataIndex: 'name',
                    key: 'name',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <img src={index == 0 ? firstImg : index == 1 ? secondImg : index == 2 ? thirdImg : null} alt='' width="20px"/>
                                <div className={'title'}>
                                    {text}
                                </div>
                            </div>
                        )
                    },
                },
                {
                    title: '完成数',
                    dataIndex: 'completeCount',
                    key: 'completeCount',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {text}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '合格率',
                    dataIndex: 'qualifiedRate',
                    key: 'qualifiedRate',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {`${text}%`}
                                </div>
                            </div>
                        )
                    }
                }
            ]
        };
        this.inputEl = React.createRef();
    }

    componentDidMount() {
        this.getData();
    }

    // 获取数据
    getData = () => {
        GetDctAssistantStatistics().then(res => {
            if (res.code == 0) {
                if (!res.data) {
                    return;
                }
                this.setState({
                    tasksData: res.data.rankList,
                    totalNum: res.data.TotalNumber,
                    onLineNum: res.data.currentlyOnline
                })
            }
        })
    }

    /**
     * 下拉框
     * @param key
     */
    handleSelect = (c) => {
        this.setState({
            selectValue: c
        })
    }

    // 查看更多
    openMore = () => {
        console.log('查看更多')
    }

    render() {

        let { totalNum, onLineNum, columns, tasksData } = this.state

        const selectCard = [
            { 'value': 1, 'label': '' },
            { 'value': 2, 'label': '' },
            { 'value': 3, 'label': '' },
        ];

        let selectCardAfter = (
            <Select
                defaultValue="今日内"
                className="select-after"
                onSelect={this.handleSelect}
                name={'subjectCode'}
            >
                {
                    selectCard.map(i => (
                        <Option
                            value={i.value}
                            key={i.value}
                        >{i.label}</Option>
                    ))
                }
            </Select>
        );

        const data = [
            { title: '总人数', number: totalNum, style: { color: '#276ec9' } },
            { title: '今日在线', number: onLineNum, style: { color: '#23aea8' } },
        ]

        return (
            <div className={'dctStatistics_content'}>
                <div className="top_bottom">
                    <div className="tit">助理统计</div>
                    <div className="statisticsNum">
                        {
                            data.map((item, index) => (
                                <div className={`number`} key={index}>
                                    <span className={`num`} style={item.style}>{item.number}</span>
                                    <div className={`numtxt`}>{item.title}</div>
                                </div>
                            ))
                        }

                    </div>
                    <Card title="排行榜" extra={selectCardAfter} bordered={false}>
                        <Table columns={columns} dataSource={tasksData} size={'small'} pagination={false} />
                    </Card>
                    {tasksData.length > 0 && <div onClick={this.openMore} className="more">查看更多 {'>'}</div>}
                </div>

            </div>
        )
    }
}
export default AssistantStatistics