import React from 'react';
import { Button, Select, DatePicker, Space, Table, Modal, Form, Input, Spin, message, Radio, Image, ConfigProvider } from 'antd';
import { InfoCircleOutlined, FileTextOutlined } from '@ant-design/icons';
import {
    GetCsoOrderList, GetUserSelectsByName, GetCSOorderDetail, getCsoProjectList,
    getCsoDoctorList, getCsoPatientList, downloadCsoOrder, getVrFollowUserList,
    vrFollow
} from '../../../services/cso/CsoService'
import { getTenantList } from "../../../services/system/UserService";
import './index.less'
import locale from 'antd/es/date-picker/locale/zh_CN';
import utils from '../../../util/utils';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import UserIcon from '../../../static/csoImages/user_icon.png'
import FileText from '../../../static/csoImages/file_text.png'
import FilePlus from '../../../static/csoImages/file_plus.png'
import BottomArrow from '../../../static/csoImages/bottom_arrow.png'
import TopArrow from '../../../static/csoImages/bottom_top.png'
import EditFileText from '../../../static/csoImages/edit_file_text.png'
import $ from 'jquery';
import { debounce } from 'lodash';
import { BASE_URL } from '../../../../src/comm/config';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

let typeOptions = [{
    label: '荐方',
    value: '1'
}, {
    label: '问诊',
    value: '2'
}, {
    label: '开方',
    value: '3'
}, {
    label: '下单',
    value: '4'
}, {
    label: '支付',
    value: '5'
}, {
    label: '发货',
    value: '6'
},  {
    label: '收货',
    value: '7'
}]

let roleOptions = [{
    label: '医生',
    value: '1'
}, {
    label: '药师',
    value: '2'
}, {
    label: '护士',
    value: '3'
}, {
    label: '非医疗专业人士',
    value: '4'
}]

const processName = ['荐方', '问诊', '开方', '下单', '支付', '发货', '收货'];
const processList = [
    {
        processCode: 1,
        processState: 3,
        processName: '荐方',
    },
    {
        processCode: 2,
        processState: 3,
        processName: '问诊',
    },
    {
        processCode: 3,
        processState: 3,
        processName: '开方',
    },
    {
        processCode: 4,
        processState: 3,
        processName: '下单',
    },
    {
        processCode: 5,
        processState: 3,
        processName: '支付',
    },
    {
        processCode: 6,
        processState: 3,
        processName: '发货',
    },
    {
        processCode: 7,
        processState: 3,
        processName: '收货',
    }
];

class Research extends React.Component {

    constructor(props) {
        super(props);
        this.lastFetchId = 0;
        this.listTimeOut = null;
        this.detailTimeOut = null;
        this.fetchUser = debounce(this.fetchUser, 800);
        this.fetchDoctorUser = debounce(this.fetchDoctorUser, 800);
        this.fetchPatientUser = debounce(this.fetchPatientUser, 800);
        this.fetchPatientList = debounce(this.fetchPatientList, 800);
        this.fetchDoctorList = debounce(this.fetchDoctorList, 800);
    }


    state = {
        userList: [],
        doctorList: [],
        patientList: [],
        fetching: false,
        typeOptions: typeOptions,
        roleOptions: roleOptions,
        tenantOptions: [],
        loading: false,
        doctorCode: null, // 所属医生 (手机号)
        doctorName: null, // 所属医生 (姓名)
        patientCode: null, // 所属患者 (手机号)
        patientName: null, // 所属患者 (名称)
        endDate: null, // 结束时间
        processState: null, // 当前状态
        startDate: null, // 开始时间
        createDate: null, // 创建时间
        tenantCode: '',
        code: null, // 所属项目
        identityType: null, // 身份
        processStates: [], // 当前状态
        identityTypes:  [], // 身份
        orderId: null, // 身份
        page: 1,
        pageSize: 10,
        pageList: [1],
        data: [],
        totalRow: 0,

        visible: false,
        visible2: false,
        visible3: false,
        visible4: false,
        visible5: false,
        vrVisible: false,
        vrDetailVisible: false,

        patientInfo: null,
        orderInfo: null,


        arrow1: true, //true是展开 false是折叠
        arrow2: true,
        arrow3: true,
        arrow4: true,
        radio1: null,
        radio2: null,
        radio3: null,
        textArea: '',
        recordList: [1, 2, 3],
        nowRecord: {}, // 当前点击的条目

        dStartDate: '', //下载开始时间
        dEndDate: '', //下载结束时间
        downloadDate: '',

        vrOptions: [],
        vrLoading: false,
        vrValue: null,
        downloading: false
    }



    formRef = React.createRef();

    componentDidMount() {
        this.getProjectList()
        this.getData()
        this.getDataLoop()
        this.getVrFollowList();
    }

    componentWillUnmount() {
        this.listTimeOut && clearTimeout(this.listTimeOut)
        this.detailTimeOut && clearTimeout(this.detailTimeOut)
    }

    //获取项目code列表
    getProjectList = () => {
        // getTenantList().then(res => {
        getCsoProjectList({
            page: 1,
            pageSize: 100 // 不传默认20条
        }).then(res => {
            let tenantOptions = []
            // res.forEach(v => {
            res.rows.forEach(v => {
                tenantOptions.push({
                    label: v.name,
                    value: v.code
                })
            });
            this.setState({
                tenantOptions
            })

        })
    }

    // 列表->因为进度更新等，需要5秒轮询
    getDataLoop = async () => {
        this.listTimeOut = setTimeout(async () => {
            const successRes = await this.getData({ needLoading: false })
            successRes && this.getDataLoop() // 成功返回时才可以继续查询以及更新
        }, 5 * 1000);
    }

    // 详情->因为进度更新等，需要5秒轮询
    getDetailLoop = (detailParam) => {
        this.detailTimeOut = setTimeout(() => {
            this.jumpDetail(detailParam)
            this.getDetailLoop(detailParam)
        }, 5 * 1000);
    }

    getData = (ops) => {
        return new Promise((resolve, reject) => {

            let needLoading = true;
            if (ops) {
                needLoading = ops.needLoading
            }
            let params = {
                page: this.state.page,
                pageSize: this.state.pageSize,
                processStates: this.state.processStates ? this.state.processStates.map(val => parseInt(val)) : null, // processState
                patientCode: this.state.patientCode ? this.state.patientCode : null,
                doctorCode: this.state.doctorCode ? this.state.doctorCode : null,
                identityTypes: this.state.identityTypes ? this.state.identityTypes.map(val => parseInt(val)) : null, // identityType
                startDate: this.state.startDate ? this.state.startDate : null,
                endDate: this.state.endDate ? this.state.endDate : null,
                // tenantCode: this.state.tenantCode ? this.state.tenantCode : undefined
                code: this.state.code ? this.state.code : null,
                orderId: this.state.orderId ? this.state.orderId : null,
            }

            this.setState({ loading: needLoading }, () => {
                GetCsoOrderList(params).then(res => {
                    if (res.code == 0) {
                        this.setState({
                            data: res.data.rows,
                            totalRow: res.data.total,
                            loading: false
                        })
                        resolve(true)
                    } else {
                        this.setState({
                            loading: false
                        })
                        resolve(false)
                    }
                })
            })
        });

    }

    getVrFollowList() {
        getVrFollowUserList().then(res => {
            if (res.code == 0) {
                this.setState({
                    vrOptions: (res.data || []).map(v => ({
                        label: v.name + ' ' + v.email,
                        value: v.code
                    }))
                })
            } 
        })
    }

    changeDate = (date, dateString) => {
        this.setState({
            startDate: dateString[0],
            endDate: dateString[1],
            createDate: date
        })
    }



    fetchUser = value => {
        if (!value) {
            this.setState({ userList: [], fetching: false });
            return;
        }
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ userList: [], fetching: true });

        let params = {
            userName: value
        }

        GetUserSelectsByName(params).then(res => {
            if (fetchId !== this.lastFetchId) {
                // for fetch callback order
                return;
            }
            const data = res.data.map(user => ({
                text: user.userName,
                value: user.userCode
            }));
            this.setState({ userList: data, fetching: false });
        });
    };

    fetchDoctorUser = value => {
        if (!value) {
            this.setState({ doctorList: [], fetching: false });
            return;
        }
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ doctorList: [], fetching: true });

        let params = {
            page: 1,
            pageSize: 100,
            content: value
        }

        getCsoDoctorList(params).then(res => {
            if (fetchId !== this.lastFetchId) {
                // for fetch callback order
                return;
            }
            const data = res.rows.map(user => ({
                text: user.name,
                value: user.code
            }));
            this.setState({ doctorList: data, fetching: false });
        });
    };

    fetchPatientUser = value => {
        if (!value) {
            this.setState({ patientList: [], fetching: false });
            return;
        }
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ patientList: [], fetching: true });

        let params = {
            page: 1,
            pageSize: 100,
            content: value
        }

        getCsoPatientList(params).then(res => {
            if (fetchId !== this.lastFetchId) {
                // for fetch callback order
                return;
            }
            const data = res.rows.map(user => ({
                text: user.name,
                value: user.code
            }));
            this.setState({ patientList: data, fetching: false });
        });
    };

    fetchPatientList = value => {
        if (!value) {
            this.setState({ patientList: [], fetching: false });
            return;
        }
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;

        let params = {
            page: 1,
            pageSize: 100,
            content: value
        }

        getCsoPatientList(params).then(res => {
            if (fetchId !== this.lastFetchId) {
                // for fetch callback order
                return;
            }
            const data = res.rows.map(user => ({
                text: user.name,
                value: user.code
            }));
            this.setState({ patientList: data, fetching: false });
        });
    };

    fetchDoctorList = value => {
        if (!value) {
            this.setState({ doctorList: [], fetching: false });
            return;
        }
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;

        let params = {
            page: 1,
            pageSize: 100,
            content: value
        }

        getCsoDoctorList(params).then(res => {
            if (fetchId !== this.lastFetchId) {
                // for fetch callback order
                return;
            }
            const data = res.rows.map(user => ({
                text: user.name,
                value: user.code
            }));
            this.setState({ doctorList: data, fetching: false });
        });
    };

    wrapperClick = e => {
        this.setState({
            patientList: [],
            doctorList: []
        });
    }

    //患者下拉框改变
    handlePatientChange = data => {
        this.setState({
            patientCode: data ? data.value : null,
            patientName: data ? data.label : null,
            userList: [],
            patientList: [],
            fetching: false,
        });
    };
    //医生下拉框改变
    handleDoctorChange = data => {
        this.setState({
            doctorCode: data ? data.value : null,
            doctorName: data ? data.label : null,
            userList: [],
            doctorList: [],
            fetching: false,
        });
    };

    handlePatientInfoChange = event => {
        this.setState({
            patientCode: event.target.value,
            patientName: event.target.value
        });
        this.fetchPatientList(event.target.value);
    };

    handleDoctorInfoChange = event => {
        this.setState({
            doctorCode: event.target.value,
            doctorName: event.target.value
        });
        this.fetchDoctorList(event.target.value);
    };

    patientClick = (data) => {
        this.setState({
            patientCode: data ? data.value : null,
            patientName: data ? data.text : null,
            patientList: []
        });
    }

    doctorClick = (data) => {
        this.setState({
            doctorCode: data ? data.value : null,
            doctorName: data ? data.text : null,
            doctorList: []
        });
    }

    // 上一页筛选
    lastPageSearch = () => {
        const nowPageList = this.state.pageList;
        const lastPage = nowPageList.length == 1 ? nowPageList[0] : nowPageList[nowPageList.length - 2]; // 回到上一个页码
        const pageList = nowPageList.length == 1 ? nowPageList : nowPageList.slice(0, nowPageList.length - 1); // 截短最后一条
        this.setState({
            page: lastPage,
            pageList: pageList
        }, () => {
            this.getData()
        })
    }

    // 重置查询条件
    resetSearch = () => {
        this.setState({
            page: 1,
            code: null,
            processState: null,
            startDate: null,
            endDate: null,
            createDate: null,
            patientCode: null,
            patientName: null,
            doctorCode: null,
            doctorName: null,
            identityType: null,
            orderId: null
        }, () => {
            this.getData()
        })
    }

    handleSearch = () => {
        this.setState({
            page: 1
        }, () => {
            this.getData()
        })
    }


    changePage = (page) => {
        // pageList
        this.setState({
            pageList: [...this.state.pageList, page]
        });
        this.setState({ page }, () => {
            this.getData()
        })
    }
    changePageSize = (current, size) => {
        this.setState({ page: 1, pageSize: size }, () => {
            this.getData()
        })
    }


    jumpDetail = (data) => {
        let params = {
            orderCode: data.orderCode
        }
        this.setState({ nowRecord: data })
        GetCSOorderDetail(params).then(res => {
            if (res.code == 0) {
                let orderInfo = res.data.orderInfoDto;
                let orderProcessList = orderInfo.processVoList;
                let showData = orderProcessList;
                showData = processList.map((val) => {
                    // 寻找对应数据
                    let item = orderProcessList.find((item) => (item.processCode == val.processCode));
                    // 缺失用默认数据
                    return item ? item : val;
                });
                orderInfo.processVoList = showData;
                this.setState({
                    patientInfo: res.data.patientInfoDto,
                    orderInfo: orderInfo,
                    recommendInfo: res.data.recommendDtoList ? res.data.recommendDtoList[0] : {},
                    treatmentInfo: res.data.treatmentInfoDto,
                })
            }
        })
        // this.getDetailLoop(data);
        this.setState({
            visible: true
        })
    }

    toShowVr = (data) => {
        this.setState({ nowRecord: data, vrVisible: true })
    }

    toShowVrDetail = (data) => {
        this.setState({ nowRecord: data, vrDetailVisible: true })
    }

    operationRecordDetail = (data) => {
        this.setState({
            visible3: true
        })
    }

    radioOnChange = (e, type) => {
        const targetValue = [];
        targetValue[type] = e.target.value;
        this.setState(targetValue)
    };

    textAreaOnChange = (e) => {
        const targetValue = [];
        targetValue['textArea'] = e.target.value;
        this.setState(targetValue)
    };

    handleSubmit = () => {
        this.detailTimeOut && clearTimeout(this.detailTimeOut);
        this.setState({
            visible: false,
            visible2: false
        });
    }



    changeDownloadDate = (date, dateString) => {
        this.setState({
            dStartDate: dateString[0],
            dEndDate: dateString[1],
            downloadDate: date
        })
    }

    vrFollowConfirm = () => {
        if (!this.state.vrValue) {
            message.info('请选择坐席~');
            return;
        }
        this.setState({
            vrLoading: true
        });
        vrFollow({
            followVr: this.state.vrValue,
            recordCode: this.state.nowRecord.orderCode
        }).then(res => {
            if (res.code == 0) {
                message.success('跟进成功！');
                setTimeout(() => {
                    this.getData();
                }, 1000);
            } else {
                message.error(res.message || '跟进失败！');
            }
            this.setState({
                vrVisible: false,
                vrLoading: false
            });
        })
    }

    handleDownload = () => {
        this.setState({
            downloading: true
        });
        let _this = this;
        let params = {
            startDate: this.state.dStartDate,
            endDate: this.state.dEndDate,
        }
        let tokenData = sessionStorage.getItem('token')
        tokenData = JSON.parse(tokenData);
        const url = BASE_URL + `order/operateManage/downloadOrder`;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.responseType = "blob";// 返回类型blob
        xhr.setRequestHeader("x-auth-token", tokenData.token);
        xhr.setRequestHeader("Content-Type", 'application/json;charset=UTF-8');
        xhr.onload = function () {
            if (this.status === 200) {
                let blob = this.response;
                let reader = new FileReader();
                reader.readAsDataURL(blob);    // 转换为base64，可以直接放入a表情href
                reader.onload = function (e) {
                    // 转换完成，创建一个a标签用于下载
                    var a = document.createElement('a');
                    a.download = '订单追踪.xls';
                    a.href = e.target.result;
                    $("body").append(a);// 修复firefox中无法触发click
                    a.click();
                    $(a).remove();
                    _this.setState({
                        dStartDate: '',
                        dEndDate: '',
                        downloadDate: '',
                        downloading: false,
                        visible4: false
                    })
                }
            }
        };

        xhr.send(JSON.stringify(params));
    }

    handlePosterDownload = () => {
        this.setState({
            downloading: true
        });
        let _this = this;
        let params = {
        }
        let tokenData = sessionStorage.getItem('token')
        tokenData = JSON.parse(tokenData);
        const url = BASE_URL + `visit/treatment/plan/doctor/poster/export`;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.responseType = "blob";// 返回类型blob
        xhr.setRequestHeader("x-auth-token", tokenData.token);
        xhr.setRequestHeader("Content-Type", 'application/json;charset=UTF-8');
        xhr.onload = function () {
            if (this.status === 200) {
                let blob = this.response;
                let reader = new FileReader();
                reader.readAsDataURL(blob);    // 转换为base64，可以直接放入a表情href
                reader.onload = function (e) {
                    // 转换完成，创建一个a标签用于下载
                    var a = document.createElement('a');
                    a.download = '海报导出.xls';
                    a.href = e.target.result;
                    $("body").append(a);// 修复firefox中无法触发click
                    a.click();
                    $(a).remove();
                    _this.setState({
                        downloading: false,
                        visible5: false
                    })
                }
            }
        };

        xhr.send(JSON.stringify(params));  
    }

    render() {
        let { data, typeOptions, tenantOptions, visible, loading, patientName, doctorName, fetching, userList, createDate,
            arrow1, arrow2, arrow3, arrow4, patientInfo, orderInfo, recommendInfo, treatmentInfo, roleOptions,orderId, downloadDate,
            radio1, radio2, radio3, visible2, visible3, recordList, patientList, doctorList, visible4, visible5, vrOptions, vrVisible } = this.state;
        const columns = [
            {
                title: '创建人',
                width: 80,
                dataIndex: 'patientName'
            },
            {
                title: '就诊人',
                width: 80,
                dataIndex: 'outPatientName'
            },
            {
                title: '志愿者',
                width: 80,
                dataIndex: 'invitationUser',
                render: (text, record) =>
                    <div style={{textAlign: 'center'}}>
                        { record.invitationUser ? record.invitationUser : '无' }
                    </div>
            },
            {
                title: '医生（角色）',
                width: 120,
                render: (text, record) =>
                    <div>
                        {(record.doctorName || '') + `${record.identityName ? ` (${record.identityName})` : ''}`}
                    </div>
            },
            // {
            //     title: '志愿者',
            //     width: 80,
            //     dataIndex: 'volunteer'
            // },
            {
                title: '订单号',
                // width: 160,
                dataIndex: 'orderId',
                render: (text, record) =>
                    <div >
                        {record.orderId || '暂无'}
                    </div>
            },
            {
                title: '创建时间',
                dataIndex: 'createTime'
            },
            {
                title: () => (
                    <div className='progress'>
                        <div>进度</div>
                        <div className='status'>
                            <span>荐方</span>
                            <span>问诊</span>
                            <span>开方</span>
                            <span>下单</span>
                            <span>支付</span>
                            <span>发货</span>
                            <span>收货</span>
                        </div>
                    </div>
                ),
                width: 460,
                dataIndex: 'processVoList',
                render: (data, record) => {
                    let blcok1 = data[0], blcok2 = data[1], blcok3 = data[2],
                        blcok4 = data[3], blcok5 = data[4], blcok6 = data[5]
                    let orderProcessList = data;
                    let showData = orderProcessList;
                    showData = processList.map((val) => {
                        // 寻找对应数据
                        let item = orderProcessList.find((item) => (item.processCode == val.processCode));
                        // 缺失用默认数据
                        return item ? item : val;
                    });
                    while (showData.length < processName.length) {
                        showData.push({});
                    }
                    return (
                        <div className='block_list'>
                            {
                                (showData || []).map((val, index) =>
                                    <div key={index} className={`block ${val ? (val.processState == '1' ? 'success' : val.processState == '2' ? 'wait' : val.processState == '4' ? 'error' : val.processState == '5' ? 'refund' : 'notStarted'
                                    ) : 'notStarted'}`}>
                                        <div className="time">{val ? val.duringTime : ''}</div>
                                    </div>
                                )
                            }
                            {/* <div className={`block ${blcok1 ? (blcok1.processState == '1' ? 'success' : blcok1.processState == '2' ? 'wait' : blcok1.processState == '4' ? 'error' : 'notStarted'
                            ) : 'notStarted'}`}>
                                <div className="time"></div>
                            </div>
                            <div className={`block ${blcok2 ? (blcok2.processState == '1' ? 'success' : blcok2.processState == '2' ? 'wait' : blcok2.processState == '4' ? 'error' : 'notStarted'
                            ) : 'notStarted'}`}>
                                <div className="time">{blcok2 ? blcok2.duringTime : ''}</div>
                            </div>
                            <div className={`block ${blcok3 ? (blcok3.processState == '1' ? 'success' : blcok3.processState == '2' ? 'wait' : blcok3.processState == '4' ? 'error' : 'notStarted'
                            ) : 'notStarted'}`}>
                                <div className="time">{blcok3 ? blcok3.duringTime : ''}</div>
                            </div>
                            <div className={`block ${blcok4 ? (blcok4.processState == '1' ? 'success' : blcok4.processState == '2' ? 'wait' : blcok4.processState == '4' ? 'error' : 'notStarted'
                            ) : 'notStarted'}`}>
                                <div className="time">{blcok4 ? blcok4.duringTime : ''}</div>
                            </div>
                            <div className={`block ${blcok5 ? (blcok5.processState == '1' ? 'success' : blcok5.processState == '2' ? 'wait' : blcok5.processState == '4' ? 'error' : 'notStarted'
                            ) : 'notStarted'}`}>
                                <div className="time">{blcok5 ? blcok5.duringTime : ''}</div>
                            </div>
                            <div className={`block ${blcok6 ? (blcok6.processState == '1' ? 'success' : blcok6.processState == '2' ? 'wait' : blcok6.processState == '4' ? 'error' : 'notStarted'
                            ) : 'notStarted'}`}>
                                <div className="time">{blcok6 ? blcok6.duringTime : ''}</div>
                            </div> */}
                        </div>
                    )
                }
            },
            {
                title: '发货地',
                dataIndex: 'storeName',
                render: (text, record) =>
                <div>
                    { record.storeName ? record.storeName : '无' }
                </div>
            },
            {
                title: '所属项目',
                // width: 160,
                // dataIndex: 'tenantName'
                dataIndex: 'projectName'
            },
            {
                title: '所属处方',
                // width: 160,
                dataIndex: 'treatmentPlan'
            },
            {
                title: '购买数',
                dataIndex: 'buyNum'
            },
            {
                title: '建议编号',
                // width: 160,
                dataIndex: 'orderCode'
            },
            {
                title: 'VR状态',
                render: (text, record) =>
                <div>
                    {record.vrFollowDto?.followState == 1 ? '分配跟进' : record.vrFollowDto?.followState == 2 ? '跟进结束' : '暂无'}
                </div>
            },
            {
                title: 'VR跟进人',
                render: (text, record) =>
                <div>
                    {record.vrFollowDto?.vrName || '暂无'}
                </div>
            },
            {
                title: '分配跟进时间',
                render: (text, record) =>
                <div>
                    {record.vrFollowDto?.createTime?.replace('T', ' ') || '暂无'}
                </div>
            },
            {
                title: 'VR沟通结果(时间点)',
                render: (text, record) =>
                <div>
                    <div>
                        {record.vrFollowDto?.result || '暂无'}
                    </div>
                    <div>
                        {record.vrFollowDto?.finishTime?.replace('T', ' ') || ''}
                    </div>
                </div>
            },
            {
                title: '操作',
                fixed: 'right',
                // width: 160,
                render: (text, record) =>
                    <div >
                        <Button style={{ padding: 0, marginRight: '10px' }} type="link" onClick={() => this.jumpDetail(record)} >
                            查看
                        </Button>
                        {
                            record.vrFollowDto?.followState != 1 &&
                            <Button style={{ padding: 0, marginRight: '10px' }} type="link" onClick={() => this.toShowVr(record)} >
                                VR跟进
                            </Button>
                        }
                        {
                            record.vrFollowDto?.followState &&
                            <Button style={{ padding: 0, marginRight: '10px' }} type="link" onClick={() => this.toShowVrDetail(record)} >
                                跟进详情
                            </Button>
                        }
                        {/* <Button style={{ padding: 0, marginRight: '10px' }} type="link" onClick={() => this.operationRecordDetail(record)} >
                            处置记录
                        </Button> */}
                    </div>
            },
        ];

        const vrDetailCol = [
            {
                title: 'VR状态',
                render: (text, record) =>
                <div>
                    {record.vrFollowDto?.followState == 1 ? '分配跟进' : record.vrFollowDto?.followState == 2 ? '跟进结束' : '--'}
                </div>
            },
            {
                title: 'VR跟进人',
                render: (text, record) =>
                <div>
                    {record.vrFollowDto?.vrName || '--'}
                </div>
            },
            {
                title: '分配跟进时间',
                render: (text, record) =>
                <div>
                    {record.vrFollowDto?.createTime?.replace('T', ' ') || '--'}
                </div>
            },
            {
                title: '跟进结束时间',
                render: (text, record) =>
                <div>
                        {record.vrFollowDto?.finishTime?.replace('T', ' ') || '--'}
                </div>
            },
            {
                title: '跟进结果',
                render: (text, record) =>
                <div>
                    {record.vrFollowDto?.result || '--'}
                </div>
            },
            {
                title: '备注内容',
                render: (text, record) =>
                <div>
                    {record.vrFollowDto?.remark || '--'}
                </div>
            }
        ];

        const pagination = {
            current: this.state.page,
            pageSize: this.state.pageSize,
            total: this.state.totalRow,
            showSizeChanger: true,
            onChange: this.changePage,
            onShowSizeChange: this.changePageSize
        }


        return (
            <>
                <div className='research_container' onClick={this.wrapperClick}>
                    <div className='search'>
                        <div className='search_con'>
                            <div className='search_item'>
                                <span className='label'>所属项目</span>
                                <Select
                                    showSearch
                                    placeholder="请选择所属项目"
                                    style={{ width: 280 }}
                                    allowClear
                                    value={this.state.code}
                                    onChange={value => this.setState({ code: value })}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {tenantOptions.length > 0 && tenantOptions.map(item => (
                                        <Option key={item.value} value={item.value}>{item.label}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='search_item'>
                                <span className='label'>当前状态</span>
                                <Select
                                    style={{ width: 280 }}
                                    options={typeOptions}
                                    placeholder="请选择订单状态"
                                    allowClear
                                    mode='multiple'
                                    value={this.state.processStates}
                                    onChange={value => this.setState({ processStates: value })}
                                />
                            </div>
                            <div className='search_item'>
                                <span className='label'>创建时间</span>
                                <RangePicker showTime value={createDate} onChange={this.changeDate} locale={locale} style={{ width: 280 }} />
                            </div>
                            <div className='search_item'>
                                <span className='label'>所属患者</span>
                                {/* <Select
                                    allowClear
                                    showSearch
                                    style={{ width: 280 }}
                                    labelInValue
                                    value={patientName}
                                    placeholder="请输入患者姓名搜索"
                                    notFoundContent={fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onSearch={this.fetchPatientUser}
                                    onChange={this.handlePatientChange}
                                >
                                    {patientList.map(d => (
                                        <Option key={d.value} value={d.value}>{d.text}</Option>
                                    ))}
                                </Select> */}
                                <Input
                                    allowClear
                                    style={{ width: 280 }}
                                    value={patientName}
                                    placeholder="请输入患者姓名或手机号搜索"
                                    onChange={this.handlePatientInfoChange}
                                >
                                </Input>
                                {
                                    patientList.length > 0 &&
                                    <div className='item_input_select'>
                                        {
                                            patientList.map((val, key) => (
                                                <div className='item_input_select_list' key={key} onClick={() => { this.patientClick(val) }}>
                                                    <div style={{ minWidth: '42px' }}>{val.text}</div> <div>{val.value}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                            </div>
                            <div className='search_item'>
                                <span className='label'>所属医生</span>
                                {/* <Select
                                    allowClear
                                    showSearch
                                    style={{ width: 280 }}
                                    labelInValue
                                    // value={doctorName}
                                    placeholder="请输入医生姓名搜索"
                                    notFoundContent={fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onSearch={this.fetchDoctorUser}
                                    onChange={this.handleDoctorChange}
                                >
                                    {doctorList.map(d => (
                                        <Option key={d.value} value={d.value}>{d.text}</Option>
                                    ))}
                                </Select> */}
                                <Input
                                    allowClear
                                    style={{ width: 280 }}
                                    value={doctorName}
                                    placeholder="请输入医生姓名或手机号搜索"
                                    onChange={this.handleDoctorInfoChange}
                                >
                                </Input>
                                {
                                    doctorList.length > 0 &&
                                    <div className='item_input_select'>
                                        {
                                            doctorList.map((val, key) => (
                                                <div className='item_input_select_list' key={key} onClick={() => { this.doctorClick(val) }}>
                                                    <div style={{ minWidth: '42px' }}>{val.text}</div> <div>{val.value}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                            </div>
                            <div className='search_item'>
                                <span className='label'>身份</span>
                                <Select
                                    style={{ width: 280 }}
                                    options={roleOptions}
                                    placeholder="请选择身份类型"
                                    allowClear
                                    mode='multiple'
                                    value={this.state.identityTypes}
                                    onChange={value => this.setState({ identityTypes: value })}
                                />
                            </div>
                            <div className='search_item'>
                                <span className='label'>订单号</span>
                                <Input
                                    allowClear
                                    style={{ width: 280 }}
                                    value={orderId}
                                    placeholder="请输入订单号"
                                    onChange={e => this.setState({ orderId: e.target.value })}
                                >
                                </Input>
                            </div>
                        </div>
                        <div className='search_btn'>
                            <Button type="primary" style={{ width: 88, marginRight: 6 }} onClick={this.handleSearch}>查询</Button>
                            <Button type="primary" style={{ width: 88, marginRight: 6 }} onClick={this.resetSearch}>重置</Button>
                            <Button type="primary" style={{ width: 88, marginRight: 6 }} onClick={() => { this.setState({ visible4: true }) }}>下载</Button>
                            <Button type="primary" style={{ width: 150 }} onClick={() => { this.setState({ visible5: true }) }}> 海报数据导出</Button>
                            {/* <Button type="primary" style={{ width: 88, marginRight: 6 }} onClick={this.lastPageSearch}>上一页</Button> */}
                        </div>
                    </div>

                    <div className='cpds_content'>
                        <Spin tip="数据加载中..." spinning={loading}>
                            <Table rowKey={(record) => record.id} dataSource={data} columns={columns} pagination={pagination} scroll={{ x: 'calc(700px + 50%)' }} />;
                        </Spin>
                    </div>

                </div>



                <Modal
                    visible={visible}
                    title=''
                    closable={false}
                    onCancel={() => { this.setState({ visible: false }); this.detailTimeOut && clearTimeout(this.detailTimeOut); }}
                    destroyOnClose
                    width={950}
                    className='resourceModal'
                    onOk={this.handleSubmit}
                    okText="确定"
                    // okText="处置"
                    cancelText="取消"
                >
                    <div className='modal_content'>
                        <div className='menu'>
                            <div className='title'>
                                <div className='title_left'>
                                    <img src={UserIcon} alt="" />
                                    <span>患者资料</span>
                                </div>
                                <img
                                    className='pointer'
                                    src={arrow1 ? TopArrow : BottomArrow}
                                    onClick={() => this.setState({ arrow1: !this.state.arrow1 })}
                                    alt=""
                                />
                            </div>
                            <div className='menu_content' hidden={!arrow1}>
                                <div className='info_item'>
                                    <div className='info_label widthAuto'>患者：</div>
                                    <div className='info'>{patientInfo && patientInfo.name}</div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label'>手机号：</div>
                                    <div className='info'>{patientInfo && patientInfo.phone}</div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label'>身份证：</div>
                                    <div className='info'>{patientInfo && patientInfo.idCard}</div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label widthAuto'>性别：</div>
                                    <div className='info'>{patientInfo && (patientInfo.sex == 1 ? '男' : patientInfo.sex == 2 ? '女' : '')}</div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label text_right'>年龄：</div>
                                    <div className='info'>{patientInfo && utils.getAgeByBirth(patientInfo.birthDate)}</div>
                                </div>
                            </div>
                        </div>
                        <div className='menu'>
                            <div className='title'>
                                <div className='title_left'>
                                    <img src={FileText} alt="" />
                                    <span>订单信息</span>
                                </div>
                                <img
                                    className='pointer'
                                    src={arrow2 ? TopArrow : BottomArrow}
                                    onClick={() => this.setState({ arrow2: !this.state.arrow2 })}
                                    alt=""
                                />
                            </div>
                            <div className='menu_content' hidden={!arrow2}>
                                <div className='info_item order_item'>
                                    <div className='info_label widthAuto'>订单号：</div>
                                    <div className='info'>{orderInfo && (orderInfo.orderId ? orderInfo.orderId : '暂无信息')}</div>
                                </div>
                                <div className='info_item order_item'>
                                    <div className='info_label text_right'>所属项目：</div>
                                    <div className='info'>{orderInfo && orderInfo.projectName}</div>
                                </div>
                                <div className='info_item order_item'>
                                    <div className='info_label widthAuto'>创建时间：</div>
                                    <div className='info'>{orderInfo && orderInfo.createTime.replace('T', ' ')}</div>
                                </div>
                                <div className='info_item order_item'>
                                    <div className='info_label text_right'>渠道：</div>
                                    <div className='info'>{orderInfo && orderInfo.channelName}</div>
                                </div>
                                <div className='info_item order_item'>
                                    <div className='info_label text_right'>荐方医生：</div>
                                    <div className='info'>{orderInfo && (orderInfo.recommendDoctorName + (orderInfo.identityName ? ` (${orderInfo.identityName})` : ''))}</div>
                                </div>
                                <div className='info_item order_item'>
                                    <div className='info_label text_right'>客户id：</div>
                                    <div className='info'>{orderInfo && orderInfo.doctorNo }</div>
                                </div>
                                <div className='info_item order_item'>
                                    <div className='info_label widthAuto'>建议编号：</div>
                                    <div className='info'>{orderInfo && orderInfo.orderCode}</div>
                                </div>
                                <div className='info_item order_rate'>
                                    <div className='info_label text_right'>订单进度：</div>
                                    <div className='info'>
                                        <div className='block_list'>
                                            {
                                                (orderInfo?.processVoList || []).map((val, index) =>
                                                    <div key={index} className={`block ${val ? (val.processState == '1' ? 'success' : val.processState == '2' ? 'wait' : val.processState == '4' ? 'error' : val.processState == '5' ? 'refund' : 'notStarted'
                                                    ) : 'notStarted'}`}>
                                                        <div className="time">{val ? val.duringTime : ''}</div>
                                                        <div className='name'>{processName[index]}</div>
                                                    </div>
                                                )
                                            }
                                            {/* <div className='block success '>
                                                <div className="time">1h</div>
                                                <div className='name'>荐方</div>
                                            </div>
                                            <div className='block success'>
                                                <div className="time">1h</div>
                                                <div className='name'>问诊</div>
                                            </div>
                                            <div className='block wait'>
                                                <div className="time">1h</div>
                                                <div className='name'>开方</div>
                                            </div>
                                            <div className='block error'>
                                                <div className="time">1h</div>
                                                <div className='name'>下单</div>
                                            </div>
                                            <div className='block notStarted'>
                                                <div className="time"></div>
                                                <div className='name'>支付</div>
                                            </div>
                                            <div className='block notStarted'>
                                                <div className="time"></div>
                                                <div className='name'>收货</div>
                                            </div> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='menu'>
                            <div className='title'>
                                <div className='title_left'>
                                    <img src={FileText} alt="" />
                                    <span>诊疗信息</span>
                                </div>
                                <img
                                    className='pointer'
                                    src={arrow3 ? TopArrow : BottomArrow}
                                    onClick={() => this.setState({ arrow3: !this.state.arrow3 })}
                                    alt=""
                                />
                            </div>
                            <div className='menu_content practice' hidden={!arrow3}>
                                <div className='info_item'>
                                    <div className='info_label'>诊疗时间：</div>
                                    <div className='info'>{treatmentInfo?.treatmentTime ? treatmentInfo.treatmentTime.replace('T', ' ') : ''}</div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label'>处方医生：</div>
                                    <div className='info'>
                                        互联网医院值班医生
                                        {/* {treatmentInfo?.prescriptionDoctorName} */}
                                    </div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label'>诊断：</div>
                                    <div className='info'>{treatmentInfo?.diagnosis}</div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label'>疾病史/过敏史：</div>
                                    <div className='info'>
                                        <div style={{ height: '20px' }}>
                                            {treatmentInfo?.history || '无'}
                                        </div>
                                        {
                                            treatmentInfo?.historyPicture && (treatmentInfo?.historyPicture || []).map((val, index) =>
                                            (<div key={index} style={{ height: '100px', display: 'inline-block', marginRight: '20px', marginTop: '16px' }}>
                                                {
                                                    val ?
                                                        <ConfigProvider locale={zh_CN}>
                                                            <Image src={val} height={'100%'} />
                                                        </ConfigProvider>
                                                        :
                                                        ''
                                                }
                                            </div>))
                                        }
                                    </div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label'>不良反应史：</div>
                                    <div className='info'>
                                        <div style={{ height: '20px' }}>{treatmentInfo?.aeContent || '无'}</div>
                                        {
                                            treatmentInfo?.aePicture && (treatmentInfo?.aePicture || []).map((val, index) => (
                                                <div key={index} style={{ height: '100px', display: 'inline-block', marginRight: '20px', marginTop: '16px' }}>
                                                    {
                                                        val ?
                                                            <ConfigProvider locale={zh_CN}>
                                                                <Image src={val} height={'100%'} />
                                                            </ConfigProvider>
                                                            :
                                                            ''
                                                    }
                                                </div>))
                                        }
                                    </div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label'>药品：</div>
                                    <div className='info'>
                                        {
                                            treatmentInfo?.drugs && (treatmentInfo?.drugs || []).map((val, index) => (
                                                <div key={index}>{val.drugName}  x{val.quantity}</div>
                                            ))
                                        }
                                        {
                                            (treatmentInfo?.drugs && treatmentInfo?.drugs.length == 0) && <div>暂无信息</div>
                                        }
                                    </div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label'>发货地：</div>
                                    <div className='info'>
                                        {
                                            treatmentInfo?.drugs && treatmentInfo.drugs[0] &&
                                            <div>{ treatmentInfo.drugs[0]?.storeName || '暂无信息'}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='menu'>
                            <div className='title'>
                                <div className='title_left'>
                                    <img src={FilePlus} alt="" />
                                    <span>推荐处方信息</span>
                                </div>
                                <img
                                    className='pointer'
                                    src={arrow4 ? TopArrow : BottomArrow}
                                    onClick={() => this.setState({ arrow4: !this.state.arrow4 })}
                                    alt=""
                                />
                            </div>
                            <div className='menu_content practice' hidden={!arrow4}>
                                <div className='info_item'>
                                    <div className='info_label'>药品名称：</div>
                                    <div className='info'>{recommendInfo?.drugName || '暂无信息'}</div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label'>用法：</div>
                                    <div className='info'>{recommendInfo?.useType || '暂无信息'}</div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label'>适应症：</div>
                                    <div className='info'>{recommendInfo?.adaptDisease || '暂无信息'}</div>
                                </div>
                                <div className='info_item'>
                                    <div className='info_label'>适用人群：</div>
                                    <div className='info'>{recommendInfo?.adaptMember || '暂无信息'}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>

                <Modal
                    visible={visible2}
                    title=''
                    closable={false}
                    onCancel={() => this.setState({ visible2: false })}
                    destroyOnClose
                    // centered
                    width={950}
                    className='order_record_modal_wrap'
                    footer={
                        <div className='modal_footer'>
                            <Button type='link' onClick={() => { this.setState({ visible2: false }) }}>关闭</Button>
                            <Button type='primary' ghost onClick={() => { this.setState({ visible: true, visible2: false }) }}>返回</Button>
                            <Button type='primary' onClick={() => { this.setState({ visible: false, visible2: false }) }}>确定</Button>
                        </div>}
                    okText="确定"
                    cancelText="取消"
                >
                    <div className='modal_content'>
                        <div className='title'>
                            <InfoCircleOutlined style={{ 'color': '#1890ff', 'fontSize': '20px' }} />
                            <span className='title_text'>请选择干预结果</span>
                        </div>
                        <div className='item'>
                            <span className='item_left'>当前节点：</span>
                            <span>问诊</span>
                        </div>
                        <div className='item'>
                            <span className='item_left'>干预目标：</span>
                            <Radio.Group onChange={(e) => { this.radioOnChange(e, 'radio1') }} value={radio1}>
                                <Radio value={1}>完成问诊</Radio>
                                <Radio value={2}>完成付款</Radio>
                                <Radio value={3}>其他</Radio>
                            </Radio.Group>
                        </div>
                        <div className='item'>
                            <span className='item_left'>干预方式：</span>
                            <Radio.Group onChange={(e) => { this.radioOnChange(e, 'radio2') }} value={radio2}>
                                <Radio value={1}>电话干预</Radio>
                                <Radio value={2}>放弃</Radio>
                            </Radio.Group>
                        </div>
                        <div className='item'>
                            <span className='item_left'>干预结果：</span>
                            <Radio.Group onChange={(e) => { this.radioOnChange(e, 'radio3') }} value={radio3}>
                                <Radio value={1}>成功</Radio>
                                <Radio value={2}>失败</Radio>
                                <Radio value={3}>未接听</Radio>
                            </Radio.Group>
                        </div>
                        <div className='item'>
                            <span className='item_left'>干预内容：</span>
                            <TextArea className='item_right' onChange={(e) => { this.textAreaOnChange(e) }} style={{ resize: 'none' }} placeholder="在此处输入干预内容" maxLength={500} ></TextArea>
                        </div>
                    </div>
                </Modal>

                <Modal
                    visible={visible3}
                    closable={true}
                    onCancel={() => this.setState({ visible3: false })}
                    destroyOnClose
                    centered
                    width={950}
                    className='order_record_modal_wrap order_record_progress_wrap'
                    footer={null}
                >
                    <div className='modal_content'>
                        <div className='title'>
                            {/* <FileTextOutlined style={{'color': '#1890ff', 'fontSize': '20px'}} />  */}
                            <img src={EditFileText} alt=''></img>
                            <span className='proress_title_text'>共<span style={{ color: '#1890ff' }}> 10 </span>条干预结果</span>
                        </div>
                        <div className='item'>
                            <div className='line_wrap'>
                                <div className='dot'></div>
                                <div className='line'></div>
                            </div>
                            <div className='item_left'>
                                <div className='date'> 03-30 </div>
                                <div className='time'> 10:05:08 </div>
                            </div>
                            <div className='item_right'>
                                <div>
                                    <div className='r_t'>干预节点：问诊</div>
                                </div>
                                <div>
                                    <div className='r_l'></div>
                                    <div className='r_m'>干预目标</div>
                                    <div className='r_r'>已完成问诊</div>
                                </div>
                                <div>
                                    <div className='r_l'></div>
                                    <div className='r_m'>干预方式</div>
                                    <div className='r_r'>放弃</div>
                                </div>
                                <div>
                                    <div className='r_l'></div>
                                    <div className='r_m'>干预结果</div>
                                    <div className='r_r'>失败</div>
                                </div>
                                <div>
                                    <div className='r_b_l'></div>
                                    <div className='r_b_m'>
                                        干预内容干预内容干预内容干预内容干预内容干预内容干预内容
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='line_wrap'>
                                <div className='dot'></div>
                                <div className='line'></div>
                            </div>
                            <div className='item_left'>
                                <div className='date'> 03-30 </div>
                                <div className='time'> 10:05:08 </div>
                            </div>
                            <div className='item_right'>

                            </div>
                        </div>
                        <div className='item'>
                            <div className='line_wrap'>
                                <div className='dot'></div>
                                <div className='line'></div>
                            </div>
                            <div className='item_left'>
                                <div className='date'> 03-30 </div>
                                <div className='time'> 10:05:08 </div>
                            </div>
                            <div className='item_right'></div>
                        </div>
                        <div className='item'>
                            <div className='line_wrap'>
                                <div className='dot'></div>
                            </div>
                            <div className='item_left'>
                                <div className='date'> 03-30 </div>
                                <div className='time'> 10:05:08 </div>
                            </div>
                            <div className='item_right'></div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title="请选择时间"
                    visible={visible4}
                    onOk={this.handleDownload}
                    onCancel={() => { this.setState({ visible4: false, downloading: false }) }}
                    confirmLoading={this.state.downloading}
                    okText='确定下载'
                    cancelText='取消'
                    destroyOnClose
                >
                    <div>
                        <span className='label'>创建时间 </span>
                        <RangePicker showTime  value={downloadDate}  onChange={this.changeDownloadDate} locale={locale} style={{ width: 350 }} />
                    </div>
                </Modal>
                <Modal
                    title="海报数据导出"
                    visible={visible5}
                    onOk={this.handlePosterDownload}
                    onCancel={() => { this.setState({ visible5: false, downloading: false }) }}
                    confirmLoading={this.state.downloading}
                    okText='确定导出'
                    cancelText='取消'
                    destroyOnClose
                >
                    <div>
                        确定导出海报数据？
                    </div>
                </Modal>
                
                <Modal
                    title="选择坐席"
                    className='order_record_vr_modal_wrap'
                    visible={vrVisible}
                    onOk={this.vrFollowConfirm}
                    confirmLoading={this.state.vrLoading}
                    onCancel={() => { this.setState({ vrVisible: false }) }}
                    okText='确定'
                    cancelText='取消'
                    centered
                    destroyOnClose
                >
                    <div>
                        <span className='label'>选择坐席： </span>
                        <Select
                            style={{ width: 280 }}
                            options={vrOptions}
                            placeholder="请选择"
                            allowClear
                            value={this.state.vrValue}
                            onChange={value => this.setState({ vrValue: value })}
                        />
                    </div>
                </Modal>
                <Modal
                    title="VR跟进明细"
                    width={950}
                    className='order_record_vr_modal_wrap'
                    visible={this.state.vrDetailVisible}
                    onCancel={() => { this.setState({ vrDetailVisible: false }) }}
                    footer={null}
                    centered
                    destroyOnClose
                >
                    <div>
                        <Spin tip="数据加载中..." spinning={Object.keys(this.state.nowRecord).length == 0}>
                            <Table rowKey={(record) => record.id} columns={vrDetailCol} dataSource={[this.state.nowRecord]} pagination={null} />
                        </Spin>
                    </div>
                </Modal>
            </>
        )

    }

}

export default Research