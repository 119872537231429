/**
 * 模态框
 * 
 * this.props.btnType: 1-病史备注, 2-受试者暂停, 3-事件登记
 * 
 */
import React, { Component } from 'react';
import { Modal, Button, message } from 'antd';
import { createFromIconfontCN } from '@ant-design/icons';
import axios from 'axios';
import './modalOperate.less';
import StopSubject from './stopSubject/stopSubject';
import Disease from './disease/disease';
import EventRegister from './eventRegister/eventRegister';
// import {subjectStop, subjectSuspend, subjectCancel} from '../../../../../../services/data/DataService';

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

class ModalOperate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stopType: 0, // 默认暂停类型
            modalTitle: '', // 模态框标题
        }
    }

    componentWillMount() {
        let { btnType } = this.props;
        if (btnType == 1) {
            this.setState({
                modalTitle: '病史备注',
            })

        } else if (btnType == 2) {
            this.setState({
                modalTitle: `${sessionStorage.getItem('targetName') || '受试者'}暂停研究`,
            })
        } else if (btnType == 3) {
            this.setState({
                modalTitle: '事件登记',
            })
        }
    }

    /**
     * 关闭模态框
     */
    handleCancel = (v) => {
        this.props.closeModal(v);
    };

    render() {
        let { stopType, modalTitle, submitBtn } = this.state;
        let { visible, btnType } = this.props;

        return (
            <>
                <Modal
                    className={'operatorModal'}
                    visible={visible}
                    title={modalTitle}
                    onCancel={this.handleCancel}
                    width={'50%'}
                    centered
                    destroyOnClose
                    footer={null}
                >
                    {btnType == 1 && <Disease />}
                    {btnType == 2 && <StopSubject enableCancel={this.props.enableCancel} subjectCode={this.props.subjectCode} handleCancel={(v) => this.handleCancel(v)} />}
                    {btnType == 3 && <EventRegister />}
                </Modal>
            </>
        );
    }
}

export default ModalOperate;