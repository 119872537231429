import { requestGetRes, requestPostRes } from "../EdcService";

export type PaginateResult<T> = {
  code: number
  message?: string
  data: {
    page: number
    pageSize: number
    total: number
    rows: T[];
  }
}

export type Result<T> = {
  code: number
  message?: string
  data: T
}

//
// 获取区域
// 
export type AreaResult = {
  code: string
  name: string
  label?: string
  value?: string
  children: AreaResult[]
}
export const getAreaOptions = (projectCode: string)=> {
  return requestPostRes(`/project/dashboard/area/${projectCode}`) as Promise<Result<AreaResult[]>>
}

//
// 获取中心列表
// 
export type HospitalResult = {
  code: string
  name: string
}
export const getHospitalOptions = (projectCode: string, areaCodes: string[] = [])=> {
  return requestPostRes(`/project/dashboard/center/${projectCode}`, { areaCodes }) as Promise<Result<HospitalResult[]>>
}

//
// 获取中心列表
// 
export type DoctorResult = {
  code: string
  name: string
}
export const getDoctorOptions = (projectCode: string, areaCodes: string[] = [], centerCode = '')=> {
  return requestPostRes(`/project/dashboard/doctor/${projectCode}`, { areaCodes, centerCode}) as Promise<Result<DoctorResult[]>>
}

//
// 获取患者治疗阶段
//
export type StageResult = {
  code: string
  name: string
}
export const getStageOptions = ()=> {
  return requestGetRes('/data/dashboard/stage') as Promise<Result<StageResult[]>>
}

//
// 获取数据更新时间
//
export const getUpdateDeadline = ()=> {
  return requestGetRes('/data/dashboard/last/time') as Promise<Result<string>>
}

// 数据列表 患者层面
export type QueryParams = {
  areaCodes?: string[]
  centerCode?: string
  doctorCode?: string
  stage?: number
  subjectCode?: string
}
export type SubjectResult = {
  approve: number
  approveAgreeRate: number
  approveFinishRate: number
  approveWait: number
  areaName: string
  baseRate: number
  baseRequired: number
  baseRequiredImage: number
  centerName: string
  doctorName: string
  image: number
  imageAgree: number
  imageApprove: number
  imagePage: number
  imagePageAgree: number
  imagePageApprove: number
  imagePageRate: number
  imageRate: number
  join: string
  pageRate: number
  replenish: number
  replenishFinish: number
  replenishRate: number
  required: number
  requiredFinish: number
  requiredRate: number
  stageDetail: string
  subjectCode: string
  visitRate: number
}
export const getSubjectPaginate = (page: number, pageSize: number, projectCode: string, payload: Partial<QueryParams>)=> {
  return requestPostRes(`/data/dashboard/subject/${projectCode}`, {
    ...payload,
    page,
    pageSize
  }) as Promise<PaginateResult<SubjectResult>>
}

// 数据列表 患者层面
export type PageResult = {
  approve: number
  approveAgreeRate: number
  approveFinishRate: number
  approveWait: number
  areaName: string
  baseRate: number
  baseRequired: number
  baseRequiredImage: number
  centerName: string
  doctorName: string
  image: number
  imageAgree: number
  imageApprove: number
  imagePage: number
  imagePageAgree: number
  imagePageApprove: number
  imagePageRate: number
  imageRate: number
  join: string
  pageRate: number
  replenish: number
  replenishFinish: number
  replenishRate: number
  required: number
  requiredFinish: number
  requiredRate: number
  stageDetail: string
  subjectCode: string
  visitRate: number
}
export const getPagePaginate = (page: number, pageSize: number, projectCode: string, payload: Partial<QueryParams>)=> {
  return requestPostRes(`/data/dashboard/page/${projectCode}`, {
    ...payload,
    page,
    pageSize
  }) as Promise<PaginateResult<PageResult>>
}