/**
 * 知识库相关
 */

import KnowledgeTagList from "../components/content/tags";

const routes = [
    { 'path': '/tag/lists', 'component': KnowledgeTagList },
];

export default routes
