import React, { Fragment, useEffect, useMemo, useRef, useState } from "react"

import { Button, Empty, Modal, Table } from "antd"
import indexStyl from './index.module.scss'
import Condition from "./components/condition"
import { ColumnsType } from "antd/lib/table"
import EditModal from "./components/edit-modal"
import CreateModal from "./components/create-modal"
import { DataType, deleteLabel, getLabelList, LabelListParams, labelTypeTransform } from "../../../services/knowledge/label"

const KnowledgeTagList = ()=> {
  const [record, setRecord] = useState<DataType>()
  // 详情
  const [visible, setVisible] = useState(false)
  // 
  const [createVisible, setCreateVisible] = useState(false)
  
  const [pageSize] = useState(3)
  const [pageNum, setPageNum] = useState(1)
  
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  
  const conditionRef = useRef<LabelListParams>({
    name: '',
  })

  const [dataSource, setDataSource] = useState<DataType[]>([])

  const openEditModal = (record: DataType)=> {
    setRecord(record)
    setVisible(true)
  }

  const openCreateModal = (record?: DataType)=> {
    setRecord(record)
    setCreateVisible(true)
  }
  
  const removeItem = (record: DataType)=> {
    Modal.confirm({
      title: '确定删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: async ()=> {
        const {code} = await deleteLabel(record.id)
        if(code === 0) {
          reload(true)
        }
      }
    })
  }

  //#region 表格列
  const columns: ColumnsType<DataType> = [
    {
      width: 120,
      title: '标签名称',
      ellipsis: true,
      dataIndex: 'name',
      render: (text: string) => <a>{text}</a>,
    },
    {
      width: 120,
      title: '标签层级',
      dataIndex: 'labelLevel',
      render: (text: number)=> `${text === 1 ? '一级' : '二级'}标签`
    },
    {
      width: 120,
      title: '标签类型',
      dataIndex: 'labelType',
      render: (text: number)=> labelTypeTransform(text)
    },
    {
      width: 160,
      title: '创建人',
      dataIndex: 'createUserName',
    },
    {
      width: 180,
      title: '操作',
      key: 'action',
      render: (_: any, record: DataType) => (
        <Fragment>
          <Button onClick={()=> openEditModal(record)} type="primary" size="small">编辑</Button>
          <Button onClick={()=> removeItem(record)} type="ghost" danger size="small" style={{margin: '0 8px'}}>删除</Button>
          {
            record.labelLevel === 1 && (
              <Button onClick={()=> openCreateModal(record)} type="ghost" size="small">新增子级</Button>
            )
          }
        </Fragment>
      ),
    },
  ];
  //#endregion

  const fetchData = async (params: LabelListParams)=> {
    setLoading(true)
    const {code, data} = await getLabelList(params, pageNum, pageSize)
    setLoading(false)
    if(code === 0) {
      setTotal(data.total)
      setDataSource(data.rows.map((t)=> ({...t,key: t.id})))
    }
  }

  const onSearch = (params: LabelListParams)=> {
    setPageNum(1)
    conditionRef.current = params
    // 修复当前页面为第1页面不自动刷新问题
    if(pageNum == 1) {
      fetchData(conditionRef.current)
    }
  }


  const reload = (refresh: boolean)=> {
    setVisible(false)
    setCreateVisible(false)
    if (!refresh) {
      return
    }
    if(pageNum === 1) {
      fetchData(conditionRef.current)
    } else {
      setPageNum(1)
    }
  }

  useEffect(()=> {
    fetchData(conditionRef.current)
  }, [pageNum])

  return (
    <Fragment>
    <div className={indexStyl.wrapper}>
      <Condition onSearch={onSearch}/>
      <div className={indexStyl.toolbar}>
        <Button type="primary" onClick={()=> openCreateModal()}>新增</Button>
      </div>
      <div className={indexStyl.tableWrapper}>
        <Table
          loading={loading}
          size="middle"
          rowKey="id"
          scroll={{y: window.innerHeight - 350}}
          columns={columns}
          dataSource={dataSource}
          locale={{emptyText: <Empty description="暂无数据"/>}}
          pagination={{
            total: total,
            current: pageNum,
            pageSize: pageSize,
            onChange: (page: number)=> setPageNum(page),
            showSizeChanger: false,
            showQuickJumper: false,
            showTotal: total=> `共${total}条，每页${pageSize}条`,
            position: ["bottomRight"]}}/>
      </div>
    </div>
    {
      visible && record && (
        <EditModal record={record} onClose={reload}/>
      )
    }
    {
      createVisible && (
        <CreateModal record={record} onClose={reload}/>
      )
    }
    </Fragment>
  )
}

export default KnowledgeTagList
