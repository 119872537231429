import React, {Fragment} from 'react';
import {Redirect, RouteChildrenProps} from 'react-router-dom';
import { RedirectProps } from 'react-router'

type State = {
    id: number
    code?: string
    path: string
}
const EmptyPage: React.FC<RouteChildrenProps<any, State>> = ({ location })=> {
    const { state } = location
    return (
        <Fragment>
            <Redirect to={{ pathname: state.path, state }}/>
        </Fragment>
    )
}
export default EmptyPage;