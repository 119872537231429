import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import formAction from '../../utils/formAction';
import mExper from '../../utils/operation.ts';


function DecimalCom(props: any, ref: any) {
    const [value, setValue] = useState<any>();
    const [data, setData] = useState<any>();
    const [formId, setFormId] = useState<any>();
    const [timeObject, setTimeObject] = useState<any>();
    const [savedValues, setSavedValues] = useState<any>([]);
    const [updatedValue, setUpdatedValue] = useState<any>();

    useEffect(() => {
        if (props.data) {
            //获取当前表单的id
            setFormId(props.formId);
            //该条表单元素的数据
            setData(props.data);
            //已经保存的表单数据
            setSavedValues(props.savedValues);

            //设置当前表单已存的value
            let currentItem = props.savedValues.find((i: any) => {
                return i.dataCode == props.data.dataCode
            });
            if (currentItem) {
                setValue(currentItem.value);
                setUpdatedValue(currentItem.value);
            } else {
                setValue(null);
                setUpdatedValue(null);
            }
            if (props?.data?.conditionalValues?.length) {
                let result = mExper.setConditionalValue(props.data.conditionalValues, {
                    savedValues: props.savedValues,
                    data: props.data
                });

                if (result) {
                    setValue(result)
                    let values = [
                        {
                            dataCode: props.data.dataCode,
                            deCode: props.data.deCode,
                            type: props.data.type,
                            value: result
                        }
                    ]
                    saveData(formId, values);
                }
            }

            if (props.formId && props.data && props.data.logicOperations && props.data.logicOperations.length > 0) {
                for (let logicOperation of props.data.logicOperations) {
                    let result = mExper.handleLogicOperations(logicOperation, {
                        savedValues: props.savedValues,
                        data: props.data,
                        propsData: props.propsData
                    });
                    if (logicOperation.type == 'dynamicValue' && !props.data.isAdd) {
                        continue;
                    }
                    if (!result || result == "null" || result == value) {
                        continue;
                    }
                    setValue(result);
                    let values = [
                        {
                            dataCode: props.data.dataCode,
                            deCode: props.data.deCode,
                            type: props.data.type,
                            value: result
                        }
                    ]
                    saveData(formId, values);
                }
            }

        }

        // console.log('hideprops', props.savedValues);
    }, [props])


    // 设置(跨表单)初始值
    useEffect(() => {
        if (props.data && props.formId && props.propsData) {
            if (props.formId && props.data && props.data.logicOperations && props.data.logicOperations.length > 0) {
                for (let logicOperation of props.data.logicOperations) {
                    if (logicOperation.type == 'globalValue') {
                        let currentVisitInfo: any = sessionStorage.getItem('currentVisitInfo') || ''
                        if (!logicOperation.visit && currentVisitInfo) {
                            logicOperation.visit = JSON.parse(currentVisitInfo).name
                        }

                        let result = mExper.singleHandleGlobalValue(logicOperation, {
                            savedValues: savedValues,
                            data: props.data,
                            propsData: props.propsData
                        });
                        if (!result || result == "null" || result == value) {
                            continue;
                        }
                        setValue(result);
                        let values = [
                            {
                                dataCode: props.data.dataCode,
                                deCode: props.data.deCode,
                                type: props.data.type, //带有hide标签的表单项，传递type给后端，
                                value: result
                            }
                        ]
                        saveData(formId, values);
                    }
                }
            }
        }
    }, [props.data, props.formId, props.propsData])


    //暂存数据
    const saveData = (formId: any, values: any) => {
        let params = {
            formId: formId || props.formId,
            submit: false,
            values: values
        }
        formAction.saveData(params);

        props.updateFun()

    }

    useImperativeHandle(ref, () => ({
        // changeVal 就是暴露给父组件的方法
        getModifyValue: () => {
            let _data = data || props.data;
            let params = {
                dataCode: _data.dataCode,
                deCode: _data.deCode,
                formId: formId || props.formId,
                value: updatedValue,
            }
            return params;
        },
    }));



    return (
        <div className="decimalCom" hidden={true}></div>
    )
}

export default forwardRef(DecimalCom);
