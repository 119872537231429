import React, {Component} from 'react';
import './metaManagementDetail.less';
import {withRouter} from 'react-router-dom';
import {Tree, Select, Menu, Tabs, Modal} from 'antd';
import {createFromIconfontCN, ExclamationCircleOutlined} from '@ant-design/icons';
import {
    GetDataThemeInfo,
    GetDataThemeDirectoryTreeList,
    GetDataThemeDirectoryDel,
    GetDataElementDel
} from '../../../../services/config/ConfigService'
import {clearThreeTitle} from '../../../../util/menuModuleUtil';
import DataThemTreeDetail from './dataThemTreeDetail/dataThemTreeDetail'
import DataCatalogDetail from './dataCatalogDetail/dataCatalogDetail'

const {DirectoryTree} = Tree;
const {TreeNode} = Tree;
let treeExpandKey = '';
const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 19,
    },
};
const {confirm} = Modal;
const {Option} = Select
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2343612_4qmxqp0jbfl.js',
    ],
});
const {TabPane} = Tabs;

class MetaManagementDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            treeData: [], // 树 数据集合
            infoData: {
                /*name: '',
                mark: '',
                category: '',
                scope: '',
                type: '',
                state: '',*/
            }, // 详情 信息集合
            rightClickNodeTreeItem: {}, // 当前右击 节点的信息
            currentSelectNodeInfo: {}, // 当前右击 节点的信息
            treeNodeOperationType: '', // 树节点 操作类型
            treeNodeType: '', // 树节点 类型 目录catalog or 元elem
            treeSelectedKeys: [''], // 树节点 选中的key值
            isFirst: true, // 设置是否为第一次状态
            expandedKeys: null, // 指定展开的树节点
            cedeModalIsShow: false,// 菜单 功能 显 隐
            operationTitle: '主题数据设置', // 树节点 不同操作 title名称
            operationTypeFlag: true, // 树节点 点击不同节点
            operationTypeFlag2: false, // tabs 切换的时候 flag
            tabsSelectKeys: '1', // tabs 切换选中的 key值
        };
    }

    componentDidMount() {
        this.getDataThemeInfo()
        this.getDataThemeDirectoryTreeList()
    }

    componentWillUnmount() {
        clearThreeTitle()
    }

    menuRef = React.createRef()

    /**
     * 详情 接口
     */
    getDataThemeInfo = () => {
        GetDataThemeInfo(this.props.location.state.id).then(res => {
            if (res) {
                /*const {
                  name,
                  mark,
                  category,
                  scope,
                  type,
                  state,
              } = res*/
                this.setState({
                    infoData: res
                })
            }
        })
    }

    //设置默认展开&&默认选中的节点
    setDefaultKeys = (data) => {
        let expandedKeys = [];
        let selectedKeys = [];
        let selectedNode;
        if (data && data.length > 0) {
            expandedKeys.push(data[0].key);
            if (data[0].children && data[0].children.length > 0) {
                let judgeIsOut = false;
                let filterData = (dataItem) => {
                    for (let item of dataItem) {
                        if (item.children && item.children.length > 0) {
                            if (item.isLeaf) {
                                selectedKeys = [item.key];
                                selectedNode = item;
                                judgeIsOut = true;
                            } else {
                                expandedKeys.push(item.key);
                                filterData(item.children);
                            }
                        } else {
                            if (item.isLeaf) {
                                selectedKeys = [item.key];
                                selectedNode = item;
                                judgeIsOut = true;
                            }
                        }
                        if (judgeIsOut) {
                            return;
                        }
                    }
                }
                filterData(data[0].children);
            }
            if (selectedKeys.length == 0) {
                // 如果没有 叶子 则选中第一级 并且 是添加
                this.setState({
                    expandedKeys,
                    treeSelectedKeys: [data[0].key],
                    currentSelectNodeInfo: data[0],
                    treeData: data,
                    isFirst: false,
                    treeNodeType: 'catalog',
                    treeNodeOperationType: 'add',
                    operationTitle: '添加数据主题目录'
                })
            } else {
                this.setState({
                    expandedKeys,
                    treeSelectedKeys: selectedKeys,
                    currentSelectNodeInfo: selectedNode,
                    treeData: data,
                    isFirst: false,
                    treeNodeType: selectedKeys.length != 0 ? 'elem' : 'catalog',
                    treeNodeOperationType: 'modify',
                    operationTitle: `数据元数据设置(${selectedNode.title}):`,
                    operationTypeFlag: false
                })
            }
        }
    }

    /**
     * 树 展开节点
     */
    onExpand = (keys) => {
        this.setState({
            expandedKeys: [...keys]
        })
    };

    /**
     * 查询 树 info
     */
    getDataThemeDirectoryTreeList = () => {
        GetDataThemeDirectoryTreeList(this.props.location.state.id).then((res) => {
            if (res.code == 0) {
                if (res.data && res.data.length != 0) {
                    let c = this.treeMapList(res.data);
                    if (this.state.isFirst) {
                        this.setDefaultKeys(c)
                    } else {
                        this.setState({
                            treeData: c,
                            expandedKey: treeExpandKey,
                        })
                    }
                } else {
                    this.setState({
                        treeData: [],
                    })
                }
            }
        })
    }

    /**
     * 树 映射
     * @param values
     */
    treeMapList = (data) => {
        let b = [];
        data.forEach(i => {
            let c = {};
            if (i.parentId == '0') {
                treeExpandKey = i.key;
                c.icon = i.parentId === 0 ? <IconFont type="icondatabase"></IconFont> :
                    <React.Fragment></React.Fragment>;
            }
            c.id = i.id;
            c.key = i.key;
            c.title = i.name
            c.isLeaf = i.directoryFlag ? false : true
            c.parentId = i.parentId
            c.directoryFlag = i.directoryFlag
            if (i.children && i.children.length != 0) {
                c.children = this.treeMapList(i.children)
            } else {
                c.children = []
            }
            b.push(c)
        })
        return b
    }

    /**
     * 树 节点 选中
     */
    handleTreeSelect = (selectedKeys, info) => {
        if (info.selectedNodes[0]) {
            /*console.log(selectedKeys, info.selectedNodes[0])*/
            this.setState({
                rightClickNodeTreeItem: {},
                currentSelectNodeInfo: info.selectedNodes[0],
                treeNodeOperationType: info.selectedNodes[0].directoryFlag ? 'add' : 'modify',
                treeNodeType: info.selectedNodes[0].directoryFlag ? 'catalog' : 'elem',
                treeSelectedKeys: selectedKeys,
                operationTitle: info.selectedNodes[0].directoryFlag ? '添加数据主题目录' : `数据元数据设置(${info.selectedNodes[0].title}):`,
                operationTypeFlag: info.selectedNodes[0].directoryFlag ? true : false,
                operationTypeFlag2: false
            });
        }
    };

    /**
     * 树 节点 右点击选中
     */
    handleRightClick = (e) => {
        /*console.log('右击事件', e.event)
        console.log('右击节点信息', e.node)*/
        this.setState({
            rightClickNodeTreeItem: {
                clientX: e.event.clientX,
                clientY: e.event.clientY,
                id: e.node.id,
                key: e.node.key,
                categoryName: e.node.title,
                currentTreeNodeInfo: e.node,
            },
            treeNodeOperationType: e.node.directoryFlag ? 'add' : 'modify',
            treeNodeType: e.node.directoryFlag ? 'catalog' : 'elem',
            treeSelectedKeys: [e.node.key],
            currentSelectNodeInfo: e.node,
            cedeModalIsShow: true,
            operationTitle: e.node.directoryFlag ? e.node.parentId == 0 ? '添加数据主题目录' : '修改数据主题目录' : `数据元数据设置(${e.node.title}):`,
            operationTypeFlag: e.node.directoryFlag ? true : false,
            operationTypeFlag2: false
        });
    }

    /**
     * 树 节点 右点击选中
     */
    getNodeTreeRightClickMenu = () => {
        const {clientX, clientY} = {...this.state.rightClickNodeTreeItem};
        const treeNodeType = this.state.treeNodeType;
        const left = clientX;
        const top = clientY;
        const tmpStyle = {
            position: 'absolute',
            left: `${left}px`,
            top: `${top}px`,
            zIndex: '1',
            /*display: `${treeNodeTypeFlag == 'catalog' ? 'block' : 'none'}`*/
        };
        const menu = (
            <Menu
                style={tmpStyle}
                ref={this.menuRef}
                selectedKeys={''}
            >
                <Menu.Item
                    key='add'
                    style={{display: `${treeNodeType == 'catalog' ? 'block' : 'none'}`}}
                    onClick={this.handleTreeNodeCatalogAdd}
                >
                    <IconFont type="iconaddfolder"></IconFont>
                    {'添加数据主题目录'}
                </Menu.Item>
                <Menu.Item
                    key='addChildren'
                    style={{display: `${treeNodeType == 'catalog' ? 'block' : 'none'}`}}
                    onClick={this.handleTreeNodeElemAdd}
                >
                    <IconFont type="iconaddFile"></IconFont>
                    {'添加数据元数据'}
                </Menu.Item>
                <Menu.Item
                    key='modifyCatalog'
                    style={{
                        display: `${treeNodeType == 'catalog' ? this.state.currentSelectNodeInfo.parentId == 0 ? 'none' : 'block' : 'none'}`
                    }}
                    onClick={this.handleTreeNodeCatalogModify}
                >
                    <IconFont type="iconedit"></IconFont>
                    {'修改数据主题目录'}
                </Menu.Item>
                <Menu.Item
                    key='modifyElem'
                    style={{display: `${treeNodeType == 'elem' ? 'block' : 'none'}`}}
                    onClick={this.handleTreeNodeElemModify}
                >
                    <IconFont type="iconedit"></IconFont>
                    {'修改数据元数据'}
                </Menu.Item>
                <Menu.Item
                    key='delCatalog'
                    style={{
                        display: `${treeNodeType == 'catalog' ? this.state.currentSelectNodeInfo.parentId == 0 ? 'none' : 'block' : 'none'}`
                    }}
                    onClick={this.handleTreeNodeCatalogDel}
                >
                    <IconFont type="icondelete"></IconFont>
                    {'删除数据主题目录'}
                </Menu.Item>
                <Menu.Item key='delElem' style={{display: `${treeNodeType == 'elem' ? 'block' : 'none'}`}}
                           onClick={this.handleTreeNodeElemDel}
                >
                    <IconFont type="icondelete"></IconFont>
                    {'删除数据元数据'}
                </Menu.Item>
            </Menu>
        );
        return (Object.keys(this.state.rightClickNodeTreeItem).length == 0) ? '' : menu;
    }

    /**
     * 点击 树模块 隐藏 目录操作按钮
     */
    handleTreeNodeTypeFlag = (e) => {
        e.stopPropagation()
        this.setState({
            /*treeNodeType: '',*/
            cedeModalIsShow: false,
        })
    }

    /**
     * 树 节点 添加目录
     */
    handleTreeNodeCatalogAdd = (e) => {
        console.log(e)
        this.setState({
            rightClickNodeTreeItem: {},
            treeNodeType: 'catalog',
            treeNodeOperationType: 'add',
            cedeModalIsShow: false,
            operationTitle: '添加数据主题目录',
            operationTypeFlag: false,
            operationTypeFlag2: false
        });
    }

    /**
     * 树 节点 添加子元节点
     */
    handleTreeNodeElemAdd = () => {
        this.setState({
            rightClickNodeTreeItem: {},
            treeNodeType: 'elem',
            treeNodeOperationType: 'add',
            cedeModalIsShow: false,
            operationTitle: '添加数据元数据',
            operationTypeFlag: false,
            operationTypeFlag2: false
        });
    }

    /**
     * 树 节点 修改子元节点
     */
    handleTreeNodeElemModify = () => {
        this.setState({
            rightClickNodeTreeItem: {},
            treeNodeType: 'elem',
            treeNodeOperationType: 'modify',
            cedeModalIsShow: false,
            operationTitle: '修改数据元数据',
            operationTypeFlag: false,
            operationTypeFlag2: false
        });
    }

    /**
     * 树 节点 修改目录
     */
    handleTreeNodeCatalogModify = (e) => {
        this.setState({
            rightClickNodeTreeItem: {},
            treeNodeType: 'catalog',
            treeNodeOperationType: 'modify',
            cedeModalIsShow: false,
            operationTitle: '修改数据主题目录',
            operationTypeFlag: false,
            operationTypeFlag2: false
        });
    }

    /**
     * 树 节点 删除目录
     */
    handleTreeNodeCatalogDel = (e) => {
        let that = this;
        confirm({
            title: '确定删除此数据主题目录吗？',
            icon: <ExclamationCircleOutlined/>,
            okText: '确认',
            cancelText: '取消',
            content: '',
            centered: true,
            onOk: () => {
                GetDataThemeDirectoryDel(that.state.rightClickNodeTreeItem.id).then(res => {
                    if (res) {
                        that.setState({
                            rightClickNodeTreeItem: {},
                            treeNodeType: '',
                            treeNodeOperationType: '',
                            cedeModalIsShow: false,
                            operationTitle: '未选择数据主题目录'
                        }, () => {
                            that.getDataThemeDirectoryTreeList()
                        });
                    }
                })
            },
            onCancel: () => {
                that.closeModal();
            },
        });
    }

    /**
     * tab 切换
     */
    handleTabChange = (key) => {
        if (key == 1) {
            this.setState({
                rightClickNodeTreeItem: {},
                treeNodeType: 'catalog',
                treeNodeOperationType: 'add',
                cedeModalIsShow: false,
                operationTypeFlag: false,
                operationTypeFlag2: true,
                tabsSelectKeys: key
            });
        } else {
            this.setState({
                rightClickNodeTreeItem: {},
                treeNodeType: 'elem',
                treeNodeOperationType: 'add',
                cedeModalIsShow: false,
                operationTypeFlag: false,
                operationTypeFlag2: true,
                tabsSelectKeys: key
            });
        }
    }

    /**
     * 点击 关闭 modal
     */
    closeModal = () => {
        let modalEle = document.getElementById('modal');
        if (modalEle) {
            modalEle.style.display = 'none';
        }
    }

    /**
     * 树 节点 删除编码
     */
    handleTreeNodeElemDel = () => {
        let that = this;
        confirm({
            title: '确定删除此数据元数据吗？',
            icon: <ExclamationCircleOutlined/>,
            okText: '确认',
            cancelText: '取消',
            content: '',
            centered: true,
            onOk: () => {
                GetDataElementDel(that.state.rightClickNodeTreeItem.id).then(res => {
                    if (res) {
                        that.setState({
                            rightClickNodeTreeItem: {},
                            treeNodeType: '',
                            treeNodeOperationType: '',
                            cedeModalIsShow: false,
                            operationTitle: '未选择数据主题'
                        }, () => {
                            that.getDataThemeDirectoryTreeList()
                        });
                    }
                })
            },
            onCancel: () => {
                that.closeModal();
            },
        });
    }

    render() {
        return (
            <div className={'metaManagementDetail'}>
                <div className={'metaManagementDetailCon'}>
                    <div className={'detail_title'}>
                        <h4>数据主题详情</h4>
                        <div className={'info'}>
                            <div>
                                <div>
                                    <span>主题名称：</span>
                                    {this.state.infoData.name}
                                </div>
                                <div>
                                    <span>主题类别：</span>
                                    {this.state.infoData.category}
                                </div>
                                <div>
                                    <span>主题编码：</span>
                                    {this.state.infoData.code}
                                </div>
                                <div>
                                    <span>创建时间：</span>
                                    {this.state.infoData.createTime}
                                </div>
                                <div>
                                    <span>创建人：</span>
                                    {this.state.infoData.createUserName}
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span>主题标识：</span>
                                    {this.state.infoData.mark}
                                </div>
                                <div>
                                    <span>状态：</span>
                                    {this.state.infoData.stateName}
                                </div>
                                <div>
                                    <span>适用范围：</span>
                                    {this.state.infoData.scope}
                                </div>
                                <div>
                                    <span>类型：</span>
                                    {this.state.infoData.typeName}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'edit_con'}>
                        <div className={'edit_left'}>
                            <div className={'title'}>
                                目录
                            </div>
                            <div className={'con'}>
                                {
                                    this.state.treeData && this.state.treeData.length !== 0 ?
                                        <DirectoryTree
                                            /*multiple={false}*/
                                            showLine={{showLeafIcon: false}}
                                            onExpand={this.onExpand}
                                            expandedKeys={this.state.expandedKeys}
                                            onSelect={this.handleTreeSelect}
                                            onRightClick={this.handleRightClick}
                                            treeData={this.state.treeData}
                                            selectedKeys={this.state.treeSelectedKeys}
                                        /> : '暂无数据,请联系管理员！！！'
                                }
                                <div
                                    id="dataThemModal"
                                    onClick={e => this.handleTreeNodeTypeFlag(e)}
                                    style={{display: `${this.state.cedeModalIsShow ? 'block' : 'none'}`}}
                                >
                                    <div id="dataThemModal_content">
                                        {
                                            this.getNodeTreeRightClickMenu()
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'edit_right'}>
                            <div className={'title'}>
                                {
                                    this.state.operationTypeFlag ?
                                        // 一层 判断 if
                                        <Tabs
                                            activeKey={this.state.tabsSelectKeys}
                                            onChange={this.handleTabChange}
                                        >
                                            <TabPane tab="添加数据主题目录" key="1"/>
                                            <TabPane tab="添加数据元数据" key="2"/>
                                        </Tabs> :
                                        // 一层 判断 else
                                        this.state.operationTypeFlag2 ?
                                            // 二层 判断 if
                                            <Tabs
                                                defaultActiveKey="1"
                                                onChange={this.handleTabChange}
                                            >
                                                <TabPane tab="添加数据主题目录" key="1"/>
                                                <TabPane tab="添加数据元数据" key="2"/>
                                            </Tabs> :
                                            // 二层 判断 else
                                            this.state.operationTitle
                                }
                            </div>
                            <div className={'con'}>
                                {
                                    this.state.operationTypeFlag ?
                                        // 一层 判断 if
                                        this.state.tabsSelectKeys == 1 ?
                                            <DataCatalogDetail
                                                operation={this.state.treeNodeOperationType}
                                                info={{...this.state.currentSelectNodeInfo}}
                                                interFace={this.getDataThemeDirectoryTreeList}
                                                themeId={this.props.location.state.id}
                                            />
                                            : <DataThemTreeDetail
                                                operation={this.state.treeNodeOperationType}
                                                info={{...this.state.currentSelectNodeInfo}}
                                                interFace={this.getDataThemeDirectoryTreeList}
                                                themeId={this.props.location.state.id}

                                            />
                                        :
                                        // 一层 判断 else
                                        this.state.treeData && this.state.treeData.length == 0 ?
                                            // 二层 判断 if
                                            '暂无数据,请联系管理员！！！'
                                            :
                                            // 一层 判断 else
                                            this.state.treeNodeType == 'elem' ?
                                                this.state.treeNodeOperationType == 'add' || this.state.treeNodeOperationType == 'modify' ?
                                                    <DataThemTreeDetail operation={this.state.treeNodeOperationType}
                                                                        info={{...this.state.currentSelectNodeInfo}}
                                                                        interFace={this.getDataThemeDirectoryTreeList}
                                                                        themeId={this.props.location.state.id}

                                                    /> : <div className={'tips'}>{<IconFont
                                                        type="icontoLeft"></IconFont>}请选择左边数据主题查看!</div>
                                                : this.state.treeNodeOperationType == 'add' || this.state.treeNodeOperationType == 'modify' ?
                                                <DataCatalogDetail operation={this.state.treeNodeOperationType}
                                                                   info={{...this.state.currentSelectNodeInfo}}
                                                                   interFace={this.getDataThemeDirectoryTreeList}
                                                                   themeId={this.props.location.state.id}
                                                /> : <div className={'tips'}>{<IconFont
                                                    type="icontoLeft"></IconFont>}请选择左边数据主题查看!</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(MetaManagementDetail);