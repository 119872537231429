/**
 * 批量审核
 */
import React from 'react';
import './index.less';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Form, Input, Select, Button, message as Message, Table, message, Modal, DatePicker, Upload } from "antd";
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { GetProjectEnume, GetBatchAuditModule, GetBatchAuditTaskList, BatchAuditCreateTask, GetBatchAuditTaskUrl } from '../../../../services/batchAudit/batchAuditService';
import { createFromIconfontCN, UploadOutlined } from '@ant-design/icons';

const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});
const { Option } = Select;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

let _this;

class DoctorApprove extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            dataLists: [], // 列表集合
            columns: [
                {
                    title: '创建人',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.createUser}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '项目名称',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.projectName}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '任务名称',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.name}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '创建时间',
                    width: '',
                    render: (text, record, index) => {
                        if (record.createTime) {
                            return (
                                <div className={'con_div'}>
                                    <div className={'sub-title'}>{record.createTime ? record.createTime.replace('T', ' ') : ''}</div>
                                </div>
                            )
                        }
                    }
                },
                {
                    title: '完成时间',
                    width: '',
                    render: (text, record, index) => {
                        if (record.finishTime) {
                            return (
                                <div className={'con_div'}>
                                    <div className={'sub-title'}>{record.finishTime ? record.finishTime.replace('T', ' ') : ''}</div>
                                </div>
                            )
                        }
                    }
                },
                {
                    title: '状态',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div current_state'}>
                                {/* 0-生成中 1-已完成 */}
                                <Button type={record.taskState == '0' ? 'link' : 'text'}>
                                    {
                                        record.taskState == '0' ? '处理中' : <div style={{ color: '#87d068' }}>已完成</div>
                                    }
                                </Button>
                            </div>
                        )
                    }
                },
                {
                    title: '操作',
                    width: '',
                    render: (text, record) =>
                        <div
                            onClick={eve => {
                                eve.stopPropagation();
                            }}
                            className={'partPop'}
                        >
                            <Button type="link" onClick={() => this.downLoadData(record)} disabled={record.downloadState == '0'}>
                                下载数据
                            </Button>
                        </div>
                },
            ],// 表格 行 配置
            page: 1,//当前页码数
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo);
                },
                current: 1,
                pageSize: 10,
                total: 100,
                showSizeChanger: false,
            },
            isCreateVisible: false, // 创建数据下载任务模态框
            isDownVisible: false, // 下载数据模态框
            currentDate: null, // 检索日期
            rowId: '',

            fileList: [],
            uploadBtnFlag: false,
            noFileTips: false,//未选择文件提示
            uploadFlag: true,

            projects: [], // 项目枚举
            projectCode: '', // 项目code
            disabledMould: false,
        }
        _this.timer = null;
    }

    formRef = React.createRef();
    formRefDownLoad = React.createRef();

    componentDidMount = () => {
        // 列表
        this.getPageData();
        this.getProjectEnume();
    }

    componentWillUnmount = () => {
        clearInterval(_this.timer);
    }

    // 获取项目枚举
    getProjectEnume = () => {
        GetProjectEnume().then(res => {
            if (res) {
                this.setState({
                    projects: res
                })
            }
        })
    }

    /**
     * 下载数据
     */
    downLoadData = async (record) => {

        if (this.formRefDownLoad.current) {
            this.formRefDownLoad.current.resetFields()
        }

        this.setState({
            isDownVisible: true,
            rowId: record.id,
        })
    }

    /**
     * 下载数据-关闭
     */
    handleDownCancel = () => {
        this.setState({
            isDownVisible: false
        })
    }

    /**
     * 获取列表数据 
     */
    getPageData = async (curPage) => {
        let { page, currentDate, projectCode } = this.state;

        const params = {
            page: curPage ? curPage : page,
            pageSize: this.state.pagination.pageSize,
            date: currentDate || '',
            projectCode
        }

        const res = await GetBatchAuditTaskList(params);
        if (res && res.code == 0) {
            _this.setState({
                dataLists: [...res.data.rows],
                page: res.data.page,
                pageSize: res.data.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })
        }
    }

    // 取消
    handleCancel = async () => {
        this.setState({
            isCreateVisible: false,
            uploadBtnFlag: false,
            noFileTips: false,
            fileList: []
        })
    }

    // form过滤空格
    getValueFromEvent = (event) => {
        return event.target.value.replace(/\s+/g, "")
    }

    // 创建下载任务
    createTasks = () => {
        if (this.formRef.current) {
            this.formRef.current.resetFields()
        }

        this.setState({
            isCreateVisible: true
        })
    }

    // 下载模版
    downLoadMould = () => {
        this.setState({
            disabledMould: true
        })
        GetBatchAuditModule().then(res => {
            if (res) {
                window.open(res);
                this.setState({
                    disabledMould: false
                })
            }
        })
    }

    // 选择日期
    onDateChange = (date, dateString) => {
        this.setState({
            currentDate: dateString
        }, () => {
            this.getPageData()
        })
    };

    // 上传文件
    onSubmit = async (values) => {
        if (!this.state.uploadFlag) {
            return false
        }
        const { fileList } = this.state;
        if (fileList.length === 0) {
            this.setState({
                noFileTips: true
            })
            return;
        }
        const uploadFormData = new FormData();
        fileList.forEach(file => {
            uploadFormData.append('file', file);
        });

        const form = this.formRef.current;
        const formValues = form.getFieldsValue(
            ['passWord', 'projectCode']);

        uploadFormData.append('pwd', formValues.passWord);
        uploadFormData.append('projectCode', formValues.projectCode);
        this.setState({ uploadFlag: false })

        const res = await BatchAuditCreateTask(uploadFormData);
        if (res && res.code == 0) {
            message.success('上传成功！');
            _this.setState({
                isCreateVisible: false,
                uploadBtnFlag: false,
                noFileTips: false,
                fileList: [],
                uploadFlag: true
            }, () => {
                // 5s刷新一次进度
                _this.timer = setInterval(() => {
                    this.getPageData()
                }, 5000)
            })
        } else {
            _this.setState({
                uploadFlag: true
            })
        }
    }

    // 下载/提交
    onDownloadSubmit = async (values) => {
        const form = this.formRefDownLoad.current;
        const formValues = form.getFieldsValue(['passWord']);

        let params = {
            id: this.state.rowId,
            pwd: formValues.passWord
        }

        GetBatchAuditTaskUrl(params).then(res => {
            if (res && res.code == 0) {
                window.open(res.data);
                this.setState({
                    isDownVisible: false
                })
            }
        })
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    // 选择项目
    handleSelectChange = (value) => {
        this.setState({
            projectCode: value
        }, () => {
            this.getPageData()
        })
    }

    render() {
        let { columns, dataLists, pagination, isCreateVisible, isDownVisible, currentDate, projects, projectCode, disabledMould } = this.state;

        dataLists.length > 0 && dataLists.map((item, index) => {
            item['key'] = index + 1
        })

        const props = {
            name: 'file',
            accept: ".xlsx,.xls",
            action: this.state.GetProjectUploadUser,
            headers: {
                authorization: 'authorization-text',
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }), () => {
                    if (this.state.fileList.length === 1) {
                        this.setState({
                            uploadBtnFlag: true,
                            noFileTips: false
                        })
                    }
                });
                return false
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
                this.setState({
                    uploadBtnFlag: false
                })
            },
        }

        let searchWrapHeight = document.getElementById('search_wrap');
        let sh = searchWrapHeight ? searchWrapHeight.offsetHeight : 0;
        let wh = document.documentElement.clientHeight;
        let th = wh - sh - 300;

        return (
            <div className='batchAudit'>
                <div className={'content_wrap'}>
                    <div className={'search_wrap'} id="search_wrap">
                        <div>
                            <DatePicker
                                onChange={this.onDateChange}
                                locale={locale}
                                value={currentDate ? moment(currentDate, 'YYYY-MM-DD') : null}
                            />
                            <Select
                                showSearch
                                defaultValue="所有项目"
                                value={projectCode}
                                onChange={this.handleSelectChange}
                                size='middle'
                                style={{ minWidth: 220, margin: '0 15px' }}
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                allowClear
                            >
                                <Option value={''}>所有项目</Option>
                                {projects && projects.length > 0 && projects.map(project => (
                                    <Option key={project.projectCode} value={project.projectCode}>{project.projectName}</Option>
                                ))}
                            </Select>
                        </div>


                        <div>
                            <Button type="primary" size={'middle'} onClick={() => this.createTasks()}>
                                创建上传任务
                            </Button>

                            <Button type="primary" size={'middle'} onClick={() => this.downLoadMould()} className='mould' disabled={disabledMould}>
                                下载模版
                            </Button>
                        </div>
                    </div>

                    <Table
                        locale={{ emptyText: "暂无数据" }}
                        columns={columns}
                        dataSource={dataLists}
                        pagination={dataLists.length !== 0 && pagination}
                        rowKey={'id'}
                        scroll={{ y: `${th}px` }}
                    />
                </div>

                {/* 创建数据下载任务 */}
                <Modal
                    title="创建数据下载任务"
                    visible={isCreateVisible}
                    onOk={this.onSubmit}
                    okText='确认创建'
                    cancelText='关闭'
                    onCancel={this.handleCancel}
                    width='30%'
                >
                    <div className="tooltip_text">该操作不可撤销，请仔细检查数据！</div>
                    <Form
                        {...layout}
                        name="nest-messages"
                        onFinish={this.onSubmit}
                        requiredMark={true}
                        ref={this.formRef}
                        autoComplete="off"
                        initialValues={{
                            'passWord': '',
                        }}
                    >
                        <Form.Item
                            label="请选择文件"
                            name='file'
                            rules={[{ required: true, message: '请上传文件!' }]}
                        >
                            <div className={'upFile'} >
                                <Upload
                                    {...props}
                                >
                                    <Button
                                        icon={<UploadOutlined />}
                                        disabled={this.state.uploadBtnFlag}
                                    >
                                        上传文件
                                    </Button>
                                </Upload>
                                <div
                                    className={'tips'}
                                    style={{ display: `${this.state.noFileTips ? 'block' : 'none'}` }}
                                >
                                    {this.formatIcon('icon-guanbi')}
                                    <span style={{ marginLeft: 4 }}>请先选择文件</span>
                                </div>
                                <div className={'tip'} style={{ marginTop: 4 }}>支持扩展名：.xlsx</div>

                            </div>
                        </Form.Item>

                        <Form.Item
                            label="请选择项目"
                            name="projectCode"
                            rules={[{ required: true, message: '请选择项目!' }]}
                        >
                            <Select
                                showSearch
                                defaultValue="所有项目"
                                value={projectCode}
                                size='middle'
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                allowClear
                            >
                                <Option value={''}>所有项目</Option>
                                {projects && projects.length > 0 && projects.map(project => (
                                    <Option key={project.projectCode} value={project.projectCode}>{project.projectName}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="请输入超级密码"
                            name="passWord"
                            rules={[{ required: true, message: '请输入超级密码!' }]}
                        >
                            <Input type="password" allowClear />
                        </Form.Item>
                    </Form>
                </Modal>

                {/* 下载数据 */}
                <Modal
                    title="验证密码"
                    visible={isDownVisible}
                    onOk={this.onDownloadSubmit}
                    okText='开始下载'
                    cancelText='关闭'
                    onCancel={this.handleDownCancel}
                    width='30%'
                >
                    <Form
                        {...layout}
                        name="nest-messages"
                        onFinish={this.onDownloadSubmit}
                        requiredMark={true}
                        ref={this.formRefDownLoad}
                        initialValues={{
                            'passWord': '',
                        }}
                    >
                        <Form.Item
                            label="请输入超级密码"
                            name="passWord"
                            rules={[{ required: false, message: '请输入超级密码!' }]}
                        >
                            <Input type="password" />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}
export default withRouter(DoctorApprove);