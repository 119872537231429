import React from 'react';
import { Table, Input, Modal, Button, Form, Select, message, Tooltip, Spin } from 'antd';
import { getTenantList, getUserList, editUserStatus, deleteUser, editUser, addUser } from "../../../services/system/UserService";
import { getAllRoleList } from "../../../services/system/RoleService";
import { MinusCircleOutlined, DeleteOutlined, FormOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import './index.less'


let unique = 1

const { TextArea } = Input;
const { Option } = Select;
const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 17,
    },
};

class UserManagement extends React.Component {

    formRef = React.createRef();
    editformRef = React.createRef();


    state = {
        tenantList: [],
        roleList: [],
        user: '',
        role: '',
        data: [],
        page: 1,
        pageSize: 10,
        totalRow: 0,
        loading: false,

        addUserModal: false,
        submitDisabled: false,

        editUserModal: false,
        editUserData: null
    }

    componentDidMount() {
        this.getData()
        this.getTenantData()
        this.getRoleList()
    }

    getData = () => {
        let params = {
            user: this.state.user,
            role: this.state.role,
            page: this.state.page,
            pageSize: this.state.pageSize,
        }
        this.setState({ loading: true }, () => {
            getUserList(params).then(res => {
                this.setState({
                    data: res.rows,
                    totalRow: res.total,
                    loading: false
                })
            }).catch(err => {
                this.setState({
                    loading: false
                })
                message.error(err.message);
            })
        })
    }

    getRoleList = () => {
        let params = {
            name: ''
        }
        getAllRoleList(params).then(res => {
            this.setState({
                roleList: res
            })
        }).catch(err => {
            message.error(err.message);
        })
    }

    getTenantData = () => {
        getTenantList().then(res => {
            this.setState({
                tenantList: res
            })
        }).catch(err => {
            message.error(err.message);
        })
    }


    /**
     * 改变页数
     * @param {*} page 
     */
    changePage = (page) => {
        this.setState({ page }, () => {
            this.getData()
        })
    }
    changePageSize = (current, size) => {
        this.setState({ page: 1, pageSize: size }, () => {
            this.getData()
        })
    }


    changeRole = (value) => {
        this.setState({
            role: value
        })
    }

    /**
     * 搜索
     */
    handleSearch = () => {
        this.setState({
            page: 1,
            data: []
        }, () => {
            this.getData()
        })
    }

    handleAdd = (values) => {
        let params = {
            code: values.code,
            name: values.name,
            pass: values.pass,
            roleCode: values.roleCode,
            describe: values.describe,
            tenantCode: values.tenantCode
        }

        let reg = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,16}$/;

        if (!reg.test(params.pass)) {
            message.error('密码必须由数字、字母和特殊字符8-16位组成，特殊字符：~!@#$%^&*')
            return false
        }

        this.setState({
            submitDisabled: true
        })

        addUser(params).then(res => {
            // message.success('新增用户成功', 2000)
            this.setState({
                page: 1,
                data: [],
                addUserModal: false,
                submitDisabled: false
            }, () => {
                this.getData()
            })
        }).catch(err => {
            message.error(err.message);
        })
    };


    showEditModal = (data) => {
        this.setState({ editUserModal: true, editUserData: data })
    }

    handleEdit = (values) => {
        let params = {
            code: values.code,
            name: values.name,
            // pass: values.pass,
            roleCode: values.roleCode,
            describe: values.describe,
            tenantCode: values.tenantCode
        }

        this.setState({
            submitDisabled: true
        })

        editUser(params).then(res => {
            this.setState({
                editUserModal: false,
                submitDisabled: false
            })
            this.getData()
        }).catch(err => {
            message.error(err.message);
        })
    }

    disableUser = (data) => {
        let params = {
            code: data.code
        }
        editUserStatus(params).then(res => {
            this.getData()
        }).catch(err => {
            message.error(err.message)
        })
    }

    handleDelete = (data) => {
        let params = {
            code: data.code
        }
        Modal.confirm({
            title: '确定删除该用户？',
            icon: <ExclamationCircleOutlined />,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                deleteUser(params).then(res => {
                    // message.success('删除用户成功', 2000)
                    this.setState({
                        page: 1,
                        data: [],
                    }, () => {
                        this.getData()
                    })
                }).catch(err => {
                    message.error(err.message)
                })
            }
        });
    }


    render() {
        const columns = [
            {
                title: '账号',
                dataIndex: 'code'
            },
            {
                title: '昵称',
                dataIndex: 'name'
            },
            // {
            //     title: '密码',
            //     dataIndex: 'createTime',
            // },
            {
                title: '角色',
                dataIndex: 'roleName'
            },
            {
                title: '账号状态',
                dataIndex: 'userStatus',
                render: (text, record) => (
                    <div className='opertion'>
                        {text == '0' ? '禁用' : '启用'}
                    </div>
                )
            },
            {
                title: '描述',
                dataIndex: 'remarks',
                width: 300,
                render: (text) => (
                    <Tooltip title={text} placement="topLeft">
                        <div style={{ width: 300 }} className='remarks'>
                            {text}
                        </div>
                    </Tooltip>
                )
            },
            {
                title: '操作',
                dataIndex: 'operation',
                width: 200,
                align: 'center',
                render: (text, record) => (
                    record.status != 0 && <div className='opertion'>
                        {/* <FormOutlined onClick={() => this.showEditModal(record)} />
                        <MinusCircleOutlined onClick={() => this.disableUser(record)} />
                        <DeleteOutlined onClick={() => this.handleDelete(record)} /> */}

                        <div className='jurisdiction' onClick={() => this.showEditModal(record)}>编辑</div>
                        <div className='disable' onClick={() => this.disableUser(record)}>{record.userStatus == '0' ? '启用' : '禁用'}</div>
                        <div className='delete' onClick={() => this.handleDelete(record)}>删除</div>
                    </div>
                )
            }
        ];


        const pagination = {
            current: this.state.page,
            pageSize: this.state.pageSize,
            total: this.state.totalRow,
            showSizeChanger: true,
            onChange: this.changePage,
            onShowSizeChange: this.changePageSize
        }

        const { addUserModal, editUserModal, submitDisabled, roleList, editUserData, tenantList, loading } = this.state

        return (
            <div className='user_container'>
                <div className='search'>
                    <div className='searchLeft'>
                        <div className='searchItem'>
                            <Input placeholder="请输入账号/昵称搜索" onChange={e => this.setState({ user: e.target.value })} style={{ width: 200 }} />
                        </div>
                        <div className='searchItem'>角色：
                            <Select
                                placeholder='请选择平台角色'
                                onChange={this.changeRole}
                                style={{ width: 300 }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    roleList && roleList.length > 0 ?
                                        roleList.map(v => (
                                            <Option
                                                value={v.code}
                                                id={v.code}
                                                key={v.code}>{v.name}</Option>
                                        )) : ''
                                }
                            </Select></div>
                        <Button type="primary" onClick={this.handleSearch}>搜索</Button>
                    </div>

                    <Button type="primary" className='addBtn' onClick={() => this.setState({ addUserModal: true })}>新增账号</Button>
                </div>

                <Spin tip="数据加载中..." spinning={loading}>
                    <Table rowKey={(record) => {
                        unique++
                        return `${record.code}-${unique}`
                    }} bordered columns={columns} dataSource={this.state.data} pagination={pagination} />
                </Spin>
                <Modal
                    visible={addUserModal}
                    title="新增用户"
                    onCancel={() => this.setState({ addUserModal: false })}
                    centered
                    destroyOnClose
                    footer={false}
                >
                    <div>
                        <Form
                            {...layout}
                            name="nest-messages"
                            ref={this.formRef}
                            onFinish={this.handleAdd}

                        >
                            <Form.Item
                                label="账号"
                                name='code'
                                rules={[
                                    { required: true, message: '请输入账号!' },
                                    { pattern: '^[a-zA-Z0-9_]*$', message: '只支持数字、英文，请正确输入!' }
                                ]}
                            >
                                <Input style={{ width: 300 }} placeholder="请输入账号" autoComplete="off" />
                            </Form.Item>
                            <Form.Item
                                label="昵称"
                                name='name'
                                rules={[{ required: true, message: '请输入昵称!' }]}>
                                <Input style={{ width: 300 }} placeholder="请输入昵称" autoComplete="off" />
                            </Form.Item>
                            <Form.Item
                                label="密码"
                                name='pass'
                                autoComplete="off"
                                extra="密码必须由数字、字母和特殊字符8-16位组成，特殊字符：~!@#$%^&*"
                                rules={[
                                    { required: true, message: '请输入密码!' },
                                ]}>
                                <Input.Password style={{ width: 300 }} placeholder="请输入密码" autoComplete="new-password" />
                            </Form.Item>

                            <Form.Item
                                label="平台角色"
                                name='roleCode'
                                rules={[{ required: true, message: '请选择平台角色!' }]}>
                                <Select
                                    placeholder='请选择平台角色'
                                    style={{ width: 300 }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        roleList && roleList.length > 0 ?
                                            roleList.map(v => (
                                                <Option
                                                    value={v.code}
                                                    id={v.code}
                                                    key={v.code}>{v.name}</Option>
                                            )) : ''
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="租户"
                                name='tenantCode'>
                                <Select
                                    placeholder='请选择租户'
                                    style={{ width: 300 }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value='' id=''>无租户</Option>
                                    {
                                        tenantList && tenantList.length > 0 ?
                                            tenantList.map(v => (
                                                <Option
                                                    value={v.code}
                                                    id={v.code}
                                                    key={v.code}>{v.name}</Option>
                                            )) : ''
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item label="描述" name='describe' >
                                <TextArea style={{ width: 300 }} placeholder="请输入用户描述" />
                            </Form.Item>

                            <Form.Item style={{ textAlign: 'center', width: '100%', marginBottom: 0, marginTop: 45, }} wrapperCol={24}>
                                <Button htmlType="submit" disabled={submitDisabled} type="primary">
                                    确认
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>


                <Modal
                    visible={editUserModal}
                    title="修改用户"
                    onCancel={() => this.setState({ editUserModal: false })}
                    centered
                    destroyOnClose
                    footer={false}
                >
                    <div>
                        <Form
                            {...layout}
                            name="nest-messages"
                            ref={this.editformRef}
                            onFinish={this.handleEdit}
                        >
                            <Form.Item
                                label="账号"
                                name='code'
                                rules={[
                                    { required: true, message: '请输入账号!' },
                                    { pattern: '^[a-zA-Z0-9_]*$', message: '只支持数字、英文，请正确输入!' }
                                ]}
                                initialValue={editUserData && editUserData.code}
                            >
                                <Input disabled style={{ width: 300 }} placeholder="请输入账号" autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="昵称" name='name' rules={[{ required: true, message: '请输入昵称!' }]} initialValue={editUserData && editUserData.name} >
                                <Input style={{ width: 300 }} placeholder="请输入昵称" autoComplete="off" />
                            </Form.Item>
                            {/* <Form.Item label="密码" name='pass' rules={[{ required: true, message: '请输入密码!' }]} initialValue={editUserData && editUserData.pass} >
                                <Input style={{ width: 300 }} placeholder="请输入密码" autoComplete="off" />
                            </Form.Item> */}

                            <Form.Item
                                label="平台角色"
                                name='roleCode'
                                rules={[{ required: true, message: '请选择平台角色!' }]}
                                initialValue={editUserData && editUserData.roleCode}
                            >
                                <Select
                                    placeholder='请选择平台角色'
                                    style={{ width: 300 }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        roleList && roleList.length > 0 ?
                                            roleList.map(v => (
                                                <Option
                                                    value={v.code}
                                                    id={v.code}
                                                    key={v.code}>{v.name}</Option>
                                            )) : ''
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="租户"
                                name='tenantCode'
                                initialValue={editUserData && editUserData.tenantCode}
                            >
                                <Select
                                    placeholder='请选择租户'
                                    style={{ width: 300 }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value='' id=''>无租户</Option>
                                    {
                                        tenantList && tenantList.length > 0 ?
                                            tenantList.map(v => (
                                                <Option
                                                    value={v.code}
                                                    id={v.code}
                                                    key={v.code}>{v.name}</Option>
                                            )) : ''
                                    }
                                </Select>
                            </Form.Item>


                            <Form.Item label="描述" name='describe' initialValue={editUserData && editUserData.remarks}>
                                <TextArea style={{ width: 300 }} placeholder="请输入用户描述" />
                            </Form.Item>

                            <Form.Item style={{ textAlign: 'center', width: '100%', marginBottom: 0, marginTop: 45, }} wrapperCol={24}>
                                <Button htmlType="submit" disabled={submitDisabled} type="primary">
                                    确认
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
            </div>
        )
    }

}

export default UserManagement;
