type Dictionary = {
  // 端午活动
  enableDuanWuActivity: null | boolean
  // ？
  enableInterest: null | boolean
  // ？
  enableMenu: null | boolean
  // ？
  enableNewUi: null | boolean
  // 隐藏100个适应症医学项目实际访视时间
  hiddenVisitDate: null | boolean
  // 隐藏神州细胞数据审计列表中的所在医院、上传医生、医生账号列
  hiddenField: null | number
  // 君实修改受试者为患者
  label: null | number
  // 根据租户跳转默认页面
  route: string
  [key: string]: unknown
}

export default class TenantConfiguration {

  protected static readonly key = 'tenant-configuration'

  protected static dictionary: Dictionary

  // 持久化到本地缓存
  static write(dictionary: Dictionary) {
    this.dictionary = this.persist(dictionary)
  }

  // 读取数据
  static read<T = unknown>(name: keyof Dictionary, defaultValue: unknown = null) {
    if (!this.dictionary) {
      this.dictionary = this.persist()
    }
    return (this.dictionary[name] ?? defaultValue) as T
  }

  static all() {
    if (!this.dictionary) {
      this.dictionary = this.persist()
    }
    return this.dictionary
  }

  protected static persist(dictionary?: Dictionary) {
    if (dictionary) {
      sessionStorage.setItem(this.key, JSON.stringify(dictionary || {}))
      return dictionary
    }
    return JSON.parse(sessionStorage.getItem(this.key) || '{}') as Dictionary
  }
}