/**
 * 源数据下载
 */
 import { requestPostFile, requestPost, requestGet, requestPostRes, requestGetRes, requestPatchRes } from '../EdcService';

 const urlPrefix = "/cdms/dataSource";

// 源数据任务列表 
export const GetDataSourceList = (params) => {
    const url = `${urlPrefix}/dataSourceTaskList`;
    return requestPostRes(url, params)
};

// 获取下载链接
export const GetDownLoadUrl = (params) => {
    const url = `${urlPrefix}/getDownLoadUrl`;
    return requestPostRes(url, params)
};

// 创建下载任务 
export const createDataSource = (params) => {
    const url = `${urlPrefix}/createTask`;
    return requestPostRes(url, params)
};
export const createMultiDataSource = (params) => {
    const url = `${urlPrefix}/createMultiTask`;
    return requestPostRes(url, params)
};

// 项目枚举
export const GetProjectsEnume = (params) => {
    const url = `${urlPrefix}/tenant/process/list`;
    return requestGet(url, params)
};

// 项目可选参与者
export const GetProjectUserList = (params) => {
    const url = `${urlPrefix}/projectUser/list`;
    return requestGet(url, params)
};


