/**
 * 
 */
import { requestPost, requestGet, requestPostRes, requestGetRes, requestPostFile } from '../EdcService';

const urlPrefix = "/dct/data";

/**
 * 获取OSS访问令牌
 * @param {} params
 */
export const getCaseSts = (params) => {
    const url = "/file/sts/getStsToken";
    return requestGetRes(url, params)
};

/*
 * 批量审核列表
 */
export const GetCsoAuditList = (params) => {
    const url = `/visit/doctor/approve/task/list`;
    return requestPostRes(url, params)
};

/**
 * 上传文件
 * @param {} params
 */
export const CsoApproveTaskUpload = (params) => {
    const url = `/visit/doctor/approve/task/import`;
    return requestPostFile(url, params)
};


/*
 * 订单追踪列表(未完成订单)
 */
export const GetCsoOrderList = (params) => {
    const url = `/order/operateManage/orderList`;
    return requestPostRes(url, params)
};


/*
 * 根据名称获取用户列表
 */
export const GetUserSelectsByName = (params) => {
    const url = `/plat/user/userSelectsByName`;
    return requestGetRes(url, params)
};

/*
 * 订单详情
 */
export const GetCSOorderDetail = (params) => {
    const url = `/order/operateManage/orderDetail`;
    return requestGetRes(url, params)
};
/**
 * 项目列表
 * @param {} params
 */
 export const CsoProjectPage = (params) => {
    const url = `/cso/project/page`;
    return requestPostRes(url, params)
};

/**
 * 项目供应商列表分页
 * @param {} params
 */
 export const CsoProjectSupplierPage = (params) => {
    const url = `/cso/project/supplier/page`;
    return requestPostRes(url, params)
};

/**
 * 查询医生
 * @param {} params
 */
 export const CsoDoctorSearch = (params) => {
    const url = `/visit/doctor/search`;
    return requestPostRes(url, params)
};

/**
 * 增加项目供应商
 * @param {} params
 */
 export const CsoSupplierAddProject = (params) => {
    const url = `/cso/supplier/add/project`;
    return requestGetRes(url, params)
};

/**
 * 获取cso列表
 * @param {} params
 */
 export const getCsoProjectList = (params) => {
    const url = "/cso/project/page";
    return requestPost(url, params)
};

/**
 * 获取cso医生列表
 * @param {} params
 */
 export const getCsoDoctorList = (params) => {
    const url = "/visit/doctor/page";
    return requestPost(url, params)
};

/**
 * 获取cso患者列表
 * @param {} params
 */
 export const getCsoPatientList = (params) => {
    const url = "/visit/patient/page";
    return requestPost(url, params)
};

/**
 * 医生审核列表
 * @param {} params
 */
 export const getDoctorApproveList = (params) => {
    const url = "/visit/doctor/approve/list";
    return requestPostRes(url, params)
};

/**
 * 审核详情
 * @param {} params
 */
 export const getDoctorApproveListDetail = (params) => {
    const url = "/visit/doctor/approve/detail";
    return requestGetRes(url, params)
};

/**
 * 审核操作
 * @param {} params
 */
 export const doctorApproveListOperation= (params) => {
    const url = "/visit/doctor/approve/operation";
    return requestPostRes(url, params)
};

/**
 * 审核数据统计
 * @param {} params
 */
 export const doctorApproveStatistics= (params) => {
    const url = "/visit/doctor/approve/statistics";
    return requestGetRes(url, params)
};

/**
 * 审核操作
 * @param {} params
 */
export const getDesensitizationOrderList = (params) => {
    const url = "/order/operateManage/desensitization/orderList";
    return requestPostRes(url, params)
};

/**
 * vr跟进
 * @param {} params
 */
export const vrFollow = (params) => {
    const url = "/order/operateManage/vr/follow";
    return requestGetRes(url, params)
};

/**
 * vr跟进人员列表
 * @param {} params
 */
export const getVrFollowUserList = (params) => {
    const url = "/order/operateManage/vr/follow/user/list";
    return requestGetRes(url, params)
};

/**
 * 获取药品列表
 * @param {} params
 */
export const getCsoDrugsList = (params) => {
    const url = "/visit/drug/list";
    return requestPostRes(url, params)
};

/**
 * 保存药品
 * @param {} params
 */
export const getCsoDrugsSave = (params) => {
    const url = "/visit/drug/save";
    return requestPostRes(url, params)
};

/**
 * 获取药品
 * @param {} params
 */
export const getCsoDrugs = (params) => {
    const url = "/visit/drug/api/getDrugs";
    return requestGetRes(url, params)
};

/**
 * 药品详情
 * @param {} params
 */
export const getCsoDrugsDetail = (code) => {
    const url = "/visit/drug/detail/" + code;
    return requestGetRes(url)
};

/**
 * 药品详情
 * @param {} params
 */
export const updateCsoDrugState = (params) => {
    const url = "/visit/drug/state/update";
    return requestGetRes(url, params)
};

// 领域查询, 疾病查询 department, disease
export const getCsoDrugProperty = (type) => {
    const url = `/visit/properties/list/${type}`
    return requestGetRes(url)
};

/**
 * 导入
 * @param {} params
 */
export const submitCsoDrugsExcel = (code) => {
    const url = "/drug/excel/import";
    return requestGetRes(url)
};

/**
 * 获取商店商品集合(skuId集合)
 * @param {} params
 */
export const getCsoDrugList = (params) => {
    const url = "/visit/drug/api/getDrugList";
    return requestGetRes(url, params)
};

/**
 * 导入
 * @param {} params
 */
export const CsoDrugImport = (params) => {
    const url = "/visit/drug/excel/import";
    return requestPostFile(url, params)
};

