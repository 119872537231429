let env = process.env.REACT_APP_ENV;
let baseUrl = '';
let eproScaleUrl = ''


//开发环境
if (env === 'development') {
    baseUrl = 'https://edc-saas-test.huimeimt.com/server/';
    eproScaleUrl = 'https://edc-saas-test.huimeimt.com/eproScale/';
    //baseUrl = 'http://192.168.60.105:6060/'
    /*baseUrl = 'http://192.168.1.38:8004/'*/
}
//测试环境
else if (env === 'test') {
    /* baseUrl = 'http://106.14.197.93:6060/'*/
    baseUrl = 'https://edc-saas-test.huimeimt.com/server/';
    eproScaleUrl = 'https://edc-saas-test.huimeimt.com/eproScale/';
}
//生产环境（未定
else if (env === 'production') {
    baseUrl = 'https://edc-saas.huimeihealth.com.cn/server/'
    eproScaleUrl = 'https://edc-saas.huimeihealth.com.cn/eproScale/';
}

export const BASE_URL = baseUrl;
export const EPRO_SCALE_URL = eproScaleUrl;
