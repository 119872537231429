import {DESIGN, SELECTSLISTS} from './actionTypes'
import axios from 'axios'
import {fromJS} from 'immutable';

export const changePageNoAction = (pageNo) => ({
    type: DESIGN,
    pageNo,
})

// 设计中心 状态 类型 下拉框列表集合
export const selectsListsAction = (statusList, typeList) => ({
    type: SELECTSLISTS,
    statusList: fromJS(statusList),
    typeList: fromJS(typeList)
})

// 使用redux-thunk封装返回一个函数 [使用dispatch(action)派发到这里]
// export const getFocusTipValuesAction = (focused, cacheTipList, pageSize) => {
//   return (dispatch) => {
//     if (!cacheTipList) {
//       axios.get("/api/tipList.json").then((res) => {
//         console.log(res)
//         const tipList = res.data
//         const action = focusTipValuesAction(focused, tipList, true, tipList.length, pageSize)
//         dispatch(action)
//       }).catch(() => {
//         console.log('error')
//         const action = changeFocusValueAction(focused)
//         dispatch(action)
//       })
//     } else {
//       const action = changeFocusValueAction(focused)
//       dispatch(action)
//     }
//   }
// }

