/**
 * dct 待办任务
 */
import React, { Component } from 'react';
import './index.less';
import { message, Card, List, Avatar, Tag } from 'antd';
import { GetDctTasks } from "../../../../services/dct/DctService";
import utils from '../../../apply/utils';

let _this;

class DctTasksComponent extends Component {

    constructor(props) {
        super(props);
        _this = this;
        this.state = {
            tasksData: [], // 待办任务
        };
        this.inputEl = React.createRef();
    }

    componentDidMount() {
        this.getList();
    }

    // 获取数据
    getList = () => {
        let params = {
            top: 5
        }
        GetDctTasks(params).then(res => {
            if (res.code == 0) {
                if (!res.data || res.data.length == 0) {
                    return;
                }
                this.setState({
                    tasksData: res.data
                })
            }
        })
    }

    // 查看更多
    openMore = () => {
        console.log('查看更多')
    }

    render() {

        let { tasksData } = this.state;
        tasksData.map(item => {
            if (item.taskStatus === '11') {
                item['color'] = 'error'
                item.taskStatus = '失败'
            }
            else if (item.taskStatus === '10') {
                item['color'] = 'warning'
                item.taskStatus = '合格'
            }
        })

        return (
            <div className={'dctTasks_content'}>
                <div className="top_card">
                    <Card title="待办任务" extra={tasksData.length > 0 ? <div className="more" onClick={this.openMore}>查看更多 ></div> : ''}>

                        <List
                            dataSource={tasksData}
                            renderItem={(item, index) => (

                                <List.Item key={index}>
                                    <List.Item.Meta
                                        avatar={
                                            <Tag color={item.color}>{item.taskStatus}</Tag>
                                        }
                                        title={
                                            <div >
                                                {`【${item.projectName}】`}{item.userName}{item.userCode}{item.hospitalName}
                                            </div>
                                        }
                                    />
                                    <div className="timeTxt">{utils.formatDuring(item.createDate)}前</div>
                                </List.Item>
                            )}
                        />
                    </Card>
                </div>
            </div>
        )
    }
}
export default DctTasksComponent