import { requestGetRes, requestPostRes } from "../EdcService";

export type CreateMessageParams = {
  sender: string;
  subjectCode: string;
  content: string;
  url: string;
}

export type MessageListParams = {
  id: number;
  pageSize: number;
  sender: string;
  subjectCode: string;
  refresh: boolean;
}

export type MessageListResult = {
  id: number;
  content: string;
  createTime: string;
  direction: number;
  subjectCode: string;
  receiver: string;
  sender: string;
  url: string;
}

/**
 * 留言列表
 * @param params 
 * @returns 
 */
export const messageList = (params: MessageListParams)=> {
  return requestPostRes('knowledge/message/getList', params)
}

/**
 * 给患者留言
 * @param params 
 * @returns 
 */
export const createMessage = (params: CreateMessageParams)=> {
  return requestPostRes('knowledge/message/doctor/create', params)
}

type MessageSessionParams = {
  page: number;
  pageSize?: number;
  sender: string;
}
export const messageSession = (params: MessageSessionParams)=> {
  return requestPostRes('knowledge/message/getReceiverList', params)
}


// 聊天常用语
export type MessageFillResult = {
  id: number;
  content: string;
}
export const messageFill = ()=> {
  return requestGetRes('knowledge/message/template')
}