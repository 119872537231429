import React, { Component } from 'react';
import { Modal, Button, Form, message, Checkbox } from 'antd';
import { withRouter } from 'react-router-dom';
import './modal.less'
import PubSub from "pubsub-js";
import { GetNotDoctorReasons, GetSignFailReasons } from "../../../../services/apply/ApplyService";

const layout = {
    labelCol: {
        span: 7,
    },
    wrapperCol: {
        span: 17,
    },
};

class ModalReason extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,//按钮状态
            reasonList: [],
            otherReason: '', // 其它原因
            checkedValues: [],
        };
    }

    componentDidMount() {
        this.getRejectResaons()
    }

    form = React.createRef();

    /**
     * 提交
     */
    handleOk = () => {
        this.setState({
            isDisabled: true
        })
        
        let { otherReason, checkedValues } = this.state;

        let reasons = checkedValues;
        let params = ''
        
        if (reasons.length > 0) {
            if (reasons.includes('其他')) {
                if (otherReason == '') {
                    this.setState({
                        isDisabled: false
                    })
                    message.error('请填写拒绝理由！');
                    return
                }
                params = reasons.join(',').replace('其他',otherReason)
            } else {
                params = reasons.join(',')
            }
            
        } else {
            this.setState({
                isDisabled: false
            })
            message.error('请选择拒绝理由！');
            return
        }

        this.props.getRejectReason(params, this.props.type)
    };

    /**
     * 关闭
     */
    handleClose = () => {
        this.props.closeModal();
        this.setState({
            reasonList: []
        })
    };

    /**
     * 获取拒绝原因 
     */
    getRejectResaons = () => {
        let { type } = this.props;
        let APIMethods = type == 'sign' ? GetSignFailReasons : GetNotDoctorReasons
        
        APIMethods().then(res => {
            let data = [];
            res.map((i => {
                data.push(
                    {
                        key: i.key,
                        label: i.text,
                        value: i.remark
                    }
                )
            }))
            this.setState({
                reasonList: data
            });
        })
    }

    onCheckboxChange = (checkedValues) => {
        this.setState({
            checkedValues
        })
    }

    /**
     * 其他原因填写
     */
     onReasonChange = (event) => {
        this.setState({
            otherReason: event.target.value
        });
     }

    render() {
        let { reasonList, isDisabled, otherReason, checkedValues } = this.state;
        const { visible } = this.props;

        return (
            <>
                <Modal
                    className={'reasonModal'}
                    visible={visible}
                    title="请选择拒绝理由"
                    onCancel={this.handleClose}
                    width={'40%'}
                    centered
                    destroyOnClose
                    footer={[
                        <Button key="back" onClick={this.handleClose}>
                            返回
                        </Button>,
                        <Button key="submit" type="primary" loading={isDisabled} onClick={this.handleOk}>
                            确认
                        </Button>
                    ]}
                >

                    <Checkbox.Group options={reasonList} onChange={this.onCheckboxChange} />
                    {
                        checkedValues.includes('其他') &&
                        <textarea className='case-audit-text-area' onChange={this.onReasonChange} value={otherReason} style={{ resize: 'none' }}></textarea>
                    }
                </Modal>
            </>
        );
    }
}

export default withRouter(ModalReason);
