import React from "react"
import { Button, Col, Form, Input, Row } from "antd"
import { useState } from "react"

import modStyl from './condition.module.scss'
import { CareOfficerListParams } from "../../../../../services/apply/patient"

type ConditionProps = {
  onSearch: (params: CareOfficerListParams)=> void
}

const Condition: React.FC<ConditionProps> = ({ onSearch })=> {
  const [careOfficerName, setCareOfficerName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')

  const submitControl = ()=> {
    onSearch({
      careOfficerName,
      phone
    })
  }

  const resetControl = ()=> {
    onSearch({
      careOfficerName: '',
      phone: '',
    })
  }

  const colCols = {
    span: 6
  }
  const fieldCols = {
    labelCol: {
      span: 6
    },
    wrapperCol: {
      span: 18
    }
  }

  return (
    <div className={modStyl.wrapper}>
      <Form>
        <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="关爱专员姓名">
              <Input onChange={({target})=> setCareOfficerName(target.value)} value={careOfficerName} placeholder="请输入医助姓名" allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="　手机号">
              <Input onChange={({target})=> setPhone(target.value)} value={phone} placeholder="请输入医助手机号" allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Button onClick={submitControl} type="primary">搜索</Button>
            <Button onClick={resetControl} style={{marginLeft: 10}}>重置</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Condition
