import React, {Component} from 'react';
import './dictionaryDetail.less';
import {Form, Space, Input, Button, Empty, message} from 'antd';
import {MinusCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {DictionaryInfo, DictionariesProjectAdd} from '../../../../services/config/ConfigService'
import {clearThreeTitle} from '../../../../util/menuModuleUtil';

const FormItem = Form.Item;

class dictionaryDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            code: "",
            createTime: "",
            createUser: "",
            createUserName: "",
            state: "",
            stateName: "",
            name: "",
            type: "",
            typeName: "",
            identification: "",
            releasePro: "",
            remark: "",
        };
    }

    EditForm = React.createRef();

    componentDidMount() {
        DictionaryInfo(this.props.location.state.id).then(res => {
            let {
                code,
                createTime,
                createUser,
                createUserName,
                state,
                stateName,
                name,
                type,
                typeName,
                identification,
                releasePro,
                remark,
                dictionariesProjectDtos
            } = res
            if (this.EditForm && this.EditForm.current) {
                if (dictionariesProjectDtos.length == 0) {
                    dictionariesProjectDtos.push({
                        code: "",
                        name: "",
                        description: ""
                    })
                }
                this.EditForm.current.setFieldsValue({'dictionariesProjects': dictionariesProjectDtos});
                this.setState({
                    code,
                    createTime,
                    createUser,
                    createUserName,
                    state,
                    stateName,
                    name,
                    type,
                    typeName,
                    identification,
                    releasePro,
                    remark,
                })
            }
        })
    }

    componentWillUnmount() {
        clearThreeTitle()
    }

    /**
     * 查询提交方法
     * @param fieldsValue
     */
    onSubmitProject = (fieldsValue) => {
        fieldsValue.dictionariesId = this.props.location.state.id;
        DictionariesProjectAdd(this.props.location.state.id, fieldsValue).then((res) => {
            if (res) {
                message.success('保存成功！')
                this.props.history.push('/config/dictionary')
            }
        })
    }

    render() {
        return (
            <div className={'dictionaryDetail'}>
                <div className={'dictionaryDetailCon'}>
                    <div className={'dictionaryDetail_title'}>
                        <h4>平台字典详情</h4>
                        <div className={'info'}>
                            <div className={'div1'}>
                                <div>
                                    <span>字典名称：</span>
                                    {this.state.name}
                                </div>
                                <div>
                                    <span>字典标识：</span>
                                    {this.state.identification}
                                </div>
                                <div>
                                    <span>分类：</span>
                                    {this.state.typeName}
                                </div>
                                <div>
                                    <span>发布机构：</span>
                                    {this.state.releasePro}
                                </div>
                                <div>
                                    <span>创建时间：</span>
                                    {this.state.createTime}
                                </div>
                            </div>
                            <div className={'div2'}>
                                <div>
                                    <span>状态：</span>
                                    {this.state.stateName}
                                </div>
                                <div>
                                    <span>创建人：</span>
                                    {this.state.createUserName}
                                </div>
                            </div>
                            <div className={'div3'}>
                                <p>字典介绍：</p>
                                <p>{this.state.remark ? this.state.remark : '暂无介绍'}</p>
                            </div>
                        </div>
                    </div>
                    <div className={'dictionaryDetail_con'}>
                        <h4>字典项目列表</h4>
                        <Form
                            ref={this.EditForm}
                            onFinish={this.onSubmitProject}
                            autoComplete="off"
                            className={'dictionaryForm'}
                        >
                            <Form.List
                                name="dictionariesProjects"
                            >
                                {(fields, {add, remove}) => (
                                    <>
                                        {
                                            fields.map((field, index) => (
                                                <Space
                                                    key={field.id}
                                                    style={{display: 'flex', marginBottom: 4}}
                                                    align="baseline"
                                                >
                                                    {fields.length > 1 ? (
                                                        <MinusCircleOutlined
                                                            className="dynamic-delete-button"
                                                            onClick={() => remove(field.name)}
                                                        />
                                                    ) : <div style={{width: 16}}></div>}
                                                    <Form.Item
                                                        name={[field.name, 'code']}
                                                        fieldKey={[field.fieldKey, 'code']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '请输入项目编码'
                                                            },
                                                            {
                                                                whitespace: true,
                                                                message: '不能输入空格',
                                                            }
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="请输入项目编码"
                                                            style={{
                                                                borderColor: '#EEEFF7',
                                                                width: 150,
                                                                marginRight: 10
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[field.name, 'name']}
                                                        fieldKey={[field.fieldKey, 'name']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '请输入项目名称'
                                                            },
                                                            {
                                                                whitespace: true,
                                                                message: '不能输入空格',
                                                            }
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="请输入项目名称"
                                                            style={{
                                                                borderColor: '#EEEFF7',
                                                                width: 150,
                                                                marginRight: 10
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[field.name, 'description']}
                                                        fieldKey={[field.fieldKey, 'description']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '请输入项目说明'
                                                            }, {
                                                                whitespace: true,
                                                                message: '不能输入空格',
                                                            }
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="请输入项目说明"
                                                            style={{borderColor: '#EEEFF7', width: 450}}
                                                        />
                                                    </Form.Item>
                                                </Space>
                                            ))
                                        }
                                        <Form.Item>
                                            <PlusCircleOutlined
                                                style={{marginRight: 8}}
                                                onClick={() => add()}
                                            />
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                            >
                                                添加字典项目
                                            </Button>
                                        </Form.Item>
                                        <FormItem
                                            wrapperCol={{span: 24}}
                                            style={{
                                                textAlign: "right",
                                                /*display: `${fields.length == 0 ? 'none' : 'block'}`*/
                                            }}
                                        >
                                            <Button type="primary" htmlType="submit">
                                                保存
                                            </Button>
                                        </FormItem>
                                    </>
                                )}
                            </Form.List>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

export default dictionaryDetail;