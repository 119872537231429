/**
 * 重置密码
 */

 import React from 'react';
 import { Form, Input, Button, Radio, Checkbox, message, Row, Col, Select } from 'antd';
 import { getDesignPlanSelects, toVisitGroupSave, getVisitGroupRangeEnums, getVisitGroupDetail } from "../../../../services/tenant/Tenant";
 import './index.less';
 const { Option } = Select;

 class FollowupPatientSetting extends React.Component {
 
     constructor(props) {
         super(props);
         this.state = {
             isEdit: this.props.code ? false : true,
             showEditContent: true,
             isOpenFollowUp: null,
             isOpenMenu: null,
             submitLoading: false,
             groupList: [],
             groupListMap: {},
             groupRangeMap: {},
             groupRangeList: [],
             npOpList: [{
                selected: true,
                name: '是'
             }, {
                selected: false,
                name: '否'
             }],
             registerList: [{
                selected: true,
                name: '是'
             }, {
                selected: false,
                name: '否'
             }],
            //  enablePatientLabel: null, 
             initGroupValue: {
                remark: '',
                groupName: '',
                groupPlan: '',
                groupRange: '',
                enablePatientLabel: '', // 开启患者标签（新老患者）
                enableRegisterAudit: '' // 开启启报名审核
             },
             savedValues: {
                remark: '',
                groupName: '',
                groupPlan: '',
                groupRange: '',
                enablePatientLabel: '',
                enableRegisterAudit: ''
             }
         }
     }
 
     componentDidMount() {
         this.initData();
     }

     initData = () => {
        if (this.props.code) {
            getVisitGroupDetail(this.props.code).then(res => {
                this.setState({
                    initGroupValue: {
                        remark: res.remark,
                        groupName: res.name,
                        groupPlan: res.planCode ? res.planCode + '_' + res.planVersion : '',
                        groupRange: res.dataScope,
                        enablePatientLabel: res.enablePatientLabel,
                        enableRegisterAudit: res.enableRegisterAudit
                    },
                    savedValues: {
                        remark: res.remark,
                        groupName: res.name,
                        groupPlan: res.planCode ? res.planCode + '_' + res.planVersion : '',
                        groupRange: res.dataScope,
                        enablePatientLabel: res.enablePatientLabel,
                        enableRegisterAudit: res.enableRegisterAudit
                    },
                });
            });
        }
        getDesignPlanSelects({
            tenantCode: this.props.tenantCode,
            type: 'VISIT'
        }).then(res => {
            const _groupListMap = {};
            (res || []).forEach(item => {
                _groupListMap[item.code + '_' + item.version] = item;
            });
            this.setState({
                groupList: res || [],
                groupListMap: _groupListMap
            })
        })
        getVisitGroupRangeEnums().then(res => {
            const _groupRangeMap = {};
            (res || []).forEach(item => {
                _groupRangeMap[item.code] = item.name;
            });
            this.setState({
                groupRangeList: res,
                groupRangeMap: _groupRangeMap
            });
        })
    }
 
     onFinish = (values) => {
        //  toVisitGroupSave
        if (this.state.submitLoading) {
            return;
        }
         this.setState({
             submitLoading: true
         });
        //  const [code, version] = values.groupPlan;
         const item = this.state.groupListMap[values.groupPlan];
         const version = item && item.version;
         const code = item && item.code;
         const param = {
            // "code": "", // 修改传code
            "name": values.groupName,
            "planCode": code,
            "planVersion": version,
            "dataScope": values.groupRange,
            "tenantCode": this.props.tenantCode,
            "enablePatientLabel": values.enablePatientLabel,
            "enableRegisterAudit": values.enableRegisterAudit,
            "remark": values.remark
        };
        if (this.props.code) {
            param.code = this.props.code;
        }
         toVisitGroupSave(param).then(res => {
            // 新增更新tab
            if (!this.props.code) {
                this.props.updateGroupTabs();
                message.success('保存成功', 1);
                this.setState({
                    savedValues: {
                        groupName: '',
                        groupPlan: '',
                        groupRange: ''
                     },
                     submitLoading: false,
                     showEditContent: false
                }, () => {
                    // 清空然后刷新表单数据
                    this.setState({
                        showEditContent: true
                    });
                });
            } else {
                // 编辑
                this.setState({
                    savedValues: values,
                    submitLoading: false
                });
                this.toEdit();
            }
         });
     };
 
     onFinishFailed = (errorInfo) => {
     };
 
     toEdit = () => {
         this.setState({
             isEdit: !this.state.isEdit
         })
     }
 
     isOpenMenuChange = (event) => {
         this.setState({
            groupRange: event.target.value
         });
     }

     onGroupPlan = (value) => {
        this.setState({
            groupPlan: value
        });
    }
 
    //  isOpenFollowUpChange = (event) => {
    //      this.setState({
    //          isOpenFollowUp: event.target.value
    //      });
    //  }
 
     render() {
 
         const { isEdit, savedValues, groupList, groupListMap, showEditContent, groupRangeList, groupRangeMap, npOpList, submitLoading, registerList }= this.state;

         let _npopText = '';
         if (savedValues.enablePatientLabel == true) {
            _npopText = '是';
         } else if (savedValues.enablePatientLabel == false) {
            _npopText = '否';
         } else {
            _npopText = '';
         }
         let _registerText = '';
         if (savedValues.enableRegisterAudit == true) {
            _registerText = '是';
         } else if (savedValues.enableRegisterAudit == false) {
            _registerText = '否';
         } else {
            _registerText = '';
         }
         const editContent = [
             { name: '分组名称：', text: savedValues.groupName },
             { name: '分组绑定方案：', text: groupListMap[savedValues.groupPlan]?.name || '' },
             { name: '分组适用范围：', text: groupRangeMap[savedValues.groupRange] || '' },
             { name: '是否统计NP/OP：', text: _npopText },
             { name: '是否开启报名审核：', text: _registerText },
             { name: '介绍：', text: savedValues.remark }
         ]
 
         return (
             <div className={'followupPatientSetting_wrap'}>
                 <div hidden={isEdit} className={'pTop'}>
                     {
                         editContent.map(item => (
                             <Row className={'rowBottom'}>
                                 <Col span={10} className={'leftLable'}>
                                     {item.name}
                                 </Col>
                                 <Col span={14} className={'rightText'}>
                                     {item.text}
                                 </Col>
                             </Row>
                         ))
                     }
                     <Row className={'rowBottom'}>
                         <Col span={7} offset={10}>
                             <Button
                                 hidden={isEdit}
                                 type="primary"
                                 style={{ height: 36 }}
                                 onClick={this.toEdit}
                             >编 辑</Button>
                         </Col>
                     </Row>
                 </div>
                 {
                    isEdit && showEditContent &&
                    <Form
                        hidden={!isEdit}
                        name="basic"
                        labelCol={{
                            span: 5,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        initialValues={savedValues}
                        // autoComplete="off"
                        // autoComplete={'new-password'}
                    >
                        <Form.Item
                            label="分组名称"
                            name="groupName"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入分组名称!',
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>

                        <Form.Item
                            label="分组绑定方案"
                            name="groupPlan"
                            rules={[
                                {
                                    required: false,
                                    message: '请选择分组绑定方案!',
                                },
                            ]}
                        >
                        {/* <Select placeholder="分组绑定方案" value={this.state.groupPlan} onChange={this.onGroupPlan}> */}
                        <Select placeholder="分组绑定方案" value={this.state.groupPlan}>
                            {/* {
                                versionList && versionList.map((item: any, index: any) => {
                                    return <Option value={item} key={index}>{item}</Option>
                                })
                            } */}
                            {
                                groupList && groupList.map((val, index) => (
                                    <Option value={val.code + '_' + val.version} key={index} >{val.name}</Option>
                                ))
                            }
                        </Select>
                        </Form.Item>

                        <Form.Item
                            label="分组适用范围"
                            name="groupRange"
                        >
                            <Radio.Group
                            //  onChange={this.isOpenMenuChange}
                            //  value={this.state.isOpenMenu}
                            >
                                {
                                    groupRangeList && groupRangeList.map((val, key) => (
                                        <Radio value={val.code}>{val.name}</Radio>
                                    ))
                                }
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="是否统计NP/OP"
                            name="enablePatientLabel"
                        >
                            <Radio.Group
                            >
                                {
                                    npOpList && npOpList.map((val, key) => (
                                        <Radio key={key} value={val.selected}>{val.name}</Radio>
                                    ))
                                }
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="是否开启报名审核"
                            name="enableRegisterAudit"
                        >
                            <Radio.Group
                            >
                                {
                                    registerList && registerList.map((val, key) => (
                                        <Radio key={key} value={val.selected}>{val.name}</Radio>
                                    ))
                                }
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="介绍"
                            name="remark"
                        >
                                <Input.TextArea
                                    style={{height: 138}}
                                    placeholder="请输入介绍信息"
                                />
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: this.props.code ? 10 : 10,
                                span: 16,
                            }}
                        >
                        {
                            this.props.code &&
                            <Button type="primary" onClick={this.toEdit}>
                                取消
                            </Button>
                        }
                            <Button loading={submitLoading} type="primary" style={{marginLeft: this.props.code ? '20px' : '0px'}} htmlType="submit">
                                保存
                            </Button>
                        </Form.Item>
                    </Form>
                 }
             </div>
         )
     }
 
 }
 
 export default FollowupPatientSetting;
 
 
 