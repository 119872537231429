/**
 * 配置中心服务接口
 * meixin
 */
import { postFile, post, get, patch } from '../comm/http';
import { message, Modal } from 'antd';

export const requestPostFile = async (url, params) => {
    return new Promise((resolve, reject) => {
        postFile(url, params).then(res => {
            if (res.code == '0') {
                resolve(res.data);
            } else {
                message.error(res.message);
                resolve(res.data);
            }
        }).catch(res => {
            message.error(res.message);
        });
    });
}

export const requestPost = async (url, params, headers) => {
    return new Promise((resolve, reject) => {
        post(url, params, headers).then(res => {
            if (res.code == '0') {
                resolve(res.data);
            } else {
                message.error(res.message);
                resolve(res.data);
            }
        }).catch(res => {
            if (res.status == '502' || res.status == '503') {
                Modal.warning({
                    title: '温馨提示',
                    content: '系统正在更新中,请耐心等待3~5分钟',
                    okText: '关闭',
                    maskClosable: true
                });
            } else {
                message.error(res.message);
            }
        });
    });
}
export const requestGet = async (url, params, headers) => {
    return new Promise((resolve, reject) => {
        get(url, params, headers).then(res => {
            if (res.code == '0') {
                resolve(res.data);
            } else {
                message.error(res.message);
                resolve(res.data);
            }
        }).catch(res => {
            message.error(res.message);
        });
    });
}

export const requestPostRes = (url, params, headers, options = { showErrorMsg: true }) => {
    return new Promise((resolve, reject) => {
        post(url, params, headers).then(res => {
            if(options.showErrorMsg) {
                // 跳过错误类型 该受试者信息已存在，重复受试者编码为：[QLXH200015]
                if(res.code === 50031411) {
                    Modal.error({
                        title: '提示',
                        content: res.message,
                        zIndex: 9999
                    })
                } else if(res.code !=  '0') {
                    message.error(res.message);
                }
            }
            resolve(res);
        }).catch(res => {
            message.error(res.message);
        });
    });
}

export const requestGetRes = (url, params, headers) => {
    return new Promise((resolve, reject) => {
        get(url, params, headers).then(res => {
            if (res.code != '0' && res.message) {
                message.error(res.message);
            }
            resolve(res);
        }).catch(res => {
            message.error(res.message);
        });
    });
}

export const requestPatchRes = (url, params, headers) => {
    return new Promise((resolve, reject) => {
        patch(url, params, headers).then(res => {
            if (res.code == '0') {
                resolve(res);
            } else {
                message.error(res.message);
                resolve(res);
            }
        }).catch(res => {
            message.error(res.message);
        });
    });
}

export const requestPostFileRes = async (url, params) => {
    return new Promise((resolve, reject) => {
        postFile(url, params).then(res => {
            if (res.code == '0') {
                resolve(res);
            } else {
                message.error(res.message);
                resolve(res);
            }
        }).catch(res => {
            message.error(res.message);
        });
    });
}