import { requestGetRes } from "../EdcService";

export type SignAgreementResult = {
  agreement: string;
  allocationCode: unknown | null;
  centerCode: string;
  code: string;
  createTime: string;
  createUser: string;
  hasScreen: unknown | null;
  icon: string;
  id: number | null;
  joinDate: string;
  projectCode: string;
  state: number;
}

// 根据code查询签署模板信息
export const signAgreementQuery = (subjectCode: string, fromDoctor=false)=> {
  return requestGetRes(`data/subject/signAgreementQuery/${subjectCode}?judgment=${fromDoctor ? 1 : 2}`)
}
